/* import __COLOCATED_TEMPLATE__ from './assigned-to.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { UNASSIGNED_ID as UNASSIGNED_ADMIN_ID } from 'embercom/objects/inbox/admin-summary';
import { UNASSIGNED_ID as UNASSIGNED_TEAM_ID } from 'embercom/objects/inbox/team-summary';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';

interface Args {
  conversationSummary: ConversationSummary;
}

interface Signature {
  Args: Args;
}

export default class AssignedTo extends Component<Signature> {
  @service declare intl: IntlService;

  get summary() {
    return this.args.conversationSummary;
  }

  get isAdminAssigned() {
    return this.summary.adminAssignee && this.summary.adminAssignee.id !== UNASSIGNED_ADMIN_ID;
  }

  get isTeamAssigned() {
    return this.summary.teamAssignee && this.summary.teamAssignee.id !== UNASSIGNED_TEAM_ID;
  }

  get assigneeInformation() {
    if (this.isAdminAssigned && !this.isTeamAssigned) {
      return this.intl.t('inbox.duplicate-conversation-note.assigned-to.assigned-to', {
        assigneeName: this.summary.adminAssignee?.name,
        htmlSafe: true,
      });
    } else if (!this.isAdminAssigned && this.isTeamAssigned) {
      return this.intl.t('inbox.duplicate-conversation-note.assigned-to.assigned-to', {
        assigneeName: this.summary.teamAssignee?.name,
        htmlSafe: true,
      });
    } else if (this.isAdminAssigned && this.isTeamAssigned) {
      return this.intl.t('inbox.duplicate-conversation-note.assigned-to.assigned-to', {
        assigneeName: `${this.summary.adminAssignee?.name} (${this.summary.teamAssignee?.name})`,
        htmlSafe: true,
      });
    } else {
      return this.intl.t('inbox.duplicate-conversation-note.assigned-to.unassigned', {
        htmlSafe: true,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::DuplicateConversationNote::AssignedTo': typeof AssignedTo;
    'inbox2/conversation-stream/part-groups/duplicate-conversation-note/assigned-to': typeof AssignedTo;
  }
}
