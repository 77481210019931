/* import __COLOCATED_TEMPLATE__ from './auto-refresh-indicator-text.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import type Inbox2OverviewService from 'embercom/services/inbox2-overview-service';
import type Inbox2TeammateActivityService from 'embercom/services/inbox2-teammate-activity-service';

const ANIMATION_TIMEOUT = 5000;

export default class AutoRefreshIndicatorText extends Component {
  @service declare inbox2OverviewService: Inbox2OverviewService;
  @service declare inbox2TeammateActivityService: Inbox2TeammateActivityService;

  @tracked animate = false;

  get isFetchingData() {
    return (
      this.inbox2OverviewService.isFetchingData || this.inbox2TeammateActivityService.isFetchingData
    );
  }

  get translationKey() {
    return this.isFetchingData
      ? 'inbox.dashboard.refresh-indicator-updating'
      : 'inbox.dashboard.refresh-indicator-default';
  }

  @task({ drop: true }) *doAnimate() {
    if (this.isFetchingData) {
      this.animate = true;
      yield timeout(ANIMATION_TIMEOUT);
      this.animate = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::AutoRefreshIndicatorText': typeof AutoRefreshIndicatorText;
    'inbox2/dashboard/auto-refresh-indicator-text': typeof AutoRefreshIndicatorText;
  }
}
