/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { sortBy, partition } from 'underscore';
import { isEmpty } from '@ember/utils';
import ENV from 'embercom/config/environment';
import type InboxApi from 'embercom/services/inbox-api';
import { taskFor } from 'ember-concurrency-ts';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import {
  type CallsAggregations,
  type ConversationsAggregations,
} from 'embercom/services/inbox2-overview-service';
import type Inbox2OverviewService from 'embercom/services/inbox2-overview-service';
import type DashboardSettings from 'embercom/objects/inbox/dashboard-settings';
import type View from 'embercom/objects/inbox/inboxes/view';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type Session from 'embercom/services/session';
import { type TableWidth } from '../main';
import type IntlService from 'embercom/services/intl';
import {
  REALTIME_DASHBOARD_TEAMS_VIEWS_COLUMNS,
  REALTIME_DASHBOARD_TEAMS_VIEWS_AGGREGATION_METRICS,
  REALTIME_DASHBOARD_TEAMS_VIEWS_COLUMNS_GROUPED,
} from 'embercom/lib/inbox2/realtime-dashboard-constants';

const DEFAULT_REFRESH_TIME_IN_SECONDS = 60;

const DEFAULT_TIMEFRAME = 8;

interface Args {
  selectedInboxIds: string[];
  temporalViews: View[];
  selectedColumns: string[];
  selectedInboxOverviewTimeframe: number;
  settings: DashboardSettings;
  saveSelectedInboxIds: (selectedInboxIds: string[]) => Promise<void>;
  saveSelectedColumns: (selectedColumns: string[]) => void;
  saveSelectedInboxOverviewTimeframe: (timeframe: number) => void;
  selectedTab: string;
  availableInboxes: Inbox[];
  openDrilldownDrawer: (selectedCell: any) => void;
  tableWidth: TableWidth;
}

interface Signature {
  Args: Args;
}

export interface AggregationMetric {
  title: string;
  value: any;
  icon?: string;
  qualifier?: string;
  metricType?: string | undefined;
  tooltipContent: string;
  awayReasons?: any;
  statusIndicator?: 'active' | 'away' | 'reassigning';
}

export default class Table extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare inbox2OverviewService: Inbox2OverviewService;
  @service declare intl: IntlService;
  @service intercomEventService: any;
  @service notificationsService: any;

  @tracked data: any = [];
  @tracked aggregations: ConversationsAggregations | {} = {};
  @tracked callsAggregations: CallsAggregations | {} = {};
  @tracked selectedColumns: string[] = this.args.selectedColumns || this.defaultSelectedColumns;
  @tracked currentTimeframe: number = this.args.selectedInboxOverviewTimeframe || DEFAULT_TIMEFRAME;
  @tracked selectedDrilldownCell: any;

  // TODO: make into types
  @tracked sortState: { valuePath: string; direction: string; sortKey: string } = {
    valuePath: 'name',
    direction: 'desc',
    sortKey: 'name',
  };

  @tracked isLoading = true;
  @tracked isSwitchingTimeframe = false;
  @tracked hasChangedSettings = false;
  @tracked isFullscreen = false;
  @tracked inboxSelectorOpened = true;
  @tracked selectedAggregationMetrics: AggregationMetric[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.refresh).perform();
    this.inbox2OverviewService.setForceRefresh(this.refresh);
  }

  get settings() {
    return this.args.settings;
  }

  get selectedInboxes() {
    return this.args.availableInboxes
      .filter(({ id }) => this.args.selectedInboxIds.includes(id))
      .map((inbox) => {
        if (inbox.category === InboxCategory.View) {
          return { ...inbox, id: `view:${inbox.id}` };
        }
        return inbox;
      });
  }

  get temporalViewIds(): string[] {
    return this.args.temporalViews.map((v) => v.id);
  }

  get availableInboxesWithSelectionInfo() {
    let inboxItems = this.args.availableInboxes.map((inbox) => ({
      text: inbox.name,
      value: inbox.id,
      component: 'inbox2/dashboard/icon-and-name-dropdown-item',
      componentAttrs: {
        displayName: inbox.name,
        icon: inbox.icon,
      },
      isInboxView: inbox.type === InboxType.View,
      isDisabled: this.temporalViewIds.includes(inbox.id),
      isSelected: this.args.selectedInboxIds.includes(inbox.id),
      tooltipText: this.intl.t(
        'inbox.dashboard.inbox-overview.inboxes-and-views-filter.temporal-views-tooltip',
        { isTemporalView: this.temporalViewIds.includes(inbox.id) },
      ),
    }));

    let [selectedItems, unselectedItems] = partition(inboxItems, ({ isSelected }) => isSelected);

    let [unselectedViewItems, unselectedTeamItems] = partition(
      unselectedItems,
      ({ isInboxView }) => isInboxView,
    );

    let [unselectedTemporalViewItems, unselectedNonTemporalViewItems] = partition(
      unselectedViewItems,
      (item) => this.temporalViewIds.includes(item.value),
    );

    return [
      {
        heading: this.intl.t(
          'inbox.dashboard.inbox-overview.inboxes-and-views-filter.selected-items',
        ),
        items: selectedItems,
      },
      {
        heading: this.intl.t(
          'inbox.dashboard.inbox-overview.inboxes-and-views-filter.unselected-inboxes',
        ),
        items: unselectedTeamItems,
      },
      {
        heading: this.intl.t(
          'inbox.dashboard.inbox-overview.inboxes-and-views-filter.unselected-views',
        ),
        items: unselectedNonTemporalViewItems,
      },
      {
        heading: this.intl.t(
          'inbox.dashboard.inbox-overview.inboxes-and-views-filter.temporal-views',
          { hasOtherSelectedViews: unselectedNonTemporalViewItems.length !== 0 },
        ),
        items: unselectedTemporalViewItems,
      },
    ];
  }

  get loadingRowCount() {
    return this.args.selectedInboxIds.length + 1;
  }

  get columns() {
    return this.columnsData.filter(({ hideable, valuePath }) => {
      return !hideable || this.selectedColumns.includes(valuePath);
    });
  }

  get columnsGrouped() {
    let result: {
      label: string;
      tooltip: string | boolean | null;
      isSortable: string | boolean | null;
      valuePath: string;
      metricType?: string;
    }[] = [];
    this.columnsDataGrouped.map((group) => {
      group.items.forEach((item) => {
        if (item.hideable && this.selectedColumns.includes(item.valuePath)) {
          result.push(item);
        }
      });
    });
    return result;
  }

  get canShowCallsMetrics() {
    return this.session.workspace.isFeatureEnabled('inbound-phone-call');
  }

  get columnsData() {
    let hideIfFullscreen = (value: string | boolean) => (this.isFullscreen ? null : value);
    let columnsList = REALTIME_DASHBOARD_TEAMS_VIEWS_COLUMNS;
    if (!this.canShowCallsMetrics) {
      columnsList = columnsList.filter(({ category }) => category !== 'calls');
    }

    return columnsList.map((column) => ({
      ...column,
      label: this.intl.t(column.labelKey),
      tooltip: column.tooltipKey
        ? hideIfFullscreen(
            this.intl.t(column.tooltipKey, {
              idleThresholdText: this.settings.idleThresholdText,
              timeframe: this.currentTimeframe,
              aggregationType: this.settings.aggregationTypeText,
            }),
          )
        : null,
      isSortable: hideIfFullscreen(true),
    }));
  }

  get columnsDataGrouped() {
    let hideIfFullscreen = (value: string | boolean) => (this.isFullscreen ? null : value);
    let columnsGroup = REALTIME_DASHBOARD_TEAMS_VIEWS_COLUMNS_GROUPED;

    return columnsGroup.map((columnGroup) => ({
      heading: this.intl.t(columnGroup.headingKey),
      hideGroup: columnGroup.hideGroup,
      items: columnGroup.items.map((column) => ({
        ...column,
        label: this.intl.t(column.labelKey),
        tooltip: column.tooltipKey
          ? hideIfFullscreen(
              this.intl.t(column.tooltipKey, {
                idleThresholdText: this.settings.idleThresholdText,
                timeframe: this.currentTimeframe,
                aggregationType: this.settings.aggregationTypeText,
              }),
            )
          : null,
        isSortable: hideIfFullscreen(true),
      })),
    }));
  }

  get columnsDropdown() {
    return this.columnsData
      .filter(({ hideable }) => hideable)
      .map((column) => {
        return {
          text: column.label,
          value: column.valuePath,
          isSelected: this.selectedColumns.includes(column.valuePath),
        };
      });
  }

  get columnsDropdownGrouped() {
    return this.columnsDataGrouped
      .filter(({ hideGroup }) => !hideGroup)
      .map((group) => ({
        heading: group.heading,
        items: group.items.map((column) => ({
          text: column.label,
          value: column.valuePath,
          isSelected: this.selectedColumns.includes(column.valuePath),
        })),
      }));
  }

  get defaultSelectedColumns() {
    return this.columnsData.filter(({ hideable }) => hideable).map(({ valuePath }) => valuePath);
  }

  get hasSelectedInboxes() {
    return this.args.selectedInboxIds.length > 0;
  }

  get displayEmptyState() {
    return this.args.selectedInboxIds.length === 0;
  }

  @action openInboxSelector() {
    (document.querySelector('[data-monitoring-filter-selector=inboxes]') as HTMLElement).click();
  }

  @action async inboxSelectionChanged(newSelectedInboxIds: string[]) {
    try {
      await this.args.saveSelectedInboxIds(newSelectedInboxIds);
      this._trackNumberOfInboxes('selection_changed');
    } catch (e) {
      // Do not refresh
      return;
    }

    // Refresh if saved successfully
    this.isLoading = true;
    taskFor(this.refresh).perform();
  }

  @action columnsSelectionChanged(newSelection: string[]) {
    this.selectedColumns = newSelection;
    this.args.saveSelectedColumns(this.selectedColumns);
  }

  @action changeTimeframe(newTimeFrame: number) {
    this.currentTimeframe = newTimeFrame;
    this.isSwitchingTimeframe = true;
    taskFor(this.refresh).perform();
    this.args.saveSelectedInboxOverviewTimeframe(newTimeFrame);
  }

  @action onSort(valuePath: string) {
    let direction = 'asc';
    if (this.sortState.valuePath === valuePath && this.sortState.direction === 'asc') {
      direction = 'desc';
    }
    let sortKey = this.columnsData.find((col) => col.valuePath === valuePath)?.sortKey || valuePath;
    this.sortState = { valuePath, direction, sortKey };

    this._sortData();
  }

  @action toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
  }

  @action openDrilldownDrawer(cell: any) {
    if (this.canShowCallsMetrics) {
      this.selectedDrilldownCell = {
        ...cell,
        metricName: this.columnsGrouped.find(({ metricType }) => metricType === cell.metricType)
          ?.label,
      };
    } else {
      this.selectedDrilldownCell = {
        ...cell,
        metricName: this.columns.find(({ metricType }) => metricType === cell.metricType)?.label,
      };
    }

    this.args.openDrilldownDrawer(this.selectedDrilldownCell);
  }

  @task({ restartable: true }) *refresh({ hasChangedSettings } = { hasChangedSettings: false }) {
    this.hasChangedSettings = hasChangedSettings;
    let refreshCount = 0;
    while (true) {
      if (document.hidden) {
        yield timeout(3 * 1000);
        refreshCount = 0;
      } else {
        try {
          yield taskFor(this.doRefresh).perform(refreshCount % 15 === 0 ? [] : ['csat', 'sla']);
          if (refreshCount >= 15) {
            refreshCount = 0;
          }
          refreshCount++;
        } catch (err) {
          this.notificationsService.notifyError(
            `Failed to refresh inboxes. Next try in ${DEFAULT_REFRESH_TIME_IN_SECONDS} seconds.`,
          );
        }
        if (ENV.environment === 'test') {
          break;
        } else {
          yield timeout(DEFAULT_REFRESH_TIME_IN_SECONDS * 1000);
        }
      }
    }
  }

  @task({ restartable: true }) *doRefresh(excludedQueryTypes: string[] = []): TaskGenerator<void> {
    let inboxData = yield this.inbox2OverviewService.getInboxData(
      this.selectedInboxes,
      {
        timeframeInHours: this.currentTimeframe,
        aggregationType: this.settings.aggregationType,
        idleThreshold: this.settings.idleThreshold,
        excludeBotTime: this.settings.excludeBotTime,
        excludedQueryTypes,
      },
      {
        cachedOverview: this.data,
      },
    );
    this.data = inboxData.overview;
    this.aggregations = inboxData.aggregations;
    this.callsAggregations = inboxData.callsAggregations;
    this._sortData();

    this.selectedAggregationMetrics = this.updatedSelectedAggregationMetrics();

    if (this.isLoading) {
      this.isLoading = false;
      this._trackNumberOfInboxes('loaded');
    }
    if (this.isSwitchingTimeframe) {
      this.isSwitchingTimeframe = false;
    }
    if (this.hasChangedSettings) {
      this.hasChangedSettings = false;
    }
    this._trackNumberOfInboxes('refreshed');
  }

  _trackNumberOfInboxes(action: string) {
    let selectedInboxes = this.args.availableInboxes.filter(({ id }) =>
      this.args.selectedInboxIds.includes(id),
    );
    let [viewItems, teamItems] = partition(selectedInboxes, ({ type }) => type === InboxType.View);

    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'inbox_overview_table',
      place: 'monitoring',
      inboxIds: this.args.selectedInboxIds,
      numberOfInboxes: this.args.selectedInboxIds.length,
      tabVisible: !document.hidden,
      numberOfTeamInboxes: teamItems.length,
      numberOfViewInboxes: viewItems.length,
      selectedColumns: this.selectedColumns,
      tabCurrentlySelected: this.args.selectedTab === 'inboxes',
    });
  }

  _sortData() {
    let { sortKey, direction } = this.sortState;
    let [blankData, sortableData] = partition(this.data, (data) => isEmpty(data[sortKey]));
    let sortedData = sortBy(sortableData, 'name').sortBy(sortKey);
    if (direction === 'desc') {
      sortedData = sortedData.reverse();
    }
    this.data = [...sortedData, ...sortBy(blankData, 'name')];
  }

  updatedSelectedAggregationMetrics(): AggregationMetric[] {
    if (this.canShowCallsMetrics) {
      return REALTIME_DASHBOARD_TEAMS_VIEWS_AGGREGATION_METRICS.map((metric) => ({
        ...metric,
        title: this.intl.t(metric.titleKey),
        value:
          metric.category === 'conversations'
            ? (this.aggregations as ConversationsAggregations)[
                metric.valueKey as keyof ConversationsAggregations
              ]
            : (this.callsAggregations as CallsAggregations)[
                metric.valueKey as keyof CallsAggregations
              ],
        tooltipContent: this.intl.t(metric.tooltipContentKey, {
          threshold: this.settings.idleThresholdText,
          timeframe: this.currentTimeframe,
          aggregationType: this.settings.aggregationTypeText,
        }),
      }));
    }
    return [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::InboxOverview::Table': typeof Table;
    'inbox2/dashboard/inbox-overview/table': typeof Table;
  }
}
