/* import __COLOCATED_TEMPLATE__ from './outbound-whatsapp.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Whatsapp from 'embercom/objects/inbox/renderable/whatsapp';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { Channel } from 'embercom/models/data/inbox/channels';
import { type SenderSummary } from 'embercom/objects/inbox/renderable/whatsapp';
import { OutboundInitialPartSenderType } from 'embercom/objects/inbox/outbound-initial-part-sender-types';

interface OutboundWhatsappRenderablePart extends RenderablePart {
  renderableData: Whatsapp;
}

interface OutboundWhatsappPartGroup extends PartGroup {
  parts: Array<OutboundWhatsappRenderablePart>;
}

interface Args {
  partGroup: OutboundWhatsappPartGroup;
  isReadOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamTeammateContentComponent extends Component<Signature> {
  @service declare session: Session;
  whatsappChannel = Channel.Whatsapp;

  get renderableData() {
    return this.args.partGroup.parts[0].renderableData;
  }

  get isDeleted() {
    return this.renderableData.deletedAt;
  }

  get sender(): SenderSummary {
    return this.renderableData.senderSummary;
  }

  get senderIsTeammate() {
    return this.isDeleted || this.renderableData.senderType === OutboundInitialPartSenderType.Admin;
  }

  get senderIsTeam() {
    return this.renderableData.senderType === OutboundInitialPartSenderType.Team;
  }

  get senderIsBot() {
    return this.renderableData.senderType === OutboundInitialPartSenderType.Bot;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::OutboundWhatsapp': typeof ConversationStreamTeammateContentComponent;
    'inbox2/conversation-stream/part-groups/outbound-whatsapp': typeof ConversationStreamTeammateContentComponent;
  }
}
