/* import __COLOCATED_TEMPLATE__ from './answer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import {
  type FinSource,
  type InboxAssistantConversationPart,
} from 'embercom/objects/inbox/inbox-assistant-conversation';
import { type RelatedContentArgs } from '../../related-content';
import type RelatedContent from '../../related-content';
import { type WithBoundArgs } from '@glint/template';
import {
  type CopilotChatMessageAnalyticsData,
  type CopilotInteractionMetadata,
  type NormalizedFinSource,
} from 'embercom/lib/inbox2/copilot/types';
import templateOnlyComponent from '@ember/component/template-only';
import { type AiAssistPromptKey } from 'embercom/resources/inbox2/composer/ai-assist';

export interface AnswerArgs {
  relatedContentComponent: WithBoundArgs<typeof RelatedContent, keyof RelatedContentArgs>;
  onInlineSourceClick: (
    content: FinSource,
    opts: CopilotInteractionMetadata,
    event: PointerEvent,
  ) => void;
  sourceDetails: NormalizedFinSource[];
  message: InboxAssistantConversationPart;
  answerIsStreaming: boolean;
  onAddToComposerClick: () => unknown;
  insertSourceBlocksIntoComposer: (
    source: NormalizedFinSource,
    message: InboxAssistantConversationPart,
  ) => void;
  analyticsData: CopilotChatMessageAnalyticsData;
  setHoveredSourceKey: (sourceKey: string | null) => void;
  onAddNoteToComposerClick: () => unknown;
  changeToneAndInsertBlocks: (promptKey: AiAssistPromptKey) => void;
  shouldRenderFirstAnswerTooltip: boolean;
}

interface Signature {
  Element: Element;
  Args: AnswerArgs;
  Blocks: {
    default: [];
  };
}

const Answer = templateOnlyComponent<Signature>();
export default Answer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::MessageStates::AnswerState::Answer': typeof Answer;
    'inbox2/copilot/message-states/answer-state/answer': typeof Answer;
  }
}
