/* import __COLOCATED_TEMPLATE__ from './icon-and-name-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type Icon } from 'embercom/objects/inbox/command-k/icons';

interface Signature {
  Args: {
    componentAttrs: {
      icon: Icon;
      displayName: string;
    };
  };
}

const IconAndNameDropdownItem = templateOnlyComponent<Signature>();
export default IconAndNameDropdownItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::IconAndNameDropdownItem': typeof IconAndNameDropdownItem;
    'inbox2/dashboard/icon-and-name-dropdown-item': typeof IconAndNameDropdownItem;
  }
}
