/* import __COLOCATED_TEMPLATE__ from './editable-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Attribute from 'embercom/objects/inbox/attribute';
import { taskFor } from 'ember-concurrency-ts';
import { keepLatestTask } from 'ember-concurrency-decorators';
import type IntlService from 'embercom/services/intl';

// @ts-ignore
import isValidUrl, { urlWithHttpPrefix } from 'embercom/lib/url-validator';

import { localCopy } from 'tracked-toolbox';
import { tracked } from '@glimmer/tracking';
import type User from 'embercom/objects/inbox/user';

type Value = unknown;

interface Args {
  attribute: Attribute;
  value: Value;
  isLoading: boolean;
  showPlaceholder?: boolean;
  showError?: boolean;
  emailIdentifiesUser?: boolean;
  user?: User;
  setQualificationCompanyForUser?: (value: Value) => void;
  removeQualificationCompanyForUser?: () => void;
  onUpdate: (attribute: Attribute, newValue: Attribute['value']) => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    icon: [];
  };
}

export default class EditableAttribute extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare notificationsService: any;

  @localCopy('args.value') declare updatedValue: Value;
  @tracked isHovering = false;

  @action
  onSelectItem(value: Value) {
    this.updatedValue = value;
    taskFor(this.updateAttribute).perform();
  }

  get listOptions() {
    return this.args.attribute.options.map((option) => ({
      text: option.value,
      value: option.value,
    }));
  }

  get isUrlValue(): boolean {
    if (!this.updatedValue) {
      return false;
    }

    let value = String(this.updatedValue);
    return isValidUrl(value) && !value.includes('@');
  }

  get linkUrl() {
    if (!this.isUrlValue) {
      return undefined;
    }

    let value = this.updatedValue as string;
    return urlWithHttpPrefix(value);
  }

  @action async onFinishEditing() {
    await taskFor(this.updateAttribute).perform();
  }

  @keepLatestTask
  *updateAttribute() {
    yield this.args.onUpdate(this.args.attribute, this.updatedValue as Attribute['value']);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::EditableAttribute': typeof EditableAttribute;
    'inbox2/conversation-details-sidebar/sidebar-sections/editable-attribute': typeof EditableAttribute;
  }
}
