/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-component-inheritance */

import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
import { taskFor } from 'ember-concurrency-ts';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { task } from 'ember-concurrency-decorators';
import { Resource } from 'ember-resources';

export class ConversationAttributesResource extends Resource {
  @service session!: Session;
  @tracked conversationAttributeDescriptors: Array<ConversationAttributeDescriptor> = [];

  constructor(owner: any, args: any, previous: any) {
    super(owner, args);

    if (!previous) {
      taskFor(this.loadConversationAttributeDescriptors).perform();
    } else {
      this.conversationAttributeDescriptors = previous.conversationAttributeDescriptors;
    }
  }

  @task
  *loadConversationAttributeDescriptors() {
    this.conversationAttributeDescriptors =
      (yield this.session.workspace.fetchConversationAttributeDescriptors()) as ConversationAttributeDescriptor[];
  }
}
