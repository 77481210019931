/* import __COLOCATED_TEMPLATE__ from './ticket-id-readonly-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    conversation: ConversationTableEntry;
  };
}

const UnnamedComponent = templateOnlyComponent<Signature>();
export default UnnamedComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::TicketPanel::TicketIdReadonlyRow': typeof UnnamedComponent;
  }
}
