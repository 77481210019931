/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { ChildAction } from 'embercom/objects/inbox/command-k/action';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type InsertTicketCardAction from 'embercom/objects/inbox/command-k/insert-ticket-card';
import { TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import type CommandKService from 'embercom/services/command-k';
import { inject as service } from '@ember/service';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';
import { TicketCategory, type TicketType } from 'embercom/objects/inbox/ticket';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';

interface Category {
  id: TicketCategory;
  heading: string;
}

interface TicketByCategory {
  id: TicketCategory;
  heading: string;
  items: ChildAction[];
}

interface Signature {
  Args: CommandKPaneComponentArgs<InsertTicketCardAction>;
}

export default class InsertTicketCardPaneComponent extends Component<Signature> {
  @tracked query = '';
  @service declare commandK: CommandKService;
  @service declare intl: IntlService;
  @service declare session: Session;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.selectableItems,
    isCommandKVisible: this.commandK.isVisible,
  }));

  ticketTypeResource = useResource(this, TicketTypeResource);

  convertTicketTypeToChildAction(ticketType: TicketType) {
    let { id, name } = ticketType;

    return new ChildAction({
      parent: this.args.actionItem,
      id: id.toString(),
      label: name,
      value: ticketType,
    });
  }

  get selectableItems() {
    return this.ticketsByCategory.flatMap((category) => category.items);
  }

  get shareableTickets() {
    let upperQuery = this.query.trim().toLocaleUpperCase();

    return this.ticketTypeResource.activeShareableTicketTypes
      .filter((ticketType) => ticketType.name.toLocaleUpperCase().includes(upperQuery))
      .sortBy('name');
  }

  get items(): ChildAction[] {
    return this.shareableTickets.map((ticketType) =>
      this.convertTicketTypeToChildAction(ticketType),
    );
  }

  get ticketsByCategory(): TicketByCategory[] {
    let ticketCategories: Category[] = [
      {
        id: TicketCategory.Task,
        heading: this.intl.t('inbox.command-k.tickets.task'),
      },
      {
        id: TicketCategory.Request,
        heading: this.intl.t('inbox.command-k.tickets.request'),
      },
      {
        id: TicketCategory.Tracker,
        heading: this.intl.t('inbox.command-k.tickets.tracker'),
      },
    ];

    let sections = ticketCategories
      .map((category) => {
        let items = this.shareableTickets
          .filter((item) => item.category === category.id)
          .map((ticketType) => this.convertTicketTypeToChildAction(ticketType));

        return {
          ...category,
          items,
        };
      })
      .filter((section) => section.items.length > 0);

    return sections;
  }

  @action search(query: string) {
    this.query = query;

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.items.length,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::InsertTicketCard::Pane': typeof InsertTicketCardPaneComponent;
    'inbox2/command-k/insert-ticket-card/pane': typeof InsertTicketCardPaneComponent;
  }
}
