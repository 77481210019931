/* import __COLOCATED_TEMPLATE__ from './customer-conversations.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import { inject as service } from '@ember/service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type Session from 'embercom/services/session';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLElement;
  Args: {
    conversationId: number;
    previewOnly?: boolean;
    linkedItems: LinkedTicketSummary[];
  };
  Blocks: {
    itemRow: [
      {
        item: LinkedTicketSummary;
        component: any;
      },
    ];
  };
}

export default class CustomerConversations extends Component<Signature> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare session: Session;

  get itemAlreadyLinked() {
    return !!this.linkedItem;
  }

  get isLiteInbox() {
    return this.session.showLightInbox;
  }

  get linkedItem() {
    return this.args.linkedItems.firstObject;
  }

  @action async createLinkedConversation() {
    this.inboxSidebarService.startLinkedConversation();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Links::CustomerConversations': typeof CustomerConversations;
    'inbox2/conversation-details-sidebar/sidebar-sections/links/customer-conversations': typeof CustomerConversations;
  }
}
