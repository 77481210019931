/* import __COLOCATED_TEMPLATE__ from './breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const Breakdown = templateOnlyComponent<Signature>();
export default Breakdown;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Drilldown::Breakdown': typeof Breakdown;
    'inbox2/dashboard/drilldown/breakdown': typeof Breakdown;
  }
}
