/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type TagItemBaseAction } from 'embercom/objects/inbox/command-k/tag-item';
import type Tag from 'embercom/objects/inbox/tag';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';

// @ts-ignore
import { ref } from 'ember-ref-bucket';
import type CommandKService from 'embercom/services/command-k';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';
import type Inbox2TagsSearch from 'embercom/services/inbox2-tags-search';
import { type SearchByTypeResponse } from 'embercom/services/quick-search';

type Args = CommandKPaneComponentArgs<TagItemBaseAction>;
interface Signature {
  Args: Args;
}

export default class TagItemPaneComponent extends Component<Signature> {
  @tracked query = '';

  @service declare commandK: CommandKService;
  @service declare inbox2TagsSearch: Inbox2TagsSearch;

  @ref('command-k-input') declare commandKInput?: HTMLInputElement;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.items,
    isCommandKVisible: this.commandK.isVisible,
  }));

  get placeholder(): string {
    return this.args.actionItem.placeholder;
  }

  @action search(query: string) {
    this.query = query;
    if (!query) {
      this.selection.select(null);
    }
  }

  get items(): Array<ChildAction> {
    return this.args.actionItem.buildResults(
      this.query,
      this.tagsLoader.value || { results: [], total: 0 },
    );
  }

  tagsLoader = trackedFunction(this, async () => {
    let results: SearchByTypeResponse = await this.inbox2TagsSearch.filterTags(this.query);

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: results.results.length,
    });

    return results;
  });

  @action selectTag(tagAction: ChildAction & { value: Tag }) {
    this.args.onSelect(tagAction);

    // Manually focus the input because a click would have moved focus away
    this.commandKInput?.focus();

    if (this.query !== '') {
      this.query = '';
    }
  }

  get containerSelectorName(): string {
    return 'js__inbox-tags-pane';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::TagItem::Pane': typeof TagItemPaneComponent;
    'inbox2/command-k/tag-item/pane': typeof TagItemPaneComponent;
  }
}
