/* import __COLOCATED_TEMPLATE__ from './editable-email-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type Named, Resource, useResource } from 'ember-resources';
import { inject as service } from '@ember/service';
import type InboxApi from 'embercom/services/inbox-api';
import type Session from 'embercom/services/session';
import { taskFor } from 'ember-concurrency-ts';
import { registerDestructor } from '@ember/destroyable';
import { task } from 'ember-concurrency-decorators';
import { USER_ROLE_PREDICATE } from 'embercom/lib/default-predicates';
import Conversation from 'embercom/models/conversation';
import { type AttributeComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';

function hasValueChanged(currentValue: any, newValue: any) {
  if (isEmpty(currentValue) && isEmpty(newValue)) {
    return false;
  }
  return currentValue !== newValue;
}

interface Signature {
  Args: AttributeComponentArgs & {
    conversationId: number;
    activeInbox: Inbox;
  };
}

interface ResourceArgs {
  email: string;
  userId: string;
}

class EditableEmailResource extends Resource<Named<ResourceArgs>> {
  @tracked conversationCount = null;
  @tracked existingUsers = [];
  @tracked isHovered = false;

  @service declare inboxApi: InboxApi;
  @service declare session: Session;

  constructor(owner: any, args: any) {
    super(owner, args);

    let { email, userId } = this.args.named;

    taskFor(this.searchUsers).perform(email);
    taskFor(this.fetchConversationCount).perform(userId);

    registerDestructor(this, () => {
      taskFor(this.searchUsers).cancelAll();
      taskFor(this.fetchConversationCount).cancelAll();
    });
  }

  get isRunning() {
    return taskFor(this.searchUsers).isRunning || taskFor(this.fetchConversationCount).isRunning;
  }

  @task
  *searchUsers(email: string): any {
    let predicates = [
      {
        attribute: 'email',
        type: 'string',
        comparison: 'eq',
        value: email,
      },
      USER_ROLE_PREDICATE,
    ];

    this.existingUsers = yield this.inboxApi.searchUsers(predicates);
  }

  @task
  *fetchConversationCount(userId: string): any {
    let response = yield Conversation.newSearch({
      app_id: this.session.workspace.id,
      user_ids: [userId],
      scope: 'all',
      _data_source: 'elasticsearch',
    });
    this.conversationCount = response.total_count;
  }
}

export default class EditableEmailAttributeComponent extends Component<Signature> {
  @tracked isEditing = false;
  @tracked isConfirmModalOpen = false;
  @tracked newValue: string | undefined;

  @service declare intercomEventService: any;

  data = useResource(this, EditableEmailResource, () => ({
    email: this.attributeValue,
    userId: this.args.user?.id,
  }));

  get attributeName() {
    return this.args.attribute.humanFriendlyName;
  }

  get attributeValue() {
    let { user, attribute } = this.args;

    return user?.attributes[attribute.key] as string | undefined;
  }

  get emailIdentifiesUser() {
    let { user } = this.args;
    return user?.hasUserRole && !user?.userId;
  }

  @action
  enableEditMode() {
    this.newValue = this.attributeValue;
    this.isEditing = true;
  }

  @action
  disableEditMode() {
    this.isEditing = false;
  }

  @action
  maybePromptForConfirmation(event: any) {
    event.preventDefault();

    if (!this.isEditing) {
      return;
    }

    if (!hasValueChanged(this.attributeValue, this.newValue)) {
      this.disableEditMode();

      return;
    }

    if (isEmpty(this.newValue)) {
      this.approveEdit();
      return;
    }

    this.isConfirmModalOpen = true;
  }

  @action
  reset() {
    this.newValue = this.attributeValue;
    this.isConfirmModalOpen = false;
    this.disableEditMode();
  }

  @action
  async approveEdit() {
    this.trackEditEvent();
    await taskFor(this.updateAttribute).perform(this.args.attribute, this.newValue);

    this.reset();
  }

  @task
  *updateAttribute(attribute: AttributeComponentArgs['attribute'], newValue: string | undefined) {
    yield this.args.onUserAttributeUpdated(attribute, newValue);
  }

  @action togglePin() {
    if (!this.args.showPinIcon) {
      return;
    }

    this.args.togglePin('email');
  }

  private trackEditEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'edited',
      object: 'user_email_attribute',
      place: 'inbox2',
      end_user_id: this.args.user?.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::EditableEmailAttribute': typeof EditableEmailAttributeComponent;
    'inbox2/conversation-details-sidebar/sidebar-sections/editable-email-attribute': typeof EditableEmailAttributeComponent;
  }
}
