/* import __COLOCATED_TEMPLATE__ from './teammate-assignee-row.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: any;
}

const TeammateAssigneeRow = templateOnlyComponent<Signature>();
export default TeammateAssigneeRow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::TeammateAssigneeRow': typeof TeammateAssigneeRow;
    'inbox2/conversation-details-sidebar/teammate-assignee-row': typeof TeammateAssigneeRow;
  }
}
