/* import __COLOCATED_TEMPLATE__ from './track-people-typing.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type Nexus from 'embercom/services/nexus';
import {
  NexusEventName,
  type UserIsTypingEvent,
  type AdminIsTypingANoteEvent,
  type AdminIsTypingEvent,
} from 'embercom/services/nexus';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { taskFor } from 'ember-concurrency-ts';
import AdminSummary from 'embercom/objects/inbox/admin-summary';

interface Signature {
  Args: {
    conversationId: number;
  };
  Blocks: {
    default: [
      {
        typingUserId?: string;
        typingAdmin?: AdminSummary;
        typingNoteAdmin?: AdminSummary;
      },
    ];
  };
}

const TIMEOUT = ENV.APP._400MS * 3;

type TypingEvent = UserIsTypingEvent | AdminIsTypingEvent | AdminIsTypingANoteEvent;

export default class TrackPeopleTypingComponent extends Component<Signature> {
  @service declare nexus: Nexus;

  conversationId?: number;

  @tracked typingUserId?: string;
  @tracked typingAdmin?: AdminSummary;
  @tracked typingNoteAdmin?: AdminSummary;

  get hasNoTypingEvents() {
    return !this.typingUserId && !this.typingAdmin && !this.typingNoteAdmin;
  }

  get events() {
    return [
      NexusEventName.UserIsTyping,
      NexusEventName.AdminIsTyping,
      NexusEventName.AdminIsTypingANote,
    ];
  }

  @task({ restartable: true }) *userIsTypingTimer(event: UserIsTypingEvent) {
    if (this.typingUserId !== event['nx.FromUser']) {
      this.typingUserId = event['nx.FromUser'];
    }
    yield timeout(TIMEOUT);
    this.typingUserId = undefined;
  }

  @task({ restartable: true }) *adminIsTypingTimer(event: AdminIsTypingEvent) {
    if (this.typingAdmin?.id !== Number(event.eventData.adminId)) {
      this.typingAdmin = AdminSummary.deserialize({
        id: Number(event.eventData.adminId),
        name: event.eventData.adminName,
        image_url: event.eventData.adminAvatar,
      });
    }
    yield timeout(TIMEOUT);
    this.typingAdmin = undefined;
  }

  @task({ restartable: true }) *adminIsTypingANoteTimer(event: AdminIsTypingANoteEvent) {
    if (this.typingNoteAdmin?.id !== Number(event.eventData.adminId)) {
      this.typingNoteAdmin = AdminSummary.deserialize({
        id: Number(event.eventData.adminId),
        name: event.eventData.adminName,
        image_url: event.eventData.adminAvatar,
      });
    }
    yield timeout(TIMEOUT);
    this.typingNoteAdmin = undefined;
  }

  @action
  handleTypingEvent(event: TypingEvent) {
    let eventData = event.eventData;

    if (Number(eventData.conversationId) === this.args.conversationId) {
      switch (event.eventName) {
        case NexusEventName.UserIsTyping: {
          taskFor(this.userIsTypingTimer).perform(event);
          break;
        }
        case NexusEventName.AdminIsTyping: {
          taskFor(this.adminIsTypingTimer).perform(event);
          break;
        }
        case NexusEventName.AdminIsTypingANote: {
          taskFor(this.adminIsTypingANoteTimer).perform(event);
          break;
        }
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Conversation::TrackPeopleTyping': typeof TrackPeopleTypingComponent;
  }
}
