/* import __COLOCATED_TEMPLATE__ from './conversation-stream.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import PartGroup, {
  PartGroupCategory,
} from 'embercom/objects/inbox/conversation-stream/part-group';
import { inject as service } from '@ember/service';
// @ts-ignore
import { cached, dedupeTracked } from 'tracked-toolbox';
import type InboxState from 'embercom/services/inbox-state';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { later } from '@ember/runloop';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { EntityType } from 'embercom/models/data/entity-types';
import type Session from 'embercom/services/session';
import { isEmpty } from '@ember/utils';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { HumanCommentTypes } from 'embercom/models/data/inbox/renderable-classes';
import ENV from 'embercom/config/environment';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type InboxRedirectionService from 'embercom/services/inbox-redirection-service';
import type Inbox2ImagePreviewService from 'embercom/services/inbox2-image-preview-service';
import type CopilotApi from 'embercom/services/copilot-api';
import type Router from '@ember/routing/router-service';
import { type TableEntryHighlights } from 'embercom/objects/inbox/conversation-table-entry';
import { Channel } from 'embercom/models/data/inbox/channels';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  conversation: Conversation;
  isPreviewPanel: boolean;
  highlightedConversationPartSelectors?: string[];
  hideConversationEvents: boolean;
  highlights?: TableEntryHighlights;
  isTicketStream?: boolean;
  isReadOnly?: boolean;
  scrollDisabled?: boolean;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamComponent extends Component<Signature> {
  @service declare router: Router;
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare intercomEventService: any;
  @service declare copilotApi: CopilotApi;
  @service declare inboxRedirectionService: InboxRedirectionService;
  @service declare inbox2ImagePreviewService: Inbox2ImagePreviewService;

  @tracked completedInitialRender = false;
  @tracked renderedGroups: Set<string> = new Set();
  @dedupeTracked firstUnseenByAdminPartId?: string;
  @dedupeTracked lastConversationId?: number;
  @dedupeTracked lastKnownPartId?: string;
  @dedupeTracked lastConversationLoadingState?: boolean;
  @tracked scrollPending?: boolean;
  @tracked highlightedConversationPartId = '';
  @tracked imagePreviewUrl?: string;
  @tracked highlightedUserContentText = '';
  @tracked highlightedUserContentBoundingRect: DOMRect | undefined;

  @ref('container') container!: HTMLElement;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.highlightConversationPart();
  }

  // Because VerticalCollection uses PartGroups, this arg will only ensure that the
  // collection starts rendering from the top of the PartGroup that contains the part referenced in the URL.
  // Depending on where that part is in the group, it may not land in the scrollview.
  get idForFirstItem(): string | undefined {
    if (!this.highlightedConversationPartId) {
      return;
    }

    if (this.highlightedConversationPartId.startsWith('initial')) {
      return this.groupedParts[0].identifier;
    }
    let partId = this.highlightedConversationPartId.split('-')[2];
    let targetGroup = this.groupedParts.find((group) => {
      let firstEntityId = group.allParts[0].entityId;
      let lastEntityId = group.allParts[group.allParts.length - 1].entityId;
      return firstEntityId <= parseInt(partId, 10) && parseInt(partId, 10) <= lastEntityId;
    });
    return targetGroup?.identifier;
  }

  get isReadOnly() {
    if (!this.args.isReadOnly) {
      return false;
    }
    return this.args.isReadOnly;
  }

  get renderFromLast() {
    return !this.idForFirstItem || this.args.isPreviewPanel;
  }

  // Email messages are a lot more complex to render and might impact performance so set a lower buffer
  // https://github.com/intercom/intercom/issues/284346
  get bufferSize() {
    if (this.args.conversation.currentChannel === Channel.Email) {
      return 3;
    } else {
      return 5;
    }
  }

  @cached
  get groupedParts(): Array<PartGroup> {
    let conversation = this.args.conversation;
    let forcedSplits: string[] = [];
    if (this.firstUnseenByAdminPartId) {
      forcedSplits.push(this.firstUnseenByAdminPartId);
    }
    if (this.firstUnseenByUserPartId) {
      forcedSplits.push(this.firstUnseenByUserPartId);
    }

    let parts = conversation.renderableParts;
    let hiddenCategories: Array<PartGroupCategory> = [];
    if (this.args.hideConversationEvents) {
      hiddenCategories = [
        PartGroupCategory.Event,
        PartGroupCategory.Unknown,
        PartGroupCategory.TicketEvent,
      ];
    }

    return PartGroup.groupParts(conversation, parts, forcedSplits, hiddenCategories).reject(
      (x) => x.isEmpty,
    );
  }

  get isScrolledNearBottom(): boolean {
    let distanceFromBottom = this.container.scrollHeight - this.container.scrollTop;
    let buffer = 200;
    return distanceFromBottom - buffer < this.container.getBoundingClientRect().height;
  }

  get firstUnseenByUserPartId() {
    let { conversation } = this.args;
    if (!conversation.lastSeenByUserAt) {
      return;
    }

    let firstUnseenUserPart = conversation.renderableParts.find(
      (part) =>
        part.renderableType === RenderableType.AdminComment &&
        conversation.lastSeenByUserAt &&
        part.createdAt > conversation.lastSeenByUserAt,
    );

    return firstUnseenUserPart?.uuid;
  }

  get isNewSearch() {
    return this.router.currentRouteName?.startsWith('inbox.workspace.inbox.search');
  }

  handleUrlFragment(urlFragmentStr: string) {
    if (urlFragmentStr.startsWith('#')) {
      urlFragmentStr = urlFragmentStr.substring(1);
    }
    let fragments = urlFragmentStr.split(/&|\?/);
    for (let fragment of fragments) {
      let [key, value] = fragment.split('=');

      if (key === 'part_id' && value) {
        this.highlightedConversationPartId = value;
        this.intercomEventService.trackAnalyticsEvent({
          action: 'loaded',
          object: 'permalink',
          place: 'inbox',
        });
      }
    }
  }

  @action onConversationChanged() {
    if (this.lastConversationId !== this.args.conversation.id) {
      this.lastConversationId = this.args.conversation.id;
      this.scrollPending = true;
    }

    this.resetHighlightedConversationPart();
  }

  @action onConversationLoaded() {
    if (this.lastConversationLoadingState !== this.args.conversation.isLoading) {
      this.lastConversationLoadingState = this.args.conversation.isLoading;
      this.lastKnownPartId = this.args.conversation.lastPart?.uuid;
    }
  }

  @task({ keepLatest: true }) *onPartsChange() {
    let newParts = this.getNewParts();

    if (this.scrollPending && !this.args.conversation.isLoading) {
      this.scrollPending = false;
      this.scrollConversationToEnd('smooth');
    }
    if (!newParts.length) {
      return;
    }

    // If any of the new parts were written by the current admin, we scroll to the bottom.
    // We ignore non HumanCommentType parts (e.g. an assignment)
    if (
      newParts.some(
        (part) =>
          part.renderableData.creatingEntity.type === EntityType.Admin &&
          part.renderableData.creatingEntity.id === this.session.teammate.id,
      )
    ) {
      this.scrollConversationToEnd('smooth');
      this.firstUnseenByAdminPartId = undefined;
      this.lastKnownPartId = this.args.conversation.lastPart?.uuid;

      // Otherwise, if we're near the bottom of the page, just scroll to the end of the conversation.
    } else if (this.isScrolledNearBottom) {
      this.scrollConversationToEnd('smooth');

      // If we're not near the end of the conversation, we should ensure if a new comment part
      // has arrived that we show the "NEW" divider and show a referenece that the there are new
      // comments to be read.
    } else if (isEmpty(this.firstUnseenByAdminPartId)) {
      let newHumanCommentPart = newParts.find((part) => HumanCommentTypes.has(part.renderableType));

      if (newHumanCommentPart) {
        this.firstUnseenByAdminPartId = newHumanCommentPart.uuid;
      }
    }
    yield timeout(100);
  }

  /*
   * Given a previously known last part, this method finds the new parts which have been added to a conversation.
   * This information is used to determine where to place the "NEW" time divider in the stream, especially in
   * cases where many new parts are added at once.
   */
  private getNewParts(): Array<RenderablePart> {
    if (this.lastKnownPartId) {
      let lastKnownPart = this.args.conversation.renderableParts.find(
        (part) => part.uuid === this.lastKnownPartId,
      ) as RenderablePart;
      let lastKnownPartIndex = this.args.conversation.renderableParts.indexOf(lastKnownPart);

      return this.args.conversation.renderableParts.slice(
        lastKnownPartIndex + 1,
        this.args.conversation.renderableParts.length,
      );
    } else {
      return [];
    }
  }

  @action onStreamRendered() {
    if (this.highlightedConversationPartId) {
      // If there is a highlighted conversation part, VerticalCollection will start rendering the stream from the
      // beginning of the part group. If the group is several parts, the highlighted part may be off screen.
      // This scrolls it into view.
      this.scrollToConversationPart(this.highlightedConversationPartId);
    } else {
      this.scrollConversationToEnd('auto');
    }

    this.completedInitialRender = true;
    this.lastKnownPartId = this.args.conversation.lastPart?.uuid;
    // TODO: We need to refactor this so that this function is actually called when the stream is fully rendered
    if (this.args.highlightedConversationPartSelectors) {
      later(
        this,
        () => {
          let inboxSidebar = document.querySelector('.conversation-preview-panel-copilot-sidebar');
          let scrolled = false;
          if (inboxSidebar && this.args.highlightedConversationPartSelectors) {
            for (let dataPartId of this.args.highlightedConversationPartSelectors) {
              let element = inboxSidebar.querySelector(`[data-part-id="${dataPartId}"]`);
              if (element && !element.classList.contains('inbox-copilot-passage-highlight')) {
                element.classList.add('inbox-copilot-passage-highlight');
                if (!scrolled) {
                  scrolled = true;
                  element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
              }
            }
          }
        },
        500,
      ); // 500 milliseconds
    }
  }

  @action onStreamDestroyed() {
    this.completedInitialRender = false;
    this.firstUnseenByAdminPartId = undefined;
  }

  @action scrollConversationToEnd(behavior: ScrollBehavior = 'smooth') {
    // I believe requestAnimationFrame() is needed here because the DOM hasn't
    // yet been updated, so we don't yet know how big `this.container.scrollHeight`
    // will be. Postponing the logic to the next render frame lets us measure
    // scrollHeight and pass the correct value to `scrollTo()`.
    requestAnimationFrame(() => {
      // *However*, the `VerticalCollection` component also does its own
      // measuring and scrolling in this render frame. We have discovered (what
      // we think is) a bug in Safari/WebKit where if you perform an animated
      // scroll (which is what we do here) in the same render frame where you
      // change the div height (which is what `VerticalCollection` does as it
      // scrolls and renders new conversation parts), it gets confused and
      // blanks out the div. By postponing our scroll logic to yet *another*
      // render frame, we are putting our logic *after* VerticalCollection has
      // done its own measuring, scrolling, and height-changing. Our scroll
      // logic should then not cause any conflicts.
      // Original issue: https://github.com/intercom/intercom/issues/330648
      // PR: https://github.com/intercom/embercom/pull/81857
      requestAnimationFrame(() => {
        this.container?.scrollTo({ top: this.container.scrollHeight, behavior });
      });
    });
  }

  @action scrollToBottomIfClose() {
    if (this.isScrolledNearBottom) {
      this.scrollConversationToEnd();
    }
  }

  @action onViewLastPartGroup() {
    this.maybeMarkAsRead();
  }

  @action fadeIn(element: HTMLElement) {
    let groupId = element.getAttribute('data-part-group-id') as string;
    if (!this.renderedGroups.has(groupId)) {
      if (this.completedInitialRender) {
        element.classList.add('inbox2__fade-in');
      }
      this.renderedGroups.add(groupId);
    }
  }

  @action scrollToConversationPart(id: string) {
    requestAnimationFrame(() => {
      let conversationPart = this.container.querySelector(`[data-part-id="${id}"]`);

      if (conversationPart) {
        conversationPart.scrollIntoView({ behavior: 'auto', block: 'center' });
        taskFor(this.removeHighlightedConversationPart).perform();
      }
    });
  }

  @task
  *removeHighlightedConversationPart() {
    if (ENV.environment === 'test') {
      // Assertions won't run until this has settled. Then the highlight is removed and we cannot test for their presence.
      return;
    }

    yield timeout(5000);
    this.highlightedConversationPartId = '';
  }

  @action maybeMarkAsRead() {
    if (this.args.conversation.isRead) {
      return;
    }

    this.inboxState.markAsRead(this.args.conversation);
  }

  _trackScrollEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'scroll',
      object: 'conversation_stream',
      conversation_id: this.args.conversation.id,
      inbox_type: this.inboxState.activeInbox?.type,
    });
  }

  private resetHighlightedConversationPart() {
    if (this.isNewSearch) {
      this.highlightConversationPart();
      this.scrollToConversationPart(this.highlightedConversationPartId);
    }
  }

  highlightConversationPart() {
    if (this.inboxRedirectionService.anchorId) {
      window.location.hash = this.inboxRedirectionService.anchorId;
      this.inboxRedirectionService.unsetConversationPartAnchor();
    }

    if (window.location.hash) {
      this.handleUrlFragment(window.location.hash);
    }
  }

  @action handleClickInStream(event: MouseEvent) {
    let target = event.target as HTMLElement;

    let urlBehindLinkShield = target.dataset?.externalLinkUrl;

    if (!urlBehindLinkShield) {
      let parentWithLink = target.closest('a.external-link') as HTMLElement;
      urlBehindLinkShield = parentWithLink?.dataset?.externalLinkUrl;
    }
    if (urlBehindLinkShield) {
      event.preventDefault();
      safeWindowOpen(urlBehindLinkShield);
    }

    let imageSrc = target.getAttribute('src');

    if (target.classList.contains('js-inbox2-modal-opener') && imageSrc) {
      event.preventDefault();
      // We do not want the resized image to be shown in the preview modal
      let url = new URL(imageSrc);
      url.searchParams.delete('resize');
      this.inbox2ImagePreviewService.onPreviewImageClick(url.href);
    }
  }

  @action onDisplayEntryPoint(selectedText: string, selectionBoundingRect: DOMRect) {
    this.highlightedUserContentText = selectedText;
    this.highlightedUserContentBoundingRect = selectionBoundingRect;
  }

  @action onHideEntryPoint() {
    this.highlightedUserContentText = '';
    this.highlightedUserContentBoundingRect = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream': typeof Inbox2ConversationStreamComponent;
    'inbox2/conversation-stream': typeof Inbox2ConversationStreamComponent;
  }
}
