/* import __COLOCATED_TEMPLATE__ from './github-link.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type GithubLinkSummary from 'embercom/objects/inbox/github-link-summary';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    githubLink: GithubLinkSummary;
  };
}

const GithubLink = templateOnlyComponent<Signature>();
export default GithubLink;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::LinkedGithubIssues::GithubLink': typeof GithubLink;
    'inbox2/conversation-details-sidebar/sidebar-sections/linked-github-issues/github-link': typeof GithubLink;
  }
}
