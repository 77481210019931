/* import __COLOCATED_TEMPLATE__ from './conversation-header-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState } from 'embercom/objects/inbox/conversation';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import { type TableEntryHighlights } from 'embercom/objects/inbox/conversation-table-entry';

interface Args {
  conversation: Conversation;
  linkedTickets: LinkedTicketSummary[];
  highlights?: TableEntryHighlights;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationHeaderContentComponent extends Component<Signature> {
  @service declare session: Session;

  @tracked conversationId?: number;

  conversationState = ConversationState;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get ticketTypeNames() {
    return this.args.linkedTickets.map((ticket) => ticket.ticketType?.name).join(', ');
  }

  setConversationId() {
    this.conversationId = this.args.conversation.id;
  }

  get highlight(): string[] | undefined {
    return this.args.highlights?.title;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationHeaderContent': typeof Inbox2ConversationHeaderContentComponent;
    'inbox2/conversation-header-content': typeof Inbox2ConversationHeaderContentComponent;
  }
}
