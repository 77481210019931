/* import __COLOCATED_TEMPLATE__ from './new-divider.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface NewDividerSignature {
  Element: HTMLDivElement;
  Args: {};
}

const NewDivider = templateOnlyComponent<NewDividerSignature>();

export default NewDivider;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::NewDivider': typeof NewDivider;
  }
}
