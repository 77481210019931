/* import __COLOCATED_TEMPLATE__ from './forwarded.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Forwarded from 'embercom/objects/inbox/renderable/forwarded';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import LinkWithText from 'embercom/helpers/link-with-text';
import type Router from '@ember/routing/router-service';
import type InboxState from 'embercom/services/inbox-state';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import type InboxRedirectionService from 'embercom/services/inbox-redirection-service';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';

interface ForwardedPart extends RenderablePart {
  renderableData: Forwarded;
}

interface Args {
  part: ForwardedPart;
}

export default class ForwardedPartComponent extends Component<Args> {
  @service declare session: Session;
  @service declare router: Router;
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;
  @service declare inboxRedirectionService: InboxRedirectionService;

  get translationData() {
    let adminSummary = this.args.part.renderableData.adminSummary;
    let newConversationId = this.args.part.renderableData.newConversationId;

    let key = 'someone-forwarded-part';

    key =
      adminSummary && adminSummary.id === this.session.teammate.id
        ? 'you-forwarded-part'
        : 'admin-forwarded-part';

    let baseUrl = window.location.origin;

    let inbox = this.inboxState.activeInbox || {
      id: InboxType.All,
      category: InboxCategory.Shared,
    };

    let conversationUrl = this.router.urlFor(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      inbox.category,
      inbox.id,
      newConversationId,
    );

    let data = {
      // @ts-ignore helper doesn't seem to be typed correctly
      conversationLink: LinkWithText.create(this).compute_with_href(
        baseUrl + conversationUrl,
        this.intl.t('inbox.forwarded.new-conversation'),
      ),
      admin:
        adminSummary &&
        // @ts-ignore helper doesn't seem to be typed correctly
        LinkWithText.create(this).compute([
          'apps.app.admins.admin',
          adminSummary.id,
          adminSummary.name,
        ]),
    };

    return { key, data };
  }

  @action
  onLinkClick() {
    window.location.hash = `part_id=${this.args.part.renderableData.forwardedMessageId}`;
    location.reload;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::Forwarded': typeof ForwardedPartComponent;
    'inbox2/conversation-stream/event-parts/forwarded': typeof ForwardedPartComponent;
  }
}
