/* import __COLOCATED_TEMPLATE__ from './outbound-email.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Email from 'embercom/objects/inbox/renderable/email';
import { OutboundInitialPartSenderType } from 'embercom/objects/inbox/outbound-initial-part-sender-types';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { Channel } from 'embercom/models/data/inbox/channels';
import type Conversation from 'embercom/objects/inbox/conversation';

interface OutboundEmailRenderablePart extends RenderablePart {
  renderableData: Email;
}

interface OutboundEmailPartGroup extends PartGroup {
  parts: Array<OutboundEmailRenderablePart>;
}

interface Args {
  partGroup: OutboundEmailPartGroup;
  conversation: Conversation;
  isReadOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamPartGroupsOutboundEmailComponent extends Component<Signature> {
  @service declare session: Session;
  senderTypes = OutboundInitialPartSenderType;
  emailChannel = Channel.Email;

  get renderableData() {
    return this.args.partGroup.parts[0].renderableData;
  }

  get isDeleted() {
    return this.renderableData.deletedAt;
  }

  get sender() {
    return this.isDeleted && !!this.renderableData.adminSummary
      ? this.renderableData.adminSummary
      : this.renderableData.senderSummary;
  }

  get senderIsTeammate() {
    return this.isDeleted || this.renderableData.senderType === OutboundInitialPartSenderType.Admin;
  }

  get senderIsCustomEmailAddress() {
    return this.renderableData.senderType === OutboundInitialPartSenderType.CustomEmailAddress;
  }

  get lastPartInGroup() {
    let { parts } = this.args.partGroup;
    return parts[parts.length - 1];
  }

  get lastPartSeenByUser() {
    let { conversation } = this.args;

    return (
      conversation?.lastSeenByUserAt &&
      conversation?.lastSeenByUserAt >= this.lastPartInGroup.createdAt
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::OutboundEmail': typeof Inbox2ConversationStreamPartGroupsOutboundEmailComponent;
    'inbox2/conversation-stream/part-groups/outbound-email': typeof Inbox2ConversationStreamPartGroupsOutboundEmailComponent;
  }
}
