/* import __COLOCATED_TEMPLATE__ from './cti-call-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type CtiService from 'embercom/services/cti-service';
import type User from 'embercom/objects/inbox/user';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Args {
  user: User;
  conversation: Conversation;
}

interface Signature {
  Args: Args;
}

export default class CtiCallButton extends Component<Signature> {
  @service declare ctiService: CtiService;

  @action async onClick() {
    if (!this.args.user) {
      return;
    }

    this.ctiService.showAppContainer();
    this.ctiService.escalateChat(this.phoneNumber, this.args.conversation.id);
  }

  get phoneNumber() {
    return this.args.user?.attributes.phone as string;
  }

  get isGroupConversation() {
    return this.args.conversation.participantSummaries.length > 1;
  }

  get ctiAppAvailable() {
    return this.ctiService.ctiAvailable;
  }

  get initiatedCall() {
    return this.ctiService.initiatedCall;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::Header::CtiCallButton': typeof CtiCallButton;
    'inbox2/conversation-stream/header/cti-call-button': typeof CtiCallButton;
  }
}
