/* import __COLOCATED_TEMPLATE__ from './company-search-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Resource, type Named } from 'ember-resources';
import { useResource } from 'ember-resources';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type CompanySummary from 'embercom/objects/inbox/company-summary';
import type InboxSearchSuggestionsService from 'embercom/services/inbox-search-suggestions-service';
import { type SearchDropdownArgs, type SearchDropdownSignature } from './search-dropdown';

interface CompanySearchResourceArgs {
  searchTerm: string;
}

class CompanySearchResource extends Resource<Named<CompanySearchResourceArgs>> {
  @service declare inboxSearchSuggestionsService: InboxSearchSuggestionsService;
  @tracked results: Array<CompanySummary> = [];

  searchTerm: string;

  get isLoading() {
    return taskFor(this.search).isRunning;
  }

  constructor(owner: any, args: Named<CompanySearchResourceArgs>, previous: CompanySearchResource) {
    super(owner, args, previous);

    this.searchTerm = args.named.searchTerm;

    taskFor(this.search).perform(args.named.searchTerm);
  }

  @task({ restartable: true })
  *search(searchTerm: string) {
    this.results = [];

    let companies: CompanySummary[] =
      yield this.inboxSearchSuggestionsService.getCompanySuggestions(
        searchTerm,
        10,
        [],
        'contains',
      );
    this.results = companies;
  }
}

type Args<T> = Pick<
  SearchDropdownArgs<T>,
  | 'onEscape'
  | 'searchPlaceholder'
  | 'popoverPlacement'
  | 'fallbackPopoverPlacements'
  | 'onPopoverShow'
  | 'onPopoverHide'
  | 'initiallyOpen'
  | 'size'
>;

interface Signature<T> {
  Element: HTMLDivElement;
  Args: Args<T>;
  Blocks: {
    opener: [];
    placeholder: [];
    dataRow: SearchDropdownSignature<CompanySummary>['Blocks']['dataRow'];
    footer: SearchDropdownSignature<T>['Blocks']['footer'];
  };
}

export default class CompanySearchDropdown<T> extends Component<Signature<T>> {
  @tracked query = '';

  @tracked companySearchData = useResource(this, CompanySearchResource, () => ({
    searchTerm: this.query,
  }));

  @action onQueryChanged(query: string) {
    this.query = query;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::CompanySearchDropdown': typeof CompanySearchDropdown;
    'inbox2/common/company-search-dropdown': typeof CompanySearchDropdown;
  }
}
