/* import __COLOCATED_TEMPLATE__ from './email-attribute-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';

interface Args {
  email: string;
  emailIdentifiesUser: boolean;
  conversationId: number;
  activeInbox: Inbox;
  isDisabled: boolean;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class Inbox2SidebarEmailAttributeTooltip extends Component<Signature> {
  get emailParts() {
    return this.args.email?.split('@');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::EmailAttributeTooltip': typeof Inbox2SidebarEmailAttributeTooltip;
    'inbox2/conversation-details-sidebar/sidebar-sections/email-attribute-tooltip': typeof Inbox2SidebarEmailAttributeTooltip;
  }
}
