/* import __COLOCATED_TEMPLATE__ from './navigation-hint.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

type NavigationKey = 'up' | 'down' | 'left' | 'right';

interface Args {
  isHome?: boolean;
  isEmojiPane?: boolean;
  navigationKeys?: NavigationKey[];
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class Inbox2CommandKNavigationHint extends Component<Signature> {
  @service declare intl: IntlService;

  get navigationKeys() {
    let hints = {
      up: this.intl.t('inbox.command-k.hints.up-arrow'),
      down: this.intl.t('inbox.command-k.hints.down-arrow'),
      left: this.intl.t('inbox.command-k.hints.left-arrow'),
      right: this.intl.t('inbox.command-k.hints.right-arrow'),
    };

    if (this.args.navigationKeys) {
      return this.args.navigationKeys.map((key) => hints[key]);
    } else {
      return ['up', 'down'].map((key: NavigationKey) => hints[key]);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::NavigationHint': typeof Inbox2CommandKNavigationHint;
    'inbox2/command-k/navigation-hint': typeof Inbox2CommandKNavigationHint;
  }
}
