/* import __COLOCATED_TEMPLATE__ from './ticket-state-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type InboxState from 'embercom/services/inbox-state';
import type TicketStateService from 'embercom/services/ticket-state-service';

interface Args {
  conversation: Conversation;
  changeTicketState?: (conversation: Conversation, ticketState: TicketSystemState) => unknown;
}

interface Signature {
  Args: Args;
}

interface TicketStateOption {
  id: TicketSystemState;
  text: string;
  isSelected?: boolean;
}

export default class TicketStateButton extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare ticketStateService: TicketStateService;

  get selectedTicketStateOption() {
    return this.ticketStateOptions.find(
      (ticketStateOption) => ticketStateOption.id === this.selectedTicketState,
    );
  }

  get selectedTicketState() {
    return this.args.conversation.ticketState;
  }

  get ticketStateOptions(): TicketStateOption[] {
    let ticketStateOptions = this.visibleTicketStateOptions;
    ticketStateOptions.push({
      text: this.intl.t('inbox.ticket-state.submitted'),
      id: TicketSystemState.Submitted,
      isSelected: this.selectedTicketState === TicketSystemState.Submitted,
    } as TicketStateOption);
    return ticketStateOptions;
  }

  get visibleTicketStateOptions(): TicketStateOption[] {
    return [
      {
        text: this.intl.t('inbox.ticket-state.in_progress'),
        id: TicketSystemState.InProgress,
        isSelected: this.selectedTicketState === TicketSystemState.InProgress,
      },
      {
        text: this.intl.t('inbox.ticket-state.waiting_on_customer'),
        id: TicketSystemState.WaitingOnCustomer,
        isSelected: this.selectedTicketState === TicketSystemState.WaitingOnCustomer,
      },
      {
        text: this.intl.t('inbox.ticket-state.resolved'),
        id: TicketSystemState.Resolved,
        isSelected: this.selectedTicketState === TicketSystemState.Resolved,
      },
    ];
  }

  @action async onTicketStateChange(selectedOption: TicketStateOption) {
    if (selectedOption.id !== this.args.conversation.ticketState) {
      this.args.conversation.ticketState = selectedOption.id;
      if (this.args.changeTicketState) {
        await this.args.changeTicketState(this.args.conversation, selectedOption.id);
      } else {
        let newState = this.ticketStateService.getFirstTicketCustomStatesForSystemState(
          selectedOption.id,
        );
        await this.inboxState.changeTicketState(this.args.conversation, newState);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::Header::TicketStateButton': typeof TicketStateButton;
    'inbox2/conversation-stream/header/ticket-state-button': typeof TicketStateButton;
  }
}
