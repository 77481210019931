/* import __COLOCATED_TEMPLATE__ from './fin-animation.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import FinWavingLottieJSON from 'embercom/lib/lottie-animation-data/Fin-Header-Greeting-WithArm.json';
import FinSmilingLottieJSON from 'embercom/lib/lottie-animation-data/Fin-Header-Joy-Lottie.json';
import FinLookingUpwardLottieJSON from 'embercom/lib/lottie-animation-data/Fin-Header-LookUpward-Lottie.json';
import FinThinkingLottieJSON from 'embercom/lib/lottie-animation-data/Fin-Header-Pivoting-Lottie.json';

interface Signature {
  Args: {
    shouldLoop?: boolean;
    isSmallAnimation?: boolean;
    finAnimation: string;
  };
  Element: HTMLDivElement;
}

export default class FinAnimation extends Component<Signature> {
  animationDataHash: Record<string, any> = {
    finWaving: FinWavingLottieJSON,
    finSmiling: FinSmilingLottieJSON,
    finThinking: FinThinkingLottieJSON,
    finLookingUpward: FinLookingUpwardLottieJSON,
  };
  animationData = this.animationDataHash[this.args.finAnimation];
  animationContainerId = 'fin-waving-animation-container';
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::FinAnimation': typeof FinAnimation;
    'inbox2/copilot/fin-animation': typeof FinAnimation;
  }
}
