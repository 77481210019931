/* import __COLOCATED_TEMPLATE__ from './preview-pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const PreviewPane = templateOnlyComponent<Signature>();
export default PreviewPane;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::TriggerWorkflowConnectorAction::PreviewPane': typeof PreviewPane;
    'inbox2/command-k/trigger-workflow-connector-action/preview-pane': typeof PreviewPane;
  }
}
