/* import __COLOCATED_TEMPLATE__ from './relationship-attribute-descriptor-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const RelationshipAttributeDescriptorItem = templateOnlyComponent<Signature>();
export default RelationshipAttributeDescriptorItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::RelationshipAttributeDescriptorItem': typeof RelationshipAttributeDescriptorItem;
    'inbox2/conversation-details-sidebar/sidebar-sections/relationship-attribute-descriptor-item': typeof RelationshipAttributeDescriptorItem;
  }
}
