/* import __COLOCATED_TEMPLATE__ from './table-container.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';

interface Args {
  title: string;
  isFullscreen: boolean;
  toggleFullscreen: Function;
  displayEmptyState: Function;
}

interface Signature {
  Args: Args;
}

export default class TableContainer extends Component<Signature> {
  requestOrExitFullscreen(element: HTMLElement, [isTableFullscreen]: [boolean]) {
    let document = element.ownerDocument;

    if (isTableFullscreen) {
      element.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::TableContainer': typeof TableContainer;
    'inbox2/dashboard/table-container': typeof TableContainer;
  }
}
