/* import __COLOCATED_TEMPLATE__ from './video-call-participants.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';

import type UserSummary from 'embercom/objects/inbox/user-summary';
import { inject as service } from '@ember/service';
import type VideoCallService from 'embercom/services/video-call-service';

interface Args {
  user: UserSummary | undefined;
  isCameraOn: boolean;
  isUserCameraOn: boolean;
  isScreenSharingOn: boolean;
  isExpandedView: boolean;
}

export default class VideoCallParticipants extends Component<Args> {
  @service declare videoCallService: VideoCallService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.videoCallService.addParticipantTracksToDisplay();
  }

  get defaultSizes() {
    if (!this.args.isExpandedView) {
      return 'h-32 w-40';
    }

    return 'w-full h-full max-h-96 max-w-96';
  }

  get userVideoClasses() {
    if (!this.args.isUserCameraOn) {
      return '';
    }

    if (!this.args.isScreenSharingOn) {
      return 'w-full h-64';
    }

    return this.defaultSizes;
  }

  get agentVideoClasses() {
    if (!this.args.isCameraOn) {
      return '';
    }

    if (!this.args.isScreenSharingOn) {
      return 'absolute bottom-3 right-3 w-24 h-20 z-20';
    }

    return this.defaultSizes;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Calling::VideoCallParticipants': typeof VideoCallParticipants;
    'inbox2/calling/video-call-participants': typeof VideoCallParticipants;
  }
}
