/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import type AssignToAction from 'embercom/objects/inbox/command-k/assign-to';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { type Icon } from 'embercom/objects/inbox/command-k/icons';

interface Args {
  actionItem: ChildAction;
  selected: any;
  currentAction?: AssignToAction;
}

interface Signature {
  Args: Args;
}

export default class CreateTicketOption extends Component<Signature> {
  @service declare session: Session;

  get showCategoryInformation() {
    return this.args.actionItem.value?.newTicket;
  }

  get isBackOfficeTicket() {
    return this.args.actionItem.value.category === TicketCategory.Task;
  }

  get isCustomerTicket() {
    return this.args.actionItem.value.category === TicketCategory.Request;
  }

  get isTrackerTicket() {
    return this.args.actionItem.value.category === TicketCategory.Tracker;
  }

  get isIconType() {
    let iconType = typeof this.args.actionItem.icon;
    return iconType !== 'string' && (this.args.actionItem.icon as Icon).type === 'icon';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Ticket::Option': typeof CreateTicketOption;
    'inbox2/command-k/ticket/option': typeof CreateTicketOption;
  }
}
