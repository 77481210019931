/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';

interface Signature {
  Args: {
    actionItem: ChildAction;
    selected?: any;
  };
}

const Option = templateOnlyComponent<Signature>();
export default Option;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::InsertAttribute::Option': typeof Option;
    'inbox2/command-k/insert-attribute/option': typeof Option;
  }
}
