/* import __COLOCATED_TEMPLATE__ from './tooltip-with-hotkey.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';
import { type Hotkey } from 'embercom/services/inbox-hotkeys';

interface Signature {
  Element: HTMLElement;
  Args: {
    label: string;
    hotkey: Hotkey | { display: string[] };
    isDisabled?: boolean;
  };
  Blocks: { default: [any] };
}

const TooltipWithHotkey = templateOnlyComponent<Signature>();
export default TooltipWithHotkey;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::TooltipWithHotkey': typeof TooltipWithHotkey;
  }
}
