/* import __COLOCATED_TEMPLATE__ from './conversation-sla-removed.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type ConversationSlaRemoved from 'embercom/objects/inbox/renderable/conversation-sla-removed';
import LinkWithText from 'embercom/helpers/link-with-text';

interface ConversationSlaRemovedPart extends RenderablePart {
  renderableData: ConversationSlaRemoved;
}

interface Args {
  part: ConversationSlaRemovedPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsConversationSlaRemovedComponent extends Component<Signature> {
  @service declare session: Session;

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get renderableData() {
    return this.args.part.renderableData;
  }

  get teammate() {
    return this.renderableData.adminSummary;
  }

  get translationData() {
    if (this.teammate.id === this.session.teammate.id) {
      return {
        key: 'you-removed-sla',
        data: {
          sla: this.renderableData.conversationSla.label,
        },
      };
    } else {
      return {
        key: 'admin-removed-sla',
        data: {
          who: this.linkWithTextHelper.compute([
            'apps.app.admins.admin',
            this.teammate.id,
            this.teammate.name,
          ]),
          sla: this.renderableData.conversationSla.label,
        },
      };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::ConversationSlaRemoved': typeof Inbox2ConversationStreamEventPartsConversationSlaRemovedComponent;
    'inbox2/conversation-stream/event-parts/conversation-sla-removed': typeof Inbox2ConversationStreamEventPartsConversationSlaRemovedComponent;
  }
}
