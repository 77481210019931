/* import __COLOCATED_TEMPLATE__ from './conversation-state.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import TeamSummary from 'embercom/objects/inbox/team-summary';
import type Session from 'embercom/services/session';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { SlaStatus } from 'embercom/objects/inbox/conversation-sla-state';
import type LbaMetricsService from 'embercom/services/lba-metrics-service';
import { LbaTriggerEvent } from 'embercom/services/lba-metrics-service';

interface Args {
  conversation: Conversation;
  onAdminUpdate: () => unknown;
  onTeamUpdate: () => unknown;
  onSelectTicketType: () => unknown;
  setTicketTypeToLinkToConversation: (ticketTypeId?: number) => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class ConversationState extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare lbaMetricsService: LbaMetricsService;

  get assigneeTooltipKey() {
    return `inbox.conversation-details.tooltips.assign-${
      this.args.conversation?.isTicket ? 'ticket' : 'conversation'
    }`;
  }

  get currentSlaLabel() {
    if (this.validSlaStates.length === 0) {
      return `inbox.conversation-details.slas.sla.short.due`;
    }

    return this.validSlaStates[0]?.shortLabel || `inbox.conversation-details.slas.sla.short.due`;
  }

  get showSlaAttribute() {
    return (
      this.args.conversation.nextBreachTime !== undefined ||
      this.validSlaStates.some((state) => state.status === SlaStatus.Paused)
    );
  }

  get hideSlaTooltip() {
    return this.validSlaStates.length === 0;
  }

  get validSlaStates() {
    let slaStates = this.args.conversation?.conversationSlaStates || [];
    return slaStates.filter(
      (state) => state.nextBreachAt !== undefined || state.status === SlaStatus.Paused,
    );
  }

  @action commandKAssignConversation(assignee: AdminWithPermissions | AdminSummary | TeamSummary) {
    if (assignee instanceof TeamSummary) {
      this.inboxState.assignConversationToTeam(this.args.conversation, assignee, 'command_k');
    } else {
      this.inboxState.assignConversationToAdmin(this.args.conversation, assignee, 'command_k');
    }

    this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(
      LbaTriggerEvent.ASSIGNMENT,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::ConversationState': typeof ConversationState;
    'inbox2/conversation-details-sidebar/conversation-state': typeof ConversationState;
  }
}
