/* import __COLOCATED_TEMPLATE__ from './segments.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
import type Conversation from 'embercom/objects/inbox/conversation';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import type User from 'embercom/objects/inbox/user';
import type Segment from 'embercom/objects/inbox/segment';
import { request } from 'embercom/lib/inbox/requests';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';

type Args = SidebarSectionComponentArgs<{
  conversation: Conversation;
  isLoadingUser: boolean;
  sidebarSection: SidebarSection;
  user: User;
  name: string;
  isOpen: boolean;
  onOpenSectionChange: (sectionId: number | string) => {};
  isExpanded: boolean;
  onExpandedSectionChange: (sectionId: number | string) => {};
  showCompanySegments?: boolean;
  isPreviewingConversation?: boolean;
}>;

interface Signature {
  Args: Args;
}

export default class Segments extends Component<Signature> {
  @service declare session: Session;

  @tracked segments: Segment[] = [];
  @tracked userId?: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.user) {
      this.setUserId();
      taskFor(this.loadSegments).perform();
    }
  }

  get isLoadingSegments() {
    return taskFor(this.loadSegments).isRunning;
  }

  @action onUserChange() {
    if (this.args.user && this.userId !== this.args.user.id) {
      this.segments = [];
      taskFor(this.loadSegments).perform();
      this.setUserId();
    }
  }

  get limitedUserSegments() {
    return this.segments.slice(0, 5);
  }

  @task
  *loadSegments(): TaskGenerator<void> {
    if (this.args.showCompanySegments) {
      yield taskFor(this.loadCompanySegments).perform();
    } else {
      yield taskFor(this.loadUserSegments).perform();
    }
  }

  @task
  *loadUserSegments(): TaskGenerator<void> {
    let response = yield request(
      `/ember/segments/for_user?app_id=${this.session.workspace.id}&user_id=${this.args.user.id}`,
    );
    let { segments } = (yield response.json()) as { segments: Segment[] };
    this.segments = segments;
  }

  @task
  *loadCompanySegments(): TaskGenerator<void> {
    let companyIds = this.args.user.companies?.map((company) => company.id).join(',');
    if (!companyIds) {
      this.segments = [];
      return;
    }

    let response = yield request(
      `/ember/segments/for_companies?app_id=${this.session.workspace.id}&company_ids=${companyIds}`,
    );
    let result = (yield response.json()) as Record<string, Segment[]>;
    let allSegments = Object.values(result).flat();
    this.segments = Array.from(
      allSegments.reduce((map, segment) => map.set(segment.id, segment), new Map()).values(),
    );
  }

  setUserId() {
    this.userId = this.args.user?.id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Segments': typeof Segments;
    'inbox2/conversation-details-sidebar/sidebar-sections/segments': typeof Segments;
  }
}
