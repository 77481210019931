/* import __COLOCATED_TEMPLATE__ from './team-and-teammate-selector.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { partition } from 'underscore';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'embercom/services/intl';
import { ProfilePictureIcon } from 'embercom/objects/inbox/command-k/icons';
import { type CopilotSourceTypes } from 'embercom/lib/inbox2/copilot/types';

interface Args {
  selectedAssigneeIds: number[];
  onSelectionChange: (selection: CopilotSourceTypes[]) => void;
}

interface Signature {
  Args: Args;
}

export default class TeamAndTeammateSelector extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  @tracked allTeams: TeamSummary[] = [];
  @tracked selectedTeamIds: number[] = [];

  @tracked allTeammates: AdminSummary[] = [];
  @tracked selectedTeammateIds: number[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadTeamsAndTeammates).perform();
  }

  get workspace() {
    return this.session.workspace;
  }

  get teams() {
    let teamsForDropdown = this.allTeams
      .filter((team) => !team.isUnassignedAssignee)
      .map((team) => ({
        text: team.name,
        value: team.id,
        component: 'inbox2/dashboard/icon-and-name-dropdown-item',
        componentAttrs: {
          displayName: team.name,
          icon: team.icon,
        },
        isFilterable: true,
        isSelected: this.args.selectedAssigneeIds.includes(team.id),
      }));

    let [selectedTeams, unselectedTeams] = partition(
      teamsForDropdown,
      ({ isSelected }) => isSelected,
    );

    return [
      {
        heading: this.intl.t(
          'inbox.dashboard.teammate-activity.team-selector.selected-teams-label',
        ),
        items: selectedTeams,
      },
      {
        heading: this.intl.t('inbox.dashboard.teammate-activity.team-selector.members-of-label'),
        items: unselectedTeams,
      },
    ];
  }

  get teamIdsFromAdminIds() {
    return this.allTeams
      .filter(({ id }) => this.args.selectedAssigneeIds.includes(id))
      .map(({ id }) => id);
  }

  get teamsLabel() {
    let selectedTeamsLength = this.teams[0].items.length;

    return this.intl.t('inbox.dashboard.teammate-activity.team-selector.label', {
      hasSelectedTeams: selectedTeamsLength > 0,
      selectedTeamsCount: selectedTeamsLength,
    });
  }

  get teammates() {
    let allTeammates = this.allTeammates
      .filter((teammate) => !teammate.isUnassignedAssignee && teammate.name !== 'Operator')
      .map((teammate) => ({
        text: teammate.name,
        value: teammate.id,
        component: 'inbox2/dashboard/icon-and-name-dropdown-item',
        componentAttrs: {
          displayName: teammate.name,
          icon: new ProfilePictureIcon(teammate.imageURL),
        },
        isFilterable: true,
        assignee: teammate,
        isSelected: this.args.selectedAssigneeIds.includes(teammate.id),
      }));

    let [selectedTeammates, unselectedTeammates] = partition(
      allTeammates,
      ({ isSelected }) => isSelected,
    );

    return [
      {
        heading: this.intl.t(
          'inbox.dashboard.teammate-activity.teammate-selector.selected-teammates-label',
        ),
        items: selectedTeammates,
      },
      {
        heading: this.intl.t('inbox.dashboard.teammate-activity.teammate-selector.teammates-label'),
        items: unselectedTeammates,
      },
    ];
  }

  get selectedAssigneeIds() {
    return this.selectedTeamIds.concat(this.selectedTeammateIds);
  }

  get teammateIdsFromAdminIds() {
    return this.allTeammates
      .filter(({ id }) => this.args.selectedAssigneeIds.includes(id))
      .map(({ id }) => id);
  }

  get teammatesLabel() {
    let selectedTeammatesLength = this.teammates[0].items.length;

    return this.intl.t('inbox.dashboard.teammate-activity.teammate-selector.label', {
      hasSelectedTeammates: selectedTeammatesLength > 0,
      selectedTeammatesCount: selectedTeammatesLength,
    });
  }

  @task({ drop: true })
  *loadTeamsAndTeammates() {
    this.allTeams = yield this.session.workspace.fetchTeamAssignees();
    this.selectedTeamIds = this.teamIdsFromAdminIds;

    this.allTeammates = yield this.session.workspace.fetchAdminAssignees();
    this.selectedTeammateIds = this.teammateIdsFromAdminIds;
  }

  @action changeTeamsSelection(newSelectedTeamIds: number[]) {
    this.selectedTeamIds = newSelectedTeamIds;
    this.args.onSelectionChange(this.selectedAssigneeIds);
  }

  @action changeTeammatesSelection(newSelectedTeammateIds: number[]) {
    this.selectedTeammateIds = newSelectedTeammateIds;
    this.args.onSelectionChange(this.selectedAssigneeIds);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Teammates::TeamAndTeammateSelector': typeof TeamAndTeammateSelector;
    'inbox2/dashboard/teammates/team-and-teammate-selector': typeof TeamAndTeammateSelector;
  }
}
