/* import __COLOCATED_TEMPLATE__ from './confirm-remove-sla-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    workspaceID: string | number;
    onModalClose: () => void;
    onConfirm: (e: Event) => void;
    onClose: () => void;
  };
}

const ConfirmRemoveSlaModal = templateOnlyComponent<Signature>();
export default ConfirmRemoveSlaModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConfirmRemoveSlaModal': typeof ConfirmRemoveSlaModal;
    'inbox2/confirm-remove-sla-modal': typeof ConfirmRemoveSlaModal;
  }
}
