/* import __COLOCATED_TEMPLATE__ from './tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type PopoverArgs } from '@intercom/pulse/template-registry';

interface Signature {
  Element: HTMLElement;
  Args: PopoverArgs & { delayLength?: number };
  Blocks: {
    opener: [];
    content: [];
  };
}

const Tooltip = templateOnlyComponent<Signature>();
export default Tooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Tooltip': typeof Tooltip;
    'inbox2/common/tooltip': typeof Tooltip;
  }
}
