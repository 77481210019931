/* import __COLOCATED_TEMPLATE__ from './suggested-snackbar.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { type Notification } from 'embercom/services/snackbar';
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  notification: Notification;
}

interface Signature {
  Args: Args;
}

const SuggestedSnackbar = templateOnlyComponent<Signature>();
export default SuggestedSnackbar;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::FinQiestionAnswerModal::SuggestedSnackbar': typeof SuggestedSnackbar;
    'inbox2/fin-question-answer-modal/suggested-snackbar': typeof SuggestedSnackbar;
  }
}
