/* import __COLOCATED_TEMPLATE__ from './toolbar-ai-assist-option.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type NestedDropdownOption } from 'embercom/components/inbox2/common/nested-dropdown';
import { EMBERCOM_PROSEMIRROR_COMPOSER_ALLOW_MOUSEDOWN_CLASS } from '@intercom/embercom-prosemirror-composer/components/editor-api/constants';

interface Signature {
  Args: {
    option: NestedDropdownOption;
    isSelected: boolean;
  };
  Element: HTMLDivElement;
}

export default class ToolbarAiAssistOption extends Component<Signature> {
  readonly allowMousedownClassNames = EMBERCOM_PROSEMIRROR_COMPOSER_ALLOW_MOUSEDOWN_CLASS;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::ToolbarAiAssistOption': typeof ToolbarAiAssistOption;
    'inbox2/composer/toolbar-ai-assist-option': typeof ToolbarAiAssistOption;
  }
}
