/* import __COLOCATED_TEMPLATE__ from './gifs-action.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import { type GifSearchEntry } from 'embercom/objects/inbox/command-k/gifs';
import { type DisplayContext } from 'embercom/services/command-k';

interface Args {
  composerApi?: ComposerPublicAPI;
  priority?: number;
  trackingData?: Record<string, unknown>;
  activatingComponent?: `${DisplayContext}`;
}

interface Signature {
  Args: Args;
}

export default class GifsAction extends Component<Signature> {
  @action insertGif(gif: GifSearchEntry) {
    let block = {
      type: 'image',
      url: gif.url,
      width: gif.width,
      height: gif.height,
      text: gif.title,
    };

    this.args.composerApi?.composer.commands.focus();
    this.args.composerApi?.composer.commands.insertBlock(block);
    // Focusing composer immediately after inserting a gif, selects the gif in composer.
    // Since we don't want that behaviour, we add a new line to fix it.
    this.args.composerApi?.composer.commands.insertNewlineAndSelect();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::CommandK::GifsAction': typeof GifsAction;
    'inbox2/composer/command-k/gifs-action': typeof GifsAction;
  }
}
