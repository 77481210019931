/* import __COLOCATED_TEMPLATE__ from './ticket-description.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import type Conversation from 'embercom/objects/inbox/conversation';
import { NewConversation } from 'embercom/objects/inbox/conversation';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import { type TicketDescriptor } from 'embercom/objects/inbox/ticket';
import TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';

type Args = SidebarSectionComponentArgs<{
  conversation: Conversation;
  isPreviewingConversation?: boolean;
}>;

interface Signature {
  Args: Args;
}

export default class TicketDescription extends Component<Signature> {
  get descriptionDescriptor() {
    if (this.args.conversation instanceof NewConversation) {
      return;
    }

    return this.args.conversation.ticketType?.descriptors?.find(
      (descriptor: TicketDescriptor) => descriptor.name === '_default_description_',
    );
  }

  get conversationAttributesById() {
    if (this.args.conversation instanceof NewConversation) {
      return {};
    }

    return this.args.conversation.attributes.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  get descriptionAttribute() {
    if (!this.descriptionDescriptor) {
      return;
    }

    return (
      this.conversationAttributesById[this.descriptionDescriptor.id] ??
      new TicketAttributeSummary('', this.descriptionDescriptor)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::TicketDescription': typeof TicketDescription;
    'inbox2/conversation-details-sidebar/sidebar-sections/ticket-description': typeof TicketDescription;
  }
}
