/* import __COLOCATED_TEMPLATE__ from './ticket-attributes.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import type InboxApi from 'embercom/services/inbox-api';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
// @ts-ignore
import intermoji from '@intercom/intermoji';

interface Args {
  conversation: Conversation;
}

interface Signature {
  Args: Args;
}

export default class TicketAttributes extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare session: Session;
  @service declare intl: IntlService;

  @trackedReset('args.conversation.id') ticketTypeId: number | undefined;

  get visibleTicketAttributeDescriptors() {
    return this.args.conversation.ticketType?.descriptors?.filter(
      (descriptor) =>
        descriptor.isListedAsAttribute &&
        (!descriptor.archived || this.conversationAttributesById[descriptor.id]?.value),
    );
  }

  get conversationAttributesById() {
    return this.args.conversation.attributes.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  get emojiUrl(): string {
    return intermoji.twemojiSVGUri(this.args.conversation?.ticketType?.emoji ?? '');
  }

  get createdAtTranslationKey() {
    let translationPrefix = 'inbox.conversation-details';
    let translationSuffix = 'submission-date';

    return `${translationPrefix}.${translationSuffix}`;
  }

  @action async onUpdateTicketAttribute(attribute: ConversationAttributeSummary) {
    if (this.ticketTypeId || !attribute.isUpdated) {
      return;
    }

    return this.inboxApi.updateAttribute(this.args.conversation?.id, attribute);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::TicketAttributes': typeof TicketAttributes;
    'inbox2/conversation-details-sidebar/ticket-attributes': typeof TicketAttributes;
  }
}
