/* import __COLOCATED_TEMPLATE__ from './preview-pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import type Macro from 'embercom/objects/inbox/macro';
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';

interface Signature {
  Element: HTMLDivElement;
  Args: { item: ChildAction<Macro> };
}

export default class PreviewPane extends Component<Signature> {
  @service declare session: Session;

  get hasAttachements() {
    return this.args.item.value.blocks.some((block) => block.type === 'attachmentList');
  }
  get canSeeNewSettings() {
    return this.session.workspace.isFeatureEnabled('team-activation-channels-new-ia-settings-hub');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::UseMacro::PreviewPane': typeof PreviewPane;
  }
}
