/* import __COLOCATED_TEMPLATE__ from './mentions-filters.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { InboxMentionsStatus } from 'embercom/models/data/inbox/inbox-filters';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';

interface Arguments {
  selectedMentionsStatus?: InboxMentionsStatus;
  onMentionsStatusChanged?: (status: InboxMentionsStatus) => void;
  selectedLabelPrefix?: string;
}

interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}

export default class Inbox2ConversationsTableMentionsFilters extends Component<Signature> {
  @service declare intl: IntlService;

  get items() {
    this.intl.primaryLocale;

    return Object.values(InboxMentionsStatus).map((option) => {
      return {
        text: this.intl.t(`inbox.conversation-filters.mentions-status.table-view.${option}`),
        value: option,
        isSelected: option === this.args.selectedMentionsStatus,
      };
    });
  }

  get formattedSelectedLabelPrefix() {
    if (this.args.selectedLabelPrefix === 'number') {
      return this.intl.formatNumber(this.args.selectedLabelPrefix);
    }
    return this.args.selectedLabelPrefix;
  }

  @action select(value: InboxMentionsStatus) {
    this.args.onMentionsStatusChanged?.(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::MentionsFilters': typeof Inbox2ConversationsTableMentionsFilters;
    'inbox2/conversations-table/mentions-filters': typeof Inbox2ConversationsTableMentionsFilters;
  }
}
