/* import __COLOCATED_TEMPLATE__ from './company-select.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { localCopy } from 'tracked-toolbox';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type InboxApi from 'embercom/services/inbox-api';
import type IntlService from 'embercom/services/intl';
import type Snackbar from 'embercom/services/snackbar';
import type CompanySummary from 'embercom/objects/inbox/company-summary';
import type CompanyApi from 'embercom/services/company-api';
import { tracked } from '@glimmer/tracking';

export interface Args {
  userCompanies: CompanySummary[];
  companyId: string;
  conversationId: number;
  existingRecord: boolean;
  onCompanySelected: (companyId: string | undefined, isDefaultSelection: boolean) => void;
  updateAssociatedCompanyWasDeleted: (wasDeleted: boolean) => void;
  updateAssociatedCompanyId: (companyId: string | undefined) => void;
  isReadOnly?: boolean;
}

interface Signature {
  Args: Args;
}

interface CompanyListItem {
  text: string;
  value: string;
}

export default class CompanySelectComponent extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare companyApi: CompanyApi;
  @localCopy('args.companyId') selectedCompanyId: string | undefined;
  @tracked associatedCompanyWasDeleted = false;
  selectionHasBeenMade = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    if (this.associatedCompanyId) {
      this.selectCompany(this.associatedCompanyId);
    }
  }

  get isDisabled() {
    return taskFor(this.updateAssociation).isRunning;
  }

  get companyAssociatedTooltip() {
    return this.intl.t('inbox.company-select.company-associated');
  }

  get companyOptions(): CompanyListItem[] {
    let sortCompanies = (companies: CompanyListItem[]) =>
      companies.sort((a: CompanyListItem, b: CompanyListItem) => a.text.localeCompare(b.text));

    if (this.userRemovedFromCompanyCase && !this.associatedCompanyWasDeleted) {
      return this.selectedCompanyOnly().concat(sortCompanies(this.userCompaniesIfPresent));
    }

    return sortCompanies(this.userCompaniesIfPresent);
  }

  get associatedCompanyText(): string {
    let associatedCompanyId = this.associatedCompanyId;
    return this.companyOptions.find((company) => company.value === associatedCompanyId)?.text ?? '';
  }

  private get userCompaniesIfPresent(): CompanyListItem[] {
    if (this.args.userCompanies) {
      return this.userCompaniesList();
    } else {
      return [];
    }
  }

  private get userRemovedFromCompanyCase(): boolean {
    return (
      this.args.existingRecord &&
      this.selectedCompanyId !== null &&
      this.selectedCompanyId !== undefined &&
      !this.userCompanyIds.includes(this.selectedCompanyId)
    );
  }

  private selectedCompanyOnly(): CompanyListItem[] {
    return [
      {
        text:
          this.selectedCompany.value?.name ||
          this.intl.t('inbox.ticket-details-header.loading-company-name'),
        value: this.selectedCompanyId!,
      },
    ];
  }

  private userCompaniesList(): CompanyListItem[] {
    return this.args.userCompanies.map((company: CompanySummary) => {
      return {
        text: company.name || company.id,
        value: company.id,
      };
    });
  }

  private get userCompanyIds(): string[] {
    return this.args.userCompanies.map((company: CompanySummary) => {
      return company.id;
    });
  }

  get companyIds(): string[] {
    return this.companyOptions.map((company: CompanyListItem) => {
      return company.value;
    });
  }

  get associatedCompanyId(): string | undefined {
    if (this.companyIds.includes(this.selectedCompanyId!)) {
      return this.selectedCompanyId;
    }

    // if this is a new ticket being created or converted from a conversation
    if (this.args.existingRecord === false && this.selectionHasBeenMade === false) {
      return this.defaultCompanyId;
    }

    return undefined;
  }

  get defaultCompanyId(): string | undefined {
    if (this.companyIds.length === 1) {
      return this.companyIds[0];
    }

    return undefined;
  }

  @action
  selectCompany(selectedCompanyId: string | undefined) {
    // We want to ensure the change propagates all the way up every time this component is loaded
    if (this.args.onCompanySelected) {
      let isDefaultSelection = selectedCompanyId === this.defaultCompanyId;
      this.args.onCompanySelected(selectedCompanyId, isDefaultSelection);
    }

    // If there was no change, we don't need to do any of the following steps
    if (this.selectedCompanyId === selectedCompanyId) {
      return;
    }

    this.selectedCompanyId = selectedCompanyId;
    this.selectionHasBeenMade = true;
    this.associatedCompanyWasDeleted = false;

    if (this.args.updateAssociatedCompanyWasDeleted) {
      this.args.updateAssociatedCompanyWasDeleted(false);
    }

    if (this.args.updateAssociatedCompanyId) {
      this.args.updateAssociatedCompanyId(selectedCompanyId);
    }

    if (this.args.existingRecord === true) {
      taskFor(this.updateAssociation).perform();
    }
  }

  @task({ drop: true })
  *updateAssociation() {
    try {
      yield this.inboxApi.updateCompanyTicketAssociation(
        this.args.conversationId,
        this.selectedCompanyId!,
      );
    } catch {
      this.snackbar.notifyError(this.intl.t('inbox.company-select.update-association-error'));
    }
  }

  @use selectedCompany = AsyncData<CompanySummary | undefined>(async () => {
    if (this.selectedCompanyId === undefined) {
      return undefined;
    }

    let selectedCompany = await this.companyApi.fetchCompany(this.selectedCompanyId);

    if (selectedCompany === null) {
      this.associatedCompanyWasDeleted = true;
      this.args.updateAssociatedCompanyWasDeleted(true);
      this.args.updateAssociatedCompanyId(undefined);
      return null;
    }

    return selectedCompany;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CompanySelect': typeof CompanySelectComponent;
    'inbox2/company-select': typeof CompanySelectComponent;
  }
}
