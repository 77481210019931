/* import __COLOCATED_TEMPLATE__ from './ticket-state-updated.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import LinkWithText from 'embercom/helpers/link-with-text';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type TicketStateUpdated from 'embercom/objects/inbox/renderable/ticket-state-updated';
import { type WorkflowObject } from 'embercom/objects/inbox/interfaces/workflow';
import { EntityType } from 'embercom/models/data/entity-types';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxApi from 'embercom/services/inbox-api';
import { type TicketType, TicketCategory } from 'embercom/objects/inbox/ticket';
import { trackedFunction } from 'ember-resources/util/function';

interface TicketStateUpdatedPart extends RenderablePart {
  renderableData: TicketStateUpdated;
}

interface Args {
  part: TicketStateUpdatedPart;
  conversation: Conversation;
  isTicketStream: boolean;
}

interface Signature {
  Args: Args;
}

export default class TicketStateUpdatedPartComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare inboxApi: InboxApi;

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get ticketState() {
    return this.args.part.renderableData.ticketState;
  }

  get teammate() {
    if (this.renderableData.entityType === EntityType.Workflow) {
      return {
        name: 'Operator',
      };
    } else if (this.renderableData.entityType === EntityType.Admin) {
      return this.args.part.renderableData.entity as AdminSummary;
    } else {
      return this.args.part.renderableData.entity as UserSummary;
    }
  }

  get renderableData() {
    return this.args.part.renderableData;
  }

  get translatedTicketState() {
    return this.intl.t(`inbox.ticket-state.${this.renderableData.ticketState}`);
  }

  get translationData() {
    let workflow = this.renderableData.entity as WorkflowObject;
    return {
      key: 'rule-changed-ticket-state',
      data: {
        value: this.translatedTicketState,
        rule: workflow.label,
      },
    };
  }

  // This event part can be rendered in the context of any ticket category or conversation
  //
  // For customer tickets and conversations we want to display the icon of the ticket that had its state updated
  //
  // For tracker and back office tickets we want to show the corresponding icon
  get ticketIcon() {
    let conversationTicketCategory = this.args.conversation.ticketType?.category;

    if (
      conversationTicketCategory === TicketCategory.Request ||
      conversationTicketCategory === undefined
    ) {
      let partTicketCategory = this.getPartTicketCategory();

      if (partTicketCategory === TicketCategory.Tracker) {
        return 'tracker';
      } else if (partTicketCategory === TicketCategory.Task) {
        return 'back-office';
      } else if (partTicketCategory === TicketCategory.Request) {
        return 'ticket';
      }
    } else if (conversationTicketCategory === TicketCategory.Tracker) {
      return 'tracker';
    } else if (conversationTicketCategory === TicketCategory.Task) {
      return 'back-office';
    }

    // We should never get here
    return undefined;
  }

  private getPartTicketCategory() {
    let ticketTypeId = this.args.part.renderableData?.ticketTypeId;

    if (ticketTypeId === undefined) {
      return null;
    }
    let ticketType = this.ticketTypes().find((ticketType) => ticketType.id === ticketTypeId);

    return ticketType?.category;
  }

  private ticketTypes(): TicketType[] {
    return this.ticketTypesLoader.value ?? [];
  }

  private ticketTypesLoader = trackedFunction(this, async () => {
    return await this.inboxApi.listTicketTypes();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::TicketStateUpdated': typeof TicketStateUpdatedPartComponent;
    'inbox2/conversation-stream/event-parts/ticket-state-updated': typeof TicketStateUpdatedPartComponent;
  }
}
