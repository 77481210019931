/* import __COLOCATED_TEMPLATE__ from './conversation-panel.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ComposerLocation } from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type ConversationStreamSettings from 'embercom/services/conversation-stream-settings';
// @ts-ignore
import type EmojiService from 'embercom/services/emoji-service';
import { type ConversationResource } from '../conversation-resource';
import { type SendAndCloseFn } from 'embercom/objects/inbox/types/composer';
import type SmartReplyResource from '../smart-reply-resource';
import { type PublicAPI } from 'embercom/components/inbox2/conversation-reply-composer';
import { action } from '@ember/object';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';

interface Args {
  conversationResource: ConversationResource;
  sendAndClose: SendAndCloseFn;
  isLivePhoneCall: boolean;
  smartReplyResource: SmartReplyResource;
  isNotePane: boolean;
  canShowSendAndCloseHint: boolean;
  dismissSendAndCloseHint: () => void;
  onComposerReady?: (composerAPI: PublicAPI) => void;
}

interface Signature {
  Args: Args;
}

export default class ConversationMainPanelComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare conversationStreamSettings: ConversationStreamSettings;
  @service declare emojiService: EmojiService;
  @service declare inboxHotkeys: InboxHotkeys;

  private composerAPI?: PublicAPI;

  readonly ComposerLocation = ComposerLocation;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get conversation(): Conversation {
    return this.args.conversationResource.conversation;
  }

  get highlights() {
    return this.args.conversationResource.highlights;
  }

  get hideEvents() {
    return this.conversationStreamSettings.hideEvents;
  }

  @action onComposerReady(composerAPI: PublicAPI) {
    this.composerAPI = composerAPI;
    this.args.onComposerReady?.(composerAPI);
  }

  @action focusComposer(e: KeyboardEvent) {
    this.inboxHotkeys.handleFocusWithin(() => this.composerAPI?.focus(), '[data-list-in-focus]', e);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::ConversationPanel': typeof ConversationMainPanelComponent;
    'inbox2/conversation-page/conversation-panel': typeof ConversationMainPanelComponent;
  }
}
