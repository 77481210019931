/* import __COLOCATED_TEMPLATE__ from './state-change.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { EntityType } from 'embercom/models/data/entity-types';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { type StateChangePart } from 'embercom/objects/inbox/renderable/state-change';
import LinkWithText from 'embercom/helpers/link-with-text';
import { StateChangeType } from 'embercom/models/data/inbox/state-change-enums';
import { type TeammateStateChangeContext } from 'embercom/objects/inbox/renderable/state-changes/teammate-state-change';
import { DurationType } from 'embercom/objects/inbox/duration';
import type IntlService from 'embercom/services/intl';
import moment from 'moment-timezone';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import { captureException } from 'embercom/lib/sentry';
import { isDeletedPart } from 'embercom/objects/inbox/deleted-part';

interface StateChangeRenderablePart extends RenderablePart {
  renderableData: StateChangePart;
  createdAt: Date;
}

interface Args {
  part: StateChangeRenderablePart;
  isTicket: boolean;
}

interface Signature {
  Args: Args;
}

const snoozeUntilTranslationKeyMap = {
  [DurationType.LaterToday]: 'until-later-today',
  [DurationType.Tomorrow]: 'until-tomorrow',
  [DurationType.Monday]: 'until-next-monday',
  [DurationType.NextWeek]: 'for-one-week',
  [DurationType.NextMonth]: 'for-one-month',
  [DurationType.CustomTime]: 'until-custom-time',
};

export default class Inbox2ConversationStreamEventPartsStateChangeComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get renderableData() {
    return this.args.part.renderableData;
  }

  get creatingEntity() {
    return this.renderableData.creatingEntity;
  }

  get customBotSummary() {
    return this.renderableData.customBotSummary;
  }

  private getSnoozedUntil(changeContext?: TeammateStateChangeContext) {
    if (changeContext?.duration && snoozeUntilTranslationKeyMap[changeContext.duration.type]) {
      let type = changeContext.duration.type;
      let data = {};
      if (type === DurationType.CustomTime) {
        data = {
          customTime: moment
            .unix(Number(changeContext.duration.time))
            .format(dateAndTimeFormats.timeOnDay),
        };
      }
      return this.intl.t(
        `inbox.${snoozeUntilTranslationKeyMap[changeContext.duration.type]}`,
        data,
      );
    }
    return '';
  }

  get translationData() {
    if (isDeletedPart(this.args.part)) {
      return { key: 'inbox.state-change.state-change-deleted' };
    }

    let part = (
      this.args.isTicket ? this.intl.t('inbox.ticket') : this.intl.t('inbox.conversation')
    ).toLowerCase();

    let data = {};
    if (this.renderableData.change === StateChangeType.Snoozed) {
      data = {
        until: this.getSnoozedUntil(this.args.part.renderableData.changeContext),
      };
    }

    if (!this.renderableData.eventDescription) {
      return this.unsupportedCase();
    }

    switch (this.creatingEntity.type) {
      case EntityType.Bot:
        if (this.customBotSummary) {
          return {
            key: `${this.renderableData.eventDescription}.named`,
            data: {
              who: this.intl.t('inbox.event-part.custom-bot', {
                title: this.linkWithTextHelper.compute([
                  'apps.app.operator.custom-bots.custom-bot.show',
                  this.customBotSummary.id,
                  this.customBotSummary.title,
                ]),
                htmlSafe: true,
              }),
              part,
            },
          };
        }
        return {
          key: `${this.renderableData.eventDescription}.named`,
          data: {
            who: this.linkWithTextHelper.compute([
              'apps.app.admins.admin',
              this.creatingEntity.object.id,
              this.creatingEntity.object.name,
            ]),
            part,
          },
        };
      case EntityType.Workflow:
        return {
          key: `${this.renderableData.eventDescription}.named`,
          data: {
            who: this.creatingEntity.object.label,
            ...data,
            part,
          },
        };
      case EntityType.Admin: {
        if (this.creatingEntity.object.id === this.session.teammate.id) {
          return {
            key: `${this.renderableData.eventDescription}.you`,
            data: { ...data, part },
          };
        } else {
          return {
            key: `${this.renderableData.eventDescription}.named`,
            data: {
              who: this.linkWithTextHelper.compute([
                'apps.app.admins.admin',
                this.creatingEntity.object.id,
                this.creatingEntity.object.name,
              ]),
              ...data,
              part,
            },
          };
        }
      }
      case EntityType.User:
        return {
          key: `${this.renderableData.eventDescription}.named`,
          data: {
            who: this.linkWithTextHelper.compute([
              'apps.app.users.user',
              this.creatingEntity.object.id,
              this.creatingEntity.object.name,
            ]),
            part,
          },
        };
      default:
        return this.unsupportedCase();
    }
  }

  unsupportedCase() {
    captureException(new Error('State change case not implemented'), {
      extra: {
        creatingEntity: this.creatingEntity,
        renderableData: this.renderableData,
      },
    });
    return { key: 'inbox.state-change.state-change-case-not-implemented' };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::StateChange': typeof Inbox2ConversationStreamEventPartsStateChangeComponent;
    'inbox2/conversation-stream/event-parts/state-change': typeof Inbox2ConversationStreamEventPartsStateChangeComponent;
  }
}
