/* import __COLOCATED_TEMPLATE__ from './tabs-header.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type SidebarTabDetail } from 'embercom/lib/inbox2/types';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';

interface Args {
  activeTab: SidebarTabDetail['id'];
  updateTab: (tab: SidebarTabDetail['id']) => void;
  isTicket: boolean;
}

interface Signature {
  Args: Args;
  Blocks: {
    actions: [];
  };
}

export default class TabsHeader extends Component<Signature> {
  @service declare inboxSidebarService: InboxSidebarService;

  get tabs(): Readonly<SidebarTabDetail[]> {
    let options = [
      {
        id: 'details',
        key: 'inbox.conversation-details-sidebar.tabs.details',
        accessible: true,
        hotkeyLabel: this.args.isTicket
          ? 'inbox.conversation-details-sidebar.tabs.ticket-details-label'
          : 'inbox.conversation-details-sidebar.tabs.details-hotkey-label',
        hotkey: 'OpenConversationDetails',
      },
      {
        id: 'copilot',
        icon: 'fin',
        key: 'inbox.conversation-details-sidebar.tabs.fin',
        accessible: this.inboxSidebarService.isCopilotTabVisible,
        shortKey: 'inbox.conversation-details-sidebar.tabs.fin-short-title',
        hotkeyLabel: 'inbox.conversation-details-sidebar.tabs.fin-hotkey-label',
        hotkey: 'OpenCopilot',
      },
    ] as const;

    return options.filter((tab) => tab.accessible);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::TabsHeader': typeof TabsHeader;
  }
}
