/* import __COLOCATED_TEMPLATE__ from './pipedrive.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type CrmProfile from 'embercom/objects/inbox/crm-profile';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    profile: CrmProfile;
  };
}

const Pipedrive = templateOnlyComponent<Signature>();
export default Pipedrive;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::ExternalProfiles::Hovercards::Pipedrive': typeof Pipedrive;
    'inbox2/conversation-details-sidebar/sidebar-sections/external-profiles/hovercards/pipedrive': typeof Pipedrive;
  }
}
