/* import __COLOCATED_TEMPLATE__ from './team-assignee.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type TeamSummary from 'embercom/objects/inbox/team-summary';

interface Signature {
  Args: {
    team?: TeamSummary;
    onClick: () => void;
  };
  Element: HTMLDivElement;
}

const TeamAssignee = templateOnlyComponent<Signature>();
export default TeamAssignee;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::TeamAssignee': typeof TeamAssignee;
    'inbox2/conversations-table/table-cells/team-assignee': typeof TeamAssignee;
  }
}
