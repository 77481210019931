/* import __COLOCATED_TEMPLATE__ from './paywall-pill.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    actionId: string;
  };
  Element: HTMLDivElement;
}

const PaywallPill = templateOnlyComponent<Signature>();
export default PaywallPill;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::PaywallPill': typeof PaywallPill;
    'inbox2/command-k/paywall-pill': typeof PaywallPill;
  }
}
