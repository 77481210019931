/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type JumpToAction from 'embercom/objects/inbox/command-k/jump-to';
import QuickSearchDataMultiple from 'embercom/components/inbox2/common/quick-search/quick-search-data-multiple-resource';
import type CommandKService from 'embercom/services/command-k';
import { inject as service } from '@ember/service';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';

interface Signature {
  Args: CommandKPaneComponentArgs<JumpToAction>;
}

const SEARCH_RESULTS_SIZE = 10;

export default class JumpToPaneComponent extends Component<Signature> {
  @service declare commandK: CommandKService;
  @tracked query = '';

  selection = useResource(this, NavigableSelection, () => ({
    items: this.items,
    isCommandKVisible: this.commandK.isVisible,
  }));

  quickSearchData = useResource(this, QuickSearchDataMultiple, () => ({
    types: this.args.actionItem.searchableTypes,
    query: this.query,
    size: SEARCH_RESULTS_SIZE,
    currentSearchContext: this.args.actionItem.searchableContext,
    loadSampleData: true,
    onSearchReturn: this.onQuickSearchReturn,
  }));

  get items(): Array<ChildAction> {
    return this.args.actionItem.buildResults(this.query, this.quickSearchData.results);
  }

  @action search(query: string) {
    this.query = query;
  }

  @action onQuickSearchReturn() {
    if (this.selection.items.length) {
      this.selection.select(this.selection.items[0]);
    }

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.items.length,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::JumpTo::Pane': typeof JumpToPaneComponent;
    'inbox2/command-k/jump-to/pane': typeof JumpToPaneComponent;
  }
}
