/* import __COLOCATED_TEMPLATE__ from './user-rating.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface Args {
  partGroup: PartGroup;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamPartGroupsUserRatingGroupComponent extends Component<Signature> {
  @service declare session: Session;

  renderableType = RenderableType;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::UserRating': typeof ConversationStreamPartGroupsUserRatingGroupComponent;
    'inbox2/conversation-stream/part-groups/user-rating': typeof ConversationStreamPartGroupsUserRatingGroupComponent;
  }
}
