/* import __COLOCATED_TEMPLATE__ from './customer.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class Inbox2Customer extends Component {
  @tracked isExpanded = false;

  @action
  collapseOrExpand() {
    this.isExpanded = !this.isExpanded;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Shopify::Customer': typeof Inbox2Customer;
    'inbox2/conversation-details-sidebar/sidebar-sections/shopify/customer': typeof Inbox2Customer;
  }
}
