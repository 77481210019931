/* import __COLOCATED_TEMPLATE__ from './title-changed.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type TitleChanged from 'embercom/objects/inbox/renderable/title-changed';
import LinkWithText from 'embercom/helpers/link-with-text';

interface TitleChangedPart extends RenderablePart {
  renderableData: TitleChanged;
}

interface Args {
  part: TitleChangedPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsTitleChangedComponent extends Component<Signature> {
  @service declare session: Session;

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get renderableData() {
    return this.args.part.renderableData;
  }

  get teammate() {
    return this.renderableData.adminSummary;
  }

  get translationData() {
    if (this.teammate.id === this.session.teammate.id) {
      return {
        key: 'you-changed-title',
        data: {
          value: this.renderableData.title,
        },
      };
    } else {
      return {
        key: 'admin-changed-title',
        data: {
          who: this.linkWithTextHelper.compute([
            'apps.app.admins.admin',
            this.teammate.id,
            this.teammate.name,
          ]),
          value: this.renderableData.title,
        },
      };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::TitleChanged': typeof Inbox2ConversationStreamEventPartsTitleChangedComponent;
    'inbox2/conversation-stream/event-parts/title-changed': typeof Inbox2ConversationStreamEventPartsTitleChangedComponent;
  }
}
