/* import __COLOCATED_TEMPLATE__ from './hint.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    hint: string;
    showHint: boolean;
    icon?: InterfaceIconName;
    onClick?: () => void;
    onShow?: () => void;
    onClose?: () => void;
    align: 'center' | 'right';
  };
  Blocks: {
    default: [];
  };
}

export default class ComposerHint extends Component<Signature> {
  onShow = modifier(
    () => {
      if (this.args.showHint && this.args.onShow) {
        this.args.onShow();
      }
    },
    { eager: false },
  );

  get alignClasses() {
    if (this.args.align === 'center') {
      return 'left-1/2 -translate-x-1/2 ';
    } else {
      return 'right-0 mx-6';
    }
  }

  @action onClick() {
    this.args.onClick?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::Hint': typeof ComposerHint;
  }
}
