/* import __COLOCATED_TEMPLATE__ from './attachment-list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type AttachmentList from '@intercom/embercom-prosemirror-composer/lib/composer/attachment-list';

interface Signature {
  Element: HTMLElement;
  Args: { attachmentList: AttachmentList };
}

const AttachmentListComponent = templateOnlyComponent<Signature>();
export default AttachmentListComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::AttachmentList': typeof AttachmentListComponent;
  }
}
