/* import __COLOCATED_TEMPLATE__ from './teammate-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import type Deleted from 'embercom/objects/inbox/renderable/deleted';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';

interface Args {
  part: RenderablePart & { renderableData: AdminComment | Deleted };
  conversationId: number;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamPartsTeammateContent extends Component<Signature> {
  get isNote() {
    return this.args.part.renderableType === RenderableType.AdminNote;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::Parts::TeammateContent': typeof ConversationStreamPartsTeammateContent;
    'inbox2/conversation-stream/parts/teammate-content': typeof ConversationStreamPartsTeammateContent;
  }
}
