/* import __COLOCATED_TEMPLATE__ from './results-count.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  isLoading: boolean;
  showCount: boolean;
  count: number;
}

const TableViewResultsCount = templateOnlyComponent<Signature>();
export default TableViewResultsCount;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::ResultsCount': typeof TableViewResultsCount;
    'inbox2/conversations-table/results-count': typeof TableViewResultsCount;
  }
}
