/* import __COLOCATED_TEMPLATE__ from './skin-tone-picker.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';

// @ts-ignore
import intermoji from '@intercom/intermoji';
import { inject as service } from '@ember/service';
import {
  type DefaultYellowSkinTone,
  type SkinToneModifiers,
} from 'embercom/services/emoji-service';
import type EmojiService from 'embercom/services/emoji-service';

interface Args {
  onSkinToneChange: (skinToneModifier: SkinToneModifiers | DefaultYellowSkinTone) => void;
}

interface Signature {
  Args: Args;
}

export default class SkinTonePicker extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare emojiService: EmojiService;
  @service declare intercomEventService: any;

  @tracked displayPicker = false;
  @tracked selectedSkinToneHand: string = this.buildSkinToneHand();

  static DEFAULT_EMOJI = '✋';

  get skinToneModifier() {
    return this.emojiService.skinToneModifier;
  }

  get skinToneHands() {
    return intermoji.getAllModifiedForBaseEmoji(SkinTonePicker.DEFAULT_EMOJI);
  }

  get buttonLabel() {
    return this.skinToneModifier === null
      ? `${SkinTonePicker.DEFAULT_EMOJI} ${this.intl.t('inbox.command-k.emojis.skin-tone')}`
      : this.selectedSkinToneHand;
  }

  @action togglePicker() {
    this.displayPicker = !this.displayPicker;

    if (this.displayPicker) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        section: 'command_k',
        object: 'emoji_skin_tone',
      });
    }
  }

  @action selectSkinToneHand(skinToneHand: string) {
    this.selectedSkinToneHand = skinToneHand;

    let skinToneModifier =
      skinToneHand === SkinTonePicker.DEFAULT_EMOJI
        ? ('default-yellow-skin-tone' as DefaultYellowSkinTone)
        : (skinToneHand.codePointAt(1) as number as SkinToneModifiers);
    this.emojiService.updateSkinToneModifier(skinToneModifier);
    this.args.onSkinToneChange(skinToneModifier);
    this.togglePicker();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      section: 'command_k',
      object: 'emoji_skin_tone',
      skin_tone_modifier: skinToneModifier,
    });
  }

  private buildSkinToneHand() {
    if (
      this.skinToneModifier === ('default-yellow-skin-tone' as DefaultYellowSkinTone) ||
      this.skinToneModifier === null
    ) {
      return SkinTonePicker.DEFAULT_EMOJI;
    }

    let hand = SkinTonePicker.DEFAULT_EMOJI.codePointAt(0) as number;
    return String.fromCodePoint(hand, this.skinToneModifier);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Emoji::SkinTonePicker': typeof SkinTonePicker;
    'inbox2/command-k/emoji/skin-tone-picker': typeof SkinTonePicker;
  }
}
