/* import __COLOCATED_TEMPLATE__ from './priority-changed-by-workflow.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const PriorityChangedByWorkflow = templateOnlyComponent<Signature>();
export default PriorityChangedByWorkflow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::PriorityChangedByWorkflow': typeof PriorityChangedByWorkflow;
    'inbox2/conversation-stream/event-parts/priority-changed-by-workflow': typeof PriorityChangedByWorkflow;
  }
}
