/* import __COLOCATED_TEMPLATE__ from './inbox1.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import type Store from '@ember-data/store';

interface Args {}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
    loading: [];
  };
}

export default class Inbox1Adapter extends Component<Signature> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare session: Session;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.session.inbox1Status !== 'unknown') {
      return;
    }

    this.loadAllTheThings();
  }

  async loadAllTheThings() {
    this.session.inbox1Status = 'booting';
    let promises = [];

    promises.push(this.loadApp());
    promises.push(this.store.findAll('conversation-attributes/descriptor'));
    promises.push(this.store.findAll('inbox/ticket-type'));

    if (this.session.workspace.isFeatureEnabled('ae-wfc-macros') && !this.session.showLightInbox) {
      promises.push(this.store.findAll('workflow-connector/insertable-action'));
    }

    await Promise.all(promises);
    this.session.inbox1Status = 'booted';
  }

  async loadApp() {
    if (this.appService.app) {
      return;
    }
    await this.appService.loadApp(this.session.workspace.id);
  }

  get hasBooted() {
    return this.session.inbox1Status === 'booted';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Adapters::Inbox1': typeof Inbox1Adapter;
    'inbox2/adapters/inbox1': typeof Inbox1Adapter;
  }
}
