/* import __COLOCATED_TEMPLATE__ from './draggable-dropdown-row.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const DraggableDropdownRow = templateOnlyComponent<Signature>();
export default DraggableDropdownRow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::DraggableDropdownRow': typeof DraggableDropdownRow;
    'inbox2/conversations-table/draggable-dropdown-row': typeof DraggableDropdownRow;
  }
}
