/* import __COLOCATED_TEMPLATE__ from './created-by.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';

interface Args {
  createdByAdminId?: string;
}

interface Signature {
  Args: Args;
}

export default class CreatedBy extends Component<Signature> {
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;

  get createdBySummary() {
    if (this.args.createdByAdminId) {
      return this.inbox2AssigneeSearch.findAdminById(Number(this.args.createdByAdminId));
    }
    return undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::CreatedBy': typeof CreatedBy;
    'inbox2/conversations-table/table-cells/created-by': typeof CreatedBy;
  }
}
