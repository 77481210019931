/* import __COLOCATED_TEMPLATE__ from './editable-phone-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type AttributeComponentArgs } from 'embercom/lib/inbox2/sidebar-types';

function hasValueChanged(currentValue: any, newValue: any) {
  if (isEmpty(currentValue) && isEmpty(newValue)) {
    return false;
  }
  return currentValue !== newValue;
}

interface Signature {
  Args: AttributeComponentArgs;
}

export default class EditablePhoneAttributeComponent extends Component<Signature> {
  @tracked isEditing = false;
  @tracked newValue: unknown;
  @tracked isHovered = false;

  get attributeName() {
    return this.args.attribute.humanFriendlyName;
  }

  get attributeValue() {
    let { user, attribute } = this.args;

    return user?.attributes[attribute.key] as string;
  }

  @action
  enableEditMode() {
    this.newValue = this.attributeValue;
    this.isEditing = true;
  }

  @action
  disableEditMode() {
    this.isEditing = false;
  }

  @action
  async finishEditing() {
    if (!this.isEditing) {
      return;
    }

    if (hasValueChanged(this.attributeValue, this.newValue)) {
      await this.args.onUserAttributeUpdated(this.args.attribute, this.newValue);
    }

    this.disableEditMode();
  }

  @action togglePin() {
    if (!this.args.showPinIcon) {
      return;
    }

    this.args.togglePin('phone');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::EditablePhoneAttribute': typeof EditablePhoneAttributeComponent;
    'inbox2/conversation-details-sidebar/sidebar-sections/editable-phone-attribute': typeof EditablePhoneAttributeComponent;
  }
}
