/* import __COLOCATED_TEMPLATE__ from './ticket-type-label.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import { type TicketType } from 'embercom/objects/inbox/ticket';

interface Args {
  ticketType: TicketType;
}

interface Signature {
  Args: Args;
}

export default class TicketTypeLabel extends Component<Signature> {
  get emojiUri() {
    if (!this.args.ticketType?.emoji) {
      return undefined;
    }

    return intermoji.twemojiSVGUri(this.args.ticketType.emoji);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::ConversationDetails::TicketTypeLabel': typeof TicketTypeLabel;
    'inbox2/conversation-details-sidebar/sidebar-sections/conversation-details/ticket-type-label': typeof TicketTypeLabel;
  }
}
