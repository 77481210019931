/* import __COLOCATED_TEMPLATE__ from './admin-assignee.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface Signature {
  Args: {
    admin?: AdminSummary;
    onClick: () => void;
  };
}

const AdminAssignee = templateOnlyComponent<Signature>();
export default AdminAssignee;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::AdminAssignee': typeof AdminAssignee;
    'inbox2/conversations-table/table-cells/admin-assignee': typeof AdminAssignee;
  }
}
