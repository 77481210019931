/* import __COLOCATED_TEMPLATE__ from './loading-state.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

const LoadingState = templateOnlyComponent();
export default LoadingState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::SearchTicket::LoadingState': typeof LoadingState;
    'inbox2/command-k/search-ticket/loading-state': typeof LoadingState;
  }
}
