/* import __COLOCATED_TEMPLATE__ from './notes.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Conversation from 'embercom/objects/inbox/conversation';
import type User from 'embercom/objects/inbox/user';
import type InboxApi from 'embercom/services/inbox-api';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { useResource } from 'ember-resources';
import { NotesResource } from 'embercom/components/inbox2/conversation-details-sidebar/sidebar-sections/notes/notes-resource';
import type Note from 'embercom/objects/inbox/note';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';

type Args = SidebarSectionComponentArgs<{
  conversation: Conversation;
  user?: User;
  activeInbox?: Inbox;
}>;

interface Signature {
  Args: Args;
}

type SectionId = 'notes';

export default class Notes extends Component<Signature> {
  readonly sectionId: SectionId = 'notes';

  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;

  @tracked userNotes = useResource(this, NotesResource, () => ({
    noteIds: this.args.user?.noteIds,
  }));

  @action
  async addNewNote(newNoteText: string) {
    if (this.userId) {
      let newNote = await this.inboxApi.addNote(this.userId, newNoteText);
      this.userNotes.appendNote(newNote);
      this.args.user?.updateNoteIds([newNote.id, ...this.args.user.noteIds]);

      this.intercomEventService.trackAnalyticsEvent({
        action: 'added',
        section: 'profile_notes',
        object: 'note',
        inbox_type: this.args.activeInbox?.type,
        conversation_id: this.args.conversation.id,
      });
    }
  }

  @action
  async updateNote(note: Note, body: string) {
    let updatedNote = await this.inboxApi.updateNote(note, body);
    this.userNotes.replaceNote(note, updatedNote);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated',
      section: 'profile_notes',
      object: 'note',
      inbox_type: this.args.activeInbox?.type,
      conversation_id: this.args.conversation.id,
    });
  }

  get userId() {
    return this.args.user?.id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Notes': typeof Notes;
    'inbox2/conversation-details-sidebar/sidebar-sections/notes': typeof Notes;
  }
}
