/* import __COLOCATED_TEMPLATE__ from './audience-targeting-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { inject as service } from '@ember/service';
import { type InboxAssistantConversationPart } from 'embercom/objects/inbox/inbox-assistant-conversation';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
import storage from 'embercom/vendor/intercom/storage';
import type CopilotApi from 'embercom/services/copilot-api';

export const COPILOT_AUDIENCE_TARGETING_TOOLTIP_APPEARANCES =
  'copilot-audience-targeting-tooltip-appearances';
export const COPILOT_AUDIENCE_TARGETING_TOOLTIP_DISMISSED =
  'copilot-audience-targeting-tooltip-dismissed';
export const MAX_APPEARANCES = 5;

interface Args {
  message: InboxAssistantConversationPart;
}

export default class AudienceTargetingTooltip extends Component<Args> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare session: Session;
  @service declare copilotApi: CopilotApi;

  @tracked appearancesKey: string;
  @tracked hasDismissedTooltipKey: string;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.appearancesKey = `${COPILOT_AUDIENCE_TARGETING_TOOLTIP_APPEARANCES}:${this.session.workspace.id}`;
    this.hasDismissedTooltipKey = `${COPILOT_AUDIENCE_TARGETING_TOOLTIP_DISMISSED}:${this.session.workspace.id}`;
    storage.set(this.appearancesKey, this.appearances ?? []);
  }

  get articleLink() {
    return 'https://www.intercom.com/help/en/articles/9357948-manage-knowledge-audiences-and-targeting';
  }

  get shouldShowTooltip() {
    return (
      this.session.teammate.permissions.canManageKnowledgeBaseContent &&
      !this.hasDismissedTooltip &&
      this.appearances.length < MAX_APPEARANCES &&
      !this.copilotApi.hasExcerptMatchingAttributes
    );
  }

  get appearances(): string[] {
    return storage.get(this.appearancesKey);
  }

  get hasDismissedTooltip(): boolean {
    return storage.get(this.hasDismissedTooltipKey);
  }

  @action trackAppearance() {
    let messagedId = `${this.args.message.id}`;

    if (!this.appearances.includes(messagedId)) {
      let newAppearances = this.appearances.concat(messagedId);
      storage.set(this.appearancesKey, newAppearances);
    }
  }

  @action markAsDismissed() {
    storage.set(this.hasDismissedTooltipKey, true);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::AudienceTargetingTooltip': typeof AudienceTargetingTooltip;
    'inbox2/copilot/audience-targeting-tooltip': typeof AudienceTargetingTooltip;
  }
}
