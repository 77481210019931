/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type Named, Resource } from 'ember-resources';
import { action } from '@ember/object';
import type NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';

interface KeyboardNavigableItemsResourceArgs {
  items: Array<ChildAction>;
  maxItemsPerRow: number;
  selection: NavigableSelection;
}

// eslint-disable-next-line @intercom/intercom/no-component-inheritance
export default class KeyboardNavigableItemsResource extends Resource<
  Named<KeyboardNavigableItemsResourceArgs>
> {
  private selection = this.args.named.selection;
  private items = this.args.named.items;
  private maxItemsPerRow = this.args.named.maxItemsPerRow;

  get currentSelectionIndex() {
    return this.selection.indexOfSelected;
  }

  get selectionIsOnTopRow() {
    return this.currentSelectionIndex < this.maxItemsPerRow;
  }

  get selectionIsOnBottomRow() {
    return Math.ceil((this.currentSelectionIndex + 1) / this.maxItemsPerRow) === this.totalRows;
  }

  get totalRows() {
    return Math.ceil(this.items.length / this.maxItemsPerRow);
  }

  get selectionIsFirstItem() {
    return this.currentSelectionIndex === 0;
  }

  @action arrowLeft(e: KeyboardEvent, ek: any) {
    e.preventDefault();

    if (this.selectionIsFirstItem) {
      return;
    }

    ek.stopPropagation();
    this.selection.previous();
  }

  @action arrowRight(e: KeyboardEvent, ek: any) {
    e.preventDefault();
    ek.stopPropagation();
    this.selection.next();
  }

  @action arrowDown(e: KeyboardEvent, ek: any) {
    e.preventDefault();
    ek.stopPropagation();

    if (this.items.length <= this.maxItemsPerRow) {
      return;
    }
    if (this.selectionIsOnBottomRow) {
      this.selection.previous(
        this.currentSelectionIndex - ((this.currentSelectionIndex % this.maxItemsPerRow) + 1),
      );
    } else {
      this.selection.next(this.maxItemsPerRow - 1);
    }
  }

  @action arrowUp(e: KeyboardEvent, ek: any) {
    e.preventDefault();
    ek.stopPropagation();

    if (this.items.length <= this.maxItemsPerRow) {
      return;
    }

    if (this.selectionIsOnTopRow) {
      this.selection.next((this.totalRows - 1) * this.maxItemsPerRow - 1);
    } else {
      this.selection.previous(this.maxItemsPerRow - 1);
    }
  }
}
