/* import __COLOCATED_TEMPLATE__ from './teammate-temp-view-banner.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type InboxApi from 'embercom/services/inbox-api';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';

interface Args {
  showBanner: boolean;
  tempSelectedTeamId: string;
}

interface Signature {
  Args: Args;
}

export default class TeammateTempViewBanner extends Component<Signature> {
  @service declare inboxApi: InboxApi;

  @tracked showBanner: boolean = this.args.showBanner;
  @tracked teamName = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.fetchTeamName).perform();
    taskFor(this.hideBanner).perform();
  }

  @task({ drop: true })
  *hideBanner() {
    yield timeout(10000);
    this.showBanner = false;
  }

  @task({ drop: true })
  *fetchTeamName(): TaskGenerator<void> {
    let team: Inbox = yield this.inboxApi.fetchInbox(
      InboxCategory.Team,
      this.args.tempSelectedTeamId,
    );
    this.teamName = team.name;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Teammates::TeammateTempViewBanner': typeof TeammateTempViewBanner;
    'inbox2/dashboard/teammates/teammate-temp-view-banner': typeof TeammateTempViewBanner;
  }
}
