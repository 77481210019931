/* import __COLOCATED_TEMPLATE__ from './files.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Inbox2ImagePreviewService from 'embercom/services/inbox2-image-preview-service';
import { type Upload } from 'embercom/objects/inbox/renderable/upload';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type IntlService from 'embercom/services/intl';

interface Args {
  files: Upload[];
}

interface Signature {
  Args: Args;
}

const MAX_FILES_TO_DISPLAY = 2;

export default class Files extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare inbox2ImagePreviewService: Inbox2ImagePreviewService;

  get visibleFiles() {
    return this.args.files.slice(0, MAX_FILES_TO_DISPLAY);
  }

  get hiddenFiles() {
    return this.args.files.slice(MAX_FILES_TO_DISPLAY, this.args.files.length);
  }

  get text(): string {
    return `+ ${this.hiddenFiles.length} ${this.intl.t(
      'inbox.conversations-table.cells.tags.more',
    )}`;
  }

  @action handlePreviewImageClick(file: Upload) {
    if (file.contentType.startsWith('image/')) {
      this.inbox2ImagePreviewService.onPreviewImageClick(file.url);
    } else {
      safeWindowOpen(file.url);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::Files': typeof Files;
    'inbox2/conversations-table/table-cells/files': typeof Files;
  }
}
