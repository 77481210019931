/* import __COLOCATED_TEMPLATE__ from './attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { DataType } from 'embercom/objects/inbox/conversation-attribute-descriptor';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { type TicketDescriptor } from 'embercom/objects/inbox/ticket';

interface Args {
  attribute?: ConversationAttributeSummary;
  descriptor: ConversationAttributeDescriptor | TicketDescriptor;
}

interface Signature {
  Args: Args;
  Element: HTMLParagraphElement;
}

export default class Attribute extends Component<Signature> {
  @trackedReset('args.attribute') attribute =
    // @ts-ignore
    this.args.attribute ?? new ConversationAttributeSummary(undefined, this.args.descriptor);

  get value() {
    let { descriptor } = this.args;

    if (descriptor.type === DataType.List) {
      if (!descriptor.listOptions) {
        return undefined;
      }

      let option = descriptor.listOptions.find((option) => option.id === this.attribute.value);
      if (!option) {
        return undefined;
      }

      return option.label;
    } else {
      return this.attribute.value as string | undefined;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::Attribute': typeof Attribute;
    'inbox2/conversations-table/table-cells/attribute': typeof Attribute;
  }
}
