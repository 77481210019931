/* import __COLOCATED_TEMPLATE__ from './clear-list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    item: { text: string };
  };
}

const ClearList = templateOnlyComponent<Signature>();
export default ClearList;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::Attribute::Editor::ClearList': typeof ClearList;
    'inbox2/conversation-details-sidebar/attribute/editor/clear-list': typeof ClearList;
  }
}
