/* import __COLOCATED_TEMPLATE__ from './manage-sections.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
// @ts-ignore
import { trackedReset, dedupeTracked } from 'tracked-toolbox';
import SidebarSection from 'embercom/objects/inbox/sidebar-section';
import type Session from 'embercom/services/session';
import { SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import type SidebarSections from '../sidebar-sections';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Args {
  pinnedSections: SidebarSection[];
  availableSections: SidebarSection[];
  sidebarSectionDetails: SidebarSections['sidebarSectionDetails'];
  onUpdateSections: (sections: SidebarSection[]) => unknown;
  conversation?: Conversation;
}

interface Signature {
  Args: Args;
}

export default class ManageSectionsComponent extends Component<Signature> {
  supportedSidebarSection = SupportedSidebarSection;
  @service declare session: Session;

  @dedupeTracked hoveredSection?: SidebarSection;

  @trackedReset({
    memo: 'args.availableSections',
    update() {
      return [...this.pinnedSections, ...this.unpinnedSections];
    },
  })
  sections: SidebarSection[] = [];

  get pinnedSections() {
    return this.args.availableSections.filter((section) => this.isPinned(section));
  }

  get unpinnedSections() {
    return this.args.availableSections.filter((section) => !this.isPinned(section));
  }

  @action updateSections() {
    this.sections = [...this.pinnedSections, ...this.unpinnedSections];
  }

  @action toggleSection(section: SidebarSection) {
    if (this.isPinned(section)) {
      this.args.onUpdateSections(this.args.pinnedSections.reject((s) => s.isSameSection(section)));
    } else {
      this.args.onUpdateSections([...this.args.pinnedSections, new SidebarSection(section)]);
    }
  }

  @action onHover(section: SidebarSection | undefined) {
    if (this.hoveredSection?.isSameSection(section)) {
      return;
    }

    this.hoveredSection = section;
  }

  private isPinned(section: SidebarSection) {
    return this.args.pinnedSections.any((s) => s.isSameSection(section));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::ManageSections': typeof ManageSectionsComponent;
    'inbox2/conversation-details-sidebar/manage-sections': typeof ManageSectionsComponent;
  }
}
