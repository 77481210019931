/* import __COLOCATED_TEMPLATE__ from './table-cell.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type ConversationsTableColumn from 'embercom/objects/inbox/conversations-table-column';
import { type SortState } from '../conversations-table';

interface Args {
  column: ConversationsTableColumn;
  conversationEntry?: ConversationTableEntry;
  useAnimations?: boolean;
  isSkeleton?: boolean;
  sortState?: SortState;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class TableCell extends Component<Signature> {
  get hideWhenRestricted(): boolean {
    if (!this.args.conversationEntry) {
      return false;
    }

    return this.args.conversationEntry.redacted && this.type !== 'contact';
  }

  get type() {
    return this.args.column.type;
  }

  get style() {
    return `width: ${this.args.column.width}px;`;
  }

  get cellStyling() {
    if (this.args.isSkeleton) {
      let skeletonClasses = `o__skeleton rounded-lg`;
      return this.args.useAnimations ? `${skeletonClasses} animate-pulse` : skeletonClasses;
    } else if (this.args.useAnimations) {
      return 'o__fade';
    }
    return '';
  }

  get conversationEntry() {
    return this.args.conversationEntry;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCell': typeof TableCell;
    'inbox2/conversations-table/table-cell': typeof TableCell;
  }
}
