/* import __COLOCATED_TEMPLATE__ from './manage-user-subscription-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {
  subscriptionAction: 'resubscribe' | 'unsubscribe';
  isOpen: boolean;
  onModalClose: () => any;
  onSubmit: () => any;
  onCancel: () => any;
}

interface Signature {
  Args: Args;
}

export default class ManageUserSubscriptionModal extends Component<Signature> {
  @service declare intl: IntlService;

  get title(): string {
    return this.intl.t(
      `inbox.attribute-list-item.user-data-menu.subscription.${this.args.subscriptionAction}`,
    );
  }

  get body(): string {
    return this.intl.t(
      `inbox.attribute-list-item.user-data-menu.subscription.${this.args.subscriptionAction}-confirmation`,
    );
  }

  get submitText(): string {
    return this.intl.t(
      `inbox.attribute-list-item.user-data-menu.subscription.${this.args.subscriptionAction}`,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::ManageUserSubscriptionModal': typeof ManageUserSubscriptionModal;
    'inbox2/conversation-details-sidebar/sidebar-sections/manage-user-subscription-modal': typeof ManageUserSubscriptionModal;
  }
}
