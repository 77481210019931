/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type UseMacroAction from 'embercom/objects/inbox/command-k/use-macro';
import type CommandKService from 'embercom/services/command-k';
import { inject as service } from '@ember/service';
import { type SearchByTypeResponse } from 'embercom/services/quick-search';
import type InboxState from 'embercom/services/inbox-state';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Router from '@ember/routing/router-service';
import type Session from 'embercom/services/session';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';
import type Inbox2MacrosSearch from 'embercom/services/inbox2-macros-search';
import { trackedFunction } from 'ember-resources/util/function';
import type CopilotApi from 'embercom/services/copilot-api';

interface Signature {
  Args: CommandKPaneComponentArgs<UseMacroAction>;
}

export default class UseMacroPaneComponent extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare inboxState: InboxState;
  @service declare router: Router;
  @service declare session: Session;
  @service declare inbox2MacrosSearch: Inbox2MacrosSearch;
  @service declare copilotApi: CopilotApi;
  @service declare intercomEventService: any;
  @tracked query = '';

  @action search(query: string) {
    this.query = query;
    this.commandK.trackSearch({
      query,
      action: 'search_initiated',
    });
  }

  get macroResults(): SearchByTypeResponse {
    return this.macrosLoader.value || { results: [], total: 0 };
  }

  macrosLoader = trackedFunction(this, async () => {
    let results: SearchByTypeResponse = await this.inbox2MacrosSearch.filterMacros(
      this.query,
      undefined,
      this.args.actionItem.searchableContext,
    );

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: results.results.length,
    });

    return results;
  });

  @action askCopilot(query: string) {
    this.commandK.hide();
    this.copilotApi.triggerAndSearchCopilotQuery(query);
    this.trackAskOpenCopilotEvent(query);
  }

  get shouldDisplayCopilotEntrypoint() {
    return this.copilotApi.isCopilotEnabled && this.query.trim() !== '';
  }

  private trackAskOpenCopilotEvent(query: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'copilot_clicked',
      object: 'command_k',
      place: 'inbox',
      section: 'conversation-stream',
      conversation_id: this.inboxState.activeConversationId,
      teammate_id: this.session.teammate.id,
      query,
    });
  }

  @action manageMacros() {
    let manageMacrosRoute = 'apps.app.settings.helpdesk.macros';
    let url: string = this.router.urlFor(manageMacrosRoute, this.session.workspace);
    safeWindowOpen(url);
  }

  @action createMacro() {
    this.commandK.hide();
    this.inboxState.toggleCreateMacroModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::UseMacro::Pane': typeof UseMacroPaneComponent;
    'inbox2/command-k/use-macro/pane': typeof UseMacroPaneComponent;
  }
}
