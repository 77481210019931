/* import __COLOCATED_TEMPLATE__ from './entities-unlinked.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import LinkWithText from 'embercom/helpers/link-with-text';
import type IntlService from 'embercom/services/intl';
import type InboxApi from 'embercom/services/inbox-api';
import type Router from '@ember/routing/router-service';
import { trackedFunction } from 'ember-resources/util/function';
//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { TicketCategory, type TicketType } from 'embercom/objects/inbox/ticket';
import type EntitiesUnLinked from 'embercom/objects/inbox/renderable/entities-unlinked';
import type Conversation from 'embercom/objects/inbox/conversation';

interface EntitiesUnlinkedPart extends RenderablePart {
  renderableData: EntitiesUnLinked;
}

interface Args {
  part: EntitiesUnlinkedPart;
  conversation: Conversation;
  isTicket: boolean;
  ticketType?: TicketType;
  isTicketStream: boolean;
}

interface Signature {
  Args: Args;
}

type TranslationData = {
  key: string;
  data: {
    value: string;
    who?: string;
    user?: string;
    linkedBy?: string;
  };
};

export default class EntitiesUnlinkedAdminPartComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare inboxApi: InboxApi;

  // This event part can only be rendered in the context of tracker tickets, customer tickets and conversations
  //
  // For tracker tickets we want to show the tracker ticket icon
  //
  // For customer tickets and conversations we want to display the icon of the tracker ticket (entity unlinked)
  get ticketIcon() {
    let conversationTicketCategory = this.args.conversation.ticketType?.category;

    if (conversationTicketCategory === TicketCategory.Tracker) {
      return 'tracker';
    } else if (
      conversationTicketCategory === TicketCategory.Request ||
      conversationTicketCategory === undefined
    ) {
      let unlinkedFromTicketCategory = this.getTicketCategoryForTicketTypeId(
        this.renderableData.unlinkedFrom.ticketTypeId,
      );

      // Currently we can only unlink customer tickets and conversations from tracker tickets hence why we just have one branch
      if (unlinkedFromTicketCategory === TicketCategory.Tracker) {
        return 'tracker';
      }
    }

    // We should never get here
    return undefined;
  }

  private getTicketCategoryForTicketTypeId(ticketTypeId: number | undefined) {
    let ticketType = this.ticketTypes.find((ticketType) => ticketType.id === ticketTypeId);

    return ticketType?.category;
  }

  // @ts-ignore
  linkWithTextHelper = LinkWithText.create(this);

  get renderableData() {
    return this.args.part.renderableData;
  }

  get teammate() {
    return this.renderableData.adminSummary;
  }

  get didIAuthorTheEvent() {
    return this.teammate.id === this.session.teammate.id;
  }

  get ticketTypes(): TicketType[] {
    return this.ticketTypesLoader.value ?? [];
  }

  private ticketTypesLoader = trackedFunction(this, async () => {
    return await this.inboxApi.listTicketTypes();
  });

  conversationLink() {
    let linkedConversationId = this.renderableData.entitiesUnlinked[0].entityId;
    let href = this.router.urlFor(this.router.currentRouteName, linkedConversationId);

    let conversationText = this.intl.t(`inbox.entity-linked.capitalize-conversation`);

    if (this.isTrackerUnlinkedFromCustomerTicket) {
      if (this.args.ticketType?.category === TicketCategory.Tracker) {
        conversationText = this.intl.t('inbox.entities-unlinked.customer-ticket');
      }
    }

    return sanitizeHtml(
      `<a class="hover:text-blue dark:hover:text-dm-blue no-underline" target="_blank" href=${href}>${conversationText}</a>`,
    );
  }

  get isUnlinkedFromTrackerTicket() {
    let ticketType = this.ticketTypes.find(
      (ticketType) => ticketType.id === this.renderableData.unlinkedFrom.ticketTypeId,
    );

    return ticketType?.category === TicketCategory.Tracker;
  }

  get isCustomerTicketUnlinked() {
    let ticketType = this.ticketTypes.find(
      (ticketType) => ticketType.id === this.renderableData.entitiesUnlinked[0].ticketTypeId,
    );

    return ticketType?.category === TicketCategory.Request;
  }

  ticketLink() {
    let ticketId = this.renderableData.unlinkedFrom.entityId;
    let href = this.router.urlFor(this.router.currentRouteName, ticketId);
    let ticketTypeId = this.renderableData.unlinkedFrom.ticketTypeId;
    let ticketType = undefined;
    let ticketTitle = this.renderableData.unlinkedFrom.ticketTitle;

    if (ticketTypeId !== undefined) {
      ticketType = this.ticketTypes.find((ticketType) => ticketType.id === ticketTypeId);
    }

    if (ticketType === undefined && ticketTitle === undefined) {
      return this.intl.t(`inbox.entity-linked.fallback-ticket-type`);
    }
    let displayText = ticketTitle ?? ticketTitle ?? ticketType?.name;

    if (ticketType === undefined) {
      return this.intl.t(`inbox.entity-linked.fallback-ticket-type`);
    }

    let exposedTicketId = this.renderableData.unlinkedFrom.userFacingTicketId
      ? this.renderableData.unlinkedFrom.userFacingTicketId
      : this.renderableData.unlinkedFrom.entityId;

    displayText = `${displayText} #${exposedTicketId}`;

    return sanitizeHtml(
      `<a class="hover:text-blue dark:hover:text-dm-blue no-underline" target="_blank" href=${href}>${displayText}</a>`,
    );
  }

  get isTrackerUnlinkedFromCustomerTicket() {
    return this.isCustomerTicketUnlinked && this.isUnlinkedFromTrackerTicket;
  }

  get showTrackerLink() {
    return (
      this.isTrackerUnlinkedFromCustomerTicket &&
      this.args.ticketType?.category === TicketCategory.Request
    );
  }

  get translationData(): TranslationData {
    let data: TranslationData = {
      key: this.translationKey,
      data: {
        value:
          this.args.isTicket && !this.showTrackerLink ? this.conversationLink() : this.ticketLink(),
      },
    };

    if (!this.didIAuthorTheEvent) {
      data.data.who = this.linkWithTextHelper.compute([
        'apps.app.admins.admin',
        this.teammate.id,
        this.teammate.name,
      ]);
    }

    if (this.entitiesUnlinkedUserSummary) {
      data.data.user = this.linkWithTextHelper.compute([
        'apps.app.users.user',
        this.entitiesUnlinkedUserSummary.id,
        this.entitiesUnlinkedUserSummary.displayAs,
      ]);
    }

    if (this.isTrackerUnlinkedFromCustomerTicket) {
      data.data.linkedBy = this.linkedBy();
    }

    return data;
  }

  linkedBy() {
    return this.didIAuthorTheEvent
      ? this.intl.t('inbox.entities-unlinked.you')
      : this.linkWithTextHelper.compute([
          'apps.app.admins.admin',
          this.teammate.id,
          this.teammate.name,
        ]);
  }

  get translationKey() {
    let adminKeyPrefix = this.didIAuthorTheEvent ? 'you' : 'admin';

    if (this.isTrackerUnlinkedFromCustomerTicket) {
      let userPrefix =
        this.entitiesUnlinkedUserSummary &&
        this.args.ticketType?.category === TicketCategory.Tracker
          ? 'with-user'
          : 'with-unknown-user';

      return `inbox.entities-unlinked.unlinked-customer-ticket-to-tracker-${userPrefix}`;
    }

    if (this.args.isTicket) {
      let userPrefix = this.entitiesUnlinkedUserSummary ? 'with-user' : 'with-unknown-user';
      return `inbox.entities-unlinked.${adminKeyPrefix}-unlinked-conversation-${userPrefix}`;
    }
    return `inbox.entities-unlinked.${adminKeyPrefix}-unlinked-ticket`;
  }

  get entitiesUnlinkedUserSummary() {
    if (
      this.renderableData.entitiesUnlinkedUserSummary &&
      this.renderableData.entitiesUnlinkedUserSummary.length > 0
    ) {
      let userId = this.renderableData.entitiesUnlinked[0].userId;
      return this.renderableData.entitiesUnlinkedUserSummary.find(
        (summary: any) => summary.id === userId,
      );
    }
    return null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::EntitiesUnlinked': typeof EntitiesUnlinkedAdminPartComponent;
    'inbox2/conversation-stream/event-parts/entities-unlinked': typeof EntitiesUnlinkedAdminPartComponent;
  }
}
