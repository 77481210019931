/* import __COLOCATED_TEMPLATE__ from './draggable-call-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
// @ts-ignore
import { ref } from 'ember-ref-bucket';

interface Args {
  isDraggable: boolean;
  isListening: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
  Blocks: {
    content: [];
  };
}

export default class DraggableCallModal extends Component<Signature> {
  @tracked dragging = false;
  @tracked top = 80;
  @tracked left = window.innerWidth / 2 - 192;
  @tracked shiftX = 0;
  @tracked shiftY = 0;

  @ref('draggable-container') declare draggableContainer: HTMLElement;

  get positionCSS() {
    return `top: ${this.top}px; left: ${this.left}px; z-index: 10002;`;
  }

  @action startDrag(event: MouseEvent) {
    if (!this.draggableContainer) {
      return;
    }

    event.preventDefault();
    this.dragging = true;
    this.shiftX = event.clientX - this.draggableContainer.getBoundingClientRect().left;
    this.shiftY = event.clientY - this.draggableContainer.getBoundingClientRect().top;
    document.addEventListener('mousemove', this.drag);
  }

  @action drag(event: MouseEvent) {
    if (this.dragging) {
      this.left = event.pageX - this.shiftX;
      this.top = event.pageY - this.shiftY;
    }
  }

  @action stopDrag() {
    this.dragging = false;
    document.removeEventListener('mousemove', this.drag);
  }

  willDestroy() {
    super.willDestroy();
    document.removeEventListener('mousemove', this.drag);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::DraggableCallModal': typeof DraggableCallModal;
    'inbox2/draggable-call-modal': typeof DraggableCallModal;
  }
}
