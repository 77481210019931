/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { sortBy, partition } from 'underscore';
import ENV from 'embercom/config/environment';
import type Session from 'embercom/services/session';
import {
  type ConversationsActivityAggregations,
  type CallsActivityAggregations,
} from 'embercom/services/inbox2-teammate-activity-service';
import type Inbox2TeammateActivityService from 'embercom/services/inbox2-teammate-activity-service';
import type DashboardSettings from 'embercom/objects/inbox/dashboard-settings';
import { taskFor } from 'ember-concurrency-ts';
import { type TableWidth } from '../main';
import type IntlService from 'embercom/services/intl';
import {
  REALTIME_DASHBOARD_TEAMMATES_COLUMNS,
  REALTIME_DASHBOARD_TEAMMATES_COLUMNS_GROUPED,
  REALTIME_DASHBOARD_TEAMMATES_AGGREGATION_METRICS,
} from 'embercom/lib/inbox2/realtime-dashboard-constants';

const DEFAULT_REFRESH_TIME_IN_SECONDS = 60;

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_TIME_FRAME = 8;

interface ColumnsGroup {
  headingKey: string;
  hideGroup: boolean;
  items: {
    labelKey: string;
    valuePath: string;
    tooltipKey?: string;
    hideable?: boolean;
    metricType?: string;
    featureFlags?: string[];
  }[];
}

export interface AggregationMetric {
  title: string;
  value: any;
  icon?: string;
  qualifier?: string;
  metricType?: string | undefined;
  tooltipContent: string;
  awayReasons?: string | null;
  statusIndicator?: 'active' | 'away' | 'reassigning';
}

interface Args {
  selectedAssigneeIds: number[];
  selectedColumns: string[];
  selectedTeammateActivityTimeframe: number;
  selectedTeammateStatuses: string[];
  settings: DashboardSettings;
  saveSelectedAssigneeIds: (selectedAssigneeIds: number[]) => Promise<void>;
  saveSelectedTeammateActivityColumns: (selectedColumns: string[]) => void;
  saveSelectedTeammateActivityTimeframe: (timeframe: number) => void;
  saveSelectedTeammateStatuses: (statuses: string[]) => void;
  selectedTab: string;
  pageSize: number;
  openDrilldownDrawer: (selectedCell: any) => void;
  tableWidth: TableWidth;
}

interface Signature {
  Args: Args;
}

export default class Table extends Component<Signature> {
  @service declare session: Session;
  @service declare inbox2TeammateActivityService: Inbox2TeammateActivityService;
  @service intercomEventService: any;
  @service notificationsService: any;
  @service declare intl: IntlService;

  @tracked data: any = [];
  @tracked aggregations: ConversationsActivityAggregations | {} = {};
  @tracked callsAggregations: CallsActivityAggregations | {} = {};
  @tracked numberOfVisibleRows: number = this.pageSize;
  @tracked currentTimeframe: number =
    this.args.selectedTeammateActivityTimeframe || DEFAULT_TIME_FRAME;
  @tracked currentStatuses: string[] = this.args.selectedTeammateStatuses || [];

  @tracked isLoading = true;
  @tracked isSwitchingTimeframe = false;
  @tracked hasChangedSettings = false;
  @tracked selectedColumns: string[] = this.args.selectedColumns || this.defaultSelectedColumns;

  @tracked isFullscreen = false;

  @tracked isChangingTeammateStatus = false;
  @tracked selectedDrilldownCell: any;

  @tracked sortState = {
    valuePath: 'status',
    direction: 'asc',
    sortKey: 'statusCode',
  };

  @tracked selectedAggregationMetrics: AggregationMetric[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.refresh).perform();
    this.inbox2TeammateActivityService.setForceRefresh(this.refresh);
  }

  get settings() {
    return this.args.settings;
  }

  get visibleData() {
    return this.data.slice(0, this.numberOfVisibleRows);
  }

  get pageSize() {
    return this.args.pageSize || DEFAULT_PAGE_SIZE;
  }

  get canLoadMore() {
    return this.data.length > this.numberOfVisibleRows;
  }

  get loadingRowCount() {
    return Math.min(this.data.length, this.numberOfVisibleRows);
  }

  get columns() {
    return this.columnsData.filter(({ hideable, valuePath }) => {
      return !hideable || this.selectedColumns.includes(valuePath);
    });
  }

  get canShowCallsMetrics() {
    return this.session.workspace.isFeatureEnabled('inbound-phone-call');
  }

  get columnsData() {
    let hideIfFullscreen = (value: string | boolean) => (this.isFullscreen ? null : value);
    let columnsList = REALTIME_DASHBOARD_TEAMMATES_COLUMNS;

    columnsList = columnsList.filter((column) => {
      if (!this.canShowCallsMetrics && column.category === 'calls') {
        return false;
      }

      if (!column.featureFlags) {
        return true;
      }

      return column.featureFlags.every((flag) => this.session.workspace.isFeatureEnabled(flag));
    });

    return columnsList.map((column) => ({
      ...column,
      label: this.intl.t(column.labelKey, {
        aggregationType: this.settings.aggregationTypeText,
      }),
      tooltip: column.tooltipKey
        ? hideIfFullscreen(
            this.intl.t(column.tooltipKey, {
              idleThresholdText: this.settings.idleThresholdText,
              timeframe: this.currentTimeframe,
              aggregationType: this.settings.aggregationTypeText,
            }),
          )
        : null,
      isSortable: hideIfFullscreen(true),
    }));
  }

  get columnsDataGrouped() {
    let hideIfFullscreen = (value: string | boolean) => (this.isFullscreen ? null : value);

    let columnsGroup = REALTIME_DASHBOARD_TEAMMATES_COLUMNS_GROUPED.map(
      (columnGroup: ColumnsGroup) => ({
        ...columnGroup,
        items: columnGroup.items.filter((column: { featureFlags?: string[] }) => {
          if (!column.featureFlags) {
            return true;
          }
          return column.featureFlags.every((flag) => this.session.workspace.isFeatureEnabled(flag));
        }),
      }),
    );

    return columnsGroup.map((columnGroup) => ({
      heading: this.intl.t(columnGroup.headingKey),
      hideGroup: columnGroup.hideGroup,
      items: columnGroup.items.map((column) => ({
        ...column,
        label: this.intl.t(column.labelKey, {
          aggregationType: this.settings.aggregationTypeText,
        }),
        tooltip: column.tooltipKey
          ? hideIfFullscreen(
              this.intl.t(column.tooltipKey, {
                idleThresholdText: this.settings.idleThresholdText,
                timeframe: this.currentTimeframe,
                aggregationType: this.settings.aggregationTypeText,
              }),
            )
          : null,
        isSortable: hideIfFullscreen(true),
      })),
    }));
  }

  get columnsDropdown() {
    return this.columnsData
      .filter(({ hideable }) => hideable)
      .map((column) => {
        return {
          text: column.label,
          value: column.valuePath,
          isSelected: this.selectedColumns.includes(column.valuePath),
        };
      });
  }

  get columnsDropdownGrouped() {
    return this.columnsDataGrouped
      .filter(({ hideGroup }) => !hideGroup)
      .map((group) => ({
        heading: group.heading,
        items: group.items.map((column) => ({
          text: column.label,
          value: column.valuePath,
          isSelected: this.selectedColumns.includes(column.valuePath),
        })),
      }));
  }

  get defaultSelectedColumns() {
    return this.columnsData.filter(({ hideable }) => hideable).map(({ valuePath }) => valuePath);
  }

  get displayEmptyState() {
    return this.args.selectedAssigneeIds.length === 0;
  }

  @action openDrilldownDrawer(cell: any) {
    this.selectedDrilldownCell = {
      ...cell,
      metricName: this.columns.find(({ metricType }) => metricType === cell.metricType)?.label,
    };
    this.args.openDrilldownDrawer(this.selectedDrilldownCell);
  }

  @action openTeamsSelector() {
    (document.querySelector('[data-monitoring-filter-selector=teams]') as HTMLElement).click();
  }

  @action async changeAssignees(newSelectedAssigneeIds: number[]) {
    try {
      await this.args.saveSelectedAssigneeIds(newSelectedAssigneeIds);
      this._trackSelectionChanged();
    } catch (e) {
      // Do not refresh
      return;
    }

    // Refresh if saved successfully
    this.isLoading = true;
    this.numberOfVisibleRows = this.pageSize;
    taskFor(this.refresh).perform();
  }

  @action changeTimeframe(newTimeFrame: number) {
    this.currentTimeframe = newTimeFrame;
    this.isSwitchingTimeframe = true;
    taskFor(this.refresh).perform();
    this.args.saveSelectedTeammateActivityTimeframe(newTimeFrame);
  }

  @action changeTeammateStatus() {
    taskFor(this.refresh).perform();
  }

  @action changeStatusFilter(newStatusFilter: string[]) {
    this.currentStatuses = newStatusFilter;
    this.isLoading = true;
    taskFor(this.refresh).perform();
    this.args.saveSelectedTeammateStatuses(this.currentStatuses);
  }

  @action columnsSelectionChanged(newSelection: string[]) {
    this.selectedColumns = newSelection;
    this.args.saveSelectedTeammateActivityColumns(this.selectedColumns);
  }

  @action toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
  }

  @action onSort(valuePath: string) {
    let direction = 'asc';
    if (this.sortState.valuePath === valuePath && this.sortState.direction === 'asc') {
      direction = 'desc';
    }
    let sortKey = this.columnsData.find((col) => col.valuePath === valuePath)?.sortKey || valuePath;
    this.sortState = { valuePath, direction, sortKey };

    this._sortData();
  }

  @action
  onLoadMore() {
    this.numberOfVisibleRows += this.pageSize;
    this._trackNumberOfVisibleTeammates('loaded_more');
  }

  @task({ restartable: true }) *refresh({ hasChangedSettings } = { hasChangedSettings: false }) {
    this.hasChangedSettings = hasChangedSettings;
    let refreshCounter = this.refreshCounter();
    while (true) {
      if (document.hidden) {
        yield timeout(3 * 1000);
        refreshCounter.reset();
      } else {
        try {
          yield taskFor(this.doRefresh).perform(refreshCounter.excludedQueryTypes());
          refreshCounter.increase();
        } catch (err) {
          this.notificationsService.notifyError(
            `Failed to refresh teammates. Next try in ${DEFAULT_REFRESH_TIME_IN_SECONDS} seconds.`,
          );
        }
        if (ENV.environment === 'test') {
          break;
        } else {
          yield timeout(DEFAULT_REFRESH_TIME_IN_SECONDS * 1000);
        }
      }
    }
  }

  @task({ restartable: true }) *doRefresh(excludedQueryTypes: string[] = []): TaskGenerator<void> {
    if (this.args.selectedAssigneeIds.length > 0) {
      let teammatesData = yield this.inbox2TeammateActivityService.getTeammateActivityForTeams(
        this.args.selectedAssigneeIds,
        {
          timeframeInHours: this.currentTimeframe,
          aggregationType: this.settings.aggregationType,
          idleThreshold: this.settings.idleThreshold,
          excludeBotTime: this.settings.excludeBotTime,
          excludedQueryTypes,
          selectedTeammateStatuses: this.currentStatuses,
        },
        {
          cachedOverview: this.data,
          cachedAggregations: this.aggregations,
        },
      );
      this.data = teammatesData.overview;
      this.aggregations = teammatesData.aggregations;
      this.callsAggregations = teammatesData.callsAggregations;
    } else {
      this.data = [];
    }
    this._sortData();
    this.selectedAggregationMetrics = this.updatedSelectedAggregationMetrics();
    if (this.isLoading) {
      this.isLoading = false;
      this._trackNumberOfVisibleTeammates('loaded');
    }
    if (this.isSwitchingTimeframe) {
      this.isSwitchingTimeframe = false;
    }
    if (this.hasChangedSettings) {
      this.hasChangedSettings = false;
    }
    if (this.isChangingTeammateStatus) {
      this.isChangingTeammateStatus = false;
    }
    this._trackNumberOfVisibleTeammates('refreshed');
  }

  _trackNumberOfVisibleTeammates(action: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'teammate_activity_table',
      place: 'monitoring',
      assigneeIds: this.args.selectedAssigneeIds,
      numberOfVisibleTeammates: Math.min(this.data.length, this.numberOfVisibleRows),
      tabVisible: !document.hidden,
      numberOfTeammates: this.data.length,
      sortColumn: this.sortState.valuePath,
      sortDirection: this.sortState.direction,
      selectedColumns: this.selectedColumns,
      tabCurrentlySelected: this.args.selectedTab === 'teammates',
    });
  }

  _trackSelectionChanged() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selection_changed',
      object: 'teammate_activity_table',
      place: 'monitoring',
      assigneeIds: this.args.selectedAssigneeIds,
    });
  }

  _sortData() {
    let { sortKey, direction } = this.sortState;
    let [blankData, sortableData] = partition(this.data, (data) => isEmpty(data[sortKey]));
    let sortedData = sortBy(sortableData, 'name').sortBy(sortKey);
    if (direction === 'desc') {
      sortedData = sortedData.reverse();
    }
    this.data = [...sortedData, ...sortBy(blankData, 'name')];
  }

  refreshCounter() {
    let count = 0;

    return {
      reset: () => (count = 0),
      increase: () => (count = (count % 15) + 1),
      excludedQueryTypes: () => (count % 15 === 0 ? [] : ['csat']),
    };
  }

  getValidStatusIndicatorValue(statusIndicator: string | undefined) {
    if (
      statusIndicator === 'active' ||
      statusIndicator === 'away' ||
      statusIndicator === 'reassigning'
    ) {
      return statusIndicator;
    }
    return undefined;
  }

  updatedSelectedAggregationMetrics(): AggregationMetric[] {
    if (this.canShowCallsMetrics) {
      return REALTIME_DASHBOARD_TEAMMATES_AGGREGATION_METRICS.map((metric) => ({
        ...metric,
        title: this.intl.t(metric.titleKey, {
          aggregationType: this.settings.aggregationTypeText,
        }),
        value:
          metric.category === 'conversations'
            ? (this.aggregations as ConversationsActivityAggregations)[
                metric.valueKey as keyof ConversationsActivityAggregations
              ]
            : (this.callsAggregations as CallsActivityAggregations)[
                metric.valueKey as keyof CallsActivityAggregations
              ],
        tooltipContent: this.intl.t(metric.tooltipContentKey, {
          threshold: this.settings.idleThresholdText,
          timeframe: this.currentTimeframe,
          aggregationType: this.settings.aggregationTypeText,
        }),
        statusIndicator: this.getValidStatusIndicatorValue(metric.statusIndicator),
        awayReasons: metric.awayReasonsKey
          ? (this.aggregations as ConversationsActivityAggregations)[
              metric.awayReasonsKey as 'awayReasons' | 'reassigningReasons'
            ]
          : undefined,
      }));
    }
    return [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Teammates::Table': typeof Table;
    'inbox2/dashboard/teammates/table': typeof Table;
  }
}
