/* import __COLOCATED_TEMPLATE__ from './fin-answer.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { FinAnswerType } from 'embercom/objects/inbox/renderable-data';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type FinAnswer from 'embercom/objects/inbox/renderable/fin-answer';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import { EntityType } from 'embercom/models/data/entity-types';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';

interface FinAnswerRenderablePart extends RenderablePart {
  renderableData: FinAnswer;
}

interface Args {
  partGroup: PartGroup;
  part: FinAnswerRenderablePart;
  conversation: Conversation;
  disableFinDebugger?: boolean; //use this to hide the fin debugger btn
  displayCreatedAt?: boolean; //render createdAt below title
  appendPublicSources: boolean; // show teammate-facing public sources section after the answer
}

interface Signature {
  Args: Args;
  Blocks: {
    blocksContent: [];
  };
}

export default class FinAnswerComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @tracked finViewPart: FinAnswerRenderablePart | null = null;

  readonly macroType = EntityType.SavedReply;
  readonly contentSnippetType = EntityType.ContentSnippet;
  readonly contentSnippetParamName = objectNames[EntityType.ContentSnippet];

  get renderableData() {
    return this.args.part.renderableData;
  }

  get finIssueSummary() {
    return 'issueSummary' in this.renderableData && this.renderableData.issueSummary;
  }

  get isFinDebuggerOpen() {
    return this.finViewPart !== null;
  }

  get canUseFinAnswerDebugger() {
    return !this.args.disableFinDebugger && this.finIssueSummary;
  }

  get isOutOfDomainAnswerType() {
    return this.args.part.renderableData.finAnswerType === FinAnswerType.OutOfDomain;
  }

  @action setFinViewPart(part: FinAnswerRenderablePart): void {
    this.finViewPart = part;
    if (this.finViewPart !== null) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'open_debugger',
        object: 'improve_answer_button',
        conversation_id: this.args.conversation.id,
        context: 'conversation_part',
        place: 'inbox',
        part_id: part.id,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::BotContent::FinAnswer': typeof FinAnswerComponent;
    'inbox2/conversation-stream/part-groups/bot-content/fin-answer': typeof FinAnswerComponent;
  }
}
