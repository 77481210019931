/* import __COLOCATED_TEMPLATE__ from './prepare-workspace.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type CopilotApi from 'embercom/services/copilot-api';
import type Session from 'embercom/services/session';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';

export interface Args {
  renderSkipButtonFor: ('join-waitlist' | 'on-the-waitlist')[];
  onSkip?: () => void;
  isCopilotVisible: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class PrepareWorkspace extends Component<Signature> {
  @service declare copilotApi: CopilotApi;
  @service declare session: Session;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare permissionsService: any;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  @tracked shouldRenderRequestToUpgradeModal = false;

  get isAdmin(): boolean {
    return this.session.teammate.permissions.canAccessBillingSettings;
  }

  get hasConsentedToIngestion(): boolean {
    return this.session.workspace.hasConsentedToFinIngestion || false;
  }

  get hasJoinedWaitlist(): boolean {
    return this.session.teammate.optedIntoCopilotWaitlist;
  }

  @action async consentToIngestionAndSetupCopilot() {
    await this.copilotApi.consentToIngestionAndSetupCopilot();

    this.intercomEventService.trackAnalyticsEvent({
      object: 'waitlist',
      action: 'consent_given',
      section: 'copilot',
    });
  }

  @action onSkip() {
    if (this.args.onSkip) {
      this.args.onSkip();
    } else {
      this.inboxSidebarService.updateActiveTab('details');
    }

    this.intercomEventService.trackAnalyticsEvent({
      object: 'waitlist',
      action: 'skipped',
      section: 'copilot',
    });
  }

  @action trackPrepareYourWorkspaceClicked() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'waitlist',
      action: 'clicked_prepare_workspace',
      section: 'copilot',
    });
  }

  @action trackLearnMoreClicked() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'waitlist',
      action: 'clicked_learn_more',
      section: 'copilot',
    });
  }

  @action trackAddKnowledgeContentClicked() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'waitlist',
      action: 'clicked_add_knowledge_content',
      section: 'copilot',
    });
  }

  @action trackWaitlistShown(state: string) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'waitlist',
      action: 'seen',
      section: 'copilot',
      state,
      has_joined_waitlist: this.hasJoinedWaitlist,
    });
  }

  @action openPermissionsModal() {
    this.permissionsService.loadAllAdminsAndShowPermissionRequestModalForMultiplePermissions(
      ['can_access_billing_settings'],
      this.intl.t('inbox.copilot-waitlist.permissions-modal.title'),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Setup::PrepareWorkspace': typeof PrepareWorkspace;
    'inbox2/copilot/setup/prepare-workspace': typeof PrepareWorkspace;
  }
}
