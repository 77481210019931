/* import __COLOCATED_TEMPLATE__ from './actions.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type CommandKService from 'embercom/services/command-k';
import { type DurationType } from 'embercom/objects/inbox/duration';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type InboxState from 'embercom/services/inbox-state';
import type Session from 'embercom/services/session';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';

interface Args<T> {
  conversation: T;
  openConversation: (conversation: T) => unknown;
  closeConversation: (conversation: T) => unknown;
  snoozeConversation: (conversation: T, duration: DurationType) => unknown;
  assignConversationToTeam: (conversation: T, team: TeamSummary) => unknown;
  assignConversationToAdmin: (conversation: T, admin: AdminSummary) => unknown;
  togglePriority: (conversation: T, priority: boolean) => unknown;
  changeTicketState?: (conversation: T, ticketState: TicketCustomState) => unknown;
  hideConversationEvents: boolean;
  onToggleConversationEvents: () => unknown;
}

interface Signature<T> {
  Args: Args<T>;
}

export default class Inbox2ConversationPreviewPanelActionsComponent extends Component<
  Signature<Conversation | ConversationTableEntry>
> {
  @service declare commandK: CommandKService;
  @service declare intercomEventService: any;
  @service declare inboxState: InboxState;
  @service declare session: Session;

  @action open() {
    this.args.openConversation(this.args.conversation);
    this.trackOpen();
  }

  private trackOpen() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      section: 'conversation_preview',
      object: 'conversation',
      layout_type: this.inboxState.activeConversationsView,
    });
  }

  @action close() {
    this.args.closeConversation(this.args.conversation);
    this.trackClose();
  }

  private trackClose() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      section: 'conversation_preview',
      object: 'conversation',
      layout_type: this.inboxState.activeConversationsView,
    });
  }

  @action snooze() {
    this.commandK.registerAndShow({
      actionID: 'snooze',
      onSelect: (duration: DurationType) => {
        this.args.snoozeConversation(this.args.conversation, duration);
        this.trackSnooze(duration);
      },
    });
  }

  private trackSnooze(duration: DurationType) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'snoozed',
      section: 'conversation_preview',
      object: 'conversation',
      layout_type: this.inboxState.activeConversationsView,
      snoozed_until: duration,
    });
  }

  @action assignToTeammate() {
    this.commandK.registerAndShow({
      actionID: 'assign-to',
      context: { excludeTeams: true },
      onSelect: (admin: AdminSummary) => {
        this.args.assignConversationToAdmin(this.args.conversation, admin);
      },
    });
  }

  @action assignToTeam() {
    this.commandK.registerAndShow({
      actionID: 'assign-to',
      context: { excludeAdmins: true },
      onSelect: (team: TeamSummary) => {
        this.args.assignConversationToTeam(this.args.conversation, team);
      },
    });
  }

  @action togglePriority() {
    this.args.togglePriority(this.args.conversation, !this.args.conversation.priority);
  }

  get showTicketState() {
    if (!this.args.changeTicketState) {
      return;
    }

    return this.args.conversation.isTicket;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPreviewPanel::Actions': typeof Inbox2ConversationPreviewPanelActionsComponent;
    'inbox2/conversation-preview-panel/actions': typeof Inbox2ConversationPreviewPanelActionsComponent;
  }
}
