/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { getOwner, setOwner } from '@ember/application';

import type Router from '@ember/routing/router-service';
import type Session from 'embercom/services/session';
import type InboxApi from 'embercom/services/inbox-api';
import type CustomIntlService from 'embercom/services/intl';
import type CommandKService from 'embercom/services/command-k';
import { DisplayContext } from 'embercom/services/command-k';
import AttributesResolver from 'embercom/objects/inbox/attributes-resolver';
import { Config } from 'embercom/objects/inbox/composer-config';
import { BlocksDocument, type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import {
  MacroAction,
  type MacroActionType,
  transformMacroActions,
  type MacroType,
  transformMacroTypes,
  MacroVisibility,
} from 'embercom/objects/inbox/macro';
import trimWhitespaces from 'embercom/lib/inbox/composer-helpers/trim-whitespaces';

import safeWindowOpen from 'embercom/lib/safe-window-open';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import { type BlockList } from '@intercom/interblocks.ts';
import type Inbox2MacrosSearch from 'embercom/services/inbox2-macros-search';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';

interface Args {
  onClose: () => void;
  blocks?: BlockList;
  initialMacroType?: MacroType;
  commandKActionPriority?: number;
  analyticsMetadata?: Record<string, unknown>;
}

interface Signature {
  Args: Args;
}

export default class CreateMacroModal extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare intl: CustomIntlService;
  @service declare router: Router;
  @service declare session: Session;
  @service declare commandK: CommandKService;
  @service declare intercomEventService: any;
  @service declare inbox2MacrosSearch: Inbox2MacrosSearch;

  @service notificationsService: any;

  @tracked api?: ComposerPublicAPI;
  @tracked blocksDoc: BlocksDocument;
  @tracked macroActions: MacroAction[] = [];
  @tracked types: MacroType[] = this.args.initialMacroType ? [this.args.initialMacroType] : [];
  @tracked visibility: MacroVisibility = MacroVisibility.Everyone;
  @tracked visibleTeams: TeamSummary[] = [];
  @tracked name = '';

  readonly config: Config;
  readonly allowedAttachmentFileTypes = ['*'];
  readonly allowedImageFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let resolver = new AttributesResolver();
    setOwner(resolver, getOwner(this));

    this.config = new Config(
      getOwner(this),
      this.session.workspace.id,
      {
        allowedImageFileTypes: this.allowedImageFileTypes,
        allowedAttachmentTypes: this.allowedAttachmentFileTypes,
        uploader: EmbercomFileUploader,
        attrs: { policyUrl: `/apps/${this.session.workspace.id}/uploads` },
      },
      undefined,
      resolver,
    );
    this.blocksDoc = new BlocksDocument(this.args.blocks);
    this.config.placeholder = this.intl.t('inbox.create-macro.body-placeholder');
    this.commandK.setActivationContext(DisplayContext.CreateMacro);
  }
  willDestroy() {
    super.willDestroy();
    this.commandK.resetActivationContext();
  }

  get isUploading() {
    return this.api?.composer.state.isUploading;
  }

  get disableSave() {
    return (
      this.name.trim().length === 0 ||
      this.composerIsEmpty ||
      this.isUploading ||
      this.createMacroTask.isRunning
    );
  }

  get composerIsEmpty() {
    return (
      (!this.api?.composer.state.hasContent || this.trimmedBlocksDoc.blocks.length === 0) &&
      (this.macroActions ?? []).length === 0
    );
  }

  get visibleForTeamIds() {
    return this.visibleTeams.map((team: TeamSummary) => team.id);
  }

  get canSeeNewSettings() {
    return this.session.workspace.isFeatureEnabled('team-activation-channels-new-ia-settings-hub');
  }

  @action onReady(api: ComposerPublicAPI) {
    this.api = api;
  }

  @action onChange(blocksDoc: BlocksDocument) {
    this.blocksDoc = blocksDoc;
  }

  @action addMacroAction(macroActionType: MacroActionType) {
    this.macroActions = [...this.macroActions, new MacroAction(macroActionType, {}, true)];
  }

  @action onDeleteMacroAction(macroAction: MacroAction) {
    this.macroActions = this.macroActions.without(macroAction);
  }

  @action selectTypes(option: { id: MacroType; label: string; isSelected: boolean }) {
    if (!option.isSelected) {
      this.types = [...this.types, option.id];
    } else {
      this.types = this.types.without(option.id);
    }
  }

  @action selectVisibility(option: { id: MacroVisibility }) {
    this.visibility = option.id;
    if (this.visibility !== MacroVisibility.Teams) {
      this.visibleTeams = [];
    }
  }

  @action selectVisibilityForTeams(team: TeamSummary) {
    if (this.visibleForTeamIds.includes(team.id)) {
      this.visibleTeams = this.visibleTeams.without(team);
    } else {
      this.visibleTeams = [...this.visibleTeams, team];
    }
  }

  @action openManageMacros() {
    let manageMacrosRoute = 'apps.app.settings.helpdesk.macros';
    let url: string = this.router.urlFor(manageMacrosRoute, this.session.workspace);
    safeWindowOpen(url, '_blank');
  }

  get createMacroTask() {
    return taskFor(this.createMacro);
  }

  @task({ drop: true })
  *createMacro(): TaskGenerator<void> {
    let data = {
      app_id: this.session.workspace.id,
      actions: transformMacroActions(this.macroActions),
      blocks: this.blocksDoc.blocks,
      has_updates: false,
      name: this.name,
      types: transformMacroTypes(this.types),
      visibility: this.visibility,
      visible_to_team_ids: this.visibleForTeamIds,
    } as Record<string, unknown>;

    let createdMacro = yield this.inboxApi.createMacro(data);

    // Reload macros to show the newly created macro, asynchronously to not block the UI
    this.inbox2MacrosSearch.loadAllMacros();

    this.notificationsService.notifyConfirmation(
      this.intl.t('inbox.create-macro.notifications.success', {
        name: this.name,
      }),
    );
    this.trackCreateMacro(createdMacro.id);
    this.args.onClose();
  }

  private trackCreateMacro(macroId: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      section: 'command_k',
      object: 'macro',
      macro_id: parseInt(macroId, 10),
      ...this.args.analyticsMetadata,
    });
  }

  private get trimmedBlocksDoc() {
    return trimWhitespaces(this.blocksDoc);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CreateMacro::Modal': typeof CreateMacroModal;
    'inbox2/create-macro/modal': typeof CreateMacroModal;
  }
}
