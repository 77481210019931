/* import __COLOCATED_TEMPLATE__ from './attribute-collected.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const AttributeCollected = templateOnlyComponent<Signature>();
export default AttributeCollected;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::BotFlowParts::AttributeCollected': typeof AttributeCollected;
    'inbox2/conversation-stream/bot-flow-parts/attribute-collected': typeof AttributeCollected;
  }
}
