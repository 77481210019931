/* import __COLOCATED_TEMPLATE__ from './user-became-idle.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface Args {
  part: any;
}

export default class UserBecameIdleComponent extends Component<Args> {
  @service declare session: Session;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::UserBecameIdle': typeof UserBecameIdleComponent;
    'inbox2/conversation-stream/event-parts/user-became-idle': typeof UserBecameIdleComponent;
  }
}
