/* import __COLOCATED_TEMPLATE__ from './participant-removed.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type ParticipantRemoved from 'embercom/objects/inbox/renderable/participant-removed';
import LinkWithText from 'embercom/helpers/link-with-text';

interface ParticipantRemovedPart extends RenderablePart {
  renderableData: ParticipantRemoved;
}

interface Args {
  part: ParticipantRemovedPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsParticipantRemovedPartComponent extends Component<Signature> {
  @service declare session: Session;

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get renderableData() {
    return this.args.part.renderableData;
  }

  get participant() {
    return this.renderableData.participantSummary;
  }

  get participantMerged() {
    return !!this.participant.mergedIntoId;
  }

  get participantLink() {
    return this.linkWithTextHelper.compute([
      'apps.app.users.user',
      this.participant.id,
      this.participant.displayAs,
    ]);
  }

  get admin() {
    return this.renderableData.adminSummary;
  }

  get translationData() {
    let key;

    if (this.admin.id === this.session.teammate.id) {
      key = this.participantMerged ? 'you-removed-participant-merged' : 'you-removed-participant';
    } else {
      key = this.participantMerged
        ? 'entity-removed-participant-merged'
        : 'entity-removed-participant';
    }

    let data = {
      participant: this.participantLink,
      admin: this.linkWithTextHelper.compute([
        'apps.app.admins.admin',
        this.admin.id,
        this.admin.name,
      ]),
    };

    return { key, data };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::ParticipantRemoved': typeof Inbox2ConversationStreamEventPartsParticipantRemovedPartComponent;
    'inbox2/conversation-stream/event-parts/participant-removed': typeof Inbox2ConversationStreamEventPartsParticipantRemovedPartComponent;
  }
}
