/* import __COLOCATED_TEMPLATE__ from './user-company-attributes-search-box.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const UserCompanyAttributesSearchBox = templateOnlyComponent<Signature>();
export default UserCompanyAttributesSearchBox;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::UserCompanyAttributesSearchBox': typeof UserCompanyAttributesSearchBox;
    'inbox2/conversation-details-sidebar/sidebar-sections/user-company-attributes-search-box': typeof UserCompanyAttributesSearchBox;
  }
}
