/* import __COLOCATED_TEMPLATE__ from './note.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Note from 'embercom/objects/inbox/note';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { modifier } from 'ember-modifier';

interface Args {
  note: Note;
  onSave?: (body: string) => Promise<void>;
}

interface Signature {
  Args: Args;
}

export default class NoteComponent extends Component<Signature> {
  @tracked isEditing = false;

  moveCursorToEndOnFocus = modifier((element: HTMLTextAreaElement) => {
    element.addEventListener('focus', () => {
      let end = element.value.length;
      element.setSelectionRange(end, end);
    });
  });

  get isUpdated() {
    return !!this.args.note.updatedBy;
  }

  get lastEditedBy() {
    if (this.isUpdated) {
      return this.args.note.updatedBy;
    } else {
      return this.args.note.createdBy;
    }
  }

  get lastEditedAt() {
    if (this.isUpdated) {
      return this.args.note.updatedAt;
    } else {
      return this.args.note.createdAt;
    }
  }

  get isSaving() {
    return taskFor(this.saveChanges).isRunning;
  }

  @action edit() {
    this.isEditing = true;
  }

  @task({ drop: true })
  *saveChanges(value: string) {
    if (!this.isEditing) {
      return;
    }
    if (this.args.onSave && value !== this.args.note.body) {
      yield this.args.onSave(value);
    }
    this.isEditing = false;
  }

  @action onBlur(event: Event) {
    let target = event.target as HTMLTextAreaElement;
    taskFor(this.saveChanges).perform(target.value);
  }

  @action onKeyDown(event: KeyboardEvent) {
    let target = event.target as HTMLTextAreaElement;
    if (event.key === 'Enter' && !event.shiftKey) {
      taskFor(this.saveChanges).perform(target.value);
      event.preventDefault();
    }
    if (event.key === 'Escape') {
      this.isEditing = false;
      event.preventDefault();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Notes::Note': typeof NoteComponent;
    'inbox2/conversation-details-sidebar/sidebar-sections/notes/note': typeof NoteComponent;
  }
}
