/* import __COLOCATED_TEMPLATE__ from './bulk-edit-no-permissions-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type InboxState from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';

interface Args {
  onClose: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class BulkEditNoPermissionsModal extends Component<Signature> {
  @service declare inboxState: InboxState;

  @action deselectConversationsAndContinueEditing() {
    this.inboxState.selectedConversations.conversationObjects.forEach((conversation) => {
      if (conversation.isInboundConversation) {
        this.inboxState.selectedConversations.toggle(conversation);
      }
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::BulkEditNoPermissionsModal': typeof BulkEditNoPermissionsModal;
    'inbox2/bulk-edit-no-permissions-modal': typeof BulkEditNoPermissionsModal;
  }
}
