/* import __COLOCATED_TEMPLATE__ from './priority-changed-by-rule.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { EntityType } from 'embercom/models/data/entity-types';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

export default class Inbox2ConversationStreamEventPartsPriorityChangedByRuleComponent extends Component {
  @service declare session: Session;

  entityType = EntityType;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::PriorityChangedByRule': typeof Inbox2ConversationStreamEventPartsPriorityChangedByRuleComponent;
    'inbox2/conversation-stream/event-parts/priority-changed-by-rule': typeof Inbox2ConversationStreamEventPartsPriorityChangedByRuleComponent;
  }
}
