/* import __COLOCATED_TEMPLATE__ from './impersonate-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { dropTask } from 'ember-concurrency-decorators';

import type User from 'embercom/objects/inbox/user';
import { impersonateInRegion } from 'embercom/lib/impersonate';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { type Hotkey } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';

interface Args {
  user: User;
}

interface Signature {
  Args: Args;
}

export default class ImpersonateButton extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxHotkeys: InboxHotkeys;

  readonly handleNotEditorHotkey;
  readonly impersonateHotkey: Hotkey;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.handleNotEditorHotkey = this.inboxHotkeys.handleNotEditorHotkey;
    this.impersonateHotkey = this.inboxHotkeys.hotkeysMap[HotkeyID.Impersonate];
  }

  get impersonateDropdownListItems() {
    return [
      {
        items: [
          {
            text: 'Impersonate Admin in US',
            value: 'us-east-1',
          },
          {
            text: 'Impersonate Admin in EU',
            value: 'eu-west-1',
          },
          {
            text: 'Impersonate Admin in AU',
            value: 'ap-southeast-2',
          },
        ],
      },
    ];
  }

  get shouldDisplay() {
    return this.session.workspace.isIntercom && this.args.user.hasUserRole;
  }

  @action async impersonateUser(region: string) {
    impersonateInRegion(region, this.args.user.userId, this.args.user.email);
  }

  @dropTask *impersonateUserUsEast1() {
    yield this.impersonateUser('us-east-1');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::ImpersonateButton': typeof ImpersonateButton;
    'inbox2/conversation-details-sidebar/sidebar-sections/impersonate-button': typeof ImpersonateButton;
  }
}
