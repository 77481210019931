/* import __COLOCATED_TEMPLATE__ from './hide-icon.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const HideIcon = templateOnlyComponent<Signature>();
export default HideIcon;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::Icons::HideIcon': typeof HideIcon;
    'inbox2/conversations-table/icons/hide-icon': typeof HideIcon;
  }
}
