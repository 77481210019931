/* import __COLOCATED_TEMPLATE__ from './custom-snooze.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import { calculateUnsnoozeTime, DurationType } from 'embercom/objects/inbox/duration';
import moment from 'moment-timezone';
import { type CustomSnoozeData } from './pane';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { later } from '@ember/runloop';
import type Session from 'embercom/services/session';
import storage from 'embercom/vendor/intercom/storage';
import { type EmberKeyboardEvent } from 'embercom/lib/inbox2/types';

interface Args {
  onDateSelected: (date: moment.Moment) => void;
  onTimeSelected: (data: CustomSnoozeData) => void;
}

interface Signature {
  Args: Args;
}

enum SelectedObject {
  DatePicker = 'datePicker',
  TimePicker = 'timePicker',
}

interface TimePickerOption {
  label: string;
  secondsFromMidnight: number;
}

const hoursWith30MinsInterval: TimePickerOption[] = [
  { label: '12:00 am', secondsFromMidnight: 0 },
  { label: '12:30 am', secondsFromMidnight: 1 * 1800 },
  { label: '1:00 am', secondsFromMidnight: 2 * 1800 },
  { label: '1:30 am', secondsFromMidnight: 3 * 1800 },
  { label: '2:00 am', secondsFromMidnight: 4 * 1800 },
  { label: '2:30 am', secondsFromMidnight: 5 * 1800 },
  { label: '3:00 am', secondsFromMidnight: 6 * 1800 },
  { label: '3:30 am', secondsFromMidnight: 7 * 1800 },
  { label: '4:00 am', secondsFromMidnight: 8 * 1800 },
  { label: '4:30 am', secondsFromMidnight: 9 * 1800 },
  { label: '5:00 am', secondsFromMidnight: 10 * 1800 },
  { label: '5:30 am', secondsFromMidnight: 11 * 1800 },
  { label: '6:00 am', secondsFromMidnight: 12 * 1800 },
  { label: '6:30 am', secondsFromMidnight: 13 * 1800 },
  { label: '7:00 am', secondsFromMidnight: 14 * 1800 },
  { label: '7:30 am', secondsFromMidnight: 15 * 1800 },
  { label: '8:00 am', secondsFromMidnight: 16 * 1800 },
  { label: '8:30 am', secondsFromMidnight: 17 * 1800 },
  { label: '9:00 am', secondsFromMidnight: 18 * 1800 },
  { label: '9:30 am', secondsFromMidnight: 19 * 1800 },
  { label: '10:00 am', secondsFromMidnight: 20 * 1800 },
  { label: '10:30 am', secondsFromMidnight: 21 * 1800 },
  { label: '11:00 am', secondsFromMidnight: 22 * 1800 },
  { label: '11:30 am', secondsFromMidnight: 23 * 1800 },
  { label: '12:00 pm', secondsFromMidnight: 24 * 1800 },
  { label: '12:30 pm', secondsFromMidnight: 25 * 1800 },
  { label: '1:00 pm', secondsFromMidnight: 26 * 1800 },
  { label: '1:30 pm', secondsFromMidnight: 27 * 1800 },
  { label: '2:00 pm', secondsFromMidnight: 28 * 1800 },
  { label: '2:30 pm', secondsFromMidnight: 29 * 1800 },
  { label: '3:00 pm', secondsFromMidnight: 30 * 1800 },
  { label: '3:30 pm', secondsFromMidnight: 31 * 1800 },
  { label: '4:00 pm', secondsFromMidnight: 32 * 1800 },
  { label: '4:30 pm', secondsFromMidnight: 33 * 1800 },
  { label: '5:00 pm', secondsFromMidnight: 34 * 1800 },
  { label: '5:30 pm', secondsFromMidnight: 35 * 1800 },
  { label: '6:00 pm', secondsFromMidnight: 36 * 1800 },
  { label: '6:30 pm', secondsFromMidnight: 37 * 1800 },
  { label: '7:00 pm', secondsFromMidnight: 38 * 1800 },
  { label: '7:30 pm', secondsFromMidnight: 39 * 1800 },
  { label: '8:00 pm', secondsFromMidnight: 40 * 1800 },
  { label: '8:30 pm', secondsFromMidnight: 41 * 1800 },
  { label: '9:00 pm', secondsFromMidnight: 42 * 1800 },
  { label: '9:30 pm', secondsFromMidnight: 43 * 1800 },
  { label: '10:00 pm', secondsFromMidnight: 44 * 1800 },
  { label: '10:30 pm', secondsFromMidnight: 45 * 1800 },
  { label: '11:00 pm', secondsFromMidnight: 46 * 1800 },
  { label: '11:30 pm', secondsFromMidnight: 47 * 1800 },
];

export default class CustomSnooze extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;

  @tracked selectedDate: moment.Moment = moment();
  @tracked disableDatesBefore = moment();
  @tracked currentSelectedObject = SelectedObject.DatePicker;
  @tracked selectedsecondsFromMidnight: number = this.initialSelectedSecondsFromMidnight();

  @ref('calendar-element') declare calendarElement: HTMLElement;
  @ref('time-picker-element') declare timePickerElement: HTMLElement;

  @action onEnter(event: KeyboardEvent, emberEvent: EmberKeyboardEvent) {
    event.preventDefault();
    this.stopEmberEventPropagation(emberEvent);
    let selected = event.target as HTMLElement;

    if (this.currentSelectedObject === SelectedObject.DatePicker) {
      this.currentSelectedObject = SelectedObject.TimePicker;

      if (selected?.getAttribute && selected.getAttribute('data-date')) {
        this.setSelectedDate(moment(selected.getAttribute('data-date')));
        // Timepicker refreshes its time options when the date is changed
        // So we run this code in the next event loop to focus the right first element in time picker
        later(() => {
          let firstTimeOption = this.timePickerElement.querySelector('button') as HTMLElement;
          if (firstTimeOption) {
            firstTimeOption.focus();
          }
        }, 0);
      }
    } else {
      this.currentSelectedObject = SelectedObject.DatePicker;

      if (selected && selected.getAttribute('data-time-seconds-from-midnight')) {
        this.setTime(Number(selected.getAttribute('data-time-seconds-from-midnight')));
      }
    }
  }

  @action onDateSelect(date: any) {
    this.currentSelectedObject = SelectedObject.DatePicker;
    this.setSelectedDate(date.moment);
  }

  private setSelectedDate(date: moment.Moment) {
    this.selectedDate = date;
    this.args.onDateSelected(date);
  }

  get hoursWith30MinsInterval() {
    let currentTime = moment();
    let midnight = currentTime.clone().startOf('day');
    let elapsedSecondsSinceMidnight = currentTime.diff(midnight, 'seconds');
    let utcOffsetDiff = midnight.utcOffset() - currentTime.utcOffset();

    if (utcOffsetDiff !== 0) {
      // We have transitioned from one timezone to another because of daylight savings starting or ending
      // We want to discount or add back the time change to make sure we get an accurate count of hours from midnight -> now
      // Negative means moving forward: we had x fewer minutes during the morning (e.g. 1 hour forward for GMT -> BST)
      // Positive means moving backwards: we had x more minutes during the morning (e.g. 1 hour backward for BST -> GMT)
      // Absolute value of how much we need to change the secondsSinceMidnight by
      let timezoneChangeDiff = Math.abs(utcOffsetDiff);

      // Negative: we moved forward and "lost" minutes => add them back
      // Positive: we moved backward and "gained" minutes => remove them
      if (utcOffsetDiff < 0) {
        elapsedSecondsSinceMidnight += timezoneChangeDiff * 60;
      } else {
        elapsedSecondsSinceMidnight -= timezoneChangeDiff * 60;
      }
    }

    return hoursWith30MinsInterval.filter(({ secondsFromMidnight }) => {
      if (!this.selectedDate.startOf('day').diff(midnight, 'days')) {
        return secondsFromMidnight >= elapsedSecondsSinceMidnight;
      } else {
        return true;
      }
    });
  }

  @action focusCurrentDate() {
    let currentDateBtn = this.calendarElement.querySelector(
      `[data-date="${this.selectedDate.format('yyyy-MM-DD')}"]`,
    ) as HTMLElement;
    if (currentDateBtn) {
      currentDateBtn.focus();
    }
  }

  @action setTime(secondsFromMidnight: number) {
    let unsnoozeTime = calculateUnsnoozeTime(
      DurationType.CustomTime,
      this.selectedDate,
      secondsFromMidnight,
    );
    storage.set(this.storageKey(), secondsFromMidnight);
    this.args.onTimeSelected({ time: unsnoozeTime.toISOString() });
  }

  // Arrow right key selects the current option in Cmd+K. We want to prevent that in custom snooze
  @action onArrowRight(_: any, emberEvent: any) {
    this.stopEmberEventPropagation(emberEvent);
  }

  // Arrow left key makes the command-K menu go back one level. We want to prevent that in custom snooze
  @action onArrowLeft(_: any, emberEvent: any) {
    this.stopEmberEventPropagation(emberEvent);
  }

  @action onArrowDown(event: KeyboardEvent, emberEvent: any) {
    this.stopEmberEventPropagation(emberEvent);
    if (this.currentSelectedObject === SelectedObject.TimePicker) {
      let elem = event.target as HTMLElement;
      let sibling = elem?.nextElementSibling as HTMLElement;
      sibling?.focus();
    }
  }

  @action onArrowUp(event: KeyboardEvent, emberEvent: any) {
    this.stopEmberEventPropagation(emberEvent);
    if (this.currentSelectedObject === SelectedObject.TimePicker) {
      let elem = event.target as HTMLElement;
      let sibling = elem?.previousElementSibling as HTMLElement;
      sibling?.focus();
    }
  }

  private updateSelectedSecondsFromElement(elem: HTMLElement) {
    let selectedValue = elem?.getAttribute('data-time-seconds-from-midnight');
    if (selectedValue) {
      this.selectedsecondsFromMidnight = Number(selectedValue);
    }
  }

  @action onDatePickerFocus() {
    this.currentSelectedObject = SelectedObject.DatePicker;
  }

  @action onTimePickerFocus(event: FocusEvent) {
    this.currentSelectedObject = SelectedObject.TimePicker;
    let elem = event.target as HTMLElement;
    this.updateSelectedSecondsFromElement(elem);
  }

  private stopEmberEventPropagation(emberEvent: any) {
    emberEvent?.stopPropagation();
    emberEvent?.stopImmediatePropagation();
  }

  private storageKey(): string {
    return `custom-snooze-selected-time-${this.session.workspace.id}`;
  }

  private initialSelectedSecondsFromMidnight(): number {
    let storedSecondsFromMidnight = storage.get(this.storageKey());
    if (storedSecondsFromMidnight) {
      return Number(storedSecondsFromMidnight);
    } else {
      return -1;
    }
  }

  get defaultHourLabel() {
    if (this.selectedsecondsFromMidnight === -1) {
      return '9:00 AM';
    } else {
      let selected = this.hoursWith30MinsInterval.filter(({ secondsFromMidnight }) => {
        return secondsFromMidnight === this.selectedsecondsFromMidnight;
      });
      return selected[0]?.label || '9:00 AM';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Snooze::CustomSnooze': typeof CustomSnooze;
    'inbox2/command-k/snooze/custom-snooze': typeof CustomSnooze;
  }
}
