/* import __COLOCATED_TEMPLATE__ from './link-github-form.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type InboxApi from 'embercom/services/inbox-api';
import type Snackbar from 'embercom/services/snackbar';

interface Args {
  conversationId: number;
  onLinkCreate?: () => void;
  githubLinkInputOpened?: boolean;
  toggleInputOpened: () => void;
}

interface Signature {
  Args: Args;
}

export default class LinkGithubForm extends Component<Signature> {
  @service declare snackbar: Snackbar;
  @service declare inboxApi: InboxApi;

  @tracked githubLink = '';
  @tracked showError = false;

  @task({ drop: true })
  *createGithubLink() {
    if (!this.githubLink) {
      this.showError = true;
      return;
    }
    try {
      yield this.inboxApi.createGithubLink(this.args.conversationId, this.githubLink);
      this.showError = false;
      this.githubLink = '';
      this.snackbar.notify('Github issue linked');
      if (this.args.onLinkCreate) {
        this.args.onLinkCreate();
      }
    } catch (e) {
      if (e.jqXHR) {
        this.showError = true;
        this.snackbar.notifyError(e.jqXHR.responseJSON?.errors);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::LinkedGithubIssues::LinkGithubForm': typeof LinkGithubForm;
    'inbox2/conversation-details-sidebar/sidebar-sections/linked-github-issues/link-github-form': typeof LinkGithubForm;
  }
}
