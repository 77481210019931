/* import __COLOCATED_TEMPLATE__ from './checklists.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type InboxApi from 'embercom/services/inbox-api';
import { type Checklist, ChecklistStatus } from 'embercom/objects/inbox/checklists';
import type IntlService from 'embercom/services/intl';
import { trackedFunction } from 'ember-resources/util/function';

interface Args {
  userId: string;
  isExpanded: boolean;
  onExpandedSectionChange: (expandedSectionId?: string) => void;
  onConversationOrChecklistSelected?: () => void;
  isPreviewingConversation?: boolean;
}

interface Signature {
  Args: Args;
}

const INITIAL_CHECKLISTS_TO_SHOW = 3;

export default class Checklists extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;
  @tracked focusedChecklist?: Checklist = undefined;
  @tracked conversationId?: number = undefined;

  data = trackedFunction(this, async () => {
    let { checklists } = await this.inboxApi.getUserChecklists(this.args.userId);
    return checklists;
  });

  get recentChecklists() {
    return this.data.value ?? [];
  }

  @action handleChecklistClick(checklist: Checklist) {
    this.focusedChecklist = checklist;
    this.args.onExpandedSectionChange('in-app-checklists');

    this.args.onConversationOrChecklistSelected && this.args.onConversationOrChecklistSelected();
  }

  @action handleSectionChange(sectionId?: string) {
    this.focusedChecklist = undefined;
    this.args.onExpandedSectionChange(sectionId);
  }

  get noChecklistsToShow() {
    return this.recentChecklists.length === 0;
  }

  get isViewingFocusedChecklist() {
    return !!this.focusedChecklist;
  }

  get inProgressChecklists() {
    return this.recentChecklists.filter((c) => c.status !== ChecklistStatus.Complete).length;
  }

  get initialChecklists() {
    return this.recentChecklists.slice(0, INITIAL_CHECKLISTS_TO_SHOW);
  }

  get checklistsToDisplay() {
    return this.args.isExpanded ? this.recentChecklists : this.initialChecklists;
  }

  get hasMoreChecklistsToShow() {
    return this.recentChecklists.length > INITIAL_CHECKLISTS_TO_SHOW;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Checklists::Checklists': typeof Checklists;
    'inbox2/conversation-details-sidebar/sidebar-sections/checklists/checklists': typeof Checklists;
  }
}
