/* import __COLOCATED_TEMPLATE__ from './time-divider.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import moment from 'moment-timezone';
interface Args {
  index: number;
  partGroups: Array<PartGroup>;
  now?: Date;
}

interface Signature {
  Args: Args;
}

type DateFormatterStyle =
  | 'relative'
  | 'absolute-hour'
  | 'absolute-day-month'
  | 'absolute-day-month-year';

export default class Inbox2ConversationStreamTimeDividerComponent extends Component<Signature> {
  get time(): Date | void {
    if (this.args.partGroups[this.args.index]) {
      return this.args.partGroups[this.args.index].parts[0].createdAt;
    }
  }

  get dateFormatterStyle(): DateFormatterStyle {
    if (this.time) {
      let now = this.getNow();
      let partCreationTime = moment(this.time).tz(this.timezone);

      if (now.diff(partCreationTime, 'year') === 0) {
        return 'absolute-day-month';
      } else {
        return 'absolute-day-month-year';
      }
    }

    return 'relative';
  }

  get timezone() {
    return moment.tz.guess();
  }

  // Exposed for testing purposes as our `travelToDate` helper can be flakey.
  private getNow() {
    if (this.args.now) {
      return moment(this.args.now).tz(this.timezone);
    } else {
      return moment().tz(this.timezone);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::TimeDivider': typeof Inbox2ConversationStreamTimeDividerComponent;
    'inbox2/conversation-stream/time-divider': typeof Inbox2ConversationStreamTimeDividerComponent;
  }
}
