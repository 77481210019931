/* import __COLOCATED_TEMPLATE__ from './links.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { inject as service } from '@ember/service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { action } from '@ember/object';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import type InboxApi from 'embercom/services/inbox-api';
import type Session from 'embercom/services/session';
import {
  type BulkReportsLinkedEvent,
  type ThreadUpdatedEvent,
  type SideConversationEvent,
  type TicketStateUpdatedEvent,
} from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { type NonExpandableSidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import type InboxState from 'embercom/services/inbox-state';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'ember-intl/services/intl';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Router from '@ember/routing/router-service';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { noCache } from 'embercom/lib/cached-decorator';
import { tracked } from '@glimmer/tracking';

type Args = NonExpandableSidebarSectionComponentArgs<{
  conversation: Conversation;
  sidebarSection: SidebarSection;
  readonly?: boolean;
  setTicketTypeToLinkToConversation: (ticketTypeId: number) => void;
  onConversationOrChecklistSelected?: () => void;
  previewOnly?: boolean;
}>;

interface Signature {
  Args: Args;
}

export default class Links extends Component<Signature> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;
  @service declare nexus: Nexus;
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare router: Router;

  @tracked linkedItems: LinkedTicketSummary[] = [];
  @tracked linkedItemsCount = 0;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.getLinkedTicketsData();
  }

  async getLinkedTicketsData(useCache = true) {
    let inboxApi = useCache === false ? noCache(this.inboxApi) : this.inboxApi;
    let { tickets, totalCount } = await inboxApi.getLinkedTickets(this.args.conversation.id);
    this.linkedItems = tickets;
    this.linkedItemsCount = totalCount;
  }

  get allLinkedItemsCount() {
    return this.linkedItemsCount + this.sideConversations.length;
  }

  get sideConversations() {
    return this.sideConversationData.value ?? [];
  }

  get linkedTrackerTickets() {
    return this.linkedItems.filter((ticket) => ticket.category === TicketCategory.Tracker);
  }

  get linkedTaskTickets() {
    return this.linkedItems
      .filter((ticket) => ticket.category === TicketCategory.Task)
      .sort((ticketA, ticketB) => {
        // Show shared tasks first
        return Number(ticketB.visibleToUser) - Number(ticketA.visibleToUser);
      });
  }

  get isSidebarWidthLessThan280() {
    return this.inboxState.sidebarWidthInPixels <= 280;
  }

  get activeConversationId() {
    return this.inboxState?.activeConversation?.id;
  }

  sideConversationData = DeduplicatedAsyncData(
    this,
    () => [this.args.conversation.id],
    async (conversationId, { signal }) => {
      return await this.inboxApi.getSideConversations(conversationId, { signal });
    },
  );

  @action handleConversationChanged() {
    this.getLinkedTicketsData();
  }

  @action handleSideConversationStarted(e: SideConversationEvent) {
    let data = e.eventData;
    if (data.parent_conversation_id === this.args.conversation.id) {
      this.sideConversationData.reload();
    }
  }

  @action reloadSideConversations() {
    this.sideConversationData.reload();
  }

  @action async handleTicketStateUpdatedEvent(e: TicketStateUpdatedEvent) {
    let data = e.eventData;
    let ticketId = parseInt(data.conversation_id, 10);

    let ticket = this.linkedItems.find((ticket) => ticket.id === ticketId);
    if (ticket) {
      this.getLinkedTicketsData();
    }
  }

  @action async handleThreadUpdatedEvent(e: ThreadUpdatedEvent) {
    let data = e.eventData;
    if (data.subType !== 'entity_linked') {
      return;
    }

    this.getLinkedTicketsData();
  }

  @action async handleLinkedTicketsUpdatedEvent() {
    this.getLinkedTicketsData();
  }

  @action async handleBulkReportsLinkedEvent(e: BulkReportsLinkedEvent) {
    if (e.eventData.conversation_id === this.args.conversation.id) {
      this.getLinkedTicketsData(false);
    }
  }

  @action async handlePreviewClick(itemId: number) {
    if (this.activeConversationId === itemId) {
      return;
    }

    let inbox = this.inboxState.activeInbox || {
      id: InboxType.All,
      category: InboxCategory.Shared,
    };

    let url: string = this.router.urlFor(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      inbox.category,
      inbox.id,
      itemId,
    );
    safeWindowOpen(url);
  }

  @action async onLinkedTicketClick(item: LinkedTicketSummary) {
    if (this.args.readonly) {
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'linked_ticket',
      section: 'respond',
      place: 'details_sidebar',
    });

    if (this.args.previewOnly) {
      this.handlePreviewClick(item.id);
    } else {
      this.args.onConversationOrChecklistSelected && this.args.onConversationOrChecklistSelected();
      this.inboxSidebarService.previewConversation(item);
    }
  }

  @action async onUnlinkTicket(ticketId: number) {
    try {
      await this.inboxApi.unlinkTicket(ticketId, this.args.conversation.id);
      if (this.args.conversation.isCustomerTicket) {
        this.snackbar.notify(this.intl.t(`inbox.linked-tickets.ticket-unlinked-from-ticket`));
      } else {
        this.snackbar.notify(this.intl.t(`inbox.linked-tickets.ticket-unlinked`));
      }
      this.getLinkedTicketsData();
    } catch (e) {
      this.snackbar.notifyError(this.intl.t('inbox.linked-tickets.ticket-unlinking-failed'));
    }
  }

  @action async onLinkTicket(ticket: LinkedTicketSummary) {
    let ticketId = ticket?.id;
    if (!ticketId) {
      throw new Error('expected ticketId to be defined');
    }

    let translationData: any = {
      ticket_id: ticket.ticketId,
    };

    try {
      let data = {
        ticket_id: ticketId,
        conversation_id: this.args.conversation.id,
      } as Record<string, unknown>;

      let successMessageTranslationKey =
        'inbox.conversation-details-sidebar.linked-tickets.link-existing-ticket.success-message';

      await this.inboxApi.linkExistingTicket(data);

      if (this.args.conversation.isCustomerTicket) {
        successMessageTranslationKey =
          'inbox.conversation-details-sidebar.linked-tickets.link-existing-ticket.success-message-customer-to-tracker-ticket';
        translationData = {
          tracker_ticket_id: ticket.ticketId,
          customer_ticket_id: this.args.conversation.ticketId,
        };
      }
      this.snackbar.notify(this.intl.t(successMessageTranslationKey, translationData));
    } catch (e) {
      let response = await e.response.json();
      let errorMessage = response.errors[0].message;
      this.snackbar.notifyError(errorMessage);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Links': typeof Links;
    'inbox2/conversation-details-sidebar/sidebar-sections/links': typeof Links;
  }
}
