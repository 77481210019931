/* import __COLOCATED_TEMPLATE__ from './duration-select.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

const DISPLAY_UNITS = [
  {
    seconds: 3600,
    unit: 'hour',
  },
  {
    seconds: 60,
    unit: 'minute',
  },
  {
    seconds: 1,
    unit: 'second',
  },
];

interface Args {
  initialDurationInSeconds: number;
  changeDuration: (duration: number) => void;
}

interface Signature {
  Args: Args;
}

export default class DurationSelect extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked duration = 4;
  @tracked durationUnit = 'hour';

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.computeDisplayedDuration();
  }

  computeDisplayedDuration() {
    let durationInSeconds = this.args.initialDurationInSeconds;

    let displayUnit = DISPLAY_UNITS.find(
      ({ seconds }) => durationInSeconds >= seconds && durationInSeconds % seconds === 0,
    );

    if (displayUnit) {
      this.duration = durationInSeconds / displayUnit.seconds;
      this.durationUnit = displayUnit.unit;
    }
  }

  get durationUnitLabel() {
    return this.intl.t(`inbox.dashboard.settings.duration-select.${this.durationUnit}`, {
      duration: this.duration,
      default: '',
    });
  }

  @action changeDuration() {
    this.args.changeDuration(this.getDurationInSeconds(this.duration, this.durationUnit));
  }

  @action changeDurationUnit(newDurationUnit: string) {
    this.durationUnit = newDurationUnit;
    this.args.changeDuration(this.getDurationInSeconds(this.duration, this.durationUnit));
  }

  getDurationInSeconds(duration: number, durationUnit: string) {
    let displayUnit = DISPLAY_UNITS.find(({ unit }) => unit === durationUnit);
    if (displayUnit) {
      return displayUnit.seconds * duration;
    } else {
      return 0;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::DurationSelect': typeof DurationSelect;
    'inbox2/common/duration-select': typeof DurationSelect;
  }
}
