/* import __COLOCATED_TEMPLATE__ from './attachments-dropped.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type AttachmentsDropped from 'embercom/objects/inbox/renderable/attachments-dropped';

interface AttachmentsDroppedPart extends RenderablePart {
  renderableData: AttachmentsDropped;
}

interface Args {
  part: AttachmentsDroppedPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsAttachmentsDroppedPartComponent extends Component<Signature> {
  get renderableData() {
    return this.args.part.renderableData;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::AttachmentsDropped': typeof Inbox2ConversationStreamEventPartsAttachmentsDroppedPartComponent;
    'inbox2/conversation-stream/event-parts/attachments-dropped': typeof Inbox2ConversationStreamEventPartsAttachmentsDroppedPartComponent;
  }
}
