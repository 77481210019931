/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { ChildAction } from 'embercom/objects/inbox/command-k/action';
import { useResource } from 'ember-resources';
import KeyboardNavigableItemsResource from 'embercom/components/inbox2/command-k/keyboard-navigable-items-resource';
import type GifsAction from 'embercom/objects/inbox/command-k/gifs';
import { GifSearchData } from 'embercom/objects/inbox/command-k/gifs';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type CommandKService from 'embercom/services/command-k';
import { inject as service } from '@ember/service';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';

interface Signature {
  Args: CommandKPaneComponentArgs<GifsAction>;
}

const MAX_GIF_PER_ROW = 4;
export default class GifsPaneComponent extends Component<Signature> {
  @tracked query = '';
  @service declare commandK: CommandKService;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.items,
    isCommandKVisible: this.commandK.isVisible,
  }));

  keyboardNavigableItemsResource = useResource(this, KeyboardNavigableItemsResource, () => ({
    selection: this.selection,
    items: this.items,
    maxItemsPerRow: MAX_GIF_PER_ROW,
  }));

  gifData = useResource(this, GifSearchData, () => ({
    query: this.query,
    onSearchReturn: this.onGifSearchReturn,
  }));

  get items(): Array<ChildAction> {
    return this.gifData.results.map(
      (gif) =>
        new ChildAction({
          parent: this.args.actionItem,
          id: gif.url,
          label: gif.preview_url,
          value: gif,
        }),
    );
  }

  @action search(query: string) {
    this.query = query;
  }

  @action onGifSearchReturn() {
    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.items.length,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Gifs::Pane': typeof GifsPaneComponent;
    'inbox2/command-k/gifs/pane': typeof GifsPaneComponent;
  }
}
