/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import {
  type ParentAction,
  type Action,
  type ChildAction,
} from 'embercom/objects/inbox/command-k/action';
import Component from '@glimmer/component';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    actionItem: Action;
    currentAction?: ParentAction;
    selected?: any;
    isPaywalled?: boolean;
    beta?: boolean;
  };
}
export default class Option extends Component<Signature> {
  get isUnselectableReason() {
    return (this.args.actionItem as ChildAction).isUnselectableReason;
  }

  get hint() {
    return (this.args.actionItem as ChildAction).hint;
  }

  get shortcutsDisplay() {
    return (this.args.actionItem as ChildAction).shortcuts.display;
  }

  get parent() {
    return (this.args.actionItem as ChildAction).parent;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Option': typeof Option;
    'inbox2/command-k/option': typeof Option;
  }
}
