/* import __COLOCATED_TEMPLATE__ from './no-answer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { type RelatedContentArgs } from '../related-content';
import type RelatedContent from '../related-content';
import { type WithBoundArgs } from '@glint/template';
import { type InboxAssistantConversationPart } from 'embercom/objects/inbox/inbox-assistant-conversation';
import { isEmpty } from '@ember/utils';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

export interface NoAnswerArgs {
  message: InboxAssistantConversationPart;
  relatedContentComponent: WithBoundArgs<typeof RelatedContent, keyof RelatedContentArgs>;
}

interface Signature {
  Element: Element;
  Args: NoAnswerArgs;
  Blocks: {
    default: [];
  };
}

export default class NoAnswer extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;

  get knowledgeBaseUrl() {
    return `a/apps/${this.session.workspace.id}/knowledge-hub/all-content`;
  }

  get addContentUrl() {
    return `a/apps/${this.session.workspace.id}/knowledge-hub/overview?tab=ai-copilot`;
  }

  get descriptionTranslations() {
    // We need to hardcode these translations because we're only translating 5 languages
    // in the Inbox, and Copilot multilingual can chat in 47 languages. We don't want to
    // translate too much more, but this is actual copy in an answer.
    /* eslint-disable @intercom/intercom/no-bare-strings */
    return {
      ar: `لم أتمكن من العثور على إجابة في مركز معرفة فريقك أو تاريخ المحادثة. يرجى إعادة الصياغة أو <a href="${this.addContentUrl}" rel="noopener" target="_blank">إضافة محتوى</a> لمساعدتي في الإجابة على المزيد من الأسئلة.`,
      bg: `Не успях да намеря отговор в хъба за знания на вашия екип или в историята на разговорите. Моля, преформулирайте или <a href="${this.addContentUrl}" rel="noopener" target="_blank">добавете съдържание</a>, за да ми помогнете да отговоря на повече въпроси.`,
      bn: `আমি আপনার দলের জ্ঞানভান্ডার বা কথোপকথনের ইতিহাসে কোনও উত্তর খুঁজে পাইনি। দয়া করে পুনরায় লিখুন বা <a href="${this.addContentUrl}" rel="noopener" target="_blank">উত্তর দিতে সাহায্য করার জন্য বিষয়বস্তু যোগ করুন</a>।`,
      bs: `Nisam mogao pronaći odgovor u centru za znanje vašeg tima ili istoriji razgovora. Molimo vas da preformulišete ili <a href="${this.addContentUrl}" rel="noopener" target="_blank">dodate sadržaj</a> kako biste mi pomogli odgovoriti na više pitanja.`,
      ca: `No he pogut trobar una resposta al centre de coneixement del teu equip ni a l\`historial de converses. Si us plau, torna a formular-ho o <a href="${this.addContentUrl}" rel="noopener" target="_blank">afegeix contingut</a> per ajudar-me a respondre més preguntes.`,
      cs: `Nemohl jsem najít odpověď ve znalostní bázi vašeho týmu nebo v historii konverzace. Prosím, přeformulujte nebo <a href="${this.addContentUrl}" rel="noopener" target="_blank">přidejte obsah</a>, abyste mi pomohli odpovědět na více otázek.`,
      da: `Jeg kunne ikke finde et svar i dit teams videnshub eller samtalehistorik. Venligst omformuler eller <a href="${this.addContentUrl}" rel="noopener" target="_blank">tilføj indhold</a> for at hjælpe mig med at besvare flere spørgsmål.`,
      de: `Ich konnte keine Antwort im Wissenshub Ihres Teams oder in der Gesprächshistorie finden. Bitte formulieren Sie um oder <a href="${this.addContentUrl}" rel="noopener" target="_blank">fügen Sie Inhalte hinzu</a>, um mir zu helfen, mehr Fragen zu beantworten.`,
      'de-form': `Ich konnte keine Antwort im Wissenszentrum Ihres Teams oder in der Gesprächshistorie finden. Bitte formulieren Sie neu oder <a href="${this.addContentUrl}" rel="noopener" target="_blank">fügen Sie Inhalte hinzu</a>, um mir zu helfen, mehr Fragen zu beantworten.`,
      el: `Δεν μπόρεσα να βρω απάντηση στον κόμβο γνώσεων της ομάδας σας ή στο ιστορικό συνομιλιών. Παρακαλώ επαναδιατυπώστε ή <a href="${this.addContentUrl}" rel="noopener" target="_blank">προσθέστε περιεχόμενο</a> για να με βοηθήσετε να απαντήσω σε περισσότερες ερωτήσεις.`,
      en: `I couldn’t find an answer in your team’s knowledge hub or conversation history. Please rephrase or <a href="${this.addContentUrl}" rel="noopener" target="_blank">add content</a> to help me answer more questions.`,
      es: `No pude encontrar una respuesta en el centro de conocimiento de su equipo o en el historial de conversaciones. Por favor, reformule o <a href="${this.addContentUrl}" rel="noopener" target="_blank">agregue contenido</a> para ayudarme a responder más preguntas.`,
      et: `Ma ei leidnud vastust teie meeskonna teadmistebaasist ega vestlusajaloost. Palun sõnastage ümber või <a href="${this.addContentUrl}" rel="noopener" target="_blank">lisage sisu</a>, et aidata mul rohkematele küsimustele vastata.`,
      'fa-IR': `من نتوانستم پاسخی در مرکز دانش تیم شما یا تاریخچه گفتگو پیدا کنم. لطفاً دوباره عبارت را بازنویسی کنید یا <a href="${this.addContentUrl}" rel="noopener" target="_blank">محتوا اضافه کنید</a> تا به من کمک کنید به سؤالات بیشتری پاسخ دهم.`,
      fi: `En löytänyt vastausta tiimisi tietokeskuksesta tai keskusteluhistoriasta. Ole hyvä ja muotoile uudelleen tai <a href="${this.addContentUrl}" rel="noopener" target="_blank">lisää sisältöä</a> auttaaksesi minua vastaamaan useampiin kysymyksiin.`,
      fr: `Je n\`ai pas pu trouver de réponse dans le hub de connaissances de votre équipe ou l\`historique des conversations. Veuillez reformuler ou <a href="${this.addContentUrl}" rel="noopener" target="_blank">ajouter du contenu</a> pour m\`aider à répondre à plus de questions.`,
      he: `לא הצלחתי למצוא תשובה במרכז הידע של הצוות שלך או בהיסטוריית השיחות. אנא נסח מחדש או <a href="${this.addContentUrl}" rel="noopener" target="_blank">הוסף תוכן</a> כדי לעזור לי לענות על עוד שאלות.`,
      hi: `मैं आपके टीम के ज्ञान केंद्र या वार्तालाप इतिहास में कोई उत्तर नहीं पा सका। कृपया पुनः वाक्य बनाएं या <a href="${this.addContentUrl}" rel="noopener" target="_blank">अधिक प्रश्नों का उत्तर देने में मदद करने के लिए सामग्री जोड़ें</a>।`,
      hr: `Nisam mogao pronaći odgovor u centru znanja vašeg tima ili povijesti razgovora. Molimo preformulirajte ili <a href="${this.addContentUrl}" rel="noopener" target="_blank">dodajte sadržaj</a> kako biste mi pomogli odgovoriti na više pitanja.`,
      hu: `Nem találtam választ a csapat tudásközpontjában vagy a beszélgetési előzményekben. Kérem, fogalmazza újra, vagy <a href="${this.addContentUrl}" rel="noopener" target="_blank">adjon hozzá tartalmat</a>, hogy több kérdésre válaszolhassak.`,
      id: `Saya tidak dapat menemukan jawaban di pusat pengetahuan tim Anda atau riwayat percakapan. Silakan ubah atau <a href="${this.addContentUrl}" rel="noopener" target="_blank">tambahkan konten</a> untuk membantu saya menjawab lebih banyak pertanyaan.`,
      it: `Non sono riuscito a trovare una risposta nell\`hub di conoscenza del tuo team o nella cronologia delle conversazioni. Si prega di riformulare o <a href="${this.addContentUrl}" rel="noopener" target="_blank">aggiungere contenuti</a> per aiutarmi a rispondere a più domande.`,
      ja: `チームのナレッジハブや会話履歴で回答が見つかりませんでした。再度言い換えるか、<a href="${this.addContentUrl}" rel="noopener" target="_blank">コンテンツを追加</a>して、さらに多くの質問に答えられるようにしてください。`,
      ko: `팀의 지식 허브나 대화 기록에서 답변을 찾을 수 없었습니다. 다시 작성하거나 <a href="${this.addContentUrl}" rel="noopener" target="_blank">콘텐츠를 추가</a>하여 더 많은 질문에 답할 수 있도록 도와주세요.`,
      lt: `Negalėjau rasti atsakymo jūsų komandos žinių centre ar pokalbių istorijoje. Prašome perfrazuoti arba <a href="${this.addContentUrl}" rel="noopener" target="_blank">pridėti turinį</a>, kad galėčiau atsakyti į daugiau klausimų.`,
      lv: `Es nevarēju atrast atbildi jūsu komandas zināšanu centrā vai sarunu vēsturē. Lūdzu, pārfrāzējiet vai <a href="${this.addContentUrl}" rel="noopener" target="_blank">pievienojiet saturu</a>, lai palīdzētu man atbildēt uz vairāk jautājumiem.`,
      mn: `Би таны багийн мэдлэгийн төв эсвэл ярианы түүхээс хариулт олж чадаагүй. Дахин бичнэ үү эсвэл <a href="${this.addContentUrl}" rel="noopener" target="_blank">асуултанд хариулахад туслах агуулгыг нэмнэ үү</a>.`,
      ms: `Saya tidak dapat mencari jawapan dalam hab pengetahuan pasukan anda atau sejarah perbualan. Sila olah semula atau <a href="${this.addContentUrl}" rel="noopener" target="_blank">tambah kandungan</a> untuk membantu saya menjawab lebih banyak soalan.`,
      nb: `Jeg kunne ikke finne et svar i kunnskapshuben til teamet ditt eller samtalehistorikken. Vennligst omformuler eller <a href="${this.addContentUrl}" rel="noopener" target="_blank">legg til innhold</a> for å hjelpe meg med å svare på flere spørsmål.`,
      nl: `Ik kon geen antwoord vinden in het kenniscentrum van jouw team of in de gespreksgeschiedenis. Herformuleer het alstublieft of <a href="${this.addContentUrl}" rel="noopener" target="_blank">voeg inhoud toe</a> om mij te helpen meer vragen te beantwoorden.`,
      pl: `Nie mogłem znaleźć odpowiedzi w centrum wiedzy Twojego zespołu ani w historii rozmów. Proszę przeformułuj lub <a href="${this.addContentUrl}" rel="noopener" target="_blank">dodaj treść</a>, aby pomóc mi odpowiedzieć na więcej pytań.`,
      'pt-BR': `Não consegui encontrar uma resposta no hub de conhecimento da sua equipe ou no histórico de conversas. Por favor, reformule ou <a href="${this.addContentUrl}" rel="noopener" target="_blank">adicione conteúdo</a> para me ajudar a responder mais perguntas.`,
      pt: `Não consegui encontrar uma resposta no hub de conhecimento da sua equipe ou no histórico de conversas. Por favor, reformule ou <a href="${this.addContentUrl}" rel="noopener" target="_blank">adicione conteúdo</a> para me ajudar a responder mais perguntas.`,
      ro: `Nu am putut găsi un răspuns în centrul de cunoștințe al echipei tale sau în istoricul conversațiilor. Vă rog să reformulați sau să <a href="${this.addContentUrl}" rel="noopener" target="_blank">adăugați conținut</a> pentru a mă ajuta să răspund la mai multe întrebări.`,
      ru: `Я не смог найти ответ в центре знаний вашей команды или в истории разговоров. Пожалуйста, переформулируйте или <a href="${this.addContentUrl}" rel="noopener" target="_blank">добавьте контент</a>, чтобы помочь мне ответить на большее количество вопросов.`,
      sl: `Nisem mogel najti odgovora v vašem timu znanja ali zgodovini pogovorov. Prosimo, preoblikujte ali <a href="${this.addContentUrl}" rel="noopener" target="_blank">dodajte vsebino</a> in mi pomagajte odgovoriti na več vprašanj.`,
      sr: `Нисам могао да пронађем одговор у центру знања вашег тима или у историји разговора. Молимо вас да преобликујете или <a href="${this.addContentUrl}" rel="noopener" target="_blank">додате садржај</a> како бисте ми помогли да одговорим на више питања.`,
      sv: `Jag kunde inte hitta ett svar i ditt teams kunskapsnav eller samtalshistorik. Vänligen omformulera eller <a href="${this.addContentUrl}" rel="noopener" target="_blank">lägg till innehåll</a> för att hjälpa mig att svara på fler frågor.`,
      sw: `Sikuweza kupata jibu katika kituo cha maarifa cha timu yako au historia ya mazungumzo. Tafadhali rejesha upya au <a href="${this.addContentUrl}" rel="noopener" target="_blank">ongeza maudhui</a> ili kunisaidia kujibu maswali zaidi.`,
      th: `ฉันไม่พบคำตอบในศูนย์ความรู้ของทีมคุณหรือประวัติการสนทนา กรุณาเขียนใหม่หรือ <a href="${this.addContentUrl}" rel="noopener" target="_blank">เพิ่มเนื้อหา</a> เพื่อช่วยให้ฉันตอบคำถามเพิ่มเติมได้`,
      tr: `Ekibinizin bilgi merkezinde veya konuşma geçmişinde bir cevap bulamadım. Lütfen yeniden ifade edin veya daha fazla soruya yanıt vermeme yardımcı olmak için <a href="${this.addContentUrl}" rel="noopener" target="_blank">içerik ekleyin</a>.`,
      uk: `Я не зміг знайти відповідь у центрі знань вашої команди або в історії розмов. Будь ласка, переформулюйте або <a href="${this.addContentUrl}" rel="noopener" target="_blank">додайте вміст</a>, щоб допомогти мені відповісти на більше запитань.`,
      vi: `Tôi không thể tìm thấy câu trả lời trong trung tâm kiến thức của đội bạn hoặc lịch sử cuộc trò chuyện. Vui lòng diễn đạt lại hoặc <a href="${this.addContentUrl}" rel="noopener" target="_blank">thêm nội dung</a> để giúp tôi trả lời thêm nhiều câu hỏi.`,
      'zh-CN': `我在您的团队知识中心或对话历史中找不到答案。请重新表述或<a href="${this.addContentUrl}" rel="noopener" target="_blank">添加内容</a>以帮助我回答更多问题。`,
      'zh-TW': `我在您的團隊知識中心或對話歷史中找不到答案。請重新表述或<a href="${this.addContentUrl}" rel="noopener" target="_blank">添加內容</a>以幫助我回答更多問題。`,
      zh: `我在您的团队知识中心或对话历史中找不到答案。请重新表述或<a href="${this.addContentUrl}" rel="noopener" target="_blank">添加内容</a>以帮助我回答更多问题。`,
      fa: `من نتوانستم پاسخی در مرکز دانش تیم شما یا تاریخچه گفتگو پیدا کنم. لطفاً دوباره عبارت را بازنویسی کنید یا <a href="${this.addContentUrl}" rel="noopener" target="_blank">محتوا اضافه کنید</a> تا به من کمک کنید به سؤالات بیشتری پاسخ دهم.`,
    };

    /* eslint-enable @intercom/intercom/no-bare-strings */
  }

  get noContentDescriptionTranslations() {
    // We need to hardcode these translations because we're only translating 5 languages
    // in the Inbox, and Copilot multilingual can chat in 47 languages. We don't want to
    // translate too much more, but this is actual copy in an answer.
    /* eslint-disable @intercom/intercom/no-bare-strings */
    return {
      ar: 'يبدو أن فريقك لم يضف أي محتوى دعم إلى مركز المعرفة الخاص بك حتى الآن. أحتاج إلى مصادر لمساعدتي في الإجابة على أسئلتك.',
      bg: 'Изглежда, че вашият екип все още не е добавил поддържащо съдържание към вашия център за знания. Трябват ми източници, за да мога да отговоря на въпросите ви.',
      bn: 'আপনার দলের সদস্যরা এখনও আপনার জ্ঞান কেন্দ্রে কোনও সহায়তামূলক কনটেন্ট যোগ করেনি বলে মনে হচ্ছে। আপনার প্রশ্নের উত্তর দিতে আমাকে উৎস প্রয়োজন।',
      bs: 'Izgleda da vaš tim još nije dodao podršku u vaš centar znanja. Trebam izvore da bih vam mogao odgovoriti na pitanja.',
      ca: 'Sembla que el vostre equip encara no ha afegit cap contingut de suport al vostre centre de coneixement. Necessito fonts per ajudar-me a respondre les vostres preguntes.',
      cs: 'Vypadá to, že váš tým dosud nepřidal žádný podpůrný obsah do vašeho znalostního centra. Potřebuji zdroje, abych mohl odpovědět na vaše otázky.',
      da: 'Det ser ud til, at dit team endnu ikke har tilføjet noget supportindhold til din videnshub. Jeg har brug for kilder til at hjælpe mig med at besvare dine spørgsmål.',
      de: 'Es sieht so aus, als hätte Ihr Team noch keine Unterstützungsinhalte zu Ihrem Wissenshub hinzugefügt. Ich benötige Quellen, um Ihre Fragen zu beantworten.',
      'de-form':
        'Es scheint, dass Ihr Team noch keine Unterstützungsinhalte zu Ihrem Wissenshub hinzugefügt hat. Ich benötige Quellen, um Ihre Fragen zu beantworten.',
      el: 'Φαίνεται ότι η ομάδα σας δεν έχει προσθέσει ακόμη περιεχόμενο υποστήριξης στο κέντρο γνώσεων σας. Χρειάζομαι πηγές για να μπορέσω να απαντήσω στις ερωτήσεις σας.',
      en: "It looks like your team hasn't added any support content to your knowledge hub yet. I need sources to help me answer your questions.",
      es: 'Parece que su equipo aún no ha añadido contenido de soporte a su centro de conocimiento. Necesito fuentes para ayudarme a responder sus preguntas.',
      et: 'Tundub, et teie meeskond pole veel lisanud tugisisu teie teadmistekeskusesse. Vajan allikaid, et aidata mul teie küsimustele vastata.',
      'fa-IR':
        'به نظر می‌رسد تیم شما هنوز هیچ محتوای پشتیبانی به مرکز دانش شما اضافه نکرده است. من به منابعی نیاز دارم تا به سؤالات شما پاسخ دهم.',
      fi: 'Näyttää siltä, että tiimisi ei ole vielä lisännyt tukisisältöä tietokeskukseen. Tarvitsen lähteitä auttaakseni sinua vastaamaan kysymyksiisi.',
      fr: "Il semble que votre équipe n'ait pas encore ajouté de contenu de support à votre centre de connaissances. J'ai besoin de sources pour m'aider à répondre à vos questions.",
      he: 'נראה כי הצוות שלך עדיין לא הוסיף תוכן תמיכה למרכז הידע שלך. אני צריך מקורות שיעזרו לי לענות על השאלות שלך.',
      hi: 'ऐसा लगता है कि आपकी टीम ने अभी तक आपके ज्ञान केंद्र में कोई समर्थन सामग्री नहीं जोड़ी है। मुझे आपके प्रश्नों का उत्तर देने में मदद करने के लिए स्रोतों की आवश्यकता है।',
      hr: 'Čini se da vaš tim još nije dodao sadržaj podrške u vaš centar znanja. Trebam izvore da bih vam mogao odgovoriti na pitanja.',
      hu: 'Úgy tűnik, hogy a csapata még nem adott hozzá támogató tartalmat a tudásközpontjához. Forrásokra van szükségem, hogy megválaszolhassam a kérdéseit.',
      id: 'Sepertinya tim Anda belum menambahkan konten dukungan ke pusat pengetahuan Anda. Saya membutuhkan sumber daya untuk membantu saya menjawab pertanyaan Anda.',
      it: 'Sembra che il tuo team non abbia ancora aggiunto contenuti di supporto al tuo hub di conoscenza. Ho bisogno di fonti per aiutarmi a rispondere alle tue domande.',
      ja: 'あなたのチームはまだナレッジハブにサポートコンテンツを追加していないようです。質問に答えるために情報源が必要です。',
      ko: '팀에서 아직 지식 허브에 지원 콘텐츠를 추가하지 않은 것 같습니다. 질문에 답변하기 위해 출처가 필요합니다.',
      lt: 'Panašu, kad jūsų komanda dar nepridėjo jokio palaikymo turinio į jūsų žinių centrą. Man reikia šaltinių, kad galėčiau atsakyti į jūsų klausimus.',
      lv: 'Izskatās, ka jūsu komanda vēl nav pievienojusi atbalsta saturu jūsu zināšanu centram. Man ir nepieciešami avoti, lai palīdzētu atbildēt uz jūsu jautājumiem.',
      mn: 'Танай баг мэдлэгийн төвдөө дэмжлэгийн агуулга нэмээгүй бололтой. Таны асуултанд хариулахад надад эх сурвалжууд хэрэгтэй байна.',
      ms: 'Nampaknya pasukan anda belum menambah kandungan sokongan ke pusat pengetahuan anda lagi. Saya perlukan sumber untuk membantu saya menjawab soalan anda.',
      nb: 'Det ser ut som teamet ditt ennå ikke har lagt til noe støtteinnhold i kunnskapshuben din. Jeg trenger kilder for å hjelpe meg med å svare på spørsmålene dine.',
      nl: 'Het lijkt erop dat uw team nog geen ondersteuningsinhoud heeft toegevoegd aan uw kenniscentrum. Ik heb bronnen nodig om uw vragen te beantwoorden.',
      pl: 'Wygląda na to, że Twój zespół nie dodał jeszcze żadnych treści wspierających do Twojego centrum wiedzy. Potrzebuję źródeł, aby pomóc mi odpowiedzieć na Twoje pytania.',
      'pt-BR':
        'Parece que sua equipe ainda não adicionou nenhum conteúdo de suporte ao seu hub de conhecimento. Preciso de fontes para ajudar a responder às suas perguntas.',
      pt: 'Parece que a sua equipe ainda não adicionou nenhum conteúdo de suporte ao seu centro de conhecimento. Preciso de fontes para ajudar a responder às suas perguntas.',
      ro: 'Se pare că echipa ta nu a adăugat încă niciun conținut de suport în centrul tău de cunoștințe. Am nevoie de surse pentru a te ajuta să răspund la întrebările tale.',
      ru: 'Похоже, ваша команда еще не добавила никакого поддерживающего контента в ваш информационный центр. Мне нужны источники, чтобы помочь ответить на ваши вопросы.',
      sl: 'Zdi se, da vaša ekipa še ni dodala nobene vsebine za podporo v vašo zbirko znanja. Potrebujem vire, da vam pomagam odgovoriti na vaša vprašanja.',
      sr: 'Izgleda da vaš tim još nije dodao podršku u vaš centar znanja. Trebaju mi izvori da bih vam mogao odgovoriti na pitanja.',
      sv: 'Det verkar som att ditt team ännu inte har lagt till något supportinnehåll till din kunskapshubb. Jag behöver källor för att hjälpa mig att besvara dina frågor.',
      sw: 'Inaonekana timu yako bado haijaongeza maudhui yoyote ya usaidizi kwenye kitovu chako cha maarifa. Nahitaji vyanzo vya kunisaidia kujibu maswali yako.',
      th: 'ดูเหมือนว่าทีมของคุณยังไม่ได้เพิ่มเนื้อหาการสนับสนุนใด ๆ ลงในศูนย์ความรู้ของคุณ ฉันต้องการแหล่งข้อมูลเพื่อช่วยตอบคำถามของคุณ',
      tr: 'Görünüşe göre ekibiniz henüz bilgi merkezine herhangi bir destek içeriği eklememiş. Sorularınızı yanıtlamama yardımcı olacak kaynaklara ihtiyacım var.',
      uk: 'Схоже, ваша команда ще не додала жодного матеріалу підтримки до вашого центру знань. Мені потрібні джерела, щоб допомогти відповісти на ваші запитання.',
      vi: 'Có vẻ như đội của bạn chưa thêm bất kỳ nội dung hỗ trợ nào vào trung tâm kiến thức của bạn. Tôi cần các nguồn để giúp tôi trả lời câu hỏi của bạn.',
      'zh-CN':
        '看起来您的团队还没有向您的知识中心添加任何支持内容。我需要资源来帮助我回答您的问题。',
      'zh-TW': '看起來您的團隊尚未在您的知識中心添加任何支援內容。我需要資源來幫助我回答您的問題。',
      zh: '看起来您的团队还没有向您的知识中心添加任何支持内容。我需要资源来帮助我回答您的问题。',
      fa: 'به نظر می‌رسد تیم شما هنوز هیچ محتوای پشتیبانی به مرکز دانش شما اضافه نکرده است. من به منابعی نیاز دارم تا به سؤالات شما پاسخ دهم.',
    };
    /* eslint-enable @intercom/intercom/no-bare-strings */
  }

  get buttonTranslations(): Record<string, Record<string, string>> {
    // We need to hardcode these translations because we're only translating 5 languages
    // in the Inbox, and Copilot multilingual can chat in 47 languages. We don't want to
    // translate too much more, but this is actual copy in an answer.
    /* eslint-disable @intercom/intercom/no-bare-strings */
    return {
      ar: {
        'learn-more': 'تعرف على المزيد',
        'add-content': 'أضف محتوى',
      },
      bg: {
        'learn-more': 'Научете повече',
        'add-content': 'Добавете съдържание',
      },
      bn: {
        'learn-more': 'আরও জানুন',
        'add-content': 'বিষয়বস্তু যোগ করুন',
      },
      bs: {
        'learn-more': 'Saznaj više',
        'add-content': 'Dodaj sadržaj',
      },
      ca: {
        'learn-more': 'Més informació',
        'add-content': 'Afegeix contingut',
      },
      cs: {
        'learn-more': 'Zjistit více',
        'add-content': 'Přidat obsah',
      },
      da: {
        'learn-more': 'Lær mere',
        'add-content': 'Tilføj indhold',
      },
      de: {
        'learn-more': 'Erfahren Sie mehr',
        'add-content': 'Inhalt hinzufügen',
      },
      'de-form': {
        'learn-more': 'Erfahren Sie mehr',
        'add-content': 'Inhalt hinzufügen',
      },
      el: {
        'learn-more': 'Μάθετε περισσότερα',
        'add-content': 'Προσθήκη περιεχομένου',
      },
      en: {
        'learn-more': 'Learn more',
        'add-content': 'Add content',
      },
      es: {
        'learn-more': 'Aprende más',
        'add-content': 'Agregar contenido',
      },
      et: {
        'learn-more': 'Lisateave',
        'add-content': 'Lisa sisu',
      },
      'fa-IR': {
        'learn-more': 'بیشتر بدانید',
        'add-content': 'اضافه کردن محتوا',
      },
      fi: {
        'learn-more': 'Lue lisää',
        'add-content': 'Lisää sisältöä',
      },
      fr: {
        'learn-more': 'En savoir plus',
        'add-content': 'Ajouter du contenu',
      },
      he: {
        'learn-more': 'למד עוד',
        'add-content': 'הוסף תוכן',
      },
      hi: {
        'learn-more': 'और जानें',
        'add-content': 'सामग्री जोड़ें',
      },
      hr: {
        'learn-more': 'Saznajte više',
        'add-content': 'Dodaj sadržaj',
      },
      hu: {
        'learn-more': 'Tudj meg többet',
        'add-content': 'Tartalom hozzáadása',
      },
      id: {
        'learn-more': 'Pelajari lebih lanjut',
        'add-content': 'Tambahkan konten',
      },
      it: {
        'learn-more': 'Scopri di più',
        'add-content': 'Aggiungi contenuto',
      },
      ja: {
        'learn-more': 'もっと詳しく知る',
        'add-content': 'コンテンツを追加',
      },
      ko: {
        'learn-more': '자세히 알아보기',
        'add-content': '콘텐츠 추가',
      },
      lt: {
        'learn-more': 'Sužinoti daugiau',
        'add-content': 'Pridėti turinį',
      },
      lv: {
        'learn-more': 'Uzzināt vairāk',
        'add-content': 'Pievienot saturu',
      },
      mn: {
        'learn-more': 'Дэлгэрэнгүй үзэх',
        'add-content': 'Агуулга нэмэх',
      },
      ms: {
        'learn-more': 'Ketahui lebih lanjut',
        'add-content': 'Tambah kandungan',
      },
      nb: {
        'learn-more': 'Lær mer',
        'add-content': 'Legg til innhold',
      },
      nl: {
        'learn-more': 'Meer informatie',
        'add-content': 'Inhoud toevoegen',
      },
      pl: {
        'learn-more': 'Dowiedz się więcej',
        'add-content': 'Dodaj treść',
      },
      'pt-BR': {
        'learn-more': 'Saiba mais',
        'add-content': 'Adicionar conteúdo',
      },
      pt: {
        'learn-more': 'Saber mais',
        'add-content': 'Adicionar conteúdo',
      },
      ro: {
        'learn-more': 'Află mai multe',
        'add-content': 'Adăugați conținut',
      },
      ru: {
        'learn-more': 'Узнать больше',
        'add-content': 'Добавить контент',
      },
      sl: {
        'learn-more': 'Izvedi več',
        'add-content': 'Dodaj vsebino',
      },
      sr: {
        'learn-more': 'Saznajte više',
        'add-content': 'Dodaj sadržaj',
      },
      sv: {
        'learn-more': 'Läs mer',
        'add-content': 'Lägg till innehåll',
      },
      sw: {
        'learn-more': 'Jifunze zaidi',
        'add-content': 'Ongeza maudhui',
      },
      th: {
        'learn-more': 'เรียนรู้เพิ่มเติม',
        'add-content': 'เพิ่มเนื้อหา',
      },
      tr: {
        'learn-more': 'Daha fazla bilgi edinin',
        'add-content': 'İçerik ekle',
      },
      uk: {
        'learn-more': 'Дізнатися більше',
        'add-content': 'Додати контент',
      },
      vi: {
        'learn-more': 'Tìm hiểu thêm',
        'add-content': 'Thêm nội dung',
      },
      'zh-CN': {
        'learn-more': '了解更多',
        'add-content': '添加内容',
      },
      'zh-TW': {
        'learn-more': '了解更多',
        'add-content': '新增內容',
      },
      zh: {
        'learn-more': '了解更多',
        'add-content': '添加内容',
      },
      fa: {
        'learn-more': 'بیشتر بدانید',
        'add-content': 'اضافه کردن محتوا',
      },
    };
    /* eslint-enable @intercom/intercom/no-bare-strings */
  }

  get learnMoreButtonText() {
    let locale = this.args.message.metadata?.locale;

    if (typeof locale !== 'string' || isEmpty(locale) || !(locale in this.buttonTranslations)) {
      return this.intl.t('inbox.ask-fin.related-contents.fallback-state-learn-more-button');
    }

    return this.buttonTranslations[locale]['learn-more'];
  }

  get addContentButtonText() {
    let locale = this.args.message.metadata?.locale;

    if (typeof locale !== 'string' || isEmpty(locale) || !(locale in this.buttonTranslations)) {
      return this.intl.t('inbox.ask-fin.related-contents.fallback-state-add-content-button');
    }

    return this.buttonTranslations[locale]['add-content'];
  }

  get noAnswerDescriptionText() {
    let locale = this.args.message.metadata?.locale;

    if (
      typeof locale !== 'string' ||
      isEmpty(locale) ||
      !(locale in this.descriptionTranslations)
    ) {
      return this.intl.t('inbox.ask-fin.no-answer-description', {
        addContentUrl: this.addContentUrl,
      });
    }

    return this.descriptionTranslations[locale as keyof typeof this.descriptionTranslations];
  }

  get noContentNoAnswerDescriptionText() {
    let locale = this.args.message.metadata?.locale;

    if (
      typeof locale !== 'string' ||
      isEmpty(locale) ||
      !(locale in this.noContentDescriptionTranslations)
    ) {
      return this.intl.t('inbox.ask-fin.related-contents.fallback-state-no-content');
    }

    return this.noContentDescriptionTranslations[
      locale as keyof typeof this.noContentDescriptionTranslations
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::MessageStates::NoAnswer': typeof NoAnswer;
    'inbox2/copilot/message-states/no-answer': typeof NoAnswer;
  }
}
