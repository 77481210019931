/* import __COLOCATED_TEMPLATE__ from './editable-owner-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type QuickSearchService from 'embercom/services/quick-search';
import AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import { UNASSIGNED_ID } from 'embercom/objects/inbox/admin-summary';
import type Session from 'embercom/services/session';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { type default as IntlService, DEFAULT_LOCALE } from 'embercom/services/intl';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import type InboxApi from 'embercom/services/inbox-api';
import { useFunction } from 'ember-resources';
import { AdminSearchContexts } from 'embercom/objects/inbox/macro';
import { type AttributeComponentArgs } from 'embercom/lib/inbox2/sidebar-types';

interface Signature {
  Args: AttributeComponentArgs;
}

export default class EditableOwnerAttributeComponent extends Component<Signature> {
  @tracked isEditing = false;
  @tracked isHovered = false;
  @tracked isLoadingAdmin = false;

  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare session: Session;
  @service declare quickSearch: QuickSearchService;
  @service declare inboxApi: InboxApi;

  searchContexts = [AdminSearchContexts.Mentions]; // excludes an operator

  get ownerId() {
    let { user, attribute } = this.args;

    if (!user || !attribute) {
      return;
    }

    return user.attributes[attribute.key];
  }

  adminLoader = useFunction(
    this,
    async (previous: AdminSummary, id: number) => {
      if (!id || previous?.id === id) {
        return previous;
      }

      let owner: AdminSummary | undefined;
      try {
        this.isLoadingAdmin = true;
        owner = await this.inboxApi.fetchAdminById(id);
      } finally {
        this.isLoadingAdmin = false;
      }
      return owner;
    },
    () => [this.ownerId],
  );

  @ref('dropdownOpener') dropdownOpener!: HTMLElement;

  readonly teammateEntityType = SearchableType.Admin;

  get unassignedTeammate() {
    return AdminWithPermissions.deserialize({
      id: UNASSIGNED_ID,
      name: this.intl.t('inbox.assignee-selector.none'),
      image_url: null,
      permissions: {
        has_inbox_access: true,
        manage_teammates: true,
        inbox__conversation_transcripts__export: true,
        teammates__away_mode__change: true,
        manage_tags: false,
        manage_saved_replies: false,
        redact_conversation_parts: false,
        conversation_access: {},
        has_full_access: false,
        manage_inbox_views: false,
        access_real_time_dashboard: false,
        reassign_conversations: false,
        send_from_custom_addresses: false,
        reply_to_inbound_conversations: false,
        access_workspace_settings: false,
        access_contacts: false,
        access_billing_settings: false,
        can_access_all_conversations: false,
        calling__listening_and_barging: false,
        manage_knowledge_base_content: false,
      },
      team_ids: [],
      seats: [],
      locale: DEFAULT_LOCALE,
      copilot_waitlist_opt_in: false,
      product_ia_opt_in: false,
    });
  }

  @action focusDropdownOpener() {
    this.dropdownOpener.focus();
  }

  @action updateAssignee(assignee: AdminWithPermissions) {
    this.focusDropdownOpener();

    if (
      !this.isLoadingAdmin &&
      this.adminLoader.value?.id !== assignee.id &&
      assignee.permissions.hasInboxAccess
    ) {
      this.args.onUserAttributeUpdated(this.args.attribute, assignee.id || null);

      let action = assignee === this.unassignedTeammate ? 'remove' : 'add';
      this.intercomEventService.trackAnalyticsEvent({
        action,
        object: 'owner',
        place: 'inbox',
      });
    }
  }

  get currentTeammate() {
    return this.session.teammate;
  }

  get topResults() {
    return [this.unassignedTeammate, this.currentTeammate];
  }

  get attributeName() {
    return this.args.attribute.humanFriendlyName;
  }

  @action togglePin() {
    if (!this.args.showPinIcon) {
      return;
    }

    this.args.togglePin('owner_id');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::EditableOwnerAttribute': typeof EditableOwnerAttributeComponent;
    'inbox2/conversation-details-sidebar/sidebar-sections/editable-owner-attribute': typeof EditableOwnerAttributeComponent;
  }
}
