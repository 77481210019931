/* import __COLOCATED_TEMPLATE__ from './cell.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type Inbox2TeammateActivityService from 'embercom/services/inbox2-teammate-activity-service';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import {
  type Breakdown,
  type BreakdownSettings,
} from 'embercom/services/inbox2-monitoring-breakdown-service';
import type Inbox2MonitoringBreakdownService from 'embercom/services/inbox2-monitoring-breakdown-service';

interface Args {
  value: number;
  qualifier?: string;
  clarificationValue?: string | undefined;
  useColorRange?: boolean;
  maxRange?: number;
  midRange?: number;
  lowRange?: number;
  metricType?: string;
  adminId?: string;
  timeframe?: number;
  settings?: BreakdownSettings;
  openDrilldownDrawer?: (options: object) => void;
}

interface Signature {
  Args: Args;
}

export default class TeammateActivityTableCell extends Component<Signature> {
  @service declare session: Session;
  @service declare inbox2TeammateActivityService: Inbox2TeammateActivityService;
  @service declare inbox2MonitoringBreakdownService: Inbox2MonitoringBreakdownService;

  @tracked value = this.args.value;
  @tracked breakdown: Breakdown | undefined = undefined;

  popover: any = null;
  isHovering = false;

  get qualifier() {
    return this.args.qualifier || '';
  }

  get text() {
    if (!isBlank(this.value)) {
      return !isBlank(this.args.clarificationValue)
        ? `${this.value}${this.qualifier} (${this.args.clarificationValue})`
        : `${this.value}${this.qualifier}`;
    }
    return '-';
  }

  get maxRange() {
    return this.args.maxRange || 100;
  }

  get lowRange() {
    return 0.5 * this.maxRange;
  }

  get midRange() {
    return 0.85 * this.maxRange;
  }

  get textColor() {
    if (!isBlank(this.value) && this.args.useColorRange) {
      if (this.value <= this.lowRange) {
        return 'text-red dark:text-dm-red';
      }
      if (this.value <= this.midRange) {
        return 'text-yellow dark:text-dm-yellow';
      }
      return 'text-green dark:text-dm-green';
    }
    return 'text-black dark:text-dm-white';
  }

  get isLink() {
    return !!this.args.metricType;
  }

  @action openDrilldownDrawer() {
    this.popover?.hide();
    if (this.args.metricType) {
      this.args.openDrilldownDrawer &&
        this.args.openDrilldownDrawer({
          adminId: this.args.adminId,
          metricType: this.args.metricType,
          value: this.text,
          updateValue: this.updateValue,
        });
    }
  }

  get metricHasBreakdown() {
    return ['idle', 'avg_wait_time', 'avg_time_to_subsequent_response', 'csat'].includes(
      this.args.metricType || '',
    );
  }

  get metricLabel() {
    return {
      avg_time_to_subsequent_response: 'Avg. Time to Subsequent Response',
      idle: 'Idle',
      avg_wait_time: 'Avg. Wait Time',
      csat: 'CSAT',
    }[this.args.metricType || ''];
  }

  @action setPopover(popover: any) {
    this.popover = popover;
  }

  format(value: string) {
    switch (this.args.metricType) {
      case 'avg_wait_time':
        return this.inbox2TeammateActivityService.getAvgWaitTime({
          avg_first_response_time_in_seconds: value,
        }).avgFirstResponseTime;
      case 'avg_time_to_subsequent_response':
        return this.inbox2TeammateActivityService.getAvgTimeToSubsequentResponse({
          avg_time_to_subsequent_response: value,
        }).avgTimeToSubsequentResponse;
      case 'csat':
        return this.inbox2TeammateActivityService.getCsat({ csat: value });
      default:
        return value;
    }
  }

  @action updateValue(rawValue: string) {
    this.value = this.format(rawValue);
  }

  @task({ restartable: true })
  *fetchPopoverBreakdown() {
    this.breakdown = yield this.inbox2MonitoringBreakdownService.fetchBreakdown({
      metric: { adminId: this.args.adminId, metricType: this.args.metricType || '' },
      timeframe: this.args.timeframe,
      settings: this.args.settings,
    });
    if (this.isHovering) {
      this.popover?.show();
      this.updateValue(this.breakdown?.buckets[0].value || '');
    }
  }

  @action hidePopover(event: any) {
    if (event.relatedTarget.classList.contains('inbox2-teammate-activity__cell__popover')) {
      this.popover?.hide();
    } else {
      // mouseleave event should fire here
    }
  }

  @action
  onHidePopover() {
    this.isHovering = false;
  }

  @action
  async onHover() {
    this.isHovering = true;
    if (this.metricHasBreakdown) {
      // We want to trigger popover data fetching only if cursor hovers longer than 200ms
      setTimeout(
        () => (this.isHovering ? taskFor(this.fetchPopoverBreakdown).perform() : null),
        200,
      );
    } else {
      setTimeout(() => (this.isHovering ? this.popover.show() : null), 200);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Teammates::Cell': typeof TeammateActivityTableCell;
    'inbox2/dashboard/teammates/cell': typeof TeammateActivityTableCell;
  }
}
