/* import __COLOCATED_TEMPLATE__ from './status-cell.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import { TeammateStatus } from 'embercom/services/inbox2-teammate-activity-service';
import type AdminAwayService from 'embercom/services/admin-away-service';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';

interface Args {
  admin: AdminWithPermissions;
  status: TeammateStatus;
  statusReason: string;
  changeTeammateStatus: () => void;
}

interface Signature {
  Args: Args;
}

export default class StatusCell extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare adminAwayService: AdminAwayService;
  @service intercomEventService: any;

  @tracked status: TeammateStatus = this.args.status;
  @tracked statusReason: string = this.args.statusReason;

  get admin() {
    return this.args.admin;
  }

  get currentAdminCanChangeAwayMode() {
    return this.session.teammate.permissions.canChangeAwayMode;
  }

  get statusText() {
    return this.statusOptions.findBy('value', this.status)?.text;
  }

  get statusOptions() {
    return [
      {
        text: this.intl.t('inbox.dashboard.teammate-activity.status.active'),
        value: TeammateStatus.Active,
      },
      {
        text: this.intl.t('inbox.dashboard.teammate-activity.status.away'),
        value: TeammateStatus.Away,
      },
      {
        text: this.intl.t('inbox.dashboard.teammate-activity.status.reassigning'),
        value: TeammateStatus.Reassign,
      },
    ];
  }

  get groupList() {
    let options = this.statusOptions.map((status) => {
      return {
        text: status.text,
        value: status.value,
        isSelected: this.status === status.value,
      };
    });
    return [
      {
        hasItemsMarkableAsSelected: true,
        items: options,
      },
    ];
  }

  @action
  changeStatus(value: TeammateStatus) {
    switch (value) {
      case TeammateStatus.Active:
        this.setActive();
        return;
      case TeammateStatus.Away:
        this.setAway();
        return;
      case TeammateStatus.Reassign:
        this.setAwayAndReassigning();
        return;
    }
  }

  private setActive() {
    this.adminAwayService.toggleAwayModeFor(this.admin.id, false);
    this.status = TeammateStatus.Active;
    this.statusReason = '';
    this.sendAnalyticsEvent(TeammateStatus.Active);
  }

  private setAway() {
    this.adminAwayService.toggleAwayModeFor(this.admin.id, true);
    this.status = TeammateStatus.Away;
    this.sendAnalyticsEvent(TeammateStatus.Away);
  }

  private setAwayAndReassigning() {
    this.adminAwayService.toggleReassignConversationsFor(this.admin.id, true);
    this.status = TeammateStatus.Reassign;
    this.sendAnalyticsEvent(TeammateStatus.Reassign);
  }

  private sendAnalyticsEvent(mode_changed_to: string) {
    let away_mode_enabled;
    let reassign_conversations;
    switch (mode_changed_to) {
      case TeammateStatus.Active:
        away_mode_enabled = false;
        reassign_conversations = false;
        break;
      case TeammateStatus.Away:
        away_mode_enabled = true;
        reassign_conversations = false;
        break;
      case TeammateStatus.Reassign:
        away_mode_enabled = true;
        reassign_conversations = true;
        break;
    }

    this.intercomEventService.trackAnalyticsEvent({
      object: 'away_mode',
      action: 'changed',
      place: 'inbox_2',
      section: 'realtime_dashboard',
      teammate_id: this.admin.id,
      away_mode_enabled,
      reassign_conversations,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Teammates::StatusCell': typeof StatusCell;
    'inbox2/dashboard/teammates/status-cell': typeof StatusCell;
  }
}
