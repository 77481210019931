/* import __COLOCATED_TEMPLATE__ from './user-avatar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import { Role } from 'embercom/objects/inbox/user-summary';
import { transformAvatarUrl } from 'embercom/objects/inbox/inboxes/avatars';

export interface Args {
  user?: UserSummary;
  noBackground?: boolean;
  color?: string;
  darkColor?: string;
  large?: boolean;
  small?: boolean;
  tiny?: boolean;
  outline?: boolean;
  isTyping?: boolean;
  typingDotColor?: string;
  showActiveStatus?: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class UserAvatarComponent extends Component<Signature> {
  @service declare session: Session;

  get isUser() {
    return this.args.user?.role === Role.User;
  }

  get imageURL(): string | undefined {
    let url = this.args.user?.imageURL;
    if (!url) {
      return;
    }

    if (url.includes('pseudonym')) {
      return;
    }

    return transformAvatarUrl(url, this.session.darkModeEnabled);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::UserAvatar': typeof UserAvatarComponent;
  }
}
