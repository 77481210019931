/* import __COLOCATED_TEMPLATE__ from './workflow-connector-action.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { type MacroActionComponentArgs } from 'embercom/lib/inbox2/bulk-action-types';

interface Signature {
  Element: HTMLElement;
  Args: MacroActionComponentArgs;
}

export default class WorkflowConnectorAction extends Component<Signature> {
  @service declare intl: IntlService;

  readonly entityType = SearchableType.WorkflowConnectorAction;

  get label() {
    let workflowConnectorActionSummary =
      this.args.macroAction.data?.workflow_connector_action_summary;
    if (!workflowConnectorActionSummary) {
      return this.intl.t('inbox.composer.macro-actions.workflow-connector.missing-action');
    }

    return workflowConnectorActionSummary.name;
  }

  get isInvalid() {
    if (this.args.forceInvalid) {
      return true;
    }

    return this.args.macroAction.isInvalid;
  }

  @action onSelectWorkflowConnectorAction(data: Record<string, any>) {
    this.args.macroAction.updateData({ workflow_connector_action_summary: data });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MacroActions::WorkflowConnectorAction': typeof WorkflowConnectorAction;
    'inbox2/composer/macro-actions/workflow-connector-action': typeof WorkflowConnectorAction;
  }
}
