/* import __COLOCATED_TEMPLATE__ from './github.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  conversationEntry: ConversationTableEntry;
  onPreviewGithubIssues?: () => void;
}

interface Signature {
  Args: Args;
}

export default class Github extends Component<Signature> {
  @action onClick() {
    let githubLink = this.firstGithubLink?.rawLink;
    safeWindowOpen(githubLink, '_blank');
  }

  get additionalGithubLinks() {
    return this.args.conversationEntry.githubLinks?.slice(1);
  }

  get additionalGithubLinksCount() {
    return this.additionalGithubLinks?.length;
  }

  get formattedAdditionalLinksCount() {
    return `+${this.additionalGithubLinksCount}`;
  }

  get firstGithubLink() {
    return this.args.conversationEntry.githubLinks
      ? this.args.conversationEntry.githubLinks[0]
      : undefined;
  }

  @action onPreviewGithubIssues() {
    this.args.onPreviewGithubIssues?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::Github': typeof Github;
    'inbox2/conversations-table/table-cells/github': typeof Github;
  }
}
