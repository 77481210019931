/* import __COLOCATED_TEMPLATE__ from './related-content-list-view.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { CopilotSourceSection, type NormalizedFinSource } from 'embercom/lib/inbox2/copilot/types';
import { type InboxAssistantConversationPart } from 'embercom/objects/inbox/inbox-assistant-conversation';
import { type RelatedContentSheet } from 'embercom/services/sidebar-manager';

export interface RelatedContentListViewArgs {
  onClose: () => void;
  data: RelatedContentSheet['data'];
  insertBlocks: (source: NormalizedFinSource, message: InboxAssistantConversationPart) => void;
  message: InboxAssistantConversationPart;
}

interface Signature {
  Args: RelatedContentListViewArgs;
}

export default class RelatedContentListView extends Component<Signature> {
  readonly CopilotSourceSection = CopilotSourceSection;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::RelatedContentListView': typeof RelatedContentListView;
    'inbox2/copilot/related-content-list-view': typeof RelatedContentListView;
  }
}
