/* import __COLOCATED_TEMPLATE__ from './on-the-waitlist.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  hasConsentedToIngestion: boolean;
  consentToIngestion: () => Promise<void>;
  isAdmin: boolean;
  openPermissionsModal: () => void;
  trackLearnMoreClicked: () => void;
  trackPrepareYourWorkspaceClicked: () => void;
  shouldRenderSkipButton?: boolean;
  onSkip?: () => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

const OnTheWaitlist = templateOnlyComponent<Signature>();
export default OnTheWaitlist;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Setup::OnTheWaitlist': typeof OnTheWaitlist;
    'inbox2/copilot/setup/on-the-waitlist': typeof OnTheWaitlist;
  }
}
