/* import __COLOCATED_TEMPLATE__ from './company-tags.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import type Company from 'embercom/objects/inbox/company';

interface Signature {
  Args: SidebarSectionComponentArgs<{
    userCompanies?: Company[];
  }>;
}

const CompanyTags = templateOnlyComponent<Signature>();
export default CompanyTags;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::CompanyTags': typeof CompanyTags;
    'inbox2/conversation-details-sidebar/sidebar-sections/company-tags': typeof CompanyTags;
  }
}
