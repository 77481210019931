/* import __COLOCATED_TEMPLATE__ from './duplicate-conversation-note.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type InboxState from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type DuplicateConversationSummary from 'embercom/objects/inbox/duplicate-conversation-summary';
import { action } from '@ember/object';

interface Args {
  partGroup: PartGroup;
  isReadOnly?: boolean;
}

interface Signature {
  Args: Args;
}

export default class DuplicateConversationNoteComponent extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare session: Session;

  get renderableData() {
    return this.args.partGroup.parts[0].renderableData;
  }

  get isReadOnly() {
    if (!this.args.isReadOnly) {
      return false;
    }
    return this.args.isReadOnly;
  }

  get operator() {
    return this.renderableData.creatingEntity.object;
  }

  @action onConversationClick(conversationSummary: DuplicateConversationSummary) {
    this.inboxSidebarService.previewConversation(conversationSummary);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::DuplicateConversationNote': typeof DuplicateConversationNoteComponent;
    'inbox2/conversation-stream/part-groups/duplicate-conversation-note': typeof DuplicateConversationNoteComponent;
  }
}
