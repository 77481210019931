/* import __COLOCATED_TEMPLATE__ from './relationship-attribute-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import { type SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { tracked } from '@glimmer/tracking';

interface RelationshipAttributeItem {
  external_id: string;
  id: string;

  // These are referenced in the template, but missing here. I'm not sure this
  // is the correct way to do this, we should probably serialize the wire
  // format.
  external_updated_at: string;
  external_created_at: string;
  attribute_values: Record<string, unknown>;
}

interface Args {
  sidebarSection: SidebarSection;
  onExpandedSectionChange: (id: SupportedSidebarSection | number | undefined) => unknown;
  conversationId: number;
  value: RelationshipAttributeItem[];
  icon?: string;
}

interface Signature {
  Args: Args;
}

export default class RelationshipAttributeListItem extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare inboxSidebarService: InboxSidebarService;
  @tracked expandedAttributeId?: string;

  @action onExpandAttribute(id: string, attribute_external_id: string) {
    this.expandedAttributeId = id;
    this.inboxSidebarService.onExpandCustomAttribute(this.expandedAttributeId);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'click',
      object: 'custom_objects',
      place: 'inbox2',
      conversation_id: this.args?.conversationId,
      expanded_attribute_id: this.expandedAttributeId,
      external_id: attribute_external_id,
    });
  }

  @action onClearAttribute() {
    this.expandedAttributeId = undefined;
    this.inboxSidebarService.onExpandCustomAttribute(undefined);
  }

  get expandedAttribute() {
    return this.args.value?.find((item) => item.id === this.expandedAttributeId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::RelationshipAttributeListItem': typeof RelationshipAttributeListItem;
    'inbox2/conversation-details-sidebar/sidebar-sections/relationship-attribute-list-item': typeof RelationshipAttributeListItem;
  }
}
