/* import __COLOCATED_TEMPLATE__ from './side-conversation-event-group.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type SideConversationStarted from 'embercom/objects/inbox/renderable/side-conversation-started';
import type SideConversationReply from 'embercom/objects/inbox/renderable/side-conversation-reply';
import { helper } from '@ember/component/helper';
import { type SideConversationStartedPart } from '../event-parts/side-conversation-started';
import { type SideConversationReplyPart } from '../event-parts/side-conversation-reply';

interface SideConversationPart extends RenderablePart {
  renderableData: SideConversationStarted | SideConversationReply;
}

export interface SideConversationEventGroupParts extends PartGroup {
  parts: Array<SideConversationPart>;
}

interface Args {
  partGroup: SideConversationEventGroupParts;
  isTicketStream: boolean;
  isPreviewPanel: boolean;
}

interface Signature {
  Args: Args;
}

export default class SideConversationEventGroup extends Component<Signature> {
  get renderableTypes() {
    return RenderableType;
  }

  convertToEventStartedType = helper(function ([part]: [any]): SideConversationStartedPart {
    return part as unknown as SideConversationStartedPart;
  });

  convertToEventReplyType = helper(function ([part]: [any]): SideConversationReplyPart {
    return part as unknown as SideConversationReplyPart;
  });
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::SideConversationEventGroup': typeof SideConversationEventGroup;
    'inbox2/conversation-stream/part-groups/side-conversation-event-group': typeof SideConversationEventGroup;
  }
}
