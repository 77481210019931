/* import __COLOCATED_TEMPLATE__ from './types-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type CustomIntlService from 'embercom/services/intl';
import { MacroType } from 'embercom/objects/inbox/macro';
import type CreateMacroModal from './modal';

interface Args {
  types: MacroType[];
  onSelectTypes: CreateMacroModal['selectTypes'];
}

interface Signature {
  Args: Args;
}

export default class MacroTypesSelector extends Component<Signature> {
  @service declare intl: CustomIntlService;

  get availableWhenOptions() {
    return [
      {
        id: MacroType.Opener,
        label: this.intl.t('inbox.create-macro.available-when.opener'),
        isSelected: this.args.types.includes(MacroType.Opener),
      },
      {
        id: MacroType.Reply,
        label: this.intl.t('inbox.create-macro.available-when.reply'),
        isSelected: this.args.types.includes(MacroType.Reply),
      },
      {
        id: MacroType.Note,
        label: this.intl.t('inbox.create-macro.available-when.note'),
        isSelected: this.args.types.includes(MacroType.Note),
      },
    ];
  }

  get availableWhenLabel() {
    if (this.args.types.length) {
      let selectedOptions = this.availableWhenOptions
        .filter((option) => this.args.types.includes(option.id))
        .map((option) => option.label);

      return this.intl.formatList(selectedOptions, { style: 'long', type: 'conjunction' });
    } else {
      return this.intl.t('inbox.create-macro.available-when.placeholder');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CreateMacro::TypesSelector': typeof MacroTypesSelector;
    'inbox2/create-macro/types-selector': typeof MacroTypesSelector;
  }
}
