/* import __COLOCATED_TEMPLATE__ from './user-details.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Conversation from 'embercom/objects/inbox/conversation';
import User, { type UserAttributes } from 'embercom/objects/inbox/user';
import AttributeIconMap from 'embercom/models/data/attribute-icon-map';
import type Attribute from 'embercom/objects/inbox/attribute';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { request } from 'embercom/lib/inbox/requests';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type Session from 'embercom/services/session';
import { type SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface UserAttributeWithIconLinkParams
  extends Omit<Attribute, 'category' | 'isCompany' | 'options' | 'isRelationshipDataType'> {
  icon?: InterfaceIconName;
  linkParams?: { route: string; models: unknown[] } | null;
}

interface Args {
  conversation: Conversation;
  user?: User;
  userAttributes: Attribute[];
  toggleUserDetailsPin: (pinnedAttrsKeys: string[]) => unknown;
  isPreviewingConversation?: boolean;
}

interface Signature {
  Args: SidebarSectionComponentArgs<Args>;
}

type SectionId = 'userDetails';

export const BLOCKLISTED_ATTRIBUTE_NAMES = [
  'anonymous',
  'name',
  'role',
  'geoip_data.continent_code',
  'geoip_data.country_name',
  'geoip_data.country_code',
  'geoip_data.region_name',
  'geoip_data.city_name',
  'geoip_data.timezone',
  'social_accounts.facebook.accounts.0.url',
  'social_accounts.twitter.accounts.0.url',
  'social_accounts.linkedin.accounts.0.url',
  'social_accounts.klout.accounts.0.kscore',
  'social_accounts.job_title',
  'company.tag_ids',
  'manual_tag_ids',
  'account_id',
  'owner_name',
  'opted_in_subscription_type_ids',
  'opted_out_subscription_type_ids',
  'companies',
];

export const ALWAYS_PINNED_ATTRIBUTE_KEYS = ['name', 'companyName', 'role', 'location'];

export const DEFAULT_ICON_NAME = 'transfer' as const;

export default class UserDetails extends Component<Signature> {
  readonly sectionId: SectionId = 'userDetails';
  @trackedReset('args.conversation') searchAttributeValue = '';

  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare inboxSidebarService: InboxSidebarService;

  @action onExpandedSectionChange(id: SupportedSidebarSection | number | undefined) {
    this.searchAttributeValue = '';
    this.args.onExpandedSectionChange(id);
  }

  get filteredAttributes() {
    if (this.searchAttributeValue.trim()) {
      return this.pinnedAndUnpinnedAttributes.filter((attr: Attribute) => {
        return (
          attr &&
          attr.humanFriendlyName
            .toLocaleLowerCase()
            .includes(this.searchAttributeValue.toLocaleLowerCase())
        );
      });
    } else {
      return this.pinnedAndUnpinnedAttributes;
    }
  }

  get user() {
    return this.args.user;
  }

  get isNewUser() {
    return this.user?.id === '';
  }

  get newUserAttributes() {
    return [this.userNameAttribute, this.userEmailAttribute];
  }

  get pinnedAttributes() {
    return [
      this.userNameAttribute,
      this.companyNameAttribute,
      this.roleAttribute,
      this.userLocationAttribute,
      ...this.allAttributes.filter((attribute) => attribute.adminVisibilitySetting.visible),
    ];
  }

  get unpinnedAttributes() {
    return this.allAttributes.filter((attribute) => !attribute.adminVisibilitySetting.visible);
  }

  get pinnedAndUnpinnedAttributes() {
    return [...this.pinnedAttributes, ...this.unpinnedAttributes];
  }

  get allAttributes() {
    return (this.args.userAttributes ?? []).reduce((attributes, attr) => {
      if (!attr || BLOCKLISTED_ATTRIBUTE_NAMES.includes(attr.key)) {
        return attributes;
      }

      attributes.push({
        ...attr,
        icon:
          AttributeIconMap.icons[attr.key as keyof typeof AttributeIconMap.icons] ??
          DEFAULT_ICON_NAME,
      });
      return attributes;
    }, [] as UserAttributeWithIconLinkParams[]);
  }

  get emailIdentifiesUser() {
    return this.user?.hasUserRole && !this.user?.userId;
  }

  get userNameAttribute(): UserAttributeWithIconLinkParams {
    return {
      key: 'name',
      name: 'name',
      humanFriendlyName: this.intl.t('inbox.conversation-details-sidebar.attributes.name'),
      type: 'string',
      icon: 'multiple-people',
      value: this.user?.name,
      linkParams: this.user?.id
        ? { route: 'apps.app.users.user', models: [this.session.workspace.id, this.user?.id] }
        : null,
      adminVisibilitySetting: {
        visible: true,
      },
      requiresFallback: true,
    };
  }

  get userEmailAttribute() {
    return {
      key: 'email',
      humanFriendlyName: this.intl.t('inbox.conversation-details-sidebar.attributes.email'),
      type: 'string',
      icon: 'email',
      value: this.user?.email,
    };
  }

  get companyNameAttribute(): UserAttributeWithIconLinkParams {
    return {
      key: 'companyName',
      name: 'companyName',
      humanFriendlyName: this.intl.t('inbox.conversation-details-sidebar.attributes.company'),
      type: 'string',
      icon: 'company',
      value: this.user?.company?.name,
      linkParams: this.user?.company
        ? {
            route: 'apps.app.companies.company',
            models: [this.session.workspace.id, this.user?.company.id],
          }
        : null,
      adminVisibilitySetting: {
        visible: true,
      },
      requiresFallback: true,
    };
  }

  get roleAttribute(): UserAttributeWithIconLinkParams | undefined {
    let roleAttribute = this.args.userAttributes.find(
      (attribute) => attribute?.key === 'role',
    ) as UserAttributeWithIconLinkParams;
    if (roleAttribute) {
      roleAttribute = {
        ...roleAttribute,
        icon: 'person',
        adminVisibilitySetting: {
          visible: true,
        },
      };
    }
    return roleAttribute;
  }

  get userLocationAttribute(): UserAttributeWithIconLinkParams {
    return {
      key: 'location',
      name: 'location',
      humanFriendlyName: this.intl.t('inbox.conversation-details-sidebar.attributes.location'),
      type: 'string',
      icon: 'location',
      value: this.userLocation,
      adminVisibilitySetting: {
        visible: true,
      },
      requiresFallback: true,
    };
  }

  get alwaysPinnedAttributeKeys() {
    return ALWAYS_PINNED_ATTRIBUTE_KEYS;
  }

  get userName(): string {
    let userName =
      this.user?.attributes.name ||
      this.args.userAttributes?.find((attr) => attr.key === 'name')?.value;
    if (!userName) {
      return this.roleValue;
    }
    return userName;
  }

  get roleValue(): string {
    let userRoleValue = this.intl
      .t('inbox.conversation-details-sidebar.role.user')
      ?.toLocaleLowerCase();
    let role = this.args.userAttributes?.find((attr) => attr.key === 'role');
    let roleValue = role?.value || this.user?.attributes['role'];
    if (roleValue === 'contact_role') {
      return this.intl.t('inbox.conversation-details-sidebar.role.contact')?.toLocaleLowerCase();
    }
    return userRoleValue;
  }

  get locationFallbackText(): string {
    return this.intl.t('inbox.attribute-list-item.location-fallback', { username: this.userName });
  }

  get companyNameFallbackText(): string {
    return this.intl.t('inbox.attribute-list-item.company-fallback', { username: this.userName });
  }

  get userNameFallbackText(): string {
    return this.intl.t('inbox.attribute-list-item.username-fallback', { type: this.roleValue });
  }

  get potentialDuplicates(): User[] {
    return this.potentialDuplicatesLoader.value?.users ?? [];
  }

  get morePotentialDuplicatesExist(): boolean {
    return !!this.potentialDuplicatesLoader.value?.moreExist;
  }

  private get userLocation() {
    let geoipData = this.user?.geoipData;
    if (geoipData?.hasData()) {
      let { cityName, countryCode2, countryName, regionCode, timezone } = geoipData;

      let localTime = timezone
        ? this.intl.formatTime(new Date(), {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timezone,
          })
        : '';

      let locationParts;
      if (countryCode2 === 'US') {
        locationParts = [cityName, regionCode, countryName];
      } else {
        locationParts = [cityName, countryName];
      }

      let location = locationParts.filter((n) => n).join(', ');
      return [localTime, location].filter((n) => n).join(' · ');
    }

    return '';
  }

  @action togglePin(key: string) {
    let pinnedAttrsKeys: string[] = this.pinnedAttributes.reduce((arr: string[], attr) => {
      if (attr?.key) {
        arr.push(attr.key);
      }
      return arr;
    }, []);
    let attribute: UserAttributeWithIconLinkParams | undefined = this.allAttributes.find(
      (attr) => attr && attr.key === key,
    );

    if (attribute) {
      if (attribute.adminVisibilitySetting.visible) {
        pinnedAttrsKeys = pinnedAttrsKeys.filter((attrKey) => attrKey !== attribute?.key);
      } else {
        pinnedAttrsKeys = [...pinnedAttrsKeys, attribute.key];
      }
      this.args.toggleUserDetailsPin(pinnedAttrsKeys);
    }
  }

  @action
  expandDuplicates() {
    let conversationId =
      this.args.conversation instanceof Conversation ? this.args.conversation?.id : undefined;
    this.inboxSidebarService.onExpandPotentialDuplicates(
      this.potentialDuplicates,
      this.morePotentialDuplicatesExist,
      conversationId,
    );
  }

  @action
  collapseDuplicates() {
    let conversationId =
      this.args.conversation instanceof Conversation ? this.args.conversation?.id : undefined;
    this.inboxSidebarService.onExpandPotentialDuplicates(
      null,
      this.morePotentialDuplicatesExist,
      conversationId,
    );
  }

  private potentialDuplicatesLoader = DeduplicatedAsyncData(
    this,
    () => [this.args.user?.id],
    async (userId, { signal }) => {
      let response = await request(
        `/ember/users/duplicates.json?app_id=${this.session.workspace.id}&id=${userId}`,
        { signal },
      );
      let responseJson = await response.json();
      let users = responseJson.users.map((userAttributes: UserAttributes) => {
        return new User(userAttributes);
      });

      return {
        users,
        moreExist: !!responseJson.more_exist,
      };
    },
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::UserDetails': typeof UserDetails;
    'inbox2/conversation-details-sidebar/sidebar-sections/user-details': typeof UserDetails;
  }
}
