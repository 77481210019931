/* import __COLOCATED_TEMPLATE__ from './timestamp.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    time: any;
  };
}

const Timestamp = templateOnlyComponent<Signature>();
export default Timestamp;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::Timestamp': typeof Timestamp;
    'inbox2/conversation-stream/part-groups/timestamp': typeof Timestamp;
  }
}
