/* import __COLOCATED_TEMPLATE__ from './linked-ticket-shared.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import LinkWithText from 'embercom/helpers/link-with-text';
import type LinkedTicketShared from 'embercom/objects/inbox/renderable/linked-ticket-shared';
import type IntlService from 'embercom/services/intl';
import type InboxApi from 'embercom/services/inbox-api';
import { type TicketType } from 'embercom/objects/inbox/ticket';
import { trackedFunction } from 'ember-resources/util/function';
import type Router from '@ember/routing/router-service';
//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

interface LinkedTicketSharedPart extends RenderablePart {
  renderableData: LinkedTicketShared;
}

interface Args {
  part: LinkedTicketSharedPart;
}

interface Signature {
  Args: Args;
}

type TranslationData = {
  key: string;
  data: {
    value: string;
    who?: string;
  };
};

export default class Inbox2ConversationStreamEventPartsLinkedTicketSharedComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare inboxApi: InboxApi;

  // @ts-ignore
  linkWithTextHelper = LinkWithText.create(this);

  get renderableData() {
    return this.args.part.renderableData;
  }

  get teammate() {
    return this.renderableData.adminSummary;
  }

  get ticketTypes(): TicketType[] {
    return this.ticketTypesLoader.value ?? [];
  }

  private ticketTypesLoader = trackedFunction(this, async () => {
    return await this.inboxApi.listTicketTypes();
  });

  get didIAuthorTheEvent() {
    return this.teammate.id === this.session.teammate.id;
  }

  ticketLink() {
    let ticketId = this.renderableData.ticketId;
    let id = this.renderableData?.id;
    let ticketType = this.renderableData.ticketType;

    // @ts-ignore
    let href = this.router.urlFor(this.router.currentRouteName, id);

    return sanitizeHtml(
      `<a class="hover:text-blue dark:hover:text-dm-blue no-underline" target="_blank" href=${href}>${ticketType.name} #${ticketId}</a>`,
    );
  }

  get translationData(): TranslationData {
    let data: TranslationData = {
      key: this.translationKey,
      data: {
        value: this.ticketLink(),
      },
    };

    if (!this.didIAuthorTheEvent) {
      data.data.who = this.linkWithTextHelper.compute([
        'apps.app.admins.admin',
        this.teammate.id,
        this.teammate.name,
      ]);
    }

    return data;
  }

  get translationKey() {
    let key = 'shared-linked-ticket';

    key = this.didIAuthorTheEvent ? `you-${key}` : `admin-${key}`;

    return `inbox.ticket-shared.${key}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::LinkedTicketShared': typeof Inbox2ConversationStreamEventPartsLinkedTicketSharedComponent;
    'inbox2/conversation-stream/event-parts/linked-ticket-shared': typeof Inbox2ConversationStreamEventPartsLinkedTicketSharedComponent;
  }
}
