/* import __COLOCATED_TEMPLATE__ from './confirm-unlinking.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Signature {
  Args: {
    onClose: () => void;
    onModalClose: () => void;
    onConfirm: () => void;
    ticket: LinkedTicketSummary;
    conversation: Conversation;
  };
}

const ConfirmUnlinking = templateOnlyComponent<Signature>();
export default ConfirmUnlinking;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Links::ConfirmUnlinking': typeof ConfirmUnlinking;
    'inbox2/conversation-details-sidebar/sidebar-sections/links/confirm-unlinking': typeof ConfirmUnlinking;
  }
}
