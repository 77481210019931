/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { useResource } from 'ember-resources';
import type IntlService from 'embercom/services/intl';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import EmojiNavigationResource from 'embercom/components/inbox2/command-k/emoji-navigation-resource';
import type EmojiAction from 'embercom/objects/inbox/command-k/emoji';
import { ChildAction } from 'embercom/objects/inbox/command-k/action';

// @ts-ignore
import { ref } from 'ember-ref-bucket';

// @ts-ignore
import { cached } from 'tracked-toolbox';

// @ts-ignore
import intermoji from '@intercom/intermoji';
import storage from 'embercom/vendor/intercom/storage';
import type CommandKService from 'embercom/services/command-k';
import type Session from 'embercom/services/session';
import {
  type DefaultYellowSkinTone,
  type SkinToneModifiers,
} from 'embercom/services/emoji-service';
import type EmojiService from 'embercom/services/emoji-service';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';

interface Signature {
  Args: CommandKPaneComponentArgs<EmojiAction>;
}

const EMOJI_PER_PAGE = 100;
const DEFAULT_EMOJIS = Object.freeze([
  'thumbs_up',
  '-1',
  'sob',
  'confused',
  'neutral_face',
  'blush',
  'heart_eyes',
]);

type Icon = string;
export type ItemGroups = Record<string, ChildAction[]>;

export default class EmojiPaneComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare commandK: CommandKService;
  @service declare session: Session;
  @service declare emojiService: EmojiService;

  @ref('ul') declare ul: HTMLUListElement;

  @tracked query = '';
  @tracked limit = EMOJI_PER_PAGE;

  readonly selection = useResource(this, NavigableSelection, () => ({
    items: this.items,
    isCommandKVisible: this.commandK.isVisible,
  }));

  readonly keyboardNavigableItemsResource = useResource(this, EmojiNavigationResource, () => ({
    selection: this.selection,
    items: this.itemGroups,
  }));

  get skinToneModifier() {
    return this.emojiService.skinToneModifier;
  }

  get allEmojisVisible() {
    return this.allEmojis.length <= this.items.length;
  }

  get items() {
    return Object.values(this.itemGroups).flat();
  }

  get itemGroups() {
    let itemGroups: ItemGroups = {};

    let frequentlyUsedEmojis = this.getFrequentlyUsedEmojis();
    if (!this.query && frequentlyUsedEmojis.length > 0) {
      itemGroups[this.intl.t('inbox.command-k.emojis.frequently-used')] = frequentlyUsedEmojis;
    }

    let regexp = new RegExp(this.query, 'i');
    let currentIndex = frequentlyUsedEmojis.length;

    for (let [index, unicodeIcons] of this.emojiGroups.entries()) {
      let [, groupName] = this.groupRepresentatives[index];

      if (!itemGroups[groupName]) {
        itemGroups[groupName] = [];
      }

      for (let icon of unicodeIcons) {
        if (currentIndex >= this.limit) {
          break;
        }

        let identifier = intermoji.identifierFromUnicode(icon);
        if (intermoji.isEmojiBaseUnicode(icon) && regexp.test(identifier)) {
          currentIndex += 1;
          itemGroups[groupName].push(
            this.actionFor(identifier, identifier, this.emojiService.skinTonedModifiedEmoji(icon)),
          );
        }
      }

      if (currentIndex >= this.limit) {
        break;
      }
    }

    return itemGroups;
  }

  @action loadMore() {
    if (this.limit >= this.allEmojis.length) {
      return;
    }

    let oldLimit = this.limit;
    this.limit += EMOJI_PER_PAGE;
    this.selection.select(this.items[Math.min(oldLimit, this.items.length - 1)]);
  }

  @action search(query: string) {
    this.query = query;
    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.items.length,
    });
  }

  @action updateSkinToneModifier(skinToneModifier: SkinToneModifiers | DefaultYellowSkinTone) {
    this.emojiService.updateSkinToneModifier(skinToneModifier);
  }

  @cached
  private get allEmojis() {
    if (
      this.skinToneModifier !== ('default-yellow-skin-tone' as DefaultYellowSkinTone) &&
      this.skinToneModifier !== null
    ) {
      return this.allEmojisBySkinToneModifier.get(this.skinToneModifier);
    }

    return this.allBaseEmojis;
  }

  @cached
  private get allBaseEmojis() {
    return this.emojiGroups.flat().filter((icon: Icon) => intermoji.isEmojiBaseUnicode(icon));
  }

  @cached
  private get allEmojisBySkinToneModifier() {
    let allBaseEmojis = this.allBaseEmojis;

    let emojisBySkinToneModifier = new Map<number, Array<Icon>>();
    let skinToneModifiers = ['🏻', '🏼', '🏽', '🏾', '🏿'].map(
      (skinTone) => skinTone.codePointAt(0) as number,
    );
    let modifiedEmojiBySkinToneModifierMemo = new Map<number, Icon | null>();

    skinToneModifiers.forEach((skinToneModifier) => {
      emojisBySkinToneModifier.set(skinToneModifier, []);
      modifiedEmojiBySkinToneModifierMemo.set(skinToneModifier, null);
    });

    for (let emoji of allBaseEmojis) {
      let modifiedEmojis = intermoji.getAllModifiedForBaseEmoji(emoji) as Array<Icon>;

      if (modifiedEmojis.length > 1) {
        for (let skinToneModifierKey of skinToneModifiers.keys()) {
          modifiedEmojiBySkinToneModifierMemo.set(skinToneModifierKey, null);
        }

        for (let modifiedEmoji of modifiedEmojis) {
          let codePoints = Array.from(modifiedEmoji).map((char) => char.codePointAt(0) as number);

          for (let codePoint of codePoints) {
            if (modifiedEmojiBySkinToneModifierMemo.has(codePoint)) {
              modifiedEmojiBySkinToneModifierMemo.set(codePoint, modifiedEmoji);
            }
          }
        }
      }

      for (let skinToneModifier of skinToneModifiers) {
        let emojis = emojisBySkinToneModifier.get(skinToneModifier) as Array<Icon>;

        if (modifiedEmojis.length === 1) {
          emojis.push(modifiedEmojis[0]);
        } else {
          let matchedModifiedEmoji = modifiedEmojiBySkinToneModifierMemo.get(skinToneModifier);
          emojis.push(matchedModifiedEmoji || emoji);
        }

        emojisBySkinToneModifier.set(skinToneModifier, emojis);
      }
    }

    return emojisBySkinToneModifier;
  }

  @cached
  private get emojiGroups() {
    return intermoji.prettyEmoticonsUnicodeGroups();
  }

  @cached
  private get groupRepresentatives() {
    return intermoji.getGroupRepresentatives();
  }

  private getFrequentlyUsedEmojis() {
    let userEmojis = storage.get('userEmojis') ?? [];

    return userEmojis
      .concat(DEFAULT_EMOJIS)
      .uniq()
      .map((identifier: string) => {
        let icon = intermoji.unicodeFromIdentifier(identifier);
        if (!icon) {
          return;
        }

        return this.actionFor(
          `frequent-${identifier}`,
          identifier,
          this.emojiService.skinTonedModifiedEmoji(this.emojiService.baseEmojiFor(icon)),
        );
      })
      .compact();
  }

  private actionFor(id: string, identifier: string, icon: Icon) {
    return new ChildAction({
      parent: this.args.actionItem,
      id,
      label: identifier,
      value: icon,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Emoji::Pane': typeof EmojiPaneComponent;
    'inbox2/command-k/emoji/pane': typeof EmojiPaneComponent;
  }
}
