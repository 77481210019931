/* import __COLOCATED_TEMPLATE__ from './participant-avatars.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ParticipantUserSummary from 'embercom/objects/inbox/participant-user-summary';
import { first, last } from 'underscore';

interface Args {
  activeParticipantId?: string;
  participantSummaries: ParticipantUserSummary[];
  updateActiveParticipant: (id: string) => unknown;
}

interface Signature {
  Args: Args;
}

const AVATAR_WIDTH = 32;
const AVATAR_GAP = 8;
const OFFSET = 48;
const DEFAULT_CONTAINER_WIDTH = 160;

export default class UnnamedComponent extends Component<Signature> {
  @tracked containerWidth: number = DEFAULT_CONTAINER_WIDTH;

  get visibleCount() {
    return Math.floor((this.containerWidth - OFFSET) / (AVATAR_WIDTH + AVATAR_GAP));
  }

  get visibleParticipants() {
    return first(this.args.participantSummaries, this.visibleCount);
  }

  get hiddenParticipants() {
    return last(
      this.args.participantSummaries,
      this.args.participantSummaries.length - this.visibleCount,
    );
  }

  @action updateActiveParticipant(participant: ParticipantUserSummary) {
    this.args.updateActiveParticipant(participant.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::ParticipantAvatars': typeof UnnamedComponent;
    'inbox2/conversation-details-sidebar/participant-avatars': typeof UnnamedComponent;
  }
}
