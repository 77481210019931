/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import type NavigableSelection from '../../common/navigable-selection-resource';

type Args = {
  items: Array<{ actionItem: ChildAction; visibleToUser: boolean }>;
  heading: string;
  helpCenterId: string;
  showHeading: boolean;
  selection: NavigableSelection;
  onSelect: (item: { actionItem: ChildAction; visibleToUser: boolean }) => void;
};

export default class InsertArticleListComponent extends Component<Args> {
  get filteredItems() {
    return this.args.items.filter((i) => {
      return i.actionItem.trackingData.help_center_id === this.args.helpCenterId;
    });
  }

  get hasItemsToShow() {
    return this.filteredItems.length > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::InsertArticle::List': typeof InsertArticleListComponent;
    'inbox2/command-k/insert-article/list': typeof InsertArticleListComponent;
  }
}
