/* import __COLOCATED_TEMPLATE__ from './latest-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type LatestConversationSummary from 'embercom/objects/inbox/latest-conversation-summary';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Session from 'embercom/services/session';
import type InboxState from 'embercom/services/inbox-state';
import type IntlService from 'embercom/services/intl';

import { action } from '@ember/object';
import { type TicketStateUpdatedEvent } from 'embercom/services/nexus';

interface Args {
  conversation: LatestConversationSummary;
  handleConversationClick: (conversation: LatestConversationSummary) => void;
  summaryPart?: RenderablePart;
  readonly?: boolean;
  setTicketTypeToLinkToConversation?: (ticketTypeId?: number) => void;
  onTicketStateUpdate?: () => void;
}

interface Signature {
  Args: Args;
}

export default class LatestConversation extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;

  @action async handleTicketStateUpdatedEvent(e: TicketStateUpdatedEvent) {
    let data = e.eventData;
    let ticketId = parseInt(data.conversation_id, 10);
    if (ticketId === this.args.conversation.id) {
      this.args.onTicketStateUpdate?.();
    }
  }

  get adminAvatarShape(): 'squircle' | undefined {
    if (this.args.conversation?.adminAssignee?.isOperator) {
      return 'squircle';
    }
    return undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::LatestConversations::LatestConversation': typeof LatestConversation;
    'inbox2/conversation-details-sidebar/sidebar-sections/latest-conversations/latest-conversation': typeof LatestConversation;
  }
}
