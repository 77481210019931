/* import __COLOCATED_TEMPLATE__ from './cell-name.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
interface Args {
  admin: any;
}

interface Signature {
  Args: Args;
}

export default class CellName extends Component<Signature> {
  get adminInbox() {
    return {
      category: InboxCategory.Admin,
      id: this.args.admin.id,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Teammates::CellName': typeof CellName;
    'inbox2/dashboard/teammates/cell-name': typeof CellName;
  }
}
