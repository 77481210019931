/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type Block } from '@intercom/interblocks.ts';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';
import type ConvertToTicketAction from 'embercom/objects/inbox/command-k/convert-to-ticket';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState } from 'embercom/objects/inbox/conversation';
import type LatestConversationSummary from 'embercom/objects/inbox/latest-conversation-summary';
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import type CommandKService from 'embercom/services/command-k';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type IntlService from 'embercom/services/intl';

interface Signature {
  Args: CommandKPaneComponentArgs<ConvertToTicketAction>;
}

interface renderableDataType extends RenderableData {
  blocks?: Block[] | undefined;
}

export default class MergeIntoPaneComponent extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;

  @tracked query = '';
  @tracked isConfirmationModalOpen = false;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.latestConversations,
    isCommandKVisible: this.commandK.isVisible,
  }));

  get conversation() {
    return this.inboxState.activeConversation as Conversation;
  }

  get latestConversations() {
    let latestConversations = this.latestConversationsLoader.value;

    if (!latestConversations) {
      return [];
    }

    let filteredConversations = this.query
      ? this.sortLatestConversations(latestConversations).filter((conversation) =>
          this.isConversationMatchQuery(conversation, this.query),
        )
      : this.sortLatestConversations(latestConversations);

    return filteredConversations;
  }

  @action hide() {
    this.commandK.hide();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'exit',
      object: 'commandk_merge_modal',
      section: 'composer',
    });
  }

  private isConversationMatchQuery(
    conversation: LatestConversationSummary,
    query: string,
  ): boolean {
    let lowerCaseTitle = conversation.title?.toLowerCase() || '';
    let lowerCaseQuery = query.toLowerCase();
    let renderableData = conversation.lastRenderableSummaryPart
      ?.renderableData as renderableDataType;
    let blockData: any = renderableData?.blocks?.firstObject;
    let conversationId = conversation.id.toString();
    let ticketId = conversation.ticketId?.toString();
    let textToSearch = (blockData && blockData.text?.toLowerCase()) || '';
    return (
      textToSearch.includes(lowerCaseQuery) ||
      lowerCaseTitle.includes(lowerCaseQuery) ||
      conversationId.includes(lowerCaseQuery) ||
      ticketId?.includes(lowerCaseQuery)
    );
  }

  get limitedLatestConversations() {
    return this.latestConversations.slice(0, 5);
  }

  latestConversationsLoader = DeduplicatedAsyncData(
    this,
    () => [this.conversation?.user.id, this.conversation?.id],
    async (userID, conversationID, { signal }) => {
      let { conversations } = await this.inboxApi.getUserConversations(userID, conversationID, {
        signal,
      });

      return conversations.filter((convo) => {
        return (
          convo.state !== ConversationState.Closed &&
          convo.participantSummaries.length <= 1 &&
          !convo.redacted &&
          (this.conversation.isTicket ? convo.isTicket : true)
        );
      });
    },
  );

  private sortLatestConversations(
    conversations: LatestConversationSummary[],
  ): LatestConversationSummary[] {
    return [...conversations].sort(
      (conv1: LatestConversationSummary, conv2: LatestConversationSummary) =>
        conv2.lastUpdated.getTime() - conv1.lastUpdated.getTime(),
    );
  }

  @action search(query: string) {
    this.query = query;

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.latestConversations.length,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::MergeInto::Pane': typeof MergeIntoPaneComponent;
    'inbox2/command-k/merge-into/pane': typeof MergeIntoPaneComponent;
  }
}
