/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';

interface Signature {
  Args: {
    items: ChildAction[];
    selection: any;
    onSelect: (item: ChildAction) => void;
  };
}

const List = templateOnlyComponent<Signature>();
export default List;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::AssignTo::List': typeof List;
    'inbox2/command-k/assign-to/list': typeof List;
  }
}
