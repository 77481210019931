/* import __COLOCATED_TEMPLATE__ from './topics.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type ConversationTopic from 'embercom/objects/inbox/conversation-topic';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import { type ExpandableSectionArgs } from '../../expandable-section';
import type ConversationalInsightsApi from 'embercom/services/inbox2-conversational-insights-api';

interface Args {
  conversation: Conversation;
  isPreviewingConversation?: boolean;
}

interface Signature {
  Args: Args &
    Pick<
      ExpandableSectionArgs,
      'isExpanded' | 'onExpandedSectionChange' | 'expandableSheet' | 'sidebarSectionId'
    >;
  Element: HTMLDivElement;
}

export default class Topics extends Component<Signature> {
  @service declare session: Session;
  @service declare notificationsService: any;
  @service declare inbox2ConversationalInsightsApi: ConversationalInsightsApi;

  @trackedReset('args.conversation.id') private newlyAddedTopics: ConversationTopic[] = [];
  @trackedReset('args.conversation.id') private removedTopics: ConversationTopic[] = [];

  private topicsLoader = DeduplicatedAsyncData(
    this,
    () => [this.args.conversation.id],
    (conversationId, { signal }) => {
      return this.inbox2ConversationalInsightsApi.fetchTopicsForConversation(conversationId, {
        signal,
      });
    },
  );

  get topics(): ConversationTopic[] {
    return [...this.loadedTopics, ...this.newlyAddedTopics].filter(
      (x) => !this.removedTopics.some((y) => y === x),
    );
  }

  get selectedTopicIds() {
    return this.topics.map((t) => Number(t.id));
  }

  private get loadedTopics(): ConversationTopic[] {
    return this.topicsLoader.value || [];
  }

  @action async toggleTopic(topic: ConversationTopic) {
    let existingTopic = this.topics.find((x) => x.id === topic.id);
    if (existingTopic) {
      this.removeTopic(existingTopic);
    } else {
      this.addTopic(topic);
    }
  }

  @action async addTopic(topic: ConversationTopic) {
    if (this.selectedTopicIds.includes(topic.id)) {
      return;
    }

    if (this.removedTopics.some((x) => x === topic)) {
      this.removedTopics.removeObject(topic);
    } else {
      this.newlyAddedTopics.addObject(topic);
    }

    try {
      await post(`/ember/conversations/${this.args.conversation.id}/topics/add`, {
        app_id: this.session.workspace.id,
        topic_ids: [topic.id.toString()],
      });
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
      this.newlyAddedTopics.removeObject(topic);
    }
  }

  @action async removeTopic(topic: ConversationTopic, event?: PointerEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.newlyAddedTopics.some((x) => x === topic)) {
      this.newlyAddedTopics.removeObject(topic);
    } else {
      this.removedTopics.addObject(topic);
    }

    try {
      await post(`/ember/conversations/${this.args.conversation.id}/topics/remove`, {
        app_id: this.session.workspace.id,
        topic_ids: [topic.id.toString()],
      });
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
      this.removedTopics.removeObject(topic);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::ConversationDetails::Topics': typeof Topics;
    'inbox2/conversation-details-sidebar/sidebar-sections/conversation-details/topics': typeof Topics;
  }
}
