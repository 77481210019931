/* import __COLOCATED_TEMPLATE__ from './export-ticket-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Args {
  onClose: () => void;
  exportConversation: () => void;
}

interface Signature {
  Args: Args;
}

export default class ExportTicketModal extends Component<Signature> {
  @tracked exportNotesAndHiddenAttributes = false;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ExportTicketModal': typeof ExportTicketModal;
    'inbox2/export-ticket-modal': typeof ExportTicketModal;
  }
}
