/* import __COLOCATED_TEMPLATE__ from './edit-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { put } from 'embercom/lib/ajax';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import { InboxFolder } from 'embercom/objects/inbox/inbox-folder';

interface Args {
  inboxFolder: InboxFolder;
  onClose: (args: { success: boolean; inboxFolder?: InboxFolder }) => void;
  onDelete: (inboxFolderId: number) => void;
}

interface Signature {
  Args: Args;
}

export default class EditModal extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare intercomEventService: any;

  @tracked name;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.name = args.inboxFolder.name;
  }

  get isValid() {
    let name = this.name.trim();
    return name.match(/^.{1,50}$/) !== null;
  }

  @action cancel() {
    this.args.onClose({ success: false });
  }

  @task({ restartable: true })
  *updateCustomFolder(): TaskGenerator<void> {
    try {
      let result = yield put(
        `/ember/inbox/inbox_folders/${this.args.inboxFolder.id}?app_id=${this.session.workspace.id}`,
        {
          name: this.name,
          objects: this.args.inboxFolder.objects.map((inboxFolderObject) => {
            return {
              object_type: inboxFolderObject.objectType,
              object_id: inboxFolderObject.objectId,
              position: inboxFolderObject.position,
            };
          }),
        },
      );

      this.snackbar.notify(this.intl.t('inbox.custom-folders.modals.edit.success-message'));

      let inboxFolder = InboxFolder.deserialize(result.inbox_folder);

      this.trackAnalyticsEvent(inboxFolder);

      this.args.onClose({ success: true, inboxFolder });
    } catch (error) {
      if (error.jqXHR.responseJSON.error === 'Name has already been taken') {
        this.snackbar.notifyError(
          this.intl.t('inbox.custom-folders.modals.shared.name-already-taken-error-message'),
        );
      } else {
        this.snackbar.notifyError(
          this.intl.t('inbox.custom-folders.modals.edit.generic-error-message'),
        );
      }
    }
  }

  trackAnalyticsEvent(inboxFolder: InboxFolder) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated',
      object: 'inbox_folder',
      id: inboxFolder.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CustomFolders::EditModal': typeof EditModal;
    'inbox2/custom-folders/edit-modal': typeof EditModal;
  }
}
