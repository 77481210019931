/* import __COLOCATED_TEMPLATE__ from './conversation-preview.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { SidebarEvents } from 'embercom/services/inbox-sidebar-service';
import { inject as service } from '@ember/service';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import { registerDestructor } from '@ember/destroyable';

interface Args {
  onPreviewConversation: (isPreviewing: boolean) => void;
  onExpandedSectionChange: (sectionId: SupportedSidebarSection | number | undefined) => void;
}

interface Signature {
  Args: Args;
}

export default class ConversationPreview extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare inboxState: InboxState;

  @tracked conversationId?: number = undefined;
  @tracked selectedTab?: string = undefined;
  @tracked shouldFocusComposer?: boolean = false;
  @tracked parentConversationId?: number = undefined;
  @tracked isPreviewingFromCopilot?: boolean = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.setupPreviewConversationListener();
    this.setupPreviewSideConversationListener();
  }

  @action closeConversationPreview() {
    this.args.onExpandedSectionChange(undefined);
    this.inboxState.showConversationList();
    this.conversationId = undefined;
    this.parentConversationId = undefined;
    this.selectedTab = undefined;
    this.shouldFocusComposer = false;
    this.isPreviewingFromCopilot = false;
    this.args.onPreviewConversation(false);
    this.inboxSidebarService.isViewingSideConversation = false;
    this.inboxState.revertRHSBToOriginalSize();
    this.inboxSidebarService.isPreviewingLinkedItem = false;
  }

  @action resetShouldFocusComposer() {
    this.shouldFocusComposer = false;
  }

  @action handleConversationClick(
    id: number,
    selectedTab?: string,
    shouldFocusComposer?: boolean,
    isPreviewingFromCopilot?: boolean,
  ) {
    this.resetPreview(id);
    this.parentConversationId = undefined;
    this.selectedTab = selectedTab;
    this.shouldFocusComposer = shouldFocusComposer;
    this.isPreviewingFromCopilot = isPreviewingFromCopilot;

    this.args.onPreviewConversation(!!this.conversationId);
  }

  @action handleSideConversationClick(
    sideConversationId: number,
    parentConversationId: number,
    shouldFocusComposer?: boolean,
  ) {
    this.resetPreview(sideConversationId);
    this.shouldFocusComposer = shouldFocusComposer;
    this.parentConversationId = parentConversationId;
    this.args.onPreviewConversation(!!this.conversationId);
  }

  resetPreview(newConversationId: number) {
    if (newConversationId !== this.conversationId) {
      this.conversationId = newConversationId;
    }
    if (!this.conversationId) {
      this.closeConversationPreview();
    }
  }

  get isSideConversation() {
    return !!this.parentConversationId;
  }

  private setupPreviewConversationListener() {
    this.inboxSidebarService.on(SidebarEvents.PreviewConversation, this.handleConversationClick);
    registerDestructor(this, () => {
      this.inboxSidebarService.off(SidebarEvents.PreviewConversation, this.handleConversationClick);
    });
  }

  private setupPreviewSideConversationListener() {
    this.inboxSidebarService.on(
      SidebarEvents.PreviewSideConversation,
      this.handleSideConversationClick,
    );

    registerDestructor(this, () => {
      this.inboxSidebarService.off(
        SidebarEvents.PreviewSideConversation,
        this.handleSideConversationClick,
      );
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::ConversationPreview': typeof ConversationPreview;
    'inbox2/conversation-details-sidebar/conversation-preview': typeof ConversationPreview;
  }
}
