/* import __COLOCATED_TEMPLATE__ from './unengaged-inbound-bot-introduction.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type UnengagedInboundBotIntroduction from 'embercom/objects/inbox/renderable/unengaged-inbound-bot-introduction';
import LinkWithText from 'embercom/helpers/link-with-text';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface UnengagedInboundBotIntroductionPart extends RenderablePart {
  renderableData: UnengagedInboundBotIntroduction;
}

interface Args {
  part: UnengagedInboundBotIntroductionPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsUnengagedInboundBotIntroductionComponent extends Component<Signature> {
  @service declare session: Session;

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get customBotSummary() {
    return this.args.part.renderableData.customBotSummary;
  }

  get customBotLink() {
    return this.linkWithTextHelper.compute([
      'apps.app.operator.custom-bots.custom-bot.show',
      this.customBotSummary.id,
      this.customBotSummary.title,
    ]);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::UnengagedInboundBotIntroduction': typeof Inbox2ConversationStreamEventPartsUnengagedInboundBotIntroductionComponent;
    'inbox2/conversation-stream/event-parts/unengaged-inbound-bot-introduction': typeof Inbox2ConversationStreamEventPartsUnengagedInboundBotIntroductionComponent;
  }
}
