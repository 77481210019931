/* import __COLOCATED_TEMPLATE__ from './dropdown-button-nested-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type Optionable } from './dropdown-button';

interface Signature {
  Args: {
    option: Optionable;
    dropdownListContentClass?: string;
    onSelect?: (item: Optionable) => Promise<void> | void;
  };
  Blocks: {
    nestedListItem: [Optionable, any];
  };
}

export default class DropdownDownButtonNestedListItem extends Component<Signature> {
  @action
  onSelect(option: Optionable) {
    if (option.onSelect) {
      return option.onSelect();
    } else if (this.args.onSelect) {
      return this.args.onSelect(option);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::DropdownButtonNestedListItem': typeof DropdownDownButtonNestedListItem;
    'inbox2/common/dropdown-button-nested-list-item': typeof DropdownDownButtonNestedListItem;
  }
}
