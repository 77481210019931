/* import __COLOCATED_TEMPLATE__ from './content-group.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    partGroup: PartGroup;
  };
  Blocks: {
    avatar: [];
    partList: [];
  };
}

const ContentGroup = templateOnlyComponent<Signature>();
export default ContentGroup;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::ContentGroup': typeof ContentGroup;
    'inbox2/conversation-stream/part-groups/content-group': typeof ContentGroup;
  }
}
