/* import __COLOCATED_TEMPLATE__ from './search-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { useResource } from 'ember-resources';
import KeyboardNavigableItemsResource from 'embercom/components/inbox2/command-k/keyboard-navigable-items-resource';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { type PopoverArgs } from '@intercom/pulse/template-registry';
import { type WithBoundArgs } from '@glint/template';
import type SearchResultRow from './quick-search/search-result-row';

type DropdownSize = 'md' | 'lg' | 'xl';

export interface SearchDropdownArgs<R> {
  onEscape?: Function;
  searchPlaceholder: string;
  results: Array<R>;
  query: string;
  onPopoverShow?: PopoverArgs['onShow'];
  onPopoverHide?: PopoverArgs['onHide'];
  onQueryChanged: (query: string) => void;
  onLoadMore?: () => void;
  showPlaceholder?: boolean;
  isLoading?: boolean;
  showNoResults?: boolean;
  size?: DropdownSize;
  stopPropagation?: boolean;
  showOn?: PopoverArgs['showOn'];
  hideOn?: PopoverArgs['hideOn'];
  preventOverflow?: boolean;
  hideDelay?: number;
  initiallyOpen?: boolean;
  popoverPlacement?: PopoverArgs['placement'];
  fallbackPopoverPlacements?: PopoverArgs['fallbackPlacements'];
  repositionOnContentResize?: PopoverArgs['repositionOnContentResize'];
  verticalCollection?: boolean;
}

export interface SearchDropdownSignature<R> {
  Args: SearchDropdownArgs<R>;
  Element: HTMLDivElement;
  Blocks: {
    opener: [{ isOpen: boolean }];
    placeholder: [];
    dataRow: [
      {
        data: R;
        hidePopover: () => void;
        rowIndex: number;
        selection: NavigableSelection;
        row: WithBoundArgs<
          typeof SearchResultRow,
          'data' | 'selection' | 'rowIndex' | 'hidePopover'
        >;
      },
    ];
    footer: [{ hidePopover: () => void }];
  };
}

export default class Inbox2SearchDropdownComponent<R> extends Component<
  SearchDropdownSignature<R>
> {
  popover?: { hide: () => void };

  @tracked selection = useResource(this, NavigableSelection, () => ({
    items: this.args.results,
  }));

  keyboardNavigableItemsResource = useResource(this, KeyboardNavigableItemsResource, () => ({
    selection: this.selection,
    items: this.args.results,
    maxItemsPerRow: 1,
  }));

  get size() {
    return this.args.size ?? 'lg';
  }

  @action escape() {
    this.hidePopover();

    if (this.args.onEscape !== undefined) {
      this.args.onEscape();
    }
  }

  @action hidePopover() {
    this.popover?.hide();
  }

  @action onInput(event: Event & { target: HTMLInputElement }) {
    this.args.onQueryChanged(event.target.value);
  }

  @action maybeStopPropagation(event: Event) {
    if (this.args.stopPropagation) {
      event.stopPropagation();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::SearchDropdown': typeof Inbox2SearchDropdownComponent;
    'inbox2/common/search-dropdown': typeof Inbox2SearchDropdownComponent;
  }
}
