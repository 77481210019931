/* import __COLOCATED_TEMPLATE__ from './admin-note.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface AdminNoteSignature {
  Element: HTMLDivElement;
  Args: { teammate?: AdminSummary };
}

const AdminNote = templateOnlyComponent<AdminNoteSignature>();

export default AdminNote;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::TypingIndicators::AdminNote': typeof AdminNote;
  }
}
