/* import __COLOCATED_TEMPLATE__ from './related-content.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import {
  type FinSource,
  type InboxAssistantConversationPart,
} from 'embercom/objects/inbox/inbox-assistant-conversation';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { EntityType } from 'embercom/models/data/entity-types';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import type SidebarManager from 'embercom/services/sidebar-manager';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import {
  type CopilotChatMessageAnalyticsData,
  type NormalizedFinSource,
  type CopilotInteractionMetadata,
  CopilotSourceSection,
} from 'embercom/lib/inbox2/copilot/types';
import type ExperimentsApi from 'embercom/services/experiments-api';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { timeout } from 'ember-concurrency';
import { type TransitionContext } from 'ember-animated';

export interface RelatedContentArgs {
  message: InboxAssistantConversationPart;
  normalizedData: NormalizedFinSource[];
  instrumentRelatedContentPreviewCopy: () => void;
  instrumentRelatedContentClick: (
    source: { entity_id: number; entity_type: EntityType },
    message: InboxAssistantConversationPart,
  ) => void;
  openSourcePreview: (
    content: FinSource,
    analyticsData: CopilotChatMessageAnalyticsData,
    highlightedBlockIndices?: [number, number],
    articleVersionId?: string,
  ) => void;
  inbox: Inbox | { id: string; category: string };
  analyticsData: CopilotChatMessageAnalyticsData;
  noDirectAnswerFound?: boolean;
  answerIsStreaming: boolean;
  relatedContentIsStreaming: boolean;
  insertSourceBlocksIntoComposer: (
    source: NormalizedFinSource,
    message: InboxAssistantConversationPart,
  ) => void;
  hoveredSourceUniqueKey: string | null;
  isFirstFinMessage: boolean;
}

interface Signature {
  Element: Element;
  Args: RelatedContentArgs;
  Blocks: {
    default: [];
  };
}

export default class RelatedContent extends Component<Signature> {
  @service declare session: Session;
  @service declare sidebarManager: SidebarManager;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;
  @service declare experimentsApi: ExperimentsApi;

  @tracked showMore = false;

  readonly EntityType = EntityType;
  readonly CopilotSourceSection = CopilotSourceSection;

  relatedContentRevealAnimation = function* ({ insertedSprites }: TransitionContext) {
    for (let sprite of insertedSprites) {
      yield fadeOut(sprite, { duration: 0 });
    }
    for (let sprite of insertedSprites) {
      yield fadeIn(sprite, { duration: 0 });
      yield timeout(250);
    }
  };

  textCrossfadeTransition = function* ({
    duration,
    insertedSprites,
    removedSprites,
  }: TransitionContext) {
    for (let sprite of removedSprites) {
      yield fadeOut(sprite, { duration });
    }
    for (let sprite of insertedSprites) {
      yield fadeIn(sprite, { duration });
    }
  };

  @action generateRelatedContentKey(relatedContent: NormalizedFinSource): string {
    return `${relatedContent.entity_id}${relatedContent.entity_type}`;
  }

  @action toggleShowMore() {
    this.showMore = !this.showMore;
  }

  @action onLinkClick(
    content: FinSource,
    metadata: CopilotInteractionMetadata,
    event: PointerEvent,
  ) {
    if (metadata.openInSidebar) {
      event.preventDefault();
      this.args.openSourcePreview(
        content,
        this.args.analyticsData,
        metadata.highlightedBlockIndices,
        metadata.articleVersionId,
      );
    }

    this.args.instrumentRelatedContentClick(content, this.args.message);
  }

  @action instrumentRelatedContentCopy(
    content: { entity_id: number; entity_type: EntityType },
    metadata: CopilotInteractionMetadata,
  ) {
    let selection = document.getSelection()?.toString();
    // The 'copy' event seems to get triggred if you select, then unselect, then copy
    // So we need to ignore these events if there isn't any text currently selected
    if (selection) {
      this.intercomEventService.trackAnalyticsEvent({
        object: metadata.isFromRelatedContentSheet
          ? 'related_content_list_item'
          : 'related_content_preview',
        action: 'manually_copied',
        context: 'related_content',
        entity_id: content.entity_id,
        entity_type: content.entity_type,
        inbox_assistant_part_id: this.args.message.persistedId,
        text: selection,
        ...this.args.analyticsData,
      });
    }
  }

  @action onViewAllClick() {
    this.sidebarManager.openSheet({
      name: 'related-content',
      data: this.args.normalizedData,
      insertBlocks: this.args.insertSourceBlocksIntoComposer,
      message: this.args.message,
    });

    this.intercomEventService.trackAnalyticsEvent({
      object: 'related_content_see_all_button',
      action: 'clicked',
      context: 'related_content',
      inbox_assistant_part_id: this.args.message.persistedId,
      ...this.args.analyticsData,
    });
  }

  get uniqueSourceKeys() {
    if (!this.args.message.sources) {
      return [];
    }
    let sources = this.args.message.sources;
    let uniqueSourceKeys = sources.map(
      ({ entity_id, entity_type }) => `${entity_id}${entity_type}`,
    );
    return uniqueSourceKeys;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::RelatedContent': typeof RelatedContent;
    'inbox2/copilot/related-content': typeof RelatedContent;
  }
}
