/* import __COLOCATED_TEMPLATE__ from './side-conversation-started.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type SideConversationStarted from 'embercom/objects/inbox/renderable/side-conversation-started';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';

export interface SideConversationStartedPart extends RenderablePart {
  renderableData: SideConversationStarted;
}

interface Args {
  part: SideConversationStartedPart;
  conversationId: number;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsSideConversationStartedComponent extends Component<Signature> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;

  get firstParticipantEmail() {
    return this.args.part.renderableData.firstParticipantEmail;
  }

  get extraParticipantsCount() {
    return this.args.part.renderableData.participantsCount - 1;
  }

  get createdAt() {
    return this.args.part.createdAt;
  }

  get sideConversationId() {
    return this.args.part.renderableData.sideConversationId;
  }

  @action openSideConversation() {
    if (!this.sideConversationId) {
      return;
    }

    this.inboxSidebarService.previewSideConversation(
      this.sideConversationId,
      this.args.conversationId,
      true,
    );

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'side_conversation',
      section: 'respond',
      place: 'conversation_part',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::SideConversationStarted': typeof Inbox2ConversationStreamEventPartsSideConversationStartedComponent;
    'inbox2/conversation-stream/event-parts/side-conversation-started': typeof Inbox2ConversationStreamEventPartsSideConversationStartedComponent;
  }
}
