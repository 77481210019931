/* import __COLOCATED_TEMPLATE__ from './description.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';

interface Signature {
  Args: {
    conversationSummary: ConversationTableEntry;
  };
}

const Description = templateOnlyComponent<Signature>();
export default Description;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::Description': typeof Description;
    'inbox2/conversations-table/table-cells/description': typeof Description;
  }
}
