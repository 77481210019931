/* import __COLOCATED_TEMPLATE__ from './create-ticket-card.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type CreateTicketCard as TicketCardBlock } from '@intercom/interblocks.ts';

interface Signature {
  Args: {
    dataBlock: TicketCardBlock;
  };
}

const CreateTicketCard = templateOnlyComponent<Signature>();
export default CreateTicketCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::CreateTicketCard': typeof CreateTicketCard;
    'inbox2/conversation-stream/part-groups/create-ticket-card': typeof CreateTicketCard;
  }
}
