/* import __COLOCATED_TEMPLATE__ from './auto-refresh-indicator.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const AutoRefreshIndicator = templateOnlyComponent<Signature>();
export default AutoRefreshIndicator;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::AutoRefreshIndicator': typeof AutoRefreshIndicator;
    'inbox2/dashboard/auto-refresh-indicator': typeof AutoRefreshIndicator;
  }
}
