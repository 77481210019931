/* import __COLOCATED_TEMPLATE__ from './block-user-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const BlockUserModal = templateOnlyComponent<Signature>();
export default BlockUserModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::BlockUserModal': typeof BlockUserModal;
    'inbox2/conversation-details-sidebar/sidebar-sections/block-user-modal': typeof BlockUserModal;
  }
}
