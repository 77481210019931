/* import __COLOCATED_TEMPLATE__ from './content-part.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type TaggablePart from 'embercom/objects/inbox/taggable-part';
import { isTaggable } from 'embercom/objects/inbox/taggable-part';
import type Tag from 'embercom/objects/inbox/tag';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import type Conversation from 'embercom/objects/inbox/conversation';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { taskFor } from 'ember-concurrency-ts';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import { PartGroupCategory } from 'embercom/objects/inbox/conversation-stream/part-group';
import type Session from 'embercom/services/session';
import type TracingService from 'embercom/services/tracing';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

import type ConversationUpdates from 'embercom/services/conversation-updates';
interface Args {
  classStyling?: string;
  conversation?: Conversation;
  part: RenderablePart;
  index: number;
  partGroup: PartGroup;
  partWidth: number | null;
  isReadOnly: boolean;
  fullWidth?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    content: [];
    channel?: [];
    seenState?: [];
    sendState?: [];
    timestamp?: [];
  };
}

export default class Inbox2ConversationStreamContentPartComponent extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare tracing: TracingService;
  @service declare intercomEventService: any;
  @service declare session: Session;
  @service declare conversationUpdates: ConversationUpdates;
  @tracked isHovered = false;
  @tracked isSending = false;

  partGroupCategories = PartGroupCategory;

  get isSideConversation() {
    return this.args.conversation?.isSideConversation;
  }

  get classStyling() {
    let styles =
      this.args.classStyling || 'relative inbox2__renderable-part inbox2__break-words py-3 px-4';
    return `${this.bubbleRounding} ${styles}`;
  }

  get bubbleRounding(): string {
    let bubbleRoundings = this.args.partGroup.isUser ? ['rounded-r-3xl'] : ['rounded-l-3xl'];
    if (this.args.partGroup.parts.length === 1) {
      bubbleRoundings.push(this.args.partGroup.isUser ? 'rounded-l-3xl' : 'rounded-r-3xl');
    } else if (this.args.index + 1 === this.args.partGroup.parts.length) {
      bubbleRoundings.push(this.args.partGroup.isUser ? 'rounded-bl-3xl' : 'rounded-br-3xl');
    } else if (this.args.index === 0) {
      bubbleRoundings.push(this.args.partGroup.isUser ? 'rounded-tl-3xl' : 'rounded-tr-3xl');
    }
    return bubbleRoundings.join(' ');
  }

  get isTaggablePart() {
    return isTaggable(this.args.part);
  }

  get permalinkId() {
    if (this.args.conversation) {
      return this.args.part.generatePermalinkId(this.args.conversation.id);
    }
    return '';
  }

  get isNote() {
    return this.args.part.renderableType === RenderableType.AdminNote;
  }

  get isPhoneCall() {
    return this.args.part.isPhoneCallPart;
  }

  get conversationPartBackgroundStyles() {
    let renderablePart = this.args.part.renderableData;
    let hoverStyles = !this.failedToSend && renderablePart.styles?.backgroundColorHover;

    if (this.isHovered && hoverStyles) {
      return hoverStyles;
    }

    return renderablePart.styles?.backgroundColor;
  }

  get existingUpdate() {
    return (
      this.args.conversation &&
      this.conversationUpdates.existingUpdate(this.args.part, this.args.conversation)
    );
  }

  get failedToSend() {
    return this.existingUpdate?.isFailed;
  }

  get isEmail() {
    return (
      this.args.partGroup.category === this.partGroupCategories.TeammateEmailReply ||
      this.args.partGroup.category === this.partGroupCategories.BotEmailReply
    );
  }

  get isUserContent() {
    return this.args.partGroup.category === this.partGroupCategories.UserContent;
  }

  @action removeTag(tagToRemove: Tag) {
    if (this.args.conversation && this.isTaggablePart) {
      let renderablePart = this.args.part as TaggablePart;
      let tagsWithoutRemovedTag = renderablePart.renderableData.tags.reject(
        (tag) => tag === tagToRemove,
      );
      taskFor(this.inboxState.updateTags).perform(
        this.args.conversation,
        renderablePart,
        tagsWithoutRemovedTag,
      );
      this.trackTagRemovedEvent(this.args.conversation?.id);
    }
  }

  private trackTagRemovedEvent(conversationId: number) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'tag_removed',
      object: 'conversation_part',
      place: 'inbox',
      inbox_type: this.inboxState.activeInbox?.type,
      conversation_id: conversationId,
    });
  }

  @action instrumentImages(element: HTMLElement) {
    let images = element.getElementsByTagName('img');

    for (let i = 0; i < images.length; i++) {
      this.tracing.trackImageLoad(images[i]);
    }
  }

  get sanitizedPartWidthAttribute() {
    if (this.args.partWidth && !this.isSideConversation) {
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      return sanitizeHtml(`width: ${this.args.partWidth}px`);
    }
  }

  @action async retrySendMessage() {
    let { conversation, part } = this.args;

    if (!conversation) {
      return;
    }

    if (this.existingUpdate) {
      this.conversationUpdates.rollbackUpdates(conversation.id, [this.existingUpdate]);
    }

    this.isSending = true;

    try {
      await this.inboxState.retrySendReplyOrNote({
        conversation,
        part,
        isNote: this.isNote,
      });
    } finally {
      this.isSending = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::ContentPart': typeof Inbox2ConversationStreamContentPartComponent;
    'inbox2/conversation-stream/content-part': typeof Inbox2ConversationStreamContentPartComponent;
  }
}
