/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  icon: InterfaceIconName;
  header: string;
  linkText: string;
  subText: string;
  onClick?: () => void;
}

interface Signature {
  Args: Args;
}

export default class EmptyState extends Component<Signature> {
  @service declare session: Session;
  @service declare router: Router;

  @action onClick() {
    if (this.args.onClick) {
      this.args.onClick();
    } else {
      let url = this.router.urlFor('apps.app.settings.helpdesk.tickets', this.session.workspace, {
        queryParams: { tab: 'ticket-types' },
      });
      safeWindowOpen(url);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Ticket::EmptyState': typeof EmptyState;
    'inbox2/command-k/ticket/empty-state': typeof EmptyState;
  }
}
