/* import __COLOCATED_TEMPLATE__ from './preview-pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { useFunction } from 'ember-resources';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { type Article, type LocaleDetail } from 'embercom/objects/inbox/article';
import { type CommandKMetadata } from 'embercom/services/command-k';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { type Block, type Heading } from '@intercom/interblocks.ts';
import type ArticlesApi from 'embercom/services/articles-api';
import type Session from 'embercom/services/session';
import type NavigableSelection from '../../common/navigable-selection-resource';

interface Args {
  item: ChildAction;
  setMetadata: (metadata: CommandKMetadata) => unknown;
  selection: NavigableSelection;
}

interface Signature {
  Args: Args;
}

type ArticleLoaderResponse = { id: string; article: Article };

type HeadingLink = {
  id: string | null;
  name: string;
};

function isHeadingBlock(block: Block): block is Heading {
  return (
    block.type === 'heading' ||
    block.type === 'subheading' ||
    block.type === 'subheading3' ||
    block.type === 'subheading4'
  );
}

export default class PreviewPane extends Component<Signature> {
  @service declare articlesApi: ArticlesApi;
  @service declare session: Session;

  @tracked isLoadingArticle = false;
  @tracked locale = 'en';
  @tracked heading: HeadingLink | null = null;

  async loadDefaultLocale() {
    if (!this.session.workspace.helpCenterLocale) {
      await this.session.workspace.fetchHelpCenterLocale();
    }
  }

  get article() {
    return this.articleLoader.value?.article;
  }

  get localeContent() {
    return this.article?.contents.find((content) => content.locale.id === this.locale);
  }

  get availableLocales() {
    return this.article?.contents.map((content) => content.locale) ?? [];
  }

  get selectedHeading() {
    return this.headingLinks.find((link) => link.id === this.heading);
  }

  get headingLinks() {
    let defaultItem = {
      id: null,
      name: 'From the start',
    };

    return [defaultItem, ...this.articleHeadings];
  }

  @action
  onSelectLocale(locale: LocaleDetail) {
    this.locale = locale.id;

    if (this.heading) {
      this.args.setMetadata({
        locale: locale.id,
        heading: { id: this.heading.id, title: this.heading.name },
      });
    } else {
      this.args.setMetadata({ locale: locale.id });
    }
  }

  @action
  onSelectHeading(heading: HeadingLink) {
    document.querySelector(`#${heading.id}`)?.scrollIntoView({ behavior: 'smooth' });

    this.heading = heading;
    this.args.setMetadata({
      locale: this.locale,
      heading: { id: heading.id, title: heading.name },
    });
  }

  private get headingBlocks() {
    if (!this.localeContent) {
      return [];
    }

    return this.localeContent.blocks.filter(isHeadingBlock);
  }

  private get articleHeadings() {
    return this.headingBlocks.map((block) => {
      return {
        name: htmlToTextContent(block.text),
        id: block.idAttribute,
      };
    });
  }

  localeFromAvailable(article: Article) {
    let availableLocales = article.contents.map((content) => content.locale) ?? [];
    let locale = 'en';
    if (availableLocales.find((locale) => locale.id === this.session.workspace.helpCenterLocale)) {
      locale = this.session.workspace.helpCenterLocale as string;
    } else if (availableLocales.find((locale) => locale.id === 'en')) {
      locale = 'en';
    } else if (availableLocales.length) {
      locale = availableLocales[0].id;
    }

    return { id: locale, name: '' } as LocaleDetail;
  }

  private articleLoader = useFunction<ArticleLoaderResponse>(
    this,
    async (previous: ArticleLoaderResponse, id: string) => {
      await this.loadDefaultLocale();

      if (previous && id === previous.id) {
        return previous;
      }

      try {
        this.isLoadingArticle = true;
        let articleLoaderResponse: ArticleLoaderResponse = await this.articlesApi.fetchArticle(id);
        this.onSelectLocale(this.localeFromAvailable(articleLoaderResponse.article));

        return articleLoaderResponse;
      } finally {
        this.isLoadingArticle = false;
      }
    },
    () => [this.args.item.value],
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::InsertArticle::PreviewPane': typeof PreviewPane;
    'inbox2/command-k/insert-article/preview-pane': typeof PreviewPane;
  }
}
