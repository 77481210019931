/* import __COLOCATED_TEMPLATE__ from './deleted-message-timestamp.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  deletedBySummary: AdminSummary;
  deletionTimestamp: string;
}

interface Signature {
  Args: Args;
}

const DeletedMessageTimestamp = templateOnlyComponent<Signature>();
export default DeletedMessageTimestamp;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::DeletedMessageTimestamp': typeof DeletedMessageTimestamp;
    'inbox2/conversation-stream/part-groups/deleted-message-timestamp': typeof DeletedMessageTimestamp;
  }
}
