/* import __COLOCATED_TEMPLATE__ from './table-header-column-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import {
  type ColumnDefinition,
  type ColumnGroup,
} from 'embercom/objects/inbox/conversations-table-column';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

type Column = ColumnDefinition | ColumnGroup;

interface Args {
  hiddenColumns: Array<Column>;
  showColumn: (column: ColumnDefinition) => void;
  showColumns: (columns: ColumnDefinition[]) => void;
}

interface Signature {
  Args: Args;
}

export default class TableHeaderColumnSelector extends Component<Signature> {
  @service declare session: Session;

  @tracked selectedValues: ColumnDefinition[] = [];

  get hiddenColumns() {
    return this.args.hiddenColumns.map((columnGroup: ColumnGroup) => {
      return {
        heading: columnGroup.heading,
        items: columnGroup.columns.map((column) => {
          return {
            text: column.label,
            icon: column.icon,
            value: column,
          };
        }),
      };
    });
  }

  @action onSelectionChange(selectedValues: ColumnDefinition[]) {
    this.selectedValues = selectedValues;
  }

  @action onClose() {
    this.args.showColumns(this.selectedValues);
    this.selectedValues = [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableHeaderColumnSelector': typeof TableHeaderColumnSelector;
    'inbox2/conversations-table/table-header-column-selector': typeof TableHeaderColumnSelector;
  }
}
