/* import __COLOCATED_TEMPLATE__ from './conversation-details-tab.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { inject as service } from '@ember/service';
import type InboxApi from 'embercom/services/inbox-api';
import type Snackbar from 'embercom/services/snackbar';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import { type TicketType } from 'embercom/objects/inbox/ticket';
import { TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import { ConversationAttributesResource } from 'embercom/components/inbox2/conversation-attributes-resource';
import { useResource } from 'ember-resources';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import SidebarSection from 'embercom/objects/inbox/sidebar-section';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type InboxState from 'embercom/services/inbox-state';

enum SupportedSidebarSection {
  LinkedTickets = 'linked-tickets',
  LinkedGithubIssues = 'linked-github-issues',
  TicketDetails = 'ticket-details',
  ConversationDetails = 'conversation-details',
}

interface Args {
  conversation: Conversation;
  setTicketTypeToAddToConversation: (ticketTypeId?: number) => void;
  viewMode: 'stacked' | '2-column';
  expandedSection?: SupportedSidebarSection | number;
}

interface Signature {
  Args: Args;
}

export default class TicketAttributesTab extends Component<Signature> {
  supportedSidebarSections = SupportedSidebarSection;

  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare inboxState: InboxState;

  @tracked visibleToUser = false;
  @tracked labelRowSize = 'w-1/4';
  @tracked expandedSection?: SupportedSidebarSection | number = this.args.expandedSection;
  @tracked openSections: Array<SupportedSidebarSection | number> = this.args.conversation.isTicket
    ? [SupportedSidebarSection.TicketDetails]
    : [SupportedSidebarSection.ConversationDetails];

  readonly ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    ticketTypeId: this.args.conversation.ticketType?.id,
    conversation: this.args.conversation,
  }));

  readonly conversationAttributesResource = useResource(
    this,
    ConversationAttributesResource,
    () => ({
      conversation: this.args.conversation,
    }),
  );

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.expandedSection) {
      this.openSections = [...this.openSections, SupportedSidebarSection.LinkedGithubIssues];
    }
  }

  get ticketType(): TicketType | undefined {
    return this.args.conversation?.ticketType;
  }

  get isAddingTicketTypeToConversation(): boolean {
    return this.args.conversation.ticketType === undefined;
  }

  get linkedTicketsSection(): SidebarSection {
    return new SidebarSection({
      id: -1,
      name: 'Linked tickets',
      intercomModuleIdentifier: 'linked-tickets',
    });
  }

  get linkedConversationSection(): SidebarSection {
    return new SidebarSection({
      id: -1,
      name: this.intl.t('inbox.conversation-details-sidebar.section-headings.linked-conversation'),
      intercomModuleIdentifier: 'linked-conversation',
    });
  }

  get linkedGithubIssuesSection(): SidebarSection {
    return new SidebarSection({
      id: -1,
      name: 'Linked Github issues',
      intercomModuleIdentifier: 'linked-github-issues',
    });
  }

  get ticketDetailsSection(): SidebarSection {
    return new SidebarSection({
      id: -1,
      name: 'Ticket details',
      intercomModuleIdentifier: 'ticket-details',
    });
  }

  get conversationDetailsSection(): SidebarSection {
    return new SidebarSection({
      id: -1,
      name: 'Conversation details',
      intercomModuleIdentifier: 'conversation-details',
    });
  }

  @action onVisibilityChange(visibility_change: boolean) {
    this.visibleToUser = visibility_change;
  }

  @action
  cancelAddTicketToConversation() {
    this.args.setTicketTypeToAddToConversation(undefined);
  }

  @task({ drop: true })
  *updateAttribute(attribute: TicketAttributeSummary) {
    if (attribute.isUpdated) {
      yield this.inboxApi.updateAttribute(this.args.conversation.id, attribute);
    }
  }

  @action onOpenSectionChange(sectionId: SupportedSidebarSection | number) {
    this.expandedSection = undefined;
    return (openSection?: SupportedSidebarSection | number) => {
      this.expandedSection = undefined;

      if (openSection && !this.openSections.includes(openSection)) {
        this.openSections = [...this.openSections, sectionId];
      } else {
        this.openSections = this.openSections.reject(
          (openSectionId) => openSectionId === sectionId,
        );
      }
    };
  }

  @action onExpandedSectionChange(sectionId: SupportedSidebarSection | number | undefined) {
    this.expandedSection = sectionId;
  }

  @action onTeamUpdate(team: TeamSummary) {
    this.inboxState.assignConversationToTeam(this.args.conversation, team);
  }

  @action onAdminUpdate(admin: AdminWithPermissions) {
    this.inboxState.assignConversationToAdmin(this.args.conversation, admin);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsTab': typeof TicketAttributesTab;
    'inbox2/conversation-details-tab': typeof TicketAttributesTab;
  }
}
