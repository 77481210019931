/* import __COLOCATED_TEMPLATE__ from './linked-github-issues.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { action } from '@ember/object';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import type InboxApi from 'embercom/services/inbox-api';
import type GithubLinkSummary from 'embercom/objects/inbox/github-link-summary';
import type Session from 'embercom/services/session';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Args {
  conversation: Conversation;
  sidebarSection: SidebarSection;
  activeInbox?: Inbox;
  isTableView?: boolean;
}

interface Signature {
  Args: Args;
}

type GithubLink = GithubLinkSummary;

export default class LinkedTickets extends Component<Signature> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;
  @service declare inboxApi: InboxApi;
  @service declare session: Session;

  @tracked githubLinks: GithubLink[] = [];
  @tracked limitedGithubLinks: GithubLink[] = [];
  @tracked conversationId?: number;
  @tracked isLoadingGithubLinks = false;
  @tracked addingGithubLink = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.loadGithubLinks();
    this.setConversationId();
  }

  @action onConversationChange() {
    if (this.conversationId !== this.args.conversation.id) {
      this.loadGithubLinks();
    }
    this.setConversationId();
  }

  @action async handleConversationUpdatedEvent() {
    await this.loadGithubLinks();
  }

  @action
  async loadGithubLinks() {
    this.isLoadingGithubLinks = true;
    this.githubLinks = [];
    let { github_links } = await this.inboxApi.getGithubLinks(this.args.conversation.id);
    this.githubLinks = github_links;
    this.limitedGithubLinks = this.githubLinks.slice(0, 3);
    this.isLoadingGithubLinks = false;
  }

  setConversationId() {
    this.conversationId = this.args.conversation.id;
  }

  @action toggleGithubLinkInputOpened() {
    this.addingGithubLink = !this.addingGithubLink;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::LinkedGithubIssues': typeof LinkedTickets;
    'inbox2/conversation-details-sidebar/sidebar-sections/linked-github-issues': typeof LinkedTickets;
  }
}
