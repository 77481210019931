/* import __COLOCATED_TEMPLATE__ from './collapsible-content.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import storage from 'embercom/vendor/intercom/storage';
// @ts-ignore
import type InboxState from 'embercom/services/inbox-state';
import { InboxEvents } from 'embercom/services/inbox-state';
import type Session from 'embercom/services/session';

interface Args {
  ticketDescriptionHighlights?: string[];
  showTicketAttributesPanel?: boolean;
  isDescriptionEmpty?: boolean;
  isLoading: boolean;
  class?: string;
  isReadOnly?: boolean;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class CollapsibleContent extends Component<Signature> {
  @tracked collapsedDescription: boolean =
    storage.get('inbox-ticket-description-collapse-state') || false;
  @ref('collapse-description-button') collapseDescriptionButton!: HTMLElement;
  @service declare inboxState: InboxState;
  @service declare session: Session;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.isReadOnly) {
      this.collapsedDescription = true;
    } else if (this.hasTicketDescriptionMatches) {
      this.collapsedDescription = false;
    }
  }

  get showCollapseExpandButton() {
    if (this.args.isLoading) {
      return false;
    }
    if (this.args.showTicketAttributesPanel) {
      return true;
    }
    if (this.collapsedDescription) {
      return true;
    }
    if (this.args.isDescriptionEmpty) {
      return false;
    }
    return true;
  }

  get attributeLoadingPlaceholderWidths() {
    return ['w-20', 'w-18', 'w-20'];
  }

  @action
  handleButtonClickEvent() {
    this.collapseDescriptionButton.blur();
    this.collapsedDescription = !this.collapsedDescription;
    this.storeCollapsedDescriptionState(this.collapsedDescription);
    this.inboxState.trigger(InboxEvents.TicketMainPanelCollapsed, this.collapsedDescription);
  }

  @action
  handleBodyClickEvent(event: MouseEvent) {
    if (!this.collapsedDescription) {
      return;
    }

    this.handleButtonClickEvent();
    this.preventClickPropagation(event);
  }

  storeCollapsedDescriptionState(state: boolean) {
    if (!this.args.isReadOnly) {
      storage.set('inbox-ticket-description-collapse-state', state);
    }
  }

  preventClickPropagation(event: MouseEvent) {
    // @ts-ignore
    event.target?.querySelectorAll('input').forEach((input: any) => {
      input.blur();
    });
  }

  get hasTicketDescriptionMatches() {
    return (
      this.args.ticketDescriptionHighlights && this.args.ticketDescriptionHighlights?.length > 0
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::TicketPanel::CollapsibleContent': typeof CollapsibleContent;
    'inbox2/conversation-page/ticket-panel/collapsible-content': typeof CollapsibleContent;
  }
}
