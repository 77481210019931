/* import __COLOCATED_TEMPLATE__ from './recent-events.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Event from 'embercom/objects/inbox/event';
import type EventsApi from 'embercom/services/events-api';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { Resource } from 'ember-resources/core';
import { type Named } from 'ember-resources/core/types';

import { registerDestructor } from '@ember/destroyable';
import type ApplicationInstance from '@ember/application/instance';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';

type Args = SidebarSectionComponentArgs<{
  userId?: string;
  isPreviewingConversation?: boolean;
}>;

interface Signature {
  Args: Args;
}

interface ResourceArgs {
  userId?: string;
}

class RecentEventsResource extends Resource<Named<ResourceArgs>> {
  @service declare eventsApi: EventsApi;

  @tracked list: Event[] = [];
  @tracked hasLoadedAllEvents = false;

  private userId?: string;

  constructor(owner: ApplicationInstance) {
    super(owner);

    registerDestructor(this, () => {
      taskFor(this.loadEvents).cancelAll();
    });
  }

  modify(_: unknown[], args: ResourceArgs) {
    if (args.userId && args.userId !== this.userId) {
      this.userId = args.userId;
      this.list = [];
      this.hasLoadedAllEvents = false;
      taskFor(this.loadEvents).perform();
    }
  }

  get isLoading() {
    return taskFor(this.loadEvents).isRunning;
  }

  @action
  loadMore() {
    if (this.hasLoadedAllEvents) {
      return;
    }

    let last = this.list[this.list.length - 1];

    taskFor(this.loadEvents).perform({
      before: last.createdAt,
      timestampEventId: last.id,
    });
  }

  @task
  *loadEvents(opts?: { before: string; timestampEventId: string }): TaskGenerator<void> {
    if (!this.userId) {
      return;
    }

    let { events, pageSize } = yield this.eventsApi.fetchRecentEventsForUser(this.userId, opts);
    this.list = [...this.list, ...events].uniqBy('id');
    this.hasLoadedAllEvents = pageSize !== events.length;
  }
}

export default class RecentEventsComponent extends Component<Signature> {
  @tracked displayMetadata = false;

  recentEvents = RecentEventsResource.from(this, () => ({
    userId: this.args.userId,
  }));
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::RecentEvents': typeof RecentEventsComponent;
    'inbox2/conversation-details-sidebar/sidebar-sections/recent-events': typeof RecentEventsComponent;
  }
}
