/* import __COLOCATED_TEMPLATE__ from './call-timer.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';
import moment from 'moment-timezone';

interface Args {
  startTime?: number;
}

export default class CallTimer extends Component<Args> {
  @tracked timer = 0;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.calculateStartTime();
    let timer = setInterval(() => (this.timer += 1), 1000);

    registerDestructor(this, () => {
      clearInterval(timer);
    });
  }

  calculateStartTime() {
    if (this.args.startTime) {
      this.timer = Math.round(
        moment.duration(moment().valueOf() - this.args.startTime, 'milliseconds').asSeconds(),
      );
    } else {
      this.timer = 0;
    }
  }

  get formattedTime() {
    let minutes = Math.floor(this.timer / 60);
    let seconds = this.timer % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CallTimer': typeof CallTimer;
    'inbox2/call-timer': typeof CallTimer;
  }
}
