/* import __COLOCATED_TEMPLATE__ from './key-value-pair.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const KeyValuePair = templateOnlyComponent<Signature>();
export default KeyValuePair;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Shopify::KeyValuePair': typeof KeyValuePair;
    'inbox2/conversation-details-sidebar/sidebar-sections/shopify/key-value-pair': typeof KeyValuePair;
  }
}
