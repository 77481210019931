/* import __COLOCATED_TEMPLATE__ from './ticket-state.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type InboxState from 'embercom/services/inbox-state';
import { localisedCustomStateAdminLabel } from 'embercom/lib/inbox2/ticket-custom-states';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import type TicketStateService from 'embercom/services/ticket-state-service';

interface Signature {
  Args: {
    conversationEntry: ConversationTableEntry;
    onClick?: () => void;
  };
  Element: HTMLDivElement;
}

class TicketStateComponent extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;
  @service declare ticketStateService: TicketStateService;

  get currentTicketCustomState(): TicketCustomState {
    return this.ticketStateService.getTicketCustomStateById(
      this.args.conversationEntry.ticketCustomStateId,
    );
  }

  get isTicket(): boolean {
    return this.args.conversationEntry.isTicket;
  }

  get currentTicketState() {
    if (!this.args.conversationEntry || !this.currentTicketCustomState) {
      return undefined;
    }

    return this.currentTicketCustomState.systemState;
  }

  get displayText(): string {
    if (this.currentTicketCustomState) {
      return localisedCustomStateAdminLabel(this.currentTicketCustomState.adminLabel, this.intl);
    }
    let state = this.currentTicketState || TicketSystemState.Submitted;
    return this.intl.t(`inbox.ticket-state.${state}`);
  }
}

export default TicketStateComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::TicketState': typeof TicketStateComponent;
    'inbox2/conversations-table/table-cells/ticket-state': typeof TicketStateComponent;
  }
}
