/* import __COLOCATED_TEMPLATE__ from './view-card-skeleton.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const ViewCardSkeleton = templateOnlyComponent<Signature>();
export default ViewCardSkeleton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::SummaryCard::ViewCardSkeleton': typeof ViewCardSkeleton;
    'inbox2/dashboard/summary-card/view-card-skeleton': typeof ViewCardSkeleton;
  }
}
