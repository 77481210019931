/* import __COLOCATED_TEMPLATE__ from './infinite-scroll.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { run } from '@ember/runloop';

interface Args {
  onLoadMore?: () => void;
  isLoading: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

// how far away from the bottom should trigger a load
const OFFSET = '250px';

export default class InfiniteScrollComponent extends Component<Signature> {
  @tracked observer?: IntersectionObserver;

  @action setupObserver(element: HTMLElement) {
    if (this.args.onLoadMore) {
      let options = {
        root: element,
        rootMargin: OFFSET,
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            run(() => this.args.onLoadMore!());
          }
        });
      }, options);
    }
  }

  @action observe(element: HTMLElement) {
    this.observer?.observe(element);
  }

  @action stopObserving(element: HTMLElement) {
    this.observer?.unobserve(element);
  }

  willDestroy() {
    super.willDestroy();
    this.observer?.disconnect();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::InfiniteScroll': typeof InfiniteScrollComponent;
    'inbox2/common/infinite-scroll': typeof InfiniteScrollComponent;
  }
}
