/* import __COLOCATED_TEMPLATE__ from './ask-fin.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  onClick(): void;
}

interface Signature {
  Args: Args;
}

const AskFinButton = templateOnlyComponent<Signature>();
export default AskFinButton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Entry::AskFin': typeof AskFinButton;
    'inbox2/copilot/entry/ask-fin': typeof AskFinButton;
  }
}
