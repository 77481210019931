/* import __COLOCATED_TEMPLATE__ from './task-tickets.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import { action } from '@ember/object';
import type CommandKService from 'embercom/services/command-k';
import { type WithBoundArgs } from '@glint/template';
import type LinkedTicketV2 from './linked-ticket-v2';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Session from 'embercom/services/session';

interface Signature {
  Element: HTMLElement;
  Args: {
    tickets: LinkedTicketSummary[];
    previewOnly?: boolean;
    onCreateTaskTicket: (ticketTypeId: number) => void;
    parentConversation: Conversation;
  };
  Blocks: {
    ticketRow: [
      { ticket: LinkedTicketSummary; component: WithBoundArgs<typeof LinkedTicketV2, 'ticket'> },
    ];
  };
}

export default class TaskTickets extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare session: Session;
  @service declare intercomEventService: any;

  get canLinkTaskTicket() {
    return !this.args.previewOnly;
  }

  get previewingObject() {
    return this.args.parentConversation.isTicket ? 'ticket' : 'conversation';
  }

  get app() {
    return this.session.workspace;
  }

  get showMinimalTooltip() {
    return this.app?.hasBackOfficeTickets;
  }

  @action
  showLearnArticle(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'task_ticket_article_link',
      context: `inbox.tooltip.task_ticket_article_link`,
      place: 'inbox.tooltip',
      section: 'right_side_banner',
    });
    window.Intercom('showArticle', 8300297); // https://www.intercom.com/help/en/articles/8300297-how-to-create-a-back-office-ticket
  }

  @action onLinkTaskTicketClick() {
    this.commandK.registerAndShow({
      actionID: 'create-linked-ticket',
      context: {
        category: TicketCategory.Task,
      },
      onSelect: (action: any) => {
        this.args.onCreateTaskTicket(action.id);
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Links::TaskTickets': typeof TaskTickets;
    'inbox2/conversation-details-sidebar/sidebar-sections/links/task-tickets': typeof TaskTickets;
  }
}
