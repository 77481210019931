/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { includesTag } from 'embercom/objects/inbox/taggable-part';

interface Args {
  actionItem: ChildAction;
}

interface Signature {
  Args: Args;
}

export default class TagItemOptionComponent extends Component<Signature> {
  get isChecked(): boolean {
    if (!this.args.actionItem.parent) {
      return false;
    }

    let context = this.args.actionItem.parent.context;

    return includesTag(context.tags, this.args.actionItem.value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::TagItem::Option': typeof TagItemOptionComponent;
    'inbox2/command-k/tag-item/option': typeof TagItemOptionComponent;
  }
}
