/* import __COLOCATED_TEMPLATE__ from './image-preview-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type EmberKeyboardEvent, EscKeyPriority } from 'embercom/lib/inbox2/types';
import type Inbox2ImagePreviewService from 'embercom/services/inbox2-image-preview-service';

export default class ImagePreviewModal extends Component {
  @service declare inbox2ImagePreviewService: Inbox2ImagePreviewService;

  get imagePreviewUrl() {
    return this.inbox2ImagePreviewService.imagePreviewUrl;
  }

  get escPriority() {
    return EscKeyPriority.imagePreviewModal;
  }

  @action onClose(event?: KeyboardEvent, kev?: EmberKeyboardEvent) {
    event?.stopPropagation();
    kev?.stopPropagation();
    kev?.stopImmediatePropagation();

    this.inbox2ImagePreviewService.closeImagePreview();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::ImagePreviewModal': typeof ImagePreviewModal;
    'inbox2/conversation-stream/image-preview-modal': typeof ImagePreviewModal;
  }
}
