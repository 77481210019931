/* import __COLOCATED_TEMPLATE__ from './event-group-part-text.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { type TicketType } from 'embercom/objects/inbox/ticket';

interface Signature {
  Args: {
    part: RenderablePart;
    isTicket: boolean;
    ticketType?: TicketType;
    renderableType: typeof RenderableType;
    partGroup: PartGroup;
    isTicketStream?: boolean;
  };
}

const EventGroupPartText = templateOnlyComponent<Signature>();
export default EventGroupPartText;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::EventGroupPartText': typeof EventGroupPartText;
  }
}
