/* import __COLOCATED_TEMPLATE__ from './answer-content.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { EntityType } from 'embercom/models/data/entity-types';
import { type InboxAssistantConversationPart } from 'embercom/objects/inbox/inbox-assistant-conversation';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  type CopilotChatMessageAnalyticsData,
  type NormalizedFinSource,
} from 'embercom/lib/inbox2/copilot/types';
import { inject as service } from '@ember/service';
import { CopilotSourceSection } from 'embercom/lib/inbox2/copilot/types';
import { type PromptPrefix } from 'embercom/lib/open-ai-prompt';
import { MacroType } from 'embercom/objects/inbox/macro';
import type Session from 'embercom/services/session';
import type AiAssistSettings from 'embercom/services/ai-assist-settings';

export interface AnswerContentArgs {
  sourceDetails: NormalizedFinSource[];
  message: InboxAssistantConversationPart;
  analyticsData: CopilotChatMessageAnalyticsData;
  answerIsStreaming: boolean;
  onAddToComposerClick: () => unknown;
  insertSourceBlocksIntoComposer: (
    source: NormalizedFinSource,
    message: InboxAssistantConversationPart,
  ) => void;
  setHoveredSourceKey: (sourceKey: string | null) => void;
  onAddNoteToComposerClick: () => unknown;
  changeToneAndInsertBlocks: (promptPrefix: PromptPrefix) => void;
  shouldRenderFirstAnswerTooltip: boolean;
}

interface Signature {
  Element: Element;
  Args: AnswerContentArgs;
  Blocks: {
    default: [];
  };
}

export default class AnswerContent extends Component<Signature> {
  @service declare aiAssistSettings: AiAssistSettings;
  @service declare intercomEventService: any;
  @service declare savedReplyInsertionsService: $TSFixMe;
  @service declare session: Session;

  @tracked previewingSource?: {
    source?: NormalizedFinSource;
    element?: HTMLElement;
  };
  @tracked inlineHelperPopoverElement?: HTMLElement;
  @tracked showCreateMacroModal = false;

  macroTypeReply: MacroType = MacroType.Reply;

  readonly EntityType = EntityType;
  readonly CopilotSourceSection = CopilotSourceSection;

  get saveAsMacroAnalyticsData() {
    return {
      ...this.args.analyticsData,
      inbox_assistant_part_id: this.args.message.id,
    };
  }

  get hasAttachments() {
    return this.args.message.content.some((block) => block.type === 'attachmentList');
  }

  @action onAnswerContentRender(firstCitationRect: DOMRect, answerCitationRect: DOMRect): void {
    let inlineHelperRect = {
      getBoundingClientRect: () => new DOMRect(answerCitationRect.x, firstCitationRect.y, 1, 1),
    } as HTMLElement;

    this.inlineHelperPopoverElement = inlineHelperRect;
  }

  @action async onInsertMacroAnswer() {
    if (!this.args.message.isMacroAnswer) {
      return;
    }

    await this.savedReplyInsertionsService.recordInsertion(
      this.session.workspace,
      this.session.teammate,
      { id: this.args.message.metadata?.macro_id },
      this.args.analyticsData.conversation_id, // Not the cleanest, but given we are already passing this metadata in, it's perhaps the easier way to record macro insertions.
    );
  }

  @action onSourceClick(
    entityId: number,
    entityType: EntityType,
    passageId: string,
    event: PointerEvent,
  ) {
    let source = this.args.sourceDetails.find(
      (s) => s.entity_id === entityId && s.entity_type === entityType,
    );

    if (!source) {
      throw new Error('Source not found');
    }
    if (passageId && source.preview && source.preview.passage_ids) {
      let passageIds = source.preview.passage_ids;
      let passageIndex = passageIds?.indexOf(passageId.toString());
      let highlightedBlockIndices = source.preview.blocks_indices?.[passageIndex];
      let articleVersionId = source.preview.version_id;
      source.onClickLink(
        { openInSidebar: true, isInlineSource: true, highlightedBlockIndices, articleVersionId },
        event,
      );
    } else {
      source.onClickLink({ openInSidebar: true, isInlineSource: true }, event);
    }
  }

  @action onSourceHover(
    entityId: number,
    entityType: EntityType,
    event: PointerEvent,
    element: HTMLElement,
  ): void {
    let source = this.args.sourceDetails.find(
      (s) => s.entity_id === entityId && s.entity_type === entityType,
    );
    if (!source) {
      throw new Error('Source not found');
    }

    event.preventDefault();
    event.stopPropagation();
    this.previewingSource = { source, element };

    this.args.setHoveredSourceKey(`${source.entity_id}${source.entity_type}`);
  }

  @action onSourceDismissHover(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
    //If we set the previewingSource to undefined it instantly removes the popover
    //Just removing the element will keep the source rendered as long as the user is hovering over it
    this.previewingSource = {
      source: this.previewingSource?.source,
    };
    this.args.setHoveredSourceKey(null);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::MessageStates::AnswerState::AnswerContent': typeof AnswerContent;
    'inbox2/copilot/message-states/answer-state/answer-content': typeof AnswerContent;
  }
}
