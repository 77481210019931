/* import __COLOCATED_TEMPLATE__ from './bulk-action-controls.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type CommandKService from 'embercom/services/command-k';
import type InboxState from 'embercom/services/inbox-state';
import { type UpdateTicketStateCommandKContext } from 'embercom/objects/inbox/command-k/update-ticket-state';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { perform } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import type InboxApi from 'embercom/services/inbox-api';
import PredicateGroup, { type Predicate } from 'embercom/objects/inbox/search/predicate-group';
import { buildUpdateTicketStateCommandKContext } from 'embercom/lib/inbox2/ticket-custom-states';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';

export interface BulkActionControlsArgs {
  narrow: boolean;
  conversationIds: number[];
  selectedCount: number;
  totalCount: number;
  onToggleAll: () => void;
  canShowCommandKActions: boolean;
  canBulkAssign: boolean;
  canBulkReopen: boolean;
  canUpdateTicketState: boolean;
  canBulkPrioritise: boolean;
  isLinkedConversationsView?: boolean;

  // todo: these should be bound correctly with args with the table view component
  onBulkAssign: (opts: {}) => void;
  onBulkEdit: () => void;
  onBulkReopen: () => void;
  onBulkClose: () => void;
  onDisplayActions: () => void;
  onUpdateTicketState?: (state: TicketCustomState['id']) => void;
  onTogglePriority?: () => void;
  onSelectTrackerTicket: (conversation: ConversationTableEntry) => void;
}

interface Signature {
  Element: HTMLDivElement;
  Blocks: {
    header: [
      {
        selectedCount: number;
        totalCount: number;
        onToggleAll: () => void;
      },
    ];
  };
  Args: BulkActionControlsArgs;
}

export default class BulkActionControls extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare commandK: CommandKService;
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare ticketStateService: TicketStateService;
  @service declare inboxApi: InboxApi;

  @tracked bulkAssignSource?: string;
  @tracked hasTrackerTicket?: boolean;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    perform(this.fetchTrackerTicket);
  }

  get updateTicketStateContext(): UpdateTicketStateCommandKContext {
    return buildUpdateTicketStateCommandKContext({
      customStates: this.ticketStateService.liveTicketCustomStates,
      allowClosedState: false,
      intl: this.intl,
    });
  }

  get trackerTicketSearchQuery() {
    let predicates: Predicate[] = [
      {
        identifier: 'ticket-category',
        type: 'string',
        attribute: 'ticket_category',
        comparison: 'eq',
        value: 'tracker',
      },
      {
        identifier: 'ticket-type',
        type: 'integer',
        attribute: 'ticket_type_id',
        comparison: 'known',
        value: null,
      },
    ];

    let predicatesGroup = new PredicateGroup(predicates);

    return {
      query: '',
      sortParams: {
        sort_field: 'sorting_updated_at',
        sort_direction: 'desc',
      },
      count: 1,
      predicates: { predicates: predicatesGroup.predicates },
      fields: [],
    };
  }

  get canBulkLinkToTracker() {
    return (
      this.session.workspace.isFeatureEnabled('bulk-linking-to-tracker-tickets') &&
      this.hasTrackerTicket &&
      !this.args.isLinkedConversationsView
    );
  }

  @action onDisplayBulkAssign(opts: { source: string }) {
    this.bulkAssignSource = opts.source;
    this.commandK.findAndShow('assign-to', () => (this.bulkAssignSource = undefined), opts);
  }

  @task({ restartable: true })
  *fetchTrackerTicket() {
    let { conversations } = yield this.inboxApi.searchForConversationsTableV2(
      this.trackerTicketSearchQuery,
    );
    this.hasTrackerTicket = !!conversations.length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::BulkActionControls': typeof BulkActionControls;
    'inbox2/common/bulk-action-controls': typeof BulkActionControls;
  }
}
