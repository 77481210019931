/* import __COLOCATED_TEMPLATE__ from './audio-player.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const AudioPlayer = templateOnlyComponent<Signature>();
export default AudioPlayer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::Attachments::AudioPlayer': typeof AudioPlayer;
    'inbox2/conversation-stream/part-groups/attachments/audio-player': typeof AudioPlayer;
  }
}
