/* import __COLOCATED_TEMPLATE__ from './team-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';

export default class TeamSelector extends Component {
  readonly teamEntity = SearchableType.Team;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::QuickSearch::TeamSelector': typeof TeamSelector;
    'inbox2/common/quick-search/team-selector': typeof TeamSelector;
  }
}
