/* import __COLOCATED_TEMPLATE__ from './overflow-menu-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { type CopilotSourceTypes } from 'embercom/lib/inbox2/copilot/types';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import { EntityType } from 'embercom/models/data/entity-types';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import type RouterService from '@ember/routing/router-service';
import { objectIcons } from 'embercom/models/data/matching-system/matching-constants';

interface Args {
  selectedSourceTypes: CopilotSourceTypes[];
  availableSourceTypes: CopilotSourceTypes[];
  onSelectSourceType: (selection: CopilotSourceTypes[]) => void;
  disableExcerptsWithSettingsTooltip: boolean;
  disableMacrosWithSettingsTooltip: boolean;
}

interface Signature {
  Element: Element;
  Args: Args;
  Blocks: {
    default: [];
  };
}

interface ColumnOptions {
  text: string;
  value: number;
  isSelected: boolean;
  icon: string;
  isDisabled: boolean;
  tooltip: { text?: string; isInteractive: boolean; isDelayed?: boolean };
}

export default class OverflowMenuTooltip extends Component<Signature> {
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare session: Session;

  get areAllSourceTypesSelected(): boolean {
    return this.args.selectedSourceTypes.length === this.enabledSourceTypes.length;
  }

  get enabledSourceTypes(): CopilotSourceTypes[] {
    let enabledSourceTypes = this.args.availableSourceTypes;

    if (this.args.disableExcerptsWithSettingsTooltip) {
      enabledSourceTypes = enabledSourceTypes.filter(
        (type) => type !== EntityType.ConversationExcerpt,
      );
    }
    if (this.args.disableMacrosWithSettingsTooltip) {
      enabledSourceTypes = enabledSourceTypes.filter((type) => type !== EntityType.SavedReply);
    }
    return enabledSourceTypes;
  }

  getEntityMapping(entityId: CopilotSourceTypes) {
    let mapping = {
      [EntityType.ArticleContent]: {
        entityName: this.intl.t(
          'inbox.ask-fin.overflow-menu-tooltip.source-filters.article-content',
        ),
        icon: 'article',
      },
      [EntityType.ContentSnippet]: {
        entityName: this.intl.t(
          'inbox.ask-fin.overflow-menu-tooltip.source-filters.content-snippet',
        ),
        icon: 'note',
      },
      [EntityType.ConversationExcerpt]: {
        entityName: this.intl.t(
          'inbox.ask-fin.overflow-menu-tooltip.source-filters.conversation-excerpt',
        ),
        icon: 'conversation',
      },
      [EntityType.InternalArticle]: {
        entityName: this.intl.t(
          'inbox.ask-fin.overflow-menu-tooltip.source-filters.internal-article',
        ),
        icon: 'locked',
      },
      [EntityType.FileSourceContent]: {
        entityName: this.intl.t('inbox.ask-fin.overflow-menu-tooltip.source-filters.file'),
        icon: objectIcons[EntityType.FileSourceContent],
      },
      [EntityType.ExternalContent]: {
        entityName: this.intl.t(
          'inbox.ask-fin.overflow-menu-tooltip.source-filters.external-content',
        ),
        icon: objectIcons[EntityType.ExternalContent],
      },
      [EntityType.SavedReply]: {
        entityName: this.intl.t('inbox.ask-fin.overflow-menu-tooltip.source-filters.saved-reply'),
        icon: 'saved-reply',
      },
    };
    return mapping[entityId];
  }

  sourceFilterTooltipText(entityId: CopilotSourceTypes): string | undefined {
    if (
      entityId === EntityType.ConversationExcerpt &&
      this.args.disableExcerptsWithSettingsTooltip
    ) {
      return this.disabledTooltipText('excerpts', false, true);
    }

    if (entityId === EntityType.SavedReply && this.args.disableMacrosWithSettingsTooltip) {
      return this.disabledTooltipText('macros', true, false);
    }

    return undefined;
  }

  disabledTooltipText(
    type: 'excerpts' | 'macros',
    manageMacros: boolean,
    manageConversations: boolean,
  ): string {
    return this.intl.t(
      `inbox.ask-fin.overflow-menu-tooltip.source-filters.${type}-settings-tooltip`,
      {
        url: this.router.urlFor('apps.app.knowledge-hub.overview', this.session.workspace.id, {
          queryParams: { tab: 'ai-copilot', manageConversations, manageMacros },
        }),
        htmlSafe: true,
      },
    );
  }

  get sourceFilterOptions(): {
    heading: string;
    items: ColumnOptions[];
  }[] {
    let options: ColumnOptions[] = [];
    this.args.availableSourceTypes.forEach((entityId) => {
      let { entityName, icon } = this.getEntityMapping(entityId);
      let isSelected = this.args.selectedSourceTypes.includes(entityId);
      options.push({
        text: entityName,
        value: entityId,
        isSelected,
        isDisabled:
          // disable deselecting every source
          (isSelected && this.args.selectedSourceTypes.length === 1) ||
          // disable disabled sources
          !this.enabledSourceTypes.includes(entityId),
        icon,
        tooltip: {
          isInteractive: true,
          isDelayed: false,
          text: this.sourceFilterTooltipText(entityId),
        },
      });
    });

    return [
      {
        heading: this.intl.t('inbox.ask-fin.overflow-menu-tooltip.source-filters.heading'),
        items: options,
      },
    ];
  }

  @action instrumentFilterOpened() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'overflow_menu_tooltip',
      action: 'opened',
      selected_source_types: this.args.selectedSourceTypes.join(','),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Composer::OverflowMenuTooltip': typeof OverflowMenuTooltip;
    'inbox2/copilot/composer/overflow-menu-tooltip': typeof OverflowMenuTooltip;
  }
}
