/* import __COLOCATED_TEMPLATE__ from './users-companies-link.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { USER_ROLE_PREDICATE } from 'embercom/lib/default-predicates';
import type CompanySummary from 'embercom/objects/inbox/company-summary';
import type Company from 'embercom/objects/inbox/company';
import { type User } from '@sentry/browser';

interface Args {
  user: User;
  hideIcon?: boolean;
  hideAt?: boolean;
}

interface Signature {
  Args: Args;
}

export default class UsersCompaniesLink extends Component<Signature> {
  @service declare session: Session;

  get companiesListPredicates() {
    let companyPredicates: {
      attribute: string;
      type: string;
      comparison: string;
      value: any;
    }[] = [];

    if (this.args.user.companies) {
      this.args.user.companies.forEach((company: CompanySummary | Company) => {
        if (company.remoteCompanyId) {
          companyPredicates.push({
            attribute: 'company.remote_company_id',
            type: 'string',
            comparison: 'eq',
            value: company.remoteCompanyId,
          });
        }
      });
    }

    let predicates = {
      type: 'or',
      predicates: companyPredicates,
    };

    let encodedPredicates = B64FilterParamEncoder.b64Encode(
      JSON.stringify({ predicates: [USER_ROLE_PREDICATE, predicates] }),
    );
    return `all:${encodedPredicates}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::UsersCompaniesLink': typeof UsersCompaniesLink;
    'inbox2/common/users-companies-link': typeof UsersCompaniesLink;
  }
}
