/* import __COLOCATED_TEMPLATE__ from './relevant-part-ticket-title.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';

import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type Block } from '@intercom/interblocks.ts';

interface Signature {
  Args: {
    relevantConversationEntry?: ConversationTableEntry;
  };
  Element: HTMLElement;
}

export default class RelevantPartTicketTitle extends Component<Signature> {
  @service declare intl: IntlService;

  get ticketTitleBlock(): Block[] | undefined {
    if (this.args.relevantConversationEntry?.ticketTitle) {
      return [
        {
          type: 'paragraph',
          text: this.args.relevantConversationEntry.ticketTitle,
        },
      ];
    }
    return;
  }

  get ticketTitleHighlights() {
    return this.args.relevantConversationEntry?.highlights?.ticketTitle ?? [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::RelevantPartTicketTitle': typeof RelevantPartTicketTitle;
  }
}
