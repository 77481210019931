/* import __COLOCATED_TEMPLATE__ from './products.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { isEmpty } from '@ember/utils';
import type Session from 'embercom/services/session';
import { type TaskGenerator } from 'ember-concurrency';
import { type ProductVariantHash, type SearchProductsResponse } from './create-order-modal';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';

export default class Inbox2Products extends Component {
  @service declare session: Session;

  @tracked products: ProductVariantHash[] = [];
  @tracked productSearchQuery = '';
  @tracked lastProductSearchQuery: string | null = null;

  get noResultsFound() {
    return (
      isEmpty(this.products) &&
      !isEmpty(this.productSearchQuery) &&
      this.lastProductSearchQuery === this.productSearchQuery
    );
  }

  @action
  focusOnProductSearchInput() {
    let productSearchInput = document.querySelector(
      '.js__shopify-product-search-input',
    ) as HTMLElement;
    productSearchInput.focus();
  }

  @task({ restartable: true })
  *searchShopifyForProduct(): TaskGenerator<void> {
    this.products = [];

    if (!this.productSearchQuery) {
      return;
    }

    this.lastProductSearchQuery = this.productSearchQuery;
    try {
      let productResponse = yield ajax({
        url: '/ember/inbox/shopify/search_products',
        type: 'GET',
        data: {
          app_id: this.session.workspace.id,
          query: this.productSearchQuery,
        },
      }) as SearchProductsResponse;

      this.products = productResponse.products;
    } catch (error) {
      captureException(error);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Shopify::Products': typeof Inbox2Products;
    'inbox2/conversation-details-sidebar/sidebar-sections/shopify/products': typeof Inbox2Products;
  }
}
