/* import __COLOCATED_TEMPLATE__ from './pill.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type User from 'embercom/objects/inbox/user';

interface Signature {
  Args: {
    count: number;
    potentialDuplicates: User[];
    moreExist: boolean;
    onClick: () => void;
  };
}

const Pill = templateOnlyComponent<Signature>();
export default Pill;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::PotentialDuplicates::Pill': typeof Pill;
    'inbox2/conversation-details-sidebar/sidebar-sections/potential-duplicates/pill': typeof Pill;
  }
}
