/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    onClose: () => void;
  };
}

const DeleteOutboundInitialPartModal = templateOnlyComponent<Signature>();
export default DeleteOutboundInitialPartModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::DeleteOutboundInitialPartModal': typeof DeleteOutboundInitialPartModal;
    'inbox2/conversation-stream/part-groups/delete-initial-part-modal': typeof DeleteOutboundInitialPartModal;
  }
}
