/* import __COLOCATED_TEMPLATE__ from './channels-cell.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { ChannelAvailabilityCode } from 'embercom/services/inbox2-teammate-activity-service';

import type IntlService from 'embercom/services/intl';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type Session from 'embercom/services/session';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type AdminAwayService from 'embercom/services/admin-away-service';

interface Args {
  admin: AdminWithPermissions;
  channelAvailabilityCode: ChannelAvailabilityCode;
}

interface Signature {
  Args: Args;
}

export default class ChannelsCell extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare adminAwayService: AdminAwayService;

  @tracked currentChannelAvailability: ChannelAvailabilityCode = this.args.channelAvailabilityCode;

  get channelText() {
    return this.intl.t(
      `inbox.dashboard.teammate-activity.table.channel-availability.${this.currentChannelAvailability}`,
    );
  }

  get currentAdminCanChangeChannelAvailability() {
    return this.session.teammate.permissions.canChangeAwayMode;
  }

  get channelAvailabilityOptions() {
    return [
      {
        text: this.intl.t('inbox.dashboard.teammate-activity.table.channel-availability.both'),
        value: ChannelAvailabilityCode.Both,
      },
      {
        text: this.intl.t(
          'inbox.dashboard.teammate-activity.table.channel-availability.conversations',
        ),
        value: ChannelAvailabilityCode.Conversations,
      },
      {
        text: this.intl.t('inbox.dashboard.teammate-activity.table.channel-availability.phone'),
        value: ChannelAvailabilityCode.Phone,
      },
    ];
  }

  get groupList() {
    let options = this.channelAvailabilityOptions.map((channel) => {
      return {
        text: channel.text,
        value: channel.value,
        isSelected: this.currentChannelAvailability === channel.value,
      };
    });
    return [
      {
        hasItemsMarkableAsSelected: true,
        items: options,
      },
    ];
  }

  @action async changeChannelAvailability(channelAvailability: ChannelAvailabilityCode) {
    try {
      this.currentChannelAvailability = channelAvailability;
      await this.adminAwayService.toggleAvailabeChannelForTeammate(
        this.args.admin.id,
        channelAvailability,
      );
    } catch {
      this.currentChannelAvailability = this.args.channelAvailabilityCode;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Teammates::ChannelsCell': typeof ChannelsCell;
    'inbox2/dashboard/teammates/channels-cell': typeof ChannelsCell;
  }
}
