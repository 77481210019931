/* import __COLOCATED_TEMPLATE__ from './quick-reply-options.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const QuickReplyOptions = templateOnlyComponent<Signature>();
export default QuickReplyOptions;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::QuickReplyOptions': typeof QuickReplyOptions;
    'inbox2/conversation-stream/part-groups/quick-reply-options': typeof QuickReplyOptions;
  }
}
