/* import __COLOCATED_TEMPLATE__ from './create-ticket-panel.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { type TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxApi from 'embercom/services/inbox-api';
import { type TicketAttributeSummaryValue } from 'embercom/objects/inbox/ticket-attribute-summary';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { request } from 'embercom/lib/inbox/requests';
import { type TaskFunction } from 'ember-concurrency';
import type IntlService from 'embercom/services/intl';
import type AiAssistSettings from 'embercom/services/ai-assist-settings';
import { EscKeyPriority } from 'embercom/lib/inbox2/types';

export interface CreateTicketPanelArgs {
  originalConversation: Conversation;
  ticketTypeResource: TicketTypeResource;
  createTicket: TaskFunction<void, []>;
  onUserVisibilityChanged?: (visibleToUser: boolean) => void;
  panelTitle: string;
  createTicketButtonLabel: string;
  isCreatingTicket: boolean;
  hidePanel: () => void;
  visibleToUser?: boolean;
  onCompanySelected: (companyId: string | null, isDefaultSelection: boolean) => void;
}

interface Signature {
  Args: CreateTicketPanelArgs;
}

export default class CreateTicketPanel extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;
  @service declare aiAssistSettings: AiAssistSettings;
  @service declare intercomEventService: any;

  @tracked visibleToUser = false;
  @tracked linkedTickets: LinkedTicketSummary[] = [];
  @tracked isFinPrefilled = false;

  constructor(owner: unknown, args: CreateTicketPanelArgs) {
    super(owner, args);

    this.loadLinkedTickets();
  }

  get isLoadingSuggestions() {
    return taskFor(this.loadSuggestions).isRunning;
  }

  @task({ restartable: true }) *loadSuggestions() {
    if (!this.aiAssistSettings.autoFill) {
      return;
    }

    if (this.args.ticketTypeResource.selectedTicketType?.disableAiAssist) {
      return;
    }

    let attributeNames = this.args.ticketTypeResource.ticketAttributes.map(
      (attribute) => attribute.descriptor.name,
    );

    if (
      !attributeNames.includes('_default_title_') &&
      !attributeNames.includes('_default_description_')
    ) {
      return;
    }

    let suggestions: Record<number, TicketAttributeSummaryValue> = yield this.generateTicket();
    this.isFinPrefilled = Object.keys(suggestions).length > 0;
    this.args.ticketTypeResource.ticketAttributes.forEach((attribute) => {
      attribute.value = suggestions[attribute.descriptor.id];
    });
  }

  async generateTicket() {
    let response = await request(
      `/ember/inbox/conversations/${this.args.originalConversation.id}/generate_ticket?app_id=${this.session.workspace.id}&ticket_type_id=${this.args.ticketTypeResource.selectedTicketType?.id}`,
    );
    return await response.json();
  }

  @action
  toggleVisibleToUser() {
    if (this.isSharingDisabled) {
      return;
    }

    this.visibleToUser = !this.visibleToUser;
    if (this.args.onUserVisibilityChanged) {
      this.args.onUserVisibilityChanged(this.visibleToUser);
    }
  }

  @action cancelAiAssist() {
    taskFor(this.loadSuggestions).cancelAll();
  }

  @action createTicketHandler() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'create_ticket_cta',
      context: 'create_ticket',
      place: 'inbox.create_ticket_panel',
      section: 'inbox_right_sidebar',
      ticket_category: this.args.ticketTypeResource.selectedTicketType!.category,
    });
    taskFor(this.args.createTicket).perform();
  }

  async loadLinkedTickets() {
    let { tickets } = await this.inboxApi.getLinkedTickets(this.args.originalConversation.id);
    this.linkedTickets = tickets;
  }

  get isSharingDisabled() {
    return this.isInternalTicket || this.hasSharedLinkedTicket || this.isLinkedToRequest;
  }

  get isLinkedToRequest() {
    return this.args.originalConversation.ticketCategory === TicketCategory.Request;
  }

  get isInternalTicket() {
    return this.args.ticketTypeResource.selectedTicketType?.internal;
  }

  get hasSharedLinkedTicket() {
    return this.linkedTickets.some((ticket) => ticket.visibleToUser);
  }

  get newTicketCategory() {
    return this.args.ticketTypeResource.selectedTicketType?.category;
  }

  get autoTurboTicketGenerationEnabled() {
    return this.aiAssistSettings.autoFill;
  }

  get disabledTooltipMessage() {
    if (this.isLinkedToRequest) {
      return 'inbox.create-ticket-panel.visibility-switch.linked-to-request-ticket';
    } else if (this.isInternalTicket) {
      return 'inbox.create-ticket-panel.visibility-switch.internal-ticket';
    } else {
      return 'inbox.create-ticket-panel.visibility-switch.has-shared-linked-ticket';
    }
  }

  get escPriority() {
    return EscKeyPriority.sidebarPreview;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CreateTicketPanel': typeof CreateTicketPanel;
    'inbox2/create-ticket-panel': typeof CreateTicketPanel;
  }
}
