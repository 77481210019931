/* import __COLOCATED_TEMPLATE__ from './convert-to-ticket-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import type CommandKService from 'embercom/services/command-k';
import type Router from '@ember/routing/router-service';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import type InboxApi from 'embercom/services/inbox-api';
import { useResource } from 'ember-resources';
import type Conversation from 'embercom/objects/inbox/conversation';
import { TicketTypeResource } from '../../ticket-type-resource';
import { type HotkeysMap } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';

interface Args {
  conversation: Conversation;
  setTicketTypeToConvertToTicket: (ticketTypeId?: number) => void;
}

interface Signature {
  Args: Args;
}

export default class ConvertToTicketButton extends Component<Signature> {
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare commandK: CommandKService;
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;
  @service declare inboxHotkeys: InboxHotkeys;

  readonly hotkeys: HotkeysMap;

  readonly ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    ticketTypeId: undefined,
    conversation: this.args.conversation,
  }));

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.hotkeys = this.inboxHotkeys.hotkeysMap;
  }

  get app() {
    return this.session.workspace;
  }

  get keys() {
    return [...this.hotkeys.ConvertToTicket.display];
  }

  @action async onClick() {
    this.showCreateNewTicketPane();
  }

  showCreateNewTicketPane() {
    this.commandK.registerAndShow({
      actionID: 'convert-to-ticket',
      onSelect: (ticketTypeId: any) => {
        this.args.setTicketTypeToConvertToTicket(ticketTypeId);
      },
    });
  }

  @use linkedTickets = AsyncData<LinkedTicketSummary[]>(async () => {
    if (!this.args.conversation.id) {
      return false;
    }
    let { tickets } = await this.inboxApi.getLinkedTickets(this.args.conversation.id);
    return tickets;
  });

  get hasSharedTaskTickets() {
    if (!this.linkedTickets.value) {
      return false;
    }

    return this.linkedTickets.value
      .filter((ticket) => ticket.category === TicketCategory.Task)
      .some((taskTickets) => taskTickets.visibleToUser);
  }

  get disableConvertToCustomerTicket() {
    return this.hasSharedTaskTickets;
  }

  get disabledTooltipText() {
    if (this.hasSharedTaskTickets) {
      return this.intl.t('inbox.convert-to-ticket-button.has-shared-task-tickets');
    } else {
      return '';
    }
  }

  get showMinimalTooltip() {
    return this.app?.hasCustomerTickets;
  }

  @action
  showLearnArticle(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'convert_to_ticket_article_link',
      context: `inbox.tooltip.convert_to_ticket_article_link`,
      place: 'inbox.tooltip',
      section: 'discovery_banner',
    });
    window.Intercom('showArticle', 7112463); // https://www.intercom.com/help/en/articles/7112463-how-to-create-a-customer-ticket#h_f2b9db3de8
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::Header::ConvertToTicketButton': typeof ConvertToTicketButton;
    'inbox2/conversation-stream/header/convert-to-ticket-button': typeof ConvertToTicketButton;
  }
}
