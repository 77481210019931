/* import __COLOCATED_TEMPLATE__ from './bulk-edit-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { BlocksDocument, type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import { type EditorState } from 'prosemirror-state';
import { action } from '@ember/object';
import type InboxState from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import type CommandKService from 'embercom/services/command-k';
import { DisplayContext } from 'embercom/services/command-k';
import { type MacroActionType } from 'embercom/objects/inbox/macro';
import type Macro from 'embercom/objects/inbox/macro';
import { MacroAction, MacroActionTypeValues } from 'embercom/objects/inbox/macro';
import type Session from 'embercom/services/session';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import trimWhitespaces from 'embercom/lib/inbox/composer-helpers/trim-whitespaces';
import { type HotkeysMap } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { next } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import { Config } from 'embercom/objects/inbox/composer-config';
import { type CustomInputRuleConfig } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import AttributesResolver from 'embercom/objects/inbox/attributes-resolver';
import { getOwner, setOwner } from '@ember/application';
import type ComposerPane from 'embercom/objects/inbox/composer-pane';
import { ComposerPaneType } from 'embercom/objects/inbox/composer-pane';
import BulkReplyPane from 'embercom/objects/inbox/bulk-reply-pane';
import NotePane from 'embercom/objects/inbox/note-pane';
import { type NewConversation } from 'embercom/objects/inbox/conversation';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';

interface Args {
  title: string;
  onClose: () => void;
  onSend: (macros: MacroAction[]) => unknown;
  isSending?: boolean;
  canReply?: boolean;
  commandKActionPriority: number;
  conversations:
    | undefined[]
    | Conversation[]
    | ConversationSummary[]
    | ConversationTableEntry[]
    | [NewConversation];
}

interface Signature {
  Args: Args;
}

export default class BulkEditModal extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare inboxHotkeys: InboxHotkeys;

  @tracked api?: ComposerPublicAPI;
  @tracked blocksDoc: BlocksDocument;
  @tracked macroActions: MacroAction[];
  @tracked activePane!: ComposerPane;
  @tracked config!: Config;
  @tracked macroActionType!: MacroActionType;

  readonly hotkeys: HotkeysMap;
  readonly bulkReplyPane: ComposerPane;
  readonly notePane: ComposerPane;
  readonly resolver: AttributesResolver;

  readonly allowedAttachmentFileTypes = ['*'];
  readonly allowedImageFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.resolver = new AttributesResolver();
    setOwner(this.resolver, getOwner(this));

    this.blocksDoc = new BlocksDocument();
    this.macroActions = [];

    this.api?.composer.commands.focus();
    this.commandK.setActivationContext(DisplayContext.BulkEdit);

    this.bulkReplyPane = new BulkReplyPane();
    this.notePane = new NotePane();
    this.setPane(this.args.canReply ? this.bulkReplyPane : this.notePane);
    this.hotkeys = this.inboxHotkeys.hotkeysMap;
  }

  willDestroy() {
    super.willDestroy();
    this.commandK.resetActivationContext();
  }

  get isUploading() {
    return this.api?.composer.state.isUploading ?? false;
  }

  createMacroInputRule() {
    let handleMacroInput = () =>
      this.commandK.registerAndShow({
        actionID: 'use-macro',
        onSelect: (macro) => {
          this.useMacro(macro);
        },
        onCancel: () => {
          this.insertHashSymbol();
        },
        context: {
          pane: this.activePane.type,
        },
      });
    return {
      type: 'custom',
      matcher: /(\s|^)#$/,
      handler(state: EditorState) {
        handleMacroInput();
        return state.tr;
      },
    } as CustomInputRuleConfig;
  }

  get panes() {
    return this.args.canReply ? [this.bulkReplyPane, this.notePane] : [this.notePane];
  }

  get hasInvalidMacroActions() {
    return this.macroActions.some((macroAction) => macroAction.isInvalid);
  }

  @action setPane(pane: ComposerPane) {
    this.activePane = pane;

    if (pane.type === ComposerPaneType.Note) {
      this.macroActionType = MacroActionTypeValues.AddNoteToConversation;
      this.config = this.buildConfig({ allowMentions: true });
    } else {
      this.macroActionType = MacroActionTypeValues.ReplyToConversation;
      this.config = this.buildConfig();
    }
  }

  @action insertHashSymbol() {
    this.api?.composer.commands.insertText('#');
  }

  @action onReady(api: ComposerPublicAPI) {
    this.api = api;

    next(this, () => {
      this.api?.composer.commands.focus();
    });
  }

  @action onChange(blocksDoc: BlocksDocument) {
    this.blocksDoc = blocksDoc;
  }

  @action send() {
    if (this.composerIsEmpty || this.isUploading) {
      return;
    }

    let macroActions = [...this.macroActions].filter((macroAction) => macroAction.applyable);
    let blocks = this.trimmedBlocksDoc.blocks;
    if (!isEmpty(blocks)) {
      macroActions.unshift(new MacroAction(this.macroActionType, { json_blocks: blocks }));
    }

    this.args.onSend(macroActions);
  }

  @action async useMacro(macro: Macro) {
    this.macroActions = [...this.macroActions, ...macro.actions];
    this.api?.composer.commands.focus();
    if (macro.blocks.length > 0) {
      this.api?.composer.commands.insertBlocks(macro.blocks);
    }
  }

  @action onDeleteMacroAction(macroAction: MacroAction) {
    this.macroActions = this.macroActions.without(macroAction);
  }

  @action addMacroAction(macroActionType: MacroActionType) {
    this.macroActions = [...this.macroActions, new MacroAction(macroActionType, {}, true)];
  }

  @action uploadAttachments(files: Array<File>) {
    files.forEach((file) => {
      this.api?.composer.commands.addAttachment(file);
    });
  }

  get composerIsEmpty() {
    return this.trimmedBlocksDoc.blocks.length === 0 && (this.macroActions ?? []).length === 0;
  }

  private get trimmedBlocksDoc() {
    return trimWhitespaces(this.blocksDoc);
  }

  private buildConfig({ allowMentions = false } = {}) {
    let config = new Config(
      getOwner(this),
      this.session.workspace.id,
      {
        allowedImageFileTypes: this.allowedImageFileTypes,
        allowedAttachmentTypes: this.allowedAttachmentFileTypes,
        uploader: EmbercomFileUploader,
        attrs: { policyUrl: `/apps/${this.session.workspace.id}/uploads` },
      },
      this.createMacroInputRule(),
      this.resolver,
      allowMentions,
      undefined,
      { useMacro: this.useMacro },
    );
    config.placeholder = this.intl.t('inbox.bulk-edit.composer.placeholder');
    return config;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::BulkEditModal': typeof BulkEditModal;
    'inbox2/bulk-edit-modal': typeof BulkEditModal;
  }
}
