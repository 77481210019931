/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import { type TagItemBaseAction } from 'embercom/objects/inbox/command-k/tag-item';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import type NavigableSelection from '../../common/navigable-selection-resource';
import type Tag from 'embercom/objects/inbox/tag';
import type Inbox2TagsSearch from 'embercom/services/inbox2-tags-search';
import type Session from 'embercom/services/session';

interface Args {
  containerSelectorName: string;
  items: Array<ChildAction>;
  selection: NavigableSelection;
  selectTag: (tagAction: ChildAction & { value: Tag }) => any;
  actionItem: TagItemBaseAction;
}

interface Signature {
  Args: Args;
}

export default class TagItemListComponent extends Component<Signature> {
  @service declare inbox2TagsSearch: Inbox2TagsSearch;
  @service declare session: Session;

  @ref('ul') declare ul: HTMLUListElement;

  get loadingIndicatorColor(): 'white' | 'blue' {
    return this.session.darkModeEnabled ? 'white' : 'blue';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::TagItem::List': typeof TagItemListComponent;
    'inbox2/command-k/tag-item/list': typeof TagItemListComponent;
  }
}
