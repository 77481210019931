/* import __COLOCATED_TEMPLATE__ from './preview-pane.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { useResource } from 'ember-resources';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { TicketTypeResource } from '../../ticket-type-resource';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';

import Component from '@glimmer/component';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';

interface Signature {
  Element: HTMLDivElement;
  Args: { item: ChildAction<ConversationTableEntry> };
}

export default class PreviewPane extends Component<Signature> {
  @trackedReset('args.item.value.id') ticketTypeId: number | undefined;

  readonly ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    ticketTypeId: this.args.item.value.ticketType?.id,
    conversation: this.args.item.value,
  }));

  get ticketAttributes() {
    return this.ticketTypeResource.ticketAttributeSummaries.filter(
      (attribute: TicketAttributeSummary) => !attribute.descriptor.isBuiltIn && attribute.value,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::SearchTicket::PreviewPane': typeof PreviewPane;
  }
}
