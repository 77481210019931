/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { useFunction, useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { ChildAction } from 'embercom/objects/inbox/command-k/action';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type WhatsappTemplates from 'embercom/services/whatsapp-templates';
import type InsertWhatsappTemplateAction from 'embercom/objects/inbox/command-k/insert-whatsapp-template';
import type WhatsappTemplate from 'embercom/objects/inbox/whatsapp-template';
import type Session from 'embercom/services/session';
import type InboxState from 'embercom/services/inbox-state';
import type CommandKService from 'embercom/services/command-k';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';

interface Signature {
  Args: CommandKPaneComponentArgs<InsertWhatsappTemplateAction>;
}

type TemplatesLoaderResponse = { query: string; templates: Array<WhatsappTemplate> };

export default class WhatsappInsertTemplatePaneComponent extends Component<Signature> {
  @service declare whatsappTemplates: WhatsappTemplates;
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare commandK: CommandKService;
  @tracked query = '';
  @tracked selectedTemplate: WhatsappTemplate | undefined;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.items,
    isCommandKVisible: this.commandK.isVisible,
  }));

  get items(): Array<ChildAction> {
    let foundTemplateNames = [...new Set(this.templatesByQuery.map((template) => template.name))];

    return foundTemplateNames.map((name: string) => {
      return new ChildAction({
        parent: this.args.actionItem,
        id: name,
        label: name,
        optionComponent: 'inbox2/command-k/whatsapp/insert-template/option',
      });
    });
  }

  get allTemplates(): Array<WhatsappTemplate> {
    return this.allTemplatesLoader.value ?? [];
  }

  get templatesByQuery(): Array<WhatsappTemplate> {
    return this.templatesLoader.value?.templates ?? [];
  }

  get selectedName(): string | undefined {
    return this.selection.selected?.value;
  }

  get bestMatchedLanguage(): string | undefined {
    return this.templatesByQuery.find((template) => template.name === this.selectedName)?.language;
  }

  get selectedNameTemplates(): Array<WhatsappTemplate> {
    return this.templatesByName(this.selectedName);
  }

  templatesByName(name: string | undefined) {
    return this.allTemplates.filter((template) => template.name === name);
  }

  @action search(query: string) {
    this.query = query;

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.items.length,
    });
  }

  @action onLanguageChange(language: string) {
    if (!this.selectedTemplate) {
      return;
    }
    this.selectedTemplate = this.findTemplate(this.selectedTemplate.name, language);
  }

  findTemplate(name: string, language: string) {
    return this.allTemplates.find(
      (template) => template.name === name && template.language === language,
    );
  }

  private allTemplatesLoader = useFunction(
    this,
    async (previous: WhatsappTemplate[]) => {
      if (previous) {
        return previous;
      }

      return await this.whatsappTemplates.listTemplates({
        conversation_id: this.inboxState.activeConversationId,
        whatsapp_integration_id: this.whatsappTemplates.activeIntegration?.id,
      });
    },
    () => [
      this.session.workspace.id,
      this.inboxState.activeConversationId,
      this.whatsappTemplates.activeIntegration,
    ],
  );

  private templatesLoader = useFunction(
    this,
    async (previous: TemplatesLoaderResponse, query: string) => {
      if (previous && query === previous.query) {
        return previous;
      }

      return await this.whatsappTemplates.searchTemplates(this.query, {
        conversation_id: this.inboxState.activeConversationId,
        whatsapp_integration_id: this.whatsappTemplates.activeIntegration?.id,
      });
    },
    () => [
      this.query,
      this.session.workspace.id,
      this.inboxState.activeConversationId,
      this.whatsappTemplates.activeIntegration,
    ],
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Whatsapp::InsertTemplate::Pane': typeof WhatsappInsertTemplatePaneComponent;
    'inbox2/command-k/whatsapp/insert-template/pane': typeof WhatsappInsertTemplatePaneComponent;
  }
}
