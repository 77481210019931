/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-ml */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type CommandKService from 'embercom/services/command-k';
import type AIAssist from 'embercom/objects/inbox/command-k/ai-assist';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';
import type Session from 'embercom/services/session';

interface Signature {
  Args: CommandKPaneComponentArgs<AIAssist>;
}

export default class AiAssistPaneComponent extends Component<Signature> {
  @tracked query = '';
  @tracked mode: 'default' | 'translate' = 'default';

  @service declare commandK: CommandKService;
  @service declare session: Session;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.items,
    isCommandKVisible: this.commandK.isVisible,
  }));

  get items(): Array<ChildAction> {
    if (this.mode === 'translate' || this.args.actionItem.context?.isTranslationMode) {
      return this.args.actionItem.buildTranslationOptions(this.query);
    }

    return this.args.actionItem.buildResults(this.query);
  }

  @action search(query: string) {
    this.query = query;
  }

  @action onSelect(actionItem: ChildAction) {
    if (actionItem.id === 'ai-assist-translate') {
      this.query = '';
      this.mode = 'translate';
    } else {
      this.args.onSelect(actionItem);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::AiAssist::Pane': typeof AiAssistPaneComponent;
    'inbox2/command-k/ai-assist/pane': typeof AiAssistPaneComponent;
  }
}
