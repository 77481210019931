/* import __COLOCATED_TEMPLATE__ from './github-link-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import type IntlService from 'embercom/services/intl';

interface Args {
  githubUrl: string;
}

interface Signature {
  Args: Args;
}

export default class GithubLinkDropdown extends Component<Signature> {
  @service declare router: Router;
  @service declare intl: IntlService;

  get dropdownItems() {
    return [
      {
        items: [
          {
            text: this.intl.t('inbox.linked-tickets.open-in-github'),
            onSelectItem: () => safeWindowOpen(this.args.githubUrl, '_blank'),
          },
          {
            text: this.intl.t('inbox.linked-tickets.view-all-links-to-this-issue'),
            onSelectItem: () =>
              this.router.transitionTo('inbox.workspace.search', {
                queryParams: { q: `"${this.args.githubUrl}"` },
              }),
          },
        ],
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::LinkedGithubIssues::GithubLinkDropdown': typeof GithubLinkDropdown;
    'inbox2/conversation-details-sidebar/sidebar-sections/linked-github-issues/github-link-dropdown': typeof GithubLinkDropdown;
  }
}
