/* import __COLOCATED_TEMPLATE__ from './knowledge-base-content.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { use } from 'ember-resources/util/function-resource';
import { EntityType } from 'embercom/models/data/entity-types';
import { AsyncData } from 'embercom/resources/utils/async-data';
import type InboxApi from 'embercom/services/inbox-api';
import { type KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';
import {
  type Block,
  type BlockList,
  type NestedList,
  type NestedListItem,
  type Paragraph,
  type AttachmentList,
} from '@intercom/interblocks.ts';
import { type CopilotChatMessageAnalyticsData } from 'embercom/lib/inbox2/copilot/types';
import { later } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { KNOWLEDGE_HUB_ENTITIES_NAMES } from 'embercom/lib/knowledge-hub/constants';

export interface Args {
  contentId: number;
  contentType: EntityType;
  activeParticipantId: string;
  onClose: () => void;
  analyticsData: CopilotChatMessageAnalyticsData;
  highlightedBlockIndices?: [number, number];
  articleVersionId?: string;
}

interface Signature {
  Element: Element;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class KnowledgeBaseContentComponent extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;

  get content() {
    return this.activeContentLoader.value;
  }

  get isLoading() {
    return this.activeContentLoader.isLoading;
  }

  get isWebpage() {
    return this.content?.entityType === EntityType.ExternalContent;
  }

  get authorId() {
    return this.content?.author?.id.toString();
  }

  get attachments() {
    let blocks = this.content?.blocks ?? [];
    let attachments = blocks.filter((block) => block.type === 'attachmentList');
    return attachments as AttachmentList[];
  }

  get entityName() {
    let entityType = this.content?.entityType;

    switch (entityType) {
      case EntityType.InternalArticle:
        return 'internal_article';
      case EntityType.ArticleContent:
        return KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.ArticleContent];
      case EntityType.FileSourceContent:
        return KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.FileSourceContent];
      case EntityType.ExternalContent:
        return KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.ExternalContent];
      case EntityType.ContentSnippet:
        return KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.ContentSnippet];
      default:
        return '';
    }
  }

  get iconClassNames() {
    let entityType = this.content?.entityType;

    switch (entityType) {
      case EntityType.InternalArticle:
        return 'bg-yellow-light text-yellow-dark dark:bg-dm-yellow-dark dark:text-yellow-light';
      default:
        return 'bg-gray-lightest text-dark dark:bg-dm-gray-dark dark:text-white';
    }
  }

  @use activeContentLoader = AsyncData<KnowledgeBaseContent | undefined>(async () => {
    let content = await this.inboxApi.fetchKnowledgeBaseContent(
      this.args.contentId,
      this.args.contentType,
      this.args.activeParticipantId,
    );

    if (this.args.highlightedBlockIndices) {
      if (this.args.articleVersionId === content.liveVersionId) {
        let startBlock = this.args.highlightedBlockIndices[0];
        let endBlock = this.args.highlightedBlockIndices[1];
        let blocks = content.blocks;
        addHighlighting(blocks, startBlock, endBlock);
        later(() => {
          let first = document.querySelector('.inbox-copilot-passage-highlight');
          if (first) {
            first.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, ENV.APP._100MS);
      }
    }

    return content;
  });

  @action instrumentCopy() {
    let selection = document.getSelection()?.toString();
    // The 'copy' event seems to get triggred if you select, then unselect, then copy
    // So we need to ignore these events if there isn't any text currently selected
    if (selection) {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'related_content_sheet',
        action: 'manually_copied',
        context: 'related_content',
        ...this.args.analyticsData,
        entity_id: this.args.contentId,
        entity_type: this.args.contentType,
        text: selection,
      });
    }
  }
}

function addHighlighting(blocks: BlockList, start: number, end: number) {
  if (blocks.length !== start + end) {
    for (let i = start; i <= end; i++) {
      addClassesToParagraphs(blocks[i]);
    }
  }
  return blocks;
}

function addClassesToParagraphs(block: Block) {
  if (!block) {
    return;
  }
  if (block.type === 'paragraph') {
    if (block.text === '') {
      return;
    }
    (block as Paragraph).class = `inbox-copilot-passage-highlight`;
  }

  if ((block as NestedListItem).content) {
    (block as NestedListItem).content.forEach((contentBlock: any) =>
      addClassesToParagraphs(contentBlock),
    );
  }

  if ((block as NestedList).items) {
    (block as NestedList).items.forEach((item: { content: BlockList }) => {
      item.content.forEach((itemContent: Block) => addClassesToParagraphs(itemContent));
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::SourcePreviews::KnowledgeBaseContent': typeof KnowledgeBaseContentComponent;
    'inbox2/copilot/source-previews/knowledge-base-content': typeof KnowledgeBaseContentComponent;
  }
}
