/* import __COLOCATED_TEMPLATE__ from './home.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type CommandKService from 'embercom/services/command-k';
import { type Action } from 'embercom/objects/inbox/command-k/action';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import type Session from 'embercom/services/session';
import { type SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { type QuickSearchContexts } from 'embercom/objects/inbox/macro';
import QuickSearchDataMultiple from 'embercom/components/inbox2/common/quick-search/quick-search-data-multiple-resource';
import { trackedFunction } from 'ember-resources/util/function';

// @ts-ignore
import { ref } from 'ember-ref-bucket';

interface ComponentArgs {
  selectAction: (s: Action) => void;
}

interface Signature {
  Args: ComponentArgs;
}

export const SEARCH_RESULTS_SIZE = 3;

export default class Inbox2CommandKHomeComponent extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare session: Session;

  @tracked query = '';

  @ref('command-k-input') declare commandKInput?: HTMLInputElement;

  quickSearchData = useResource(this, QuickSearchDataMultiple, () => ({
    types: this.quickSearchTypes,
    query: this.query,
    size: SEARCH_RESULTS_SIZE,
    currentSearchContext: this.quickSearchContext,
    onSearchReturn: this.onQuickSearchReturn,
  }));

  selection = useResource(this, NavigableSelection, () => ({
    items: this.filteredActions,
    isCommandKVisible: this.isVisible,
  }));

  get activeActions() {
    return this.commandK.activeActions;
  }

  get isVisible() {
    return this.commandK.isVisible;
  }

  get filteredActions(): Array<Action> {
    return this.filteredActionsLoader.value || [];
  }

  private filteredActionsLoader = trackedFunction(this, async () => {
    let upperQuery = this.query.trim().toLocaleUpperCase();
    if (upperQuery === '') {
      return this.activeActions.filter((action) => !action.secret);
    }

    let topLevelActions: Array<Action> = [];
    let childActions: Array<Action> = [];

    for (let action of this.activeActions) {
      if (action.secret) {
        if (action.label.toLocaleUpperCase() === upperQuery) {
          topLevelActions.push(action);
        }
      } else if (action.label.toLocaleUpperCase().includes(upperQuery)) {
        topLevelActions.push(action);
      }

      if ('buildTopLevelItems' in action) {
        let items = await action.buildTopLevelItems(this.query, this.quickSearchData.results);
        items.forEach((sub) => {
          childActions.push(sub);
        });
      }
    }

    return [...topLevelActions, ...childActions];
  });

  @action onQuickSearchReturn() {
    if (this.selection.items.length) {
      this.selection.select(this.selection.items[0]);
    }

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.filteredActions.length,
    });
  }

  @action search(query: string) {
    this.query = query;
    this.commandK.trackSearch({
      query,
      action: 'search_initiated',
    });
  }

  @action selectAction(action: Action) {
    this.args.selectAction(action);
    this.query = '';

    // If command-K is still visible after selecting an action, we should make
    // sure the focus stays on the input. Specifically, this is useful for
    // tagging where cmd-k isn't hidden after you select a tag.
    if (this.commandK.isVisible) {
      this.commandKInput?.focus();
    }
  }

  private get quickSearchTypes(): Array<SearchableType> {
    let types = this.activeActions.map((action) =>
      'searchableTypes' in action ? action.searchableTypes : null,
    );
    return types.flat().compact() as Array<SearchableType>;
  }

  private get quickSearchContext(): Array<QuickSearchContexts> {
    let context: number[] = [];
    this.activeActions.forEach((action) => {
      if ('searchableContext' in action && action.searchableContext) {
        context = [...context, ...action.searchableContext];
      }
    });
    return context;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Home': typeof Inbox2CommandKHomeComponent;
    'inbox2/command-k/home': typeof Inbox2CommandKHomeComponent;
  }
}
