/* import __COLOCATED_TEMPLATE__ from './ticket-types-list.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import templateOnlyComponent from '@ember/component/template-only';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import type InsertTicketCardAction from 'embercom/objects/inbox/command-k/insert-ticket-card';
import type NavigableSelection from '../../common/navigable-selection-resource';

interface Args {
  onSelect: (action: ChildAction<any>) => void;
  selection: NavigableSelection;
  items: ChildAction[];
  currentAction: InsertTicketCardAction;
}

interface Signature {
  Element: HTMLUListElement;
  Args: Args;
}

const TicketTypesListComponent = templateOnlyComponent<Signature>();
export default TicketTypesListComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::InsertTicketCard::TicketTypesList': typeof TicketTypesListComponent;
  }
}
