/* import __COLOCATED_TEMPLATE__ from './suggestion-carousel.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type CopilotSuggestion } from 'embercom/resources/inbox2/copilot/copilot-question-suggestions';

interface SuggestionCarouselArgs {
  suggestions: CopilotSuggestion[];
  onSuggestionClick: (suggestion: CopilotSuggestion, suggestionIndex: number) => void;
  onSuggestionShow: (suggestion: CopilotSuggestion, suggestionIndex: number) => void;
  onSuggestionRejected: (suggestion: CopilotSuggestion) => void;
}

interface Signature {
  Element: Element;
  Args: SuggestionCarouselArgs;
  Blocks: {
    default: [];
  };
}

export default class SuggestionCarousel extends Component<Signature> {
  @tracked
  visibleSuggestionIndex = 0;

  get suggestionsWithIndex() {
    return this.args.suggestions?.map((suggestion, index) => ({ ...suggestion, index }));
  }

  get visibleSuggestion() {
    return this.suggestionsWithIndex?.[this.visibleSuggestionIndex];
  }

  get shouldShowPreviousSuggestionArrow() {
    return this.visibleSuggestionIndex > 0;
  }

  get shouldShowNextSuggestionArrow() {
    return this.visibleSuggestionIndex < (this.args.suggestions?.length ?? 0) - 1;
  }

  @action
  onSuggestionArrowClick(direction: 'previous' | 'next') {
    let availableSuggestionCount = this.args.suggestions?.length || 0;
    if (direction === 'previous') {
      this.visibleSuggestionIndex = Math.max(0, this.visibleSuggestionIndex - 1);
    } else {
      this.visibleSuggestionIndex = Math.min(
        Math.max(availableSuggestionCount - 1, 0),
        this.visibleSuggestionIndex + 1,
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Suggestions::SuggestionCarousel': typeof SuggestionCarousel;
    'inbox2/copilot/suggestions/suggestion-carousel': typeof SuggestionCarousel;
  }
}
