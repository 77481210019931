/* import __COLOCATED_TEMPLATE__ from './mention-typeahead.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type Composer from '@intercom/embercom-prosemirror-composer/lib/composer';
import { type TypeaheadMatchResult } from '@intercom/embercom-prosemirror-composer';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type Session from 'embercom/services/session';
import { AdminSearchContexts } from 'embercom/objects/inbox/macro';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';
import { EntityType } from 'embercom/models/data/entity-types';

interface Args {
  composer: Composer;
  matchResult: TypeaheadMatchResult | null;
  registerDelegate(f: Function): void;
  deregisterDelegate(f: Function): void;
}

interface Signature {
  Args: Args;
}

const MAX_MENTION_SEARCH_LENGTH = 50;
const MAX_MENTION_NUM_SPACES = 2;
const MAX_MENTIONS_RESULTS = 8;

export default class MentionTypeahead extends Component<Signature> {
  @service declare session: Session;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @service regionService: any;
  @service intercomEventService: any;

  get query() {
    return this.args.matchResult?.text || '';
  }

  get items() {
    if (this.query.search('@') === -1 || this.query === '@ ') {
      return [];
    }

    let searchTerm = this.query.replace('@', '');
    let numSpaces = searchTerm.split(' ').length - 1;

    if (searchTerm.length > MAX_MENTION_SEARCH_LENGTH || numSpaces > MAX_MENTION_NUM_SPACES) {
      return [];
    }

    return this.inbox2AssigneeSearch.filterAdminsAndTeams(
      searchTerm,
      AdminSearchContexts.Mentions,
      MAX_MENTIONS_RESULTS,
    ).results;
  }

  @action
  insertMention(selectedItem: { data: AdminWithPermissions; entityType: EntityType }) {
    if (!this.args.matchResult) {
      return;
    }

    let prosemirrorState = this.args.composer.state.prosemirrorState;

    if (prosemirrorState) {
      let uri;
      let typeOfMention = 'admin';
      if (selectedItem.entityType === EntityType.Team) {
        typeOfMention = 'team';
      }
      this.intercomEventService.trackAnalyticsEvent({
        action: 'created',
        object: `${typeOfMention}_mention`,
        place: 'inbox',
      });
      uri = `//${this.regionService.appHost}/apps/${this.session.workspace.id}/${typeOfMention}/${selectedItem.data.id}`;

      let { tr } = prosemirrorState;
      let mentionSpec = prosemirrorState.schema.nodes.mention;

      let node = mentionSpec.createAndFill({ name: selectedItem.data.name, uri });
      tr.delete(this.args.matchResult.range.from, this.args.matchResult.range.to);
      tr.insert(this.args.matchResult.range.from, node);
      this.args.composer.commands.dispatch(tr);
      this.args.composer.commands.insertText(' ');
      this.args.composer.commands.focus();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MentionTypeahead': typeof MentionTypeahead;
    'inbox2/composer/mention-typeahead': typeof MentionTypeahead;
  }
}
