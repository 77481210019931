/* import __COLOCATED_TEMPLATE__ from './source-link.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { EntityType } from 'embercom/models/data/entity-types';
import type InboxState from 'embercom/services/inbox-state';
import { type NormalizedFinSource } from 'embercom/lib/inbox2/copilot/types';

export interface Args {
  source: NormalizedFinSource;
  onClick(event: PointerEvent): void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class SourceLink extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxState: InboxState;

  get inbox() {
    return this.inboxState.activeInbox || { id: 'all', category: 'shared' };
  }

  get isInternalArticle() {
    return this.args.source.entity_type === EntityType.InternalArticle;
  }

  get isArticleContent() {
    return this.args.source.entity_type === EntityType.ArticleContent;
  }

  get isContentSnippet() {
    return this.args.source.entity_type === EntityType.ContentSnippet;
  }

  get isSlackThread() {
    return this.args.source.entity_type === EntityType.SlackThread;
  }

  get isFileSourceContent() {
    return this.args.source.entity_type === EntityType.FileSourceContent;
  }

  get isPastConversationSnippet() {
    return this.args.source.entity_type === EntityType.PastConversationSnippet;
  }

  get isConversationExcerpt() {
    return this.args.source.entity_type === EntityType.ConversationExcerpt;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Sources::SourceLink': typeof SourceLink;
  }
}
