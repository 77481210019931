/* import __COLOCATED_TEMPLATE__ from './set-conversation-data-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type MacroActionComponentArgs } from 'embercom/lib/inbox2/bulk-action-types';

interface Signature {
  Element: HTMLElement;
  Args: MacroActionComponentArgs;
}

const SetConversationDataAttribute = templateOnlyComponent<Signature>();
export default SetConversationDataAttribute;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MacroActions::SetConversationDataAttribute': typeof SetConversationDataAttribute;
    'inbox2/composer/macro-actions/set-conversation-data-attribute': typeof SetConversationDataAttribute;
  }
}
