/* import __COLOCATED_TEMPLATE__ from './priority.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { action } from '@ember/object';
import { TABLE_ROW_TRACKING_SECTION } from '../table-row';
import type ConversationsTableActions from 'embercom/objects/inbox/conversations-table-actions';

interface Args {
  conversationEntry: ConversationTableEntry;
  conversationsTableActions: ConversationsTableActions;
}

interface Signature {
  Args: Args;
}

export default class Priority extends Component<Signature> {
  @action async onChangePriority() {
    this.args.conversationsTableActions.togglePriority(
      this.args.conversationEntry,
      TABLE_ROW_TRACKING_SECTION,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::Priority': typeof Priority;
    'inbox2/conversations-table/table-cells/priority': typeof Priority;
  }
}
