/* import __COLOCATED_TEMPLATE__ from './content-snippet-link.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';
import { type ContentSnippetSearchResult } from 'embercom/lib/inbox2/copilot/types';

export interface ContentSnippetLinkArgs {
  entityId: ContentSnippetSearchResult['entity_id'];
  isIconLink?: boolean;
  onClickLink(event: PointerEvent): void;
}

interface Signature {
  Element: Element;
  Args: ContentSnippetLinkArgs;
  Blocks: {
    default: [];
  };
}

const ContentSnippetLink = templateOnlyComponent<Signature>();
export default ContentSnippetLink;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Links::ContentSnippetLink': typeof ContentSnippetLink;
    'inbox2/copilot/links/content-snippet-link': typeof ContentSnippetLink;
  }
}
