/* import __COLOCATED_TEMPLATE__ from './smart-reply-toolbar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { type SmartReplySource } from 'embercom/objects/inbox/smart-reply';
import type SmartReply from 'embercom/objects/inbox/smart-reply';
import type IntlService from 'embercom/services/intl';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Args {
  conversationId?: Conversation['id'];
  accept: () => void;
  reject: (reason: string, detail?: string) => void;
  sources?: SmartReplySource[];
  smartReply: SmartReply | null;
  width: number;
}

interface Signature {
  Args: Args;
}

export default class SmartReplyToolbar extends Component<Signature> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @tracked expanded = false;
  @tracked showReasonModal = false;
  @tracked primaryReason?: string;
  @tracked otherReason?: string;

  get width() {
    let minWidth = 330;
    let width = this.args.width && this.args.width > minWidth ? this.args.width + 4 : minWidth;
    return `width: ${width}px`;
  }

  get showIconButtonsOnly() {
    return this.args.width < 445;
  }

  get sources() {
    return (this.args.sources ?? [])
      .map((source: SmartReplySource) => {
        if (!source.userSummary) {
          return;
        }
        return {
          title: this.intl.t('inbox.composer.smart-reply-toolbar.conversation-source-title', {
            name: source.userSummary.name,
          }),
          icon: 'conversation' as const,
          entityId: source.entityId,
          onClick: (_event: MouseEvent) => this.onConversationClick(source.entityId),
        };
      })
      .filter(Boolean);
  }

  get labelKey() {
    let action = this.expanded ? 'hide' : 'show';
    return `inbox.composer.smart-reply-toolbar.${action}-sources`;
  }

  get modalButtonDisabled() {
    if (this.primaryReason === 'other') {
      return !this.otherReason;
    }
    return !this.primaryReason;
  }

  get rejectionReasons() {
    return [
      {
        text: this.intl.t('inbox.composer.smart-reply-toolbar.reasons.incorrect-answer'),
        id: 'incorrect_answer',
        icon: 'alert',
      },
      {
        text: this.intl.t('inbox.composer.smart-reply-toolbar.reasons.too-long'),
        id: 'too_long',
        icon: 'long-text',
      },
      {
        text: this.intl.t('inbox.composer.smart-reply-toolbar.reasons.not-my-tone'),
        id: 'not_my_tone',
        icon: 'megaphone',
      },
      {
        text: this.intl.t('inbox.composer.smart-reply-toolbar.reasons.other'),
        id: 'other',
        icon: undefined,
      },
    ] as const;
  }

  // This needs to render below the feedback modal, which has a z-index of 10000
  setPopoverZIndex(popperModifierData: any) {
    let { popper: popperElement } = popperModifierData.state.elements;
    popperElement.style.zIndex = 9999;
  }

  @action toggleSources() {
    this.intercomEventService.trackAnalyticsEvent({
      action: this.expanded ? 'collapsed' : 'expanded',
      section: 'smart_reply_toolbar',
      conversation_id: this.args.conversationId,
      ...this.args.smartReply?.analyticsData,
      object: 'smart_reply_sources',
    });
    this.expanded = !this.expanded;
  }

  @action onConversationClick(conversationId: number) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      section: 'smart_reply_toolbar',
      conversation_id: this.args.conversationId,
      ...this.args.smartReply?.analyticsData,
      source_conversation_id: conversationId,
      object: 'smart_reply_source',
    });
    this.inboxSidebarService.previewConversationById(conversationId);
  }

  @action onSelectItem(reason: { id: string; text: string; icon: string }) {
    if (reason.id === 'other') {
      this.showReasonModal = true;
    } else {
      this.args.reject(reason.id, '');
    }
  }

  @action rejectWithOtherReason() {
    this.args.reject('other', this.otherReason);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::SmartReplyToolbar': typeof SmartReplyToolbar;
    'inbox2/composer/smart-reply-toolbar': typeof SmartReplyToolbar;
  }
}
