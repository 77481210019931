/* import __COLOCATED_TEMPLATE__ from './merge-into.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import { inject as service } from '@ember/service';
import type InboxApi from 'embercom/services/inbox-api';
import type IntlService from 'ember-intl/services/intl';
import type Snackbar from 'embercom/services/snackbar';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type CommandKService from 'embercom/services/command-k';
import { tracked } from '@glimmer/tracking';

interface Args {
  primaryConversationSummary: ConversationRecord;
  secondaryConversationSummary: ConversationRecord;
  onClose: () => void;
  user: UserSummary;
}

export default class MergeInto extends Component<Args> {
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;
  @service notificationsService: any;
  @service declare snackbar: Snackbar;
  @service declare commandK: CommandKService;
  @service declare intercomEventService: any;

  @tracked isDisabled = false;

  @action async onConfirm() {
    try {
      this.isDisabled = true;
      await this.inboxApi.mergeConversation(
        this.args.secondaryConversationSummary.id,
        this.args.primaryConversationSummary.id,
      );
      let { isTicket, title, id, ticketId } = this.args.primaryConversationSummary;

      let type = isTicket
        ? this.intl.t('inbox.merge-into.ticket-type')
        : this.intl.t('inbox.merge-into.conversation-type');

      this.intercomEventService.trackAnalyticsEvent({
        action: 'confirm',
        object: 'confirmation_merge_preview_modal',
        section: 'conversation_merge',
      });

      this.snackbar.notify(
        this.intl.t('inbox.merge-into.merged-conversation', {
          type,
          title,
          id: isTicket ? ticketId : id,
        }),
      );
    } catch (e) {
      let response = await e.response.json();
      this.snackbar.notifyError(response.errors);
    }
    this.args.onClose();
    this.commandK.hide();
  }

  @action onClose() {
    this.args.onClose();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'exit',
      object: 'confirmation_merge_preview_modal',
      section: 'conversation_merge',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::MergeInto::MergeInto': typeof MergeInto;
    'inbox2/command-k/merge-into/merge-into': typeof MergeInto;
  }
}
