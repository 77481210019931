/* import __COLOCATED_TEMPLATE__ from './conversations.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import { type TaskGenerator } from 'ember-concurrency';
import type InboxApi from 'embercom/services/inbox-api';
import { taskFor } from 'ember-concurrency-ts';
import type Session from 'embercom/services/session';
import { InboxSortOption } from 'embercom/models/data/inbox/inbox-filters';

interface Args {
  conversationIds: number[];
  openPreviewPanel: (conversationId: number) => void;
}

interface Signature {
  Args: Args;
}

export default class DrilldownConversations extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;

  @tracked conversations: ConversationSummary[] = [];
  @tracked didFetchConversations = false;

  @tracked conversationIds = this.args.conversationIds || [];

  @tracked page = 1;
  pageSize = 20;
  sortOption: InboxSortOption = InboxSortOption.Newest;
  rowCount: number = this.conversationIds.length;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    taskFor(this.fetchConversations).perform();
  }

  get isEmpty() {
    return (
      this.conversationIds.length === 0 ||
      (this.didFetchConversations && this.conversations.length === 0)
    );
  }

  get initialPageLoading() {
    return this.page === 1;
  }

  get isLoading() {
    return !this.initialPageLoading && taskFor(this.fetchConversations).isRunning;
  }

  @action
  requestNextPage() {
    taskFor(this.fetchConversations).perform();
  }

  @action
  selectConversation(id: number) {
    this.args.openPreviewPanel(id);
  }

  @task({ enqueue: true })
  *fetchConversations(): TaskGenerator<void> {
    let start = (this.page - 1) * this.pageSize;
    let end = start + this.pageSize;

    if (start >= this.conversationIds.length) {
      return;
    }
    let fetchedConversations = yield this.inboxApi.fetchConversations(
      this.conversationIds.slice(start, end),
    );
    this.conversations = [...this.conversations, ...fetchedConversations];
    this.page += 1;
    this.didFetchConversations = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Drilldown::Conversations': typeof DrilldownConversations;
    'inbox2/dashboard/drilldown/conversations': typeof DrilldownConversations;
  }
}
