/* import __COLOCATED_TEMPLATE__ from './header-actions.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState } from 'embercom/objects/inbox/conversation';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import type InboxState from 'embercom/services/inbox-state';
import type User from 'embercom/objects/inbox/user';
import type Router from '@ember/routing/router-service';
import type CtiService from 'embercom/services/cti-service';
import type UserApi from 'embercom/services/user-api';
// @ts-ignore
import intermoji from '@intercom/intermoji';

interface Args {
  conversation: Conversation;
  isLoading?: boolean; // This is true when creating a new conversation
  user: User;
  hideConversationEvents: boolean;
  onToggleConversationEvents: () => unknown;
  openConversation: () => unknown;
  closeConversation: () => unknown;
  snoozeConversation: () => unknown;
  exportConversation: () => void;
  togglePriority: (conversation: Conversation, priority: boolean) => unknown;
  allowManageParticipants?: boolean;
  showIconsOnly?: boolean;
  changeTicketState: InboxState['changeTicketState'];
  setTicketTypeToConvertToTicket: (ticketTypeId?: number) => void;
  hideActions?: boolean;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationHeaderActionsComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare intercomEventService: any;
  @service declare router: Router;
  @service declare ctiService: CtiService;
  @service declare userApi: UserApi;

  @tracked conversationId?: number;

  conversationState = ConversationState;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!this.args.isLoading) {
      this.setConversationId();
    }
  }

  get user(): User | undefined {
    return this.args.user;
  }

  get phoneCallEscalationEnabled() {
    return (
      this.user &&
      this.user.attributes.phone &&
      this.args.conversation.participantSummaries.length <= 1
    );
  }

  get ctiCallButtonDisabled() {
    return !(
      this.phoneCallEscalationEnabled &&
      this.ctiService.ctiAvailable &&
      !this.ctiService.initiatedCall
    );
  }

  @action goToParent() {
    let parentConversationId = this.args.conversation.parent?.id || 1;
    this.router.transitionTo(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      parentConversationId,
    );
  }

  get parentUserName() {
    return this.args.conversation.parent?.userSummary?.displayAs;
  }

  get ticketTypeEmoji() {
    return intermoji.twemojiSVGUri(this.args.conversation.ticketType?.emoji ?? '');
  }

  get isConversationListHidden() {
    return this.inboxState.isConversationListHidden;
  }

  get showIconOnly() {
    return this.inboxState.isNarrowConversationStream || this.args.showIconsOnly;
  }

  setConversationId() {
    this.conversationId = this.args.conversation.id;
  }

  @action togglePriority(options?: { keyboardShortcutUsed: boolean }) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'changed',
      object: 'priority',
      place: 'inbox',
      section: 'conversation_details',
      conversation_id: this.args.conversation.id,
      marked_as_priority: this.args.conversation.priority,
      inbox_type: this.inboxState.activeInbox?.type,
      layout_type: this.inboxState.activeConversationsView,
      shortcut_key: options?.keyboardShortcutUsed,
    });

    this.args.togglePriority(this.args.conversation, !this.args.conversation.priority);
  }

  get canShowInboxConversationPanel() {
    return !this.session.showLightInbox;
  }

  get canShowConvertToTicketButton() {
    return !this.args.conversation.isTicket && !this.session.showLightInbox;
  }

  get isCallingEnabled() {
    if (
      !this.session.workspace.isFeatureEnabled('ae-calling-killswitch') &&
      (this.session.workspace.isFeatureEnabled('channels-video-call') ||
        this.session.workspace.isFeatureEnabled('channels-phone-call'))
    ) {
      return true;
    } else {
      return false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::HeaderActions': typeof Inbox2ConversationHeaderActionsComponent;
    'inbox2/conversation-page/header-actions': typeof Inbox2ConversationHeaderActionsComponent;
  }
}
