/* import __COLOCATED_TEMPLATE__ from './team-assignee-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type NewConversation } from 'embercom/objects/inbox/conversation';
import type Conversation from 'embercom/objects/inbox/conversation';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type CommandKService from 'embercom/services/command-k';

interface Args {
  conversation: Conversation | NewConversation;
  onUpdate(team: TeamSummary): void;
}

interface Signature {
  Args: Args;
}

export default class Inbox2TeamAssigneeSelector extends Component<Signature> {
  @service declare commandK: CommandKService;

  @action
  onClick() {
    this.commandK.registerAndShow({
      actionID: 'assign-to',
      context: { excludeAdmins: true },
      onSelect: (team: TeamSummary) => {
        this.args.onUpdate(team);
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::TeamAssigneeSelector': typeof Inbox2TeamAssigneeSelector;
    'inbox2/conversation-details-sidebar/team-assignee-selector': typeof Inbox2TeamAssigneeSelector;
  }
}
