/* import __COLOCATED_TEMPLATE__ from './input.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type CommandKService from 'embercom/services/command-k';

interface Args {
  value: string | null;
  onInput: (value: string) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLInputElement;
}

export default class Input extends Component<Signature> {
  @service declare commandK: CommandKService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Input': typeof Input;
    'inbox2/command-k/input': typeof Input;
  }
}
