/* import __COLOCATED_TEMPLATE__ from './attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLElement;
  Args: {
    labelClass?: string;
    labelTooltipText?: string;
    label?: string;
    labelIntlKey?: string;
    class?: string;
    showPlaceholder?: boolean;
    valueClass?: string;
  };
  Blocks: {
    default: [];
  };
}

const Attribute = templateOnlyComponent<Signature>();
export default Attribute;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::Attribute': typeof Attribute;
    'inbox2/conversation-details-sidebar/attribute': typeof Attribute;
  }
}
