/* import __COLOCATED_TEMPLATE__ from './send-and-snooze-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const SendAndSnoozeItem = templateOnlyComponent<Signature>();
export default SendAndSnoozeItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::SendAndSnoozeItem': typeof SendAndSnoozeItem;
    'inbox2/composer/send-and-snooze-item': typeof SendAndSnoozeItem;
  }
}
