/* import __COLOCATED_TEMPLATE__ from './channel-switched.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type ChannelSwiched from 'embercom/objects/inbox/renderable/channel-switched';

interface ChannelSwichedPart extends RenderablePart {
  renderableData: ChannelSwiched;
}

interface Args {
  part: ChannelSwichedPart;
}

interface Signature {
  Args: Args;
}

export default class AttachmentsDroppedParta extends Component<Signature> {
  get channel() {
    switch (this.args.part.renderableData.channel) {
      case 'phone_switch':
        return 'phone';
      default:
        return 'whatsapp';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::ChannelSwitched': typeof AttachmentsDroppedParta;
    'inbox2/conversation-stream/event-parts/channel-switched': typeof AttachmentsDroppedParta;
  }
}
