/* import __COLOCATED_TEMPLATE__ from './call-summary.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type CallSummary from 'embercom/objects/inbox/renderable/call-summary';
import { capitalize } from '@ember/string';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { action } from '@ember/object';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import type InboxState from 'embercom/services/inbox-state';
import { tracked } from '@glimmer/tracking';
import type PhoneNumber from 'embercom/models/inbox/phone-number';
import type ArrayProxy from '@ember/array/proxy';
import type IntercomCallService from 'embercom/services/intercom-call-service';

interface CallSummaryRenderablePart extends RenderablePart {
  renderableData: CallSummary;
}

interface CallSummaryPartGroup extends PartGroup {
  parts: Array<CallSummaryRenderablePart>;
}

interface Args {
  partGroup: CallSummaryPartGroup;
  isReadOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamTeammateContentComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomCallService: IntercomCallService;
  @service declare twilioService: any;
  @service declare inboxState: InboxState;
  @tracked workspacePhoneNumbers?: ArrayProxy<PhoneNumber>;
  @tracked isActivelyListening = false;

  get app() {
    return this.session.workspace;
  }

  get callSummary() {
    return this.args.partGroup.parts[0].renderableData;
  }

  get admin() {
    return AdminSummary.deserialize(this.callSummary.admin);
  }

  get icon() {
    switch (this.callSummary.detailedState) {
      case 'routing':
        return 'workflows';
      case 'answered':
      case 'ended_by_workflow':
        return 'phone-end';
      case 'voicemail_left':
      case 'abandoned_in_voicemail':
      case 'abandoned_on_hold':
      case 'abandoned_in_queue':
      case 'abandoned_routing':
      case 'no_answer':
      case 'awaiting_callback':
      case 'answered_callback':
      case 'unanswered_callback':
      case 'closed_callback':
        return 'phone-missed';
      case 'on_hold':
        return 'phone-hold';
      default:
        return 'phone';
    }
  }

  get title() {
    if (
      this.callSummary.detailedState === 'awaiting_callback' ||
      this.callSummary.detailedState === 'answered_callback' ||
      this.callSummary.detailedState === 'unanswered_callback' ||
      this.callSummary.detailedState === 'closed_callback'
    ) {
      return this.subtitleStateString;
    }
    return this.intl.t('calling.call-summary.title', {
      call_type: capitalize(this.typeString),
      call_state: this.stateString,
    });
  }

  get subtitle() {
    if (this.callSummary.externallyTransferredTo) {
      if (
        this.callSummary.detailedState === 'answered' ||
        this.callSummary.detailedState === 'in_progress'
      ) {
        return this.intl.t('calling.call-summary.direct-transfer-to', {
          externalNumber: this.callSummary.externallyTransferredTo,
        });
      }
    }
    if (this.callSummary.detailedState === 'answered') {
      if (this.callSummary.duration) {
        return this.intl.formatDuration(this.callSummary.duration);
      }
    }
    if (this.callSummary.state === 'ended') {
      if (
        this.callSummary.detailedState === 'awaiting_callback' ||
        this.callSummary.detailedState === 'answered_callback' ||
        this.callSummary.detailedState === 'unanswered_callback' ||
        this.callSummary.detailedState === 'closed_callback'
      ) {
        return undefined;
      } else {
        return this.subtitleStateString;
      }
    }
    return undefined;
  }

  get typeString(): string {
    switch (this.callSummary.type) {
      case 'phone':
        if (this.callSummary.direction === 'outbound') {
          return this.intl.t('calling.call-summary.outbound-call');
        } else if (this.callSummary.direction === 'inbound') {
          if (this.callSummary.forwarded) {
            return this.intl.t('calling.call-summary.forwarded-call');
          } else {
            return this.intl.t('calling.call-summary.inbound-call');
          }
        }
        break;
      case 'messenger':
        return this.intl.t('calling.call-summary.messenger-call');
      default:
        return this.intl.t('calling.call-summary.call');
    }
    return this.intl.t('calling.call-summary.call');
  }

  get stateString(): string {
    switch (this.callSummary.detailedState) {
      case 'routing':
        return this.intl.t('calling.call-summary.detailed-state.routing');
      case 'in_queue':
        return this.intl.t('calling.call-summary.detailed-state.in-queue');
      case 'in_progress':
        return this.intl.t('calling.call-summary.detailed-state.in-progress');
      case 'on_hold':
        return this.intl.t('calling.call-summary.detailed-state.on-hold');
      case 'answered':
        return this.intl.t('calling.call-summary.detailed-state.answered');
      case 'ended_by_workflow':
        return this.intl.t('calling.call-summary.detailed-state.ended');
      case 'voicemail_left':
        return this.intl.t('calling.call-summary.detailed-state.ended');
      case 'abandoned_in_voicemail':
        return this.intl.t('calling.call-summary.detailed-state.ended');
      case 'abandoned_on_hold':
        return this.intl.t('calling.call-summary.detailed-state.ended');
      case 'abandoned_in_queue':
        return this.intl.t('calling.call-summary.detailed-state.ended');
      case 'abandoned_routing':
        return this.intl.t('calling.call-summary.detailed-state.ended');
      case 'initiated':
        return this.intl.t('calling.call-summary.detailed-state.initiated');
      case 'no_answer':
        return this.intl.t('calling.call-summary.detailed-state.ended');
      case 'in_csat_rating':
        return this.intl.t('calling.call-summary.detailed-state.in-csat');
      case 'awaiting_callback':
        return this.intl.t('calling.call-summary.detailed-state.awaiting-callback');
      case 'answered_callback':
        return this.intl.t('calling.call-summary.detailed-state.answered-callback');
      case 'unanswered_callback':
        return this.intl.t('calling.call-summary.detailed-state.unanswered-callback');
      case 'closed_callback':
        return this.intl.t('calling.call-summary.detailed-state.closed-callback');
      default:
        return this.intl.t('calling.call-summary.detailed-state.unknown');
    }
  }

  get subtitleStateString(): string {
    switch (this.callSummary.detailedState) {
      case 'routing':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.routing'));
      case 'in_queue':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.in-queue'));
      case 'in_progress':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.in-progress'));
      case 'on_hold':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.on-hold'));
      case 'answered':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.answered'));
      case 'ended_by_workflow':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.ended-by-workflow'));
      case 'voicemail_left':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.voicemail-left'));
      case 'abandoned_in_voicemail':
        return capitalize(
          this.intl.t('calling.call-summary.detailed-state.abandoned-in-voicemail'),
        );
      case 'abandoned_on_hold':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.abandoned-on-hold'));
      case 'abandoned_in_queue':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.abandoned-in-queue'));
      case 'abandoned_routing':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.abandoned-routing'));
      case 'initiated':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.initiated'));
      case 'no_answer':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.no-answer'));
      case 'in_csat_rating':
        return this.intl.t('calling.call-summary.detailed-state.in-csat');
      case 'awaiting_callback':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.awaiting-callback'));
      case 'answered_callback':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.answered-callback'));
      case 'unanswered_callback':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.unanswered-callback'));
      case 'closed_callback':
        return capitalize(this.intl.t('calling.call-summary.detailed-state.closed-callback'));
      default:
        return capitalize(this.intl.t('calling.call-summary.detailed-state.unknown'));
    }
  }

  get isActionableCallback() {
    if (this.callSummary.detailedState?.includes('callback')) {
      let detailState = this.callSummary.detailedState;
      return detailState === 'awaiting_callback' || detailState === 'unanswered_callback';
    }
    return false;
  }

  get isUnansweredCallback() {
    return this.callSummary.detailedState === 'unanswered_callback';
  }

  get hasCallRecording() {
    return (
      this.callSummary.isRecorded &&
      !this.callSummary.forwarded &&
      this.callSummary.answeredAt &&
      this.callSummary.endedAt &&
      this.callSummary.recordingName !== 'deleted'
    );
  }

  get hasVoicemail() {
    return (
      this.callSummary.deflectionType === 'voicemail' &&
      this.callSummary.detailedState === 'voicemail_left'
    );
  }

  get showRecording() {
    return this.hasCallRecording || this.hasVoicemail;
  }

  get canListenToCall() {
    if (this.callSummary.type !== 'phone') {
      return false;
    }
    if (!this.callSummary.callListeningEnabled) {
      return false;
    }

    let hasPermission = this.session.teammate?.permissions?.canListenAndBargeOnCalls || false;
    let hasBeenExternallyTransferred = !!this.callSummary.externallyTransferredTo;

    this.validateWhetherCallIsAlreadyBeingListenedTo();

    return (
      this.callSummary.detailedState === 'in_progress' &&
      !hasBeenExternallyTransferred &&
      hasPermission &&
      !this.isActivelyListening
    );
  }

  get recordingAndTranscriptStateString() {
    if (this.hasCallRecording) {
      if (this.callSummary.recordingUrl) {
        return this.intl.t('calling.call-summary.recording-transcript-available');
      } else if (this.callSummary.recordingName !== 'deleted') {
        return this.intl.t('calling.call-summary.recording-transcript-processing');
      }
    } else if (this.hasVoicemail) {
      if (this.callSummary.recordingUrl) {
        return this.intl.t('calling.call-summary.voicemail-available');
      } else if (this.callSummary.recordingName !== 'deleted') {
        return this.intl.t('calling.call-summary.voicemail-processing');
      }
    }
    return undefined;
  }

  get showCsatRating() {
    return this.callSummary.csatRating !== null && this.callSummary.csatRating !== 'N/A';
  }

  @action validateWhetherCallIsAlreadyBeingListenedTo() {
    this.isActivelyListening = this.intercomCallService.listeningCallId === this.callSummary.id;
  }

  @action async acceptCallback(event: Event) {
    event.stopPropagation();
    await this.twilioService.acceptCallback(this.inboxState.activeConversationId);
  }

  @action async closeCallback(event: Event) {
    event.stopPropagation();
    await this.twilioService.closeCallback(this.inboxState.activeConversationId);
  }

  @action openCallDetailsSideBar() {
    this.inboxSidebarService.toggleIsViewingPhoneDetails(this.callSummary);
  }

  @action async startListening() {
    this.isActivelyListening = true;

    await this.intercomCallService.startListeningToCallInProgess(this.callSummary.id);
  }

  @action async stopListening() {
    this.isActivelyListening = false;

    await this.intercomCallService.stopListeningToCallInProgress(this.callSummary.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::CallSummary': typeof ConversationStreamTeammateContentComponent;
    'inbox2/conversation-stream/part-groups/call-summary': typeof ConversationStreamTeammateContentComponent;
  }
}
