/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { aggregationText } from 'embercom/helpers/aggregation-text';
import type Inbox2OverviewService from 'embercom/services/inbox2-overview-service';
import type Session from 'embercom/services/session';
import type DashboardSettingsService from 'embercom/services/dashboard-settings-service';
import type DashboardSettings from 'embercom/objects/inbox/dashboard-settings';
import type Inbox2TeammateActivityService from 'embercom/services/inbox2-teammate-activity-service';
import type IntlService from 'embercom/services/intl';

interface Args {
  settings: DashboardSettings;
}

interface Signature {
  Args: Args;
}

export default class Settings extends Component<Signature> {
  @service declare inbox2OverviewService: Inbox2OverviewService;
  @service declare dashboardSettingsService: DashboardSettingsService;
  @service declare inbox2TeammateActivityService: Inbox2TeammateActivityService;
  @service declare session: Session;
  @service declare intl: IntlService;

  @tracked showModal = false;

  @tracked selectedAggregation: string = this.args.settings.aggregationType;
  @tracked selectedAggregationText: string = this.args.settings.aggregationTypeText;
  @tracked selectedIdleThreshold: number = this.args.settings.idleThreshold;
  @tracked idleThresholdText: string = this.args.settings.idleThresholdText;
  @tracked excludeBotTime: boolean = this.args.settings.excludeBotTime;

  get settings() {
    return this.args.settings;
  }

  get availableAggregations() {
    let items = ['avg', 'max', 'min', 'median'].map((label) => ({
      label,
      text: this.intl.t(`inbox.dashboard.settings.aggregation-type.${label}`),
      isSelected: label === this.selectedAggregation,
    }));

    return [
      {
        hasItemsMarkableAsSelected: true,
        items,
      },
    ];
  }

  @action updateAggregation({ label }: any) {
    this.selectedAggregationText = aggregationText(label);
    this.selectedAggregation = label;
  }

  @action updateIdleThreshold(newIdleThreshold: number) {
    this.selectedIdleThreshold = newIdleThreshold;
  }

  @action toggleExcludeBotTime() {
    this.excludeBotTime = !this.excludeBotTime;
  }

  @action openModal() {
    this.showModal = true;
  }

  @action closeModal() {
    this.showModal = false;
  }

  @action cancel() {
    this.selectedAggregation = this.settings.aggregationType;
    this.selectedIdleThreshold = this.settings.idleThreshold;
    this.excludeBotTime = this.settings.excludeBotTime;

    this.closeModal();
  }

  @action save() {
    this.settings.aggregationType = this.selectedAggregation;
    this.settings.aggregationTypeText = this.selectedAggregationText;
    this.settings.idleThreshold = this.selectedIdleThreshold;
    this.settings.excludeBotTime = this.excludeBotTime;

    this.dashboardSettingsService.updateDashboardSettings(this.settings);
    let options = { hasChangedSettings: true };
    this.inbox2OverviewService.doForceRefresh(options);
    this.inbox2TeammateActivityService.doForceRefresh(options);

    this.closeModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Settings': typeof Settings;
    'inbox2/dashboard/settings': typeof Settings;
  }
}
