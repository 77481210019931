/* import __COLOCATED_TEMPLATE__ from './admin-comment.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    teammate?: AdminSummary;
  };
}

const AdminComment = templateOnlyComponent<Signature>();
export default AdminComment;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::TypingIndicators::AdminComment': typeof AdminComment;
    'inbox2/conversation-stream/typing-indicators/admin-comment': typeof AdminComment;
  }
}
