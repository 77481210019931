/* import __COLOCATED_TEMPLATE__ from './input.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  onEnterKey: (newNoteText: any) => {};
}

interface Signature {
  Args: Args;
}

export default class Input extends Component<Signature> {
  @action onKeyDown(event: KeyboardEvent) {
    let target = event.target as HTMLTextAreaElement;

    if (event.key === 'Enter' && !event.shiftKey && target.value.trim() !== '') {
      this.args.onEnterKey(target.value);
      target.value = '';
      target.dispatchEvent(new Event('change'));
      event.preventDefault();
    }
  }

  @action onBlur(event: Event) {
    let target = event.target as HTMLTextAreaElement;
    if (target.value.trim() !== '') {
      this.args.onEnterKey(target.value);
      target.value = '';
      target.dispatchEvent(new Event('change'));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Notes::Input': typeof Input;
    'inbox2/conversation-details-sidebar/sidebar-sections/notes/input': typeof Input;
  }
}
