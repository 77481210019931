/* import __COLOCATED_TEMPLATE__ from './event.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Event from 'embercom/objects/inbox/event';
import { helper } from '@ember/component/helper';
import { isObject } from 'underscore';
import moment from 'moment-timezone';

interface Args {
  event: Event;
}

interface Signature {
  Args: Args;
}

function isPotentialUrl(value: unknown): boolean {
  if (typeof value === 'string') {
    return /^\s*https?:\/\/\S*/.test(value);
  }

  return false;
}

export default class EventComponent extends Component<Signature> {
  @tracked displayMetadata = false;

  readonly transformMetadata = helper(function ([key, value]: [
    string,
    ValueOf<Event['metadata']>,
  ]) {
    if (isObject(value) && 'value' in value && 'url' in value) {
      return { type: 'rich-link' as const, key, value: value.value, url: value.url };
    } else if (isPotentialUrl(value)) {
      return { type: 'url' as const, key, value };
    } else if (/_date$/.test(key)) {
      return { type: 'date' as const, key, value };
    } else {
      return { type: 'primitive' as const, key, value };
    }
  });

  readonly getDate = helper(function ([timestamp]: [string]) {
    return moment.unix(Number(timestamp)).toDate();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::RecentEvents::Event': typeof EventComponent;
    'inbox2/conversation-details-sidebar/sidebar-sections/recent-events/event': typeof EventComponent;
  }
}
