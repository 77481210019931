/* import __COLOCATED_TEMPLATE__ from './latest-conversations.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { type NewConversation } from 'embercom/objects/inbox/conversation';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState } from 'embercom/objects/inbox/conversation';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type InboxApi from 'embercom/services/inbox-api';
import type LatestConversationSummary from 'embercom/objects/inbox/latest-conversation-summary';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { type SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import type ApplicationInstance from '@ember/application/instance';
import { type UpdateMessage } from 'embercom/services/conversation-updates';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import { helper } from '@ember/component/helper';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import type Session from 'embercom/services/session';
import type InboxState from 'embercom/services/inbox-state';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Router from '@ember/routing/router-service';

interface Args {
  conversation: Conversation | NewConversation;
  sidebarSection: SidebarSection;
  activeInbox?: Inbox;
  userId: string;
  previewOnly?: boolean;
  onExpandedSectionChange: (sectionId: SupportedSidebarSection | number | undefined) => void;
  onConversationOrChecklistSelected?: () => void;
  isPreviewingConversation?: boolean;
}

interface Signature {
  Args: SidebarSectionComponentArgs<Args>;
}

export type LatestConversation = LatestConversationSummary & {
  summaryPart?: RenderablePart;
  stateStyling: string;
};

export default class LatestConversations extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare inboxApi: InboxApi;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare router: Router;

  constructor(owner: ApplicationInstance, args: SidebarSectionComponentArgs<Args>) {
    super(owner, args);
    this.conversationUpdates.subscribe(this.updateConversations);
  }

  willDestroy(): void {
    super.willDestroy();
    this.conversationUpdates.unsubscribe(this.updateConversations);
  }

  get latestConversations() {
    return this.sortLatestConversations(this.latestConversationsLoader.value ?? []);
  }

  get latestOpenConversations() {
    return this.latestConversations.filter(
      (conversation) => conversation.state === ConversationState.Open,
    );
  }

  get limitedLatestConversations() {
    return this.latestConversations.slice(0, 3);
  }

  @action handleConversationClick(conversation: LatestConversationSummary) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      section: 'inbox',
      object: 'recent_conversations',
      inbox_type: this.args.activeInbox?.type,
      conversation_id: conversation.id,
    });

    if (this.args.previewOnly) {
      let inbox = this.inboxState.activeInbox || {
        id: InboxType.All,
        category: InboxCategory.Shared,
      };

      let url: string = this.router.urlFor(
        'inbox.workspace.inbox.inbox.conversation.conversation',
        inbox.category,
        inbox.id,
        conversation.id,
      );
      safeWindowOpen(url);
    } else {
      this.inboxSidebarService.previewConversation(conversation);

      this.args.onConversationOrChecklistSelected && this.args.onConversationOrChecklistSelected();
    }

    return;
  }

  @action handleTicketStateUpdatedEvent() {
    this.latestConversationsLoader.reload();
  }

  getSummaryPart = helper(function ([conversation]: [LatestConversationSummary]):
    | RenderablePart
    | undefined {
    return conversation.state === ConversationState.Closed
      ? conversation.firstUserPart
      : conversation.lastRenderableSummaryPart;
  });

  @action
  private updateConversations(updates: UpdateMessage[]) {
    updates.forEach((update) => {
      let conversation = this.latestConversations.find((c) => c.id === update.conversationId);
      if (!conversation) {
        return;
      }

      if (update.type === 'added') {
        update.entries.forEach((update) => update.apply(conversation!));
      } else if (update.type === 'removed') {
        update.entries.forEach((update) => update.rollback(conversation!));
      }
    });
  }

  latestConversationsLoader = DeduplicatedAsyncData(
    this,
    () => [this.args.userId, this.args.conversation.id],
    async (userID, conversationID, { signal }) => {
      let { conversations } = await this.inboxApi.getUserConversations(userID, conversationID, {
        signal,
      });
      return conversations;
    },
  );

  private sortLatestConversations(
    conversations: LatestConversationSummary[],
  ): LatestConversationSummary[] {
    return [...conversations].sort(
      (conv1: LatestConversationSummary, conv2: LatestConversationSummary) => {
        if (conv1.state === conv2.state) {
          if (conv1.lastUpdated < conv2.lastUpdated) {
            return 1;
          } else if (conv1.lastUpdated > conv2.lastUpdated) {
            return -1;
          } else {
            return 0;
          }
        } else {
          return conv1.state === ConversationState.Open ? -1 : 1;
        }
      },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::LatestConversations': typeof LatestConversations;
    'inbox2/conversation-details-sidebar/sidebar-sections/latest-conversations': typeof LatestConversations;
  }
}
