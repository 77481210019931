/* import __COLOCATED_TEMPLATE__ from './create.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { type TagItemBaseAction } from 'embercom/objects/inbox/command-k/tag-item';

interface Signature {
  Args: {
    actionItem: ChildAction;
    currentAction: TagItemBaseAction;
  };
}

const Create = templateOnlyComponent<Signature>();
export default Create;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::TagItem::Create': typeof Create;
    'inbox2/command-k/tag-item/create': typeof Create;
  }
}
