/* import __COLOCATED_TEMPLATE__ from './tabs.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const Tabs = templateOnlyComponent<Signature>();
export default Tabs;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Shopify::Tabs': typeof Tabs;
    'inbox2/conversation-details-sidebar/sidebar-sections/shopify/tabs': typeof Tabs;
  }
}
