/* import __COLOCATED_TEMPLATE__ from './participant-blocked.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type ParticipantBlocked from 'embercom/objects/inbox/renderable/participant-blocked';

interface ParticipantBlockedPart extends RenderablePart {
  renderableData: ParticipantBlocked;
}

interface Args {
  part: ParticipantBlockedPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsParticipantBlockedPartComponent extends Component<Signature> {
  get renderableData() {
    return this.args.part.renderableData;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::ParticipantBlocked': typeof Inbox2ConversationStreamEventPartsParticipantBlockedPartComponent;
    'inbox2/conversation-stream/event-parts/participant-blocked': typeof Inbox2ConversationStreamEventPartsParticipantBlockedPartComponent;
  }
}
