/* import __COLOCATED_TEMPLATE__ from './chat.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from 'tracked-built-ins';
import { type BlockList } from '@intercom/interblocks.ts';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import { post } from 'embercom/lib/ajax';
import generateUUID from 'embercom/lib/uuid-generator';
import type Nexus from 'embercom/services/nexus';
import { ConversationPartTokenPartType } from 'embercom/services/nexus';
import type IntlService from 'embercom/services/intl';
import { ResponseError, request } from 'embercom/lib/inbox/requests';
import {
  type FinSource,
  type InboxAssistantConversationPart,
  InboxAssistantConversation,
  InboxAssistantConversationPartStatus,
  sourcesType,
  type InboxAssistantConversationWireFormat,
} from 'embercom/objects/inbox/inbox-assistant-conversation';
import type InboxState from 'embercom/services/inbox-state';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import { assertExists } from 'embercom/lib/assertions';
import type CopilotApi from 'embercom/services/copilot-api';
import { InteractionEventType } from 'embercom/services/copilot-api';
import { blocksToText } from 'embercom/lib/open-ai-prompt';
import { type ConversationPartTokenNexusEvent } from 'embercom/lib/inbox2/fin-chat-types';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import {
  type CopilotBaseAnalyticsData,
  AnswerStreamingStatus,
  type CopilotChatMessageAnalyticsData,
  type NormalizedFinSource,
  CopilotLoadingError,
  type CopilotSourceTypes,
} from 'embercom/lib/inbox2/copilot/types';
import { EntityType } from 'embercom/models/data/entity-types';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ComposerPaneType } from 'embercom/objects/inbox/composer-pane';
import { type PublicAPI } from '../conversation-reply-composer';
import type TracingService from 'embercom/services/tracing';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import Em from 'ember';

export interface CopilotChatArgs {
  insertBlocks: (blocks: BlockList, conversationId?: number, actions?: MacroAction[]) => void;
  openSourcePreview: (content: FinSource, analyticsData: CopilotChatMessageAnalyticsData) => void;
  isCollapsed?: boolean;
  conversationAnalyticsData: CopilotBaseAnalyticsData;
  activeInboxConversation?: Conversation;
  firstConversationParticipant?: UserSummary;
  isHeaderVisible?: boolean;
  insertNoteBlocks: (blocks: BlockList) => void;
  composerApi: PublicAPI;
  isCopilotVisible: boolean;
}

interface Signature {
  Args: CopilotChatArgs;
  Element: HTMLDivElement;
}

export default class CopilotChat extends Component<Signature> {
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;
  @service declare copilotApi: CopilotApi;
  @service declare nexus: Nexus;
  @service declare tracing: TracingService;

  @tracked canAutoScroll = true;
  @tracked inputValue = '';
  @tracked searchUUID = '';
  @tracked searchSessionId?: string;
  @tracked answerBlocks: BlockList = [];
  @tracked currentTokenSequenceIndex = 0;
  @tracked answerRejected = false;
  @tracked answerStreamingStatus: AnswerStreamingStatus | undefined;
  @tracked isCreatingConversation = false;
  @tracked transactionId?: string;
  @tracked sourceTypeFilters: CopilotSourceTypes[] = this.defaultSourceTypeFilters;
  @tracked canShowVerifyAnswerTooltip = false;
  @tracked shouldRenderRequestToUpgradeModal = false;

  @tracked isAttemptingFallback = false;

  readonly defaultInbox = { id: 'all', category: 'shared' };
  readonly ComposerPaneType = ComposerPaneType;

  constructor(owner: unknown, args: CopilotChatArgs) {
    super(owner, args);
    this.instrumentCopilotOpen();
  }

  /* For Analytics */
  requestStartTime?: Date;
  requestEndTime?: Date;
  firstTokenTime?: Date;

  get isCollapsed() {
    return this.args.isCollapsed ?? false;
  }

  get isHeaderVisible() {
    return this.args.isHeaderVisible ?? true;
  }

  get chatAnalyticsData() {
    return {
      ...this.args.conversationAnalyticsData,
      ...this.copilotApi.betaAnalyticsData,
      inbox_assistant_conversation_id: this.conversation?.id,
      search_session_id: this.searchSessionId,
    };
  }

  get showVerifyAnswerTooltip(): boolean {
    return (
      this.canShowVerifyAnswerTooltip &&
      this.conversation?.successfulAnswers.length === 1 &&
      this.answerStreamingStatus === AnswerStreamingStatus.FINISHED
    );
  }

  messageAnalyticsData = (
    message?: InboxAssistantConversationPart,
  ): CopilotChatMessageAnalyticsData => {
    let transactionId = message?.isPersisted ? message.transactionId : this.transactionId;

    return {
      section: 'copilot_chat',
      ...this.chatAnalyticsData,
      search_uuid: this.searchUUID,
      answer_bot_transaction_id: transactionId,
    };
  };

  get shouldDisableComposerSendButton() {
    return (
      !this.inputValue ||
      this.answerIsSending ||
      this.answerIsStreaming ||
      this.isStreamingRelatedContent ||
      this.isCreatingConversation
    );
  }

  get conversationIsEmpty() {
    return (this.conversation?.parts?.length || 0) === 0;
  }

  get isStreamingRelatedContent() {
    return this.answerStreamingStatus === AnswerStreamingStatus.STREAMING_RELATED_CONTENT;
  }

  get secondsForFirstToken(): number | undefined {
    if (this.requestStartTime && this.firstTokenTime) {
      return (+this.firstTokenTime - +this.requestStartTime) / 1000;
    }
    return undefined;
  }

  get secondsForStreamResolution() {
    if (this.requestEndTime && this.requestStartTime) {
      return (+this.requestEndTime - +this.requestStartTime) / 1000;
    }
    return undefined;
  }

  get isBillingAdmin() {
    return this.session.teammate.permissions.canAccessBillingSettings;
  }

  get isCopilotLimited() {
    return (
      this.session.workspace.isFeatureEnabled('fin-ai-copilot-addon-available') &&
      this.plan?.limited
    );
  }

  get remainingFreeCreditUsage() {
    return this.plan?.balance.credits;
  }

  get remainingFreeTurnUsage() {
    return this.plan?.balance.turns;
  }

  // We enable the composer tooltip:
  // 1. If the user is on a limited usage and they have no more credits when it's new the conversation
  // 2. If the user is on a limited usage and they have no more turns
  get reasonToDisableComposer() {
    if (!this.isCopilotLimited) {
      return undefined;
    }

    if (this.remainingFreeCreditUsage === undefined || this.remainingFreeTurnUsage === undefined) {
      return undefined;
    }

    if (this.remainingFreeCreditUsage <= 0 && !this.conversation?.parts?.length) {
      return 'no-credit';
    }

    if (this.remainingFreeTurnUsage <= 0) {
      return 'no-turn';
    }

    return undefined;
  }

  get firstFinMessageIndex(): number {
    if (!this.conversation) {
      return -1;
    }
    for (let i = 0; i < this.conversation.parts.length; i++) {
      if (this.conversation.parts[i].isFromAssistant) {
        return i;
      }
    }
    return -1;
  }

  @action openRequestToUpgradeModal() {
    this.shouldRenderRequestToUpgradeModal = true;
  }

  @action closeRequestToUpgradeModal() {
    this.shouldRenderRequestToUpgradeModal = false;
  }

  @action handleOutOfFreeUsages() {
    this.latestConversationLoader.update({
      conversation: this.conversation,
      plan: {
        limited: true,
        balance: { credits: 0, turns: 0 },
      },
    });
  }

  @action setSourceTypeFilters(selection: CopilotSourceTypes[]) {
    this.sourceTypeFilters = selection;
  }

  @action instrumentCopilotOpen() {
    if (!this.args.isCopilotVisible) {
      return;
    }
    this.searchSessionId = generateUUID();

    this.intercomEventService.trackAnalyticsEvent({
      object: 'sidebar',
      action: 'opened',
      section: 'copilot_chat',
      prefilled_question: this.inputValue,
      ...this.chatAnalyticsData,
    });
  }

  @action instrumentAnswerInsertion(
    answerType: ComposerPaneType,
    message: InboxAssistantConversationPart,
  ) {
    let answer_type;
    if (answerType === ComposerPaneType.Reply) {
      answer_type = 'reply';
    } else if (answerType === ComposerPaneType.Note) {
      answer_type = 'note';
    }

    this.intercomEventService.trackAnalyticsEvent({
      object: 'answer',
      action: 'inserted',
      text: blocksToText(this.answerBlocks),
      time_for_first_token: this.secondsForFirstToken,
      time_for_stream_resolution: this.secondsForStreamResolution,
      inbox_assistant_part_id: message.persistedId,
      answer_type,
      macro_id: message.isMacroAnswer ? message.metadata?.macro_id : null,
      ...this.messageAnalyticsData(message),
    });

    this.copilotApi.conversationPartTrackInteractionEvent(
      message,
      InteractionEventType.AddedToComposer,
    );
  }

  @action instrumentSourceInsertion(
    source: NormalizedFinSource,
    message: InboxAssistantConversationPart,
  ) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'source',
      action: 'inserted',
      text: source.data.body,
      ...this.messageAnalyticsData(message),
    });
  }

  @action instrumentStreamedSourceClick(
    source: FinSource,
    message: InboxAssistantConversationPart,
  ) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'answer',
      action: 'link_clicked',
      entity_id: source.entity_id,
      entity_type: source.entity_type,
      inbox_assistant_part_id: message.persistedId,
      ...this.messageAnalyticsData(message),
    });
  }

  @action instrumentAnswerCopy(message: InboxAssistantConversationPart) {
    if (!message.isFromAssistant) {
      return;
    }

    let selection = document.getSelection()?.toString();
    // The 'copy' event seems to get triggred if you select, then unselect, then copy
    // So we need to ignore these events if there isn't any text currently selected
    if (selection) {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'answer',
        action: 'manually_copied',
        text: selection,
        stream_complete: this.answerStreamingStatus,
        time_for_first_token: this.secondsForFirstToken,
        time_for_stream_resolution: this.secondsForStreamResolution,
        inbox_assistant_part_id: message.persistedId,
        macro_id: message.isMacroAnswer ? message.metadata?.macro_id : null,
        ...this.messageAnalyticsData(message),
      });
    }

    this.copilotApi.conversationPartTrackInteractionEvent(message, InteractionEventType.Copied);
  }

  @action instrumentPreviewCopy(source: FinSource, message: InboxAssistantConversationPart) {
    let selection = document.getSelection()?.toString();
    // The 'copy' event seems to get triggred if you select, then unselect, then copy
    // So we need to ignore these events if there isn't any text currently selected
    if (selection) {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'source_preview',
        action: 'manually_copied',
        entity_id: source.entity_id,
        entity_type: source.entity_type,
        inbox_assistant_part_id: message.persistedId,
        text: selection,
        ...this.messageAnalyticsData(message),
      });
    }
  }

  @action instrumentRelatedContentPreviewCopy(message: InboxAssistantConversationPart) {
    let selection = document.getSelection()?.toString();
    // The 'copy' event seems to get triggred if you select, then unselect, then copy
    // So we need to ignore these events if there isn't any text currently selected
    if (selection) {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'related_content_preview',
        action: 'manually_copied',
        text: selection,
        ...this.messageAnalyticsData(message),
      });
    }
  }

  @action instrumentRelatedContentClick(
    source: FinSource,
    message: InboxAssistantConversationPart,
  ) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'answer',
      action: 'related_content_link_clicked',
      entity_id: source.entity_id,
      entity_type: source.entity_type,
      inbox_assistant_part_id: message.persistedId,
      ...this.messageAnalyticsData(message),
    });
  }

  /* Actions */
  @action updateText(event: InputEvent & { target: HTMLTextAreaElement }) {
    this.inputValue = event.target.value;
  }

  @action updateComposerText(text: string) {
    this.inputValue = text;
  }

  @action async sendMessage() {
    await this._sendMessage(this.inputValue, { clearComposerAfterSend: true });
  }

  @action async sendMessageBypassingComposer(message: string) {
    await this._sendMessage(message, { clearComposerAfterSend: false });
  }

  @action async _sendMessage(
    message: string,
    { clearComposerAfterSend = true }: { clearComposerAfterSend: boolean },
  ) {
    if (
      this.answerIsSending ||
      this.isStreamingRelatedContent ||
      this.answerIsStreaming ||
      this.isCreatingConversation
    ) {
      return;
    }

    if (!message || message.trim() === '') {
      return;
    }

    if (this.reasonToDisableComposer) {
      return;
    }

    this.tracing.startRootSpan({
      name: 'copilot.sendMessage',
      resource: 'copilot',
    });

    this.searchUUID = generateUUID();
    // If there's no conversation, we need to create one
    if (!this.conversation) {
      await this.createConversation();
    }

    assertExists(this.conversation);

    this.intercomEventService.trackAnalyticsEvent({
      object: 'message',
      action: 'sent',
      query: message,
      filtersChanged:
        this.sourceTypeFilters.toString() !== this.defaultSourceTypeFilters.toString(),
      filters: this.sourceTypeFilters,
      connected_to_nexus: this.nexus.isConnected,
      ...this.messageAnalyticsData(),
    });
    this.answerStreamingStatus = AnswerStreamingStatus.SENDING;

    this.requestStartTime = new Date();
    this.requestEndTime = undefined;
    this.firstTokenTime = undefined;

    let handlebarsUtil = Em.Handlebars as any;
    let escapedQueryBlocks = [
      {
        type: 'paragraph',
        text: handlebarsUtil.Utils.escapeExpression(message),
      },
    ];

    this.conversation.addTeammatePart(this.session.teammate, escapedQueryBlocks);
    if (clearComposerAfterSend) {
      this.inputValue = '';
    }
    this.setCanAutoScroll(true);
    try {
      let result = await this.copilotApi.createTeammateReply(
        this.conversation.id,
        this.inboxState.activeConversationId,
        this.searchUUID,
        escapedQueryBlocks,
        this.sourceTypeFilters,
      );

      this.maybeShowVerifyAnswerTooltip(result.conversation);

      this.latestConversationLoader.update({
        conversation: result.conversation,
        plan: result.plan,
      });
      this.transactionId = result.answer_bot_transaction_id;
    } catch (error) {
      // teammateReply returning 405 indicates that the user tried to send a message when they shouldn't bypassing the UI in unusual ways
      // here we set the usage balance to 0 to prevent further usage at the UI level
      if (error instanceof ResponseError && error.response.status === 405) {
        this.handleOutOfFreeUsages();
      } else {
        throw error;
      }

      this.tracing.tagRootSpan({ copilot_error: true, copilot_error_message: error.message });
    } finally {
      this.requestEndTime = new Date();

      this.isAttemptingFallback = false;
      this.answerStreamingStatus = AnswerStreamingStatus.FINISHED;
      this.searchUUID = '';

      this.instrumentStreamResolved(this.conversation?.lastPart.isSuccess ?? false);

      this.tracing.tagRootSpan({
        inbox_conversation_id: this.args.activeInboxConversation?.id,
        copilot_conversation_id: this.conversation.id,
        copilot_resolved: true,
        seconds_for_stream_resolution: this.secondsForStreamResolution,
        ...this.conversation.lastPart.flattenedAbTestAssignments,
      });
    }
  }

  get answerIsSending() {
    return this.answerStreamingStatus === AnswerStreamingStatus.SENDING;
  }

  get answerIsStreaming() {
    return this.answerStreamingStatus === AnswerStreamingStatus.STREAMING_ANSWER;
  }

  @action
  async handleStreamingResponse(e: ConversationPartTokenNexusEvent) {
    assertExists(this.conversation);

    this.answerStreamingStatus =
      e.eventData.part_type === ConversationPartTokenPartType.RelatedContent
        ? AnswerStreamingStatus.STREAMING_RELATED_CONTENT
        : AnswerStreamingStatus.STREAMING_ANSWER;

    if (e.eventData.client_assigned_uuid !== this.searchUUID) {
      return;
    }

    if (!this.firstTokenTime) {
      this.firstTokenTime = new Date();
      this.tracing.tagRootSpan({ seconds_for_first_token: this.secondsForFirstToken });

      this.intercomEventService.trackAnalyticsEvent({
        object: 'search',
        action: 'first_token_received',
        seconds_for_first_token: this.secondsForFirstToken,
        answer_bot_transaction_id: e.eventData.answerbot_transaction_id,
        ...this.messageAnalyticsData(),
      });
    }

    if (e.eventData.part_type === ConversationPartTokenPartType.RelatedContent) {
      this.tracing.addEvent('copilot.relatedContentReceived');

      let relatedContent = e.eventData.related_content;

      this.intercomEventService.trackAnalyticsEvent({
        object: 'search',
        action: 'results_returned',
        query: this.inputValue,
        results_count: relatedContent.length,
        ...this.messageAnalyticsData(),
        answer_bot_transaction_id: e.eventData.answerbot_transaction_id,
      });

      let { sources } = await this.copilotApi.expandSources(relatedContent);

      let streamingPart = this.conversation.findOrCreateAssistantPart(this.searchUUID);
      streamingPart.content = [];
      streamingPart.status = InboxAssistantConversationPartStatus.success;
      streamingPart.relatedContent = sources;

      return;
    }

    if (e.eventData.part_type === ConversationPartTokenPartType.FallbackRelatedContent) {
      let relatedContent = e.eventData.related_content;

      this.tracing.addEvent('copilot.fallbackRelatedContentReceived');

      this.intercomEventService.trackAnalyticsEvent({
        object: 'search',
        action: 'fallback_results_returned',
        query: this.inputValue,
        results_count: relatedContent.length,
        ...this.messageAnalyticsData(),
        answer_bot_transaction_id: e.eventData.answerbot_transaction_id,
      });

      let streamingPart = this.conversation.findOrCreateAssistantPart(this.searchUUID);
      streamingPart.fallbackQuestion = e.eventData.query;

      let { sources } = await this.copilotApi.expandSources(relatedContent);
      streamingPart.fallbackRelatedContent = sources;
      return;
    }

    if (e.eventData.part_type === ConversationPartTokenPartType.NoAnswer) {
      let streamingPart = this.conversation.findOrCreateAssistantPart(this.searchUUID);
      streamingPart.status = InboxAssistantConversationPartStatus.could_not_find_answer;
      if (e.eventData.is_attempting_fallback) {
        this.isAttemptingFallback = true;
      }
      return;
    }

    if (e.eventData.part_type !== ConversationPartTokenPartType.Partial) {
      this.tracing.addEvent('copilot.unexpectedTokenReceived');

      this.answerStreamingStatus = AnswerStreamingStatus.FINISHED;
      throw new Error(`Unexpected part type: ${e.eventData.part_type}`);
    }

    this.tracing.addEvent('copilot.partialTokenReceived', {
      token_sequence_index: e.eventData.token_sequence_index,
      blocks_count: e.eventData.blocks.length,
    });

    this.answerStreamingStatus = AnswerStreamingStatus.STREAMING_ANSWER;

    this.currentTokenSequenceIndex = e.eventData.token_sequence_index;
    this.answerBlocks = e.eventData.blocks;

    let streamingPart = this.conversation.findOrCreateAssistantPart(this.searchUUID);
    if (streamingPart.status === InboxAssistantConversationPartStatus.could_not_find_answer) {
      if (this.isAttemptingFallback) {
        // we've started receiving the fallback answer, so hide the loading state
        this.isAttemptingFallback = false;
      }
      streamingPart.fallbackContent = this.answerBlocks;
    } else {
      streamingPart.content = this.answerBlocks;
    }
    streamingPart.sourcesType = sourcesType(e.eventData.sources);
  }

  get conversation() {
    return this.latestConversationLoader.value?.conversation;
  }

  get plan() {
    return this.latestConversationLoader.value?.plan;
  }

  get conversationPermissionError() {
    return (
      this.latestConversationLoader.value?.error ===
      CopilotLoadingError.MissingAllConversationAccess
    );
  }

  private latestConversationLoader = DeduplicatedAsyncData(
    this,
    () => [this.args.activeInboxConversation?.id],
    async (conversationId) => {
      try {
        let response = await request(
          `/ember/inbox/inbox_assistant_conversations/${conversationId}/latest_for_conversation?app_id=${this.session.workspace.id}`,
        );

        let json = (await response.json()) as {
          conversation: InboxAssistantConversationWireFormat;
          plan: { limited: boolean; balance: { credits: number; turns: number } };
        };

        let conversation = json.conversation
          ? InboxAssistantConversation.deserialize(json.conversation)
          : null;

        return { conversation, plan: json.plan };
      } catch (e) {
        if (e instanceof ResponseError && e.response.status === 404) {
          return;
        } else if (e instanceof ResponseError && e.response.status === 405) {
          // 405 indicates that the user run out of credits when it's new the conversation
          // hence we set the usage to the default values to block further usage
          return {
            conversation: null,
            plan: {
              limited: true,
              balance: { credits: 0, turns: 0 },
            },
          };
        } else if (e instanceof ResponseError && e.response.status === 403) {
          if (await missingAllConversationAccess(e)) {
            return {
              conversation: null,
              plan: null,
              error: CopilotLoadingError.MissingAllConversationAccess,
            };
          }
        }
        throw e;
      }
    },
  );

  @action async createConversation() {
    this.isCreatingConversation = true;

    let url = `/ember/inbox/inbox_assistant_conversations?app_id=${this.session.workspace.id}`;
    if (this.args.activeInboxConversation?.id) {
      url += `&inbox_conversation_id=${this.args.activeInboxConversation?.id}`;
    }

    try {
      let json = await post(url);

      let conversation = InboxAssistantConversation.deserialize(json.conversation);

      this.tracing.tagRootSpan({ copilot_created_conversation: true });

      this.latestConversationLoader.update({
        conversation,
        plan: this.plan,
      });
    } finally {
      this.isCreatingConversation = false;
    }
  }

  @action async prefillAndSearch() {
    if (!this.copilotApi.prefilledSearchQuery) {
      return;
    }
    // Wait until the conversation has loaded
    await this.latestConversationLoader.promise;
    this.sendMessageBypassingComposer(this.copilotApi.prefilledSearchQuery);
    this.copilotApi.resetPrefilledSearchQuery();
  }

  effect = (fn: (...args: unknown[]) => void) => {
    fn();
  };

  @action setCanAutoScroll(canAutoScroll: boolean) {
    this.canAutoScroll = canAutoScroll;
  }

  get allSourceTypeFilters(): CopilotSourceTypes[] {
    let filters: CopilotSourceTypes[] = [
      EntityType.InternalArticle,
      EntityType.SavedReply,
      EntityType.ConversationExcerpt,
      EntityType.FileSourceContent,
      EntityType.ArticleContent,
      EntityType.ContentSnippet,
      EntityType.ExternalContent,
    ];

    return filters;
  }

  get defaultSourceTypeFilters(): CopilotSourceTypes[] {
    let filters = this.allSourceTypeFilters;
    if (this.excerptsDisabledBySettings) {
      filters = filters.filter((type) => type !== EntityType.ConversationExcerpt);
    }
    if (this.macrosDisabledBySettings) {
      filters = filters.filter((type) => type !== EntityType.SavedReply);
    }
    return filters;
  }

  get excerptsDisabledBySettings() {
    return !this.copilotApi.excerptsEnabledForApp;
  }

  get macrosDisabledBySettings() {
    return !this.copilotApi.macrosEnabledForApp;
  }

  private instrumentStreamResolved(answerProvided: boolean) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'answer',
      action: 'stream_resolved',
      query: this.inputValue,
      time_for_first_token: this.secondsForFirstToken,
      time_for_stream_resolution: this.secondsForStreamResolution,
      answer_provided: answerProvided,
      has_switched_away: this.isDestroyed || this.isDestroying,
      ...this.messageAnalyticsData(),
    });
  }

  private maybeShowVerifyAnswerTooltip(conversation: InboxAssistantConversation | undefined) {
    // Show the tooltip if this is the first successful answer ever
    if (
      !this.copilotApi.hasHadSuccessfulCopilotAnswer &&
      conversation &&
      conversation.successfulAnswers.length === 1
    ) {
      this.canShowVerifyAnswerTooltip = true;
      this.copilotApi.onSuccessfulCopilotAnswer();
    } else {
      this.canShowVerifyAnswerTooltip = false;
    }
  }
}

async function missingAllConversationAccess(e: ResponseError): Promise<boolean> {
  try {
    let json = await e.response.json();
    return (
      'errors' in json &&
      json.errors.length &&
      'required_permission' in json.errors[0] &&
      json.errors[0]['required_permission'] === 'can_access_all_conversations'
    );
  } catch {
    return false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Chat': typeof CopilotChat;
    'inbox2/copilot/chat': typeof CopilotChat;
  }
}
