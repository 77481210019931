/* import __COLOCATED_TEMPLATE__ from './quick-reply-response.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const QuickReplyResponse = templateOnlyComponent<Signature>();
export default QuickReplyResponse;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::BotFlowParts::QuickReplyResponse': typeof QuickReplyResponse;
    'inbox2/conversation-stream/bot-flow-parts/quick-reply-response': typeof QuickReplyResponse;
  }
}
