/* import __COLOCATED_TEMPLATE__ from './track-admin-presence.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { timeout, type TaskGenerator } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import type RealtimeConversationParticipants from 'embercom/services/realtime-conversation-participants';
import { POLL_INTERVAL } from 'embercom/services/realtime-conversation-participants';
import type Session from 'embercom/services/session';
import ENV from 'embercom/config/environment';

interface Args {
  conversationId: number;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationViewingComponent extends Component<Signature> {
  @service declare realtimeConversationParticipants: RealtimeConversationParticipants;
  @service declare session: Session;

  private lastTrackedID?: number;

  @task({ restartable: true })
  *updateConversationBeingViewed(): TaskGenerator<void> {
    while (true) {
      if (document.visibilityState === 'visible') {
        this.lastTrackedID = this.args.conversationId;
        this.realtimeConversationParticipants.viewingConversation(
          this.session.teammate,
          this.args.conversationId,
        );
      }
      if (ENV.environment === 'test') {
        break;
      } else {
        yield timeout(POLL_INTERVAL);
      }
    }
  }

  @action
  stopViewingConversation(): void {
    if (!this.lastTrackedID) {
      return;
    }

    this.realtimeConversationParticipants.stopViewingConversation(
      this.session.teammate,
      this.lastTrackedID,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Conversation::TrackAdminPresence': typeof Inbox2ConversationViewingComponent;
    'inbox2/conversation/track-admin-presence': typeof Inbox2ConversationViewingComponent;
  }
}
