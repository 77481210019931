/* import __COLOCATED_TEMPLATE__ from './side-conversation-reply.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type SideConversationReply from 'embercom/objects/inbox/renderable/side-conversation-reply';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';

export interface SideConversationReplyPart extends RenderablePart {
  renderableData: SideConversationReply;
}

interface Args {
  conversationId: number;
  part: SideConversationReplyPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsSideConversationReplyComponent extends Component<Signature> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;

  get fromEmail() {
    return this.args.part.renderableData.fromEmail;
  }

  get blocks() {
    return this.args.part.renderableData.blocks;
  }

  get sideConversationId() {
    return this.args.part.renderableData.sideConversationId;
  }

  get createdAt() {
    return this.args.part.createdAt;
  }

  @action openSideConversation() {
    this.inboxSidebarService.previewSideConversation(
      this.sideConversationId,
      this.args.conversationId,
      true,
    );

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'side_conversation',
      section: 'respond',
      place: 'conversation_part',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::SideConversationReply': typeof Inbox2ConversationStreamEventPartsSideConversationReplyComponent;
    'inbox2/conversation-stream/event-parts/side-conversation-reply': typeof Inbox2ConversationStreamEventPartsSideConversationReplyComponent;
  }
}
