/* import __COLOCATED_TEMPLATE__ from './send-close-snooze.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type CommandKService from 'embercom/services/command-k';
import { DurationObject, DurationType } from 'embercom/objects/inbox/duration';
import { type Hotkey, type HotkeysMap } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type { TicketSystemState } from 'embercom/objects/inbox/conversation';
import { buildUpdateTicketStateCommandKContext } from 'embercom/lib/inbox2/ticket-custom-states';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type NewConversation } from 'embercom/objects/inbox/conversation';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type Session from 'embercom/services/session';

interface Args {
  canSend: boolean;
  onSend: () => unknown;
  onSendAndSnooze(duration: DurationObject, options?: { keyboardShortcutUsed: boolean }): void;
  onSendAndClose(options?: { keyboardShortcutUsed: boolean }): void;
  onSendAndSetState(state: TicketCustomState): void;
  sendLabel: string;
  showSendAndClose: boolean;
  showSendAndCloseButton: boolean;
  showSendAndSnooze: boolean;
  showSendAndSetState: boolean;
  replyPaneIsActive: boolean;
  currentTicketState?: TicketSystemState;
  isComposerFocused: boolean;
  conversation: Conversation | NewConversation;
}

interface Signature {
  Args: Args;
}

enum StateChange {
  Close,
  Snooze,
}

interface CloseItemValue {
  stateChange: StateChange.Close;
}

interface SnoozeItemValue {
  stateChange: StateChange.Snooze;
  snoozeDurationType: DurationType;
}

interface TicketStateChangeItemValue {
  ticketStateChange: TicketCustomState;
}

type ItemValue = CloseItemValue | SnoozeItemValue | TicketStateChangeItemValue;

export default class SendCloseSnooze extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare intl: IntlService;
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare ticketStateService: TicketStateService;
  @service declare session: Session;

  readonly hotkeys: HotkeysMap;
  declare sendAndCloseKeys: Hotkey;
  declare sendAndSnoozeKeys: Hotkey;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.hotkeys = this.inboxHotkeys.hotkeysMap;
    this.sendAndCloseKeys = this.hotkeys[HotkeyID.SendAndClose];
    this.sendAndSnoozeKeys = this.hotkeys[HotkeyID.SendAndSnooze];
  }

  get items() {
    let items = [];

    if (this.args.showSendAndClose) {
      items.push({
        items: [
          {
            text: this.intl.t('inbox.conversation-reply-composer.send_and_close'),
            icon: 'send-and-close',
            value: {
              stateChange: StateChange.Close,
            } as CloseItemValue,
            shortcutKeys: this.sendAndCloseKeys.display,
            component: 'inbox2/composer/send-and-snooze-item',
          },
        ],
      });
    }

    if (this.args.showSendAndSnooze) {
      items.push({
        heading: this.intl.t('inbox.conversation-reply-composer.send_and_snooze'),
        items: this.snoozeOptions.map((snoozeOption) => ({
          text: this.intl.t(snoozeOption.key),
          value: {
            stateChange: StateChange.Snooze,
            snoozeDurationType: snoozeOption.value,
          } as SnoozeItemValue,
          shortcutKeys: snoozeOption.shortcutKeys,
          component: 'inbox2/composer/send-and-snooze-item',
        })),
      });
    }

    if (this.args.showSendAndSetState) {
      items.push({
        heading: this.intl.t('inbox.conversation-reply-composer.send_and_update_state'),
        items: this.ticketStateDropdownItems,
      });
    }

    return items;
  }

  get snoozeOptions() {
    return [
      {
        key: 'inbox.snooze-durations.later',
        value: DurationType.LaterToday,
      },
      {
        key: 'inbox.snooze-durations.tomorrow',
        value: DurationType.Tomorrow,
        shortcutKeys: this.sendAndSnoozeKeys.display,
      },
      {
        key: 'inbox.snooze-durations.monday',
        value: DurationType.Monday,
      },
      {
        key: 'inbox.snooze-durations.one-week',
        value: DurationType.NextWeek,
      },
      {
        key: 'inbox.snooze-durations.one-month',
        value: DurationType.NextMonth,
      },
      {
        key: 'inbox.snooze-durations.custom',
        value: DurationType.CustomTime,
      },
    ];
  }

  get ticketStateDropdownItems() {
    // this.args.conversation is always going to be a Conversation in this function
    let conversation = this.args.conversation as Conversation;

    let customStates = this.ticketStateService.getLiveCustomStatesForTicketType(
      conversation.ticketType!,
    );

    let updateTicketStateCommandKContext = buildUpdateTicketStateCommandKContext({
      customStates,
      selectedCustomStateId: this.args.conversation.ticketCustomStateId,
      allowClosedState: false,
      intl: this.intl,
    });

    return updateTicketStateCommandKContext.states.map((stateOption) => ({
      text: stateOption.label,
      systemState: stateOption.systemState,
      component: 'inbox2/composer/send-and-update-state-item',
      value: {
        ticketStateChange: stateOption,
      },
    }));
  }

  get allowActions() {
    return this.args.canSend && this.args.replyPaneIsActive;
  }

  @action handleSendAndSnoozeUntilTomorrowShortcut() {
    if (!this.args.isComposerFocused) {
      return;
    }

    return this.sendAndSnooze(new DurationObject(DurationType.Tomorrow), {
      keyboardShortcutUsed: true,
    });
  }

  @action handleSendAndCloseShortcut() {
    if (!this.args.isComposerFocused) {
      return;
    }

    return this.sendAndClose({
      keyboardShortcutUsed: true,
    });
  }

  @action select(selectedItem: ItemValue) {
    if ('stateChange' in selectedItem && selectedItem.stateChange === StateChange.Close) {
      this.sendAndClose();
    } else if ('stateChange' in selectedItem && selectedItem.stateChange === StateChange.Snooze) {
      if (selectedItem.snoozeDurationType === DurationType.CustomTime) {
        this.commandK.registerAndShow({
          actionID: 'snooze',
          context: {
            startInCustom: true,
          },
          onSelect: (duration: DurationObject) => {
            this.sendAndSnooze(duration);
          },
        });
      } else {
        this.sendAndSnooze(new DurationObject(selectedItem.snoozeDurationType));
      }
    } else if ('ticketStateChange' in selectedItem) {
      this.sendAndSetState(selectedItem.ticketStateChange);
    }
  }

  private sendAndClose(options = { keyboardShortcutUsed: false }) {
    if (!this.allowActions) {
      return;
    }
    this.args.onSendAndClose(options);
  }

  private sendAndSnooze(duration: DurationObject, options = { keyboardShortcutUsed: false }) {
    if (!this.allowActions) {
      return;
    }
    this.args.onSendAndSnooze(duration, options);
  }

  private sendAndSetState(state: TicketCustomState) {
    if (!this.allowActions) {
      return;
    }
    this.args.onSendAndSetState(state);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::SendCloseSnooze': typeof SendCloseSnooze;
    'inbox2/composer/send-close-snooze': typeof SendCloseSnooze;
  }
}
