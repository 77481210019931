/* import __COLOCATED_TEMPLATE__ from './ai-assist-after-transform-toolbar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

interface Args {
  onPopoverHide: () => unknown;
  isWorking: boolean;
  onUndo: () => unknown;
  onRedo: () => unknown;
  width: number;
}

interface Signature {
  Args: Args;
}

export default class AiAssistAfterTransformToolbar extends Component<Signature> {
  get isFullWidth() {
    return this.args.width > 450;
  }

  get width() {
    let minWidth = 330;
    let width = this.args.width ? this.args.width + 4 : minWidth;
    return `width: ${width}px`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::AiAssistAfterTransformToolbar': typeof AiAssistAfterTransformToolbar;
  }
}
