/* import __COLOCATED_TEMPLATE__ from './ai-assist-option.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type CommandKService from 'embercom/services/command-k';
import type Session from 'embercom/services/session';
import { type ChildAction, type ParentAction } from 'embercom/objects/inbox/command-k/action';
import { action } from '@ember/object';

interface Args {
  parentAction: ParentAction;
  actionItem: ChildAction;
  isSelected: boolean;
  onDisplayPaywallModal?: (featureName: string) => void;
  onSelect: (action: ChildAction) => void;
  onMouseMove: (action: ChildAction) => void;
  currentSelection: ChildAction | null;
}

interface Signature {
  Args: Args;
}

export default class AiAssistOptionComponent extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare session: Session;

  get isSummarizationPaywalled() {
    let hasSummarizationFeature = this.session.workspace.isFeatureEnabled(
      'ai_assist_summarize_conversation',
    );

    return !hasSummarizationFeature;
  }

  get isToneOfVoicePaywalled() {
    let hasToneOfVoiceFeature = this.session.workspace.isFeatureEnabled('ai_assist_adjust_tone');

    return !hasToneOfVoiceFeature;
  }

  get isActionItemSummarize() {
    return (
      this.args.actionItem.id === 'ai-assist-insert-summary' ||
      this.args.actionItem.id === 'ai-assist-add-summary'
    );
  }

  get isActionItemToneOfVoice() {
    return this.args.actionItem.id === 'ai-assist-tone';
  }

  get isActionItemPaywalled() {
    if (this.isActionItemSummarize) {
      return this.isSummarizationPaywalled;
    } else if (this.isActionItemToneOfVoice) {
      return this.isToneOfVoicePaywalled;
    }
    return false;
  }

  @action onSelect() {
    if (this.isActionItemPaywalled) {
      let featureKey = this.isActionItemSummarize
        ? 'ai_assist_summarize_conversation'
        : 'ai_assist_adjust_tone';
      this.args.onDisplayPaywallModal?.(featureKey);
    } else {
      this.args.onSelect(this.args.actionItem);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::AiAssist::AiAssistOption': typeof AiAssistOptionComponent;
    'inbox2/command-k/ai-assist/ai-assist-option': typeof AiAssistOptionComponent;
  }
}
