/* import __COLOCATED_TEMPLATE__ from './keypad.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';

interface Args {
  onClick: (keyCode: string) => void;
}

const KEY_CODES = [
  {
    text: '1',
  },
  {
    text: '2',
    subText: 'abc',
  },
  {
    text: '3',
    subText: 'def',
  },
  {
    text: '4',
    subText: 'ghi',
  },
  {
    text: '5',
    subText: 'jkl',
  },
  {
    text: '6',
    subText: 'mno',
  },
  {
    text: '7',
    subText: 'pqrs',
  },
  {
    text: '8',
    subText: 'tuv',
  },
  {
    text: '9',
    subText: 'wxyz',
  },
  {
    text: '*',
  },
  {
    text: '0',
    subText: '+',
  },
  {
    text: '#',
  },
];

export default class Keypad extends Component<Args> {
  get keyCodes() {
    return KEY_CODES;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Keypad': typeof Keypad;
    'inbox2/keypad': typeof Keypad;
  }
}
