/* import __COLOCATED_TEMPLATE__ from './avatar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import AppColor from 'embercom/lib/color';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import PALETTE from '@intercom/pulse/lib/palette';

interface Args {
  color?: string;
  darkColor?: string;
  icon?: InterfaceIconName;
  iconColour?: string;
  initials?: string;
  imageURL?: string;
  userId?: string;
  isActive?: boolean;
  isTyping?: boolean;
  typingDotColor?: string;
  showActiveStatus?: boolean;
  tiny?: boolean;
  small?: boolean;
  large?: boolean;
  huge?: boolean;
  isAway?: boolean;
  outline?: boolean;
  shape?: 'squircle' | undefined;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
  Blocks: {
    default: [{ backgroundColor: string | undefined; textColor: string }];
  };
}

export default class CommonAvatarComponent extends Component<Signature> {
  @service declare session: Session;

  get showActiveStatus() {
    return this.args.showActiveStatus ?? 'true';
  }

  get style(): string {
    if (this.args.imageURL) {
      return `background-image: url('${this.args.imageURL.replace(/'/g, "\\'")}');`;
    } else {
      return `background-color: #${this.backgroundColor}; color: ${this.textColor}`;
    }
  }

  get backgroundColor() {
    return this.session.darkModeEnabled ? this.args.darkColor : this.args.color;
  }

  get textColor() {
    return this.session.darkModeEnabled && this.args.darkColor
      ? this.textColorForDarkMode(this.args.darkColor)
      : PALETTE['white'];
  }

  get dotColor() {
    let defaultColor = this.session.darkModeEnabled ? PALETTE['dm-gray-light'] : PALETTE['white'];

    return `background-color: ${this.args.typingDotColor || this.textColor || defaultColor}`;
  }

  // For dark mode, we want avatars' text to be the same hue as the background,
  // with a different, hardcoded value for saturation and luminosity.
  private textColorForDarkMode(hex: string) {
    let [h] = this.getHSL(hex);
    let color = new AppColor();

    return color.HSL_to_HEX(h, 0.79, 0.15);
  }

  private getHSL(hex: string) {
    let color = new AppColor();
    return color.HEX_to_HSL(`#${hex}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Avatar': typeof CommonAvatarComponent;
  }
}
