/* import __COLOCATED_TEMPLATE__ from './insert-attribute-action.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import { type DisplayContext } from 'embercom/services/command-k';

interface Args {
  composerApi?: ComposerPublicAPI;
  priority?: number;
  activatingComponent?: `${DisplayContext}`;
}

interface Signature {
  Args: Args;
}

export default class InsertAttributeAction extends Component<Signature> {
  @action
  insertAttribute(attributePath: string) {
    if (!this.args.composerApi) {
      return;
    }

    let state = this.args.composerApi.composer.state.prosemirrorState;
    if (!state) {
      return;
    }

    let template = state.schema.nodes.template;
    let pos = state.selection.from;
    let transaction = state.tr;
    let node = template.createAndFill({ attributePath, fallback: '' });

    transaction.insert(pos, node);
    this.args.composerApi.composer.commands.dispatch(transaction);
    this.args.composerApi.composer.commands.focus();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::CommandK::InsertAttributeAction': typeof InsertAttributeAction;
    'inbox2/composer/command-k/insert-attribute-action': typeof InsertAttributeAction;
  }
}
