/* import __COLOCATED_TEMPLATE__ from './shopify.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { task } from 'ember-concurrency-decorators';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isValidEmail } from 'embercom/lib/email';
import { isValidOrderName } from 'embercom/lib/inbox/shopify/order';
import { taskFor } from 'ember-concurrency-ts';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { type TaskGenerator } from 'ember-concurrency';
import type User from 'embercom/objects/inbox/user';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';

interface Args {
  user?: User;
  isOpen: boolean;
  onOpenSectionChange: () => void;
  onExpandedSectionChange: () => void;
  isExpanded: boolean;
  name: string;
}

interface Signature {
  Args: Args;
}

interface ShopifyError {
  key: string;
}

interface ShopifyCustomer {
  id: number | null;
  created_at: string | null;
  email: string | null;
  name: string;
  phone: string | null;
  lifetime_spent: string;
  last_order: string | null;
  currency_symbol: string;
}

interface ShopifyLineItem {
  title: string;
  subtitle: string;
  tertiary_text: string;
  tracking_number: string | null;
  tracking_url: string | null;
  sku: string | null;
}

interface ShopifyOrder {
  id: number | null;
  created_at: string | null;
  email: string | null;
  name: string | null;
  fulfillment_status_text: string;
  financial_status_text: string;
  image_url: string;
  subtotal: string;
  shipping: string;
  tax: string;
  total: string;
  line_items: ShopifyLineItem[];
  order_url: string;
  order_status_url: string | null;
}

interface ShopifyCustomerDataResponse {
  customer: ShopifyCustomer;
  customer_orders: ShopifyOrder[];
  all_customer_orders_url: string;
}

interface AccessScopeCheckResponse {
  should_request_more_scopes: boolean;
}
export default class Shopify extends Component<Signature> {
  @service declare session: Session;

  @tracked shopifyCustomer?: ShopifyCustomer;
  @tracked shopifyCustomerOrdersUrl?: string;
  @tracked orders?: ShopifyOrder[];

  @tracked shopifyError?: ShopifyError;
  @tracked shouldRequestMoreScopes?: boolean;

  @tracked shopifyEmailOrOrderName?: string | null | undefined = this.args.user?.email;

  @tracked selectedTab = 'customer';
  @tracked showCreateOrderModal = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.fetchShopifyDataTask).perform();
    taskFor(this.checkAccessScope).perform();
  }

  get emailIsValid() {
    return isValidEmail(this.shopifyEmailOrOrderName);
  }

  @task({ restartable: true })
  *fetchShopifyDataTask(): TaskGenerator<void> {
    this.shopifyCustomer = undefined;
    this.shopifyError = undefined;

    if (
      !isValidEmail(this.shopifyEmailOrOrderName) &&
      !isValidOrderName(this.shopifyEmailOrOrderName)
    ) {
      this.shopifyError = { key: 'invalid_email_or_order_name' };
      return;
    }

    let data = {
      app_id: this.session.workspace.id,
    } as { app_id: string; user_email?: string | null; order_name?: string | null };

    if (this.emailIsValid) {
      data['user_email'] = this.shopifyEmailOrOrderName;
    } else {
      data['order_name'] = this.shopifyEmailOrOrderName;
    }

    try {
      let shopifyResponse = yield ajax({
        url: '/ember/inbox/shopify/shopify_customer_data',
        type: 'GET',
        data,
      }) as ShopifyCustomerDataResponse;

      this.shopifyCustomer = shopifyResponse.customer;
      this.orders = shopifyResponse.customer_orders;
      this.shopifyCustomerOrdersUrl = shopifyResponse.all_customer_orders_url;
    } catch (error) {
      captureException(error);
      this.shopifyError = error.jqXHR.responseJSON.error;
    }
  }

  @task({ restartable: true })
  *checkAccessScope(): TaskGenerator<void> {
    try {
      let accessScopeResponse = yield ajax({
        url: '/ember/inbox/shopify/access_scope_check',
        type: 'GET',
        data: {
          app_id: this.session.workspace.id,
        },
      }) as AccessScopeCheckResponse;

      this.shouldRequestMoreScopes = accessScopeResponse.should_request_more_scopes;
    } catch (error) {
      captureException(error);
    }
  }

  @action
  intercomUserChanged() {
    this.shopifyEmailOrOrderName = this.args.user?.email;
    taskFor(this.fetchShopifyDataTask).perform();
  }

  @action
  focusOnEmailSearchInput() {
    let emailOrOrderSearchInput = document.querySelector<HTMLElement>(
      '.js__shopify-email-or-order-search-input',
    );
    emailOrOrderSearchInput && emailOrOrderSearchInput.focus();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Shopify': typeof Shopify;
    'inbox2/conversation-details-sidebar/sidebar-sections/shopify': typeof Shopify;
  }
}
