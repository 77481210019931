/* import __COLOCATED_TEMPLATE__ from './ai-agent-found-no-answer.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface Args {
  part: any;
}

export default class AiAgentFoundNoAnswer extends Component<Args> {
  @service declare session: Session;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::AiAgentFoundNoAnswer': typeof AiAgentFoundNoAnswer;
    'inbox2/conversation-stream/event-parts/ai-agent-found-no-answer': typeof AiAgentFoundNoAnswer;
  }
}
