/* import __COLOCATED_TEMPLATE__ from './fin-question-answer-modal.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type DismissReason } from 'embercom/services/fin-question-answers';
import type FinQuestionAnswers from 'embercom/services/fin-question-answers';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import type Session from 'embercom/services/session';
import { localCopy } from 'tracked-toolbox';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { type ConversationContentSettings } from 'embercom/controllers/apps/app/fin-ai-agent/setup';
import { FIN_SUPPORTED_LANGUAGES, type Language } from 'embercom/lib/ai-content-library/constants';

interface Signature {
  Args: {
    finConversationContentSettings?: ConversationContentSettings;
  };
}

interface DropDownOption {
  text: string;
  value: string;
}

type DismissOption = {
  value: DismissReason;
  text: string;
  icon?: string;
};

type DismissOptionItems = {
  heading: string;
  items: DismissOption[];
};
export default class FinQuestionAnswerModal extends Component<Signature> {
  @localCopy('finQuestionAnswer.questionAnswer.question') declare question: string;
  @localCopy('finQuestionAnswer.questionAnswer.answer') declare answer: string;
  @localCopy('finQuestionAnswer.questionAnswer.locale') declare locale: string;

  @service declare finQuestionAnswers: FinQuestionAnswers;
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare router: Router;

  get dismissOptions(): DismissOptionItems[] {
    return [
      {
        heading: this.intl.t('inbox.fin-question-answer-modal.dismiss-options.heading'),
        items: [
          {
            value: 'not-now',
            text: this.intl.t('inbox.fin-question-answer-modal.dismiss-options.not-now'),
            icon: 'unsubscribed',
          },
          {
            value: 'too-complex',
            text: this.intl.t('inbox.fin-question-answer-modal.dismiss-options.too-complex'),
            icon: 'sketch-app',
          },
          {
            value: 'content-already-exists-in-article',
            text: this.intl.t(
              'inbox.fin-question-answer-modal.dismiss-options.content-already-exists-in-article',
            ),
            icon: 'article',
          },
          {
            value: 'suggestion-not-accurate',
            text: this.intl.t(
              'inbox.fin-question-answer-modal.dismiss-options.suggestion-not-accurate',
            ),
            icon: 'crosshair',
          },
          {
            value: 'other',
            text: this.intl.t('inbox.fin-question-answer-modal.dismiss-options.other'),
          },
        ],
      },
    ];
  }

  get title(): string {
    if (this.args.finConversationContentSettings?.immediatelyAvailableToFin) {
      return this.intl.t('inbox.fin-question-answer-modal.title');
    }
    return this.intl.t('inbox.fin-question-answer-modal.waiting-for-approval-title');
  }

  get subtitle(): string {
    if (this.args.finConversationContentSettings?.immediatelyAvailableToFin) {
      return this.intl.t('inbox.fin-question-answer-modal.subtitle');
    }
    return this.intl.t('inbox.fin-question-answer-modal.waiting-for-approval-subtitle');
  }

  get toolTipText(): string[] {
    if (this.args.finConversationContentSettings?.immediatelyAvailableToFin) {
      return [
        this.intl.t('inbox.fin-question-answer-modal.info-tooltip.answer-will-be-added', {
          url: this.router.urlFor('apps.app.fin-ai-agent.content', this.session.workspace),
          htmlSafe: true,
        }),
        this.intl.t('inbox.fin-question-answer-modal.info-tooltip.edit-any-time'),
      ];
    }

    return [
      this.intl.t('inbox.fin-question-answer-modal.info-tooltip.answer-will-be-suggested', {
        url: this.router.urlFor(
          'apps.app.fin-ai-agent.content-suggestions',
          this.session.workspace,
        ),
        htmlSafe: true,
      }),
      this.intl.t('inbox.fin-question-answer-modal.info-tooltip.approval-required'),
      this.intl.t('inbox.fin-question-answer-modal.info-tooltip.edit-any-time-suggestion'),
    ];
  }

  get primaryButtonText(): string {
    if (this.args.finConversationContentSettings?.immediatelyAvailableToFin) {
      return this.intl.t('inbox.fin-question-answer-modal.buttons.add-to-fin-content');
    }
    return this.intl.t('inbox.fin-question-answer-modal.buttons.suggest-fin-content');
  }

  get languages(): Array<DropDownOption> {
    return FIN_SUPPORTED_LANGUAGES.map((language: Language) => {
      return {
        text: language.name,
        value: language.locale,
      };
    });
  }

  @action sendSuggestion() {
    let conversationQuestionAnswer = this.finQuestionAnswer;
    if (!conversationQuestionAnswer) {
      return;
    }

    this.finQuestionAnswers.submitSuggestion(
      conversationQuestionAnswer.conversationId,
      conversationQuestionAnswer.questionAnswer,
      { question: this.question, answer: this.answer, locale: this.locale },
    );
  }

  @action dismiss(reason?: DismissReason) {
    let conversationQuestionAnswer = this.finQuestionAnswer;
    if (!conversationQuestionAnswer) {
      return;
    }

    this.finQuestionAnswers.dismissSuggestion(
      conversationQuestionAnswer.conversationId,
      conversationQuestionAnswer.questionAnswer,
      reason,
    );
  }

  @action openFinSetup() {
    let url: string = this.router.urlFor('apps.app.fin-ai-agent.setup', this.session.workspace);
    safeWindowOpen(url, '_blank');
  }

  get finQuestionAnswer() {
    if (!this.finQuestionAnswers.isEnabled) {
      return false;
    }

    return this.finQuestionAnswers.currentQuestionAnswer;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::FinQuestionAnswerModal': typeof FinQuestionAnswerModal;
    'inbox2/fin-question-answer-modal': typeof FinQuestionAnswerModal;
  }
}
