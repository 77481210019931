/* import __COLOCATED_TEMPLATE__ from './editable-topic-pill.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type ConversationTopic from 'embercom/objects/inbox/conversation-topic';

interface Signature {
  Args: {
    topic: ConversationTopic;
    removeTopic: (topic: ConversationTopic) => void;
  };
}

const EditableTopicPill = templateOnlyComponent<Signature>();
export default EditableTopicPill;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::ConversationDetails::EditableTopicPill': typeof EditableTopicPill;
    'inbox2/conversation-details-sidebar/sidebar-sections/conversation-details/editable-topic-pill': typeof EditableTopicPill;
  }
}
