/* import __COLOCATED_TEMPLATE__ from './hover.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Element: HTMLElement;
  Blocks: {
    default: [boolean];
  };
}

export default class Hover extends Component<Signature> {
  @tracked isHovered = false;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Hover': typeof Hover;
    'inbox2/common/hover': typeof Hover;
  }
}
