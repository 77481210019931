/* import __COLOCATED_TEMPLATE__ from './conversation-state-dot-icon.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { ConversationState } from 'embercom/objects/inbox/conversation';

interface Signature {
  Args: {
    conversationState: ConversationState;
  };
}

export default class ConversationStateDotIcon extends Component<Signature> {
  get cssClassName(): string {
    return {
      [ConversationState.Open]: 'bg-blue dark:bg-dm-blue',
      [ConversationState.Snoozed]: 'bg-yellow-dark dark:bg-dm-yellow',
      [ConversationState.Closed]: 'bg-gray dark:bg-dm-gray',
    }[this.args.conversationState];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStateDotIcon': typeof ConversationStateDotIcon;
    'inbox2/conversation-state-dot-icon': typeof ConversationStateDotIcon;
  }
}
