/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { action } from '@ember/object';
import type NavigableSelection from '../../common/navigable-selection-resource';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

interface Args {
  onSelect: (s: ChildAction, a?: object) => void;
  selection: NavigableSelection;
  items: ChildAction<ConversationTableEntry>[];
  ticketCategory: TicketCategory;
}

interface Signature {
  Args: Args;
}

export default class SearchTicketListComponent extends Component<Signature> {
  get nonResolvedTicketItems() {
    return this.args.items.reject((item) => item.value.isTicketResolved);
  }

  get resolvedTicketItems() {
    return this.args.items.filter((item) => item.value.isTicketResolved);
  }

  get resolvedTicketsTranslationKey() {
    switch (this.args.ticketCategory) {
      case TicketCategory.Tracker:
        return 'inbox.command-k.search-ticket-results.resolved-tracker-tickets';
      default:
        return '';
    }
  }

  get nonResolvedTicketsTranslationKey() {
    switch (this.args.ticketCategory) {
      case TicketCategory.Tracker:
        return 'inbox.command-k.search-ticket-results.recently-updated-tracker-tickets';
      default:
        return '';
    }
  }

  @action handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      // Prevent from adding new line in composer when inserted via typeahead
      e.preventDefault();
      this.args.onSelect(this.args.selection.selected);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::SearchTicket::List': typeof SearchTicketListComponent;
    'inbox2/command-k/search-ticket/list': typeof SearchTicketListComponent;
  }
}
