/* import __COLOCATED_TEMPLATE__ from './summary-card-container.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
  Blocks: {
    default: [];
  };
}

const SummaryCardContainer = templateOnlyComponent<Signature>();
export default SummaryCardContainer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::SummaryCard::SummaryCardContainer': typeof SummaryCardContainer;
    'inbox2/dashboard/summary-card/summary-card-container': typeof SummaryCardContainer;
  }
}
