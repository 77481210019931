/* import __COLOCATED_TEMPLATE__ from './call-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type PhoneCall from 'embercom/objects/phone/phone-call';
import type TwilioService from 'embercom/services/twilio-service';
import type User from 'embercom/objects/inbox/user';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Args {
  user: User | undefined;
  conversation: Conversation;
}

interface Signature {
  Args: Args;
}

export default class CallButton extends Component<Signature> {
  @tracked activeCall: PhoneCall | null = null;

  @service declare twilioService: TwilioService;

  @action async onClick() {
    if (!this.args.user) {
      return;
    }

    this.activeCall = await this.twilioService.callNumber(
      this.args.user.attributes.phone as string,
      this.args.conversation,
      this.args.user,
    );
  }

  get isActiveCall() {
    return this.twilioService.isActiveCall;
  }

  get phoneNumber() {
    return this.args.user?.attributes.phone;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::Header::CallButton': typeof CallButton;
    'inbox2/conversation-stream/header/call-button': typeof CallButton;
  }
}
