/* RESPONSIBLE TEAM: team-help-desk-experience */
/* eslint-disable @intercom/intercom/no-component-inheritance */

import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { type SearchByTypeArgs, type SearchByTypeResponse } from 'embercom/services/quick-search';
import type QuickSearch from 'embercom/services/quick-search';
import { type SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { type Named } from 'ember-resources';

import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { registerDestructor } from '@ember/destroyable';
import { type QuickSearchContexts } from 'embercom/objects/inbox/macro';
import { action } from '@ember/object';
import { Resource } from 'ember-resources/core';
import { isEqual } from 'underscore';
import { type SearchableData } from 'embercom/objects/inbox/searchable-document';

const DEBOUNCE_MS = 100;

interface QuickSearchDataArgs {
  quickSearchEntityType: SearchableType;
  searchTerm: string;
  searchContexts?: Array<QuickSearchContexts>;
}

export default class QuickSearchData extends Resource<Named<QuickSearchDataArgs>> {
  @service declare quickSearch: QuickSearch;

  @tracked results: SearchableData[] = [];
  private page = 0;
  private total = -1;

  args!: QuickSearchDataArgs;

  modify(_: unknown[], args: QuickSearchDataArgs) {
    let { quickSearchEntityType, searchTerm, searchContexts } = args;

    // Minimum search term is 2 characters as ES splits and searches against digrams
    if (searchTerm.length === 1) {
      searchTerm = '';
    }

    if (
      !this.args ||
      this.simpleArgsHaveChanged({ quickSearchEntityType, searchTerm, searchContexts }, this.args)
    ) {
      taskFor(this.search).cancelAll();
      this.args = { quickSearchEntityType, searchTerm, searchContexts };
      this.results = [];
      this.page = 0;
      this.total = -1;
      this.fetchNextPage();
    }
  }

  private simpleArgsHaveChanged(newArgs: QuickSearchDataArgs, prevArgs: QuickSearchDataArgs) {
    return (
      newArgs.quickSearchEntityType !== prevArgs.quickSearchEntityType ||
      !isEqual(newArgs.searchContexts, prevArgs.searchContexts) ||
      newArgs.searchTerm !== prevArgs.searchTerm
    );
  }

  constructor(owner: any) {
    super(owner);

    registerDestructor(this, () => {
      taskFor(this.search).cancelAll();
    });
  }

  get hasNoResults() {
    return taskFor(this.search).performCount > 0 && this.results.length === 0 && !this.isLoading;
  }

  get isLoading() {
    return taskFor(this.search).isRunning;
  }

  @action fetchNextPage() {
    taskFor(this.search).perform();
  }

  @task({ restartable: true }) private *search() {
    if (this.page > 0 && this.total === this.results.length) {
      return;
    }

    let requiredFields = {
      data: {
        count: true,
        icon: true,
        id: true,
        name: true,
        image_url: true,
        permissions: true,
        team_ids: true,
        blocks: true,
        predicates: true,
        is_member: true,
        system_type: true,
        is_operator: true,
      },
    };

    yield timeout(DEBOUNCE_MS);

    let searchArgs: SearchByTypeArgs = {
      entityType: this.args.quickSearchEntityType,
      searchTerm: this.args.searchTerm,
      pageFrom: this.page,
      perPage: 10,
      fields: requiredFields,
    };

    if (this.args.searchContexts) {
      searchArgs.currentSearchContext = this.args.searchContexts;
    }

    let response = (yield this.quickSearch.searchByType(searchArgs)) as SearchByTypeResponse;

    this.results = [...this.results, ...response.results.map((result) => result.data)];
    this.total = response.total;
    this.page += 1;
  }
}
