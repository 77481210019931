/* import __COLOCATED_TEMPLATE__ from './outbound-origin-message.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { OUTBOUND_EDITOR_ROUTES } from 'embercom/models/outbound/content-wrapper';

const RENDERABLE_TYPE_TO_ROUTE_AND_LABEL_MAP: Partial<
  Record<RenderableType, { route: string; labelKey: string }>
> = {
  [RenderableType.Chat]: {
    route: OUTBOUND_EDITOR_ROUTES['chat'],
    labelKey: 'inbox.conversation-stream.chat',
  },
  [RenderableType.Post]: {
    route: OUTBOUND_EDITOR_ROUTES['post'],
    labelKey: 'inbox.conversation-stream.post',
  },
  [RenderableType.Email]: {
    route: OUTBOUND_EDITOR_ROUTES['email'],
    labelKey: 'inbox.conversation-stream.email',
  },
  [RenderableType.Sms]: {
    route: OUTBOUND_EDITOR_ROUTES['sms'],
    labelKey: 'inbox.conversation-stream.sms',
  },
  [RenderableType.Whatsapp]: {
    route: OUTBOUND_EDITOR_ROUTES['whatsapp'],
    labelKey: 'inbox.conversation-stream.whatsapp',
  },
};

interface Args {
  renderableData: RenderableData;
}

interface Signature {
  Args: Args;
}

export default class OutboundOriginMessage extends Component<Signature> {
  @service declare session: Session;

  get routeId() {
    return this.args.renderableData.rulesetId;
  }

  get routePath() {
    return RENDERABLE_TYPE_TO_ROUTE_AND_LABEL_MAP[this.args.renderableData.renderableType]?.route;
  }

  get labelKey() {
    return (
      RENDERABLE_TYPE_TO_ROUTE_AND_LABEL_MAP[this.args.renderableData.renderableType]?.labelKey ??
      ''
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::OutboundOriginMessage': typeof OutboundOriginMessage;
    'inbox2/conversation-stream/part-groups/outbound-origin-message': typeof OutboundOriginMessage;
  }
}
