/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState } from 'embercom/objects/inbox/conversation';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import type InboxState from 'embercom/services/inbox-state';
import { ComposerLocation } from 'embercom/services/inbox-state';
import { type Hotkey } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type User from 'embercom/objects/inbox/user';
import type InboxApi from 'embercom/services/inbox-api';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import type Router from '@ember/routing/router-service';
import type CtiService from 'embercom/services/cti-service';
import type UserApi from 'embercom/services/user-api';
import { type TableEntryHighlights } from 'embercom/objects/inbox/conversation-table-entry';

interface Args {
  conversation: Conversation;
  isLoading?: boolean; // This is true when creating a new conversation
  user: User;
  hideConversationEvents: boolean;
  onToggleConversationEvents: () => unknown;
  openConversation: () => unknown;
  closeConversation: () => unknown;
  snoozeConversation: () => unknown;
  exportConversation: () => void;
  togglePriority: (conversation: Conversation, priority: boolean) => unknown;
  allowManageParticipants?: boolean;
  highlights?: TableEntryHighlights;
  showIconsOnly?: boolean;
  setTicketTypeToConvertToTicket: (ticketTypeId?: number) => void;
  changeTicketState: InboxState['changeTicketState'];
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamHeaderComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare intercomEventService: any;
  @service declare inboxApi: InboxApi;
  @service declare router: Router;
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare ctiService: CtiService;
  @service declare userApi: UserApi;

  @tracked conversationId?: number;
  @tracked linkedTickets: LinkedTicketSummary[] = [];

  conversationState = ConversationState;
  readonly toggleEventKey: Hotkey;
  readonly handleNotEditorHotkey;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.toggleEventKey = this.inboxHotkeys.hotkeysMap[HotkeyID.ToggleEvents];
    this.handleNotEditorHotkey = this.inboxHotkeys.handleNotEditorHotkey;
    if (!this.args.isLoading) {
      this.setConversationId();
      this.loadLinkedTickets();
    }
  }

  async loadLinkedTickets() {
    let { tickets: ticketsData } = await this.inboxApi.getLinkedTickets(this.args.conversation.id);
    this.linkedTickets = ticketsData;
  }

  get isConversationListHidden() {
    return this.inboxState.isConversationListHidden && this.inboxState.isListView;
  }

  @action onConversationChange() {
    let conversationIdChanged = this.conversationId !== this.args.conversation.id;

    if (conversationIdChanged) {
      this.loadLinkedTickets();
    }
    this.setConversationId();
  }

  setConversationId() {
    this.conversationId = this.args.conversation.id;
  }

  @action handleToggleEventsKey(e: KeyboardEvent) {
    if (this.inboxState.activeComposerLocation !== ComposerLocation.ConversationPreviewPanel) {
      this.args.onToggleConversationEvents();
      this.intercomEventService.trackAnalyticsEvent({
        object: 'visibility',
        action: 'changed',
        section: 'conversation_header',
        conversation_id: this.args.conversation.id,
        visibility_type: 'events',
        is_visible: !this.args.hideConversationEvents,
        shortcut_key: true,
      });
      e.preventDefault();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::Header': typeof Inbox2ConversationStreamHeaderComponent;
    'inbox2/conversation-page/header': typeof Inbox2ConversationStreamHeaderComponent;
  }
}
