/* import __COLOCATED_TEMPLATE__ from './teammate-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';

export default class Inbox2CommonQuickSearchTeammateSelectorComponent extends Component {
  readonly teammateEntity = SearchableType.Admin;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MacroActions::TeammateSelector': typeof Inbox2CommonQuickSearchTeammateSelectorComponent;
    'inbox2/composer/macro-actions/teammate-selector': typeof Inbox2CommonQuickSearchTeammateSelectorComponent;
  }
}
