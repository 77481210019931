/* import __COLOCATED_TEMPLATE__ from './activity.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
interface Signature {
  Args: {
    conversationSummary: ConversationTableEntry;
  };
}

export default class Activity extends Component<Signature> {
  get showNoteLabel() {
    return (
      this.args.conversationSummary.lastRenderableSummaryPart?.renderableType ===
        RenderableType.AdminNote ||
      this.args.conversationSummary.lastRenderableSummaryPart?.renderableType ===
        RenderableType.BotNote ||
      this.args.conversationSummary.lastRenderableSummaryPart?.renderableType ===
        RenderableType.DuplicateConversationNote
    );
  }
  get summaryPart() {
    return (
      this.args.conversationSummary.lastAdminMentionedPart ||
      this.args.conversationSummary.lastRenderableSummaryPart
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::Activity': typeof Activity;
    'inbox2/conversations-table/table-cells/activity': typeof Activity;
  }
}
