/* import __COLOCATED_TEMPLATE__ from './rating-request-not-sent.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type RatingRequestNotSent from 'embercom/objects/inbox/renderable/rating-request-not-sent';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';

interface RatingRequestNotSentPart extends RenderablePart {
  renderableData: RatingRequestNotSent;
}

interface Args {
  part: RatingRequestNotSentPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsRatingRequestNotSentComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;

  get translatedReason() {
    return this.intl.t(
      `inbox.rating-request.not-sent-reason.${this.args.part.renderableData.reason}`,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::RatingRequestNotSent': typeof Inbox2ConversationStreamEventPartsRatingRequestNotSentComponent;
    'inbox2/conversation-stream/event-parts/rating-request-not-sent': typeof Inbox2ConversationStreamEventPartsRatingRequestNotSentComponent;
  }
}
