/* import __COLOCATED_TEMPLATE__ from './visible-columns-selector-grouped.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';

interface Args {
  groups: { heading: string; items: { text: string; value: string; isSelected: boolean }[] };
  onSelectionChange: (items: string[]) => void;
}

interface Signature {
  Args: Args;
}

export default class VisibleColumnsSelectorGrouped extends Component<Signature> {
  get columnItems() {
    return this.args.groups;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::VisibleColumnsSelectorGrouped': typeof VisibleColumnsSelectorGrouped;
    'inbox2/dashboard/visible-columns-selector-grouped': typeof VisibleColumnsSelectorGrouped;
  }
}
