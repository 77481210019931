/* import __COLOCATED_TEMPLATE__ from './images.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type Attachment } from '@intercom/interblocks.ts';
import { type Upload } from 'embercom/objects/inbox/renderable/upload';

interface Args {
  attachments: Upload[] | Attachment[];
}

interface Signature {
  Args: Args;
}

export default class UnnamedComponent extends Component<Signature> {
  get single(): boolean {
    return this.args.attachments.length === 1;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::Attachments::Images': typeof UnnamedComponent;
    'inbox2/conversation-stream/part-groups/attachments/images': typeof UnnamedComponent;
  }
}
