/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const Option = templateOnlyComponent<Signature>();
export default Option;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::ComposeNew::Option': typeof Option;
    'inbox2/command-k/compose-new/option': typeof Option;
  }
}
