/* import __COLOCATED_TEMPLATE__ from './primary-header.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type ConversationDetailsSidebarArgs } from 'embercom/components/inbox2/conversation-details-sidebar';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type Workspace from 'embercom/objects/workspace';

interface Args {
  isCollapsed: boolean;
  conversation: ConversationDetailsSidebarArgs['conversation'];
  canOpenInNewTab: ConversationDetailsSidebarArgs['canOpenInNewTab'];
  toggleVisibility: ConversationDetailsSidebarArgs['toggleVisibility'];
  workspace: Workspace;
  inbox: Inbox;
}

interface Signature {
  Args: Args;
  Blocks: {
    participantAvatars: [];
  };
}

const PrimaryHeader = templateOnlyComponent<Signature>();
export default PrimaryHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::PrimaryHeader': typeof PrimaryHeader;
  }
}
