/* import __COLOCATED_TEMPLATE__ from './verify-answer-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface VerifyAnswerTooltipArgs {
  answerContentReference?: HTMLElement;
}

interface Signature {
  Element: Element;
  Args: VerifyAnswerTooltipArgs;
  Blocks: {
    default: [];
  };
}

const VerifyAnswerTooltip = templateOnlyComponent<Signature>();
export default VerifyAnswerTooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::VerifyAnswerTooltip': typeof VerifyAnswerTooltip;
    'inbox2/copilot/verify-answer-tooltip': typeof VerifyAnswerTooltip;
  }
}
