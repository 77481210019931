/* import __COLOCATED_TEMPLATE__ from './option-with-highlight.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { type TagItemBaseAction } from 'embercom/objects/inbox/command-k/tag-item';
import { includesTag } from 'embercom/objects/inbox/taggable-part';

interface Args {
  actionItem: ChildAction;
  currentAction: TagItemBaseAction;
}

interface Signature {
  Args: Args;
}

export default class TagItemOptionWithHighlightComponent extends Component<Signature> {
  get isChecked(): boolean {
    if (!this.args.actionItem.parent) {
      return false;
    }

    let context = this.args.actionItem.parent.context;
    if (!context) {
      return false;
    }

    return includesTag(context.tags, this.args.actionItem.value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::TagItem::OptionWithHighlight': typeof TagItemOptionWithHighlightComponent;
    'inbox2/command-k/tag-item/option-with-highlight': typeof TagItemOptionWithHighlightComponent;
  }
}
