/* import __COLOCATED_TEMPLATE__ from './inserters.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type CommandKService from 'embercom/services/command-k';
import { type InserterId, type InserterDetail } from 'embercom/objects/inbox/types/composer';
import type Session from 'embercom/services/session';
import { take } from 'underscore';
import AIAssist from 'embercom/objects/inbox/command-k/ai-assist';
import type Conversation from 'embercom/objects/inbox/conversation';
import type AiAssistSettings from 'embercom/services/ai-assist-settings';

interface Args {
  inserters: InserterDetail[];
  insertersPopoverOpen: boolean;
  pinnedInserterIds: InserterId[];
  focusedInserterId?: InserterId;
  onUse: (inserter: InserterDetail, options: { source: string }) => unknown;
  onUpdatePinned: (ids: InserterId[]) => unknown;
  onToggleInsertersPopover: (open?: boolean) => unknown;
  isComposerFocused: boolean;
  isComposerEmpty: boolean;
  actionsContainer?: HTMLElement;
  conversation?: Conversation;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
    beforeEllipsis: [];
  };
}

const BUTTON_WIDTH = 32;

export default class Inbox2ComposerInserters extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare aiAssistSettings: AiAssistSettings;

  get visibleInserters() {
    return take(
      this.args.inserters.filter(
        (inserter) =>
          this.args.pinnedInserterIds.includes(inserter.id) &&
          // Copilot suggestions don't need to show in the visible inserters
          // Since when pinned the suggestions just show above the composer
          inserter.type !== 'copilotSuggestions',
      ),
      this.visibleInsertersCount,
    );
  }

  get isAddInserterButtonVisible() {
    return this.args.isComposerFocused || this.args.insertersPopoverOpen;
  }

  get hasAIAssistEnabled() {
    return AIAssist.someFeatureEnabled(this.session, this.aiAssistSettings, this.args.conversation);
  }

  @action onTogglePin(inserter: InserterDetail) {
    let { pinnedInserterIds, onUpdatePinned } = this.args;

    let trackInserterAction = (inserter: InserterDetail, type: 'added' | 'removed') => {
      this.intercomEventService.trackAnalyticsEvent({
        action: type,
        object: `${inserter.type}_inserter`,
        section: 'composer',
      });
    };

    if (pinnedInserterIds.includes(inserter.id)) {
      onUpdatePinned(pinnedInserterIds.without(inserter.id));
      trackInserterAction(inserter, 'removed');
    } else {
      onUpdatePinned([...pinnedInserterIds, inserter.id]);
      trackInserterAction(inserter, 'added');
    }
  }

  private get visibleInsertersCount(): number {
    let totalWidth = this.args.actionsContainer?.clientWidth ?? 750;
    let availableWidth = totalWidth / 3;

    return Math.floor(availableWidth / BUTTON_WIDTH);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationReplyComposer::Inserters': typeof Inbox2ComposerInserters;
    'inbox2/conversation-reply-composer/inserters': typeof Inbox2ComposerInserters;
  }
}
