/* import __COLOCATED_TEMPLATE__ from './customer-reports.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Session from 'embercom/services/session';
import { action } from '@ember/object';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';

interface Signature {
  Element: HTMLElement;
  Args: {
    conversationId: number;
    previewOnly?: boolean;
    linkedItems: LinkedTicketSummary[];
    linkedItemsCount: number;
  };
  Blocks: {
    itemRow: [
      {
        item: LinkedTicketSummary;
        component: any;
      },
    ];
  };
}

export default class CustomerReports extends Component<Signature> {
  @service declare session: Session;
  @service declare router: RouterService;

  @action
  showAllLinkedConversations() {
    this.router.transitionTo(
      'inbox.workspace.inbox.linked-conversations',
      this.args.conversationId,
      {
        queryParams: {
          show_bulk_edit_modal: false,
        },
      },
    );
  }

  @action
  replyAllLinkedConversations() {
    this.router.transitionTo(
      'inbox.workspace.inbox.linked-conversations',
      this.args.conversationId,
      {
        queryParams: {
          show_bulk_edit_modal: true,
        },
      },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Links::CustomerReports': typeof CustomerReports;
    'inbox2/conversation-details-sidebar/sidebar-sections/links/customer-reports': typeof CustomerReports;
  }
}
