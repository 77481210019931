/* import __COLOCATED_TEMPLATE__ from './dropdown-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type PopoverArgs } from '@intercom/pulse/template-registry';
import { type Inbox2ButtonArgs } from './button';
import { action } from '@ember/object';
import { type HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';

export type GroupedOptionable<T extends Optionable> = {
  id: string | number | null | undefined;
  selected?: T['id'];
  items?: Readonly<T[]>;
  heading?: string;
  [key: string]: any;
};

export type Optionable = {
  id: string | number | null | undefined;
  isSelected?: boolean;
  items?: Array<Optionable>;
  isDisabled?: boolean;
  hotkeyId?: HotkeyID;
  tooltipText?: string | null;
  onSelect?: () => Promise<void> | void;
  [key: string]: any;
};

interface Signature<T extends Optionable> {
  Element: HTMLElement;
  Args: Partial<Pick<PopoverArgs, 'placement' | 'isDisabled'>> & {
    buttonType?: Inbox2ButtonArgs['type'];
    buttonSize?: Inbox2ButtonArgs['size'];
    iconOnly?: boolean;
    buttonTabIndex?: number | string;
    onDoubleClick?: () => void;
    options?: Readonly<T[]>;
    groupedOptions?: Readonly<GroupedOptionable<T>[]>;
    selected?: T['id'];
    onSelect?: (item: T) => Promise<void> | void;
    dropdownListContentClass?: string;
    showOn?: Array<'click' | 'mouseenter'>;
    hideOn?: Array<'clickout' | 'mouseleave' | 'click'>;
  };
  Blocks: {
    button: [boolean];
    listItem: [any, any];
    footer: [{ hidePopover: () => void }];
  };
}

export default class DropdownButton<T extends Optionable> extends Component<Signature<T>> {
  @action
  onClick(option: T) {
    if (option.onSelect) {
      return option.onSelect();
    } else if (this.args.onSelect) {
      return this.args.onSelect(option);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::DropdownButton': typeof DropdownButton;
    'inbox2/common/dropdown-button': typeof DropdownButton;
  }
}
