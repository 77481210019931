/* import __COLOCATED_TEMPLATE__ from './conversation-resolve-and-close-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
// @ts-ignore
import { action } from '@ember/object';
// @ts-ignore
import { inject as service } from '@ember/service';
import type ConversationResolveAndCloseService from 'embercom/services/conversation-resolve-and-close-service';
import { ConversationState } from 'embercom/objects/inbox/conversation';

export default class ConversationResolveAndCloseModal extends Component {
  @service declare conversationResolveAndCloseService: ConversationResolveAndCloseService;

  get context() {
    return this.conversationResolveAndCloseService.currentContext;
  }

  get relatedReportsCount(): number {
    if (this.conversationResolveAndCloseService.isSingleConversationProcess) {
      return this.context.conversations[0].linkedCustomerReportIds.length ?? 0;
    } else {
      return this.context.conversations.reduce((total, conversation) => {
        return total + (conversation.linkedCustomerReportIds.length ?? 0);
      }, 0);
    }
  }

  get trackerTicketsCount(): number {
    return this.context.conversations.filter((conversation) => {
      return this.conversationResolveAndCloseService.hasRelatedReports(conversation);
    }).length;
  }

  get relatedReportsModalVisible() {
    return this.conversationResolveAndCloseService.trackerTicketReportsModalVisible;
  }

  get closedState() {
    return ConversationState.Closed;
  }

  @action
  cancelProcess() {
    this.conversationResolveAndCloseService.cancelProcess();
  }

  @action
  relatedReportsContinueWithoutUpdating() {
    this.conversationResolveAndCloseService.closeWithoutUpdatingRelatedReports();
  }

  @action
  relatedReportsCloseAll() {
    this.conversationResolveAndCloseService.closeRelatedReportsAndConversation();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationResolveAndCloseModal': typeof ConversationResolveAndCloseModal;
    'inbox2/conversation-resolve-and-close-modal': typeof ConversationResolveAndCloseModal;
  }
}
