/* import __COLOCATED_TEMPLATE__ from './macro-typeahead.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import { type TypeaheadMatchResult } from '@intercom/embercom-prosemirror-composer';
import type Session from 'embercom/services/session';
import { action } from '@ember/object';
import type Inbox2MacrosSearch from 'embercom/services/inbox2-macros-search';
import type CommandKService from 'embercom/services/command-k';
import type Composer from '@intercom/embercom-prosemirror-composer/lib/composer';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import type UseMacroAction from 'embercom/objects/inbox/command-k/use-macro';
import { type SearchByTypeResponse } from 'embercom/services/quick-search';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { type MacrosTypeaheadConfig } from 'embercom/objects/inbox/composer-config';
import { trackedFunction } from 'ember-resources/util/function';

interface Args {
  composer: Composer;
  matchResult: TypeaheadMatchResult | null;
  attrs: MacrosTypeaheadConfig;
}

interface Signature {
  Args: Args;
}

export default class MacroTypeahead extends Component<Signature> {
  @service declare session: Session;
  @service declare inbox2MacrosSearch: Inbox2MacrosSearch;
  @service declare commandK: CommandKService;
  @service declare intercomEventService: any;

  @trackedReset('args.matchResult.text') isVisible = !!this.args.matchResult?.text;

  get useMacroAction(): UseMacroAction | undefined {
    let action = this.commandK.registeredActions.find(
      (a) => a.id === 'use-macro',
    ) as UseMacroAction;
    if (action) {
      return action;
    }
    return undefined;
  }

  get query() {
    let result = this.args.matchResult?.text;

    if (result) {
      return result.replace(/(#|\\)/, '');
    } else {
      return '';
    }
  }

  get offset() {
    if (this.args.matchResult) {
      let { from, to } = this.args.matchResult.offset;
      // If typeahead component fits in the window to the right of the cursor,
      // offset 8px padding to align with matching character. Otherwise, position after character.
      let paddingOffset = window.innerWidth - from.left >= 640 ? 8 : 0;
      return [from.left - to.left - paddingOffset, 5];
    }
    return;
  }

  get invokedKey() {
    return this.args.matchResult?.text[0];
  }

  get conversationId() {
    return this.args.attrs.conversationId;
  }

  get items(): SearchByTypeResponse {
    return this.macrosLoader.value || { results: [], total: 0 };
  }

  private macrosLoader = trackedFunction(this, async () => {
    let results = await this.inbox2MacrosSearch.filterMacros(
      this.query,
      [],
      this.useMacroAction?.searchableContext,
    );
    return results;
  });

  @action insertMacro(macro: ChildAction, { macro_section }: { macro_section: string }) {
    if (!this.args.matchResult) {
      return;
    }

    // Capture analytics data before resetting composer state
    let analyticsData = {
      invoked_key: this.args.matchResult?.text[0],
      query_keywords: this.query,
      number_of_results: this.items.results.length,
    };

    // Remove search term from composer before applying macro
    let prosemirrorState = this.args.composer.state.prosemirrorState;
    let tr = prosemirrorState.tr;

    tr.delete(this.args.matchResult.range.from, this.args.matchResult.range.to);
    this.args.composer.commands.dispatch(tr);

    this.args.attrs.useMacro(macro.value);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'added',
      object: 'macro',
      section: 'composer',
      conversation_id: this.args.attrs.conversationId,
      searched_from: 'composer',
      macro_section,
      shortcut_key: true,
      source: 'composer',
      ...analyticsData,
    });
  }

  @action onDocumentClick(e: MouseEvent) {
    let element: Element | null = e.target as Element;
    do {
      if (element.classList.contains('embercom-prosemirror-popover')) {
        return;
      }
    } while ((element = element.parentElement));
    this.closeTypeahead();
  }

  @action closeTypeahead() {
    this.isVisible = false;
    this.args.composer.commands.focus();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MacroTypeahead': typeof MacroTypeahead;
    'inbox2/composer/macro-typeahead': typeof MacroTypeahead;
  }
}
