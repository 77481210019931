/* import __COLOCATED_TEMPLATE__ from './placeholder.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

interface Args {
  minLength?: number;
  maxLength?: number;
  variant?: string;
  size?: 'md' | 'sm';
  roundedCorners?: boolean;
  noFlex?: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class Placeholder extends Component<Signature> {
  get roundedCorners() {
    return this.args.roundedCorners ?? true;
  }

  get content(): string {
    return Placeholder.generatePlaceholderString(
      this.args.minLength || 5,
      this.args.maxLength || 10,
    );
  }

  get sizeClasses() {
    switch (this.args.size) {
      case 'sm':
        return 'text-sm h-2';
      case 'md':
        return 'text-md font-medium h-4';
      default:
        return 'text-sm h-3';
    }
  }

  static generatePlaceholderString(min: number, max: number) {
    let length = Math.floor(Math.random() * (max - min + 1)) + min;
    return '█'.repeat(length);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Placeholder': typeof Placeholder;
  }
}
