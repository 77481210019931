/* import __COLOCATED_TEMPLATE__ from './add-tag-to-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { type MacroActionComponentArgs } from 'embercom/lib/inbox2/bulk-action-types';
import type Inbox2TagsSearch from 'embercom/services/inbox2-tags-search';
import { trackedFunction } from 'ember-resources/util/function';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Element: HTMLElement;
  Args: MacroActionComponentArgs;
}

export default class AddTagToConversation extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare inbox2TagsSearch: Inbox2TagsSearch;

  @tracked query = '';

  @action onQueryChanged(query: string) {
    this.query = query;
  }

  get results() {
    return this.tagsLoader.value || [];
  }

  tagsLoader = trackedFunction(this, async () => {
    let results = await this.inbox2TagsSearch.filterTags(this.query);
    return results.results.map((d) => d.data);
  });

  get label() {
    let tagSummary = this.args.macroAction.data?.tag_summary;
    if (!tagSummary) {
      return this.intl.t('inbox.composer.macro-actions.add-tag.missing-tag');
    }

    return tagSummary.name;
  }

  get isInvalid() {
    if (this.args.forceInvalid) {
      return true;
    }

    return this.args.macroAction.isInvalid;
  }

  @action onSelectTag(data: Record<string, any>) {
    this.args.macroAction.updateData({ tag_summary: data });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MacroActions::AddTagToConversation': typeof AddTagToConversation;
    'inbox2/composer/macro-actions/add-tag-to-conversation': typeof AddTagToConversation;
  }
}
