/* import __COLOCATED_TEMPLATE__ from './list-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    label?: string;
    labelIntlKey?: string;
    showPlaceholder?: boolean;
  };
  Blocks: {
    default: [];
  };
}

const ListAttribute = templateOnlyComponent<Signature>();
export default ListAttribute;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::ListAttribute': typeof ListAttribute;
    'inbox2/conversation-details-sidebar/list-attribute': typeof ListAttribute;
  }
}
