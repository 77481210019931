/* import __COLOCATED_TEMPLATE__ from './bot-comment.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type TableEntryHighlights } from 'embercom/objects/inbox/conversation-table-entry';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';

interface Signature {
  Args: {
    part: RenderablePart;
    highlights: TableEntryHighlights;
    conversationId: number;
  };
}

const BotComment = templateOnlyComponent<Signature>();
export default BotComment;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::BotFlowParts::BotComment': typeof BotComment;
    'inbox2/conversation-stream/bot-flow-parts/bot-comment': typeof BotComment;
  }
}
