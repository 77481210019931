/* import __COLOCATED_TEMPLATE__ from './admin-name.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface Signature {
  Args:
    | {
        adminId: number;
      }
    | {
        adminSummary: AdminSummary;
      };
}
export default class CreatedByAdminName extends Component<Signature> {
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;

  get adminSummary() {
    if ('adminSummary' in this.args) {
      return this.args.adminSummary;
    } else {
      return this.inbox2AssigneeSearch.findAdminById(this.args.adminId);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::AdminName': typeof CreatedByAdminName;
    'inbox2/conversation-details-sidebar/admin-name': typeof CreatedByAdminName;
  }
}
