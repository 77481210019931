/* import __COLOCATED_TEMPLATE__ from './country-code-option.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  componentAttrs: {
    name: string;
    code: string;
    flagUrl: string;
  };
}

interface Signature {
  Args: Args;
}

const CountryCodeOption = templateOnlyComponent<Signature>();
export default CountryCodeOption;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CountryCodeOption': typeof CountryCodeOption;
    'inbox2/country-code-option': typeof CountryCodeOption;
  }
}
