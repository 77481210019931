/* RESPONSIBLE TEAM: team-help-desk-experience */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { Resource, type Named } from 'ember-resources';
import { action } from '@ember/object';
// @ts-ignore
import { dedupeTracked } from 'tracked-toolbox';

interface NavigableSelectionArgs {
  items: Array<any>;

  // Optional equality comparison function. By default, it'll try to compare
  // the `id` of two objects.
  equalityComparatorFn?: (a: any, b: any) => boolean;
  isCommandKVisible?: boolean;
}

export default class NavigableSelection extends Resource<Named<NavigableSelectionArgs>> {
  @dedupeTracked selected: any;
  isCommandKVisible?: boolean;

  constructor(owner: any, args: Named<NavigableSelectionArgs>, previous: NavigableSelection) {
    super(owner, args, previous);

    let visibilityToggled = false;
    this.isCommandKVisible = this.args.named.isCommandKVisible;
    if (previous && previous.isCommandKVisible !== this.isCommandKVisible) {
      visibilityToggled = true;
    }

    // maintain the selection if we still have that option present
    let previousSelected = this.args.named.items.find(
      (i) => i.id !== undefined && i.id === previous?.selected?.id,
    );

    if (!visibilityToggled && previousSelected) {
      this.select(previousSelected);
    } else {
      this.setInitial(args.named.items);
    }
  }

  get items() {
    return this.args.named.items;
  }

  @action select(item?: any) {
    this.selected = item;
    item?.onHover?.();
  }

  @action previous(attemptedJump = 0) {
    if (!this.selected) {
      return;
    }
    let validJump = this.findJump(true, attemptedJump);
    let newIndex = this.findPreviousIndex(validJump);

    this.select(this.items[newIndex]);
  }

  @action next(attemptedJump = 0) {
    if (!this.selected) {
      return;
    }
    let validJump = this.findJump(false, attemptedJump);
    let newIndex = this.findNextIndex(validJump);

    this.select(this.items[newIndex]);
  }

  get indexOfSelected(): number {
    if (!this.selected) {
      return -1;
    }

    return this.items.findIndex((item) => this.areEqual(item, this.selected));
  }

  private findPreviousIndex(jump: number) {
    let currentIndex = this.indexOfSelected > -1 ? this.indexOfSelected : this.items.length;
    let newIndex = currentIndex - 1 - jump;
    if (newIndex < 0) {
      newIndex += this.items.length;
    }
    return newIndex;
  }

  private findNextIndex(jump: number) {
    let currentIndex = this.indexOfSelected > -1 ? this.indexOfSelected : 0;

    let newIndex = currentIndex + 1 + jump;
    if (newIndex >= this.items.length) {
      newIndex -= this.items.length;
    }
    return newIndex;
  }

  private setInitial(items: Array<any>) {
    let found = items.find((item) => !item.isUnselectable);
    this.select(found);
  }

  private findJump(reversed = false, initialJump = 0) {
    // a -1 jump means staying in place
    let foundJump = -1;
    let currentItem = undefined;
    let size = this.args.named.items.length;

    // stops looking for the next index when it finds a valid jump or when it has looped through the entire array
    // (off by 2 to account for 0-index and to ignore the current item)
    for (let jump = initialJump; foundJump === -1 && jump <= size - 2; jump++) {
      currentItem = reversed ? this.peekPrevious(jump) : this.peekNext(jump);

      // if a valid item is found
      if (!currentItem.isUnselectable) {
        foundJump = jump;
      }
    }

    return foundJump;
  }

  private peekPrevious(jump = 0) {
    let newIndex = this.findPreviousIndex(jump);

    return this.items[newIndex];
  }

  private peekNext(jump = 0) {
    let newIndex = this.findNextIndex(jump);

    return this.items[newIndex];
  }

  private areEqual(a?: any, b?: any) {
    let fn = this.args.named.equalityComparatorFn;
    if (fn && typeof fn === 'function') {
      return fn(a, b);
    }
    return a?.id === b?.id;
  }
}
