/* import __COLOCATED_TEMPLATE__ from './inserter-settings-check.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface Args {
  type: string;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class InserterSettingsCheck extends Component<Signature> {
  @service declare session: Session;

  get isDisabled() {
    return this.session.workspace.disabledInserters?.includes(this.args.type);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::InserterSettingsCheck': typeof InserterSettingsCheck;
    'inbox2/inserter-settings-check': typeof InserterSettingsCheck;
  }
}
