/* import __COLOCATED_TEMPLATE__ from './assignment-queue-position.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import { inject as service } from '@ember/service';
import inflector from 'embercom/lib/inflector';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Session from 'embercom/services/session';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import { request } from 'embercom/lib/inbox/requests';

type Args = SidebarSectionComponentArgs<{
  conversation: Conversation;
}>;

interface Signature {
  Args: Args;
}

export default class SidebarSectionExternalProfiles extends Component<Signature> {
  @service declare session: Session;

  data = DeduplicatedAsyncData(
    this,
    () => [this.args.conversation.id],
    async (conversationId, { signal }) => {
      let response = await request(
        `/ember/conversations/${conversationId}/assignment_queue_position.json?app_id=${this.session.workspace.id}`,
        { signal },
      );
      return (await response.json()) as {
        position: number;
        assignee_name: string;
        higher_priority: number;
        shorter_breach: number;
        waiting_longer: number;
      };
    },
  );

  get position() {
    return this.data.value?.position;
  }

  get assigneeName() {
    return this.data.value?.assignee_name;
  }

  get higherPriority() {
    return this.data.value?.higher_priority;
  }

  get shorterBreach() {
    return this.data.value?.shorter_breach;
  }

  get waitingLonger() {
    return this.data.value?.waiting_longer;
  }

  get reasons() {
    if (!this.position) {
      return '';
    }

    if (this.position === 1) {
      return 'This conversation is next!';
    }

    let reasons = '';

    if (this.higherPriority) {
      reasons += `${this.higherPriority} ${inflector(
        'conversation',
        this.higherPriority,
      )} ${inflector('has', this.higherPriority)} a higher priority.\r\n`;
    }

    if (this.shorterBreach) {
      if (this.shorterBreach === 1) {
        reasons += '1 conversation has an SLA';
      } else {
        reasons += `${this.shorterBreach} conversations have an SLA`;
      }
      reasons += '.\r\n';
    }

    if (this.waitingLonger) {
      reasons += `${this.waitingLonger} ${inflector(
        'conversation',
        this.waitingLonger,
      )} ${inflector('has', this.waitingLonger)} been waiting longer\r\n`;
    }

    return reasons;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::AssignmentQueuePosition': typeof SidebarSectionExternalProfiles;
    'inbox2/conversation-details-sidebar/sidebar-sections/assignment-queue-position': typeof SidebarSectionExternalProfiles;
  }
}
