/* import __COLOCATED_TEMPLATE__ from './list-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import templateOnlyComponent from '@ember/component/template-only';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import type NavigableSelection from '../../common/navigable-selection-resource';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';

interface Args {
  onSelect: (s: ChildAction, a?: object) => void;
  selection: NavigableSelection;
  item: ChildAction<ConversationTableEntry>;
  itemIndex: number;
}

interface Signature {
  Args: Args;
}

const SearchTicketListItemComponent = templateOnlyComponent<Signature>();
export default SearchTicketListItemComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::SearchTicket::ListItem': typeof SearchTicketListItemComponent;
  }
}
