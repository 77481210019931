/* import __COLOCATED_TEMPLATE__ from './tags.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {
  conversationEntry: ConversationTableEntry;
}

interface Signature {
  Args: Args;
}

const MAX_TAGS_TO_DISPLAY = 2;

export default class tags extends Component<Signature> {
  @service declare intl: IntlService;

  get tags() {
    return this.args.conversationEntry.tags;
  }

  get visibleTags() {
    return this.tags.slice(0, MAX_TAGS_TO_DISPLAY);
  }

  get hiddenTags() {
    return this.tags.slice(MAX_TAGS_TO_DISPLAY, this.tags.length);
  }

  get text(): string {
    return `+ ${this.hiddenTags.length} ${this.intl.t(
      'inbox.conversations-table.cells.tags.more',
    )}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::Tags': typeof tags;
    'inbox2/conversations-table/table-cells/tags': typeof tags;
  }
}
