/* import __COLOCATED_TEMPLATE__ from './command-k.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type Hotkey, type HotkeysMap } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';

import type CommandKService from 'embercom/services/command-k';
import { type Action } from 'embercom/objects/inbox/command-k/action';
import { tracked } from '@glimmer/tracking';
import { EscKeyPriority } from 'embercom/lib/inbox2/types';

/*
 * # Command K
 *
 * This component provides the Command K interface.
 *
 * ## The modal
 *
 * The modal itself should be inserted in the top-most template like so:
 * <Inbox2::CommandK />
 *
 * This then handles displaying the interface when cmd+k is pressed, and
 * showing the available actions for the current context.
 *
 * ## Contextual actions
 *
 * Actions are contextual, and will only display in the interface when they
 * have been setup for the given context by adding using the
 * `Inbox2::CommandK::Action` component like so:
 *
 * <Inbox2::CommandK::Action
 *   @id="action-unique-identifier"
 *   @onSelect={{this.performTheAction}}
 * />
 *
 * This component takes the ID of the action you wish to be available in this
 * context (see below for how these are defined) and the `@onSelect` function
 * which will be called when this action is selected (which may not close command k if
 * the action has hideOnSelect set to false).
 * The following functions can also optionally be given:
 * @onCancel - called when command k is closed without selecting any action
 * @onClose  - called when command k is closed, regardless of whether an action was
 *             selected or not
 *
 * Top level actions will be called with no arguments, and child actions
 * will be passed the `value` of the child so you can determine which item
 * was selected.
 *
 * Whilst this component is in the template, the action will be available in
 * the cmd+k modal. Once the action is torn down, it will no longer be available.
 *
 * ## Adding new actions
 *
 * The list of available actions is returned from `registeredActions` in
 * the command-k service. This returns a list of objects which conform to
 * the Action interface.
 *
 * The minimum an action needs to implement is `id` and `label`.
 *
 * The `id` of the action is what is used to identify it, and will be used
 * in `Inbox2::CommandK::Action` to specify that the action is available in
 * the current context.
 *
 * The `label` is used when rendering the action in the list, but is also
 * used to filter actions when the user types into the input.
 *
 * The `icon` should be an ic-icon name and will be displayed next to the
 * label in the list.
 *
 * Multi-step actions take two forms - local and remote.
 *
 * In both cases, a list of `Action` items will be returned from `children`
 * with their `parent` set to the parent Action, and `value` set to
 * the value which will be given to the function called when the item
 * is selected.
 *
 * For local / hard-coded lists of options, implement `children` and
 * return an array of `Action` items. `children` will be given the
 * current query which should be used to filter the actions.
 *
 * For remote items using QuickSearch, the action should define a
 * `searchableType` and as the user types we perform a query against
 * the QuickSearch API and the results made available to `children`
 * which should return an array of `Action` items accordingly.
 */

export default class Inbox2CommandKComponent extends Component {
  @service declare commandK: CommandKService;
  @service declare inboxHotkeys: InboxHotkeys;

  readonly hotkeys: HotkeysMap;

  readonly supportedHotkeysInInput: HotkeyID[] = [
    HotkeyID.AddAttachment,
    HotkeyID.Close,
    HotkeyID.Emoji,
    HotkeyID.InsertArticle,
    HotkeyID.InsertGif,
    HotkeyID.InsertImage,
    HotkeyID.Reopen,
    HotkeyID.UseMacro,
    HotkeyID.TriggerWorkflowConnectorAction,
    HotkeyID.AiAssist,
  ];

  // Optional metadata to pass along when triggering actions.
  @tracked metadata: Record<string, any> = {};

  constructor(owner: unknown, args: {}) {
    super(owner, args);
    this.hotkeys = this.inboxHotkeys.hotkeysMap;
  }

  willDestroy() {
    super.willDestroy();
    this.commandK.hide();
  }

  get activeActions() {
    return this.commandK.activeActions;
  }

  get isVisible() {
    return this.commandK.isVisible;
  }

  get current() {
    return this.commandK.current;
  }

  get escPriority() {
    return EscKeyPriority.cmdK;
  }

  @action deselectCurrent() {
    this.commandK.deselectAction();
  }

  @action triggerAction(selected?: Action, trackingData?: object) {
    if (!selected) {
      return;
    }
    this.commandK.triggerAction(selected, this.metadata, trackingData);
    this.metadata = {};
  }

  @action handleColonShortcut(
    actionItem: Action,
    hotkeyId: HotkeyID,
    event: KeyboardEvent,
    kbevent: any,
  ) {
    // Workaround for https://github.com/adopted-ember-addons/ember-keyboard/issues/680
    if (event.key === ':') {
      this.handleShortcut(actionItem, hotkeyId, ':', event, kbevent);
    }
  }

  @action handleShortcut(
    actionItem: Action,
    hotkeyId: HotkeyID,
    keys: Hotkey['keys'],
    event: KeyboardEvent,
    _kbEvent: any,
  ) {
    if (!this.inboxHotkeys.hotkeysEnabled) {
      return;
    }

    let target = event.target as Element;
    let invalidTagNames = ['input', 'textarea'];

    let isCommandKInput = target.hasAttribute && target.hasAttribute('data-command-k-input');
    let targetIsAnInput =
      invalidTagNames.includes(target.tagName?.toLowerCase()) ||
      (target.getAttribute && target.getAttribute('contentEditable') === 'true');

    if (targetIsAnInput) {
      if (!this.supportedHotkeysInInput.includes(hotkeyId)) {
        return;
      }

      if (isCommandKInput && this.supportedHotkeysInInput.includes(hotkeyId)) {
        if (
          (hotkeyId === HotkeyID.UseMacro || hotkeyId === HotkeyID.Emoji) &&
          // we know that the target is an input at this point and has a value property.
          // The shortcut should not be triggered if there is other text in the input or it's not on the initial pane
          (this.current || (target as HTMLInputElement).value.length > 1)
        ) {
          return;
        }
      }

      // Macros/Emojis inside a text input (other than command-k input) is handled by composer config.
      // So we should not act upon those key presses
      if (!isCommandKInput && (hotkeyId === HotkeyID.Emoji || hotkeyId === HotkeyID.UseMacro)) {
        return;
      }
    }

    event.preventDefault();
    if (!event.repeat) {
      this.commandK.handleShortcutFor(actionItem.id, { shortcutUsed: keys });
    }
  }

  @action onDisplayPaywallModal(featureKey: string) {
    this.commandK.activePaywallForFeature = featureKey;
    this.commandK.hide();
  }

  @action onClosePaywallModal() {
    this.commandK.activePaywallForFeature = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK': typeof Inbox2CommandKComponent;
    'inbox2/command-k': typeof Inbox2CommandKComponent;
  }
}
