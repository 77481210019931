/* import __COLOCATED_TEMPLATE__ from './entity-linked.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import LinkWithText from 'embercom/helpers/link-with-text';
import type IntlService from 'embercom/services/intl';
import type InboxApi from 'embercom/services/inbox-api';
import type Router from '@ember/routing/router-service';
import { trackedFunction } from 'ember-resources/util/function';
//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import type EntityLinked from 'embercom/objects/inbox/renderable/entity-linked';
import { TicketCategory, type TicketType } from 'embercom/objects/inbox/ticket';
import type Conversation from 'embercom/objects/inbox/conversation';

interface EntityLinkedPart extends RenderablePart {
  renderableData: EntityLinked;
}

interface Args {
  part: EntityLinkedPart;
  conversation: Conversation;
  isTicketStream: boolean;
}

interface Signature {
  Args: Args;
}

type TranslationData = {
  key: string;
  data: {
    value: string;
    who?: string;
  };
};

export default class EntityLinkedAdminPartComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare inboxApi: InboxApi;

  // @ts-ignore
  linkWithTextHelper = LinkWithText.create(this);

  // This event part can be rendered in the context of any ticket category or conversation
  //
  // For customer tickets and conversations we want to display the icon of the ticket that was linked
  //
  // For tracker and back office tickets want to show the corresponding ticket icon
  get ticketIcon() {
    let conversationTicketCategory = this.args.conversation.ticketType?.category;

    if (conversationTicketCategory === TicketCategory.Tracker) {
      return 'tracker';
    } else if (conversationTicketCategory === TicketCategory.Task) {
      return 'back-office';
    } else if (
      conversationTicketCategory === TicketCategory.Request ||
      conversationTicketCategory === undefined
    ) {
      let partTicketCategory = this.entityLinkedTicketCategory;

      if (partTicketCategory === TicketCategory.Tracker) {
        return 'tracker';
      } else {
        return 'back-office';
      }
    }

    // We should never get here
    return undefined;
  }

  get renderableData() {
    return this.args.part.renderableData;
  }

  get teammate() {
    return this.renderableData.adminSummary;
  }

  get didIAuthorTheEvent() {
    return this.teammate.id === this.session.teammate.id;
  }

  get ticketTypes(): TicketType[] {
    return this.ticketTypesLoader.value ?? [];
  }

  private ticketTypesLoader = trackedFunction(this, async () => {
    return await this.inboxApi.listTicketTypes();
  });

  conversationLink(capitalize = false) {
    let linkedConversationId = this.renderableData.entityId;
    let href = this.router.urlFor(this.router.currentRouteName, linkedConversationId);

    let conversationText = capitalize
      ? this.intl.t(`inbox.entity-linked.capitalize-conversation`)
      : 'conversation';

    return sanitizeHtml(
      `<a class="hover:text-blue dark:hover:text-dm-blue no-underline" target="_blank" href=${href}>${conversationText}</a>`,
    );
  }

  ticketLink() {
    let ticketId = this.renderableData.entityId;
    let href = this.router.urlFor(this.router.currentRouteName, ticketId);
    let ticketTypeId = this.renderableData.ticketTypeId;
    let ticketType = undefined;
    let ticketTitle = this.renderableData.ticketTitle;

    if (ticketTypeId !== undefined) {
      ticketType = this.ticketTypes.find((ticketType) => ticketType.id === ticketTypeId);
    }

    if (ticketType === undefined && ticketTitle === undefined) {
      return this.intl.t(`inbox.entity-linked.fallback-ticket-type`);
    }

    let displayText = ticketTitle ?? ticketTitle ?? ticketType?.name;

    let exposedTicketId = this.renderableData.userFacingTicketId
      ? this.renderableData.userFacingTicketId
      : this.renderableData.entityId;

    displayText = `${displayText} #${exposedTicketId}`;
    return sanitizeHtml(
      `<a class="hover:text-blue dark:hover:text-dm-blue no-underline" target="_blank" href=${href}>${displayText}</a>`,
    );
  }

  customerLink() {
    let ticketId = this.renderableData.entityId;
    let href = this.router.urlFor(this.router.currentRouteName, ticketId);

    return sanitizeHtml(
      `<a class="hover:text-blue dark:hover:text-dm-blue no-underline" target="_blank" href=${href}>
        ${this.intl.t('inbox.entity-linked.customer-ticket')}
      </a>`,
    );
  }

  linkedBy() {
    return this.didIAuthorTheEvent
      ? this.intl.t('inbox.entity-linked.you')
      : this.linkWithTextHelper.compute([
          'apps.app.admins.admin',
          this.teammate.id,
          this.teammate.name,
        ]);
  }

  get entityLinkedTicketCategory() {
    let ticketType = this.ticketTypes.find(
      (ticketType) => ticketType.id === this.renderableData.ticketTypeId,
    );

    return ticketType?.category;
  }

  get translationData(): TranslationData {
    let data: TranslationData = {
      key: this.translationKey,
      data: this.dataForEventPart,
    };

    if (
      (this.renderableData.linkedEntityAction === 'linked_ticket_to_conversation' ||
        this.renderableData.linkedEntityAction === 'linked_customer_ticket_to_tracker') &&
      this.renderableData.linkedConversationUserSummary?.id
    ) {
      data.data.who = this.linkWithTextHelper.compute([
        'apps.app.users.user',
        this.renderableData.linkedConversationUserSummary.id,
        this.renderableData.linkedConversationUserSummary.displayAs,
      ]);
    } else if (!this.didIAuthorTheEvent) {
      data.data.who = this.linkWithTextHelper.compute([
        'apps.app.admins.admin',
        this.teammate.id,
        this.teammate.name,
      ]);
    }

    return data;
  }

  get dataForEventPart() {
    switch (this.renderableData.linkedEntityAction) {
      case 'started_linked_conversation':
        return {
          value: this.conversationLink(),
        };
      case 'linked_existing_ticket':
        return {
          value: this.ticketLink(),
        };
      case 'linked_ticket_to_conversation':
        return {
          value: this.conversationLink(true),
          linkedBy: this.linkedBy(),
        };
      case 'linked_customer_ticket_to_tracker':
        return {
          value: this.customerLink(),
          linkedBy: this.linkedBy(),
        };

      default:
        throw new Error(
          `${this.renderableData.linkedEntityAction} is not a supported linked entity action type`,
        );
    }
  }

  get translationKey() {
    let key;
    let adminKeyPrefix = this.didIAuthorTheEvent ? 'you' : 'admin';
    let linkedTicketToConversationSuffix = this.renderableData.linkedConversationUserSummary?.id
      ? 'with-user'
      : 'with-unknown-user';

    switch (this.renderableData.linkedEntityAction) {
      case 'started_linked_conversation':
        key = `${adminKeyPrefix}-started-linked-conversation`;
        break;
      case 'linked_existing_ticket':
        key = `${adminKeyPrefix}-linked-existing-ticket`;
        break;
      case 'linked_ticket_to_conversation':
        key = `linked-ticket-to-conversation-${linkedTicketToConversationSuffix}`;
        break;
      case 'linked_customer_ticket_to_tracker':
        key = `linked-customer-ticket-to-tracker-${linkedTicketToConversationSuffix}`;
        break;
      default:
        throw new Error(
          `${this.renderableData.linkedEntityAction} is not a supported linked entity action type`,
        );
    }

    return `inbox.entity-linked.${key}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::EntityLinked': typeof EntityLinkedAdminPartComponent;
    'inbox2/conversation-stream/event-parts/entity-linked': typeof EntityLinkedAdminPartComponent;
  }
}
