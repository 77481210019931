/* import __COLOCATED_TEMPLATE__ from './sending.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

export interface SendingArgs {
  isContainerNarrow: boolean;
}

interface Signature {
  Element: Element;
  Args: SendingArgs;
  Blocks: {
    default: [];
  };
}

const Sending = templateOnlyComponent<Signature>();
export default Sending;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::MessageStates::Sending': typeof Sending;
    'inbox2/copilot/message-states/sending': typeof Sending;
  }
}
