/* import __COLOCATED_TEMPLATE__ from './create-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import { InboxFolder } from 'embercom/objects/inbox/inbox-folder';

interface Args {
  onClose: (args: { success: boolean; inboxFolder?: InboxFolder }) => void;
}

interface Signature {
  Args: Args;
}

export default class CreateModal extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare intercomEventService: any;

  @tracked name = '';

  get isValid() {
    let name = this.name.trim();
    return name.match(/^.{1,50}$/) !== null;
  }

  @action cancel() {
    this.args.onClose({ success: false });
  }

  @task({ restartable: true })
  *createCustomFolder(): TaskGenerator<void> {
    try {
      let result = yield post(`/ember/inbox/inbox_folders/?app_id=${this.session.workspace.id}`, {
        name: this.name,
      });

      this.snackbar.notify(this.intl.t('inbox.custom-folders.modals.create.success-message'));

      let inboxFolder = InboxFolder.deserialize(result.inbox_folder);

      this.trackAnalyticsEvent(inboxFolder);
      this.args.onClose({ success: true, inboxFolder });
    } catch (error) {
      if (error.jqXHR.responseJSON.error === 'Name has already been taken') {
        this.snackbar.notifyError(
          this.intl.t('inbox.custom-folders.modals.shared.name-already-taken-error-message'),
        );
      } else {
        this.snackbar.notifyError(
          this.intl.t('inbox.custom-folders.modals.create.generic-error-message'),
        );
      }
    }
  }

  trackAnalyticsEvent(inboxFolder: InboxFolder) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'created',
      object: 'inbox_folder',
      id: inboxFolder.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CustomFolders::CreateModal': typeof CreateModal;
    'inbox2/custom-folders/create-modal': typeof CreateModal;
  }
}
