/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type ActionRegistration } from 'embercom/services/command-k';
import type CommandKService from 'embercom/services/command-k';
import { DisplayContext } from 'embercom/services/command-k';
import type Paywall from 'embercom/components/paywall';

interface Args {
  onSelect: ActionRegistration['onSelect'];
  onCancel?: ActionRegistration['onCancel'];
  onClose?: ActionRegistration['onClose'];
  id: string;
  context?: any;
  priority?: number;
  trackingData?: object;
  trackingDataFromSelectValue?: (value: any) => object;
  activatingComponent?: `${DisplayContext}`;
  paywall?: Paywall;
}

interface ActionComponentSignature {
  Element: never;
  Args: Args;
}

export default class ActionComponent
  extends Component<ActionComponentSignature>
  implements ActionRegistration
{
  @service declare commandK: CommandKService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.commandK.activate(this);
  }

  willDestroy() {
    super.willDestroy();
    this.commandK.deactivate(this);
  }

  get actionID() {
    return this.args.id;
  }

  get onSelect() {
    return this.args.onSelect;
  }

  get onCancel() {
    return this.args.onCancel;
  }

  get onClose() {
    return this.args.onClose;
  }

  get context() {
    return this.args.context;
  }

  get priority() {
    return this.args.priority || 0;
  }

  get trackingData() {
    return this.args.trackingData;
  }

  get activatingComponent() {
    return this.args.activatingComponent || DisplayContext.Global;
  }

  get trackingDataFromSelectValue() {
    return this.args.trackingDataFromSelectValue;
  }

  get paywall() {
    return this.args.paywall;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Action': typeof ActionComponent;
  }
}
