/* import __COLOCATED_TEMPLATE__ from './split-inserter-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type CommandKService from 'embercom/services/command-k';
import { inject as service } from '@ember/service';
import { type AiAssistPromptKey } from 'embercom/resources/inbox2/composer/ai-assist';
import { type CopilotChatMessageAnalyticsData } from 'embercom/lib/inbox2/copilot/types';

interface Args {
  onAddToComposerClick(): unknown;
  onAddNoteToComposerClick(): unknown;
  showModifierOptions: boolean;
  showInternalContentWarning: boolean;
  changeToneAndInsertBlocks?(promptKey: AiAssistPromptKey): void;
  analyticsData: CopilotChatMessageAnalyticsData;
}

interface Signature {
  Args: Args;
}

export default class SplitInserterButton extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare intercomEventService: any;

  get modifiers() {
    return ['friendly', 'formal', 'shorten', 'rephrase', 'expand', 'translate'];
  }

  get changeToneAndInsertBlocks() {
    return this.args.changeToneAndInsertBlocks ?? (() => {});
  }

  @action trackOverflowMenuClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'add_to_composer_opener',
      place: 'copilot',
      ...this.args.analyticsData,
    });
  }

  @action onModifierClick(modifier: AiAssistPromptKey | 'translate') {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `add_to_composer_${modifier}`,
      place: 'copilot',
      ...this.args.analyticsData,
    });

    if (modifier === 'translate') {
      this.openTranslationPane();
    } else {
      this.changeToneAndInsertBlocks(modifier);
    }
  }

  @action openTranslationPane() {
    this.commandK.registerAndShow({
      actionID: 'ai-assist',
      onSelect: this.changeToneAndInsertBlocks,
      context: {
        isTranslationMode: true,
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::SplitInserterButton': typeof SplitInserterButton;
    'inbox2/copilot/split-inserter-button': typeof SplitInserterButton;
  }
}
