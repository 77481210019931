/* import __COLOCATED_TEMPLATE__ from './relative-time-from-now.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  occuredAt: Date;
}

interface Signature {
  Args: Args;
}

const RelativeTimeFromNow = templateOnlyComponent<Signature>();
export default RelativeTimeFromNow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::RelativeTimeFromNow': typeof RelativeTimeFromNow;
    'inbox2/conversation-stream/event-parts/relative-time-from-now': typeof RelativeTimeFromNow;
  }
}
