/* import __COLOCATED_TEMPLATE__ from './side-drawer-skeleton.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const SideDrawerSkeleton = templateOnlyComponent<Signature>();
export default SideDrawerSkeleton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Drilldown::SideDrawerSkeleton': typeof SideDrawerSkeleton;
    'inbox2/dashboard/drilldown/side-drawer-skeleton': typeof SideDrawerSkeleton;
  }
}
