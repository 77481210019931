/* import __COLOCATED_TEMPLATE__ from './summary-card.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type DashboardSettings from 'embercom/objects/inbox/dashboard-settings';
import { type TableWidth } from '../main';
import { type AggregationMetric } from './table';

interface Signature {
  Args: {
    aggregations: any;
    isLoading: boolean;
    isSwitchingTimeframe: boolean;
    settings: DashboardSettings;
    currentTimeframe: number;
    viewMode: TableWidth;
    selectedAggregationMetrics: AggregationMetric[];
  };
}

const SummaryCard = templateOnlyComponent<Signature>();
export default SummaryCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::InboxOverview::SummaryCard': typeof SummaryCard;
    'inbox2/dashboard/inbox-overview/summary-card': typeof SummaryCard;
  }
}
