/* import __COLOCATED_TEMPLATE__ from './away-mode-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type AdminAwayService from 'embercom/services/admin-away-service';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';

interface Args {}

interface Signature {
  Element: HTMLButtonElement;
  Args: Args;
}

export default class AwayModeModal extends Component<Signature> {
  @service declare adminAwayService: AdminAwayService;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare session: Session;

  @tracked silenceFuturePrompts = false;

  get shouldDisplayModal() {
    return this.adminAwayService.displayModalPrompt;
  }

  get reassigning() {
    return this.adminAwayService.reassignConversations;
  }

  get reassigningTitle() {
    if (this.session.workspace.isFeatureEnabled('team-sops-wlm-stop-email-reassignment')) {
      return 'inbox.away-mode-modal.away-and-reassigning-chat.title';
    }

    return 'inbox.away-mode-modal.away-and-reassigning.title';
  }

  get reassigningBody() {
    if (this.session.workspace.isFeatureEnabled('team-sops-wlm-stop-email-reassignment')) {
      return 'inbox.away-mode-modal.away-and-reassigning-chat.body';
    }

    return 'inbox.away-mode-modal.away-and-reassigning.body';
  }

  @action setToActive() {
    this.adminAwayService.toggleAwayMode();
    this.closeModal();
    this.snackbar.notify(this.intl.t('inbox.away-mode-disabled'));
    if (this.silenceFuturePrompts) {
      this.adminAwayService.silenceAwayModePrompt();
    }
  }

  @action ignore() {
    this.closeModal();
    if (this.silenceFuturePrompts) {
      this.adminAwayService.silenceAwayModePrompt();
    }
  }

  @action closeModal() {
    this.adminAwayService.displayModalPrompt = false;
  }

  @action toggleSilenceFuturePrompts(e: InputEvent) {
    let target = e.target as HTMLInputElement;
    this.silenceFuturePrompts = target.checked;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::AwayModeModal': typeof AwayModeModal;
  }
}
