/* import __COLOCATED_TEMPLATE__ from './macro-actions-preview.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import templateOnlyComponent from '@ember/component/template-only';
import { type MacroAction } from 'embercom/objects/inbox/macro';

export interface AnswerArgs {
  actions: MacroAction[];
}

interface Signature {
  Element: Element;
  Args: AnswerArgs;
}

const MacroActionsPreview = templateOnlyComponent<Signature>();
export default MacroActionsPreview;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::MacroActionsPreview': typeof MacroActionsPreview;
    'inbox2/common/MacroActionsPreview': typeof MacroActionsPreview;
  }
}
