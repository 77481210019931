/* import __COLOCATED_TEMPLATE__ from './conversation-rating-changed.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type UserConversationRatingChange from 'embercom/objects/inbox/renderable/conversation-rating-changed';
interface UserConversationRating extends RenderablePart {
  renderableData: UserConversationRatingChange;
}

interface Args {
  part: UserConversationRating;
}

interface Signature {
  Args: Args;
}

export default class ConversationRatingChanged extends Component<Signature> {
  get userSummary() {
    return this.args.part.renderableData.userSummary;
  }

  get ratingOptions() {
    return ['😠', '🙁', '😐', '😃', '🤩'];
  }

  get userName() {
    return this.userSummary.firstName || this.userSummary.displayAs;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::ConversationRatingChanged': typeof ConversationRatingChanged;
    'inbox2/conversation-stream/event-parts/conversation-rating-changed': typeof ConversationRatingChanged;
  }
}
