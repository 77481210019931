/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { type Action } from 'embercom/objects/inbox/command-k/action';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type ChangeStateAction from 'embercom/objects/inbox/command-k/change-state';
import type CommandKService from 'embercom/services/command-k';
import { inject as service } from '@ember/service';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';

interface Signature {
  Args: CommandKPaneComponentArgs<ChangeStateAction>;
}

export default class ChangeStateComponent extends Component<Signature> {
  @tracked query = '';
  @service declare commandK: CommandKService;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.items,
    isCommandKVisible: this.commandK.isVisible,
  }));

  get items(): Array<Action> {
    return this.args.actionItem.buildResults(this.query);
  }

  @action search(query: string) {
    this.query = query;
    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.items.length,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::ChangeState::Pane': typeof ChangeStateComponent;
    'inbox2/command-k/change-state/pane': typeof ChangeStateComponent;
  }
}
