/* import __COLOCATED_TEMPLATE__ from './change-conversation-priority.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type MacroActionComponentArgs } from 'embercom/lib/inbox2/bulk-action-types';

interface Signature {
  Element: HTMLElement;
  Args: MacroActionComponentArgs;
}

const ChangeConversationPriority = templateOnlyComponent<Signature>();
export default ChangeConversationPriority;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MacroActions::ChangeConversationPriority': typeof ChangeConversationPriority;
    'inbox2/composer/macro-actions/change-conversation-priority': typeof ChangeConversationPriority;
  }
}
