/* import __COLOCATED_TEMPLATE__ from './language-detection-details.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type LanguageDetectionDetails from 'embercom/objects/inbox/renderable/language-detection-details';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export interface LanguageDetectionDetailsPart extends RenderablePart {
  renderableData: LanguageDetectionDetails;
}

export interface Args {
  part: LanguageDetectionDetailsPart;
}

export interface Signature {
  Args: Args;
}

export default class LanguageDetectionDetailsPartComponent extends Component<Signature> {
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };

  get fallbackLocale() {
    return this.args.part.renderableData.fallbackLocale;
  }
  get fallbackLanguage() {
    return this.intl.languageNameFromCode(this.fallbackLocale);
  }

  get renderableData() {
    return this.args.part.renderableData;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::LanguageDetectionDetails': typeof LanguageDetectionDetailsPartComponent;
    'inbox2/conversation-stream/event-parts/language-detection-details': typeof LanguageDetectionDetailsPartComponent;
  }
}
