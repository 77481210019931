/* import __COLOCATED_TEMPLATE__ from './company-details.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type User } from '@sentry/browser';
import type Conversation from 'embercom/objects/inbox/conversation';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import AttributeIconMap from 'embercom/models/data/attribute-icon-map';
import { action } from '@ember/object';
import { type SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type Attribute from 'embercom/objects/inbox/attribute';
import type Session from 'embercom/services/session';

type AttributeWithIcon = Omit<Attribute, 'category' | 'isCompany' | 'isRelationshipDataType'> & {
  icon: string;
};

interface Args {
  name: string;
  conversation: Conversation;
  isOpen: boolean;
  sidebarSection: SidebarSection;
  user?: User;
  userCompanyAttributes: Attribute[];
  onOpenSectionChange: (sectionId: SupportedSidebarSection | number) => {};
  expandedSidebarSection: SupportedSidebarSection | number | undefined;
  onExpandedSectionChange: (id: SupportedSidebarSection | number | undefined) => {};
  isLoadingUser: boolean;
  isLoadingUserCompanyAttributes: boolean;
  activeInbox: Inbox;
  expandableSheet: any;
  toggleCompanyDetailsPin: (pinnedAttrsKeys: string[]) => unknown;
  isPreviewingConversation?: boolean;
}

interface Signature {
  Args: Args;
}

const BLOCKLISTED_ATTRIBUTE_NAMES = [
  'plan.name',
  'tag_ids',
  'manual_tag_ids',
  'company.manual_tag_ids', // tags
  'company.tag_ids', // segments
];

const DEFAULT_ICON_NAME = 'transfer';

export default class CompanyDetails extends Component<Signature> {
  @service declare session: Session;

  @trackedReset('args.conversation') searchAttributeValue = '';

  @action onConversationChange() {
    this.args.onExpandedSectionChange(undefined);
  }

  @action onExpandedSectionChange(id: SupportedSidebarSection | number | undefined) {
    this.searchAttributeValue = '';
    this.args.onExpandedSectionChange(id);
  }

  get filteredAttributes() {
    if (this.searchAttributeValue.trim()) {
      return this.pinnedAndUnpinnedAttributes.filter((attr: AttributeWithIcon) => {
        return (
          attr &&
          attr.humanFriendlyName
            .toLocaleLowerCase()
            .includes(this.searchAttributeValue.toLocaleLowerCase())
        );
      });
    } else {
      return this.pinnedAndUnpinnedAttributes;
    }
  }

  get allAttributes(): AttributeWithIcon[] {
    return (this.args.userCompanyAttributes ?? []).reduce((attributes, attr) => {
      if (BLOCKLISTED_ATTRIBUTE_NAMES.includes(attr.key)) {
        return attributes;
      }

      attributes.push({
        ...attr,
        icon:
          AttributeIconMap.icons[attr.key as keyof typeof AttributeIconMap.icons] ??
          DEFAULT_ICON_NAME,
      });

      return attributes;
    }, [] as AttributeWithIcon[]);
  }

  get pinnedAttributes(): AttributeWithIcon[] {
    return this.allAttributes.filter((attribute) => attribute.adminVisibilitySetting.visible);
  }

  get displayablePinnedAttributes(): AttributeWithIcon[] {
    return this.pinnedAttributes.filter((attribute) => attribute.key !== 'company.name');
  }

  get unpinnedAttributes(): AttributeWithIcon[] {
    return this.allAttributes.filter((attribute) => !attribute.adminVisibilitySetting.visible);
  }

  // We want to show the pinned attributes first and unpinned after that in "See all" view
  // allAttributes getter will return the attributes in the order as they are listed in backend
  get pinnedAndUnpinnedAttributes(): AttributeWithIcon[] {
    return [...this.pinnedAttributes, ...this.unpinnedAttributes];
  }

  @action togglePin(key: string) {
    let pinnedAttrsKeys: string[] = this.pinnedAttributes.reduce((arr: string[], attr) => {
      if (attr?.key) {
        arr.push(attr.key);
      }
      return arr;
    }, []);

    let attribute: AttributeWithIcon | undefined = this.allAttributes.find(
      (attr) => attr.key === `company.${key}`,
    );
    if (attribute) {
      if (attribute.adminVisibilitySetting.visible) {
        pinnedAttrsKeys = pinnedAttrsKeys.filter((attrKey) => attrKey !== attribute?.key);
      } else {
        pinnedAttrsKeys = [...pinnedAttrsKeys, attribute.key];
      }

      this.args.toggleCompanyDetailsPin(pinnedAttrsKeys);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::CompanyDetails': typeof CompanyDetails;
    'inbox2/conversation-details-sidebar/sidebar-sections/company-details': typeof CompanyDetails;
  }
}
