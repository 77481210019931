/* import __COLOCATED_TEMPLATE__ from './add-conversation-topic.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type ConversationTopic from 'embercom/objects/inbox/conversation-topic';
import { type MacroActionComponentArgs } from 'embercom/lib/inbox2/bulk-action-types';

interface Args {
  topics: ConversationTopic[];
}

interface Signature {
  Element: HTMLElement;
  Args: MacroActionComponentArgs & Args;
}

export default class AddConversationTopic extends Component<Signature> {
  @service declare intl: IntlService;

  get label() {
    let topic = this.args.macroAction.data?.topic;
    if (!topic) {
      return this.intl.t('inbox.composer.macro-actions.add-conversation-topic.missing-topic');
    }

    return topic.name;
  }

  get isInvalid() {
    if (this.args.forceInvalid) {
      return true;
    }

    return this.args.macroAction.isInvalid;
  }

  @action onSelectTopic(data: Record<string, any>) {
    this.args.macroAction.updateData({ topic: data });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MacroActions::AddConversationTopic': typeof AddConversationTopic;
    'inbox2/composer/macro-actions/add-conversation-topic': typeof AddConversationTopic;
  }
}
