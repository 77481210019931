/* import __COLOCATED_TEMPLATE__ from './restricted-access-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type PermissionsModal from 'embercom/services/permissions-modal';
import { type Placement } from '@popperjs/core';

interface Args {
  permissionsLabel: string;
  intentKey: string;
  placement?: Placement;
  isDisabled?: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class TooltipsRestrictedAccess extends Component<Signature> {
  @service declare permissionsModal: PermissionsModal;

  @action
  async requestPermission() {
    await this.permissionsModal.showModal({
      permissionsLabel: this.args.permissionsLabel,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::RestrictedAccessTooltip': typeof TooltipsRestrictedAccess;
    'inbox2/common/restricted-access-tooltip': typeof TooltipsRestrictedAccess;
  }
}
