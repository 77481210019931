/* import __COLOCATED_TEMPLATE__ from './search-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { tracked } from '@glimmer/tracking';
import QuickSearchData from 'embercom/components/inbox2/common/quick-search/quick-search-data-resource';
import { type QuickSearchContexts } from 'embercom/objects/inbox/macro';
import { type SearchDropdownArgs, type SearchDropdownSignature } from '../search-dropdown';
import { type SearchableDataForEntityType } from 'embercom/objects/inbox/searchable-document';

type Args<EntityType> = Pick<
  SearchDropdownArgs<EntityType>,
  | 'onEscape'
  | 'searchPlaceholder'
  | 'popoverPlacement'
  | 'fallbackPopoverPlacements'
  | 'onPopoverShow'
  | 'onPopoverHide'
  | 'initiallyOpen'
  | 'size'
  | 'hideDelay'
  | 'repositionOnContentResize'
  | 'stopPropagation'
  | 'showOn'
  | 'hideOn'
  | 'preventOverflow'
> & {
  topResults?: Array<any>;
  searchContexts?: Array<QuickSearchContexts>;
  quickSearchEntityType: EntityType;
  hideCurrentAdminFromResults?: boolean;
};

interface Signature<E extends SearchableType> {
  Element: HTMLElement;
  Args: Args<E>;
  Blocks: {
    placeholder: [];
    opener: SearchDropdownSignature<SearchableDataForEntityType<E>>['Blocks']['opener'];
    dataRow: SearchDropdownSignature<SearchableDataForEntityType<E>>['Blocks']['dataRow'];
    footer: SearchDropdownSignature<SearchableDataForEntityType<E>>['Blocks']['footer'];
  };
}

export default class Inbox2CommonQuickSearchSelector<E extends SearchableType> extends Component<
  Signature<E>
> {
  @service declare session: Session;

  quickSearchData = QuickSearchData.from(this, () => ({
    quickSearchEntityType: this.quickSearchEntityType,
    searchTerm: this.query,
    searchContexts: this.args.searchContexts,
  }));

  get quickSearchEntityType() {
    if ('quickSearchEntityName' in this.args) {
      return SearchableType[this.args.quickSearchEntityName as keyof typeof SearchableType];
    } else {
      return this.args.quickSearchEntityType;
    }
  }

  @tracked query = '';

  @action onQueryChanged(query: string) {
    this.query = query;
  }

  @action resetQuery() {
    this.query = '';
  }

  get topResults() {
    return this.args.topResults || [];
  }

  get results(): any[] {
    let quickSearchResults: any[] = this.quickSearchData.results;
    if (this.args.topResults !== undefined && this.query.length < 2) {
      quickSearchResults = [...this.args.topResults, ...quickSearchResults];
    }
    if (this.isAdminType && this.args.hideCurrentAdminFromResults) {
      quickSearchResults = quickSearchResults.filter(
        (result: any) => result.id !== this.currentAdminId,
      );
    }
    return quickSearchResults;
  }

  get currentAdminId(): number {
    return this.session.teammate.id;
  }

  get isAdminType(): boolean {
    return this.args.quickSearchEntityType === SearchableType.Admin;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::QuickSearch::SearchSelector': typeof Inbox2CommonQuickSearchSelector;
    'inbox2/common/quick-search/search-selector': typeof Inbox2CommonQuickSearchSelector;
  }
}
