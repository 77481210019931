/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type CommandKService from 'embercom/services/command-k';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import type Router from '@ember/routing/router-service';
import type Session from 'embercom/services/session';
import type InboxApi from 'embercom/services/inbox-api';
import type SearchTicketAction from 'embercom/objects/inbox/command-k/search-ticket';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { perform, taskFor } from 'ember-concurrency-ts';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import type IntlService from 'embercom/services/intl';
import { trackerTicketQueryBuilder } from 'embercom/lib/tickets/search-tracker-tickets';

interface Signature {
  Args: CommandKPaneComponentArgs<SearchTicketAction>;
}

export default class SearchTicketPaneComponent extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare inboxState: InboxState;
  @service declare router: Router;
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;

  @tracked data: ConversationTableEntry[] = [];

  @tracked query = '';
  @tracked ticketCategory = TicketCategory.Tracker;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    perform(this.fetchTickets);
  }

  get items() {
    return this.args.actionItem.buildResults(this.data ?? []);
  }

  get emptyTicketTypes() {
    return this.items.length === 0;
  }

  get emptyStateInfo() {
    return {
      icon: 'tracker' as const,
      header: this.emptyStateHeader,
      linkText: this.intl.t(`inbox.command-k.tickets.empty-state.link-tracker`),
      subText: this.intl.t(`inbox.command-k.tickets.empty-state.tracker`),
    };
  }

  get emptyStateHeader() {
    if (this.query) {
      return this.intl.t(`inbox.command-k.tickets.empty-state.no-results-found`);
    }
    return this.intl.t(`inbox.command-k.tickets.empty-state.header-tracker`);
  }

  get isLoading() {
    return taskFor(this.fetchTickets).isRunning;
  }

  @task({ restartable: true })
  *fetchTickets(): TaskGenerator<void> {
    let params = trackerTicketQueryBuilder(this.ticketCategory, this.query);
    let { conversations } = yield this.inboxApi.searchForConversationsTableV2(params);
    this.data = conversations;
  }

  searchTickets() {
    perform(this.fetchTickets);
  }

  @action
  search(query: string) {
    this.query = query;
    debounce(this, this.searchTickets, ENV.APP._150MS);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::SearchTicket::Pane': typeof SearchTicketPaneComponent;
    'inbox2/command-k/search-ticket/pane': typeof SearchTicketPaneComponent;
  }
}
