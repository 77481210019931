/* import __COLOCATED_TEMPLATE__ from './confirm-linking.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Signature {
  Args: {
    onClose: () => void;
    onModalClose: () => void;
    onConfirm: () => void;
    conversation: Conversation;
    selectedTicket: LinkedTicketSummary;
  };
}

const ConfirmLinking = templateOnlyComponent<Signature>();
export default ConfirmLinking;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Links::ConfirmLinking': typeof ConfirmLinking;
    'inbox2/conversation-details-sidebar/sidebar-sections/links/confirm-linking': typeof ConfirmLinking;
  }
}
