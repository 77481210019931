/* import __COLOCATED_TEMPLATE__ from './participant-added.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type ParticipantAdded from 'embercom/objects/inbox/renderable/participant-added';
import LinkWithText from 'embercom/helpers/link-with-text';
import { EntityType } from 'embercom/models/data/entity-types';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface ParticipantAddedPart extends RenderablePart {
  renderableData: ParticipantAdded;
}

interface Args {
  part: ParticipantAddedPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsParticipantAddedPartComponent extends Component<Signature> {
  @service declare session: Session;

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get renderableData() {
    return this.args.part.renderableData;
  }

  get participant() {
    return this.renderableData.participantSummary;
  }

  get participantMerged() {
    return !!this.participant.mergedIntoId;
  }

  get participantLink() {
    return this.linkWithTextHelper.compute([
      'apps.app.users.user',
      this.participant.id,
      this.participant.displayAs,
    ]);
  }

  get addingEntity() {
    return this.renderableData.addingEntity;
  }

  get addingEntityType() {
    return this.renderableData.addingEntityType;
  }

  get translationData() {
    let key;

    if (
      this.addingEntityType === EntityType.Admin &&
      this.addingEntity.id === this.session.teammate.id
    ) {
      key = this.participantMerged ? 'you-added-participant-merged' : 'you-added-participant';
    } else {
      key = this.participantMerged ? 'entity-added-participant-merged' : 'entity-added-participant';
    }

    let data = {
      participant: this.participantLink,
      addingEntity: {},
    };

    if (this.addingEntityType === EntityType.User) {
      data.addingEntity = this.linkWithTextHelper.compute([
        'apps.app.users.user',
        this.addingEntity.id,
        (this.addingEntity as UserSummary).displayAs,
      ]);
    } else {
      data.addingEntity = this.linkWithTextHelper.compute([
        'apps.app.admins.admin',
        this.addingEntity.id,
        (this.addingEntity as AdminSummary).name,
      ]);
    }

    return { key, data };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::ParticipantAdded': typeof Inbox2ConversationStreamEventPartsParticipantAddedPartComponent;
    'inbox2/conversation-stream/event-parts/participant-added': typeof Inbox2ConversationStreamEventPartsParticipantAddedPartComponent;
  }
}
