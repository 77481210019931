/* import __COLOCATED_TEMPLATE__ from './relationship.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
interface Signature {
  Args: {
    placeholder: string;
    value: Array<{
      [K: string]: any;
      external_id: string;
    }>;
  };
}

const Relationship = templateOnlyComponent<Signature>();
export default Relationship;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::Attribute::Editor::Relationship': typeof Relationship;
    'inbox2/conversation-details-sidebar/attribute/editor/relationship': typeof Relationship;
  }
}
