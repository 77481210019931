/* import __COLOCATED_TEMPLATE__ from './call-modal-more-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Element: HTMLElement;
  Args: {
    id: string;
    icon: InterfaceIconName;
    label: string;
    isDisabled?: boolean;
    onClick: () => void;
  };
}

const CallModalMoreDropdownItem = templateOnlyComponent<Signature>();
export default CallModalMoreDropdownItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Calling::CallModalMoreDropdownItem': typeof CallModalMoreDropdownItem;
    'inbox2/calling/call-modal-more-dropdown-item': typeof CallModalMoreDropdownItem;
  }
}
