/* import __COLOCATED_TEMPLATE__ from './call-modal-participant-avatar.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';
import type CallParticipantSummary from 'embercom/objects/inbox/callings/call-participant-summary';
import type { Args as UserAvatarArgs } from '../common/user-avatar';

interface Signature {
  Element: HTMLElement;
  Args: Pick<UserAvatarArgs, 'tiny' | 'small' | 'large'> & {
    participant: CallParticipantSummary;
  };
}

const CallModalParticipantAvatar = templateOnlyComponent<Signature>();
export default CallModalParticipantAvatar;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Calling::CallModalParticipantAvatar': typeof CallModalParticipantAvatar;
    'inbox2/calling/call-modal-participant-avatar': typeof CallModalParticipantAvatar;
  }
}
