/* import __COLOCATED_TEMPLATE__ from './block-or-event-part.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type LatestConversationSummary from 'embercom/objects/inbox/latest-conversation-summary';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { type LinkedTicket } from 'embercom/objects/inbox/renderable-data';

interface Args {
  summaryPart?: RenderablePart;
  conversation: Conversation | LatestConversationSummary | ConversationTableEntry | LinkedTicket;
  checkForTruncation?: boolean;
  hideLinks?: boolean;
}

interface Signature {
  Args: Args;
}

export default class BlockOrEventPart extends Component<Signature> {
  @service declare session: Session;

  RenderableType = RenderableType;

  get isSideConversationEventPart() {
    return (
      this.args.summaryPart?.renderableType === RenderableType.SideConversationStarted ||
      this.args.summaryPart?.renderableType === RenderableType.SideConversationReply
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::BlockOrEventPart': typeof BlockOrEventPart;
    'inbox2/common/block-or-event-part': typeof BlockOrEventPart;
  }
}
