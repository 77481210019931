/* import __COLOCATED_TEMPLATE__ from './user-data-menu.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type InboxApi from 'embercom/services/inbox-api';
import type CompanyEmailAddress from 'embercom/objects/inbox/company-email-address';
import type Session from 'embercom/services/session';
import type Conversation from 'embercom/objects/inbox/conversation';
import type User from 'embercom/objects/inbox/user';
import type Snackbar from 'embercom/services/snackbar';
import type InboxState from 'embercom/services/inbox-state';
import type UserApi from 'embercom/services/user-api';
import type Router from '@ember/routing/router-service';

interface Args {
  conversation: Conversation;
  user?: User;
  role: string;
}

interface Signature {
  Args: Args;
}

export default class UserDataMenu extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare notificationsService: any;
  @service declare session: Session;
  @service declare snackbar: Snackbar;
  @service declare userApi: UserApi;
  @service declare router: Router;

  @tracked isBlockModalOpen = false;
  @tracked isLoadingBlockModal = false;
  @tracked canBlockUser = true;
  @tracked isBlockingUser = false;

  @tracked isManageSubscriptionModalOpen = false;

  private async fetchCompanyEmailAddresses(): Promise<CompanyEmailAddress[]> {
    return this.inboxApi.getCompanyEmailAddress();
  }

  @action async handleBlockMenu() {
    this.canBlockUser = true;
    if (this.args.user?.email) {
      this.isLoadingBlockModal = true;
      try {
        let companyEmailAddresses = await this.fetchCompanyEmailAddresses();
        if (
          companyEmailAddresses.length &&
          companyEmailAddresses.find(
            (companyObj) => companyObj.emailAddress === this.args.user?.email,
          )
        ) {
          this.canBlockUser = false;
        }
      } catch (err) {
        this.notificationsService.notifyError(
          this.intl.t('inbox.attribute-list-item.user-data-menu.block-modal-failure'),
        );
        this.isBlockModalOpen = false;
        this.isLoadingBlockModal = false;
        return;
      }
    }
    this.isLoadingBlockModal = false;
    this.isBlockModalOpen = true;
  }

  @action closeBlockModal() {
    this.isBlockModalOpen = false;
  }

  @action async handleBlockSubmit() {
    if (!this.args.user) {
      return;
    }

    this.isBlockingUser = true;
    try {
      let options = {
        id: this.args.user.id,
        conversation: this.args.conversation,
      };

      this.snackbar.notify(
        this.intl.t('inbox.attribute-list-item.user-data-menu.block-modal-success', {
          userName: this.nameWithFallback,
        }),
      );
      this.isBlockModalOpen = false;
      await this.inboxState.blockUser(options);
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.attribute-list-item.user-data-menu.block-modal-failure'),
      );
    }
    this.isBlockingUser = false;
  }

  get blockModalTitle(): string {
    return this.intl.t('inbox.attribute-list-item.user-data-menu.block-modal-title', {
      userNameOrEmail: this.nameWithFallback,
    });
  }

  get nameWithFallback(): string {
    return (
      this.args.user?.name ||
      (this.args.user?.email as string) ||
      (this.args.user?.attributes.display_as as string) ||
      this.args.role ||
      ''
    );
  }

  get email(): string {
    return this.args.user?.email as string;
  }

  get subscriptionIcon() {
    return this.args.user?.isUnsubscribedFromEmails ? 'email-filled' : 'unsubscribed';
  }

  get subscriptionAction() {
    return this.args.user?.isUnsubscribedFromEmails ? 'resubscribe' : 'unsubscribe';
  }

  @action manageSubscription() {
    this.isManageSubscriptionModalOpen = true;
  }

  @action closeManageSubscriptionModal() {
    this.isManageSubscriptionModalOpen = false;
  }

  @action async handleManageSubscriptionModalSubmit() {
    let { user } = this.args;
    if (!user) {
      return;
    }

    let oldSubscribedStatus = user.isUnsubscribedFromEmails;
    try {
      let options = {
        id: user.id,
        unsubscribed_from_emails: !user.isUnsubscribedFromEmails,
      };
      this.snackbar.notify(
        this.intl.t(
          `inbox.attribute-list-item.user-data-menu.subscription.${this.subscriptionAction}-success`,
          {
            userName: this.nameWithFallback,
          },
        ),
      );
      user.isUnsubscribedFromEmails = !user.isUnsubscribedFromEmails;
      this.isManageSubscriptionModalOpen = false;
      await this.userApi.manageUserSubscription(options);
    } catch (err) {
      this.isManageSubscriptionModalOpen = false;
      user.isUnsubscribedFromEmails = oldSubscribedStatus;
      this.notificationsService.notifyError(
        this.intl.t(
          `inbox.attribute-list-item.user-data-menu.subscription.${this.subscriptionAction}-failure`,
          {
            userName: this.nameWithFallback,
          },
        ),
      );
    }
  }

  @action startNewConversation() {
    this.router.transitionTo('inbox.workspace.inbox.new-conversation', {
      queryParams: {
        recipient: this.args.user?.id,
        ticketTypeId: undefined,
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::UserDataMenu': typeof UserDataMenu;
    'inbox2/conversation-details-sidebar/sidebar-sections/user-data-menu': typeof UserDataMenu;
  }
}
