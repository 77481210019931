/* import __COLOCATED_TEMPLATE__ from './frame.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { type ComponentValues } from '@intercom/interblocks.ts/dist/types/view/canvas-view/canvas-view';
import type MessengerApp from 'embercom/objects/inbox/messenger-app';
import { type Canvas } from '@intercom/interblocks.ts';
import type IntlService from 'embercom/services/intl';
import { type ConfigureCardResponse } from 'embercom/services/messenger-apps';
import type MessengerApps from 'embercom/services/messenger-apps';

interface Args {
  messengerApp: MessengerApp;
  onConfigResults: (canvas_creation_options: object) => void;
  contextualData: object;
  isSaving: boolean;
}

interface Signature {
  Args: Args;
}

export default class Frame extends Component<Signature> {
  @service declare session: Session;
  @tracked private canvas?: Canvas;
  @service declare intl: IntlService;
  @service declare messengerApps: MessengerApps;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.submitApp).perform();
  }

  handleResponse(response: ConfigureCardResponse): void {
    this.canvas = undefined;
    if (response.new_canvas) {
      let inserterPanel = document.querySelector(
        '.app-framework__messenger-apps__inserter__panel__body',
      );
      if (inserterPanel) {
        inserterPanel.scrollIntoView();
      }
      this.canvas = response.new_canvas;
    } else if (response.results) {
      this.args.onConfigResults(response.results);
    }
  }

  @task
  *submitApp(actionComponentId?: string, canvasInputValues?: ComponentValues): TaskGenerator<void> {
    let response = yield this.messengerApps.configureCard({
      admin_id: this.session.teammate.id,
      app_id: this.session.workspace.id,
      messenger_app_id: this.args.messengerApp.id,
      component_id: actionComponentId,
      context: this.args.contextualData,
      current_canvas: this.canvas,
      input_values: canvasInputValues,
    });
    this.handleResponse(response);
  }

  @task *initializeApp(): TaskGenerator<void> {
    yield taskFor(this.submitApp).perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::AppFramework::Frame': typeof Frame;
    'inbox2/app-framework/frame': typeof Frame;
  }
}
