/* import __COLOCATED_TEMPLATE__ from './snooze-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type DurationObject } from 'embercom/objects/inbox/duration';
import type CommandKService from 'embercom/services/command-k';
import type InboxState from 'embercom/services/inbox-state';

interface Args {
  conversation: Conversation;
  snoozeConversation: (value: DurationObject) => unknown;
  iconOnly?: boolean;
}

interface Signature {
  Args: Args;
}

export default class SnoozeButton extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare inboxState: InboxState;

  @action onClick() {
    this.commandK.registerAndShow({
      actionID: 'snooze',
      onSelect: (value: DurationObject) => {
        this.args.snoozeConversation(value);
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::Header::SnoozeButton': typeof SnoozeButton;
    'inbox2/conversation-stream/header/snooze-button': typeof SnoozeButton;
  }
}
