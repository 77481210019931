/* import __COLOCATED_TEMPLATE__ from './quick-reply-options.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';

interface Signature {
  Args: {
    part: RenderablePart;
  };
}

const QuickReplyOptions = templateOnlyComponent<Signature>();
export default QuickReplyOptions;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::BotFlowParts::QuickReplyOptions': typeof QuickReplyOptions;
    'inbox2/conversation-stream/bot-flow-parts/quick-reply-options': typeof QuickReplyOptions;
  }
}
