/* import __COLOCATED_TEMPLATE__ from './ticket-attribute-label.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import {
  type TicketDescriptor,
  type TicketType,
  TicketCategory,
} from 'embercom/objects/inbox/ticket';

interface Args {
  descriptor: TicketDescriptor;
  isDynamicHeight: boolean;
  ticketType?: TicketType;
  visibleToUser?: boolean;
}

interface Signature {
  Args: Args;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class TicketAttributeLabel extends Component<Signature> {
  get attributeVisible() {
    let category = this.args.ticketType?.category;

    if (category) {
      switch (category) {
        case TicketCategory.Tracker:
          return false;
        case TicketCategory.Request:
          return this.args.descriptor.visibleToUsers;
        case TicketCategory.Task:
          if (this.args.ticketType?.internal || this.args.visibleToUser === false) {
            return false;
          } else {
            return this.args.descriptor.visibleToUsers;
          }
      }
    } else {
      return this.args.descriptor.visibleToUsers;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::TicketPanel::TicketAttributeLabel': typeof TicketAttributeLabel;
    'inbox2/conversation-page/ticket-panel/ticket-attribute-label': typeof TicketAttributeLabel;
  }
}
