/* import __COLOCATED_TEMPLATE__ from './single-company-tags.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Tag, { type TagActionType, type TagMenuSource } from 'embercom/objects/inbox/tag';
import type InboxApi from 'embercom/services/inbox-api';
import type Session from 'embercom/services/session';
import type Company from 'embercom/objects/inbox/company';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import { task as trackedTask, type TaskGenerator } from 'ember-resources/util/ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import type CompanyApi from 'embercom/services/company-api';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import type CommandKService from 'embercom/services/command-k';
import { includesTag } from 'embercom/objects/inbox/taggable-part';
import { next } from '@ember/runloop';
import { CommandKTagActionId } from 'embercom/objects/inbox/command-k/tag-item';

interface Args {
  company: Company;
  userCompanies: number;
  index: number;
  name: string;
  sidebarSection: SidebarSection;
  expandableSheet: HTMLElement;
  isExpanded: boolean;
  onExpandedSectionChange: (id: string | number | undefined) => void;
}

interface Signature {
  Args: Args;
}

export default class SingleCompanyTags extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare session: Session;
  @service declare companyApi: CompanyApi;
  @service declare notificationsService: any;
  @service declare commandK: CommandKService;

  @tracked tags: Tag[] = [];
  @tracked loadInitialTags = false;
  @tracked tagMenuSource: TagMenuSource = null;
  @tracked tagActionType: TagActionType = null;
  @tracked isTagging = false;

  // @ts-ignore TaskGenerator<void>' is not assignable to parameter of type 'TaskIsh<(string | undefined)[]
  initialFetchedTags = trackedTask(this, this.loadTags, () => [this.args.company.id]);

  get companyTags(): Tag[] {
    let initialTags = (this.initialFetchedTags.value as Tag[]) || [];
    let tagsToPresent = this.loadInitialTags ? initialTags : this.tags;

    return tagsToPresent;
  }

  get limitedCompanyTags(): Tag[] {
    return this.companyTags.slice(0, 5);
  }

  @action
  async addTag() {
    this.isTagging = true;
    next(this, () => {
      this.commandK.findAndShow(CommandKTagActionId.TagCompany, this.onFinish);
    });
  }

  @task *loadTags(id: string): TaskGenerator<void> {
    if (!id) {
      return;
    }

    this.loadInitialTags = true;

    try {
      return yield this.companyApi.fetchCompanyTags(id);
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
      return;
    }
  }

  get context(): { company: Company; tags: Tag[] } {
    return {
      company: this.args.company,
      tags: this.companyTags,
    };
  }

  @action setTagActionType(type: TagActionType) {
    this.tagActionType = type;
  }

  @action onFinish() {
    this.isTagging = false;
  }

  @action
  async removeTag(tag: Tag) {
    this.setTagActionType('removed');

    await this.updateTagsForCompany({
      company: this.args.company,
      updatedTags: this.companyTags.filter((x) => x !== tag),
    });
  }

  private async updateTagsForCompany(props: { company: Company | undefined; updatedTags: Tag[] }) {
    if (!props.company?.id) {
      return;
    }

    this.loadInitialTags = false;
    let oldTags = this.companyTags;

    try {
      this.tags = props.updatedTags;

      let response = await this.inboxApi.updateTagsForCompany(props.company.id, props.updatedTags);
      // Update the id for newly created tags
      if (response?.tags?.length && props.updatedTags.some((tag) => !tag.id)) {
        this.tags = props.updatedTags.map((tag) => {
          if (!tag.id) {
            let createdTag = response.tags.find((t: Tag) => t.name === tag.name);
            if (createdTag) {
              return new Tag(
                createdTag.id,
                createdTag.name,
                this.session.teammate,
                new Date(createdTag.created_at),
              );
            }
          }

          return tag;
        });
      }
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
      this.tags = oldTags;
    }
  }

  @action async tagCompany(tag: Tag, context: { company: Company; tags: Tag[] }): Promise<void> {
    let companyIsTagged = includesTag(context.tags, tag);
    let updatedTags: Tag[] = [];

    if (companyIsTagged) {
      updatedTags = context.tags.filter(({ id }) => id !== tag.id);
    } else {
      updatedTags = [...context.tags, new Tag(tag.id, tag.name, this.session.teammate)];
    }
    this.setTagActionType(companyIsTagged ? 'removed' : 'added');
    await this.updateTagsForCompany({ company: context.company, updatedTags });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::SingleCompanyTags': typeof SingleCompanyTags;
    'inbox2/conversation-details-sidebar/sidebar-sections/single-company-tags': typeof SingleCompanyTags;
  }
}
