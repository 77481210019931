/* import __COLOCATED_TEMPLATE__ from './admin-status-selector.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { TeammateStatus } from 'embercom/services/inbox2-teammate-activity-service';
import { pluck } from 'underscore';
import type IntlService from 'embercom/services/intl';

interface Args {
  defaultStatuses: TeammateStatus[];
  changeStatusFilter: (filter: string[]) => void;
}

interface Signature {
  Args: Args;
}

export default class AdminStatusSelector extends Component<Signature> {
  @service declare intl: IntlService;
  @tracked selectedStatuses: TeammateStatus[] = this.args.defaultStatuses;

  get statusOptions() {
    return [
      {
        text: this.intl.t('inbox.dashboard.teammate-activity.status.active'),
        value: TeammateStatus.Active,
        isSelected: this.args.defaultStatuses.includes(TeammateStatus.Active),
      },
      {
        text: this.intl.t('inbox.dashboard.teammate-activity.status.away'),
        value: TeammateStatus.Away,
        isSelected: this.args.defaultStatuses.includes(TeammateStatus.Away),
      },
      {
        text: this.intl.t('inbox.dashboard.teammate-activity.status.reassigning'),
        value: TeammateStatus.Reassign,
        isSelected: this.args.defaultStatuses.includes(TeammateStatus.Reassign),
      },
    ];
  }

  get statusFilterLabel() {
    return this.intl.t('inbox.dashboard.teammate-activity.status.selection', {
      hasSelectedStatuses: this.selectedStatuses.length !== 0,
      selectedStatuses: pluck(
        this.statusOptions.filter((e) => this.selectedStatuses.includes(e.value)),
        'text',
      ).join(', '),
    });
  }

  @action changeStatusFilter(newStatusFilter: TeammateStatus[]) {
    this.selectedStatuses = newStatusFilter;
    this.args.changeStatusFilter(this.selectedStatuses);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::Teammates::AdminStatusSelector': typeof AdminStatusSelector;
    'inbox2/dashboard/teammates/admin-status-selector': typeof AdminStatusSelector;
  }
}
