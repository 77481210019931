/* import __COLOCATED_TEMPLATE__ from './macro-header.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  type CopilotInteractionMetadata,
  type NormalizedFinSource,
} from 'embercom/lib/inbox2/copilot/types';
import { type InboxAssistantConversationPart } from 'embercom/objects/inbox/inbox-assistant-conversation';

interface Args {
  message: InboxAssistantConversationPart;
  source?: NormalizedFinSource;
  insertBlocks: () => void;
  sourceDetails: NormalizedFinSource[];
  setHoveredSourceKey: (sourceKey: string | null) => void;
}

export default class MacroTitleTooltip extends Component<Args> {
  get interactionMetadata(): CopilotInteractionMetadata {
    return {
      isFromRelatedContentSheet: false,
    };
  }

  get source() {
    return this.args.sourceDetails[0];
  }

  @action onMouseEnter(): void {
    this.args.setHoveredSourceKey(`${this.source.entity_id}${this.source.entity_type}`);
  }

  @action onMouseLeave(): void {
    this.args.setHoveredSourceKey(null);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::MessageStates::AnswerState::MacroHeader': typeof MacroTitleTooltip;
    'inbox2/copilot/message-states/answer-state/macro-header': typeof MacroTitleTooltip;
  }
}
