/* import __COLOCATED_TEMPLATE__ from './dashboard-preview-panel.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
// import { get } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import BreakdownBuilder from 'embercom/lib/monitoring/breakdown-builder';
import type DashboardSettings from 'embercom/objects/inbox/dashboard-settings';
import type InboxApi from 'embercom/services/inbox-api';
import type Inbox2OverviewService from 'embercom/services/inbox2-overview-service';
import { type TaskGenerator } from 'ember-concurrency';
import type Session from 'embercom/services/session';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
// @ts-ignore
import intermoji from '@intercom/intermoji';
// @ts-ignore
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type IntlService from 'embercom/services/intl';
import { type EmojiIcon } from 'embercom/objects/inbox/command-k/icons';

interface Args {
  metric: any;
  timeframe: number;
  filter: string;
  conversationId: number;
  settings: DashboardSettings;
  tempViewUrl: string;
  openPreviewPanel: (conversationId: number) => void;
}

interface Signature {
  Args: Args;
}

const DEFAULT_DRILLDOWN_SKELETON_ROW_COUNT = 5;

export default class DashboardPreviewPanel extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare inbox2OverviewService: Inbox2OverviewService;
  @service declare intl: IntlService;
  @service intercomEventService: any;
  @service notificationsService: any;

  @tracked filter = '';

  @tracked conversationIds: string[] = [];
  @tracked buckets: any[] = [];
  @tracked teammatesTableData: any;
  @tracked inboxData?: Inbox;

  rowCount: number = !isNaN(this.args.metric.value)
    ? parseInt(this.args.metric, 10)
    : DEFAULT_DRILLDOWN_SKELETON_ROW_COUNT;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.setDefaultFilter();
    taskFor(this.fetchMetricDrilldown).perform();
    taskFor(this.fetchInboxData).perform();
  }

  get settings() {
    return this.args.settings;
  }

  get title() {
    return this.args.metric.metricName;
  }

  get metricType() {
    return this.args.metric.metricType;
  }

  get isInboxView() {
    return this.inboxData?.id.startsWith('view:') ?? false;
  }

  get inbox() {
    if (this.args.metric.inboxId) {
      return this.inboxData;
    }
    return undefined;
  }

  get iconSvgUrl() {
    let icon = this.inboxData?.icon as EmojiIcon;

    if (icon && icon.emoji) {
      return intermoji.twemojiSVGUri(icon.emoji) as string;
    }
    return undefined;
  }

  get defaultAvatar(): string {
    return DefaultAvatars.admins[128];
  }

  get admin() {
    if (this.args.metric.adminId) {
      return this.inboxData?.adminSummary || this.inboxData;
    }
    return undefined;
  }

  get hasBreakdown() {
    return this.buckets && this.buckets.length > 0;
  }

  get hasSummary() {
    let summaryMetrics = [
      'unassigned',
      'longest_waiting',
      'closed',
      'snoozed',
      'participated_in',
      'workload',
    ];

    summaryMetrics.push('open');

    return summaryMetrics.includes(this.args.metric.metricType);
  }

  get hasSummaryLabel() {
    return ['open', 'unassigned', 'closed', 'snoozed', 'participated_in'].includes(
      this.args.metric.metricType,
    );
  }

  get breakdown() {
    return this.hasBreakdown
      ? new BreakdownBuilder({
          buckets: this.buckets,
          metricType: this.args.metric.metricType,
        }).build()
      : null;
  }

  get summaryValue() {
    let value = this.args.metric.value;
    switch (this.args.metric.metricType) {
      case 'workload':
        return value.split(' ')[0];
      default:
        return value;
    }
  }

  get activeTeammatesMetric() {
    return this.args.metric.metricType === 'active_teammates';
  }

  get phoneAvailableTeammatesMetric() {
    return this.args.metric.metricType === 'phone_available_teammates';
  }

  get csatMetric() {
    return this.args.metric.metricType === 'csat';
  }

  get drilldownUrl() {
    if (this.args.metric.adminId) {
      return '/ember/monitoring/teammate_activities/metric_drilldown';
    } else {
      return '/ember/monitoring/inbox_overview/metric_drilldown';
    }
  }

  get fetchingParams() {
    return {
      inboxId: this.args.metric.inboxId,
      metricType: this.args.metric.metricType,
      adminId: this.args.metric.adminId,
      timeframeInHours: this.args.timeframe,
      filter: this.filter,
      aggregationType: this.settings.aggregationType,
      idleThreshold: this.settings.idleThreshold,
      excludeBotTime: this.settings.excludeBotTime,
    };
  }

  setDefaultFilter() {
    if (this.activeTeammatesMetric) {
      this.filter = 'Active';
    } else if (this.csatMetric) {
      this.filter = 'rating_index_total';
    } else {
      this.filter = 'All';
    }
  }

  @action
  onFilterChange(value: string) {
    this.filter = value;
    if (this.activeTeammatesMetric) {
      taskFor(this.fetchTeammates).perform();
    } else {
      taskFor(this.fetchConversationIds).perform();
    }
  }

  @action
  async updatePanel() {
    this.setDefaultFilter();
    if (this.activeTeammatesMetric || this.phoneAvailableTeammatesMetric) {
      await taskFor(this.fetchTeammates).perform();
    } else {
      await taskFor(this.fetchConversationIds).perform();
    }
    await taskFor(this.fetchInboxData).perform();
  }

  @action
  async openPreviewPanel(conversationId: number) {
    this.args.openPreviewPanel(conversationId);
  }

  @action
  async goToTempView() {
    safeWindowOpen(this.args.tempViewUrl, '_blank');
  }

  @task({ drop: true })
  *fetchMetricDrilldown(): TaskGenerator<void> {
    let data = yield this.inbox2OverviewService.getMetricData(this.fetchingParams);
    if (data.conversation_ids || data.breakdown || data.teammate_table_data) {
      this.conversationIds = data.conversation_ids;
      this.buckets = data.breakdown;
      this.teammatesTableData = data.teammate_table_data;
      if (this.buckets) {
        this.args.metric.updateValue(this?.buckets?.[0]?.value);
      }
    } else {
      this.conversationIds = data;
    }
  }

  @task({ drop: true })
  *fetchInboxData(): TaskGenerator<void> {
    let inboxId = this.args.metric.inboxId;
    let inboxCategory = InboxCategory.Team;
    if (inboxId && inboxId.startsWith('view:')) {
      inboxId = inboxId.replace(/^view:/, '');
      inboxCategory = InboxCategory.View;
    }
    this.inboxData = yield this.inboxApi.fetchInbox(
      inboxId ? inboxCategory : InboxCategory.Admin,
      inboxId || this.args.metric.adminId,
    );
  }

  @task({ drop: true })
  *fetchConversationIds(): TaskGenerator<void> {
    let data = yield this.inbox2OverviewService.getMetricData(this.fetchingParams);
    this.conversationIds = data.conversation_ids;
    if (JSON.stringify(this.buckets) !== JSON.stringify(data.breakdown)) {
      this.buckets = data.breakdown;
    }
  }

  @task({ drop: true })
  *fetchTeammates(): TaskGenerator<void> {
    let data = yield this.inbox2OverviewService.getMetricData(this.fetchingParams);
    this.teammatesTableData = data.teammate_table_data;
    if (JSON.stringify(this.buckets) !== JSON.stringify(data.breakdown)) {
      this.buckets = data.breakdown;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::DashboardPreviewPanel': typeof DashboardPreviewPanel;
    'inbox2/dashboard/dashboard-preview-panel': typeof DashboardPreviewPanel;
  }
}
