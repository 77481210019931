/* import __COLOCATED_TEMPLATE__ from './snooze-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import { SNOOZED_UNTIL_MAP } from 'embercom/lib/inbox/constants';

interface Args {
  action: MacroAction;
}

interface Signature {
  Args: Args;
}

export default class SnoozeConversation extends Component<Signature> {
  get label() {
    let option = SNOOZED_UNTIL_MAP.find(
      (option) => option.key === this.args.action.data?.snoozed_until,
    );

    return option?.value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::UseMacro::Preview::SnoozeConversation': typeof SnoozeConversation;
    'inbox2/command-k/use-macro/preview/snooze-conversation': typeof SnoozeConversation;
  }
}
