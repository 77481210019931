/* import __COLOCATED_TEMPLATE__ from './conversation-options.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type InboxState from 'embercom/services/inbox-state';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type Session from 'embercom/services/session';
import type Router from '@ember/routing/router-service';
import {
  replyChannelIsMultiParticipant,
  ReplyChannelMap,
} from 'embercom/objects/inbox/composer-pane';
import { tracked } from '@glimmer/tracking';
import type CommandKService from 'embercom/services/command-k';

interface Args {
  conversation: Conversation;
  hideConversationEvents: boolean;
  onToggleConversationEvents: () => unknown;
  exportConversation: () => void;
  allowManageParticipants?: boolean;
}

interface Signature {
  Args: Args;
}

enum DropdownOption {
  newConversation = 'new-conversation',
  conversationEvents = 'conversation-events',
  exportConversation = 'export-conversation',
  manageParticipants = 'manage-participants',
  mergeInto = 'merge-into',
}

type MoreOptionItem = {
  text: string;
  id: DropdownOption;
  isSelected: boolean;
  icon?: string;
  iconSet?: string;
  hotkeyId?: HotkeyID;
  tooltip?: string;
};

const defaultOptionValues = {
  [DropdownOption.newConversation]: {
    id: DropdownOption.newConversation,
    isSelected: false,
    icon: 'compose',
    iconSet: 'standard',
  },
  [DropdownOption.manageParticipants]: {
    id: DropdownOption.manageParticipants,
    isSelected: false,
    icon: 'multiple-people',
    iconSet: 'standard',
  },
  [DropdownOption.mergeInto]: {
    id: DropdownOption.mergeInto,
    isSelected: false,
    hotkeyId: HotkeyID.MergeInto,
    icon: 'merge',
  },
  [DropdownOption.exportConversation]: {
    id: DropdownOption.exportConversation,
    isSelected: false,
    icon: 'export',
  },
  [DropdownOption.conversationEvents]: {
    id: DropdownOption.conversationEvents,
    hotkeyId: HotkeyID.ToggleEvents,
    icon: 'visible',
  },
};

export default class Inbox2ConversationOptionDropdownComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare router: Router;
  @service declare commandK: CommandKService;

  readonly replyChannelIsMultiParticipant = replyChannelIsMultiParticipant;

  @tracked isManagingParticipants = false;

  get moreOptionItems(): MoreOptionItem[] {
    if (this.session.showLightInbox) {
      return this.lightInboxDropdownOptions();
    }

    let replyChannel = ReplyChannelMap[this.args.conversation.channel.replyChannel];

    let allowManageParticipants =
      this.args.allowManageParticipants && this.replyChannelIsMultiParticipant(replyChannel);

    if (this.args.conversation.isTicket) {
      return this.ticketDropdownOptions(this.args.conversation, allowManageParticipants);
    }

    return this.conversationDropdownOptions(allowManageParticipants);
  }

  get isMergeOptionVisible() {
    let { conversation } = this.args;
    let { ticketType, participantSummaries } = conversation;

    return (
      this.session.workspace.isFeatureEnabled('channels-merge-conversations') &&
      !conversation.isClosed &&
      participantSummaries.length <= 1 &&
      (!ticketType || ticketType.isCustomerTicketType)
    );
  }

  lightInboxDropdownOptions(): MoreOptionItem[] {
    let conversationEvents = {
      ...defaultOptionValues[DropdownOption.conversationEvents],
      text: this.intl.t('inbox.conversation-header.conversation-events'),
      isSelected: !this.args.hideConversationEvents,
    };
    return [conversationEvents];
  }

  conversationDropdownOptions(allowManageParticipants = false): MoreOptionItem[] {
    let manageParticipants = {
      ...defaultOptionValues[DropdownOption.manageParticipants],
      text: this.intl.t('inbox.conversation-header.manage-participants'),
    };

    let newConversation = {
      ...defaultOptionValues[DropdownOption.newConversation],
      text: this.intl.t('inbox.conversation-header.new-conversation'),
    };

    let mergeInto = {
      ...defaultOptionValues[DropdownOption.mergeInto],
      text: this.intl.t('inbox.conversation-header.merge-into'),
    };

    let exportConversation = {
      ...defaultOptionValues[DropdownOption.exportConversation],
      text: this.intl.t('inbox.conversation-header.export-conversation'),
    };

    let conversationEvents = {
      ...defaultOptionValues[DropdownOption.conversationEvents],
      text: this.intl.t('inbox.conversation-header.conversation-events'),
      isSelected: !this.args.hideConversationEvents,
    };

    let options: MoreOptionItem[] = [];

    if (allowManageParticipants) {
      options.push(manageParticipants);
    }

    if (
      this.session.workspace.isFeatureEnabled('channels-merge-conversations') &&
      !this.args.conversation.isClosed &&
      this.args.conversation.participantSummaries.length <= 1
    ) {
      options.push(mergeInto);
    }

    options.push(newConversation, exportConversation, conversationEvents);
    return options;
  }

  ticketDropdownOptions(ticket: Conversation, allowManageParticipants = false): MoreOptionItem[] {
    let manageParticipants = {
      ...defaultOptionValues[DropdownOption.manageParticipants],
      text: this.intl.t('inbox.conversation-header.manage-participants'),
    };

    let newConversation = {
      ...defaultOptionValues[DropdownOption.newConversation],
      text: this.intl.t('inbox.conversation-header.new-conversation'),
    };

    let mergeInto = {
      ...defaultOptionValues[DropdownOption.mergeInto],
      text: this.intl.t('inbox.conversation-header.merge-into'),
    };

    let exportTicket = {
      ...defaultOptionValues[DropdownOption.exportConversation],
      text: this.intl.t('inbox.conversation-header.export-ticket'),
    };

    let ticketEvents = {
      ...defaultOptionValues[DropdownOption.conversationEvents],
      text: this.intl.t('inbox.conversation-header.ticket-events'),
      isSelected: !this.args.hideConversationEvents,
    };

    let options: MoreOptionItem[] = [];

    if (ticket.isCustomerTicket) {
      if (allowManageParticipants) {
        options.push(manageParticipants);
      }
      options.push(newConversation);
    }
    if (
      this.session.workspace.isFeatureEnabled('channels-merge-conversations') &&
      ticket.state !== 'closed' &&
      ticket.participantSummaries.length <= 1 &&
      this.args.conversation?.ticketType?.isCustomerTicketType
    ) {
      options.push(mergeInto);
    }
    options.push(exportTicket, ticketEvents);
    return options;
  }

  @action showManageParticipantsModal() {
    this.isManagingParticipants = true;
  }

  @action showMergeIntoModal() {
    this.commandK.registerAndShow({
      actionID: 'merge-into',
      onSelect: () => {},
    });
  }

  @action onMoreOptionsSelectionChange(item: MoreOptionItem) {
    switch (item.id) {
      case DropdownOption.newConversation:
        this.router.transitionTo('inbox.workspace.inbox.new-conversation', {
          queryParams: {
            recipient: this.args.conversation.userSummary.id,
            ticketTypeId: undefined,
          },
        });
        break;
      case DropdownOption.conversationEvents:
        this.args.onToggleConversationEvents();
        this.intercomEventService.trackAnalyticsEvent({
          object: 'visibility',
          action: 'changed',
          section: 'conversation_header',
          conversation_id: this.args.conversation.id,
          visibility_type: 'events',
          is_visible: !this.args.hideConversationEvents,
        });
        break;
      case DropdownOption.mergeInto:
        this.showMergeIntoModal();
        this.intercomEventService.trackAnalyticsEvent({
          object: 'merge_into',
          action: 'clicked',
          section: 'conversation_header',
          conversation_id: this.args.conversation.id,
          using_modal: true,
        });
        break;
      case DropdownOption.exportConversation:
        this.args.exportConversation();
        break;
      case DropdownOption.manageParticipants:
        this.showManageParticipantsModal();

        this.intercomEventService.trackAnalyticsEvent({
          object: 'manage_participants',
          action: 'clicked',
          section: 'conversation_header',
          conversation_id: this.args.conversation.id,
          using_modal: true,
        });
        break;
      default:
        break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationOptions': typeof Inbox2ConversationOptionDropdownComponent;
    'inbox2/conversation-options': typeof Inbox2ConversationOptionDropdownComponent;
  }
}
