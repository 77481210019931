/* import __COLOCATED_TEMPLATE__ from './transfer-call.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const TransferCall = templateOnlyComponent<Signature>();
export default TransferCall;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::TransferCall': typeof TransferCall;
    'inbox2/conversation-stream/event-parts/transfer-call': typeof TransferCall;
  }
}
