/* import __COLOCATED_TEMPLATE__ from './aggregation-metric.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {
  title: string;
  value: any;
  icon?: string;
  qualifier?: string;
  metricType?: string | undefined;
  tooltipContent?: string;
  awayReasons?: any;
  statusIndicator?: 'active' | 'away' | 'reassigning';
}

interface Signature {
  Args: Args;
}

export default class AggregationMetric extends Component<Signature> {
  @service intl!: IntlService;

  get value() {
    return this.args.value;
  }

  get hasValue() {
    return this.value !== null && this.value !== undefined && this.value !== '';
  }

  get qualifier() {
    return this.args.qualifier || '';
  }

  get text() {
    let potentiallyFormattedValue =
      typeof this.value === 'number' ? this.intl.formatNumber(this.value) : this.value;
    return this.hasValue ? `${potentiallyFormattedValue}${this.qualifier}` : '-';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::SummaryCard::AggregationMetric': typeof AggregationMetric;
    'inbox2/dashboard/summary-card/aggregation-metric': typeof AggregationMetric;
  }
}
