/* import __COLOCATED_TEMPLATE__ from './keypad-key.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Args {
  text: string;
  subText?: string;
  onClick: (keyCode: string) => void;
}

export default class KeypadKey extends Component<Args> {
  @action
  onClick() {
    this.args.onClick(this.args.text);
  }

  @action
  onDoubleClick() {
    if (this.args.subText === '+') {
      this.args.onClick(this.args.subText);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KeypadKey': typeof KeypadKey;
    'inbox2/keypad-key': typeof KeypadKey;
  }
}
