/* import __COLOCATED_TEMPLATE__ from './conversation-sla-applied-by-workflow.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const ConversationSlaAppliedByWorkflow = templateOnlyComponent<Signature>();
export default ConversationSlaAppliedByWorkflow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::ConversationSlaAppliedByWorkflow': typeof ConversationSlaAppliedByWorkflow;
    'inbox2/conversation-stream/event-parts/conversation-sla-applied-by-workflow': typeof ConversationSlaAppliedByWorkflow;
  }
}
