/* import __COLOCATED_TEMPLATE__ from './page-view.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type PageView from 'embercom/objects/inbox/page-view';

interface Signature {
  Args: {
    pageView: PageView;
  };
}

const PageViewComponent = templateOnlyComponent<Signature>();
export default PageViewComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::RecentPageViews::PageView': typeof PageViewComponent;
    'inbox2/conversation-details-sidebar/sidebar-sections/recent-page-views/page-view': typeof PageViewComponent;
  }
}
