/* import __COLOCATED_TEMPLATE__ from './editable-company-name.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { keepLatestTask, restartableTask } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { taskFor } from 'ember-concurrency-ts';
import type CompanyApi from 'embercom/services/company-api';
import { type Awaited } from 'embercom/lib/inbox2/types';
import { useResource } from 'ember-resources';
import KeyboardNavigableItemsResource from 'embercom/components/inbox2/command-k/keyboard-navigable-items-resource';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';

// @ts-ignore
import { localCopy } from 'tracked-toolbox';

interface Args {
  id: string;
  placeholderLabel: string;
  value: string;
  canClear: boolean;
  onUpdateValue: (value: string) => unknown;
  onFinishEditing: () => void;
  setQualificationCompanyForUser: (id: string) => unknown;
  removeQualificationCompanyForUser: () => unknown;
}

interface Signature {
  Args: Args;
}

type CompanySearchResults = Awaited<ReturnType<CompanyApi['search']>>;

export default class Inbox2SidebarSectionsEditableCompanyName extends Component<Signature> {
  @service declare companyApi: CompanyApi;

  @localCopy('args.value') query?: string = this.args.value;

  @tracked companies: CompanySearchResults = [];

  readonly selection = useResource(this, NavigableSelection, () => ({
    items: this.companies,
  }));

  readonly keyboardNavigableItemsResource = useResource(
    this,
    KeyboardNavigableItemsResource,
    () => ({
      selection: this.selection,
      items: this.companies,
      maxItemsPerRow: 1,
    }),
  );

  @action onQueryChanged(event: InputEvent) {
    let target = event.target as HTMLInputElement;
    this.args.onUpdateValue(target.value);
    taskFor(this.searchForCompanies).perform(this.query);
  }

  @action onSubmit() {
    if (taskFor(this.onSelectCompany).isRunning || taskFor(this.onClearCompany).isRunning) {
      return;
    }

    this.args.onFinishEditing();
  }

  @keepLatestTask *onSelectCompany(company: CompanySearchResults[number]) {
    yield this.args.setQualificationCompanyForUser(company.remoteCompanyId as string);
  }

  @keepLatestTask *onClearCompany() {
    yield this.args.removeQualificationCompanyForUser();
  }

  @restartableTask *searchForCompanies(query?: string) {
    yield timeout(ENV.APP._100MS);

    this.companies = yield this.companyApi.search([
      {
        attribute: 'company.name',
        comparison: 'contains',
        value: query?.trim() ?? '',
        type: 'string',
      },
    ]);
  }

  @action resetDefaults() {
    this.companies = [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::EditableCompanyName': typeof Inbox2SidebarSectionsEditableCompanyName;
    'inbox2/conversation-details-sidebar/sidebar-sections/editable-company-name': typeof Inbox2SidebarSectionsEditableCompanyName;
  }
}
