/* import __COLOCATED_TEMPLATE__ from './progress.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type Checklist } from 'embercom/objects/inbox/checklists';
import type IntlService from 'embercom/services/intl';

interface Args {
  checklist: Checklist;
}

interface Signature {
  Args: Args;
}

export default class Progress extends Component<Signature> {
  @service declare intl: IntlService;

  get stepCompletionText() {
    let completedTasks = this.args.checklist.completedTasks.length;
    let allTasks = this.args.checklist.tasks.length;

    if (completedTasks === 0) {
      return this.intl.t('inbox.conversation-details-sidebar.checklists.all-steps-label', {
        allTasks,
      });
    }

    return this.intl.t('inbox.conversation-details-sidebar.checklists.completed-steps-label', {
      allTasks,
      completedTasks,
    });
  }

  get progressPercentStyle() {
    let completedTasks = this.args.checklist.completedTasks.length;
    let allTasks = this.args.checklist.tasks.length;
    let percentage = (completedTasks / allTasks) * 100;

    return `width: ${percentage}%`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Checklists::Progress': typeof Progress;
    'inbox2/conversation-details-sidebar/sidebar-sections/checklists/progress': typeof Progress;
  }
}
