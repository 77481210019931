/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Signature {
  Args: any;
}

export default class Select extends Component<Signature> {
  @service declare inboxHotkeys: any;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::Attribute::Editor::Select': typeof Select;
    'inbox2/conversation-details-sidebar/attribute/editor/select': typeof Select;
  }
}
