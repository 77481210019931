/* import __COLOCATED_TEMPLATE__ from './email-bubble-history.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
// @ts-ignore
import { action } from '@ember/object';
import { request } from 'embercom/lib/inbox/requests';
import { tracked } from '@glimmer/tracking';
import { type EmailMetadata } from 'embercom/objects/inbox/renderable/email-metadata';
import {
  INBOUND_EMAIL_SUPPORTED_TAGS,
  INBOUND_EMAIL_SUPPORTED_ATTRIBUTES,
} from 'embercom/lib/channels/constants';

interface Args {
  metadata?: EmailMetadata;
}

interface Signature {
  Args: Args;
}

export default class EmailBubbleHistoryComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intercomEventService: any;

  @tracked showExpandedContent = false;
  @tracked historyContent = '';
  @tracked hasFetchedEmptyHistory = false;

  supportedTags = INBOUND_EMAIL_SUPPORTED_TAGS;
  supportedAttributes = INBOUND_EMAIL_SUPPORTED_ATTRIBUTES;

  get adminCanSeeBubbleHistory() {
    return !this.session.teammate.hasPricing5LiteSeat;
  }

  @action
  async onToggleExpandedContentButton(emailMetadata?: EmailMetadata) {
    try {
      if (!this.historyContent) {
        let response = await request(
          `/ember/inbox/email_history?metadata_id=${emailMetadata?.id}&app_id=${this.session.workspace.id}`,
        );
        let responseJson = await response.json();
        this.historyContent = responseJson.history;
      }
      this.showExpandedContent = !this.showExpandedContent;
      if (this.showExpandedContent) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'expanded',
          object: 'email_history',
          section: 'email_bubble',
          place: 'inbox',
        });
      }
    } catch (e) {
      if (e.response.status === 404) {
        this.hasFetchedEmptyHistory = true;
      } else {
        throw e;
      }
    }
  }

  get isExternalLinkShieldEnabled() {
    return this.session.workspace?.externalLinkShieldEnabled;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::EmailBubbleHistory': typeof EmailBubbleHistoryComponent;
    'inbox2/conversation-stream/part-groups/email-bubble-history': typeof EmailBubbleHistoryComponent;
  }
}
