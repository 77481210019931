/* import __COLOCATED_TEMPLATE__ from './email-metadata.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import {
  type EmailHeaderAddress,
  type EmailMetadata,
} from 'embercom/objects/inbox/renderable/email-metadata';

interface Args {
  metadata?: EmailMetadata;
}

interface Signature {
  Args: Args;
}

export default class EmailMetadataComponent extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked isCollapsed = true;

  headersNames = ['from', 'reply_to', 'to', 'cc'];

  get headerAddresses() {
    return this.args.metadata?.headerAddresses;
  }

  get headers() {
    let headers: { [key: string]: string } = {};
    if (!this.headerAddresses) {
      return headers;
    }
    for (let headerName of this.headersNames) {
      // @ts-ignore
      let headerValue = this.formatAddressList(this.headerAddresses[headerName]);
      if (headerValue) {
        headers[headerName] = headerValue;
      }
    }
    return headers;
  }

  get date() {
    return this.args.metadata?.date || '';
  }

  get subject() {
    return this.args.metadata?.subject || '';
  }

  get allRecipients() {
    if (!this.headerAddresses) {
      return '';
    }
    let addresses = [];
    if (this.headerAddresses.to) {
      addresses.push(...this.headerAddresses.to);
    }
    if (this.headerAddresses.cc) {
      addresses.push(...this.headerAddresses.cc);
    }

    return addresses.map((address) => this.nameOrAddress(address)).join(', ');
  }

  nameOrAddress(address: EmailHeaderAddress) {
    if (!address.name) {
      return address.email;
    }
    return address.name;
  }

  get fromAddress() {
    if (this.headerAddresses?.from) {
      return this.formatAddressList(this.headerAddresses.from);
    }
    return '';
  }

  formatAddress(address: EmailHeaderAddress) {
    if (!address.name) {
      return address.email;
    }
    return `${address.name} <${address.email}>`;
  }

  formatAddressList(addresses: EmailHeaderAddress[]) {
    if (!addresses) {
      return '';
    }
    return addresses.map((address) => this.formatAddress(address)).join(', ');
  }

  get isTextSelected() {
    let selection = window.getSelection();
    if (selection) {
      return selection.toString() !== '';
    }
    return false;
  }

  @action toggleCollapseState(): void {
    if (this.isTextSelected) {
      return;
    }
    this.isCollapsed = !this.isCollapsed;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::EmailMetadata': typeof EmailMetadataComponent;
    'inbox2/conversation-stream/part-groups/email-metadata': typeof EmailMetadataComponent;
  }
}
