/* import __COLOCATED_TEMPLATE__ from './visibility-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type CustomIntlService from 'embercom/services/intl';
import { MacroVisibility } from 'embercom/objects/inbox/macro';
import { EntityType } from 'embercom/models/data/entity-types';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type Session from 'embercom/services/session';

interface Args {
  visibility: MacroVisibility;
  selectedTeams: TeamSummary[];
  onSelectVisibility: (visibility: { id: MacroVisibility }) => void;
}

interface Signature {
  Args: Args;
}

export default class VisibilitySelector extends Component<Signature> {
  @service declare intl: CustomIntlService;
  @service declare session: Session;

  readonly teamEntity = EntityType.Team;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!this.canManageMacros) {
      this.args.onSelectVisibility({ id: MacroVisibility.Myself });
    }
  }

  get canManageMacros() {
    return this.session.teammate.permissions.canManageMacros;
  }

  get visibility() {
    if (this.canManageMacros) {
      return this.args.visibility;
    } else {
      return MacroVisibility.Myself;
    }
  }

  get selectedTeamsLabel() {
    if (this.args.selectedTeams.length) {
      let selectedTeams = this.args.selectedTeams.map((team) =>
        team.icon ? `${team.icon.emoji} ${team.name}` : team.name,
      );
      return this.intl.formatList(selectedTeams, { style: 'long', type: 'conjunction' });
    } else {
      return this.intl.t('inbox.create-macro.team-selector.placeholder');
    }
  }

  get items() {
    return [MacroVisibility.Everyone, MacroVisibility.Teams, MacroVisibility.Myself].map(
      (visibility) => ({
        id: visibility,
        label: this.intl.t(`inbox.create-macro.available-for.${visibility}`),
        isSelected: this.visibility === visibility,
        isDisabled: this.canManageMacros ? false : visibility !== MacroVisibility.Myself,
      }),
    );
  }

  get showTeamsDropdown() {
    return this.args.visibility === MacroVisibility.Teams;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CreateMacro::VisibilitySelector': typeof VisibilitySelector;
    'inbox2/create-macro/visibility-selector': typeof VisibilitySelector;
  }
}
