/* import __COLOCATED_TEMPLATE__ from './expandable-section.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import fade from 'ember-animated/transitions/fade';
import { EscKeyPriority } from 'embercom/lib/inbox2/types';

export interface ExpandableSectionArgs {
  name: string;
  isExpanded: boolean;
  expandableSheet: HTMLElement;
  onExpandedSectionChange: (id: number | string | undefined) => unknown;
  sidebarSectionId?: string;
  disableExpand?: boolean;
  paddingClasses?: string;
  primaryActionClass?: string;
  showMergeLead?: boolean;
  onMergeLead?: (event: MouseEvent) => unknown;
  isPreviewingConversation?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: ExpandableSectionArgs;
  Blocks: {
    default: [];
    actions: [];
    expanded: [];
  };
}

export default class ExpandableSection extends Component<Signature> {
  rules({ newItems }: { newItems: [boolean] }) {
    if (newItems[0] === false) {
      return fade;
    }
    return undefined;
  }

  get escPriority() {
    return EscKeyPriority.sidebarPreview;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::ExpandableSection': typeof ExpandableSection;
    'inbox2/conversation-details-sidebar/expandable-section': typeof ExpandableSection;
  }
}
