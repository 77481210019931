/* import __COLOCATED_TEMPLATE__ from './selected-conversations-placeholder.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const SelectedConversationsPlaceholder = templateOnlyComponent<Signature>();
export default SelectedConversationsPlaceholder;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::SelectedConversationsPlaceholder': typeof SelectedConversationsPlaceholder;
    'inbox2/common/selected-conversations-placeholder': typeof SelectedConversationsPlaceholder;
  }
}
