/* import __COLOCATED_TEMPLATE__ from './hotkey.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { capitalize } from '@ember/string';

interface Args {
  keys: string[];
  theme?: 'light' | 'dark';
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class Hotkey extends Component<Signature> {
  get keys() {
    return (this.args.keys ?? []).map((key) => capitalize(key));
  }

  get theme() {
    return this.args.theme ?? 'light';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Hotkey': typeof Hotkey;
  }
}
