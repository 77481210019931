/* import __COLOCATED_TEMPLATE__ from './progress-bar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import type ApplicationInstance from '@ember/application/instance';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type Named, Resource, useResource } from 'ember-resources';
import ENV from 'embercom/config/environment';

import { registerDestructor } from '@ember/destroyable';

interface Args {
  isUploading: boolean;
}

interface Signature {
  Args: Args;
}

// When the composer is disabled because there are some uploads (images /
// attachments) in the background, we show a progress bar, but it cannot be
// deterministic because we don't know how long it'll take to upload, or what
// the current progress is.

// This component implements a simple non-deterministic progress bar optimised
// for smaller (<10mb) files. It'll go faster at the start, then slow down as it
// reaches the end, stopping at 99.

class ProgressResource extends Resource<Named<Args>> {
  @tracked progress = 0;
  @tracked isProgressBarVisible = false;

  private isUploading = false;

  constructor(owner: ApplicationInstance, args: Named<Args>, previous: ProgressResource) {
    super(owner, args, previous);

    this.isUploading = this.args.named.isUploading;

    if (previous) {
      if (previous.isUploading && !this.isUploading) {
        // When the progress completes, we want to set the progress bar to 100%
        // and wait for 200ms before resetting progress. That gives the progress
        // bar container ample time to animate itself out.
        this.progress = 100;
        this.isProgressBarVisible = false;
        taskFor(this.resetProgress).perform();
      } else if (!previous.isUploading && this.isUploading) {
        // If we're starting to upload, reset all state.
        this.progress = 0;
        this.isProgressBarVisible = true;
      }
    }

    if (this.isUploading) {
      this.isProgressBarVisible = true;
      taskFor(this.timer).perform();
    }

    registerDestructor(this, () => {
      taskFor(this.timer).cancelAll();
    });
  }

  private get remaining() {
    return 100 - this.progress;
  }

  @task private *timer() {
    yield timeout(100);

    // We progress by 5% of whatever is remaining every 100ms. This simulates a slowdown
    // over time, and it really slows down after 90%, moving by 2% of remaining.
    let divisor = 20;
    if (this.progress > 90) {
      divisor = 50;
    }

    this.progress = Math.min(99, this.progress + this.remaining / divisor);

    // We don't want infinite loops in tests.
    if (ENV.environment !== 'test') {
      taskFor(this.timer).perform();
    }
  }

  @task private *resetProgress() {
    yield timeout(200);
    this.progress = 0;
  }
}

export default class Inbox2ComposerProgressBar extends Component<Signature> {
  get progress() {
    return this.#progressResource.progress;
  }

  get isVisible() {
    return this.#progressResource.isProgressBarVisible;
  }

  readonly #progressResource = useResource(this, ProgressResource, () => ({
    isUploading: this.args.isUploading,
  }));
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationReplyComposer::ProgressBar': typeof Inbox2ComposerProgressBar;
    'inbox2/conversation-reply-composer/progress-bar': typeof Inbox2ComposerProgressBar;
  }
}
