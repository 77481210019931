/* import __COLOCATED_TEMPLATE__ from './background-gradient.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import { trackedReset } from 'tracked-toolbox';
// @ts-expect-error
import { scheduler } from 'ember-raf-scheduler';

export interface CopilotBackgroundGradientArgs {
  copilotAnswerIsProcessing?: boolean;
  alwaysSubtle?: boolean;
}

interface Signature {
  Args: CopilotBackgroundGradientArgs;
}

export type CopilotBackgroundAnimationState = 'static-subtle' | 'static-bright' | 'moving';

const copilotInputHasFocus = () =>
  Boolean(document.activeElement?.closest('[data-copilot-input-container]'));

export default class BackgroundGradient extends Component<Signature> {
  @tracked
  copilotInputHasFocus = false;

  // we want to revert the animation state back to the "subtle" state when we
  // settle after answer generation. Allowing this property to be reset back to
  // false when the answer stops processing facilitates this. The focus tracking
  // logic allows us to re-enter the "bright" state as long as the composer has
  // lost and regained focus since the last "moving" animation.
  @trackedReset({
    memo: 'args.copilotAnswerIsProcessing',
    update() {
      return !copilotInputHasFocus();
    },
  })
  animationCanBeBright = true;

  get animationState(): CopilotBackgroundAnimationState {
    if (this.args.copilotAnswerIsProcessing) {
      return 'moving';
    } else if (this.copilotInputHasFocus && this.animationCanBeBright) {
      return 'static-bright';
    }
    return 'static-subtle';
  }

  focusListenerModifier = modifier((_element: SVGElement, _positional, _named) => {
    if (this.args.alwaysSubtle) {
      return () => {};
    }
    setTimeout(() => {
      // the auto-focus-modifier on the copilot input hasn't run yet so we wait
      // 50ms to check if we have focus on initial render.
      this.copilotInputHasFocus = copilotInputHasFocus();
      if (this.copilotInputHasFocus) {
        // the input had focus on initial render, so we allow it to ignore the
        // "subtle" post animation treatment
        this.animationCanBeBright = true;
      }
    }, 50);

    let handleFocusChange = () => {
      scheduler.schedule('sync', () => {
        this.copilotInputHasFocus = copilotInputHasFocus();
        if (!this.copilotInputHasFocus) {
          // the composer has lost focus since the last animation
          this.animationCanBeBright = true;
        }
      });
    };

    window.addEventListener('focus', handleFocusChange, true);
    window.addEventListener('blur', handleFocusChange, true);

    return () => {
      window.removeEventListener('focus', handleFocusChange, true);
      window.removeEventListener('blur', handleFocusChange, true);
    };
  });

  randomWalkModifier = modifier(
    (
      element: HTMLDivElement | SVGGElement,
      _positional,
      {
        xMin,
        xMax,
        motionInterval = 1000,
      }: {
        xMin?: number;
        xMax?: number;
        motionInterval?: number;
      },
    ) => {
      if (this.args.alwaysSubtle) {
        return () => {};
      }
      let x = 0;
      let y = 0;
      let scale = 1;
      let transitionDurationMs = motionInterval;

      element.style.transitionDuration = `${transitionDurationMs}ms`;
      element.style.transitionTimingFunction = 'ease-in-out';
      element.style.transformBox = 'fill-box';

      let interval = setInterval(() => {
        let xScalar = 0;
        let xClampMin = 0;
        let xClampMax = 0;
        let yScalar = 0;
        let yClampMin = 0;
        let yClampMax = 0;
        let scaleScalar = 0;
        let scaleMin = 1;
        let scaleMax = 1;

        if (this.animationState === 'moving') {
          xScalar = 25;
          xClampMin = xMin || -30;
          xClampMax = xMax || 30;
          yScalar = 25;
          yClampMin = 0;
          yClampMax = 10;
          scaleScalar = 0.1;
          scaleMin = 0.65;
          scaleMax = 1;

          // movement
          x += Math.round(xScalar * Math.random() * (Math.random() > 0.5 ? 1 : -1));
          y += Math.round(yScalar * Math.random() * (Math.random() > 0.5 ? 1 : -1));
          scale += scaleScalar * Math.random() * (Math.random() > 0.5 ? 1 : -1);

          performance.measure(`Fin AI Copilot: glow animation`, {
            start: performance.now(),
            duration: transitionDurationMs,
            detail: {
              x,
              y,
              scale,
              transitionDurationMs,
              inputHasFocus: this.copilotInputHasFocus,
              animationCanBeBright: this.animationCanBeBright,
              answerIsProcessing: this.args.copilotAnswerIsProcessing,
              animationState: this.animationState,
            },
          });
        }

        // clamping
        x = Math.max(xClampMin, Math.min(xClampMax, x));
        y = Math.max(yClampMin, Math.min(yClampMax, y));
        scale = Math.max(scaleMin, Math.min(scaleMax, scale));

        // DOM manipulation
        element.style.transform = `translate(${x}%, ${-Math.abs(y)}%) scale(${scale.toFixed(2)})`;
      }, transitionDurationMs);

      return () => clearInterval(interval);
    },
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::BackgroundGradient': typeof BackgroundGradient;
    'inbox2/copilot/background-gradient': typeof BackgroundGradient;
  }
}
