/* import __COLOCATED_TEMPLATE__ from './insert-article-action.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { action } from '@ember/object';
import type InboxApi from 'embercom/services/inbox-api';
import { type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import type ArticlesApi from 'embercom/services/articles-api';
import { type DisplayContext } from 'embercom/services/command-k';

interface Args {
  conversationId?: number;
  composerApi?: ComposerPublicAPI;
  priority?: number;
  trackingData?: Record<string, unknown>;
  activatingComponent?: `${DisplayContext}`;
}

interface Signature {
  Args: Args;
}

export default class InsertArticleAction extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare articlesApi: ArticlesApi;

  @action async insertArticle(id: string, _context: unknown, metadata: any, trackingData?: any) {
    let card = await this.inboxApi.createMessengerCard(
      id,
      metadata.locale ?? 'en',
      metadata.heading ?? {},
      this.args.conversationId,
      trackingData?.help_center_id && trackingData?.help_center_id !== 'unlisted'
        ? trackingData?.help_center_id
        : undefined,
    );

    this.args.composerApi?.composer.commands.focus();
    this.args.composerApi?.composer.commands.insertBlock(card);
    this.args.composerApi?.composer.commands.insertNewlineAndSelect();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::CommandK::InsertArticleAction': typeof InsertArticleAction;
    'inbox2/composer/command-k/insert-article-action': typeof InsertArticleAction;
  }
}
