/* import __COLOCATED_TEMPLATE__ from './reconnect-shopify.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import FormForDownload from 'embercom/lib/form-for-download';

interface Args {
  context: object;
  toBeAbleTo: string;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ReconnectShopify extends Component<Signature> {
  @service declare intercomEventService: any;

  @action
  openShopifyScopeRequestUrl() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'reconnect_shopify_to_intercom_button',
      context: this.args.context,
    });
    FormForDownload.postNewTab('https://iis.intercom.io/login');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Shopify::ReconnectShopify': typeof Inbox2ReconnectShopify;
    'inbox2/conversation-details-sidebar/sidebar-sections/shopify/reconnect-shopify': typeof Inbox2ReconnectShopify;
  }
}
