/* import __COLOCATED_TEMPLATE__ from './delete-message-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    onClose: () => void;
    onDelete: () => void;
  };
}

const DeleteMessageModal = templateOnlyComponent<Signature>();
export default DeleteMessageModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::DeleteMessageModal': typeof DeleteMessageModal;
    'inbox2/conversation-stream/part-groups/delete-message-modal': typeof DeleteMessageModal;
  }
}
