/* import __COLOCATED_TEMPLATE__ from './order.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class Inbox2Order extends Component<{
  Args: {
    order: any;
  };
}> {
  @tracked isExpanded = false;

  @action
  collapseOrExpand() {
    this.isExpanded = !this.isExpanded;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Shopify::Order': typeof Inbox2Order;
    'inbox2/conversation-details-sidebar/sidebar-sections/shopify/order': typeof Inbox2Order;
  }
}
