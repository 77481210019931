/* import __COLOCATED_TEMPLATE__ from './ticket-created-at-value.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';

interface Signature {
  Args: {
    createdAt: Date | undefined;
  };
}

export default class TicketCreatedAtValue extends Component<Signature> {
  get createdAt() {
    return this.args.createdAt ?? '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::TicketPanel::TicketCreatedAtValue': typeof TicketCreatedAtValue;
  }
}
