/* import __COLOCATED_TEMPLATE__ from './datetime.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  value: string | null;
  onUpdateValue: (value: unknown) => unknown;
  onFinishEditing: () => unknown;
  isReadOnly?: boolean;
  placeholder?: string;
}

interface Signature {
  Args: Args;
}

export default class Datetime extends Component<Signature> {
  // Unknown why we're using appService here
  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service declare appService: any;

  @tracked _hour: number = this.valueAsMoment.hour();
  @tracked _minute: number = this.valueAsMoment.minute();

  get timezone() {
    return this.appService.app.timezone;
  }

  get selectedValue() {
    let { value } = this.args;
    if (value === '' || value === null) {
      return undefined;
    }

    return value;
  }

  get valueAsMoment() {
    if (this.selectedValue) {
      return moment(this.selectedValue);
    } else {
      return moment();
    }
  }

  get minute() {
    return String(this.valueAsMoment.minute()).padStart(2, '0');
  }

  set minute(minute: string) {
    this._minute = Number(minute);
    this.changeTime();
  }

  get hour() {
    return String(this.valueAsMoment.hour()).padStart(2, '0');
  }

  set hour(hour: string) {
    this._hour = Number(hour);
    this.changeTime();
  }

  _changeAtInTimezone() {
    return moment(this.valueAsMoment);
  }

  get selectedDate() {
    return this._changeAtInTimezone();
  }

  @action
  changeDate(date: any) {
    let changeAtCopy = this._changeAtInTimezone();
    changeAtCopy.set({
      date: date.moment.date(),
      month: date.moment.month(),
      year: date.moment.year(),
    });
    this.updateAttribute(changeAtCopy.toDate());
  }

  @action
  changeTime() {
    let changeAtCopy = this._changeAtInTimezone();
    changeAtCopy.set({
      hour: this._hour,
      minute: this._minute,
    });
    this.updateAttribute(changeAtCopy.toDate());
  }

  @action
  clear() {
    this.args.onUpdateValue(null);
  }

  @action
  updateAttribute(date: Date) {
    this.args.onUpdateValue(date.toISOString());
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::Attribute::Editor::Datetime': typeof Datetime;
    'inbox2/conversation-details-sidebar/attribute/editor/datetime': typeof Datetime;
  }
}
