/* import __COLOCATED_TEMPLATE__ from './qualification.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const Qualification = templateOnlyComponent<Signature>();
export default Qualification;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Qualification': typeof Qualification;
    'inbox2/conversation-details-sidebar/sidebar-sections/qualification': typeof Qualification;
  }
}
