/* import __COLOCATED_TEMPLATE__ from './bot-default-avatar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { transformAvatarUrl } from 'embercom/objects/inbox/inboxes/avatars';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    tiny?: boolean;
    small?: boolean;
    admin?: AdminSummary;
  };
}

export default class BotDefaultAvatar extends Component<Signature> {
  @service declare session: Session;

  get imageURL(): string | undefined {
    let url = this.args.admin?.imageURL;
    if (!url) {
      return;
    }

    return transformAvatarUrl(url, this.session.darkModeEnabled);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::BotDefaultAvatar': typeof BotDefaultAvatar;
    'inbox2/common/bot-default-avatar': typeof BotDefaultAvatar;
  }
}
