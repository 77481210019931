/* import __COLOCATED_TEMPLATE__ from './filter-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const FilterSelector = templateOnlyComponent<Signature>();
export default FilterSelector;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::FilterSelector': typeof FilterSelector;
    'inbox2/dashboard/filter-selector': typeof FilterSelector;
  }
}
