/* import __COLOCATED_TEMPLATE__ from './add-tag-to-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const AddTagToConversation = templateOnlyComponent<Signature>();
export default AddTagToConversation;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::UseMacro::Preview::AddTagToConversation': typeof AddTagToConversation;
    'inbox2/command-k/use-macro/preview/add-tag-to-conversation': typeof AddTagToConversation;
  }
}
