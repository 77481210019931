/* import __COLOCATED_TEMPLATE__ from './workspace.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import AdminSummary from 'embercom/objects/inbox/admin-summary';

export default class WorkspaceLoader extends Component<{
  Blocks: {
    default: [];
  };
}> {
  @service declare appService: any;
  @service session!: Session;

  constructor(owner: unknown, args: {}) {
    super(owner, args);
    this.session.switchWorkspace(this.appService.app.id);

    let admin = this.appService.app.currentAdmin;
    let adminSummary = new AdminSummary(admin.id, admin.name, admin.avatarUrl);

    // @ts-ignore
    this.session._teammate = adminSummary;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Adapters::Workspace': typeof WorkspaceLoader;
    'inbox2/adapters/workspace': typeof WorkspaceLoader;
  }
}
