/* import __COLOCATED_TEMPLATE__ from './conversation-preview.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import type { ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import { inject as service } from '@ember/service';
import type InboxApi from 'embercom/services/inbox-api';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type TicketStateService from 'embercom/services/ticket-state-service';

interface Args {
  conversationSummary: ConversationRecord;
}

export default class ConversationPreview extends Component<Args> {
  @service declare inboxApi: InboxApi;
  @service declare ticketStateService: TicketStateService;

  conversationLoader = DeduplicatedAsyncData(
    this,
    () => [this.args.conversationSummary.id],
    async (conversationID, { signal }) => {
      return await this.inboxApi.fetchConversation(conversationID, undefined, { signal });
    },
  );

  get conversation(): Conversation | undefined {
    return this.conversationLoader.value;
  }

  get title(): string {
    let convo = this.args.conversationSummary;
    return (convo.isTicket ? convo.ticketTitle : convo.title) as string;
  }

  get conversationResource() {
    return {
      conversation: this.conversation,
    };
  }

  get ticketState() {
    return this.currentTicketCustomState.systemState;
  }

  get currentTicketCustomState(): TicketCustomState {
    return this.ticketStateService.getTicketCustomStateById(
      this.args.conversationSummary.ticketCustomStateId,
    );
  }

  get ticketStateCssClass() {
    if (!this.args.conversationSummary.ticketState) {
      return '';
    }

    return {
      [TicketSystemState.Submitted]: 'ticket-state__submitted',
      [TicketSystemState.InProgress]: 'ticket-state__in-progress',
      [TicketSystemState.WaitingOnCustomer]: 'ticket-state__waiting-on-customer',
      [TicketSystemState.Resolved]: 'ticket-state__resolved',
    }[this.args.conversationSummary.ticketState];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::MergeInto::ConversationPreview': typeof ConversationPreview;
    'inbox2/command-k/merge-into/conversation-preview': typeof ConversationPreview;
  }
}
