/* import __COLOCATED_TEMPLATE__ from './send-and-update-state-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const SendAndUpdateStateItem = templateOnlyComponent<Signature>();
export default SendAndUpdateStateItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::SendAndUpdateStateItem': typeof SendAndUpdateStateItem;
    'inbox2/composer/send-and-update-state-item': typeof SendAndUpdateStateItem;
  }
}
