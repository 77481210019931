/* import __COLOCATED_TEMPLATE__ from './relevant-part.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type IntlService from 'embercom/services/intl';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { type Block } from '@intercom/interblocks.ts';

interface Signature {
  Args: {
    relevantConversationEntry: ConversationTableEntry;
  };
  Element: HTMLElement;
}

export default class RelevantPartCell extends Component<Signature> {
  @service declare intl: IntlService;
  RenderableType = RenderableType;

  get relevantPart() {
    return this.args.relevantConversationEntry.relevantPart;
  }

  get highlights() {
    return this.relevantPart?.highlights ?? [];
  }

  get titleBlock(): Block[] {
    return [
      {
        type: 'paragraph',
        text: this.args.relevantConversationEntry.title ?? '',
      },
    ];
  }

  get conversationLevelHighlights() {
    return this.args.relevantConversationEntry.highlights;
  }

  get titleHighlights() {
    return this.args.relevantConversationEntry.highlights?.title ?? [];
  }

  get ticketDescriptionHighlights() {
    return this.args.relevantConversationEntry.highlights?.ticketDescription ?? [];
  }

  get hasMoreMatches() {
    return (
      this.conversationLevelHighlights?.parts && this.conversationLevelHighlights.parts.length > 1
    );
  }

  get hasTicketDescriptionMatches() {
    return (
      this.conversationLevelHighlights?.ticketDescription &&
      this.conversationLevelHighlights?.ticketDescription?.length > 0
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::RelevantPart': typeof RelevantPartCell;
  }
}
