/* import __COLOCATED_TEMPLATE__ from './macro-suggestion-toolbar.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';

interface Signature {
  Args: {
    onShow: () => void;
    onReject: () => void;
    onClick: () => void;
  };
  Element: HTMLDivElement;
}

export default class MacroSuggestionToolbar extends Component<Signature> {
  onShow = modifier(
    () => {
      this.args.onShow();
    },
    { eager: false },
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Suggestions::MacroSuggestionToolbar': typeof MacroSuggestionToolbar;
  }
}
