/* import __COLOCATED_TEMPLATE__ from './dropdown-button-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type Optionable } from './dropdown-button';

interface Signature {
  Args: {
    option: Optionable;
    selected: Optionable['id'];
    notInteractive?: boolean;
    onClick: () => void;
  };
  Blocks: {
    default: [];
  };
}

const DropdownDownButtonListItem = templateOnlyComponent<Signature>();
export default DropdownDownButtonListItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::DropdownButtonListItem': typeof DropdownDownButtonListItem;
    'inbox2/common/dropdown-button-list-item': typeof DropdownDownButtonListItem;
  }
}
