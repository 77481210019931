/* import __COLOCATED_TEMPLATE__ from './editable-conversation-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type IntlService from 'embercom/services/intl';
import { keepLatestTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type Session from 'embercom/services/session';
import type ConditionalAttributesService from 'embercom/services/conditional-attributes-service';

interface Args {
  attribute?: ConversationAttributeSummary;
  descriptor: ConversationAttributeDescriptor;
  onUpdate: (attribute: ConversationAttributeSummary) => unknown;
  conditionsEvaluator?: ConditionalAttributesService;
  showPlaceholder: boolean;
}

interface Signature {
  Args: Args;
}

export default class EditableAttribute extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare notificationsService: any;

  @trackedReset('args.attribute') attribute =
    this.args.attribute ?? new ConversationAttributeSummary(undefined, this.args.descriptor);

  get listOptions() {
    let descriptor = this.descriptor as ConversationAttributeDescriptor;
    if (!descriptor.listOptions) {
      return [];
    }

    return descriptor.listOptions
      .filter((option: any) => {
        return (
          this.isOptionAccessible(option) &&
          (!option.archived || option.id === this.attribute.value)
        );
      })
      .map((option: any) => ({
        text: option.label,
        value: option.id,
      }));
  }

  isOptionAccessible(option: any) {
    if (!this.session.workspace.canUseConditionalAttributes || !this.args.conditionsEvaluator) {
      return true;
    }

    return this.args.conditionsEvaluator?.listOptions[this.descriptor.id]?.includes(option.id);
  }

  get descriptor() {
    if (this.args.descriptor) {
      return this.args.descriptor;
    }

    return this.attribute.descriptor;
  }

  get label() {
    return this.descriptor.name;
  }

  get isReadOnly() {
    return this.session.showLightInbox || !this.descriptor.editable;
  }

  @action async onFinishEditing() {
    await taskFor(this.updateAttribute).perform();
  }

  @action onUpdateAttributeValue(value: string | number | boolean | undefined) {
    this.attribute.value = value;
  }

  @action onUpdateFileAttributeValue(value: any | undefined) {
    this.attribute.value = value;
    this.onFinishEditing();
  }

  @keepLatestTask
  *updateAttribute() {
    let { attribute } = this;

    if (!attribute.isUpdated) {
      return;
    }

    if (!attribute.isValueValid) {
      attribute.rollback();

      this.notificationsService.notifyError(
        this.intl.t('inbox.conversation-details-sidebar.attributes.errors.invalid', {
          attributeName: attribute.descriptor.name,
        }),
      );
      return;
    }

    try {
      attribute.normalizeValue();
      yield this.args.onUpdate(attribute);
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.conversation-details-sidebar.attributes.errors.general'),
      );
    }
  }

  get placeholderTranslationKey() {
    return 'inbox.conversation-attributes.select-placeholder';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::EditableConversationAttribute': typeof EditableAttribute;
    'inbox2/conversation-details-sidebar/editable-conversation-attribute': typeof EditableAttribute;
  }
}
