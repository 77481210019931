/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const List = templateOnlyComponent<Signature>();
export default List;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::TriggerWorkflow::List': typeof List;
    'inbox2/command-k/trigger-workflow/list': typeof List;
  }
}
