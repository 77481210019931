/* import __COLOCATED_TEMPLATE__ from './list-source-opener.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import {
  type CopilotInteractionMetadata,
  type NormalizedFinSource,
} from 'embercom/lib/inbox2/copilot/types';
import { EntityType } from 'embercom/models/data/entity-types';

export interface ListSourceOpenerArgs {
  source: NormalizedFinSource;
  entityName: string;
  interactionMetadata: CopilotInteractionMetadata;
  insertBlocks: () => void;
  iconClassNames: string;
}

interface Signature {
  Element: Element;
  Args: ListSourceOpenerArgs;
  Blocks: {
    default: [];
  };
}
export default class ListSourceOpener extends Component<Signature> {
  get isWebpage() {
    return this.args.source?.entity_type === EntityType.ExternalContent;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Sources::ListSourceOpener': typeof ListSourceOpener;
    'inbox2/copilot/source/list-source-opener': typeof ListSourceOpener;
  }
}
