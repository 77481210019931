/* import __COLOCATED_TEMPLATE__ from './merged-conversation-reply.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type MergedConversationReply from 'embercom/objects/inbox/renderable/merged-conversation-reply';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';

export interface MergedConversationReplyPart extends RenderablePart {
  renderableData: MergedConversationReply;
}

interface Args {
  conversationId: number;
  part: MergedConversationReplyPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsMergedConversationReplyComponent extends Component<Signature> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;

  get userDisplayName() {
    return this.args.part.renderableData.userDisplayName;
  }

  get blocks() {
    return this.args.part.renderableData.blocks;
  }

  get mergedConversationId() {
    return this.args.part.renderableData.mergedConversationId;
  }

  get createdAt() {
    return this.args.part.createdAt;
  }

  @action openMergedConversation() {
    this.inboxSidebarService.previewMergedConversation(
      this.mergedConversationId,
      this.args.conversationId,
    );

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'merged_conversation',
      section: 'respond',
      place: 'conversation_part',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::MergedConversationReply': typeof Inbox2ConversationStreamEventPartsMergedConversationReplyComponent;
    'inbox2/conversation-stream/event-parts/merged-conversation-reply': typeof Inbox2ConversationStreamEventPartsMergedConversationReplyComponent;
  }
}
