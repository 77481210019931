/* import __COLOCATED_TEMPLATE__ from './close-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type MacroAction } from 'embercom/objects/inbox/macro';

interface Signature {
  Args: { action: MacroAction };
}

const CloseConversation = templateOnlyComponent<Signature>();
export default CloseConversation;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::UseMacro::Preview::CloseConversation': typeof CloseConversation;
    'inbox2/command-k/use-macro/preview/close-conversation': typeof CloseConversation;
  }
}
