/* import __COLOCATED_TEMPLATE__ from './external-profiles.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import type IntegrationsApi from 'embercom/services/integrations-api';
import type CrmProfilesApi from 'embercom/services/crm-profiles-api';
import type Session from 'embercom/services/session';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import { taskFor } from 'ember-concurrency-ts';

type Args = SidebarSectionComponentArgs<{
  name: string;
  sidebarSection: SidebarSection;
  isOpen: boolean;
  isExpanded: boolean;
  onExpandedSectionChange: () => unknown;
  onOpenSectionChange: () => unknown;
  conversationId: number;
  userId: string;
}>;

interface Signature {
  Args: Args;
}

export default class SidebarSectionExternalProfiles extends Component<Signature> {
  readonly sectionId = 'external-profiles';

  @service declare session: Session;
  @service declare integrationsApi: IntegrationsApi;
  @service declare crmProfilesApi: CrmProfilesApi;

  private pipedriveProfile = DeduplicatedAsyncData(
    this,
    () => [this.args.userId],
    (userId, { signal }) => {
      return this.crmProfilesApi.fetchPipedriveProfile(userId, { signal });
    },
  );

  get createPipedriveDealIsRunning() {
    return taskFor(this.createPipedriveDeal).isRunning;
  }

  @dropTask *createPipedriveDeal(): TaskGenerator<void> {
    let profile = yield this.crmProfilesApi.createPipedriveDeal(
      this.args.userId,
      this.args.conversationId,
    );

    this.pipedriveProfile.update(profile);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::ExternalProfiles': typeof SidebarSectionExternalProfiles;
    'inbox2/conversation-details-sidebar/sidebar-sections/external-profiles': typeof SidebarSectionExternalProfiles;
  }
}
