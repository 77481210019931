/* import __COLOCATED_TEMPLATE__ from './editable-name-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type AttributeComponentArgs } from 'embercom/lib/inbox2/sidebar-types';

function hasValueChanged(currentValue: any, newValue: any) {
  if (isEmpty(currentValue) && isEmpty(newValue)) {
    return false;
  }
  return currentValue !== newValue;
}

type Args = AttributeComponentArgs & {
  conversation: Conversation;
  role: string;
  fallbackText?: string;
};

interface Signature {
  Args: Args;
}

export default class EditableNameAttributeComponent extends Component<Signature> {
  @tracked isEditing = false;
  @tracked newValue: unknown;

  get attributeName() {
    return this.args.attribute.humanFriendlyName;
  }

  get attributeValue() {
    let { user, attribute } = this.args;

    return (user?.attributes?.[attribute.key] || attribute?.value) as string;
  }

  @action
  enableEditMode() {
    this.newValue = this.attributeValue;
    this.isEditing = true;
  }

  @action
  disableEditMode() {
    this.isEditing = false;
  }

  @action
  async finishEditing() {
    if (!this.isEditing) {
      return;
    }

    if (hasValueChanged(this.attributeValue, this.newValue)) {
      await this.args.onUserAttributeUpdated(this.args.attribute, this.newValue);
    }

    this.disableEditMode();
  }

  get canShowFallbackText(): boolean {
    return !this.isEditing && !this.attributeValue && !!this.args.fallbackText;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::EditableNameAttribute': typeof EditableNameAttributeComponent;
    'inbox2/conversation-details-sidebar/sidebar-sections/editable-name-attribute': typeof EditableNameAttributeComponent;
  }
}
