/* import __COLOCATED_TEMPLATE__ from './linked-ticket.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {
  conversation: any;
  handleConversationClick: (conversation: unknown) => void;
  readonly?: boolean;
  emojiUri?: string;
  ticketTypeName: string;
  previewOnly?: boolean;
}

interface Signature {
  Args: Args;
}

export default class LinkedTicketComponent extends Component<Signature> {
  @service declare intl: IntlService;

  get linkedTicketVisibilityTooltip() {
    return this.args.conversation.visibleToUser
      ? this.visibleLinkedTicketTooltip
      : this.hiddenLinkedTicketTooltip;
  }

  get visibleLinkedTicketTooltip() {
    return `${this.intl.t(`inbox.linked-tickets.shared-ticket`)} ${this.intl.t(
      `inbox.linked-tickets.hidden-ticket`,
    )} ${this.intl.t(`inbox.linked-tickets.shared-ending`)}`;
  }

  get hiddenLinkedTicketTooltip() {
    return `${this.intl.t('inbox.linked-tickets.not-shared-ticket')} ${this.intl.t(
      'inbox.linked-tickets.hidden-ticket',
    )}`;
  }

  get displayTicketInfo() {
    let { conversation } = this.args;
    let ticketId = `#${conversation.ticketId}`;
    if (conversation.ticketType?.name === conversation.ticketTitle) {
      return ticketId;
    } else {
      return `${ticketId} • ${conversation.ticketType?.name}`;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Links::LinkedTicket': typeof LinkedTicketComponent;
    'inbox2/conversation-details-sidebar/sidebar-sections/links/linked-ticket': typeof LinkedTicketComponent;
  }
}
