/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import type AssignToAction from 'embercom/objects/inbox/command-k/assign-to';

interface Args {
  actionItem: ChildAction;
  selected: any;
  currentAction?: AssignToAction;
}

interface Signature {
  Args: Args;
}

export default class AssignToOption extends Component<Signature> {
  get usesAvatarNotIcon() {
    return this.args.actionItem.value instanceof AdminSummary;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::AssignTo::Option': typeof AssignToOption;
    'inbox2/command-k/assign-to/option': typeof AssignToOption;
  }
}
