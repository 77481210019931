/* import __COLOCATED_TEMPLATE__ from './table-header-action-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const TableHeaderActionItem = templateOnlyComponent<Signature>();
export default TableHeaderActionItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableHeaderActionItem': typeof TableHeaderActionItem;
    'inbox2/conversations-table/table-header-action-item': typeof TableHeaderActionItem;
  }
}
