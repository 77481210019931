/* import __COLOCATED_TEMPLATE__ from './unknown-participant-for-email-conversation-note.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type InboxState from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type DuplicateConversationSummary from 'embercom/objects/inbox/duplicate-conversation-summary';
import { action } from '@ember/object';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type UnknownParticipantForEmailConversationNote from 'embercom/objects/inbox/renderable/unknown-participant-for-email-conversation-note';

export interface UnknownParticipantForEmailConversationNoteRenderablePart extends RenderablePart {
  renderableData: UnknownParticipantForEmailConversationNote;
}

interface UnknownParticipantForEmailConversationNotePartGroup extends PartGroup {
  parts: Array<UnknownParticipantForEmailConversationNoteRenderablePart>;
}

interface Args {
  partGroup: UnknownParticipantForEmailConversationNotePartGroup;
  isReadOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class UnknownParticipantForEmailConversationNoteComponent extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare inboxSidebarService: InboxSidebarService;

  @action onConversationClick(conversationSummary: DuplicateConversationSummary) {
    this.inboxSidebarService.previewConversation(conversationSummary);
  }

  get teammate(): AdminSummary | undefined {
    return this.args.partGroup.parts[0]?.renderableData?.conversationSummary?.adminAssignee;
  }

  get isSplitConversation(): boolean {
    let related_conversation_id =
      this.args.partGroup.parts[0]?.renderableData?.conversationSummary?.id || 0;
    let conversation_id = this.args.partGroup.conversation.id;
    return related_conversation_id < conversation_id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::UnknownParticipantForEmailConversationNote': typeof UnknownParticipantForEmailConversationNoteComponent;
    'inbox2/conversation-stream/part-groups/unknown-participant-for-email-conversation-note': typeof UnknownParticipantForEmailConversationNoteComponent;
  }
}
