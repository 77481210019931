/* import __COLOCATED_TEMPLATE__ from './user-comment.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type UserSummary from 'embercom/objects/inbox/user-summary';

interface UserCommentSignature {
  Element: HTMLDivElement;
  Args: { user?: UserSummary };
}

const UserComment = templateOnlyComponent<UserCommentSignature>();

export default UserComment;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::TypingIndicators::UserComment': typeof UserComment;
  }
}
