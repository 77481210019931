/* import __COLOCATED_TEMPLATE__ from './ticket-shared.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import LinkWithText from 'embercom/helpers/link-with-text';
import type TicketShared from 'embercom/objects/inbox/renderable/ticket-shared';

interface TicketSharedPart extends RenderablePart {
  renderableData: TicketShared;
}

interface Args {
  part: TicketSharedPart;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamEventPartsTicketSharedComponent extends Component<Signature> {
  @service declare session: Session;

  // @ts-ignore
  linkWithTextHelper = LinkWithText.create(this);

  get renderableData() {
    return this.args.part.renderableData;
  }

  get teammate() {
    return this.renderableData.adminSummary;
  }

  get translationData() {
    if (this.teammate.id === this.session.teammate.id) {
      return {
        key: 'you-shared-ticket',
      };
    } else {
      return {
        key: 'admin-shared-ticket',
        data: {
          who: this.linkWithTextHelper.compute([
            'apps.app.admins.admin',
            this.teammate.id,
            this.teammate.name,
          ]),
        },
      };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::TicketShared': typeof Inbox2ConversationStreamEventPartsTicketSharedComponent;
    'inbox2/conversation-stream/event-parts/ticket-shared': typeof Inbox2ConversationStreamEventPartsTicketSharedComponent;
  }
}
