/* import __COLOCATED_TEMPLATE__ from './call-options-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import templateOnlyComponent from '@ember/component/template-only';
import { type CallDropdownOption } from './call-options';

interface Signature {
  Args: {
    option: CallDropdownOption;
  };
}

const CallOptionsDropdownItemComponent = templateOnlyComponent<Signature>();
export default CallOptionsDropdownItemComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CallOptionsDropdownItem': typeof CallOptionsDropdownItemComponent;
    'inbox2/call-options-dropdown-item': typeof CallOptionsDropdownItemComponent;
  }
}
