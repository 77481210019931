/* import __COLOCATED_TEMPLATE__ from './full-conversation-panel.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';

interface Signature {
  Args: {
    conversation: Conversation | ConversationSummary | ConversationTableEntry;
    highlights: any;
    onClose: () => unknown;
  };
}

const FullConversationPanel = templateOnlyComponent<Signature>();
export default FullConversationPanel;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::FullConversationPanel': typeof FullConversationPanel;
    'inbox2/full-conversation-panel': typeof FullConversationPanel;
  }
}
