/* import __COLOCATED_TEMPLATE__ from './outbound-sms.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Sms from 'embercom/objects/inbox/renderable/sms';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { Channel } from 'embercom/models/data/inbox/channels';

interface OutboundSmsRenderablePart extends RenderablePart {
  renderableData: Sms;
}

interface OutboundSmsPartGroup extends PartGroup {
  parts: Array<OutboundSmsRenderablePart>;
}

interface Args {
  partGroup: OutboundSmsPartGroup;
  isReadOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamTeammateContentComponent extends Component<Signature> {
  @service declare session: Session;
  smsChannel = Channel.SMS;

  get renderableData() {
    return this.args.partGroup.parts[0].renderableData;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::OutboundSms': typeof ConversationStreamTeammateContentComponent;
    'inbox2/conversation-stream/part-groups/outbound-sms': typeof ConversationStreamTeammateContentComponent;
  }
}
