/* import __COLOCATED_TEMPLATE__ from './emoji-action.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import storage from 'embercom/vendor/intercom/storage';
import { type DisplayContext } from 'embercom/services/command-k';

// @ts-ignore
import intermoji from '@intercom/intermoji';

interface Args {
  composerApi?: ComposerPublicAPI;
  priority?: number;
  trackingData?: Record<string, unknown>;
  activatingComponent?: `${DisplayContext}`;
}

interface Signature {
  Args: Args;
}

export default class EmojiAction extends Component<Signature> {
  @action insertEmoji(emoji: string) {
    this.persistEmoji(emoji);
    this.args.composerApi?.composer.commands.focus();
    this.args.composerApi?.composer.commands.insertText(emoji);
  }

  private persistEmoji(emoji: string) {
    let emojis = storage.get('userEmojis') ?? [];
    emojis.unshift(intermoji.identifierFromUnicode(emoji));

    storage.set('userEmojis', emojis);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::CommandK::EmojiAction': typeof EmojiAction;
    'inbox2/composer/command-k/emoji-action': typeof EmojiAction;
  }
}
