/* import __COLOCATED_TEMPLATE__ from './ticket-state-updated-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const TicketStateUpdatedContent = templateOnlyComponent<Signature>();
export default TicketStateUpdatedContent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::Parts::TicketStateUpdatedContent': typeof TicketStateUpdatedContent;
    'inbox2/conversation-stream/parts/ticket-state-updated-content': typeof TicketStateUpdatedContent;
  }
}
