/* import __COLOCATED_TEMPLATE__ from './admin-assignee-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type NewConversation } from 'embercom/objects/inbox/conversation';
import type Conversation from 'embercom/objects/inbox/conversation';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type CommandKService from 'embercom/services/command-k';
import type PermissionsModal from 'embercom/services/permissions-modal';
import type IntlService from 'embercom/services/intl';

interface Args {
  conversation: Conversation | NewConversation;
  onUpdate(assignee: AdminWithPermissions): void;
  canReassignConversation: boolean;
}

interface Signature {
  Args: Args;
}

export default class Inbox2AdminAssigneeSelector extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare permissionsModal: PermissionsModal;
  @service declare intl: IntlService;

  @action
  onClick() {
    if (this.args.canReassignConversation) {
      this.commandK.registerAndShow({
        actionID: 'assign-to',
        context: { excludeTeams: true },
        onSelect: (admin: AdminWithPermissions) => {
          this.args.onUpdate(admin);
        },
      });
    } else {
      this.permissionsModal.showModal({
        permissionsLabel: this.intl.t('inbox.common.permissions-label.can-reassign-conversations'),
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::AdminAssigneeSelector': typeof Inbox2AdminAssigneeSelector;
    'inbox2/conversation-details-sidebar/admin-assignee-selector': typeof Inbox2AdminAssigneeSelector;
  }
}
