/* import __COLOCATED_TEMPLATE__ from './team-assignee-name.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const TeamAssigneeName = templateOnlyComponent<Signature>();
export default TeamAssigneeName;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::TeamAssigneeName': typeof TeamAssigneeName;
    'inbox2/conversation-details-sidebar/team-assignee-name': typeof TeamAssigneeName;
  }
}
