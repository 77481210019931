/* import __COLOCATED_TEMPLATE__ from './mentions-dot.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLSpanElement;
  Args: {};
}

const MentionsDot = templateOnlyComponent<Signature>();
export default MentionsDot;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::MentionsDot': typeof MentionsDot;
    'inbox2/common/mentions-dot': typeof MentionsDot;
  }
}
