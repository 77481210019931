/* import __COLOCATED_TEMPLATE__ from './suggested-macro.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';
import { type CopilotSuggestion } from 'embercom/resources/inbox2/copilot/copilot-question-suggestions';

interface SuggestedMacroArgs {
  suggestion: CopilotSuggestion & { type: 'macro' };
  onClick: () => void;
  onShow: () => void;
  onReject: () => void;
}

interface Signature {
  Element: Element;
  Args: SuggestedMacroArgs;
  Blocks: {
    default: [];
  };
}

export default class SuggestedMacro extends Component<Signature> {
  onShow = modifier(
    () => {
      this.args.onShow();
    },
    { eager: false },
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Suggestions::SuggestedMacro': typeof SuggestedMacro;
    'inbox2/copilot/suggestions/suggested-macro': typeof SuggestedMacro;
  }
}
