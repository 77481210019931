/* import __COLOCATED_TEMPLATE__ from './conversation-sla-applied-by-rule.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const ConversationSlaAppliedByRule = templateOnlyComponent<Signature>();
export default ConversationSlaAppliedByRule;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::ConversationSlaAppliedByRule': typeof ConversationSlaAppliedByRule;
    'inbox2/conversation-stream/event-parts/conversation-sla-applied-by-rule': typeof ConversationSlaAppliedByRule;
  }
}
