/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { action } from '@ember/object';

import type NavigableSelection from '../../common/navigable-selection-resource';
import type UseMacroAction from 'embercom/objects/inbox/command-k/use-macro';

interface Args {
  onSelect: (s: ChildAction, a?: object) => void;
  selection: NavigableSelection;
  items: ChildAction[];
  currentAction: UseMacroAction;
  containerSelectorName: string;
  inTypeahead: boolean;
  showHeading?: boolean;
  heading?: string;
}

interface Signature {
  Args: Args;
}

export default class UseMacroListComponent extends Component<Signature> {
  @action handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      // Prevent from adding new line in composer when inserted via typeahead
      e.preventDefault();
      this.args.onSelect(this.args.selection.selected);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::UseMacro::List': typeof UseMacroListComponent;
    'inbox2/command-k/use-macro/list': typeof UseMacroListComponent;
  }
}
