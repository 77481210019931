/* import __COLOCATED_TEMPLATE__ from './search-result-row.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';

interface Args {
  data: any;
  selection: NavigableSelection;
  rowIndex: number;
  hidePopover: () => unknown;
  onClick?: () => unknown;
  onMouseEnter?: () => unknown;
  onEscape?: () => unknown;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [any];
  };
}

export default class SearchResultRow extends Component<Signature> {
  get isHighlighted() {
    return this.args.selection.indexOfSelected === this.args.rowIndex;
  }

  @action click() {
    this.args.hidePopover();

    if (this.args.onClick !== undefined) {
      this.args.onClick();
    }
  }

  @action enter(e: KeyboardEvent) {
    e.preventDefault();
    if (this.isHighlighted) {
      this.click();
    }
  }

  @action mouseEnter() {
    this.args.selection.select(this.args.data);

    if (this.args.onMouseEnter !== undefined) {
      this.args.onMouseEnter();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::QuickSearch::SearchResultRow': typeof SearchResultRow;
    'inbox2/common/quick-search/search-result-row': typeof SearchResultRow;
  }
}
