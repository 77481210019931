/* import __COLOCATED_TEMPLATE__ from './list-sheet.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type User from 'embercom/objects/inbox/user';
import type Attribute from 'embercom/objects/inbox/attribute';
// @ts-ignore
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import { action } from '@ember/object';
import { postRequest } from 'embercom/lib/inbox/requests';
import type Session from 'embercom/services/session';

type Args = SidebarSectionComponentArgs<{
  user: User;
  potentialDuplicates: User[];
  userAttributes: Attribute[];
  toggleUserDetailsPin: (pinnedAttrsKeys: string[]) => unknown;
  collapseSheet: () => unknown;
}>;

interface Signature {
  Args: Args;
}

export default class PotentialDuplicatesListSheet extends Component<Signature> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare session: Session;

  @action async mergeUserAndLead(duplicateUser: User) {
    await postRequest(`/ember/users/merge_lead_into_user?app_id=${this.session.workspace.id}`, {
      target_user_id: duplicateUser.id,
      source_user_id: this.args.user.id,
    });
    // close sidebar
    this.inboxSidebarService.onExpandPotentialDuplicates(null, true);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::PotentialDuplicates::ListSheet': typeof PotentialDuplicatesListSheet;
    'inbox2/conversation-details-sidebar/sidebar-sections/potential-duplicates/list-sheet': typeof PotentialDuplicatesListSheet;
  }
}
