/* import __COLOCATED_TEMPLATE__ from './state-pill.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type LatestConversationSummary from 'embercom/objects/inbox/latest-conversation-summary';
import { ConversationState } from 'embercom/objects/inbox/conversation';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import { localisedCustomStateAdminLabel } from 'embercom/lib/inbox2/ticket-custom-states';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';

interface Signature {
  Element: HTMLSpanElement;
  Args: {
    conversation: Pick<
      LatestConversationSummary,
      'ticketType' | 'state' | 'ticketState' | 'ticketCustomStateId'
    >;
    overrideClasses?: string;
    additionalClasses?: string;
  };
}
export default class StatePill extends Component<Signature> {
  static ConversationStateCSSMapping = {
    [ConversationState.Open]: 'bg-sky-darkest text-blue dark:bg-dm-blue-dark dark:text-dm-blue',
    [ConversationState.Snoozed]:
      'bg-yellow-light text-yellow-dark dark:bg-dm-yellow-dark dark:text-dm-yellow-light',
    [ConversationState.Closed]:
      'bg-gray-light text-gray-dark dark:bg-dm-gray-dark dark:text-dm-gray-light',
  };

  @service declare ticketStateService: TicketStateService;
  @service declare intl: any;

  get currentTicketCustomState(): TicketCustomState {
    return this.ticketStateService.getTicketCustomStateById(
      this.args.conversation.ticketCustomStateId,
    );
  }

  get displayText(): string {
    if (this.currentTicketCustomState) {
      return localisedCustomStateAdminLabel(this.currentTicketCustomState.adminLabel, this.intl);
    }
    let state = this.args.conversation.ticketState || TicketSystemState.Submitted;
    return this.intl.t(`inbox.ticket-state.${state}`);
  }

  get stateStyling(): string {
    let baseStyles =
      this.args.overrideClasses ||
      'flex items-center justify-center capitalize rounded-full leading-4 h-4 font-medium px-2 select-none text-10px';
    let stateStyles = '';

    if (this.args.conversation.ticketType && this.args.conversation.ticketState) {
      let stateStyling = this.ticketStateService.getIconForSystemState(
        this.args.conversation.ticketState,
      );
      stateStyles = stateStyling.cssClass;
    } else {
      stateStyles = StatePill.ConversationStateCSSMapping[this.args.conversation.state];
    }

    return `${baseStyles} ${stateStyles} ${this.args.additionalClasses || ''}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::StatePill': typeof StatePill;
    'inbox2/conversation-details-sidebar/state-pill': typeof StatePill;
  }
}
