/* import __COLOCATED_TEMPLATE__ from './macro-actions.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import {
  type MacroAction,
  type MacroActionType,
  type MacroActionEditorContext,
} from 'embercom/objects/inbox/macro';

import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import type Conversation from 'embercom/objects/inbox/conversation';
import { NewConversation } from 'embercom/objects/inbox/conversation';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type InboxApi from 'embercom/services/inbox-api';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';
import { requiredAttributesForTeamAssignee } from 'embercom/objects/inbox/conversation-attribute-descriptor';

const BASE_ALLOWED_IDENTIFIERS: MacroActionType[] = [
  'assign-conversation-to-team',
  'assign-conversation-to-teammate',
  'add-tag-to-conversation',
  'snooze-conversation',
  'close-conversation',
  'change-conversation-priority',
];
// const CVDA_IDENTIFIER: MacroActionType = 'set-conversation-data-attribute';
const MULTI_ACTION_IDENTIFIERS: MacroActionType[] = ['add-tag-to-conversation'];
const CONFLICTING_ACTION_IDENTIFIER_SETS: MacroActionType[][] = [
  ['assign-conversation', 'assign-conversation-to-owner'],
  ['snooze-conversation', 'close-conversation'],
  ['assign-conversation-to-teammate', 'assign-conversation-to-team'],
];

interface Args {
  macroActions: MacroAction[];
  actionEditorContext: MacroActionEditorContext;
  onAddAction?: (actionType: MacroActionType) => unknown;
  onDelete: (action: MacroAction) => unknown;
  conversations?:
    | undefined[] // currently call this with {{array @conversation}} when conversation might not exist
    | Conversation[]
    | ConversationSummary[]
    | ConversationTableEntry[]
    | [NewConversation];
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class ComposerMacroActions extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare inboxApi: InboxApi;

  @use private areAllConversationsCloseableLoader = AsyncData<boolean>(async () => {
    if (this.args.conversations && this.args.conversations[0] instanceof NewConversation) {
      let conversation = this.args.conversations[0];
      let descriptors = await this.session.workspace.fetchConversationAttributeDescriptors();
      return requiredAttributesForTeamAssignee(descriptors, conversation.teamAssignee).length === 0;
    }

    let conversationIds = this.args.conversations
      ?.compact()
      .map((conversation) => conversation.id)
      .compact();

    if (!conversationIds || conversationIds?.length === 0) {
      return true;
    }
    return await this.inboxApi.areAllConversationsCloseable(
      conversationIds,
      this.macroSetAttributeActions,
    );
  });

  private get areAllCloseable() {
    if (this.areAllConversationsCloseableLoader.isLoading) {
      return true;
    } else {
      return this.areAllConversationsCloseableLoader.value;
    }
  }

  get usedIdentifiers() {
    return this.args.macroActions.map((a) => a.type);
  }

  get canAddActions() {
    return (
      (this.args.actionEditorContext === 'bulk-edit' ||
        this.args.actionEditorContext === 'create-macro') &&
      this.availableIdentifiers.length > 0
    );
  }

  get availableIdentifiers() {
    // Remove actions we've already used (except those allowing duplicates)
    if (
      this.session.workspace.isFeatureEnabled('ae-wfc-macros') &&
      !BASE_ALLOWED_IDENTIFIERS.includes('workflow-connector-action')
    ) {
      BASE_ALLOWED_IDENTIFIERS.push('workflow-connector-action');
      MULTI_ACTION_IDENTIFIERS.push('workflow-connector-action');
    }

    if (
      this.session.workspace.isFeatureEnabled('conversational_insights') &&
      !BASE_ALLOWED_IDENTIFIERS.includes('add-conversation-topic')
    ) {
      BASE_ALLOWED_IDENTIFIERS.push('add-conversation-topic');
      MULTI_ACTION_IDENTIFIERS.push('add-conversation-topic');
    }
    let availableIdentifiers = BASE_ALLOWED_IDENTIFIERS.filter((identifier) => {
      return (
        !this.usedIdentifiers.includes(identifier) || MULTI_ACTION_IDENTIFIERS.includes(identifier)
      );
    });

    // Remove any actions that cannot be applied together
    let conflicts = CONFLICTING_ACTION_IDENTIFIER_SETS.flatMap((conflictSet) => {
      if (conflictSet.some((c) => this.usedIdentifiers.includes(c))) {
        return conflictSet;
      }
      return [];
    });

    return availableIdentifiers.filter((i) => !conflicts.includes(i));
  }

  get availableActions() {
    return this.availableIdentifiers.map((identifier) => ({
      text: this.intl.t(`inbox.create-macro.actions.${identifier}`),
      value: identifier,
    }));
  }

  get componentPaths() {
    return {
      'close-conversation': 'inbox2/composer/macro-actions/close-conversation',
      'assign-conversation-to-team': 'inbox2/composer/macro-actions/assign-conversation',
      'assign-conversation-to-teammate': 'inbox2/composer/macro-actions/assign-conversation',
      'change-conversation-priority': 'inbox2/composer/macro-actions/change-conversation-priority',
      'add-tag-to-conversation': 'inbox2/composer/macro-actions/add-tag-to-conversation',
      'set-conversation-data-attribute':
        'inbox2/composer/macro-actions/set-conversation-data-attribute',
      'snooze-conversation': 'inbox2/composer/macro-actions/snooze-conversation',
      'workflow-connector-action': 'inbox2/composer/macro-actions/workflow-connector-action',
      'add-conversation-topic': 'inbox2/composer/macro-actions/add-conversation-topic',
    };
  }

  get failReasons() {
    let reasons: Record<string, string> = {};
    this.args.macroActions.forEach((action) => {
      if (!this.isClosable(action)) {
        reasons[action.uniquenessKey] =
          this.args.conversations && this.args.conversations[0] instanceof NewConversation
            ? this.intl.t(`inbox.composer.macro-actions.close-fail-reason-for-new-conversation`)
            : this.intl.t(`inbox.composer.macro-actions.close-fail-reason`);
      }
    });
    return reasons;
  }

  get macroActions() {
    let uniquenessKeys = this.args.macroActions.map((action) => action.uniquenessKey);

    return this.args.macroActions.map((action, i) => {
      let applyable = i === uniquenessKeys.indexOf(action.uniquenessKey);
      action.applyable = applyable && this.isClosable(action);
      return action;
    });
  }

  get macroSetAttributeActions(): string[] {
    return this.args.macroActions
      .filter(
        (action) =>
          action.type === 'set-conversation-data-attribute' &&
          !!action.data?.attribute_summary?.identifier,
      )
      .map((action) => action.data!.attribute_summary!.identifier);
  }

  private isClosable(action: MacroAction) {
    return action.type !== 'close-conversation' || this.areAllCloseable;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MacroActions': typeof ComposerMacroActions;
    'inbox2/composer/macro-actions': typeof ComposerMacroActions;
  }
}
