/* import __COLOCATED_TEMPLATE__ from './attribute-pin-icon.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

interface Args {
  isPinned?: boolean;
  isHovered: boolean;
  togglePin: (key: string) => unknown;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class AttributePinIcon extends Component<Signature> {
  get pinIcon() {
    return this.args.isPinned ? 'pinned' : 'unpinned';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::AttributePinIcon': typeof AttributePinIcon;
    'inbox2/conversation-details-sidebar/sidebar-sections/attribute-pin-icon': typeof AttributePinIcon;
  }
}
