/* import __COLOCATED_TEMPLATE__ from './usage.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RouterService from '@ember/routing/router-service';

interface UsageArgs {
  billingAdmin: boolean;
  remainingCredit: number | undefined;
  remainingTurn: number | undefined;
  openRequestToUpgradeModal: () => void;
}

interface Signature {
  Element: Element;
  Args: UsageArgs;
  Blocks: {
    default: [];
  };
}

export default class Usage extends Component<Signature> {
  @service declare router: RouterService;
  @service declare session: Session;
  @service declare intercomEventService: $TSFixMe;

  @action onUpgradeNowClick() {
    this.intercomEventService.trackEvent('clicked_fin_copilot_upgrade_button');
    this.router.transitionTo('apps.app.settings.workspace.teammates', this.session.workspace.id);
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Freemium::Usage': typeof Usage;
    'inbox2/copilot/freemium/usage': typeof Usage;
  }
}
