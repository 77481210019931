/* import __COLOCATED_TEMPLATE__ from './create-order-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';
import { type Task, type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type Session from 'embercom/services/session';
import { captureException } from 'embercom/lib/sentry';
//@ts-ignore
import ShopifySavedDraftOrder from 'embercom/components/notifications/shopify-saved-draft-order';

export interface ProductVariantHash {
  display_name: string;
  image_src: string | null;
  price: string;
  sku: string | null;
  variant_id: string;
  inventory_quantity: number | null;
  online_store_url: string;
}

interface TaxLine {
  price: string;
  rate: number | null;
  title: string;
}
interface DraftOrder {
  id: number;
  url: string;
  tax_lines: TaxLine[];
  total_price?: string;
  subtotal_price?: string;
  total_discounts?: string;
  total_tax?: string;
  taxes_included?: boolean;
}

export interface SearchProductsResponse {
  products: ProductVariantHash[];
}

class ProductVariant {
  @tracked display_name: string;
  @tracked image_src: string | null;
  @tracked price: string;
  @tracked sku: string | null;
  @tracked variant_id: string;

  constructor(
    display_name: string,
    image_src: string | null,
    price: string,
    sku: string | null,
    variant_id: string,
  ) {
    this.display_name = display_name;
    this.image_src = image_src;
    this.price = price;
    this.sku = sku;
    this.variant_id = variant_id;
  }

  static fromHash(hash: ProductVariantHash) {
    return new ProductVariant(
      hash.display_name,
      hash.image_src,
      hash.price,
      hash.sku,
      hash.variant_id,
    );
  }
}

class SearchSuggestion {
  @tracked productVariant: ProductVariant;
  @tracked isHighlighted: boolean;

  constructor(productVariant: ProductVariant, isHighlighted = false) {
    this.productVariant = productVariant;
    this.isHighlighted = isHighlighted;
  }

  static fromProduct(productHash: ProductVariantHash) {
    return new SearchSuggestion(ProductVariant.fromHash(productHash));
  }

  get name() {
    return this.productVariant.display_name;
  }
}

class LineItem {
  @tracked productVariant: ProductVariant;
  @tracked quantity: number;

  constructor(productVariant: ProductVariant, quantity: number) {
    this.productVariant = productVariant;
    this.quantity = quantity;
  }

  static fromProductVariant(productVariant: ProductVariant, quantity = 1) {
    return new LineItem(productVariant, quantity);
  }

  incrementQuantity() {
    this.quantity++;
  }
}

class OrderDiscount {
  @tracked amount?: string;
  @tracked type: string;
  @tracked reason: string;
  @tracked currencySymbol: string;

  constructor(amount: string | undefined, type: string, reason: string, currencySymbol: string) {
    this.amount = amount;
    this.type = type;
    this.reason = reason;
    this.currencySymbol = currencySymbol;
  }

  get formattedAmount(): string | undefined {
    if (this.amount) {
      if (this.type === 'fixed_amount') {
        return `-${this.currencySymbol}${Number.parseFloat(this.amount).toFixed(2)}`;
      }
      return `-%${Number.parseFloat(this.amount).toFixed(2)}`;
    }
    return;
  }
}

class OrderShipping {
  @tracked type: string;
  @tracked amount?: string;
  @tracked name: string;
  @tracked currencySymbol: string;

  constructor(type: string, amount: string | undefined, name: string, currencySymbol: string) {
    this.type = type;
    this.amount = amount;
    this.name = name;
    this.currencySymbol = currencySymbol;
  }

  get formattedAmount(): string | void {
    if (this.type === 'free') {
      return 'Free';
    } else if (this.amount) {
      return this.currencySymbol + Number.parseFloat(this.amount).toFixed(2);
    }
  }
}
class Order {
  @tracked lineItems: LineItem[] = [];
  @tracked discount: OrderDiscount | null = null;
  @tracked shipping: OrderShipping | null = null;

  onSearchSuggestionSelected(searchSuggestion: SearchSuggestion) {
    let lineItem = this.lineItems.find(
      (lineItem) =>
        lineItem.productVariant.variant_id === searchSuggestion.productVariant.variant_id,
    );
    if (lineItem) {
      lineItem.incrementQuantity();
    } else {
      this.lineItems.push(LineItem.fromProductVariant(searchSuggestion.productVariant, 1));
    }
    this.lineItems = this.lineItems;
  }

  toLineItemsParams() {
    return this.lineItems.map((lineItem) => {
      return {
        variant_id: lineItem.productVariant.variant_id,
        quantity: lineItem.quantity,
      };
    });
  }

  toDiscountParams():
    | { discount?: string; discount_reason: string; discount_type: string }
    | undefined {
    if (this.discount) {
      return {
        discount: this.discount.amount,
        discount_reason: this.discount.reason,
        discount_type: this.discount.type,
      };
    }
    return;
  }

  toShippingParams():
    | { shipping: string; shipping_price?: string; shipping_title: string }
    | undefined {
    if (this.shipping) {
      return {
        shipping: this.shipping.type,
        shipping_price: this.shipping.amount,
        shipping_title: this.shipping.name,
      };
    }
    return;
  }
}

interface Args {
  onModalClose: () => void;
  isOpen: boolean;
  shopifyCustomerId: number;
  currencySymbol: string;
  fetchShopifyDataTask: Task<any, any>;
  shouldRequestMoreScopes: boolean;
}

interface Signature {
  Args: Args;
}

export default class Inbox2CreateOrderModal extends Component<Signature> {
  @service notificationsService: any;
  @service declare session: Session;

  @tracked order: Order = new Order();
  @tracked lastDraftOrderResponse?: DraftOrder;

  @tracked searchSuggestions: SearchSuggestion[] = [];
  @tracked productSearchQuery = '';

  @tracked discountTypeInputValue = '';
  @tracked discountReasonInputValue = '';
  @tracked discountAmountInputValue?: string;
  @tracked shippingRateTypeInputValue = '';
  @tracked customShippingRateNameInputValue = '';
  @tracked customShippingRateAmountInputValue?: string;
  @tracked note?: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.resetAllModalData();
  }

  @action
  resetSearch() {
    this.searchSuggestions = [];
  }

  @action
  resetAllModalData() {
    this.searchSuggestions = [];
    this.order = new Order();
    this.productSearchQuery = '';
    this.lastDraftOrderResponse = undefined;

    this.discountTypeInputValue = 'fixed_amount';
    this.discountReasonInputValue = '';
    this.discountAmountInputValue = undefined;

    this.shippingRateTypeInputValue = 'free';
    this.customShippingRateNameInputValue = '';
    this.customShippingRateAmountInputValue = undefined;

    this.note = '';

    taskFor(this.searchShopifyForProductTask).cancelAll();
    taskFor(this.createOrUpdateDraftOrderTask).cancelAll();
  }

  @action
  onSearchStringChanged(searchTerm: string) {
    this.productSearchQuery = searchTerm;

    if (this.productSearchQuery && this.productSearchQuery.length >= 2) {
      taskFor(this.searchShopifyForProductTask).perform();
    } else {
      this.searchSuggestions = [];
    }
  }

  @action
  selectItem(searchSuggestion: SearchSuggestion) {
    this.order.onSearchSuggestionSelected(searchSuggestion);
    this.onOrderUpdated();
  }

  @action
  removeLineItem(index: number) {
    this.order.lineItems.splice(index, 1);
    this.order.lineItems = this.order.lineItems;
    this.onOrderUpdated();
  }

  @action
  applyDiscount() {
    this.order.discount = new OrderDiscount(
      this.discountAmountInputValue,
      this.discountTypeInputValue,
      this.discountReasonInputValue,
      this.args.currencySymbol,
    );
    this.onOrderUpdated();
  }

  @action
  removeDiscount() {
    this.order.discount = null;
    this.onOrderUpdated();
  }

  @action
  applyShipping() {
    this.order.shipping = new OrderShipping(
      this.shippingRateTypeInputValue,
      this.customShippingRateAmountInputValue,
      this.customShippingRateNameInputValue,
      this.args.currencySymbol,
    );
    this.onOrderUpdated();
  }

  @action
  removeShipping() {
    this.order.shipping = null;
    this.onOrderUpdated();
  }

  @action
  onOrderUpdated() {
    taskFor(this.createOrUpdateDraftOrderTask).perform();
  }

  @task({ restartable: true })
  *searchShopifyForProductTask(): TaskGenerator<void> {
    if (!this.productSearchQuery) {
      return;
    }

    try {
      let productResponse = yield ajax({
        url: '/ember/inbox/shopify/search_products',
        type: 'GET',
        data: {
          app_id: this.session.workspace.id,
          query: this.productSearchQuery,
        },
      }) as SearchProductsResponse;

      this.searchSuggestions = productResponse.products.map((productHash: ProductVariantHash) =>
        SearchSuggestion.fromProduct(productHash),
      );
    } catch (error) {
      console.error(error);
    }
  }

  @task({ restartable: true })
  *createOrUpdateDraftOrderTask() {
    try {
      let endpoint = '/ember/inbox/shopify/create_draft_order';

      let params = {
        app_id: this.session.workspace.id,
        customer_id: this.args.shopifyCustomerId,
        line_items: this.order.toLineItemsParams(),
        paid: true,
        shipping: 'free',
      };

      if (this.lastDraftOrderResponse) {
        Object.assign(params, { draft_order_id: this.lastDraftOrderResponse.id });
        endpoint = '/ember/inbox/shopify/update_draft_order';
      }

      if (this.order.discount) {
        Object.assign(params, this.order.toDiscountParams());
      }

      if (this.order.shipping) {
        Object.assign(params, this.order.toShippingParams());
      }

      if (this.note) {
        Object.assign(params, { note: this.note });
      }

      this.lastDraftOrderResponse = yield ajax({
        url: endpoint,
        type: 'POST',
        data: JSON.stringify(params),
      }) as DraftOrder;
    } catch (error) {
      captureException(error);
    }
  }

  @task({ restartable: true })
  *saveDraftOrderTask() {
    try {
      yield this.notificationsService.notifyConfirmationWithModelAndComponent(
        {
          url: this.lastDraftOrderResponse?.url,
        },
        ShopifySavedDraftOrder,
        ENV.APP.default_notification_timeout,
      );
      this.args.onModalClose();
      this.resetAllModalData();
    } catch (error) {
      captureException(error);
      this.notificationsService.notifyError(`Error: ${error}`);
    }
  }

  @task({ restartable: true })
  *completeDraftOrderTask(asPaid: boolean) {
    try {
      let params = {
        app_id: this.session.workspace.id,
        draft_order_id: this.lastDraftOrderResponse?.id,
        paid: asPaid,
      };

      yield ajax({
        url: '/ember/inbox/shopify/complete_draft_order',
        type: 'POST',
        data: JSON.stringify(params),
      });

      yield this.notificationsService.notifyConfirmation('Order created');
      this.args.onModalClose();
      this.args.fetchShopifyDataTask.perform();
      this.resetAllModalData();
    } catch (error) {
      this.notificationsService.notifyError(`Error: ${error}`);
      captureException(error);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Shopify::CreateOrderModal': typeof Inbox2CreateOrderModal;
    'inbox2/conversation-details-sidebar/sidebar-sections/shopify/create-order-modal': typeof Inbox2CreateOrderModal;
  }
}
