/* import __COLOCATED_TEMPLATE__ from './instagram-restricted-replies-banner.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const InstagramRestrictedRepliesBanner = templateOnlyComponent<Signature>();
export default InstagramRestrictedRepliesBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::InstagramRestrictedRepliesBanner': typeof InstagramRestrictedRepliesBanner;
    'inbox2/instagram-restricted-replies-banner': typeof InstagramRestrictedRepliesBanner;
  }
}
