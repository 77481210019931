/* import __COLOCATED_TEMPLATE__ from './icon.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const Icon = templateOnlyComponent<Signature>();
export default Icon;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Icon': typeof Icon;
    'inbox2/command-k/icon': typeof Icon;
  }
}
