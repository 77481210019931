/* import __COLOCATED_TEMPLATE__ from './admins-viewing.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RealtimeConversationParticipants from 'embercom/services/realtime-conversation-participants';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface Args {
  conversationId: number;
  format?: string;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class Inbox2ConversationAdminsViewingComponent extends Component<Signature> {
  @service declare realtimeConversationParticipants: RealtimeConversationParticipants;
  @service declare session: Session;

  get admins(): AdminSummary[] {
    return this.realtimeConversationParticipants.adminsViewingConversation(
      this.args.conversationId,
    );
  }

  get firstAdmin(): AdminSummary {
    return this.admins[0];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Conversation::AdminsViewing': typeof Inbox2ConversationAdminsViewingComponent;
    'inbox2/conversation/admins-viewing': typeof Inbox2ConversationAdminsViewingComponent;
  }
}
