/* import __COLOCATED_TEMPLATE__ from './realtime-user.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type RealtimeUsersService from 'embercom/services/realtime-users';

interface Args {
  userId: string | undefined;
  enabled: boolean;
}

interface Signature {
  Args: Args;
  Element: never;
  Blocks: {
    default: [boolean];
  };
}

export default class Inbox2CommonRealtimeUserComponent extends Component<Signature> {
  @service declare realtimeUsers: RealtimeUsersService;
  didTrack?: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.enabled && this.args.userId) {
      this.realtimeUsers.track(this.args.userId);
      this.didTrack = this.args.userId;
    }
  }

  get isActive() {
    if (this.didTrack) {
      return this.realtimeUsers.activeUsers.has(this.args.userId!);
    } else {
      return false;
    }
  }

  willDestroy() {
    super.willDestroy();
    if (this.didTrack) {
      this.realtimeUsers.untrack(this.didTrack);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::RealtimeUser': typeof Inbox2CommonRealtimeUserComponent;
  }
}
