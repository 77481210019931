/* import __COLOCATED_TEMPLATE__ from './more-matches-badge.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const MoreMatchesBadge = templateOnlyComponent<Signature>();
export default MoreMatchesBadge;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::MoreMatchesBadge': typeof MoreMatchesBadge;
    'inbox2/conversations-table/more-matches-badge': typeof MoreMatchesBadge;
  }
}
