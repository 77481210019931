/* import __COLOCATED_TEMPLATE__ from './keyboard-shortcuts.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { inject as service } from '@ember/service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';

interface Shortcut {
  label: string;
  keys: string[];
}

interface ShortcutGroup {
  heading: string;
  shortcuts: Array<Shortcut>;
}

interface Signature {
  Args: {
    goBack: () => void;
    closeSidebar: () => void;
  };
  Element: HTMLDivElement;
}

export default class Inbox2KeyboardShortcuts extends Component<Signature> {
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare inboxSidebarService: InboxSidebarService;

  readonly shortcutGroupsList: ShortcutGroup[] = [];

  constructor(owner: unknown, args: any) {
    super(owner, args);
    let hotkeys = this.inboxHotkeys.hotkeysMap;

    let navigationShortcuts: Shortcut[] = [
      {
        label: 'inbox.keyboard-shortcuts.navigation.go-to',
        keys: hotkeys[HotkeyID.JumpToAction].display,
      },
      {
        label: 'inbox.keyboard-shortcuts.navigation.up',
        keys: hotkeys[HotkeyID.NavigateUp].display,
      },
      {
        label: 'inbox.keyboard-shortcuts.navigation.down',
        keys: hotkeys[HotkeyID.NavigateDown].display,
      },
      {
        label: 'inbox.keyboard-shortcuts.navigation.switch-view',
        keys: hotkeys[HotkeyID.SwitchView].display,
      },
      {
        label: 'inbox.keyboard-shortcuts.navigation.jump-to-top',
        keys: hotkeys[HotkeyID.JumpToTop].display,
      },
      {
        label: 'inbox.keyboard-shortcuts.navigation.jump-to-bottom',
        keys: hotkeys[HotkeyID.JumpToBottom].display,
      },
      {
        label: 'inbox.keyboard-shortcuts.navigation.page-up',
        keys: hotkeys[HotkeyID.PageUp].display,
      },
      {
        label: 'inbox.keyboard-shortcuts.navigation.page-down',
        keys: hotkeys[HotkeyID.PageDown].display,
      },
    ];

    if (this.inboxSidebarService.isCopilotTabVisible) {
      navigationShortcuts.push(
        {
          label: 'inbox.keyboard-shortcuts.navigation.open-copilot',
          keys: hotkeys[HotkeyID.OpenCopilot].display,
        },
        {
          label: 'inbox.keyboard-shortcuts.navigation.open-conversation-details',
          keys: hotkeys[HotkeyID.OpenConversationDetails].display,
        },
      );
    }

    this.shortcutGroupsList = [
      {
        heading: 'inbox.keyboard-shortcuts.main.heading',
        shortcuts: [
          {
            label: 'inbox.keyboard-shortcuts.main.action-menu',
            keys: hotkeys[HotkeyID.CommandK].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.main.toggle-left-menu',
            keys: hotkeys[HotkeyID.ToggleLeftNav].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.main.toggle-right-menu',
            keys: hotkeys[HotkeyID.ToggleConversationDetailsSidebar].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.main.toggle-events',
            keys: hotkeys[HotkeyID.ToggleEvents].display,
          },
        ],
      },
      {
        heading: 'inbox.keyboard-shortcuts.conversation.heading',
        shortcuts: [
          {
            label: 'inbox.keyboard-shortcuts.conversation.close',
            keys: hotkeys[HotkeyID.Close].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.conversation.reopen',
            keys: hotkeys[HotkeyID.Reopen].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.conversation.snooze',
            keys: hotkeys[HotkeyID.SnoozeAction].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.conversation.priority',
            keys: hotkeys[HotkeyID.ChangePriority].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.conversation.assign',
            keys: hotkeys[HotkeyID.AssignToAction].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.conversation.new-conversation',
            keys: hotkeys[HotkeyID.NewConversation].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.conversation.tag',
            keys: hotkeys[HotkeyID.TagLastUserComment].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.conversation.workflow-connector-action',
            keys: hotkeys[HotkeyID.TriggerWorkflowConnectorAction].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.conversation.tag-user',
            keys: hotkeys[HotkeyID.TagUser].display,
          },
        ],
      },
      {
        heading: 'inbox.keyboard-shortcuts.navigation.heading',
        shortcuts: [...navigationShortcuts],
      },
      {
        heading: 'inbox.keyboard-shortcuts.composer.heading',
        shortcuts: [
          {
            label: 'inbox.keyboard-shortcuts.composer.reply',
            keys: hotkeys[HotkeyID.ComposeReply].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.composer.note',
            keys: hotkeys[HotkeyID.ComposeNote].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.composer.send',
            keys: hotkeys[HotkeyID.Send].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.composer.send-and-close',
            keys: hotkeys[HotkeyID.SendAndClose].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.composer.send-and-snooze',
            keys: hotkeys[HotkeyID.SendAndSnooze].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.composer.gif',
            keys: hotkeys[HotkeyID.InsertGif].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.composer.image',
            keys: hotkeys[HotkeyID.InsertImage].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.composer.article',
            keys: hotkeys[HotkeyID.InsertArticle].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.composer.attachment',
            keys: hotkeys[HotkeyID.AddAttachment].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.composer.emoji',
            keys: hotkeys[HotkeyID.Emoji].display,
          },
          {
            label: 'inbox.keyboard-shortcuts.composer.macro',
            keys: hotkeys[HotkeyID.UseMacro].display,
          },
        ],
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::KeyboardShortcuts': typeof Inbox2KeyboardShortcuts;
    'inbox2/keyboard-shortcuts': typeof Inbox2KeyboardShortcuts;
  }
}
