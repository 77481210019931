/* import __COLOCATED_TEMPLATE__ from './dropdown-button-popover.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type PopoverArgs } from '@intercom/pulse/template-registry';

interface Signature {
  Element: HTMLElement;
  Args: Pick<PopoverArgs, 'placement' | 'isDisabled' | 'offsetDistance'> & {
    showOn?: Array<'click' | 'mouseenter'>;
    hideOn?: Array<'clickout' | 'mouseleave' | 'click'>;
    dropdownListContentClass?: string;
  };
  Blocks: {
    opener: [any];
    content: [any];
  };
}

const DropdownButtonPopoverComponent = templateOnlyComponent<Signature>();
export default DropdownButtonPopoverComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::DropdownButtonPopover': typeof DropdownButtonPopoverComponent;
    'inbox2/common/dropdown-button-popover': typeof DropdownButtonPopoverComponent;
  }
}
