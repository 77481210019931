/* import __COLOCATED_TEMPLATE__ from './admin-assignee-name.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type NewConversation } from 'embercom/objects/inbox/conversation';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';

interface Signature {
  Args: {
    conversation: ConversationRecord | NewConversation;
  };
  Element: HTMLElement;
}

const AdminAssigneeName = templateOnlyComponent<Signature>();
export default AdminAssigneeName;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::AdminAssigneeName': typeof AdminAssigneeName;
    'inbox2/conversation-details-sidebar/admin-assignee-name': typeof AdminAssigneeName;
  }
}
