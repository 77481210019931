/* import __COLOCATED_TEMPLATE__ from './pane-picker.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { action } from '@ember/object';
import { type ReplyChannel } from 'embercom/objects/inbox/composer-pane';
import type ComposerPane from 'embercom/objects/inbox/composer-pane';
import { ComposerPaneType } from 'embercom/objects/inbox/composer-pane';
import { type SetPaneSource } from '../conversation-reply-composer';
import type Router from '@ember/routing/router-service';
import { type SafeString } from 'handlebars';

type Opts = { source: SetPaneSource };

interface Args {
  panes: ComposerPane[] | ReplyChannel[];
  setPane: (pane: ComposerPane | ReplyChannel, opts?: Opts) => void;
  selectedPane: ComposerPane | ReplyChannel;
  disableHotKey?: boolean;
  replyingToInboundNotAllowed?: boolean;
  singlePaneTooltip?: string | SafeString;
  showShareTicketTooltip?: boolean;
  showTicketVisibilityModal?: () => unknown;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class PanePicker extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare router: Router;
  @service declare intercomEventService: any;

  get items() {
    return this.args.panes.map((pane: ComposerPane | ReplyChannel) => ({
      id: this.intl.t(pane.nameTranslationKey),
      text: this.intl.t(pane.nameTranslationKey),
      value: pane.type,
      icon: pane.icon,
      isSelected: this.args.selectedPane.type === pane.type,
      hotkeyId: this.args.disableHotKey ? undefined : pane.hotkeyId,
      feature: pane.feature,
      isPaywalled: this.isPaywalled(pane.feature),
      setupUrl: pane.getSetupUrl
        ? pane.getSetupUrl(this.router, this.session.workspace.id)
        : undefined,
      isReply: pane.type === ComposerPaneType.Reply,
    }));
  }

  @action onSelectItem(item: PanePicker['items'][number], opts?: Opts) {
    if (!item.isPaywalled) {
      let pane = (this.args.panes as any[]).find(({ type }) => type === item.value);
      this.args.setPane(pane, { source: opts?.source ?? 'pane_picker' });
    }
  }

  @action onDoubleClick(popover?: { hide: () => unknown }) {
    popover?.hide();
    this.onSelectItem(this.nextPane, { source: 'pane_picker_dbl_click' });
  }

  @action openSetupUrl(item: PanePicker['items'][number]) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'pane_option_setup',
      action: 'opened',
      place: 'inbox2',
      channel_type: item.value,
    });
    safeWindowOpen(item.setupUrl);
  }

  private get nextPane() {
    let selectedIndex = this.items.findIndex((item) => item.isSelected);
    let nextIndex = selectedIndex + 1;

    if (nextIndex > this.items.length - 1) {
      nextIndex = 0;
    }

    return this.items[nextIndex];
  }

  private isPaywalled(feature: string | undefined) {
    if (!feature) {
      return false;
    }
    return !this.session.workspace.isFeatureEnabled(feature);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::PanePicker': typeof PanePicker;
    'inbox2/composer/pane-picker': typeof PanePicker;
  }
}
