/* import __COLOCATED_TEMPLATE__ from './snooze-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const SnoozeItem = templateOnlyComponent<Signature>();
export default SnoozeItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MacroActions::Snooze::SnoozeItem': typeof SnoozeItem;
    'inbox2/composer/macro-actions/snooze/snooze-item': typeof SnoozeItem;
  }
}
