/* import __COLOCATED_TEMPLATE__ from './conversation-table-close-action-confirmation-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    confirmationModalVisible: boolean;
    bulkSelectionCount: number;
    ticketsInBulkSelectionCount: number;
    bulkActionType?: string;
    closeConfirmationModal: () => void;
    closeItems: (resolveCustomerTickets?: boolean) => void;
  };
}

const ConversationTableCloseActionConfirmationModal = templateOnlyComponent<Signature>();
export default ConversationTableCloseActionConfirmationModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationTableCloseActionConfirmationModal': typeof ConversationTableCloseActionConfirmationModal;
  }
}
