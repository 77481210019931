/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import All from 'embercom/objects/inbox/inboxes/all';
import Mentions from 'embercom/objects/inbox/inboxes/mentions';
import Unassigned from 'embercom/objects/inbox/inboxes/unassigned';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type Session from 'embercom/services/session';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';

interface Args {}

interface Signature {
  Args: Args;
}

export default class Inbox2CommonEmptyState extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare intercomEventService: any;

  @tracked hasConversations?: boolean;

  get activeInboxIsMe() {
    return this.inboxState.activeInbox?.id === this.session.teammate.id.toString();
  }

  get activeInboxIsAll() {
    return this.inboxState.activeInbox instanceof All;
  }

  get activeInboxIsMentions() {
    return this.inboxState.activeInbox instanceof Mentions;
  }

  get activeInboxIsUnassigned() {
    return this.inboxState.activeInbox instanceof Unassigned;
  }

  get showTestConversationLink() {
    return (
      (this.activeInboxIsMe ||
        this.activeInboxIsMentions ||
        this.activeInboxIsUnassigned ||
        this.activeInboxIsAll) &&
      !this.conversationsExist.value &&
      !this.session.showLightInbox
    );
  }

  @use conversationsExist = AsyncData<boolean>(async () => {
    if (this.inboxState.conversationsExist === undefined) {
      this.inboxState.conversationsExist = await this.inboxApi.checkConversationsExist();
    }
    return this.inboxState.conversationsExist;
  });

  @action trackAnalyticsFirstImpression() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'inbox_empty_state_hosted_messenger',
      inbox_type: this.inboxState.activeInbox?.type,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::EmptyState': typeof Inbox2CommonEmptyState;
    'inbox2/common/empty-state': typeof Inbox2CommonEmptyState;
  }
}
