/* import __COLOCATED_TEMPLATE__ from './ticket-details.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Conversation from 'embercom/objects/inbox/conversation';
// @ts-ignore
import type IntlService from 'embercom/services/intl';

interface Args {
  conversation: Conversation;
  isOpen: boolean;
  name: string;
  onOpenSectionChange: () => unknown;
  autofocusTitle?: boolean;
}

interface Signature {
  Args: Args;
}

export default class TicketDetails extends Component<Signature> {
  @service declare intl: IntlService;

  get header(): string {
    return this.intl.t('inbox.conversation-details-sidebar.section-headings.ticket-attributes');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::TicketDetails': typeof TicketDetails;
    'inbox2/conversation-details-sidebar/ticket-details': typeof TicketDetails;
  }
}
