/* import __COLOCATED_TEMPLATE__ from './toolbar-ai-assist.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type Formatter } from '@intercom/embercom-prosemirror-composer/components/tools/text-formatter';
import type Session from 'embercom/services/session';
import type IntlService from 'ember-intl/services/intl';
import type ReplyComposer from 'embercom/components/inbox2/conversation-reply-composer';
import { type BlockList } from '@intercom/interblocks.ts';
import { type NestedDropdownOption } from 'embercom/components/inbox2/common/nested-dropdown';
import { type AiAssistPromptKey } from 'embercom/resources/inbox2/composer/ai-assist';
import { SUPPORTED_LANGUAGES } from 'embercom/lib/ai-assist';

type AiAssistCompletionFn = ReplyComposer['aiAssistCompletion'];

interface Args {
  formatter: Formatter;
  componentActions: {
    aiAssistCompletion: AiAssistCompletionFn;
  };
}

export default class ToolbarAiAssist extends Component<Args> {
  @service declare session: Session;
  @service declare intl: IntlService;

  @tracked isDropdownVisible = false;

  private isPaywalled(key: AiAssistPromptKey) {
    return key === 'tone' && !this.session.workspace.isFeatureEnabled('ai_assist_adjust_tone');
  }

  private paywallKey(key: AiAssistPromptKey) {
    if (key === 'tone') {
      return 'ai_assist_adjust_tone';
    }
    return;
  }

  get formatter() {
    return this.args.formatter;
  }

  get options() {
    let keys: AiAssistPromptKey[] = [
      'expand',
      'rephrase',
      'tone',
      'friendly',
      'formal',
      'shorten',
      'auto-correct',
    ];

    let options: NestedDropdownOption[] = keys.map((key) => ({
      name: this.intl.t(`inbox.composer.ai-toolbar.${key}`),
      onClick: this.isPaywalled(key)
        ? () => {}
        : () => {
            return this.args.formatter.format((blocks: BlockList) =>
              this.args.componentActions.aiAssistCompletion(key, blocks),
            );
          },
      paywalled: this.isPaywalled(key),
      paywallFeatureKey: this.paywallKey(key),
    }));

    options.push({
      name: this.intl.t(`inbox.composer.ai-toolbar.translate`),
      nestedOptions: this.translateOptions,
    });

    return options;
  }

  get translateOptions() {
    let keys: AiAssistPromptKey[] = [];

    keys.push('translate-french');
    keys.push('translate-spanish');
    keys.push('translate-english');
    keys.push('translate-portuguese');
    SUPPORTED_LANGUAGES.forEach((lang) => {
      if (!keys.includes(`translate-${lang}`)) {
        keys.push(`translate-${lang}`);
      }
    });

    return keys.map((key) => ({
      name: this.intl.t(`inbox.composer.ai-toolbar.${key}`),
      onClick: () => {
        return this.args.formatter.format((blocks: BlockList) =>
          this.args.componentActions.aiAssistCompletion(key, blocks),
        );
      },
    }));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::ToolbarAiAssist': typeof ToolbarAiAssist;
    'inbox2/composer/toolbar-ai-assist': typeof ToolbarAiAssist;
  }
}
