/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { type Named, Resource } from 'ember-resources';
import { inject as service } from '@ember/service';
import type InboxApi from 'embercom/services/inbox-api';
import { tracked } from '@glimmer/tracking';
import type Note from 'embercom/objects/inbox/note';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';

import { registerDestructor } from '@ember/destroyable';

interface ResourceArgs {
  noteIds: string[];
}

/* eslint-disable @intercom/intercom/no-component-inheritance */
export class NotesResource extends Resource<Named<ResourceArgs>> {
  @service declare inboxApi: InboxApi;
  @tracked list: Note[] = [];

  constructor(owner: unknown, args: Named<ResourceArgs>, previous: NotesResource) {
    super(owner, args, previous);

    registerDestructor(this, () => {
      taskFor(this.loadNotes).cancelAll();
    });

    // if the previous list of noteIds is the same of the current ids list, we should not reload it
    if (previous && previous.list.length === args.named.noteIds?.length) {
      let listIds = previous.list?.map(({ id }) => id);
      if (args.named.noteIds?.every((noteId) => listIds.includes(noteId))) {
        return previous;
      }
    }

    taskFor(this.loadNotes).perform(args.named.noteIds);
  }

  get isLoading() {
    return taskFor(this.loadNotes).isRunning;
  }

  @task
  *loadNotes(noteIds: string[]) {
    if (noteIds?.length) {
      this.list = yield this.inboxApi.fetchNotes(noteIds);
    }
  }

  appendNote(newNote: Note) {
    this.list = [newNote, ...this.list];
  }

  replaceNote(oldNote: Note, newNote: Note) {
    this.list = this.list.map((note) => (note.id === oldNote.id ? newNote : note));
  }
}
