/* import __COLOCATED_TEMPLATE__ from './cell-name.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
// @ts-ignore
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { type Icon } from 'embercom/objects/inbox/command-k/icons';
interface Args {
  inboxIcon: Icon;
  inboxId: string;
  name: string;
}

interface Signature {
  Args: Args;
}

export default class CellName extends Component<Signature> {
  get defaultAvatar(): string {
    return DefaultAvatars.admins[128];
  }

  get inbox() {
    return {
      category: InboxCategory.Team,
      id: this.args.inboxId,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::InboxOverview::CellName': typeof CellName;
    'inbox2/dashboard/inbox-overview/cell-name': typeof CellName;
  }
}
