/* import __COLOCATED_TEMPLATE__ from './workflow-connector-action.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type MacroAction } from 'embercom/objects/inbox/macro';

interface Signature {
  Args: { action: MacroAction };
}

const WorkflowConnectorAction = templateOnlyComponent<Signature>();
export default WorkflowConnectorAction;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::UseMacro::Preview::WorkflowConnectorAction': typeof WorkflowConnectorAction;
    'inbox2/command-k/use-macro/preview/workflow-connector-action': typeof WorkflowConnectorAction;
  }
}
