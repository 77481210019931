/* import __COLOCATED_TEMPLATE__ from './confetti-rain.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

interface Confetto {
  animationDuration: string;
  color: string;
  left: string;
  flake?: string;
}

export default class ConfettiRain extends Component {
  @service declare session: Session;
  @tracked confettiCount = this.useSnowflakes ? 20 : 40;

  private snowflakes = A(['❄', '❅', '❆']);
  private colors = A([
    '#e6525a',
    '#ff4081',
    '#f26c73',
    '#b3a3ed',
    '#ba68c8',
    '#7e61ea',
    '#1271c4',
    '#1f8ceb',
    '#b9dcfa',
    '#4dd0e1',
    '#26a69a',
    '#66bb6a',
    '#6ce4b3',
    '#b8f150',
    '#ffe645',
    '#ffd37a',
    '#ffab7a',
  ]);
  private animationBaseDuration = 500;

  get useSnowflakes(): boolean {
    return this.session.workspace.isFeatureEnabled('respond-inbox-of-love-snowflakes');
  }

  get confetti(): Confetto[] {
    let useSnowflakes = this.useSnowflakes;
    let confettiCount = this.confettiCount;

    let confetti: Confetto[] = [];
    for (let i = 0; i < confettiCount; i++) {
      let confetto: Confetto = {
        animationDuration: `${this.animationBaseDuration + Math.random() * 1500}ms`,
        color: this.randomColor(),
        left: `${(100 / confettiCount) * i}vw`,
      };
      if (useSnowflakes) {
        confetto.flake = this.randomSnowflake();
      }
      confetti.pushObject(confetto);
    }
    return confetti;
  }

  private randomSnowflake() {
    let snowflakes = this.snowflakes;
    let randomIndex = Math.floor(Math.random() * snowflakes.get('length'));
    return snowflakes.objectAt(randomIndex);
  }

  private randomColor(): string {
    let colors = this.colors;
    let randomIndex = Math.floor(Math.random() * colors.get('length'));
    return colors.objectAt(randomIndex) || colors[0];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::ConfettiRain': typeof ConfettiRain;
    'inbox2/common/confetti-rain': typeof ConfettiRain;
  }
}
