/* import __COLOCATED_TEMPLATE__ from './attribute-collector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const AttributeCollector = templateOnlyComponent<Signature>();
export default AttributeCollector;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::BotFlowParts::AttributeCollector': typeof AttributeCollector;
    'inbox2/conversation-stream/bot-flow-parts/attribute-collector': typeof AttributeCollector;
  }
}
