/* import __COLOCATED_TEMPLATE__ from './article-link.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';
import { type ArticleSearchResult } from 'embercom/lib/inbox2/copilot/types';

export interface ArticleLinkArgs {
  article: ArticleSearchResult['data']['article'];
  isIconLink?: boolean;
  onClickLink(event: PointerEvent): void;
}

interface Signature {
  Element: Element;
  Args: ArticleLinkArgs;
  Blocks: {
    default: [];
  };
}

const ArticleLink = templateOnlyComponent<Signature>();
export default ArticleLink;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Links::ArticleLink': typeof ArticleLink;
    'inbox2/copilot/links/article-link': typeof ArticleLink;
  }
}
