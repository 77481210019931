/* import __COLOCATED_TEMPLATE__ from './conversation-as-a-link.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

import type DuplicateConversationSummary from 'embercom/objects/inbox/duplicate-conversation-summary';
interface Args {
  conversationSummary: DuplicateConversationSummary;
  isTableView: boolean;
  onConversationClick: () => void;
}

interface Signature {
  Args: Args;
}

const ConversationAsALink = templateOnlyComponent<Signature>();
export default ConversationAsALink;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::ConversationAsALink': typeof ConversationAsALink;
    'inbox2/conversation-stream/part-groups/conversation-as-a-link': typeof ConversationAsALink;
  }
}
