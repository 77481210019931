/* import __COLOCATED_TEMPLATE__ from './visible-columns-selector.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';

interface Args {
  items: string[];
}

interface Signature {
  Args: Args;
}

export default class VisibleColumnsSelector extends Component<Signature> {
  get items() {
    return this.args.items;
  }

  get columnItems() {
    return [
      {
        heading: 'Displayed columns',
        items: this.items,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::VisibleColumnsSelector': typeof VisibleColumnsSelector;
    'inbox2/dashboard/visible-columns-selector': typeof VisibleColumnsSelector;
  }
}
