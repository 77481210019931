/* import __COLOCATED_TEMPLATE__ from './side-conversations.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type SideConversation from 'embercom/objects/inbox/side-conversation';
import type InboxApi from 'embercom/services/inbox-api';
import type Conversation from 'embercom/objects/inbox/conversation';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Router from '@ember/routing/router-service';
import type InboxState from 'embercom/services/inbox-state';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type Session from 'embercom/services/session';

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

interface Args {
  sideConversations: SideConversation[];
  parentConversation: Conversation;
  reloadSideConversations: () => void;
  previewOnly?: boolean;
}

export default class SideConversations extends Component<Signature> {
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare router: Router;
  @service declare session: Session;

  constructor(owner: SideConversation, args: Args) {
    super(owner, args);
    this.maybePreviewSideConversation();
  }

  get previewingObject() {
    return this.args.parentConversation.isTicket ? 'ticket' : 'conversation';
  }

  get canCreateSideConversation() {
    return !this.args.previewOnly;
  }

  maybePreviewSideConversation() {
    let previewSideConversationId =
      this.router.currentRoute?.queryParams?.preview_side_conversation;

    if (previewSideConversationId) {
      this.previewSideConversation(parseInt(previewSideConversationId, 10));
    }
  }

  @action onCreateSideConversationClick() {
    this.inboxSidebarService.openNewSideConversationPanel();
  }

  @action async handleConversationClick(sideConversationId: number) {
    if (this.args.previewOnly) {
      this.openSideConversationInNewTab(sideConversationId);
    } else {
      this.previewSideConversation(sideConversationId);
    }
  }

  private previewSideConversation(sideConversationId: number) {
    this.inboxSidebarService.previewSideConversation(
      sideConversationId,
      this.args.parentConversation.id,
      true,
    );

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'side_conversation',
      section: 'respond',
      place: 'details_sidebar',
    });
  }

  private openSideConversationInNewTab(sideConversationId: number): void {
    let inbox = this.inboxState.activeInbox || {
      id: InboxType.All,
      category: InboxCategory.Shared,
    };

    let url: string = this.router.urlFor(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      inbox.category,
      inbox.id,
      this.args.parentConversation.id,
    );

    safeWindowOpen(`${url}?preview_side_conversation=${sideConversationId}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Links::SideConversations': typeof SideConversations;
    'inbox2/conversation-details-sidebar/sidebar-sections/links/side-conversations': typeof SideConversations;
  }
}
