/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';
import { ResponseError, request } from 'embercom/lib/inbox/requests';
import { post } from 'embercom/lib/ajax';
import { helper } from '@ember/component/helper';
import { type DebugAdminMessageEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { NexusEventName } from 'embercom/services/nexus';
import { inject as service } from '@ember/service';
import generateUUID from 'embercom/lib/uuid-generator';
import type Session from 'embercom/services/session';
import { TrackedArray } from 'tracked-built-ins';
import { resource } from 'ember-resources/util/function-resource';
import ENV from 'embercom/config/environment';

interface Signature {
  Args: {};
}

type DeployedVersionInfoWireFormat = {
  id: string;
  version: string;
  created_at: string;
};

type DeployedVersionInfo = {
  id: string;
  version: string;
  createdAt: Date;
};

type PingInfoWireFormat = {
  ok: true;
  current_time: string;
};

type PingInfo = {
  serverTime: Date;
};

type NexusTestResponseWireFormat = {
  report_id: string;
  size: number;
};

type RequestTiming = {
  secureConnectionStart: number;
  connectStart: number;
  connectEnd: number;
  domainLookupStart: number;
  domainLookupEnd: number;
  duration: number;
  requestStart: number;
  responseStart: number;
  responseEnd: number;
  fetchStart: number;
  responseTime: number;
  dnsLookupTime: number;
  tcpHandshakeTime: number;
  requestTime: number;
  fetchTime: number;
  tlsTime: number;
  path: string;
};

type RequestAndServerTiming = RequestTiming & { serverTime: number; status: number };

function wait(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export default class DiagnosticsModal extends Component<Signature> {
  @service declare nexus: Nexus;
  @service declare session: Session;
  @service declare intercomEventService: any;

  @tracked isVisible = false;
  @tracked reportID?: string;
  @tracked reportCreatedAt?: Date;
  @tracked nexusMessages = new TrackedArray<DebugAdminMessageEvent['eventData']>();
  numRequestsToMake = 5;

  NexusEventName = NexusEventName;

  @use deployedVersion = AsyncData<DeployedVersionInfo>(async ({ signal }) => {
    if (!this.reportID) {
      return;
    }

    let response = await request(`/ember/version?run=${this.reportID}`, { signal });
    let { id, version, created_at } = (await response.json()) as DeployedVersionInfoWireFormat;
    return {
      id,
      version,
      createdAt: new Date(created_at),
    };
  });

  @use nexusTest = AsyncData<NexusTestResponseWireFormat>(async () => {
    if (!this.reportID) {
      return;
    }

    let json = await post(`/ember/inbox/diagnostics/test_nexus`, {
      app_id: this.session.workspace.id,
      report_id: this.reportID,
    });
    return json as NexusTestResponseWireFormat;
  });

  @use ping = AsyncData<PingInfo>(async ({ signal }) => {
    if (!this.reportID) {
      return;
    }

    let response = await request(
      `/ember/inbox/diagnostics/ping?report_id=${this.reportID}&app_id=${this.session.workspace.id}`,
      { signal },
    );
    let { current_time } = (await response.json()) as PingInfoWireFormat;
    return {
      serverTime: new Date(current_time),
    };
  });

  rounded = helper(function ([num]: [number | undefined]): string {
    if (num === undefined) {
      return '-';
    }

    return num.toFixed(2);
  });

  get browserVersion(): string {
    return navigator.userAgent;
  }

  get memoryUsage() {
    // access reportID so this gets re-run when reportID changes
    this.reportID;

    // @ts-ignore - typescript doesn't know about performance.memory as it's not part of the spec
    let memory = performance.memory;

    if (!memory) {
      return;
    }

    return memory as {
      jsHeapSizeLimit: number;
      totalJSHeapSize: number;
      usedJSHeapSize: number;
    };
  }

  get currentVersion(): DeployedVersionInfo | undefined {
    let id = this.metaContentFor('embercom_revision_id');
    let version = this.metaContentFor('embercom_revision_version');
    let created_at = this.metaContentFor('embercom_revision_created_at');

    if (!id || !version || !created_at) {
      return;
    }

    return {
      id,
      version,
      createdAt: new Date(created_at),
    };
  }

  @use benchmarkRequests = resource(() => {
    let state = new TrackedArray<RequestAndServerTiming>();
    state.length = this.numRequestsToMake;
    if (!this.reportID) {
      return state;
    }

    (async () => {
      for (let num = 0; num < this.numRequestsToMake; num++) {
        let result = await this.benchmarkRequest(`${this.reportID}-${num}`);
        state[num] = result;
      }
    })();

    return state;
  });

  get numRequestsCompleted() {
    return this.benchmarkRequests.compact().length;
  }

  private async benchmarkRequest(id: string): Promise<RequestAndServerTiming> {
    let path = `/ember/inbox/diagnostics/ping?run=${id}&app_id=${this.session.workspace.id}`;

    let timing!: RequestTiming;
    let observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry: PerformanceResourceTiming) => {
        if (entry.name.includes(path)) {
          let {
            secureConnectionStart,
            connectStart,
            connectEnd,
            domainLookupStart,
            domainLookupEnd,
            duration,
            requestStart,
            responseStart,
            responseEnd,
            fetchStart,
          } = entry;

          // https://developer.mozilla.org/en-US/docs/Web/API/Performance_API/Resource_timing#resource_loading_timestamps
          timing = {
            path,
            secureConnectionStart,
            connectStart,
            connectEnd,
            domainLookupStart,
            domainLookupEnd,
            duration,
            requestStart,
            responseStart,
            responseEnd,
            fetchStart,

            // https://developer.mozilla.org/en-US/docs/Web/API/Performance_API/Resource_timing#typical_resource_timing_metrics
            dnsLookupTime: domainLookupEnd - domainLookupStart,
            tcpHandshakeTime: connectEnd - connectStart,
            requestTime: responseStart - requestStart,
            responseTime: responseEnd - responseStart,
            tlsTime: secureConnectionStart === 0 ? 0 : requestStart - secureConnectionStart,

            fetchTime: responseEnd - fetchStart,
          };
        }
      });
    });

    observer.observe({ type: 'resource' });
    let response;

    try {
      response = await request(path);
    } catch (e) {
      if (e instanceof ResponseError) {
        response = e.response;
      } else {
        throw e;
      }
    } finally {
      if (ENV.environment !== 'test') {
        await wait(100); // observer sometimes takes a frame to fire
      }
      observer.disconnect();
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming/serverTiming
    // if we had Server-Timing we could grab from there instead of x-runtime
    let serverTimeStr = response.headers.get('x-runtime');
    let serverTime = serverTimeStr ? parseFloat(serverTimeStr) * 1000 : 0;
    return { ...timing, serverTime, status: response.status };
  }

  private metaContentFor(name: string) {
    let element = document.querySelector(`meta[name="${name}"]`);
    return element?.getAttribute('content');
  }

  @action debugNexusMessageReceived(message: DebugAdminMessageEvent) {
    let data = message.eventData;
    if (data.report_id === this.reportID) {
      this.nexusMessages.push(data);
    }
  }

  @action close() {
    this.isVisible = false;
  }

  @action show() {
    this.isVisible = true;
    this.resetReport();
  }

  @action run() {
    this.resetReport();
  }

  private resetReport() {
    this.nexusMessages.clear();
    this.reportID = generateUUID();
    this.reportCreatedAt = new Date();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'report_created',
      object: 'inbox_diagnostics',
      report_id: this.reportID,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Diagnostics::Modal': typeof DiagnosticsModal;
    'inbox2/diagnostics/modal': typeof DiagnosticsModal;
  }
}
