/* import __COLOCATED_TEMPLATE__ from './answer-rating.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { type AnswerRating } from 'embercom/objects/inbox/renderable/bot-comment';
import type BotComment from 'embercom/objects/inbox/renderable/bot-comment';
import { RatingType } from 'embercom/objects/inbox/renderable/bot-comment';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { put } from 'embercom/lib/ajax';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';

interface Args {
  part: RenderablePart & { renderableData: BotComment };
  conversationId: number;
}

interface Signature {
  Args: Args;
}

export default class AnswerRatingComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;

  ratingTypes = RatingType;

  get answerRating(): AnswerRating {
    // we know this has a rating because that's the only reason we're rendering the component
    return this.args.part.renderableData.answerRating!;
  }

  get isRatedPositive() {
    return this.answerRating.rating === RatingType.Positive;
  }

  get isRatedNegative() {
    return this.answerRating.rating === RatingType.Negative;
  }

  @task
  *saveRating(rating: RatingType) {
    let partId = this.args.part.generatePermalinkId(this.args.conversationId);
    let appId = this.session.workspace.id;
    let previousRating = this.answerRating.rating;

    try {
      yield put(`/ember/custom_answers/comment_ratings/${partId}`, {
        app_id: appId,
        id: partId,
        rating,
      });
      this.answerRating.rating = rating;
    } catch (e) {
      this.answerRating.rating = previousRating;
      console.error(e);
      this.snackbar.notifyError(this.intl.t('custom-answers.rate-conversation-part.error'));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::Parts::AnswerRating': typeof AnswerRatingComponent;
    'inbox2/conversation-stream/parts/answer-rating': typeof AnswerRatingComponent;
  }
}
