/* import __COLOCATED_TEMPLATE__ from './upload-attachment-action.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import { type DisplayContext } from 'embercom/services/command-k';

interface Args {
  composerApi?: ComposerPublicAPI;
  priority?: number;
  toggleUploading?: (value: boolean) => any;
  allowedFileTypes?: string[];
  activatingComponent?: `${DisplayContext}`;
  trackingData?: Record<string, unknown>;
}

interface Signature {
  Args: Args;
}

export default class UploadAttachmentAction extends Component<Signature> {
  @action async uploadAttachments(files: Array<File>) {
    try {
      this.args.toggleUploading?.(true);
      let promises: Promise<void>[] = [];

      files.forEach((file) => {
        let api = this.args.composerApi;
        if (!api) {
          return;
        }

        promises.push(api.composer.commands.addAttachment(file));
      });
      await Promise.all(promises);
    } finally {
      this.args.toggleUploading?.(false);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::CommandK::UploadAttachmentAction': typeof UploadAttachmentAction;
    'inbox2/composer/command-k/upload-attachment-action': typeof UploadAttachmentAction;
  }
}
