/* import __COLOCATED_TEMPLATE__ from './channel.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';

interface Signature {
  Args: {
    conversationEntry: ConversationTableEntry;
  };
}

const Channel = templateOnlyComponent<Signature>();
export default Channel;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::Channel': typeof Channel;
    'inbox2/conversations-table/table-cells/channel': typeof Channel;
  }
}
