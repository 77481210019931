/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type Named, Resource } from 'ember-resources';
import { action } from '@ember/object';
import type ApplicationInstance from '@ember/application/instance';
import type NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import {
  buildRowMap,
  findRowAndColumnForIndex,
  getIndexInNextRow,
  getIndexInPreviousRow,
  type RowMap,
} from 'embercom/objects/inbox/emoji-navigation';
import { type ItemGroups } from 'embercom/components/inbox2/command-k/emoji/pane';

// @ts-ignore
import { cached } from 'tracked-toolbox';

interface ResourceArgs {
  items: ItemGroups;
  selection: NavigableSelection;
}

const MAX_EMOJI_PER_ROW = 10;

// eslint-disable-next-line @intercom/intercom/no-component-inheritance
export default class EmojiNavigationResource extends Resource<Named<ResourceArgs>> {
  private rowMap: RowMap;

  constructor(
    owner: ApplicationInstance,
    args: Named<ResourceArgs>,
    previous: EmojiNavigationResource,
  ) {
    super(owner, args, previous);

    this.rowMap = buildRowMap(this.args.named.items, MAX_EMOJI_PER_ROW);
  }

  get selection() {
    return this.args.named.selection;
  }

  @action arrowLeft(e: KeyboardEvent, ek: any) {
    e.preventDefault();
    if (this.isFirstItemSelected) {
      return;
    }

    ek.stopPropagation();
    this.selection.previous();
  }

  @action arrowRight(e: KeyboardEvent, ek: any) {
    e.preventDefault();
    ek.stopPropagation();
    this.selection.next();
  }

  @action arrowDown(e: KeyboardEvent, ek: any) {
    e.preventDefault();
    ek.stopPropagation();

    if (this.onBottomRow) {
      return;
    }

    this.selection.next(this.getJumpForNextRow(this.currentSelectionIndex) - 1);
  }

  @action arrowUp(e: KeyboardEvent, ek: any) {
    e.preventDefault();
    ek.stopPropagation();

    if (this.onTopRow) {
      return;
    }

    this.selection.previous(this.getJumpForPreviousRow(this.currentSelectionIndex) - 1);
  }

  private getJumpForNextRow(selectedIndex: number) {
    let nextIndex = getIndexInNextRow(this.rowMap, selectedIndex);
    return nextIndex - selectedIndex;
  }

  private getJumpForPreviousRow(selectedIndex: number) {
    let nextIndex = getIndexInPreviousRow(this.rowMap, selectedIndex);
    return selectedIndex - nextIndex;
  }

  private get currentSelectionIndex() {
    return this.selection.indexOfSelected;
  }

  private get currentRow() {
    let [currentRow] = this.currentSelectionRowAndColumn;
    return currentRow;
  }

  @cached
  private get currentSelectionRowAndColumn() {
    return findRowAndColumnForIndex(this.rowMap, this.currentSelectionIndex);
  }

  private get onTopRow() {
    return this.currentRow === 0;
  }

  private get onBottomRow() {
    return this.currentRow === this.rowMap.length - 1;
  }

  private get isFirstItemSelected() {
    return this.currentSelectionIndex === 0;
  }
}
