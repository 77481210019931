/* import __COLOCATED_TEMPLATE__ from './task.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { type Task as ChecklistTask } from 'embercom/objects/inbox/checklists';

interface Args {
  task: ChecklistTask;
}

interface Signature {
  Args: Args;
}

export default class Task extends Component<Signature> {
  get isComplete() {
    return this.args.task.isComplete;
  }

  get taskNumber() {
    return this.args.task.order + 1;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Checklists::Task': typeof Task;
    'inbox2/conversation-details-sidebar/sidebar-sections/checklists/task': typeof Task;
  }
}
