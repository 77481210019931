/* import __COLOCATED_TEMPLATE__ from './preview-pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type CommandKMetadata } from 'embercom/services/command-k';
import type ArticlesApi from 'embercom/services/articles-api';
import { type WhatsappTemplateComponent } from 'embercom/objects/inbox/whatsapp-template';
import type WhatsappTemplate from 'embercom/objects/inbox/whatsapp-template';
import BrowserLocales from 'embercom/lib/browser-locales';
import AttributeInfoResolver, {
  APP_ATTRIBUTES,
} from 'embercom/lib/common/template-attribute-resolver';

interface Args {
  initialSelectedLanguage: string | undefined;
  templates: Array<WhatsappTemplate>;
  setMetadata: (metadata: CommandKMetadata) => unknown;
}

interface Signature {
  Args: Args;
}

export default class InsertTemplatePreviewPane extends Component<Signature> {
  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service attributeService: any;
  @service declare articlesApi: ArticlesApi;

  @tracked language: string | undefined;

  get selectedTemplate(): WhatsappTemplate | undefined {
    if (this.language && this.findByLanguage(this.language)) {
      return this.findByLanguage(this.language);
    }
    return this.findByLanguage(this.args.initialSelectedLanguage) || this.args.templates[0];
  }

  get resolver() {
    return new AttributeInfoResolver({
      manualAppAttributes: [
        ...this.attributeService.userAttributesOnly.rejectBy('isUserEvent', true),
        ...APP_ATTRIBUTES,
      ],
      hideAppAllowedAttributes: true,
    });
  }

  findByLanguage(language: string | undefined) {
    return this.args.templates.find((template) => template.language === language);
  }

  get languageName() {
    return this.languageNameFromCode(this.selectedTemplate?.language);
  }

  get availableLanguages(): Array<{ name: string; language: string; id: string }> {
    let languages = this.args.templates.map((template) => template.language);
    let uniqueLanguages = [...new Set(languages)];
    return uniqueLanguages.map((language) => {
      return { name: this.languageNameFromCode(language), language, id: language };
    });
  }

  languageNameFromCode(language: string | undefined): string {
    if (language) {
      let languageCode = language.replace('_', '-');
      return BrowserLocales[languageCode] || language?.toUpperCase();
    } else {
      return '';
    }
  }

  @action
  onSelectLanguage(item: { id: string; name: string; language: string }) {
    this.language = item.language;
  }

  @action
  onTemplateChange(_element: any, [template]: Array<WhatsappTemplate>) {
    this.args.setMetadata({ template });
  }

  @action
  onTemplateParametersChange(components: Array<WhatsappTemplateComponent>) {
    if (this.selectedTemplate) {
      this.selectedTemplate.updateParameters(components);
      this.onTemplateChange(null, [this.selectedTemplate]);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Whatsapp::InsertTemplate::PreviewPane': typeof InsertTemplatePreviewPane;
    'inbox2/command-k/whatsapp/insert-template/preview-pane': typeof InsertTemplatePreviewPane;
  }
}
