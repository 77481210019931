/* import __COLOCATED_TEMPLATE__ from './editable-ticket-title.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import type InboxApi from 'embercom/services/inbox-api';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import { InboxEvents } from 'embercom/services/inbox-state';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
interface Args {
  conversation: Conversation;
  highlights?: string[];
}

interface Signature {
  Args: Args;
}

export default class EditableTicketTitle extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare intercomEventService: any;

  @tracked editMode = false;

  @ref('highlighted-title-input') highlightedTitleInput!: HTMLElement;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get ticketTitle(): string {
    let titleSetByUser = this.args.conversation.attributes.find(
      (attribute) => attribute.descriptor.name === '_default_title_',
    )?.value;
    if (titleSetByUser) {
      return titleSetByUser as string;
    } else {
      return this.args.conversation.ticketType?.name as string;
    }
  }

  get titleDescriptor() {
    return this.args.conversation.ticketType?.descriptors?.find(
      (descriptor) => descriptor.name === '_default_title_',
    );
  }

  get ticketTitleAttribute() {
    if (!this.titleDescriptor) {
      return;
    }
    return this.conversationAttributesById[this.titleDescriptor.id];
  }

  get conversationAttributesById() {
    return this.args.conversation.attributes.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  trackTicketTitleChanged() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'ticket',
      action: 'changed',
      section: 'title',
      place: 'inbox',
    });
  }

  @action onKeyDown(event: KeyboardEvent) {
    let target = event.target as HTMLTextAreaElement;

    if (event.key === 'Enter') {
      target.blur();
    }
  }

  @action async updateTicketTitle(event: any) {
    if (!this.titleDescriptor) {
      return;
    }
    let newValue = event.target.value;
    if (newValue === this.ticketTitle) {
      return;
    }
    let attribute;
    if (this.ticketTitleAttribute === undefined) {
      attribute = new TicketAttributeSummary(newValue, this.titleDescriptor);
    } else {
      attribute = this.ticketTitleAttribute;
      attribute.value = newValue;
    }
    attribute.normalizeValue();
    await this.inboxApi.updateAttribute(this.args.conversation?.id, attribute);
    this.inboxState.trigger(InboxEvents.ConversationUpdated, this.args.conversation);
    this.trackTicketTitleChanged();
  }

  @action onBlur(event: FocusEvent) {
    this.updateTicketTitle(event);
    this.editMode = false;
  }

  @action onFocus() {
    this.editMode = true;
  }

  @action scrollInputBack() {
    if (!this.editMode) {
      this.highlightedTitleInput.scrollLeft = 0;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::EditableTicketTitle': typeof EditableTicketTitle;
    'inbox2/editable-ticket-title': typeof EditableTicketTitle;
  }
}
