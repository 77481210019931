/* import __COLOCATED_TEMPLATE__ from './messenger-app-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type MessengerCardBlock } from '@intercom/interblocks.ts';
import type Conversation from 'embercom/objects/inbox/conversation';
import type MessengerApp from 'embercom/objects/inbox/messenger-app';

interface Signature {
  Args: {
    messengerApp: MessengerApp;
    conversationId?: Conversation['id'];
    onAppSubmit: (data: MessengerCardBlock) => void;
    onClose: () => void;
  };
}

const MessengerAppModal = templateOnlyComponent<Signature>();
export default MessengerAppModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MessengerAppModal': typeof MessengerAppModal;
    'inbox2/composer/messenger-app-modal': typeof MessengerAppModal;
  }
}
