/* import __COLOCATED_TEMPLATE__ from './confirm-linking.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Signature {
  Args: {
    onClose: () => void;
    onModalClose: () => void;
    onConfirm: () => void;
    conversation: Conversation;
    selectedTicket: ConversationTableEntry | null;
  };
}

const ConfirmLinking = templateOnlyComponent<Signature>();
export default ConfirmLinking;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::SearchTicket::ConfirmLinking': typeof ConfirmLinking;
    'inbox2/command-k/search-ticket/confirm-linking': typeof ConfirmLinking;
  }
}
