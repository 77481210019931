/* import __COLOCATED_TEMPLATE__ from './timeframe-selector.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';

interface Args {
  defaultTimeframe: number;
}

interface Signature {
  Args: Args;
}

export default class TimeframeSelector extends Component<Signature> {
  get timeframes() {
    return [
      {
        heading: 'Timeframe',
        items: [
          { text: '1 hour', value: 1 },
          { text: '4 hours', value: 4 },
          { text: '8 hours', value: 8 },
          { text: '12 hours', value: 12 },
          { text: '24 hours', value: 24 },
        ],
      },
    ];
  }

  get selectedTimeframe() {
    return this.args.defaultTimeframe;
  }

  get timeframeLabel() {
    return this.timeframes[0].items?.find((t) => t.value === this.selectedTimeframe)?.text || '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::TimeframeSelector': typeof TimeframeSelector;
    'inbox2/dashboard/timeframe-selector': typeof TimeframeSelector;
  }
}
