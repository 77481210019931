/* import __COLOCATED_TEMPLATE__ from './attributes.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import type Attribute from 'embercom/objects/inbox/attribute';
import AttributeIconMap from 'embercom/models/data/attribute-icon-map';
import type User from 'embercom/objects/inbox/user';
import type Conversation from 'embercom/objects/inbox/conversation';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type IntlService from 'embercom/services/intl';
import {
  BLOCKLISTED_ATTRIBUTE_NAMES,
  DEFAULT_ICON_NAME,
  ALWAYS_PINNED_ATTRIBUTE_KEYS,
  type UserAttributeWithIconLinkParams,
} from 'embercom/components/inbox2/conversation-details-sidebar/sidebar-sections/user-details';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';

type Args = SidebarSectionComponentArgs<{
  userAttributes: Attribute[];
  toggleUserDetailsPin: (pinnedAttrsKeys: string[]) => unknown;
  activeInbox: Inbox;
  user: User;
  readOnly: boolean;
  enableImpersonateButton: boolean;
  conversation: Conversation;
  analyticsSection: string;
  isLoadingUser: boolean;
  isLoadingUserAttributes: boolean;
  onUserAttributeUpdated: (attribute: Attribute) => unknown;
}>;

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class UserDetailsAttributes extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare inboxSidebarService: InboxSidebarService;

  @trackedReset('args.conversation') searchAttributeValue = '';

  get userNameAttribute(): UserAttributeWithIconLinkParams {
    return {
      key: 'name',
      name: 'name',
      humanFriendlyName: this.intl.t('inbox.conversation-details-sidebar.attributes.name'),
      type: 'string',
      icon: 'multiple-people',
      value: this.args.user.name,
      linkParams: this.args.user.id
        ? { route: 'apps.app.users.user', models: [this.session.workspace.id, this.args.user.id] }
        : null,
      adminVisibilitySetting: {
        visible: true,
      },
      requiresFallback: true,
    };
  }

  get emailIdentifiesUser() {
    return this.args.user?.hasUserRole && !this.args.user?.userId;
  }

  get companyNameAttribute(): UserAttributeWithIconLinkParams {
    return {
      key: 'companyName',
      name: 'companyName',
      humanFriendlyName: this.intl.t('inbox.conversation-details-sidebar.attributes.company'),
      type: 'string',
      icon: 'company',
      value: this.args.user.company?.name,
      linkParams: this.args.user.company
        ? {
            route: 'apps.app.companies.company',
            models: [this.session.workspace.id, this.args.user.company.id],
          }
        : null,
      adminVisibilitySetting: {
        visible: true,
      },
      requiresFallback: true,
    };
  }

  get roleAttribute(): UserAttributeWithIconLinkParams | undefined {
    let roleAttribute = this.args.userAttributes.find(
      (attribute) => attribute?.key === 'role',
    ) as UserAttributeWithIconLinkParams;
    if (roleAttribute) {
      roleAttribute = {
        ...roleAttribute,
        icon: 'person',
        adminVisibilitySetting: {
          visible: true,
        },
      };
    }
    return roleAttribute;
  }

  get userLocationAttribute(): UserAttributeWithIconLinkParams {
    return {
      key: 'location',
      name: 'location',
      humanFriendlyName: this.intl.t('inbox.conversation-details-sidebar.attributes.location'),
      type: 'string',
      icon: 'location',
      value: this.userLocation,
      adminVisibilitySetting: {
        visible: true,
      },
      requiresFallback: true,
    };
  }

  get alwaysPinnedAttributeKeys() {
    return ALWAYS_PINNED_ATTRIBUTE_KEYS;
  }

  get userName(): string {
    let userName =
      this.args.user?.attributes.name ||
      this.args.userAttributes?.find((attr) => attr.key === 'name')?.value;

    return userName || this.roleValue;
  }

  get roleValue(): string {
    let userRoleValue = this.intl
      .t('inbox.conversation-details-sidebar.role.user')
      ?.toLocaleLowerCase();
    let role = this.args.userAttributes?.find((attr) => attr.key === 'role');
    let roleValue = role?.value || this.args.user?.attributes['role'];
    if (roleValue === 'contact_role') {
      return this.intl.t('inbox.conversation-details-sidebar.role.contact')?.toLocaleLowerCase();
    }
    return userRoleValue;
  }

  get locationFallbackText(): string {
    return this.intl.t('inbox.attribute-list-item.location-fallback', { username: this.userName });
  }

  get companyNameFallbackText(): string {
    return this.intl.t('inbox.attribute-list-item.company-fallback', { username: this.userName });
  }

  get userNameFallbackText(): string {
    return this.intl.t('inbox.attribute-list-item.username-fallback', { type: this.roleValue });
  }

  get allAttributes() {
    return (this.args.userAttributes ?? []).reduce((attributes, attr) => {
      if (!attr || BLOCKLISTED_ATTRIBUTE_NAMES.includes(attr.key)) {
        return attributes;
      }

      attributes.push({
        ...attr,
        icon:
          AttributeIconMap.icons[attr.key as keyof typeof AttributeIconMap.icons] ??
          DEFAULT_ICON_NAME,
      });
      return attributes;
    }, [] as UserAttributeWithIconLinkParams[]);
  }

  get pinnedAttributes() {
    return [
      this.userNameAttribute,
      this.companyNameAttribute,
      this.roleAttribute,
      this.userLocationAttribute,
      ...this.allAttributes.filter((attribute) => attribute.adminVisibilitySetting.visible),
    ];
  }

  get unpinnedAttributes() {
    return this.allAttributes.filter((attribute) => !attribute.adminVisibilitySetting.visible);
  }

  get pinnedAndUnpinnedAttributes() {
    return [...this.pinnedAttributes, ...this.unpinnedAttributes];
  }

  get filteredAttributes() {
    if (this.searchAttributeValue.trim()) {
      return this.pinnedAndUnpinnedAttributes.filter((attr: Attribute) => {
        return (
          attr &&
          attr.humanFriendlyName
            .toLocaleLowerCase()
            .includes(this.searchAttributeValue.toLocaleLowerCase())
        );
      });
    } else {
      return this.pinnedAndUnpinnedAttributes;
    }
  }

  @action togglePin(key: string) {
    let pinnedAttrsKeys: string[] = this.pinnedAttributes.reduce((arr: string[], attr) => {
      if (attr?.key) {
        arr.push(attr.key);
      }
      return arr;
    }, []);
    let attribute: UserAttributeWithIconLinkParams | undefined = this.allAttributes.find(
      (attr) => attr && attr.key === key,
    );

    if (attribute) {
      if (attribute.adminVisibilitySetting.visible) {
        pinnedAttrsKeys = pinnedAttrsKeys.filter((attrKey) => attrKey !== attribute?.key);
      } else {
        pinnedAttrsKeys = [...pinnedAttrsKeys, attribute.key];
      }
      this.args.toggleUserDetailsPin(pinnedAttrsKeys);
    }
  }

  private get userLocation() {
    let geoipData = this.args.user?.geoipData;
    if (geoipData?.hasData()) {
      let { cityName, countryCode2, countryName, regionCode, timezone } = geoipData;

      let localTime = timezone
        ? this.intl.formatTime(new Date(), {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timezone,
          })
        : '';

      let locationParts;
      if (countryCode2 === 'US') {
        locationParts = [cityName, regionCode, countryName];
      } else {
        locationParts = [cityName, countryName];
      }

      let location = locationParts.filter((n) => n).join(', ');
      return [localTime, location].filter((n) => n).join(' · ');
    }

    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::UserDetails::Attributes': typeof UserDetailsAttributes;
    'inbox2/conversation-details-sidebar/sidebar-sections/user-details/attributes': typeof UserDetailsAttributes;
  }
}
