/* import __COLOCATED_TEMPLATE__ from './teammate-email-reply.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { Channel } from 'embercom/models/data/inbox/channels';
import type Conversation from 'embercom/objects/inbox/conversation';
import type AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface TeammateEmailReplyRenderablePart extends RenderablePart {
  renderableData: AdminComment;
}

interface TeammateEmailReplyPartGroup extends PartGroup {
  parts: Array<TeammateEmailReplyRenderablePart>;
}

interface Args {
  partGroup: TeammateEmailReplyPartGroup;
  conversation: Conversation;
  isReadOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class Inbox2ConversationStreamPartGroupsTeammateEmailReplyComponent extends Component<Signature> {
  @service declare session: Session;

  get teammate(): AdminSummary {
    return this.args.partGroup.parts[0].renderableData.adminSummary;
  }

  get emailChannel() {
    return Channel.Email;
  }

  get lastPartSeenByUser() {
    let { conversation } = this.args;

    return (
      conversation?.lastSeenByUserAt &&
      conversation?.lastSeenByUserAt >= this.lastPartInGroup.createdAt
    );
  }

  get lastPartInGroup() {
    let { parts } = this.args.partGroup;
    return parts[parts.length - 1];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::TeammateEmailReply': typeof Inbox2ConversationStreamPartGroupsTeammateEmailReplyComponent;
    'inbox2/conversation-stream/part-groups/teammate-email-reply': typeof Inbox2ConversationStreamPartGroupsTeammateEmailReplyComponent;
  }
}
