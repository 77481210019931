/* import __COLOCATED_TEMPLATE__ from './sidebar-settings.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from 'underscore';
import Component from '@glimmer/component';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import type Session from 'embercom/services/session';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {}

type Option = {
  name: string;
  icon: InterfaceIconName;
  action: () => void;
};

export default class CopilotSidebarSettings extends Component<Args> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare router: RouterService;
  @service declare intercomEventService: any;

  get isAdmin(): boolean {
    return (
      this.session.teammate.permissions.canAccessBillingSettings ||
      this.session.teammate.permissions.canAccessWorkspaceSettings
    );
  }

  get primaryOptions() {
    let optionList: Option[] = [
      {
        name: 'knowledge-base',
        icon: 'campaign',
        action: () =>
          this.transitionTo('apps.app.knowledge-hub.overview', {
            queryParams: { tab: 'ai-copilot' },
          }),
      },
      {
        name: 'reporting',
        icon: 'bar-charts',
        action: () => this.transitionTo('apps.app.reports.customer-support.fin-ai-copilot'),
      },
    ];

    if (this.isAdmin) {
      optionList.push({
        name: 'manage-access',
        icon: 'multiple-people',
        action: () => this.transitionTo('apps.app.settings.workspace.teammates'),
      });
    }

    return optionList;
  }

  get helpOption() {
    let option: Option = {
      name: 'copilot-help',
      icon: 'help-space',
      action: () =>
        safeWindowOpen(
          'https://www.intercom.com/help/en/collections/9041311-fin-ai-copilot',
          '_blank',
        ),
    };
    return option;
  }

  @action transitionTo(route: string, options: { queryParams: object } | {} = {}) {
    let url = isEmpty(options)
      ? this.router.urlFor(route, this.session.workspace.id)
      : this.router.urlFor(route, this.session.workspace.id, options);
    safeWindowOpen(url, '_blank');

    this.intercomEventService.trackAnalyticsEvent({
      object: 'sidebar_settings',
      context: 'navigation_from_copilot',
      action: 'clicked',
      route: route.replace(/[.-]/g, '_'),
      section: 'copilot_chat',
    });
  }

  @action onOptionClick(option: Option) {
    option.action();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::SidebarSettings': typeof CopilotSidebarSettings;
  }
}
