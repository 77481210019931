/* import __COLOCATED_TEMPLATE__ from './ticket-created-at-row.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Signature {
  Args: {
    createdAt: Date | undefined;
  };
}

export default class TicketCreatedAtRow extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  get createdAtLabelText() {
    let translationPrefix = 'inbox.conversation-details';
    let translationSuffix = 'submission-date';

    return this.intl.t(`${translationPrefix}.${translationSuffix}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::TicketPanel::TicketCreatedAtRow': typeof TicketCreatedAtRow;
  }
}
