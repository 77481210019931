/* import __COLOCATED_TEMPLATE__ from './relevant-part-owner-details.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import type AdminNote from 'embercom/objects/inbox/renderable/admin-note';
import type UserComment from 'embercom/objects/inbox/renderable/user-comment';
import type ConversationSummary from 'embercom/objects/inbox/renderable/conversation-summary';
import type UserEmailComment from 'embercom/objects/inbox/renderable/user-email-comment';
import type QuickReplyResponse from 'embercom/objects/inbox/renderable/quick-reply-response';
import type Chat from 'embercom/objects/inbox/renderable/chat';
import type Email from 'embercom/objects/inbox/renderable/email';
import type Post from 'embercom/objects/inbox/renderable/post';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Signature {
  Args: {
    relevantConversationEntry?: ConversationTableEntry;
  };
  Element: HTMLElement;
}

export default class RelevantPartOwnerDetails extends Component<Signature> {
  @service declare intl: IntlService;

  get relevantPart() {
    return this.args.relevantConversationEntry?.relevantPart;
  }

  get lastRenderablePart() {
    return this.args.relevantConversationEntry?.lastRenderableSummaryPart;
  }

  get renderableData() {
    return this.relevantPart?.renderableData || this.lastRenderablePart?.renderableData;
  }

  get hasTicketTitleOrDescriptionMatches() {
    let highlights = this.args.relevantConversationEntry?.highlights;
    return (
      (highlights?.ticketTitle && highlights?.ticketTitle?.length > 0) ||
      (highlights?.ticketDescription && highlights?.ticketDescription?.length > 0)
    );
  }

  get relevantPartOwnerDisplayText() {
    let renderableData = this.renderableData;
    let ownerName: string | undefined;
    let translationKey: string | undefined;

    if (this.args.relevantConversationEntry?.isTicket && this.hasTicketTitleOrDescriptionMatches) {
      return this.intl.t('inbox.conversations-table.cells.relevantPart.ticket');
    }

    switch (renderableData?.renderableType) {
      case RenderableType.AdminComment:
        ownerName = (renderableData as AdminComment).adminSummary?.name;
        translationKey = 'inbox.conversations-table.cells.relevantPart.someone-replied';
        break;
      case RenderableType.AdminNote:
        ownerName = (renderableData as AdminNote).adminSummary?.name;
        translationKey = 'inbox.conversations-table.cells.relevantPart.someone-noted';
        break;
      case RenderableType.ConversationSummary:
        ownerName = (renderableData as ConversationSummary).adminSummary?.name;
        translationKey = 'inbox.conversations-table.cells.relevantPart.someone-replied';
        break;
      case RenderableType.UserComment:
        ownerName = (renderableData as UserComment).userSummary?.displayAs;
        translationKey = 'inbox.conversations-table.cells.relevantPart.someone-replied';
        break;
      case RenderableType.UserEmailComment:
        ownerName = (renderableData as UserEmailComment).userSummary?.displayAs;
        translationKey = 'inbox.conversations-table.cells.relevantPart.someone-replied';
        break;
      case RenderableType.QuickReplyResponse:
        ownerName = (renderableData as QuickReplyResponse).userSummary?.displayAs;
        translationKey = 'inbox.conversations-table.cells.relevantPart.someone-responded';
        break;
      case RenderableType.Chat:
        ownerName = (renderableData as Chat).senderSummary?.name;
        translationKey = 'inbox.conversations-table.cells.relevantPart.someone-replied';
        break;
      case RenderableType.Email:
        ownerName = (renderableData as Email).senderSummary?.name;
        translationKey = 'inbox.conversations-table.cells.relevantPart.someone-replied';
        break;
      case RenderableType.Post:
        ownerName = (renderableData as Post).senderSummary?.name;
        translationKey = 'inbox.conversations-table.cells.relevantPart.someone-posted';
        break;
    }

    if (ownerName && translationKey) {
      return this.intl.t(translationKey, { ownerName });
    }

    return null;
  }

  get lastUpdated() {
    return this.relevantPart?.createdAt || this.lastRenderablePart?.createdAt;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::RelevantPartOwnerDetails': typeof RelevantPartOwnerDetails;
  }
}
