/* import __COLOCATED_TEMPLATE__ from './empty.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const Empty = templateOnlyComponent<Signature>();
export default Empty;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::TableCells::Empty': typeof Empty;
    'inbox2/conversations-table/table-cells/empty': typeof Empty;
  }
}
