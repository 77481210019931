/* import __COLOCATED_TEMPLATE__ from './sort-icon.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    small?: boolean;
    direction: 'asc' | 'desc';
  };
}

const SortIcon = templateOnlyComponent<Signature>();
export default SortIcon;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::Icons::SortIcon': typeof SortIcon;
    'inbox2/conversations-table/icons/sort-icon': typeof SortIcon;
  }
}
