/* import __COLOCATED_TEMPLATE__ from './image-blocks-as-text.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import { type BlockList, type Block, type Image, type List } from '@intercom/interblocks.ts';

interface Args {
  blocks: BlockList;
}

interface Signature {
  Args: Args;
}

const MAX_LENGTH = 500;

export default class ImageBlocksAsText extends Component<Signature> {
  @service declare intl: IntlService;

  get textBlocks(): Array<any> {
    if (!this.args.blocks) {
      return [];
    }

    let blocks = this.args.blocks;
    let totalTextLength = 0;
    let textEntries = [];

    for (let i = 0; i < blocks.length && totalTextLength < MAX_LENGTH; i++) {
      let block = blocks[i];
      let blockText = blockToText(block, this.intl);
      totalTextLength += blockText.length;
      textEntries.push({ type: block.type, text: blockText });
    }
    return textEntries;
  }
}

export function blockToText(block: Block, intl: IntlService): string {
  switch (block.type) {
    case 'image':
      block = block as Image;
      if (!block.url) {
        return intl.t('inbox.common.block-as-text.sent-image');
      }
      if (block.url.includes('gifs.intercomcdn.com')) {
        return intl.t('inbox.common.block-as-text.sent-gif');
      }
      return `${intl.t('inbox.common.block-as-text.sent-image')} "${basename(block.url)}"`;
    case 'unorderedList':
    case 'orderedList':
      block = block as List;
      return block.items.map((item) => item).join(' ');
    default:
      if ('text' in block) {
        return block.text || '';
      } else {
        return '';
      }
  }
}

function basename(str: string) {
  let fileName = new URL(str).pathname.split('/').pop();
  return fileName && fileName.length > 50 ? `${fileName.substring(0, 50)}...` : fileName;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::ImageBlocksAsText': typeof ImageBlocksAsText;
    'inbox2/common/image-blocks-as-text': typeof ImageBlocksAsText;
  }
}
