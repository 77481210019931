/* import __COLOCATED_TEMPLATE__ from './created-by-forwarding.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type CreatedByForwarding from 'embercom/objects/inbox/renderable/created-by-forwarding';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import LinkWithText from 'embercom/helpers/link-with-text';
import type Router from '@ember/routing/router-service';
import type InboxState from 'embercom/services/inbox-state';
import type IntlService from 'ember-intl/services/intl';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';

interface CreatedByForwardingPart extends RenderablePart {
  renderableData: CreatedByForwarding;
}

interface Args {
  part: CreatedByForwardingPart;
}

export default class CreatedByForwardingPartComponent extends Component<Args> {
  @service declare session: Session;
  @service declare router: Router;
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;

  get translationData() {
    let adminSummary = this.args.part.renderableData.adminSummary;
    let originalConversationId = this.args.part.renderableData.originalConversationId;

    let key = 'someone-forwarded-part';

    key =
      adminSummary && adminSummary.id === this.session.teammate.id
        ? 'you-forwarded-part'
        : 'admin-forwarded-part';

    let baseUrl = window.location.origin;

    let inbox = this.inboxState.activeInbox || {
      id: InboxType.All,
      category: InboxCategory.Shared,
    };

    let conversationUrl = this.router.urlFor(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      inbox.category,
      inbox.id,
      originalConversationId,
    );

    let data = {
      // @ts-ignore helper doesn't seem to be typed correctly
      conversationLink: LinkWithText.create(this).compute_with_href(
        baseUrl + conversationUrl,
        this.intl.t('inbox.created-by-forwarding.existing-conversation'),
      ),
      // @ts-ignore helper doesn't seem to be typed correctly
      admin:
        adminSummary &&
        // @ts-ignore helper doesn't seem to be typed correctly
        LinkWithText.create(this).compute([
          'apps.app.admins.admin',
          adminSummary.id,
          adminSummary.name,
        ]),
    };

    return { key, data };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::CreatedByForwarding': typeof CreatedByForwardingPartComponent;
    'inbox2/conversation-stream/event-parts/created-by-forwarding': typeof CreatedByForwardingPartComponent;
  }
}
