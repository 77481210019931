/* import __COLOCATED_TEMPLATE__ from './tasks.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import { type Checklist } from 'embercom/objects/inbox/checklists';
import { generateShareableUrl } from 'embercom/models/checklists/checklist';

interface Args {
  checklist: Checklist;
}

interface Signature {
  Args: Args;
}

export default class Tasks extends Component<Signature> {
  @service declare intl: IntlService;

  get generatedShareableUrl() {
    return generateShareableUrl(this.args.checklist.shareableUrl, this.args.checklist.rulesetId);
  }

  get hasShareableUrl() {
    return !!this.args.checklist.shareableUrl;
  }

  get completedTasks() {
    return this.args.checklist.completedTasks;
  }

  get pendingTasks() {
    return this.args.checklist.pendingTasks;
  }

  get hasCompletedTasks() {
    return this.completedTasks.length > 0;
  }

  get hasPendingTasks() {
    return this.pendingTasks.length > 0;
  }

  get completedStepsLabel() {
    return this.intl.t(
      'inbox.conversation-details-sidebar.checklists.focused-completed-steps-label',
    );
  }

  get nextStepsLabel() {
    return this.intl.t('inbox.conversation-details-sidebar.checklists.focused-next-steps-label');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Checklists::Tasks': typeof Tasks;
    'inbox2/conversation-details-sidebar/sidebar-sections/checklists/tasks': typeof Tasks;
  }
}
