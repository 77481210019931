/* import __COLOCATED_TEMPLATE__ from './auto-summarize-limit-reached.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type AutoSummarizeLimitReached from 'embercom/objects/inbox/renderable/auto-summarize-limit-reached';

export interface AutoSummarizeLimitReachedSignature {
  Args: {
    part: RenderablePart & { renderableData: AutoSummarizeLimitReached };
  };
}

const AutoSummarizeLimitReachedPartComponent =
  templateOnlyComponent<AutoSummarizeLimitReachedSignature>();
export default AutoSummarizeLimitReachedPartComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::AutoSummarizeLimitReached': typeof AutoSummarizeLimitReachedPartComponent;
    'inbox2/conversation-stream/event-parts/auto-summarize-limit-reached': typeof AutoSummarizeLimitReachedPartComponent;
  }
}
