/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { type CopilotChatArgs as ChatArgs } from './chat';
import type Conversation from 'embercom/objects/inbox/conversation';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { EntityType } from 'embercom/models/data/entity-types';
import type SidebarManager from 'embercom/services/sidebar-manager';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { type FinSource } from 'embercom/objects/inbox/inbox-assistant-conversation';
import { type CopilotChatMessageAnalyticsData } from 'embercom/lib/inbox2/copilot/types';

interface Args extends Omit<ChatArgs, 'activeConversationId'> {
  activeConversation: Conversation;
  isCollapsed: boolean;
  activeParticipantId: string;
  isCopilotVisible: boolean;
}

interface Signature {
  Args: Args;
}

export default class CopilotSidebar extends Component<Signature> {
  @service declare sidebarManager: SidebarManager;
  @service declare inboxSidebarService: InboxSidebarService;

  @action openSourcePreview(
    content: FinSource,
    analyticsData: CopilotChatMessageAnalyticsData,
    highlightedBlockIndices?: [number, number],
    articleVersionId?: string,
  ) {
    if (
      (content.entity_type === EntityType.PastConversationSnippet ||
        content.entity_type === EntityType.SlackThread) &&
      content.data
    ) {
      this.sidebarManager.openSheet({
        name: 'conversation-preview',
        conversationId: content.data.id,
        analyticsData,
        entityId: content.entity_id,
        highlightedConversationPartSelectors: [],
      });
    } else if (content.entity_type === EntityType.ConversationExcerpt && content.data) {
      let selectors = content.data.conversation_part_selectors
        ? content.data.conversation_part_selectors
        : [];
      this.sidebarManager.openSheet({
        name: 'conversation-preview',
        conversationId: content.data.id,
        analyticsData,
        entityId: content.entity_id,
        highlightedConversationPartSelectors: selectors,
      });
    } else if (content.entity_type === EntityType.SavedReply && content.data) {
      this.sidebarManager.openSheet({
        name: 'macro-preview',
        entityId: content.entity_id,
        analyticsData,
        conversation: this.args.activeConversation,
        onAddToComposerClick: this.args.insertBlocks,
        onAddNoteToComposerClick: this.args.insertNoteBlocks,
      });
    } else {
      this.sidebarManager.openSheet({
        name: 'knowledge-base-source-preview',
        entityId: content.entity_id,
        entityType: content.entity_type,
        userId: this.args.activeParticipantId,
        analyticsData,
        highlightedBlockIndices,
        articleVersionId,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Sidebar': typeof CopilotSidebar;
  }
}
