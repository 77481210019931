/* import __COLOCATED_TEMPLATE__ from './updating-timestamp.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import ENV from 'embercom/config/environment';
// @ts-ignore
import { localCopy } from 'tracked-toolbox';

interface Args {
  timestamp: any;
  duration?: number;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [timestamp: any];
  };
}

export default class Inbox2CommonUpdatingTimestampComponent extends Component<Signature> {
  @localCopy('args.timestamp') timestamp: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    // We don't need to run the clock during tests
    if (ENV.environment !== 'test') {
      taskFor(this.timer).perform();
    }
  }

  @task *timer() {
    yield timeout(this.args.duration ?? 5_000);
    this.updateLocalTimestamp();
    taskFor(this.timer).perform();
  }

  @action updateLocalTimestamp() {
    this.timestamp = this.args.timestamp;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::UpdatingTimestamp': typeof Inbox2CommonUpdatingTimestampComponent;
    'inbox2/common/updating-timestamp': typeof Inbox2CommonUpdatingTimestampComponent;
  }
}
