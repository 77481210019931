/* import __COLOCATED_TEMPLATE__ from './tag.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { tracked } from '@glimmer/tracking';
import type Tag from 'embercom/objects/inbox/tag';

interface Args {
  tag: Tag;
  link?: string;
  removeTag?: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

const tagSelectors = {
  wrapper: '[data-tag-name-wrapper]',
  name: '[data-tag-name]',
};

export default class TagComponent extends Component<Signature> {
  @tracked isHovered = false;
  @tracked hasTruncatedText = false;
  @tracked isCloseIconHovered = false;

  get canRenderTooltip() {
    let hasCreatedData = this.args.tag.createdBy && this.args.tag.createdAt;

    return !this.isCloseIconHovered && (this.hasTruncatedText || hasCreatedData);
  }

  @action handleMouseOver(event: MouseEvent) {
    this.handleHovererdState(true);
    this.checkIfTagTruncated(event);
  }

  @action checkIfTagTruncated(event: MouseEvent) {
    let tagPill = event.currentTarget as HTMLElement;
    let tagNameWrapper = tagPill?.querySelector<HTMLElement>(tagSelectors.wrapper);
    let tagName = tagPill?.querySelector<HTMLElement>(tagSelectors.name);

    if (tagNameWrapper === null || tagName === null) {
      return;
    }

    let tagNameWrapperWidth = tagNameWrapper.offsetWidth;
    let tagNameWidth = tagName.offsetWidth;

    if (tagNameWidth > tagNameWrapperWidth) {
      this.setTruncatedText(true);
    }
  }

  @action handleHovererdState(isHovered: boolean) {
    this.isHovered = isHovered;
  }

  @action setTruncatedText(isTruncated: boolean) {
    this.hasTruncatedText = isTruncated;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Tag': typeof TagComponent;
    'inbox2/common/tag': typeof TagComponent;
  }
}
