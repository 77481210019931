/* import __COLOCATED_TEMPLATE__ from './convert-ticket-panel.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import { action } from '@ember/object';
import { useResource } from 'ember-resources';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type Snackbar from 'embercom/services/snackbar';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import Conversation from 'embercom/objects/inbox/conversation';
import type InboxApi from 'embercom/services/inbox-api';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import type InboxState from 'embercom/services/inbox-state';

export interface ConvertTicketPanelArgs {
  originalConversation: Conversation;
  ticketTypeToLinkId?: number;
  hidePanel: () => void;
}

interface Signature {
  Args: ConvertTicketPanelArgs;
}

export default class ConvertTicketPanel extends Component<Signature> {
  @service declare snackbar: Snackbar;
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;

  selectedUserCompanyId: string | null = null;

  readonly ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    ticketTypeId: this.args.ticketTypeToLinkId,
    conversation: undefined,
  }));

  get hasMissingRequiredAttributes() {
    return this.ticketTypeResource.ticketAttributes.any((attr) => attr.showErrorOnCreate);
  }

  @task({ drop: true })
  *convertToTicket(): any {
    if (this.args.ticketTypeToLinkId === undefined) {
      return;
    }

    if (this.hasMissingRequiredAttributes) {
      this.snackbar.notifyError(
        this.intl.t('inbox.new-conversation.errors.invalid-ticket-attributes'),
      );
      return;
    }

    let ticketAttributes = this.ticketTypeResource.ticketAttributes?.map(
      (attributeSummary: TicketAttributeSummary) => {
        return {
          descriptor_id: attributeSummary.descriptor.id,
          value: attributeSummary.value,
        };
      },
    );

    try {
      let conversationWire = yield this.inboxApi.createTicketFromConversation(
        this.args.originalConversation,
        this.args.ticketTypeToLinkId,
        ticketAttributes,
        true,
        this.selectedUserCompanyId,
      );
      let conversation = Conversation.deserialize(conversationWire);
      if (!conversation.ticketType) {
        throw new Error(this.intl.t('inbox.notifications.ticket-creation-failed'));
      }
      let attributes = conversation.attributes;
      this.args.originalConversation.addTicket(conversation.ticketType, attributes);
      this.ticketTypeResource.refreshAttributes();
      if (this.inboxState?.activeConversation?.id === conversation.id) {
        // Update active conversation object with the new converted one ( if we still are in this conversation)
        this.inboxState.activeConversation = conversation;
      }
      this.args.hidePanel();
    } catch (e) {
      let errorText =
        e?.jqXHR?.responseJSON?.errors || this.intl.t('inbox.notifications.ticket-creation-failed');
      this.snackbar.notifyError(errorText);
    }
  }

  get isConvertingToTicket() {
    return taskFor(this.convertToTicket).isRunning;
  }

  @action
  handleCompanySelected(companyId: string | null) {
    this.selectedUserCompanyId = companyId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConvertTicketPanel': typeof ConvertTicketPanel;
    'inbox2/convert-ticket-panel': typeof ConvertTicketPanel;
  }
}
