/* import __COLOCATED_TEMPLATE__ from './topics-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLElement;
  Args: any;
  Blocks: {
    opener: [any];
    footer: [any];
  };
}

const TopicsSelector = templateOnlyComponent<Signature>();
export default TopicsSelector;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::TopicsSelector': typeof TopicsSelector;
    'inbox2/common/topics-selector': typeof TopicsSelector;
  }
}
