/* import __COLOCATED_TEMPLATE__ from './editable-ticket-description-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import type InboxApi from 'embercom/services/inbox-api';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { type TicketDescriptor } from 'embercom/objects/inbox/ticket';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import {
  BaseConfig,
  BlocksDocument,
  EMOJI_TYPEAHEAD,
} from '@intercom/embercom-prosemirror-composer';
import type IntlService from 'embercom/services/intl';
import { type Block } from '@intercom/interblocks.ts';
import type InboxState from 'embercom/services/inbox-state';
import { InboxEvents } from 'embercom/services/inbox-state';
import type Conversation from 'embercom/objects/inbox/conversation';
import { INLINE_CONTROL_ALIGNMENT } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import storage from 'embercom/vendor/intercom/storage';
import { registerDestructor } from '@ember/destroyable';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';

type AttributeSummary = TicketAttributeSummary | ConversationAttributeSummary;

const FORMATTING_POPOVER_SELECTOR = '.embercom-prosemirror-popover';

interface Args {
  descriptionDescriptor?: TicketDescriptor;
  descriptionAttribute?: AttributeSummary;
  conversation?: Conversation;
  highlights?: string[];
  isCreatingTicket?: boolean;
  hasError?: boolean;
  isReadOnly?: boolean;
}

interface Signature {
  Args: Args;
}

export default class EditableTicketDescriptionAttribute extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare inboxSidebarService: InboxSidebarService;

  @tracked scrollHeight = 72;
  @tracked editMode = false;
  @tracked clientHeight: number = document.body.clientHeight;
  @tracked collapsedMode: boolean = storage.get('inbox-ticket-description-collapse-state') || false;
  @tracked descriptionIsFocused = false;
  @trackedReset({
    memo: 'inputValueResetMemo',
    update() {
      return this.convertValueToBlocks();
    },
  })
  value: BlocksDocument = this.convertValueToBlocks();
  @ref('composer-element') composerElement!: HTMLElement;
  @ref('textarea') textarea!: HTMLElement;
  @ref('rendered-description') renderedDescription!: HTMLElement;
  @trackedReset({
    memo: 'inputValueResetMemo',
    update() {
      return this.buildDescriptionText();
    },
  })
  descriptionText: string = this.buildDescriptionText();

  constructor(owner: any, args: Args) {
    super(owner, args);

    let handler = (collapsed: boolean) => {
      this.trackDescriptionSectionCollapseToggle(collapsed);
    };

    this.inboxState.on(InboxEvents.TicketMainPanelCollapsed, handler);
    registerDestructor(this, () => {
      this.inboxState.off(InboxEvents.TicketMainPanelCollapsed, handler);
    });
  }

  get isPreviewingConversation() {
    return this.inboxSidebarService.isPreviewingConversation;
  }

  get inputValueResetMemo() {
    if (this.args.conversation?.id !== undefined) {
      return this.args.descriptionAttribute;
    }

    return JSON.stringify({
      ticket_type_id: this.descriptionDescriptor?.ticketTypeId,
      description: this.args.descriptionAttribute?.value,
    });
  }

  get canSeeDescriptionPlaceholder() {
    return this.session.workspace.isFeatureEnabled('edit-ticket-description-descriptions');
  }

  get composerConfig() {
    return {
      ...new BaseConfig(),
      allowedBlocks: ['paragraph', 'orderedList', 'unorderedList', 'codeBlock'],
      allowedInline: ['bold', 'italic', 'code', 'anchor'],
      autoFocus: true,
      placeholder: this.placeholderValue,
      renderOverlaysInPlace: true,
      tools: [{ name: 'text-formatter' }, { name: 'anchor-editor' }],
      allowImplicitMarginParagraphs: true,
      hideFromTextFormatter: [INLINE_CONTROL_ALIGNMENT],
      typeaheads: [EMOJI_TYPEAHEAD],
    };
  }

  get heightStyle() {
    return `height: ${this.scrollHeight + 2}px;resize:none;max-height: ${
      this.clientHeight * 0.35
    }px;`;
  }

  get linkifiedValue() {
    return this.editableDescriptionAttribute?.value
      ?.toString()
      .replace(
        /(https?:\/\/)([^\s]+)/g,
        '<a target="_blank" href="$1$2" data-inline-link="true">$2</a>',
      );
  }

  get conversationId() {
    return this.args.conversation?.id;
  }

  get descriptionDescriptor() {
    return this.args.descriptionDescriptor;
  }

  get editableDescriptionAttribute() {
    return this.args.descriptionAttribute;
  }

  get placeholderValue() {
    if (this.descriptionDescriptor?.description && this.canSeeDescriptionPlaceholder) {
      return this.descriptionDescriptor.description;
    }

    return this.intl.t('inbox.conversation-attributes.add-description-placeholder');
  }

  get hasValue() {
    return (
      this.value.blocks.filter((block: any) => {
        if (block.type === 'paragraph') {
          return block.text.length > 0;
        }
        if (['unorderedList', 'orderedList'].includes(block.type)) {
          return block.items.length > 0;
        }
        return true;
      }).length > 0
    );
  }

  get renderedBlocks() {
    let displayBlocks = JSON.parse(JSON.stringify(this.value.blocks));
    displayBlocks = this.addTargetToLinks(displayBlocks);
    displayBlocks = this.emptyStringToLineBreaks(displayBlocks);

    if (!this.session.workspace.isFeatureEnabled('disable-description-linkifying')) {
      displayBlocks = this.linkifyBlocks(displayBlocks);
    }

    return displayBlocks;
  }

  get inEditMode(): boolean {
    return (this.editMode || this.descriptionIsFocused) && !this.args.isReadOnly;
  }

  sanitizeString(str: string | undefined) {
    return str ? str.replace(/(\r\n|\n|\r){3,}/gs, '\n\n') : '';
  }

  @action
  async onBlur() {
    let isFormatting = this.composerElement.contains(
      document.querySelector(FORMATTING_POPOVER_SELECTOR),
    );

    if (!isFormatting) {
      await this.updateRichTextDescription();
      this.descriptionText = this.sanitizeString(this.renderedDescription?.innerText);
    }
  }

  @action
  onFocus(_event: FocusEvent) {
    this.editMode = true;
  }

  @action
  onChange(blocksDoc: BlocksDocument) {
    this.value = blocksDoc;
  }

  @action
  async onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
      await this.updateRichTextDescription();
      this.editMode = false;
    }
    event.stopPropagation();
  }

  @action windowResized() {
    this.clientHeight = document.body.clientHeight;
  }

  @action
  resize() {
    this.textarea.style.height = '';
    this.scrollHeight = this.textarea.scrollHeight;
  }

  @action
  onClick(event: Event) {
    let target = event.target as any;
    if (target.tagName === 'A') {
      event.stopPropagation();
      return;
    }
    this.editMode = true;
  }

  @action
  enterEditMode(event: Event) {
    if (event.target instanceof Element) {
      if (event.target?.getAttribute('data-inline-link') === 'true') {
        event.stopPropagation();
        return;
      }
    }

    this.editMode = true;
  }

  @action async updateRichTextDescription() {
    // For isEmpty to be correctly calculated on the Ticket Attribute Summary
    // We need to set the value to an empty string
    this.editableDescriptionAttribute!.value = !this.hasValue
      ? ''
      : JSON.stringify(this.value.blocks);

    await this.updateDescription();
  }

  @action async updateDescription() {
    if (!this.editMode) {
      return;
    }

    this.editMode = false;
    this.descriptionIsFocused = false;

    if (this.editableDescriptionAttribute!.isUpdated) {
      this.updateAttribute(this.editableDescriptionAttribute!);
    }
  }

  convertValueToBlocks() {
    let blocks: Array<Block>;
    try {
      blocks = JSON.parse((this.editableDescriptionAttribute!.value || '[]').toString());
    } catch {
      blocks = [
        {
          type: 'paragraph',
          text: (this.editableDescriptionAttribute!.value || '').toString(),
        },
      ];
    }

    return new BlocksDocument(blocks);
  }

  updateTextAreaSize = undefined;

  async updateAttribute(attribute: AttributeSummary) {
    attribute.normalizeValue();

    if (this.conversationId) {
      await this.inboxApi.updateAttribute(this.conversationId, attribute);
      await this.inboxState.trigger(InboxEvents.ConversationUpdated, this.args.conversation);
    }
  }

  trackDescriptionSectionCollapseToggle(value: boolean) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'ticket',
      action: value ? 'collapsed' : 'expanded',
      section: 'description',
      place: 'inbox',
      description_length: this.editableDescriptionAttribute?.value?.toString().length || 0,
    });
  }

  addTargetToLinks(blocks: Array<Block>) {
    let addBlankTargetToAnchors = (text: string) => {
      let doc = new DOMParser().parseFromString(text, 'text/html');
      let links = doc.querySelectorAll('a');
      links.forEach((link: any) => {
        link.setAttribute('target', '_blank');
      });
      return doc.body.innerHTML;
    };

    return blocks.map((block: any) => {
      if (block.type === 'paragraph') {
        block.text = addBlankTargetToAnchors(block.text);
      } else if (['unorderedList', 'orderedList'].includes(block.type)) {
        block.items = block.items.map((item: any) => {
          return addBlankTargetToAnchors(item);
        });
      }
      return block;
    });
  }

  emptyStringToLineBreaks(blocks: Array<Block>) {
    return blocks.map((block: any) => {
      if (block.type === 'paragraph' && block.text.trim().length === 0) {
        block.text = '<br>';
      }
      return block;
    });
  }

  linkifyBlocks(blocks: Array<Block>) {
    return blocks.map((block: any) => {
      if (block.type === 'paragraph') {
        block.text = this.linkifyBlock(block.text);
      } else if (['unorderedList', 'orderedList'].includes(block.type)) {
        block.items = block.items.map((item: any) => {
          return this.linkifyBlock(item);
        });
      }
      return block;
    });
  }

  linkifyBlock(text: string) {
    let urlRegex = /(^|[^<a])(https?:\/\/[^\s<]+)/g;
    let matches = text.match(urlRegex);

    if (matches) {
      for (let i = 0; i < matches.length; i++) {
        let match = matches[i];
        let startIndex = text.indexOf(match);
        let endIndex = startIndex + match.length;

        // Check if the matched string is inside an HTML anchor tag
        if (
          text.slice(0, startIndex).lastIndexOf('<a') >
          text.slice(0, startIndex).lastIndexOf('</a>')
        ) {
          // eslint-disable-next-line no-continue
          continue;
        }

        // Create the anchor tag and replace the matched string with it
        let link = `<a href="${match}" target="_blank" data-inline-link="true">${match}</a>`;
        text = `${text.slice(0, startIndex)} ${link}${text.slice(endIndex)}`;
      }
    }

    return text;
  }

  @action handleDescriptionFocus(event: FocusEvent) {
    if (event.target instanceof HTMLAnchorElement) {
      return;
    }

    this.descriptionIsFocused = true;
  }

  buildDescriptionText() {
    return this.sanitizeString(this.renderedDescription?.innerText);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::EditableTicketDescriptionAttribute': typeof EditableTicketDescriptionAttribute;
    'inbox2/editable-ticket-description-attribute': typeof EditableTicketDescriptionAttribute;
  }
}
