/* import __COLOCATED_TEMPLATE__ from './assign-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type MacroAction } from 'embercom/objects/inbox/macro';

interface Signature {
  Element: HTMLDivElement;
  Args: { action: MacroAction };
}

const AssignConversation = templateOnlyComponent<Signature>();
export default AssignConversation;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::UseMacro::Preview::AssignConversation': typeof AssignConversation;
    'inbox2/command-k/use-macro/preview/assign-conversation': typeof AssignConversation;
  }
}
