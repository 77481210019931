/* import __COLOCATED_TEMPLATE__ from './company-notes.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxApi from 'embercom/services/inbox-api';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { useResource } from 'ember-resources';
import { NotesResource } from 'embercom/components/inbox2/conversation-details-sidebar/sidebar-sections/notes/notes-resource';
import type Company from 'embercom/objects/inbox/company';
import type Note from 'embercom/objects/inbox/note';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';

interface Args {
  conversation: Conversation;
  userCompanies: Array<Company>;
  activeInbox?: Inbox;
}

interface Signature {
  Args: SidebarSectionComponentArgs<Args>;
}

export default class CompanyNotes extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;

  @tracked allUserCompaniesNotes = useResource(this, NotesResource, () => ({
    noteIds: this.noteIds,
  }));

  @action
  async addNewNote(company: any, newNoteText: string) {
    let newNote = await this.inboxApi.addNote(company.id, newNoteText, true);

    this.allUserCompaniesNotes.appendNote(newNote);

    let currentNoteIds = company.noteIds || [];
    company.setNoteIds([newNote.id, ...currentNoteIds]);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'added',
      section: 'company_notes',
      object: 'note',
      inbox_type: this.args.activeInbox?.type,
      conversation_id: this.args.conversation.id,
    });
  }

  @action
  async updateNote(note: Note, body: string) {
    let updatedNote = await this.inboxApi.updateNote(note, body);
    this.allUserCompaniesNotes.replaceNote(note, updatedNote);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated',
      section: 'company_notes',
      object: 'note',
      inbox_type: this.args.activeInbox?.type,
      conversation_id: this.args.conversation.id,
    });
  }

  get noteIds() {
    return this.args.userCompanies?.map((company) => company.noteIds).flat() || [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::CompanyNotes': typeof CompanyNotes;
    'inbox2/conversation-details-sidebar/sidebar-sections/company-notes': typeof CompanyNotes;
  }
}
