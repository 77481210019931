/* import __COLOCATED_TEMPLATE__ from './inbox-requires-seat.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

import { inject as service } from '@ember/service';

import type Session from 'embercom/services/session';
import { type Router } from '@ember/routing';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';

export default class InboxRequiresSeat extends Component {
  @service declare session: Session;
  @service declare router: Router;
  @service declare intl: IntlService;

  @action
  linkToHome() {
    this.router.transitionTo('apps.app.home', this.session.workspace.id);
  }

  @action
  linkToSettings() {
    this.router.transitionTo('apps.app.settings.workspace.teammates', this.session.workspace.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::InboxRequiresSeat': typeof InboxRequiresSeat;
    'inbox2/inbox-requires-seat': typeof InboxRequiresSeat;
  }
}
