/* import __COLOCATED_TEMPLATE__ from './view-card-with-calls-skeleton.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const ViewCardWithCallsSkeleton = templateOnlyComponent<Signature>();
export default ViewCardWithCallsSkeleton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Dashboard::SummaryCard::ViewCardWithCallsSkeleton': typeof ViewCardWithCallsSkeleton;
    'inbox2/dashboard/summary-card/view-card-with-calls-skeleton': typeof ViewCardWithCallsSkeleton;
  }
}
