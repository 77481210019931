/* import __COLOCATED_TEMPLATE__ from './initializer.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import type MessengerApp from 'embercom/objects/inbox/messenger-app';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import { type MessengerCardBlock } from '@intercom/interblocks.ts';
import type MessengerApps from 'embercom/services/messenger-apps';

interface Args {
  messengerApp: MessengerApp;
  contextualData: { conversation_id?: number };
  onAppSubmit: (block: MessengerCardBlock) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class UnnamedComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare messengerApps: MessengerApps;

  paramValues = {};

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.messengerApp && !this.args.messengerApp.hasConfiguration) {
      taskFor(this.initialize).perform();
    }
  }

  get isInitializing() {
    return taskFor(this.initialize).isRunning;
  }

  @task
  *initialize(canvas_creation_options: object = {}): TaskGenerator<void> {
    let messenger_app_id = this.args.messengerApp.id;
    let context = this.args.contextualData || {};
    let card_creation_params = {
      messenger_app_id,
      canvas_creation_options,
    };
    try {
      let actionData;
      let params = {
        app_id: this.session.workspace.id,
        card_creation_params,
        context,
      };
      actionData = yield this.messengerApps.initializeCard(params);
      actionData.messenger_app_id = messenger_app_id;
      let app_name = this.args.messengerApp.appPackageId;
      this.args.onAppSubmit(actionData);
      let analyticsEvent = {
        action: 'initialized',
        object: 'messenger_app_card',
        messenger_app_id,
        app_name,
        conversation_id: context.conversation_id,
        messenger_card_id: actionData?.id,
      };
      this.intercomEventService.trackAnalyticsEvent(analyticsEvent);
    } catch (err) {
      console.error(err);
      this.notificationsService.notifyResponseError(err, {
        default: this.intl.t('inbox.app-framework.errors.app-insertion'),
        403: this.intl.t('inbox.app-framework.errors.app-gone'),
      });
    }
  }

  @action
  initializeAction(): void {
    taskFor(this.initialize).perform(this.paramValues);
  }

  @action
  onConfigResults(paramValues?: object): void {
    taskFor(this.initialize).perform(paramValues);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::AppFramework::Initializer': typeof UnnamedComponent;
    'inbox2/app-framework/initializer': typeof UnnamedComponent;
  }
}
