/* import __COLOCATED_TEMPLATE__ from './channels-delineating-replies-banner.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    onButtonClick: () => void;
  };
}

const ChannelsDelineatingRepliesBanner = templateOnlyComponent<Signature>();
export default ChannelsDelineatingRepliesBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ChannelsDelineatingRepliesBanner': typeof ChannelsDelineatingRepliesBanner;
    'inbox2/channels-delineating-replies-banner': typeof ChannelsDelineatingRepliesBanner;
  }
}
