/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-component-inheritance */

import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'embercom/config/environment';

import {
  type QuickSearchExcludeEntities,
  type SearchMultipleTypesResponse,
} from 'embercom/services/quick-search';
import type QuickSearch from 'embercom/services/quick-search';
import { type SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { Resource, type Named } from 'ember-resources';
import { type QuickSearchContexts } from 'embercom/objects/inbox/macro';
import { registerDestructor } from '@ember/destroyable';

import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { timeout } from 'ember-concurrency';
import generateUUID from 'embercom/lib/uuid-generator';

interface QuickSearchDataArgs {
  types: Array<SearchableType>;
  query: string;
  loadSampleData: boolean;
  size: number;
  currentSearchContext: Array<QuickSearchContexts>;
  onSearchReturn?: Function;
  excludeEntities?: QuickSearchExcludeEntities;
}

export const DEFAULT_FIELDS = {
  data: {
    id: true,
    name: true,
    image_url: true,
    permissions: true,
    team_ids: true,
    blocks: true,
    icon: true,
    count: true,
    admin_status: true,
    system_type: true,
    has_dynamic_admin_assignee_predicates: true,
  },
};

export default class QuickSearchDataMultiple extends Resource<Named<QuickSearchDataArgs>> {
  @service declare quickSearch: QuickSearch;
  @service frontendStatsService: any;

  @tracked results: SearchMultipleTypesResponse = {};

  previous: QuickSearchDataMultiple;
  queryArg: string;
  size: number;

  constructor(owner: any, args: Named<QuickSearchDataArgs>, previous: QuickSearchDataMultiple) {
    super(owner, args, previous);
    taskFor(this.search).perform();

    // Workaround for a bug:
    // https://github.com/NullVoxPopuli/ember-resources/issues/195
    this.previous = previous;
    this.queryArg = this.args.named.query;
    this.size = this.args.named.size;

    // If the current query is building on the previous one, we'll keep the previous results.
    let extendsPreviousQuery =
      previous &&
      (this.queryArg.startsWith(previous.queryArg) || previous.queryArg.startsWith(this.queryArg));

    // If the query is the same but we're loading more, we'll also keep the previous results
    let sizeChanged =
      previous && this.queryArg === previous.queryArg && this.size !== previous.size;

    if ((previous?.results && extendsPreviousQuery) || sizeChanged) {
      this.results = previous.results;
    }

    registerDestructor(this, () => {
      taskFor(this.search).cancelAll();
    });
  }

  @task *search() {
    let searchTerm = this.args.named.query;
    let entityTypes = this.args.named.types;
    let loadSampleData = this.args.named.loadSampleData;
    let size = this.args.named.size;
    let currentSearchContext = this.args.named.currentSearchContext;
    let interactionId = generateUUID();

    if ((!loadSampleData && !searchTerm) || !entityTypes.length) {
      this.results = {};
      return;
    }

    yield timeout(ENV.APP._50MS);

    this.frontendStatsService.startInteractionTime('interaction:inbox-2/quick-search-multiple', {
      id: interactionId,
      metadata: { entityTypes },
    });

    this.results = yield this.quickSearch.searchMultipleTypes({
      entityTypes,
      searchTerm,
      size,
      currentSearchContext,
      fields: DEFAULT_FIELDS,
      excludeEntities: this.args.named.excludeEntities,
    });

    this.frontendStatsService.stopInteractionTime('interaction:inbox-2/quick-search-multiple', {
      id: interactionId,
      metadata: { entityTypes, is_result_empty: Object.keys(this.results).length === 0 },
    });

    this.args.named.onSearchReturn?.();
  }
}
