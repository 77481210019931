/* import __COLOCATED_TEMPLATE__ from './assign-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import { type MacroActionComponentArgs } from 'embercom/lib/inbox2/bulk-action-types';

interface Signature {
  Element: HTMLElement;
  Args: MacroActionComponentArgs;
}

export default class AssignConversation extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare intl: IntlService;

  get currentTeammate() {
    return this.session.teammate;
  }

  get canAssignConversation() {
    if (!this.currentTeammate.permissions.canReassignConversations) {
      if (this.args.actionEditorContext === 'bulk-edit') {
        return (
          this.inboxState.activeInbox?.type === InboxType.Unassigned ||
          this.currentTeammate.id.toString() === this.inboxState.activeInbox?.id?.toString()
        );
      }

      if (this.args.actionEditorContext === 'conversation' && this.args.conversation) {
        return (
          this.currentTeammate === this.args.conversation.adminAssignee ||
          this.args.conversation.adminAssignee?.isUnassignedAssignee
        );
      }
    }

    return true;
  }

  get label() {
    let assigneeSummary = this.args.macroAction.data?.assignee_summary;
    if (!assigneeSummary) {
      return this.intl.t('inbox.composer.macro-actions.assign.missing-assignee');
    }

    return assigneeSummary.name;
  }

  get isInvalid() {
    if (this.args.forceInvalid) {
      return true;
    }

    return this.args.macroAction.isInvalid;
  }

  @action onSelectAssignee(data: Record<string, any>) {
    this.args.macroAction.updateData({ assignee_summary: data });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MacroActions::AssignConversation': typeof AssignConversation;
    'inbox2/composer/macro-actions/assign-conversation': typeof AssignConversation;
  }
}
