/* import __COLOCATED_TEMPLATE__ from './tracker-tickets.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import { action } from '@ember/object';
import type CommandKService from 'embercom/services/command-k';
import { tracked } from '@glimmer/tracking';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'ember-intl/services/intl';
import { type WithBoundArgs } from '@glint/template';
import type LinkedTicketV2 from './linked-ticket-v2';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { useResource } from 'ember-resources';
import { TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import type Session from 'embercom/services/session';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxApi from 'embercom/services/inbox-api';

interface Signature {
  Element: HTMLElement;
  Args: {
    tickets: LinkedTicketSummary[];
    onLinkTicket: (ticket: LinkedTicketSummary) => void;
    onCreateTrackerTicket: (ticketTypeId: number) => void;
    conversation: Conversation;
    previewOnly?: boolean;
  };
  Blocks: {
    ticketRow: [
      { ticket: LinkedTicketSummary; component: WithBoundArgs<typeof LinkedTicketV2, 'ticket'> },
    ];
  };
}

const MAX_ALLOWED_TRACKER_TICKETS = 1;

export default class TrackerTickets extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;

  @tracked isShowingConfirmLinkingModal = false;
  @tracked selectedTicket?: LinkedTicketSummary;

  readonly ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    allowedTicketCategories: [TicketCategory.Tracker],
  }));

  get previewingObject() {
    return this.args.conversation.isTicket ? 'ticket' : 'conversation';
  }

  get canLinkTrackerTicket() {
    return !this.args.previewOnly && !this.linkingLimitReached;
  }

  get linkingLimitReached() {
    return this.args.tickets.length >= MAX_ALLOWED_TRACKER_TICKETS;
  }

  get hasNoTrackerTicketTypes() {
    return this.ticketTypeResource.activeTicketTypes.length === 0;
  }

  get app() {
    return this.session.workspace;
  }

  get showMinimalTooltip() {
    return this.app?.hasTrackerTickets;
  }

  @action
  showLearnArticle(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'tracker_ticket_article_link',
      context: `inbox.tooltip.tracker_ticket_article_link`,
      place: 'inbox.tooltip',
      section: 'right_side_banner',
    });
    window.Intercom('showArticle', 8300302); // https://www.intercom.com/help/en/articles/8300302-how-to-create-a-tracker-ticket
  }

  @action displayLinkTrackerTicketTypesPane() {
    if (!this.canLinkTrackerTicket) {
      return;
    }

    this.commandK.registerAndShow({
      actionID: 'create-linked-ticket',
      context: {
        category: TicketCategory.Tracker,
      },
      onSelect: (action: any) => {
        this.args.onCreateTrackerTicket(action.id);
      },
    });
  }

  @action displaySearchTrackerTicketsPane() {
    if (!this.canLinkTrackerTicket) {
      return;
    }

    // We support two workflows:
    // search pane -> select -> link directly
    // search pane -> create a new tracker ticket
    this.commandK.registerAndShow({
      actionID: 'search-ticket',
      onSelect: this.onSelectTicket,
      context: {
        onCreateTrackerTicket: () => {
          this.commandK.registerAndShow({
            actionID: 'create-linked-ticket',
            context: {
              category: TicketCategory.Tracker,
            },
            onSelect: (action: any) => {
              this.args.onCreateTrackerTicket(action.id);
            },
          });
        },
      },
    });
  }

  @action onSelectTicket(ticket: LinkedTicketSummary) {
    this.selectedTicket = ticket;
    this.isShowingConfirmLinkingModal = true;
  }

  @action onModalClose() {
    this.selectedTicket = undefined;
    this.isShowingConfirmLinkingModal = false;
  }

  @action onLinkTicket() {
    this.args.onLinkTicket(this.selectedTicket!);
    this.onModalClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Links::TrackerTickets': typeof TrackerTickets;
    'inbox2/conversation-details-sidebar/sidebar-sections/links/tracker-tickets': typeof TrackerTickets;
  }
}
