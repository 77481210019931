/* import __COLOCATED_TEMPLATE__ from './recent-page-views.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import type PageView from 'embercom/objects/inbox/page-view';

interface Signature {
  Args: SidebarSectionComponentArgs<{
    recentPageViews: PageView[];
    isPreviewingConversation?: boolean;
  }>;
}

const RecentPageViews = templateOnlyComponent<Signature>();
export default RecentPageViews;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::RecentPageViews': typeof RecentPageViews;
    'inbox2/conversation-details-sidebar/sidebar-sections/recent-page-views': typeof RecentPageViews;
  }
}
