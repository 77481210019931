/* import __COLOCATED_TEMPLATE__ from './recent-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import type LatestConversationSummary from 'embercom/objects/inbox/latest-conversation-summary';

interface Args {
  conversation: LatestConversationSummary;
  currentConversation: Conversation;
  selectedConversation: LatestConversationSummary;
  onClose: () => void;
  isConfirmationModalOpen: boolean;
}

export default class RecentConversation extends Component<Args> {
  get userDisplayName() {
    return this.args.currentConversation.firstParticipant?.displayAs;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::MergeInto::RecentConversation': typeof RecentConversation;
    'inbox2/command-k/merge-into/recent-conversation': typeof RecentConversation;
  }
}
