/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { action } from '@ember/object';
import type SnoozeConfig from 'embercom/services/snooze-config';
import { inject as service } from '@ember/service';
import { DurationObject, DurationType } from 'embercom/objects/inbox/duration';
import type IntlService from 'embercom/services/intl';
import type moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import type SnoozeAction from 'embercom/objects/inbox/command-k/snooze';
import type CommandKService from 'embercom/services/command-k';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';

type Args = CommandKPaneComponentArgs<SnoozeAction>;

interface Signature {
  Args: Args;
}

export interface CustomSnoozeData {
  time: string;
}

export default class SnoozePaneComponent extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare snoozeConfig: SnoozeConfig;
  @service declare intl: IntlService;
  private customSnoozeChildAction: ChildAction | undefined;

  @tracked query = '';

  selection = useResource(this, NavigableSelection, () => ({
    items: this.items,
    isCommandKVisible: this.commandK.isVisible,
  }));

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.snoozeConfig.snoozePaneShown = true;

    if (this.args.actionItem.context?.startInCustom) {
      this.snoozeConfig.customSnoozeSelected = true;
      this.customSnoozeChildAction = this.args.actionItem.customSnoozeAction;
    }
  }

  willDestroy(): void {
    super.willDestroy();
    this.snoozeConfig.resetPlaceHolder();
    this.snoozeConfig.customSnoozeSelected = false;
    this.snoozeConfig.snoozePaneShown = false;
  }

  get items(): Array<ChildAction> {
    return this.args.actionItem.buildResults(this.query);
  }

  @action search(query: string) {
    this.query = query;

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.items.length,
    });
  }

  @action onSelect(childAction: ChildAction, _: any, emberEvent?: any) {
    emberEvent?.stopPropagation();
    emberEvent?.stopImmediatePropagation();
    if (childAction.id !== 'snooze-custom') {
      this.args.onSelect(childAction);
    } else {
      if (!this.customSnoozeChildAction) {
        this.customSnoozeChildAction = childAction;
      }
      this.snoozeConfig.customSnoozeSelected = true;
    }
  }

  @action onCustomSnooze(data: CustomSnoozeData) {
    if (this.customSnoozeChildAction) {
      this.customSnoozeChildAction.value = new DurationObject(DurationType.CustomTime, data.time);
      this.args.onSelect(this.customSnoozeChildAction);
      this.snoozeConfig.customSnoozeSelected = false;
    }
  }

  @action onDateSelected(date: moment.Moment) {
    this.snoozeConfig.placeholder = `${this.intl.t(
      'inbox.command-k.placeholders.custom-snooze',
    )} ${date.format('MMM DD yyyy')}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::Snooze::Pane': typeof SnoozePaneComponent;
    'inbox2/command-k/snooze/pane': typeof SnoozePaneComponent;
  }
}
