/* import __COLOCATED_TEMPLATE__ from './disabled-ticket-visibility.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';

interface Args {
  ticketTypeInternal: boolean;
  labelRowSize: string;
  isVisibleToUser: boolean;
}

export default class DisabledTicketVisibility extends Component<Args> {
  @service declare session: Session;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get visibilityTooltip() {
    return this.args.isVisibleToUser
      ? `inbox.ticket-attributes.create-ticket.enable-share.external`
      : `inbox.ticket-attributes.create-ticket.disable-share.external`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::DisabledTicketVisibility': typeof DisabledTicketVisibility;
    'inbox2/disabled-ticket-visibility': typeof DisabledTicketVisibility;
  }
}
